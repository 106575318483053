import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'mapCollection' })
export class MapCollectionPipe implements PipeTransform {

  public transform(value: any[], property: string): any {
    return (value && value.length) ? value?.map(s => s[property]) : [];
  }
}
