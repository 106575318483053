import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PageDTO } from 'app/data/dto/PageDTO';
import { CustomHttpParameterEncoder } from 'app/util/other/CustomHttpParameterEncoder';
import { ObjectUtil } from 'app/util/ObjectUtil';
import { ApplicationConfig } from 'app/config/ApplicationConfig';
import { map } from 'rxjs/operators';
import { PageCriteriaDTO } from 'app/data/dto/PageCriteriaDTO';
import { FriendDTO } from 'app/data/dto/friend/FriendDTO';
import { SeniorNotRelatedPageDTO } from 'app/data/dto/senior/SeniorNotRelatedPageDTO';
import { SeniorNotRelatedPageCriteriaDTO } from 'app/data/dto/senior/SeniorNotRelatedPageCriteriaDTO';
import { Constant } from 'app/common/Constant';

@Injectable({
  providedIn: 'root'
})
export class FriendService {
  constructor(private readonly http: HttpClient) {
  }

  public getFriendsPage(criteria: PageCriteriaDTO): Observable<PageDTO<FriendDTO>> {
    const params: HttpParams = new HttpParams({
      encoder: new CustomHttpParameterEncoder(),
      fromObject: ObjectUtil.valueAsString(ObjectUtil.classToPlain(criteria, true, true, true, true))
    });

    return this.http.get(`${ ApplicationConfig.apiUrl }/friends/page`, { params: params }).pipe(
      map((response: any) => ObjectUtil.plainToClassFromExisting(new PageDTO<FriendDTO>(FriendDTO), response))
    );
  }

  public getSeniorsPage(criteria: SeniorNotRelatedPageCriteriaDTO): Observable<PageDTO<SeniorNotRelatedPageDTO>> {
    const context: HttpContext = new HttpContext().set(Constant.HTTP_SUPPRESS_LOADING_TOKEN, true);

    const params: HttpParams = new HttpParams({
      encoder: new CustomHttpParameterEncoder(),
      fromObject: ObjectUtil.valueAsString(ObjectUtil.classToPlain(criteria, true, true, true, true))
    });

    return this.http.get(`${ ApplicationConfig.apiUrl }/seniors/notrelated/page`, {
      context: context,
      params: params
    }).pipe(
      map((response: any) => ObjectUtil.plainToClassFromExisting(new PageDTO<SeniorNotRelatedPageDTO>(SeniorNotRelatedPageDTO), response))
    );
  }

  public removeFriend(id: number): Observable<void> {
    return this.http.delete<void>(`${ ApplicationConfig.apiUrl }/friends/${ id }`);
  }
}
