import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/core';
import { ApplicationModel } from 'app/model/ApplicationModel';
import { State } from 'app/common/State';
import { UIRouter } from '@uirouter/angular';

@Injectable({ providedIn: 'root' })
export class StateUtil {
  constructor(
    private applicationModel: ApplicationModel,
    private router: UIRouter,
    private stateService: StateService
  ) {
  }

  public goBack(): void {
    const previousState = this.applicationModel.stateHistory.pop();
    if (previousState) {
      this.applicationModel.goingToPreviousState = true;
      this.goToState(previousState.state.name, previousState.params);
    }
    else {
      this.applicationModel.goingToPreviousState = true;
      this.goToDashboard();
    }
  }

  public reloadApplication(): void {
    window.location.reload();
  }

  public goToUrl(url: string | Promise<string>, newWindow: boolean = false): void {
    let windowReference = window.open('', newWindow ? '_blank' : '_self');
    if (url instanceof Promise) {
      url.then(resolvedUrl => {
        if (!resolvedUrl.startsWith('https://') && resolvedUrl.startsWith('www.')) {
          resolvedUrl = 'https://' + resolvedUrl;
        }
        windowReference.location.assign(resolvedUrl);
      }).catch(err => {
        console.error('Error resolving URL:', err);
        windowReference.close();
      });
    }
    else {
      if (!url.startsWith('https://') && url.startsWith('www.')) {
        url = 'https://' + url;
      }
      windowReference.location.assign(url);
    }
  }

  downloadUrl(url: string): void {
    const link = document.createElement('a');
    link.href = url;
    link.download = url.split('/').pop() || 'download';
    link.target = '_self';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  //   downloadUrl(url: string): void {
  //     fetch(url)
  //         .then(response => response.blob())
  //         .then(blob => {
  //             const blobUrl = window.URL.createObjectURL(blob);
  //             const link = document.createElement('a');
  //             link.href = blobUrl;
  //             link.download = url.split('/').pop() || 'download';
  //             document.body.appendChild(link);
  //             link.click();
  //             document.body.removeChild(link);
  //             window.URL.revokeObjectURL(blobUrl);
  //         })
  //         .catch(e => console.error(e));
  // }

  public goToState(
    state: string,
    stateParameters: { [key: string]: any } = undefined,
    forceSynchronousTransition: boolean = true
  ): void {
    if (forceSynchronousTransition) {
      // if transition is currently in progress, don't interrupt and check again in 100ms
      if (this.router?.globals?.transition) {
        setTimeout(() => {
          this.goToState(state, stateParameters, forceSynchronousTransition);
        }, 100);
      }
      else {
        this.stateService.go(state, stateParameters);
      }
    }
    else {
      this.stateService.go(state, stateParameters);
    }
  }

  public goToDashboard(): void {
    this.goToState(State.MAIN.DASHBOARD);
  }

  public goToPageNotFound(): void {
    this.goToState(State.PRELIMINARY.ERROR.NOT_FOUND);
  }

  public goToPageAccessDenied(): void {
    this.goToState(State.PRELIMINARY.ERROR.ACCESS_DENIED);
  }
}
