<ng-template #headingTemplate>
  <h1>{{ 'VIEW.MAIN.COACH.ON-DEMAND.ON-DEMAND' | translate }}</h1>
</ng-template>

<section class="section">
  <app-tabs class="py-2"
            [translationPrefix]="'VIEW.MAIN.ON_DEMAND.TABS.'"
            [tabs]="tabs"
            (tabChanged)="onTabChanged($event)">
  </app-tabs>

  <ng-container *ngIf="[ VideoListTabs.MY_VIDEOS, VideoListTabs.ALL_VIDEOS ] | includes: activeTab">
    <app-data-grid
      *ngIf="page"
      [showSearch]="false"
      [data]="page.content"
      [criteria]="criteria"
      [context]="{ host: this }"
      [showFilters]="true"
      [showPagination]="true"
      [showItemsPerPage]="false"
      [showTotalItems]="false"
      [pageTotal]="page.totalPages"
      [itemTotal]="page.totalElements"
      (gridAction)="onGridAction($event)"
      (criteriaChanged)="onCriteriaChanged($event)"
      [columns]="$any([
        { headerName: ('VIEW.MAIN.ON_DEMAND.NAME' | translate), field: 'title', tooltipField: 'title', minWidth: 200, maxWidth: 300, cellClass: 'cell-fullwidth' },
        { headerName: ('VIEW.MAIN.ON_DEMAND.CATEGORY' | translate), field: 'category', valueFormatter: enumCategoryFormatter },
        { headerName: ('VIEW.MAIN.ON_DEMAND.LENGTH' | translate), field: 'duration', cellClass: 'text-right text-lg-left', valueFormatter: durationFormatter },
        { headerName: ('VIEW.MAIN.ON_DEMAND.INTENSITY' | translate), field: 'intensity', valueFormatter: enumIntensityFormatter },
        { headerName: ('VIEW.MAIN.ON_DEMAND.COACH' | translate), field: 'coaches', cellClass: 'text-right text-lg-left', valueFormatter: coachFormatter },
        { headerName: ('VIEW.MAIN.ON_DEMAND.VIEWS' | translate), field: 'viewCount', cellClass: 'd-none d-lg-inline-block' },
        { headerName: ('VIEW.MAIN.ON_DEMAND.RATING' | translate), minWidth: 140, field: 'rating', cellRendererFramework: StarRatingCellRendererComponent },
        { headerName: '', headerClass: 'header-right', minWidth: 100, maxWidth: 130, suppressKeyboardEvent: ActionCellRendererComponent.suppressKeyboardEvent, cellClass: 'cell-fullwidth', cellRendererFramework: ActionCellRendererComponent }
      ])"
    >
      <app-data-grid-filters>
        <div class="form-group">
          <ng-select
            id="sort-by"
            name="sort-by"
            [placeholder]="'VIEW.MAIN.ON_DEMAND.SORT_BY' | translate"
            [searchable]="false"
            [style.--icon]="'url(/asset/image/icon/arrows-sort.svg)'"
            (change)="onSortOptionChange($event)"
            [(ngModel)]="sortValue"
          >
            <ng-option *ngFor="let sortType of sortByOptions" [value]="sortType.value">
              {{ sortType.label | translate }}
            </ng-option>
          </ng-select>
        </div> <!-- /.form-group -->
      </app-data-grid-filters>
    </app-data-grid>
  </ng-container>

  <ng-container *ngIf="activeTab === VideoListTabs.PROGRAMS">
    <app-program-coach-list-tab></app-program-coach-list-tab>
  </ng-container>
</section>
