import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';
import { State } from 'app/common/State';
import { VideoSeniorRecommendedDTO } from 'app/data/dto/onDemandVideo/senior/VideoSeniorRecommendedDTO';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { OnDemandVideoModel } from 'app/model/OnDemandVideoModel';

@Component({
  selector: 'app-dashboard-senior-all-recommended-modal',
  templateUrl: './DashboardSeniorAllRecommendedModalComponent.html',
  styleUrls: [ './DashboardSeniorAllRecommendedModalComponent.scss' ]
})
export class DashboardSeniorAllRecommendedModalComponent implements OnInit {
  public data: VideoSeniorRecommendedDTO[];

  constructor(
    private bsModalRef: BsModalRef,
    private onDemandVideoModel: OnDemandVideoModel,
    private stateService: StateService
  ) {
  }

  public ngOnInit(): void {
    this.onDemandVideoModel.getRecommendedVideosAll().subscribe((videos) => {
      this.data = videos;
    });
  }

  public close(): void {
    this.bsModalRef.hide();
  }

  public play(videoId: number): void {
    this.stateService.go(State.MAIN.SENIOR.ON_DEMAND.VIDEO.PLAY, { videoId });
    this.bsModalRef.hide();
  }
}
