import { DateUtil } from 'app/util/DateUtil';
import { Transform, Type } from 'class-transformer';
import { LiveClassCategory } from 'app/data/enum/liveClass/LiveClassCategory';
import { ScheduledLiveClassNeedDTO } from '../ScheduledLiveClassNeedDTO';
import * as _ from 'lodash';
import { IntensityLevel } from 'app/data/enum/IntensityLevel';

export class ScheduledLiveClassCreateRequestDTO {
  public attachmentIds?: number[];

  public capacity?: number;

  public category: LiveClassCategory;

  public coachIds: number[];

  public description: string;

  public duration: number;

  public imageId: number;

  public intensity: IntensityLevel;

  public language: string;

  @Type(() => ScheduledLiveClassNeedDTO)
  public scheduledLiveClassNeeds?: ScheduledLiveClassNeedDTO[];

  @Transform(DateUtil.dateTimeConversion)
  public startDate: Date;

  public title: string;

  public url: string;

  constructor(data?: Partial<ScheduledLiveClassCreateRequestDTO>) {
    _.defaults(this, data, {
      attachmentIds: [],
      scheduledLiveClassNeeds: []
    });
  }
}
