import { UserGender } from 'app/data/enum/account/UserGender';
import { DateUtil } from 'app/util/DateUtil';
import { Transform, Type } from 'class-transformer';
import { UserRegistrationDTO } from '../user/UserRegistrationDTO';
import { SubscriptionType } from 'app/data/enum/subscription/SubscriptionType';

export class SeniorRegistrationDTO {

  @Type(() => UserRegistrationDTO)
  public user: UserRegistrationDTO;

  @Transform(DateUtil.dateConversion)
  public dateOfBirth: Date;

  public gender?: UserGender;

  public organizationId?: number;

  public memberCode?: string;

  public subscriptionType: SubscriptionType;

}