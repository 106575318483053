import { OptionItem } from 'app/data/local/generic/OptionItem';

export enum LiveClassDuration {
  '10_MINUTES' = 10,
  '15_MINUTES' = 15,
  '30_MINUTES' = 30,
  '45_MINUTES' = 45,
  '60_MINUTES' = 60,
}

export const liveClassLengths: OptionItem<LiveClassDuration>[] = [
  { label: '10', value: 10 },
  { label: '15', value: 15 },
  { label: '30', value: 30 },
  { label: '45', value: 45 },
  { label: '60', value: 60 }
];
