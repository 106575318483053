import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActiveCoachDTO } from 'app/data/dto/coach/ActiveCoachDTO';
import { LiveClassLanguage } from 'app/data/enum/liveClass/LiveClassLanguage';
import { VideoCategory } from 'app/data/enum/video/VideoCategory';
import {
  VideoEducationSubcategory,
  VideoFitnessSubcategory,
  VideoHobbiesSubcategory,
  VideoLiveRecordingsSubcategory,
  VideoMindAndBodySubcategory,
  VideoSubcategories,
  VideoSubcategory
} from 'app/data/enum/video/VideoSubcategory';
import { OptionItem } from 'app/data/local/generic/OptionItem';
import { OnDemandVideoModel } from 'app/model/OnDemandVideoModel';
import { ViewUtil } from 'app/util/ViewUtil';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { IntensityLevel } from 'app/data/enum/IntensityLevel';

@Component({
  selector: 'app-on-demand-video-filter-modal',
  templateUrl: './OnDemandVideoFilterModalComponent.html',
  styleUrls: [ './OnDemandVideoFilterModalComponent.scss' ]
})
export class OnDemandVideoFilterModalComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  public static readonly FILTER_DATA: string = 'FILTER_DATA';
  public static readonly CLEAR_FILTERS: string = 'clearFilters';

  public displayLanguages: boolean = true;

  public allCategoryChecked: boolean = false;
  public checkedSubcategories: any = {};
  public allCoachesChecked: boolean = false;
  public checkedCoaches: any = {};
  public allTimeOfTheDayChecked: boolean = false;
  public checkedTimeOfTheDay: any = {};
  public allLanguageChecked: boolean = false;
  public checkedLanguages: any = {};

  public anyOfSubcategories: VideoSubcategory[];
  public anyOfLanguages: string[];
  public anyOfCoachIds: number[];
  public anyOfIntensities: IntensityLevel[];

  public videoCategory: VideoCategory;

  public subcategories: OptionItem<string>[];
  public languages: OptionItem<LiveClassLanguage>[] = this.viewUtil.createEnumSelectOptions(LiveClassLanguage, 'LANGUAGE');
  public intensities: OptionItem<IntensityLevel>[] = this.viewUtil.createEnumSelectOptions(IntensityLevel, 'INTENSITY_LEVEL');

  public coaches: ActiveCoachDTO[];

  public onDataSelect: Subject<any> = new Subject<any>();

  public filters: any = {
    type: {
      allChecked: false,
      checked: {},
      selectedTypes: []
    },
    languages: {
      allChecked: false,
      checked: {}
    },
    intensities: {
      allChecked: false,
      checked: {}
    },
    coaches: {
      allChecked: false,
      checked: {},
      selectedCoaches: []
    }
  };

  constructor(
    private viewUtil: ViewUtil,
    private bsModalRef: BsModalRef,
    private onDemandVideoModel: OnDemandVideoModel,
    private modalService: BsModalService
  ) {
  }

  ngOnInit(): void {
    this.getCoachesList();
    this.prepareSubcategories();
    if (this.anyOfSubcategories) {
      this.anyOfSubcategories.forEach((subcategory) => {
        this.filters.type.checked[subcategory] = true;
        this.filters.type.selectedTypes.push(subcategory);
      });
    }
    if (this.anyOfIntensities) {
      this.anyOfIntensities.forEach((intensity) => {
        this.filters.intensities.checked[intensity] = true;
      });
      this.onIntensitiesCheckboxChange();
    }

    if (this.anyOfLanguages) {
      this.anyOfLanguages.forEach((language) => {
        this.filters.languages.checked[language] = true;
      });
      this.onLanguageCheckboxChange();
    }
    if (this.anyOfCoachIds) {
      this.anyOfCoachIds.forEach((coachId) => {
        this.filters.coaches.checked[coachId] = true;
        this.filters.coaches.selectedCoaches.push(coachId);
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onAllSubcategoryChange(): void {
    this.subcategories.forEach((category) => {
      this.filters.type.checked[category.value] = this.filters.type.allChecked;
    });
  }

  public onSubcategoryCheckboxChange(): void {
    const allChecked = this.subcategories.every((category) => this.filters.type.checked[category.value]);
    this.filters.type.allChecked = allChecked;
  }

  public onAllLanguageChange(): void {
    this.languages.forEach((language) => {
      this.filters.languages.checked[language.value] = this.filters.languages.allChecked;
    });
  }

  public onLanguageCheckboxChange(): void {
    const allChecked = this.languages.every((language) => {
      return this.filters.languages.checked[language.value];
    });
    this.filters.languages.allChecked = allChecked;
  }

  public onAllIntensitiesChange(): void {
    this.intensities.forEach((intensity) => {
      this.filters.intensities.checked[intensity.value] = this.filters.intensities.allChecked;
    });
  }

  public onIntensitiesCheckboxChange(): void {
    const allChecked = this.intensities.every((intensity) => {
      return this.filters.intensities.checked[intensity.value];
    });
    this.filters.intensities.allChecked = allChecked;
  }

  public onClearFiltersClick(): void {
    // Reset subcategories
    this.filters.type.allChecked = false;
    Object.keys(this.filters.type.checked).forEach((key) => {
      this.filters.type.checked[key] = false;
    });
    this.filters.type.selectedTypes = [];

    // Reset languages
    this.filters.languages.allChecked = false;
    Object.keys(this.filters.languages.checked).forEach((key) => {
      this.filters.languages.checked[key] = false;
    });

    // Reset intensities
    this.filters.intensities.allChecked = false;
    Object.keys(this.filters.intensities.checked).forEach((key) => {
      this.filters.intensities.checked[key] = false;
    });

    // Reset coaches
    this.filters.coaches.allChecked = false;
    this.filters.coaches.selectedCoaches = [];
    Object.keys(this.filters.coaches.checked).forEach((key) => {
      this.filters.coaches.checked[key] = false;
    });
    this.modalService.setDismissReason(OnDemandVideoFilterModalComponent.CLEAR_FILTERS);
    this.bsModalRef.hide();
  }

  public onShowFiltersClick(): void {
    this.onDataSelect.next(this.filters);
    this.modalService.setDismissReason(OnDemandVideoFilterModalComponent.FILTER_DATA);

    this.bsModalRef.hide();
  }

  public onHideFiltersClick(): void {
    this.bsModalRef.hide();
  }

  private prepareSubcategories(): void {
    if (!this.videoCategory) {
      this.subcategories = this.viewUtil.createEnumSelectOptions(VideoSubcategories, 'VIDEO_SUBCATEGORY');
      return;
    }
    switch (this.videoCategory) {
      case VideoCategory.EDUCATION: {
        this.subcategories = this.viewUtil.createEnumSelectOptions(VideoEducationSubcategory, 'VIDEO_SUBCATEGORY');
        break;
      }
      case VideoCategory.FITNESS: {
        this.subcategories = this.viewUtil.createEnumSelectOptions(VideoFitnessSubcategory, 'VIDEO_SUBCATEGORY');
        break;
      }
      case VideoCategory.HOBBIES: {
        this.subcategories = this.viewUtil.createEnumSelectOptions(VideoHobbiesSubcategory, 'VIDEO_SUBCATEGORY');
        break;
      }
      case VideoCategory.LIVE_RECORDINGS: {
        this.subcategories = this.viewUtil.createEnumSelectOptions(VideoLiveRecordingsSubcategory, 'VIDEO_SUBCATEGORY');
        break;
      }
      case VideoCategory.MIND_AND_BODY: {
        this.subcategories = this.viewUtil.createEnumSelectOptions(VideoMindAndBodySubcategory, 'VIDEO_SUBCATEGORY');
        break;
      }
    }
  }

  private getCoachesList(): void {
    this.onDemandVideoModel.getActiveCoachList().subscribe((coaches) => {
      this.coaches = coaches;
    });
  }
}
