import { Injectable, Injector, NgZone, OnDestroy } from '@angular/core';
import { Event } from 'app/common/Event';
import { ApplicationConfig } from 'app/config/ApplicationConfig';
import { SecurityDataDTO } from 'app/data/dto/auth/SecurityDataDTO';
import { AuthMode } from 'app/data/local/AuthMode';
import { Token } from 'app/data/local/auth/Token';
import { CognitoAuthService } from 'app/service/CognitoAuthService';
import { CustomOAuth2Service } from 'app/service/CustomOAuth2Service';
import { AuthServiceInterface } from 'app/service/interface/AuthServiceInterface';
import { EventManager } from 'app/util/other/EventManager';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Duration } from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthModel implements OnDestroy {
  private static readonly TOKEN_REFRESH_INTERVAL: Duration = moment.duration('1', 'minutes');
  private static readonly SESSION_TIMEOUT: Duration = moment.duration('24', 'hours');

  // public token: Token; (as getter/setter below)
  public token$: BehaviorSubject<Token> = new BehaviorSubject<Token>(null);

  // public securityData: SecurityDataDTO; (as getter/setter below)
  public securityData$: BehaviorSubject<SecurityDataDTO> = new BehaviorSubject<SecurityDataDTO>(null);

  // public isLoggedIn: boolean; (as getter/setter below)
  public isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private rememberLogin: boolean = true;
  private timeoutSession: boolean = true;
  private tokenRefreshIntervalId: ReturnType<typeof setInterval>;
  private sessionTimeoutId: ReturnType<typeof setTimeout>;

  private authService: AuthServiceInterface; // injected in constructor body

  constructor(
    private eventManager: EventManager,
    private zone: NgZone,
    private injector: Injector
  ) {
    this.setupService();
    this.setupListeners();

    this.authService.setRememberLogin(this.rememberLogin);
  }

  public get token(): Token {
    return this.token$.value;
  }

  public set token(value: Token) {
    this.token$.next(value);
  }

  public get securityData(): SecurityDataDTO {
    return this.securityData$.value;
  }

  public set securityData(value: SecurityDataDTO) {
    this.securityData$.next(value);
  }

  public get isLoggedIn(): boolean {
    return this.isLoggedIn$.value;
  }

  public set isLoggedIn(value: boolean) {
    this.isLoggedIn$.next(value);
  }

  public ngOnDestroy(): void {
    this.cancelTokenRefreshSchedule();
    this.cancelSessionTimeout();
  }

  private setupService(): void {
    if (ApplicationConfig.authMode === AuthMode.CUSTOM_OAUTH2) {
      this.authService = this.injector.get(CustomOAuth2Service);
    }
    else if (ApplicationConfig.authMode === AuthMode.COGNITO) {
      this.authService = this.injector.get(CognitoAuthService);
    }
  }

  private setupListeners(): void {
    this.eventManager.on(Event.USER.SECURITY_DATA, (result: SecurityDataDTO) => {
      if (!_.isUndefined(result)) {
        this.securityData = result;
      }
    });

    this.eventManager.on(Event.AUTH.ERROR.UNAUTHORIZED, (result) => {
      // this needs to be set up right away without any delay, as it is checked in Application, during routing
      // which would also happen in response to this event
      this.isLoggedIn = false;

      // we don't care about the result of the deleteToken operation at this point, we're in error state anyway
      this.deleteToken().finally(() => {
        this.token = null;
        this.securityData = null;
        this.cancelTokenRefreshSchedule();
      });
    });
  }

  public login(username: string, password: string, silent: boolean = false): Promise<Token> {
    return new Promise((resolve, reject) => {
      this.toggleLoader(true);
      this.authService
        .login(username, password)
        .toPromise()
        .then((result: { token: Token; securityData: SecurityDataDTO }) => {
          this.setToken(result.token)
            .then((savedToken: Token) => {
              this.token = result.token;
              if (!_.isUndefined(result.securityData)) {
                this.securityData = result.securityData;
              }
              this.isLoggedIn = true;

              if (!silent) {
                this.eventManager.broadcast(Event.AUTH.LOGIN.SUCCESS, this.token);
              }

              // token can be null in certain cases (Cognito "temp password login")
              // so the user in this case is logged in only "barely", will be asked to relogin again
              if (!_.isNil(this.token)) {
                this.scheduleTokenRefresh(this.token);
              }

              if (this.timeoutSession) {
                this.setSessionTimeout();
              }

              this.toggleLoader(false);

              resolve(this.token);
            })
            .catch((error) => {
              // shouldn't happen really, but we're covering for safety
              this.token = null;
              this.securityData = null;
              this.isLoggedIn = false;

              if (!silent) {
                this.eventManager.broadcast(Event.AUTH.LOGIN.ERROR);
              }

              this.toggleLoader(false);

              reject(error);
            });
        })
        .catch((error) => {
          this.token = null;
          this.securityData = null;
          this.isLoggedIn = false;

          if (!silent) {
            this.eventManager.broadcast(Event.AUTH.LOGIN.ERROR);
          }

          this.toggleLoader(false);

          reject(error);
        });
    });
  }

  public logout(silent: boolean = false): Promise<void> {
    return new Promise((resolve, reject) => {
      this.toggleLoader(true);
      this.authService
        .logout()
        .toPromise()
        .then(() => {
          this.deleteToken()
            .then(() => {
              this.token = null;
              this.securityData = null;
              this.isLoggedIn = false;

              this.cancelTokenRefreshSchedule();

              if (this.timeoutSession) {
                this.cancelSessionTimeout();
              }

              if (!silent) {
                this.eventManager.broadcast(Event.AUTH.LOGOUT.SUCCESS);
              }

              this.toggleLoader(false);

              resolve();
            })
            .catch((error) => {
              // shouldn't happen really, but we're covering for safety
              // we need to wipe the state anyway, even if there's an error
              this.token = null;
              this.securityData = null;
              this.isLoggedIn = false;

              this.cancelTokenRefreshSchedule();

              if (this.timeoutSession) {
                this.cancelSessionTimeout();
              }

              if (!silent) {
                this.eventManager.broadcast(Event.AUTH.LOGOUT.ERROR);
              }

              this.toggleLoader(false);

              reject(error);
            });
        })
        .catch((error) => {
          // we need to wipe the state anyway, even if there's an error
          // we don't care about the result of the deleteToken operation at this point, we're in error state anyway
          this.deleteToken().finally(() => {
            this.token = null;
            this.securityData = null;
            this.isLoggedIn = false;

            this.cancelTokenRefreshSchedule();

            if (!silent) {
              this.eventManager.broadcast(Event.AUTH.LOGOUT.ERROR);
            }

            this.toggleLoader(false);

            reject(error);
          });
        });
    });
  }

  public refresh(token: Token): Promise<Token> {
    return new Promise((resolve, reject) => {
      this.toggleLoader(true);
      this.authService
        .refresh(token)
        .toPromise()
        .then((result: { token: Token; securityData: SecurityDataDTO }) => {
          this.setToken(result.token)
            .then((savedToken: Token) => {
              this.token = result.token;
              if (!_.isUndefined(result.securityData)) {
                this.securityData = result.securityData;
              }

              this.eventManager.broadcast(Event.AUTH.TOKEN_REFRESH, this.token);

              this.rescheduleRefresh(this.token);

              this.toggleLoader(false);

              resolve(this.token);
            })
            .catch((error) => {
              this.toggleLoader(false);
              reject(error);
            });
        })
        .catch((error) => {
          this.toggleLoader(false);
          reject(error);
        });
    });
  }

  public recoverToken(): Promise<Token> {
    return new Promise<Token>((resolve, reject) => {
      this.getToken()
        .then((token: Token) => {
          if (_.isNil(token)) {
            this.isLoggedIn = false;
            reject();
          }
          else {
            if (token.isExpired() || token.isNearlyExpired()) {
              this.toggleLoader(true);
              this.refresh(token)
                .then((refreshedToken: Token) => {
                  this.isLoggedIn = true;

                  if (this.timeoutSession) {
                    this.setSessionTimeout();
                  }

                  this.toggleLoader(false);

                  resolve(refreshedToken);
                })
                .catch((error) => {
                  this.isLoggedIn = false;
                  this.toggleLoader(false);
                  reject(error);
                });
            }
            else {
              this.token = token;
              this.isLoggedIn = true;

              this.scheduleTokenRefresh(token);

              if (this.timeoutSession) {
                this.setSessionTimeout();
              }

              resolve(token);
            }
          }
        })
        .catch((error) => {
          this.isLoggedIn = false;
          reject(error);
        });
    });
  }

  public startPasswordReset(username: string): Promise<void> {
    this.toggleLoader(true);
    return this.authService
      .startPasswordReset(username)
      .toPromise()
      .then(() => {
        this.toggleLoader(false);
        return;
      })
      .catch((error) => {
        this.toggleLoader(false);
        throw error;
      });
  }

  public completePasswordReset(username: string, verificationCode: string, newPassword: string): Promise<void> {
    this.toggleLoader(true);
    return this.authService
      .completePasswordReset(username, verificationCode, newPassword)
      .toPromise()
      .then(() => {
        this.toggleLoader(false);
        return;
      })
      .catch((error) => {
        this.toggleLoader(false);
        throw error;
      });
  }

  public changePassword(currentPassword: string, newPassword: string): Promise<void> {
    this.toggleLoader(true);
    return this.authService
      .changePassword(currentPassword, newPassword)
      .toPromise()
      .then(() => {
        this.toggleLoader(false);
        return;
      })
      .catch((error) => {
        this.toggleLoader(false);
        throw error;
      });
  }

  public changePasswordForced(newPassword: string): Promise<void> {
    this.toggleLoader(true);
    return this.authService
      .changePasswordForced(newPassword)
      .toPromise()
      .then(() => {
        this.toggleLoader(false);
        if (this.securityData) {
          this.securityData.requireNewPassword = false;
        }
        return;
      })
      .catch((error) => {
        this.toggleLoader(false);
        throw error;
      });
  }

  public setRememberLogin(remember: boolean): Observable<void> {
    return this.authService.setRememberLogin(remember);
  }

  // --

  // run outside of zone, since it contains setInterval, which would delay stability of ApplicationRef
  // this is executed from AuthModel.recoverToken, which is called upon during application initialization (Application.configureTransitions)
  // so we need to a) keep it outside the zone, so that we don't threat stability at that point of time (initialization), but, at the same time
  // b) keep the results of each interval tick inside the zone anyway, so that it will properly trigger change detection (which is needed here)
  // cannot use standard ChangeDetectorRef.detectChanges, as we're outside of component tree scope and injector couldn't get it here
  // (this whole thing is important for HMR among other things)
  private scheduleTokenRefresh(token: Token): void {
    this.zone.runOutsideAngular(() => {
      this.tokenRefreshIntervalId = setInterval(() => {
        this.zone.run(() => {
          if (this.token.isNearlyExpired()) {
            this.refresh(this.token)
              .then((refreshedToken: Token) => {
              })
              .catch((error) => {
              });
          }
        });
      }, AuthModel.TOKEN_REFRESH_INTERVAL.as('milliseconds'));
    });
  }

  private cancelTokenRefreshSchedule(): void {
    if (this.tokenRefreshIntervalId) {
      clearInterval(this.tokenRefreshIntervalId);
      this.tokenRefreshIntervalId = null;
    }
  }

  private rescheduleRefresh(token: Token): void {
    this.cancelTokenRefreshSchedule();
    this.scheduleTokenRefresh(token);
  }

  // run outside of zone, since it contains setInterval, which would delay stability of ApplicationRef
  // this is executed from AuthModel.recoverToken, which is called upon during application initialization (Application.configureTransitions)
  // so we need to a) keep it outside the zone, so that we don't threat stability at that point of time (initialization), but, at the same time
  // b) keep the results of the timeout inside the zone anyway, so that it will properly trigger change detection (which is needed here)
  // cannot use standard ChangeDetectorRef.detectChanges, as we're outside of component tree scope and injector couldn't get it here
  // (this whole thing is important for HMR among other things)
  private setSessionTimeout(): void {
    this.zone.runOutsideAngular(() => {
      this.sessionTimeoutId = setTimeout(() => {
        this.zone.run(() => {
          this.eventManager.broadcast(Event.AUTH.SESSION_TIMEOUT);
          this.sessionTimeoutId = null;
        });
      }, AuthModel.SESSION_TIMEOUT.as('milliseconds'));
    });
  }

  private cancelSessionTimeout(): void {
    if (this.sessionTimeoutId) {
      clearTimeout(this.sessionTimeoutId);
      this.sessionTimeoutId = null;
    }
  }

  public resetSessionTimeout(): void {
    this.cancelSessionTimeout();
    this.setSessionTimeout();
  }

  private toggleLoader(on: boolean): void {
    if (ApplicationConfig.authMode === AuthMode.CUSTOM_OAUTH2) {
      // nothing to be done, this will be handled by LoadingInterceptor
    }
    else if (ApplicationConfig.authMode === AuthMode.COGNITO) {
      this.eventManager.broadcast(Event.SYSTEM.LOADING, on);
    }
  }

  private setToken(token: Token): Promise<Token> {
    return this.authService.setToken(token).toPromise();
  }

  private getToken(): Promise<Token> {
    return this.authService.getToken().toPromise();
  }

  private deleteToken(): Promise<void> {
    return this.authService.deleteToken().toPromise();
  }
}
