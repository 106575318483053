import { SortDirection } from 'app/data/enum/SortDirection';
import * as _ from 'lodash';

export class BaseCriteriaDTO {

  public sortOrders: BaseCriteriaSortOrder[];

  public searchPhrase: string;

  public excludedIds: number[];

  public applySortOrders(sortOrders: { path: string, direction: SortDirection }[]): void {
    if (!this.sortOrders) {
      this.sortOrders = [];
    }

    _.forEach(sortOrders, (sortOrder: { path: string, direction: SortDirection }) => {
      this.sortOrders.push(`${ sortOrder.path } ${ sortOrder.direction ?? SortDirection.ASCENDING }`);
    });
  }

  public extractSortOrders(): { path: string, direction: SortDirection }[] {
    if (!this.sortOrders) {
      return null;
    }

    let result: { path: string, direction: SortDirection }[] = [];

    _.forEach(this.sortOrders, (sortOrder: BaseCriteriaSortOrder) => {
      const sortOrderSplit: string[] = sortOrder.split(' ');
      result.push({
        path: sortOrderSplit[0],
        direction: sortOrderSplit[1] === 'asc' ? SortDirection.ASCENDING : SortDirection.DESCENDING
      });
    });

    return result;
  }

}

export type BaseCriteriaSortOrder = `${ string } ${ SortDirection.ASCENDING | SortDirection.DESCENDING }`;
