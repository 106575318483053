import { Type } from 'class-transformer';
import { ScheduledLiveClassRatingCoachDTO } from 'app/data/dto/scheduledLiveClass/coach/ScheduledLiveClassRatingCoachDTO';
import { VideoRatingCoachDTO } from 'app/data/dto/onDemandVideo/coach/VideoRatingCoachDTO';

export class CoachRecentRatingsResponseDTO {
  @Type(() => ScheduledLiveClassRatingCoachDTO)
  public scheduledLiveClassRatings: ScheduledLiveClassRatingCoachDTO[];

  @Type(() => VideoRatingCoachDTO)
  public videoRatings: VideoRatingCoachDTO[];
}