import { LiveClassCategory } from 'app/data/enum/liveClass/LiveClassCategory';
import { VideoCategory } from 'app/data/enum/video/VideoCategory';
import { BadgeType } from 'app/data/enum/badge/BadgeType';
import { BadgeTier } from 'app/data/enum/badge/BadgeTier';
import { IdentifiableObjectDTO } from 'app/data/dto/IdentifiableObjectDTO';
import { BadgeKind } from 'app/data/enum/badge/BadgeKind';
import { Exclude } from 'class-transformer';

export class BadgeResponseDTO extends IdentifiableObjectDTO<number> {
  public duration?: number;

  public liveClassSeriesCategories?: LiveClassCategory[];

  public longDescription: string;

  public multiple?: number;

  public shortDescription: string;

  public liveClassCategory: string;

  public tier?: BadgeTier;

  public title: BadgeKind;

  public type: BadgeType;

  public videoCategory?: VideoCategory;

  @Exclude()
  public get iconPath(): string {
    const dir: string = 'asset/image/badge/';
    const ext: string = '.svg';
    const filename: string[] = [];

    filename.push(this.title.toLowerCase().replace(/_/g, '-'));

    if (this.multiple) {
      filename.push(this.multiple.toString());
    }

    if (this.tier) {
      filename.push(this.tier.toLowerCase());
    }

    return dir + filename.join('-') + ext;
  }
}