import { Component, OnInit } from '@angular/core';
import Player from '@vimeo/player';
import { environment } from 'environment/environment';
import { StateUtil } from 'app/util/StateUtil';
import { SubscriptionModel } from 'app/model/SubscriptionModel';

@Component({
  selector: 'app-how-does-it-work',
  templateUrl: './HowDoesItWorkComponent.html',
  styleUrls: [ './HowDoesItWorkComponent.scss' ]
})
export class HowDoesItWorkComponent implements OnInit {
  private player: Player;

  constructor(private stateUtil: StateUtil,
              private readonly membershipModel: SubscriptionModel) {
  }

  ngOnInit(): void {
    this.setupVimeoPlayer();
  }

  public goToDashboard(): void {
    this.stateUtil.goToDashboard();
    this.membershipModel.openMembershipPlanModalIfNeeded();
  }

  private setupVimeoPlayer(): void {
    const options: Player.Options = {
      id: environment.tutorialId,
      loop: true,
      responsive: true,
      byline: false,
      portrait: false,
      title: false,
      autoplay: true
    };

    this.player = new Player('vimeo-player', options);
  }
}
