import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { FAQItem } from 'app/data/local/FAQItem';

@Component({
  selector: 'app-help-faqitem',
  templateUrl: './HelpFAQItemComponent.html',
  styleUrls: [ './HelpFAQItemComponent.scss' ],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('0.5s ease-out', style({ height: '*', opacity: '*' }))
      ]),
      transition(':leave', [
        style({ height: '*', opacity: '*' }),
        animate('0.5s ease-in', style({ height: 0, opacity: 0 }))
      ])
    ])
  ]
})
export class HelpFAQItemComponent {
  @Input()
  public question: FAQItem;

  public showAnswer: boolean = false;
}
