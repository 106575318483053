import { VideoCategory } from 'app/data/enum/video/VideoCategory';
import { Transform, Type } from 'class-transformer';
import { DateUtil } from 'app/util/DateUtil';
import { ImageResponseDTO } from '../images/ImageResponseDTO';
import { CoachSeniorDetailsPageDTO } from 'app/data/dto/liveClassSeries/senior/CoachSeniorDetailsPageDTO';
import { IdentifiableObjectDTO } from 'app/data/dto/IdentifiableObjectDTO';
import { IntensityLevel } from 'app/data/enum/IntensityLevel';

export class ProgramSeniorDetailsPageDTO extends IdentifiableObjectDTO<number> {
  public category: VideoCategory;

  @Type(() => CoachSeniorDetailsPageDTO)
  public coaches: CoachSeniorDetailsPageDTO[];

  @Transform(DateUtil.dateTimeConversion)
  public createdDate: Date;

  public intensity: IntensityLevel;

  public description: string;

  public isFavorite: boolean;

  @Type(() => ImageResponseDTO)
  public thumbnail: ImageResponseDTO;

  public title: string;

  public videoCount: number;

  public viewCount: number;

  public rating: number;
}
