export class UserCoachProfileRequestDTO {
  public email: string;

  public enabled: boolean;

  public firstName: string;

  public imageId?: number;

  public lastName: string;

  public phone: string;
}