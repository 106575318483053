<form #form="ngForm">
  <div id="files" class="d-flex">
    <ng-container *ngIf="thumbnailMode">
      <div class="mr-4 mb-4">
        <div class="wrapper">
          <label for="thumbnailInput">{{ thumbnailPlaceholder }}</label>
          <div
            id="upload-thumbnail"
            class="position-relative border"
            [ngStyle]="thumbnailUrl ? {
             'background-image': 'url(' + thumbnailUrl + ')',
             'background-size': 'cover',
             'background-repeat': 'no-repeat'
            } : {}"
            [ngClass]="{'border-danger': (form.submitted || formSubmitted) && thumbnailInput.invalid, 'border-0': (form.submitted || formSubmitted ) && thumbnailInput.valid}"
          >
            <!-- thumbnail upload -->
            <input
              type="file"
              #thumbnailInput="ngModel"
              id="thumbnailInput"
              name="thumbnailInput"
              class="custom-file-input"
              [ngModel]="uploadThumbnail.file"
              (ngModelChange)="onThumbnailChange($event)"
              accept="image/*"
              [appFileAllowedExtensions]="allowedExtensionsThumbnail"
              [appFileMaxSize]="maxSize"
              [required]="isThumbnailRequired && !uploadThumbnail.file"
            />
            <button
              type="button"
              class="btn btn-outline-primary px-4 py-6 d-flex align-items-center justify-content-center"
            >
              <svg-icon class="px-1 mr-2" src="asset/image/icon/upload.svg"></svg-icon>
              <span> {{ 'COMPONENT.FILE_UPLOAD.UPLOAD' | translate }}</span>
            </button>
            <span *ngIf="uploadThumbnailStarted" class="loader position-absolute"></span>
          </div>
          <div
            class="invalid-feedback d-block text-center text-decoration-none d-flex align-items-center"
            *ngIf="(formSubmitted || thumbnailInputSubmitted) && thumbnailInput.invalid"
          >
            <svg-icon class="mr-2 fill-danger" src="asset/image/icon/alert.svg"></svg-icon>
            <div *ngIf="thumbnailInput.errors?.required">{{ 'VALIDATION.REQUIRED' | translate }}</div>
            <div *ngIf="thumbnailInput.errors?.fileMaxSize">
              {{
                'VALIDATION.FILE_SIZE_MAX' | translate:{
                  maxSizeString: (thumbnailInput.errors.fileMaxSize['requiredSize'] | filesize)
                }
              }}
            </div>
            <div *ngIf="thumbnailInput.errors?.fileAllowedExtensions">
              {{
                'VALIDATION.FILE_ALLOWED_EXTENSIONS' | translate:{
                  extensions:
                    thumbnailInput.errors.fileAllowedExtensions['allowedExtensions']
                }
              }}
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="attachmentMode">
      <div class="flex-grow-1">
        <div>
          <label>{{ 'COMPONENT.FILE_UPLOAD.UPLOAD_AND_ATTACH_FILES' | translate }}</label>
          <!-- START upload and attach files -->
          <div id="upload-file" class="p-3">
            <input
              type="file"
              #fileModel="ngModel"
              id="fileModel"
              name="fileModel"
              class="custom-file-input"
              [(ngModel)]="pendingUploadAttachment.file"
              (ngModelChange)="onAttachmentChange($event)"
              [appFileAllowedExtensions]="allowedExtensionsAttachment"
              [appFileMaxSize]="maxSize"
            />
            <div class="d-flex align-items-center gap-2">
              <div class="file-icon">
                <svg-icon src="asset/image/icon/file-upload 1.svg"></svg-icon>
              </div>
              <div class="d-flex flex-column align-items-center align-items-sm-start">
                <div class="underline">{{ 'COMPONENT.FILE_UPLOAD.CLICK_OR_DROP' | translate }}</div>
                <div class="max-size">{{ 'COMPONENT.FILE_UPLOAD.MAX_SIZE' | translate }} {{ 50 }} MB</div>
              </div>
            </div>

            <div class="invalid-feedback d-block" *ngIf="attachmentSubmitted && fileModel.invalid">
              <div *ngIf="fileModel.errors.fileMaxSize">
                {{
                  'VALIDATION.FILE_SIZE_MAX' | translate:{
                    maxSizeString: (fileModel.errors.fileMaxSize['requiredSize'] | filesize)
                  }
                }}
              </div>
              <div *ngIf="fileModel.errors?.fileAllowedExtensions">
                {{
                  'VALIDATION.FILE_ALLOWED_EXTENSIONS' | translate:{
                    extensions:
                      fileModel.errors.fileAllowedExtensions['allowedExtensions']
                  }
                }}
              </div>
            </div>
            <span *ngIf="uploadAttachmentStarted" class="loader position-absolute"></span>
          </div>
          <!-- END upload and attach files -->
        </div>
        <div *ngFor="let file of uploadedAttachments" class="file-card p-3 mt-4">
          <div class="d-flex mb-2 align-items-center">
            <svg-icon src="asset/image/icon/file-upload 1.svg"></svg-icon>
            <div class="d-flex flex-column ml-4">
              <div *ngIf="(file.file | isFile) as fileInstance">{{ fileInstance.name }}</div>
              <div *ngIf="(file.file | isFile) as fileInstance">{{ fileInstance.size| filesize }}</div>
            </div>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <!-- <div class="d-flex w-100 position-relative">
                <div class="file-progress" [style.width]="70 + '%'"></div>
                <div class="file-progress-track"></div>
              </div> -->
            <!-- <div>{{70}}%</div> -->
          </div>
          <button type="button" class="delete-btn" (click)="removeAttachment(file.id)">
            <svg-icon src="asset/image/icon/x.svg"></svg-icon>
          </button>
        </div>
        <div *ngFor="let file of attachments" class="file-card p-3 mt-4">
          <div class="d-flex mb-2 align-items-center">
            <svg-icon src="asset/image/icon/file-upload 1.svg"></svg-icon>
            <div class="d-flex flex-column ml-4">
              <div>{{ file.title }}</div>
              <div>{{ file.asset.size | filesize }}</div>
            </div>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <!-- <div class="d-flex w-100 position-relative">
                <div class="file-progress" [style.width]="70 + '%'"></div>
                <div class="file-progress-track"></div>
              </div> -->
            <!-- <div>{{70}}%</div> -->
          </div>
          <button type="button" class="delete-btn" (click)="removeAttachmentEdit(file.id)">
            <svg-icon src="asset/image/icon/x.svg"></svg-icon>
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</form>
