import { FileUtil } from 'app/util/FileUtil';
import { Transform } from 'class-transformer';

export class VideoUploadResponseDTO {
  public embedUrl: string;

  public id: number;

  @Transform(FileUtil.base64Conversion)
  public uploadUrl?: string; //base64 encoded
}