<div class="d-flex flex-wrap gap-2">
  <ng-container *ngFor="let data of collection">
    <ng-container *ngFor="let value of data.value">
      <button type="button"
              class="btn btn-sm btn-primary app-filters-label p-2"
              (click)="removeFilter(data.key, value)">
        <div class="d-flex flex-row align-items-center gap-2">
          <span>{{ data.label | translate }}:</span>
          <span>{{ getLabelForKeyValue(data.key, value) | translate }}</span>
          <svg-icon src="asset/image/icon/x.svg"></svg-icon>
        </div>
      </button>
    </ng-container>
  </ng-container>
</div>
