import { Injectable, Injector, isDevMode, OnDestroy } from '@angular/core';
import { Event } from 'app/common/Event';
import { ApplicationConfig } from 'app/config/ApplicationConfig';
import { ApplicationErrorDTO } from 'app/data/dto/ApplicationErrorDTO';
import { ApplicationState } from 'app/data/local/ApplicationState';
import { LanguageVersion } from 'app/data/local/LanguageVersion';
import { SideBarItem } from 'app/data/local/ui/SideBarItem';
import { ApplicationService } from 'app/service/ApplicationService';
import { ApplicationServiceInterface } from 'app/service/interface/ApplicationServiceInterface';
import { LangUtil } from 'app/util/LangUtil';
import { EventManager } from 'app/util/other/EventManager';
import { LookupResponse } from 'ipdata';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { IpDataCoService } from '../service/IpDataCoService';
import { PlatformDataRequestDTO } from 'app/data/dto/PlatformDataRequestDTO';
import { Platform } from '@angular/cdk/platform';
import { PlatformType } from 'app/data/enum/PlatformType';
import { DeviceType } from 'app/data/enum/DeviceType';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Injectable({ providedIn: 'root' })
export class ApplicationModel implements OnDestroy {
  public devMode: boolean = isDevMode();

  public suppressLoading: boolean = false;
  public suppressErrors: boolean = false;

  public stateBeforeLogin: ApplicationState;
  public goingToPreviousState: boolean = false;
  public goingToTransientState: boolean = false;
  public resolvingUnauthorizedState: boolean = false;
  public stateHistory: ApplicationState[] = [];
  public currentUrl: string;

  public ipData: LookupResponse;

  public platform: PlatformDataRequestDTO = new PlatformDataRequestDTO();

  // public currentState: ApplicationState; (as getter/setter below)
  public currentState$: BehaviorSubject<ApplicationState> = new BehaviorSubject<ApplicationState>(undefined);
  // public isLoading: boolean; (as getter/setter below)
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  // public sideBarItems: SideBarItem[]; (as getter/setter below)
  public sideBarItems$: BehaviorSubject<SideBarItem[]> = new BehaviorSubject<SideBarItem[]>([]);
  // public language: LanguageVersion; (as getter/setter below)
  public language$: BehaviorSubject<LanguageVersion> = new BehaviorSubject<LanguageVersion>(null);

  public lastApplicationError: ApplicationErrorDTO;
  public sendLastApplicationErrorToServer: boolean = true;

  private applicationService: ApplicationServiceInterface; // injected in constructor body

  constructor(private eventManager: EventManager,
              private langUtil: LangUtil,
              private injector: Injector,
              private ipDataCoService: IpDataCoService,
              private cdkPlatform: Platform,
              private cdkBreakpointObserver: BreakpointObserver
  ) {
    this.setupService();
    this.setupListeners();
  }

  public get currentState(): ApplicationState {
    return this.currentState$.value;
  }

  public set currentState(value: ApplicationState) {
    this.currentState$.next(value);
  }

  public get isLoading(): boolean {
    return this.isLoading$.value;
  }

  public set isLoading(value: boolean) {
    this.isLoading$.next(value);
  }

  public get sideBarItems(): SideBarItem[] {
    return this.sideBarItems$.value;
  }

  public set sideBarItems(value: SideBarItem[]) {
    this.sideBarItems$.next(value);
  }

  public get language(): LanguageVersion {
    return this.language$.value;
  }

  public set language(value: LanguageVersion) {
    this.language$.next(value);
  }

  public get versionNumberString(): string {
    return ApplicationConfig.version.substring(0, ApplicationConfig.version.lastIndexOf(' '));
  }

  public ngOnDestroy(): void {
    this.isLoading$.complete();
    this.sideBarItems$.complete();
    this.language$.complete();
  }

  private setupService(): void {
    this.applicationService = this.injector.get(ApplicationService);
  }

  private setupListeners(): void {
    this.eventManager.on(Event.AUTH.LOGOUT.SUCCESS, () => {
      this.suppressLoading = false;
      this.suppressErrors = false;
      this.sideBarItems = [];
    });
    this.eventManager.on(Event.AUTH.ERROR.UNAUTHORIZED, () => {
      this.sideBarItems = [];
    });
    this.eventManager.on(Event.SYSTEM.SET_LANGUAGE, (language: LanguageVersion) => {
      this.setLanguage(language);
    });
  }

  public selectSideBarItemWithState(stateName?: string): void {
    const sideBarItems: SideBarItem[] = _.cloneDeep(this.sideBarItems);

    _.forEach(sideBarItems, (sideBarItem: SideBarItem) => {
      sideBarItem.selected = sideBarItem.stateName === stateName;
    });

    this.sideBarItems = sideBarItems;
  }

  public deselectSideBarItems(): void {
    const sideBarItems: SideBarItem[] = _.cloneDeep(this.sideBarItems);

    _.forEach(sideBarItems, (item: SideBarItem) => {
      item.selected = false;
    });

    this.sideBarItems = sideBarItems;
  }

  public setLanguage(language: LanguageVersion): void {
    if (!this.language) {
      this.langUtil.initialize();
    }

    this.langUtil.setLanguage(language);
    this.language = language;

    this.eventManager.broadcast(Event.SYSTEM.REDRAW);
  }

  public setPlatform(): any {
    if (this.cdkPlatform.ANDROID || this.cdkPlatform.IOS) {
      this.platform.platform = PlatformType.MOBILE;

      if (this.cdkBreakpointObserver.isMatched(Breakpoints.Tablet)) {
        this.platform.deviceUsed = this.cdkPlatform.IOS ? DeviceType.IPAD : DeviceType.ANDROID_TABLET;
      }
      else {
        this.platform.deviceUsed = this.cdkPlatform.IOS ? DeviceType.IPHONE : DeviceType.ANDROID_PHONE;
      }
    }
    else {
      this.platform.deviceUsed = DeviceType.DESKTOP;
      this.platform.platform = PlatformType.WEB;
    }
  }

  public sendApplicationError(applicationError: ApplicationErrorDTO): Promise<void> {
    this.lastApplicationError = applicationError;

    return this.applicationService
      .sendApplicationError(applicationError)
      .toPromise()
      .then(() => {
        return;
      })
      .catch((error) => {
        throw error;
      });
  }

  public getIpData(): Promise<LookupResponse> {
    if (this.ipData) {
      return new Promise((resolve, reject) => {
        resolve(this.ipData);
      });
    }
    else {
      return this.ipDataCoService
        .getIpData()
        .toPromise()
        .then((ipData: LookupResponse) => {
          this.ipData = ipData;

          return ipData;
        })
        .catch((error) => {
          throw error;
        });
    }
  }
}
