<app-content-and-image-column-layout [imageSrc]="'asset/image/register-background.jpg'">
  <div id="content" class="p-4 d-flex flex-column">
    <img class="logo" src="asset/image/logo.png" alt="Logo"/>

    <h1 class="mb-6">{{ 'VIEW.PRELIMINARY.LOGIN.CREATE_NEW_PASSWORD' | translate }}</h1>

    <form #resetPasswordForm="ngForm" class="row mb-6" novalidate (keyup.enter)="resetPassword()">
      <div class="form-group col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <label for="password">{{ 'VIEW.PRELIMINARY.REGISTRATION.PASSWORD' | translate }}</label>
        <input
          type="password"
          id="password"
          name="password"
          #password="ngModel"
          class="form-control"
          placeholder="{{ 'VIEW.PRELIMINARY.REGISTRATION.PASSWORD' | translate }}"
          [ngClass]="{'is-invalid': resetPasswordForm.submitted && password.invalid, 'is-valid': resetPasswordForm.submitted && password.valid }"
          [(ngModel)]="formData.password"
          appPasswordRequirements
          required
          [pattern]="Constant.PASSWORD_PATTERN"
          appInputPasswordToggle
        />
        <div class="invalid-feedback" *ngIf="resetPasswordForm.submitted && password.invalid">
          <div *ngIf="password.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
          <div *ngIf="password.errors['pattern']">
            {{ 'VALIDATION.PASSWORD' | translate: { passwordLength: Constant.PASSWORD_MIN_LENGTH } }}
          </div>
          <div *ngIf="password.errors?.passwordRequirements?.maxLength">
            {{ 'VALIDATION.PASSWORD_REQUIREMENTS.MAX_LENGTH' | translate: { max: Constant.PASSWORD_MAX_LENGTH_256 } }}
          </div>
        </div>
      </div>

      <div class="form-group col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <label for="passwordRepeat">{{ 'VIEW.PRELIMINARY.REGISTRATION.REPEAT_PASSWORD' | translate }}</label>
        <input
          type="password"
          id="passwordRepeat"
          name="passwordRepeat"
          #passwordRepeat="ngModel"
          class="form-control"
          placeholder="{{ 'VIEW.PRELIMINARY.REGISTRATION.REPEAT_PASSWORD' | translate }}"
          [ngClass]="{'is-invalid': resetPasswordForm.submitted && passwordRepeat.invalid, 'is-valid': resetPasswordForm.submitted && passwordRepeat.valid }"
          [(ngModel)]="formData.passwordRepeat"
          required
          [pattern]="Constant.PASSWORD_PATTERN"
          [equalTo]="password.control"
          appPasswordRequirements
          appInputPasswordToggle
        />
        <div class="invalid-feedback" *ngIf="resetPasswordForm.submitted && passwordRepeat.invalid">
          <div *ngIf="passwordRepeat.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
          <div *ngIf="passwordRepeat.errors['pattern']">
            {{ 'VALIDATION.PASSWORD' | translate: { passwordLength: Constant.PASSWORD_MIN_LENGTH } }}
          </div>
          <div *ngIf="passwordRepeat.errors['equalTo']">{{ 'VALIDATION.PASSWORD_MATCH' | translate }}</div>
          <div *ngIf="passwordRepeat.errors?.passwordRequirements?.maxLength">
            {{ 'VALIDATION.PASSWORD_REQUIREMENTS.MAX_LENGTH' | translate: { max: Constant.PASSWORD_MAX_LENGTH_256 } }}
          </div>
        </div>
      </div>

      <div class="password-requirements col-12">
        <div
          class="password-req-item"
          [ngClass]="{'is-invalid': !password.value || password.errors?.passwordRequirements?.minLength}"
        >
          {{ 'VALIDATION.PASSWORD_REQUIREMENTS.MIN_LENGTH' | translate: { min: Constant.PASSWORD_MIN_LENGTH } }}
        </div>
        <div
          class="password-req-item"
          [ngClass]="{'is-invalid': !password.value || password.errors?.passwordRequirements?.upperCase}"
        >
          {{ 'VALIDATION.PASSWORD_REQUIREMENTS.UPPERCASE' | translate }}
        </div>
        <div
          class="password-req-item"
          [ngClass]="{'is-invalid': !password.value || password.errors?.passwordRequirements?.lowerCase}"
        >
          {{ 'VALIDATION.PASSWORD_REQUIREMENTS.LOWERCASE' | translate }}
        </div>
        <div
          class="password-req-item"
          [ngClass]="{'is-invalid': !password.value || password.errors?.passwordRequirements?.numberChar}"
        >
          {{ 'VALIDATION.PASSWORD_REQUIREMENTS.NUMBER' | translate }}
        </div>
        <div
          class="password-req-item"
          [ngClass]="{'is-invalid': !password.value || password.errors?.passwordRequirements?.specialChar}"
        >
          {{ 'VALIDATION.PASSWORD_REQUIREMENTS.SPECIAL' | translate }}
        </div>
      </div>
    </form>

    <div class="d-flex justify-content-end mt-6 control-buttons">
      <button class="btn btn-light mr-2 step-btn" (click)="onBackClick()">{{ 'COMMON.BACK' | translate }}</button>
      <button class="btn btn-primary step-btn" (click)="resetPassword()">
        {{ 'VIEW.PRELIMINARY.LOGIN.CREATE' | translate }}
      </button>
    </div>
  </div>
</app-content-and-image-column-layout>
