import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { OrganizationListDTO } from 'app/data/dto/registration/OrganizationListDTO';
import { ViewUtil } from 'app/util/ViewUtil';
import { SubscriptionChangeRequestDTO } from 'app/data/dto/subscription/SubscriptionChangeRequestDTO';
import { SubscriptionType } from 'app/data/enum/subscription/SubscriptionType';
import { SubscriptionModel } from 'app/model/SubscriptionModel';
import { SeniorService } from 'app/service/SeniorService';

@Component({
  selector: 'app-account-subscription-change-partial',
  templateUrl: './AccountSubscriptionChangePartialComponent.html'
})
export class AccountSubscriptionChangePartialComponent {
  @Input() public editMode: boolean;
  @Output() public editModeChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public organizationId: number;
  public memberCode: string;

  public organizations$: Observable<OrganizationListDTO[]> = this.seniorService.getOrganizationsList();

  constructor(private readonly subscriptionModel: SubscriptionModel,
              private readonly viewUtil: ViewUtil,
              private readonly seniorService: SeniorService) { }

  public save(valid: boolean): void {
    if (valid) {
      const data: SubscriptionChangeRequestDTO = {
        subscriptionType: SubscriptionType.ORGANIZATION
      };

      if (this.organizationId !== -1) {
        data.organizationId = this.organizationId;
        data.memberCode = this.memberCode;
      }

      this.subscriptionModel.changeSubscriptionType(data)
        .subscribe({
          next: () => this.changeEditMode(false),
          error: (err) => this.viewUtil.handleServerError(err)
        });
    }
  }

  public changeEditMode(value: boolean): void {
    this.editMode = value;
    this.editModeChange.emit(value);
  }
}
