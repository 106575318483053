<ng-container *ngFor="let item of unratedItems">
  <div class="d-flex mb-4">
    <div class="mr-6">
      <app-avatar [src]="item.coaches[0].user.avatar?.defaultAsset?.url" [circle]="true" size="40px"></app-avatar>
    </div>
    <div>
      <p class="font-weight-600 mb-1">{{ item.title }}</p>
      <p class="font-size-sm mb-1">{{ item.coaches[0].user | fullName }}</p>
      <app-rating-stars (rate)="rateItem($event, item)" [displayOnly]="false"></app-rating-stars>
    </div>
  </div>
</ng-container>
