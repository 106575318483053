import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { VideoAdminDTO } from 'app/data/dto/onDemandVideo/admin/VideoAdminDTO';
import { VideoCoachDTO } from 'app/data/dto/onDemandVideo/coach/VideoCoachDTO';

@Component({
  selector: 'app-on-demand-videos-bulk-actions',
  templateUrl: './OnDemandVideosBulkActionsComponent.html',
  styleUrls: [ './OnDemandVideosBulkActionsComponent.scss' ]
})
export class OnDemandVideosBulkActionsComponent implements OnChanges {
  @Input()
  public selectedVideos: VideoAdminDTO[] | VideoCoachDTO[] = [];

  @Output()
  public deleteClicked: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public recommendedClick: EventEmitter<any> = new EventEmitter<any>();

  public videosText: string;
  public shouldMark: boolean = true;

  constructor(private translate: TranslateService) {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedVideos) {
      const translationKey =
        this.selectedVideos.length === 1 ? 'VIEW.MAIN.ON_DEMAND.VIDEO' : 'VIEW.MAIN.ON_DEMAND.VIDEOS';

      this.videosText = this.translate.instant(translationKey);
      this.shouldMark = this.selectedVideos.some((video) => !video.recommended);
    }
  }

  public onDeleteClick(): void {
    this.deleteClicked.emit();
  }

  public onMarkRecommendedClick(): void {
    this.recommendedClick.emit();
  }
}
