<div
  *ngIf="(userPreferences || seniorPreferences) && form"
  id="account-preferences"
  class="d-flex flex-column"
  [formGroup]="form"
  (keyup.enter)="saveChanges()"
>
  <div *ngIf="!embeddedMode" class="d-flex gap-4 flex-wrap justify-content-end mb-4 w-100">
    <button *ngIf="form.disabled" class="btn btn-primary edit-btn" (click)="editPreferences()">
      <div class="d-flex align-items-center justify-content-center">
        <svg-icon class="d-flex mr-2" src="asset/image/icon/edit.svg"></svg-icon>
        <span>{{ 'COMMON.EDIT' | translate }}</span>
      </div>
    </button>
    <button *ngIf="form.enabled" class="btn btn-outline-primary edit-btn" (click)="cancelEdit()">
      <div class="d-flex align-items-center justify-content-center">
        <svg-icon class="d-flex mr-2" src="asset/image/icon/chevron-left.svg"></svg-icon>
        <span>{{ 'COMMON.CANCEL' | translate }}</span>
      </div>
    </button>
    <button *ngIf="form.enabled" class="btn btn-primary edit-btn" (click)="saveChanges()">
      <div class="d-flex align-items-center justify-content-center">
        <svg-icon class="d-flex mr-2" src="asset/image/icon/edit.svg"></svg-icon>
        <span>{{ 'COMMON.SAVE' | translate }}</span>
      </div>
    </button>
  </div>

  <div *ngIf="!embeddedMode" class="form-group mb-4">
    <label for="timeZone">{{ 'VIEW.MAIN.ACCOUNT.PREFERENCES.TIMEZONE' | translate }}</label>
    <input
      id="timeZone"
      type="text"
      class="form-control"
      placeholder="{{'VIEW.MAIN.ACCOUNT.PREFERENCES.TIMEZONE' | translate}}"
      disabled
      [value]="userTimezone"
    />
  </div>

  <div class="preferences-form">
    <h4>{{ 'VIEW.MAIN.ACCOUNT.PREFERENCES.PHYSICALLY_ACTIVE' | translate }}</h4>
    <div class="d-flex flex-wrap options-group">
      <div *ngFor="let activity of possibleActivity; let i = index" class="custom-control custom-radio">
        <input
          type="radio"
          class="custom-control-input"
          [id]="'active' + i"
          [value]="activity"
          formControlName="seniorActivityFrequency"
        />
        <label class="custom-control-label" [for]="'active' + i">
          {{ ('VIEW.MAIN.ACCOUNT.PREFERENCES.' + activity) | translate }}
        </label>
      </div>
    </div>
    <h4>{{ 'VIEW.MAIN.ACCOUNT.PREFERENCES.INTERESTS' | translate }}</h4>
    <div class="d-flex flex-wrap options-group">
      <div *ngFor="let control of seniorInterestsControls let i = index" class="custom-control custom-checkbox">
        <input
          type="checkbox"
          class="custom-control-input"
          [id]="'interest' + i"
          [name]="'interest' + i"
          [formControl]="control"
        />
        <label class="custom-control-label" [for]="'interest' + i">
          {{ ('VIEW.MAIN.ACCOUNT.PREFERENCES.' + possibleInterests[i]) | translate }}
        </label>
      </div>
    </div>
    <h4>{{ 'VIEW.MAIN.ACCOUNT.PREFERENCES.HEALTH' | translate }}</h4>
    <div class="d-flex flex-wrap options-group">
      <div *ngFor="let health of possibleHealth; let i = index" class="custom-control custom-radio">
        <input
          type="radio"
          class="custom-control-input"
          [id]="'health' + i"
          [value]="health"
          formControlName="seniorHealth"
        />
        <label class="custom-control-label" [for]="'health' + i">
          {{ ('VIEW.MAIN.ACCOUNT.PREFERENCES.' + health) | translate }}
        </label>
      </div>
    </div>

    <h4>{{ 'VIEW.MAIN.ACCOUNT.PREFERENCES.NOTIFICATIONS' | translate }}</h4>
    <ng-container formGroupName="user">
      <div class="d-flex flex-wrap options-group">
        <div class="custom-control custom-checkbox">
          <input
            type="checkbox"
            class="custom-control-input"
            id="mailNotifications"
            formControlName="mailNotificationsEnabled"
          />
          <label class="custom-control-label" for="mailNotifications">
            {{ 'VIEW.MAIN.ACCOUNT.PREFERENCES.MAIL_NOTIFICATIONS' | translate }}
          </label>
          <div class="invalid-feedback"
               *ngIf="!embeddedMode && form.get('user.mailNotificationsEnabled').errors?.invalidMailNotification"
          >
            {{ 'VALIDATION.EMAIL_IS_REQUIRED_FOR_EMAIL_NOTIFICATIONS' | translate }}
          </div>
        </div>

        <div class="custom-control custom-checkbox">
          <input
            type="checkbox"
            class="custom-control-input"
            id="smsNotifications"
            formControlName="smsNotificationsEnabled"
            [ngClass]="{ 'is-invalid': !embeddedMode && form.get('user.smsNotificationsEnabled').errors?.invalidSmsNotification }"
          />
          <label class="custom-control-label" for="smsNotifications">
            {{ 'VIEW.MAIN.ACCOUNT.PREFERENCES.SMS_NOTIFICATIONS' | translate }}
          </label>
          <div class="invalid-feedback"
               *ngIf="!embeddedMode && form.get('user.smsNotificationsEnabled').errors?.invalidSmsNotification"
          >
            {{ 'VALIDATION.PHONE_IS_REQUIRED_FOR_SMS_NOTIFICATIONS' | translate }}
          </div>
        </div>
      </div>

      <ng-container *ngIf="embeddedMode">
        <div *ngIf="form.get('user.smsNotificationsEnabled').value" class="form-group">
          <label for="phone">{{ 'VIEW.MAIN.ACCOUNT.PREFERENCES.PHONE_NUMBER' | translate }}</label>
          <input
            type="text"
            id="phone"
            formControlName="phone"
            class="form-control"
            placeholder="{{ 'VIEW.MAIN.ACCOUNT.PREFERENCES.PHONE_NUMBER' | translate }}"
            [ngClass]="{ 'is-invalid': form.get('user.phone')?.errors }"
            [showMaskTyped]="true"
            [prefix]="Constant.PHONE_NUMBER_RULES.prefix + ' '"
            [shownMaskExpression]="Constant.PHONE_NUMBER_RULES.expression"
            [mask]="Constant.PHONE_NUMBER_RULES.mask"
          />
          <div class="invalid-feedback" *ngIf="form.get('user.phone')?.errors">
            <div *ngIf="form.get('user.phone').errors.pattern">{{ 'VALIDATION.PHONE' | translate }}</div>
            <div *ngIf="form.get('user.phone').errors.required">{{ 'VALIDATION.PHONE_IS_REQUIRED_FOR_SMS_NOTIFICATIONS' | translate }}</div>
          </div>
        </div>

        <div *ngIf="form.get('user.mailNotificationsEnabled').value" class="form-group">
          <label for="email">{{ 'VIEW.MAIN.ACCOUNT.PREFERENCES.EMAIL_ADDRESS' | translate }}</label>
          <input
            type="text"
            id="email"
            formControlName="email"
            class="form-control"
            placeholder="{{ 'VIEW.MAIN.ACCOUNT.PREFERENCES.EMAIL_ADDRESS' | translate }}"
            [ngClass]="{ 'is-invalid': form.get('user.email')?.errors }"
          />
          <div class="invalid-feedback" *ngIf="form.get('user.email')?.errors">
            <div *ngIf="form.get('user.email').errors.email">{{ 'VALIDATION.EMAIL' | translate }}</div>
            <div *ngIf="form.get('user.email').errors.required">{{ 'VALIDATION.EMAIL_IS_REQUIRED_FOR_EMAIL_NOTIFICATIONS' | translate }}</div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
