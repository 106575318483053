<app-data-grid
  *ngIf="page"
  [data]="page.content"
  [criteria]="criteria"
  [context]="{ host: this }"
  [showFilters]="true"
  [showPagination]="true"
  [itemTotal]="page.totalElements"
  [showItemsPerPage]="false"
  [showTotalItems]="false"
  (criteriaChanged)="onCriteriaChanged($event)"
  (gridAction)="onGridAction($event)"
  [pageTotal]="page.totalPages"
  [columns]="$any([
    { headerName: ('VIEW.MAIN.PROGRAMS.TITLE' | translate), field: 'title', minWidth: 150, cellClass: 'text-underline cell-fullwidth' },
    { headerName: ('VIEW.MAIN.PROGRAMS.VIDEOS' | translate), field: 'videoCount', width: 100, cellClass: 'd-none d-lg-inline-block' },
    { headerName: ('VIEW.MAIN.PROGRAMS.CATEGORY' | translate), field: 'category', width: 150, valueFormatter: enumCategoryFormatter },
    { headerName: ('VIEW.MAIN.PROGRAMS.COACH' | translate), field: 'coaches', width: 150, cellClass: 'text-right text-lg-left', valueFormatter: coachFormatter },
    { headerName: ('VIEW.MAIN.PROGRAMS.VIEWS' | translate), field: 'viewCount', width: 100 },
    { headerName: ('VIEW.MAIN.PROGRAMS.RATING' | translate), field: 'rating', minWidth: 140, cellClass: 'text-right text-lg-left', cellRendererFramework: StarRatingCellRendererComponent },
    { headerClass: 'header-right', width: 150, sortable: false, cellClass: 'cell-fullwidth', cellRendererFramework: ActionsCellRendererComponent }
  ])"
>
  <app-data-grid-filters>
    <div class="form-row space-y-3 space-y-md-0 mb-4 mb-lg-0">
      <div class="col-12 col-md-6 col-lg-auto">
        <ng-select
          id="sort-by"
          [placeholder]="'COMMON.SORT_BY' | translate"
          [searchable]="false"
          [style.--icon]="'url(/asset/image/icon/arrows-sort.svg)'"
          (change)="onSortOptionChange($event)"
        >
          <ng-option *ngFor="let sortType of sortByOptions" [value]="sortType.value">
            {{ sortType.label | translate }}
          </ng-option>
        </ng-select>
      </div> <!-- /.col -->

      <div class="col-12 col-md-6 col-lg-auto">
        <button
          id="filter-videos"
          name="filter-videos"
          class="btn btn-select d-flex justify-content-between align-items-center gap-2 w-100 w-lg-auto"
          (click)="openFiltersModal()"
        >
          <svg-icon class="text-green" src="asset/image/icon/filter.svg"></svg-icon>
          <span>{{ 'COMMON.FILTER_BY' | translate }}</span>
          <svg-icon src="asset/image/icon/chevron-down.svg"></svg-icon>
        </button>
      </div> <!-- /.col -->
    </div> <!-- /.form-row -->
  </app-data-grid-filters>
</app-data-grid>
