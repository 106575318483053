import { Type } from 'class-transformer';
import { IdentifiableObjectDTO } from '../../IdentifiableObjectDTO';
import { ImageResponseDTO } from '../../images/ImageResponseDTO';

export class VideoSeniorRecommendedDTO extends IdentifiableObjectDTO<number> {
  public description: string;

  public title: string;

  @Type(() => ImageResponseDTO)
  public thumbnail: ImageResponseDTO;
}