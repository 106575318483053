<ng-container>
  <div
    *ngIf="selectedItems?.length"
    id="selected-classes-bar"
    class="p-2 d-flex justify-content-between align-items-center mt-4"
  >
    <span class="ml-4"> {{ selectedItems.length }} {{ text }} {{ "COMMON.SELECTED" | translate }} </span>
    <div class="d-flex">
      <div>
        <button class="btn btn-primary" (click)="onDeleteClick()">
          <span class="d-flex flex-row justify-content-center align-items-center">
            <svg-icon class="d-flex stroke-white" src="asset/image/icon/trash-x.svg"></svg-icon>
            <span>{{ "COMMON.DEACTIVATE" |translate }}</span>
          </span>
        </button>
      </div>
    </div>
  </div>
</ng-container>
