<div (click)="preventGridSelection($event)" class="d-flex align-items-center justify-content-between w-100 h-100">
  <button type="button" class="btn btn-sm btn-light mr-2" (click)="view(data)">
    <div class="d-flex flex-row align-items-center">
      <svg-icon class="d-flex mr-2" src="asset/image/icon/eye.svg"></svg-icon>
      <span>{{ 'COMMON.VIEW' | translate }}</span>
    </div>
  </button>

  <div class="btn-group d-none d-lg-inline-flex" dropdown container="body" placement="right bottom">
    <button dropdownToggle type="button" class="btn btn-sm btn-light dropdown-toggle">
      <div class="d-flex flex-row align-items-center">
        <svg-icon class="d-flex" src="asset/image/icon/dots-vertical.svg"></svg-icon>
      </div>
    </button>
    <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
      <li role="menuitem">
        <button type="button" class="dropdown-item" (click)="edit(data)">
          {{ 'COMMON.EDIT' | translate }}
        </button>
      </li>
      <li role="menuitem">
        <button type="button" class="dropdown-item" (click)="delete(data)">
          {{ 'COMMON.DELETE' | translate }}
        </button>
      </li>
    </ul>
  </div>

  <div class="d-flex gap-2 d-lg-none">
    <button type="button" class="btn btn-sm btn-primary" (click)="edit(data)">
      <span>{{ 'COMMON.EDIT' | translate }}</span>
    </button>

    <button type="button" class="btn btn-sm btn-danger" (click)="delete(data)">
      <span>{{ 'COMMON.DELETE' | translate }}</span>
    </button>
  </div>
</div>
