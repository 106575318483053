import { Component, Input } from '@angular/core';

export interface ProgressBarItem {
  text: string;

  value: number;

  valueTo?: string;
}

@Component({
  selector: 'app-progression-bar',
  templateUrl: 'ProgressionBarComponent.html',
  styleUrls: [ 'ProgressionBarComponent.scss' ]
})
export class ProgressionBarComponent {

  @Input()
  public progressBarItems: ProgressBarItem[] = [];

  @Input()
  public doubleBar: boolean = true;
}
