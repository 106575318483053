<div class="modal-header border-bottom-0">
  <h4 class="modal-title">{{ title | translate }}</h4>
</div>

<div class="d-flex flex-column modal-footer border-top-0">
  <button type="button"
          class="btn btn-primary btn-block"
          (click)="ok(LiveClassType.SERIES)">{{ 'VIEW.MAIN.LIVE_CLASSES.SERIES.SERIES' | translate }}
  </button>

  <button type="button"
          class="btn btn-outline-primary btn-block"
          (click)="ok(LiveClassType.SINGLE)">{{ 'VIEW.MAIN.LIVE_CLASSES.SERIES.ONLY_CHOSEN_ONE' | translate }}
  </button>

  <button type="button"
          class="btn btn-link btn-block"
          (click)="exit()">
    <div class="d-flex justify-content-center align-items-center gap-2">
      <svg-icon src="asset/image/icon/chevron-left.svg"></svg-icon>
      <div class="font-size-base">{{ 'COMMON.CANCEL' | translate }}</div>
    </div>
  </button>
</div>
