<form>
  <div class="modal-header border-bottom-0">
    <h4 class="modal-title">{{ 'VIEW.MAIN.FRIENDS.ADD_FRIEND.ADD_FRIEND' | translate }}</h4>
  </div>

  <div class="modal-body">
    <div class="form-group">
      <ng-select #seniorSelect
                 name="seniors"
                 [loading]="!page"
                 [items]="page?.content"
                 [multiple]="true"
                 [hideSelected]="true"
                 [closeOnSelect]="false"
                 [clearable]="false"
                 [isOpen]="true"
                 (search)="search($event)"
                 bindLabel="user.fullName"
                 [notFoundText]="'VIEW.MAIN.FRIENDS.ADD_FRIEND.NO_RESULTS' | translate"
                 [inputAttrs]="{ placeholder: ('VIEW.MAIN.FRIENDS.ADD_FRIEND.SEARCH_FOR_PEOPLE' | translate) }"
                 [virtualScroll]="true"
                 [(ngModel)]="selectedSeniors">
        <ng-template ng-option-tmp let-item="item" let-item$="item$">
          <div class="custom-control custom-checkbox">
            <input [id]="'item-' + item.id"
                   [name]="'item-' + item.id"
                   type="checkbox"
                   class="custom-control-input"
                   [ngModel]="item$.selected">
            <label class="custom-control-label d-flex gap-2 align-items-center" [for]="'item-' + item.id">
              <app-avatar [src]="item.user.avatar?.defaultAsset?.url"
                          [circle]="true"
                          size="24px"
                          class="leading-none">
              </app-avatar>
              <span>{{ item.user.fullName }}</span>
            </label>
          </div>
        </ng-template>

        <ng-template ng-footer-tmp>
          <div class="custom-control custom-checkbox" *ngFor="let item of selectedSeniors">
            <input [id]="'item-' + item.id"
                   [name]="'item-' + item.id"
                   type="checkbox"
                   class="custom-control-input"
                   (change)="seniorSelect.clearItem(item)"
                   checked>
            <label class="custom-control-label d-flex gap-2 align-items-center" [for]="'item-' + item.id">
              <app-avatar [src]="item.user.avatar?.defaultAsset?.url"
                          [circle]="true"
                          size="24px"
                          class="leading-none">
              </app-avatar>
              <span>{{ item.user.fullName }}</span>
            </label>
          </div>
        </ng-template>
      </ng-select>
    </div>
  </div>

  <div class="modal-footer gap-2 border-top-0">
    <button type="button"
            class="btn btn-outline-primary"
            (click)="close()">
      {{ 'COMMON.BACK' | translate }}
    </button>

    <button type="button"
            class="btn btn-primary"
            [disabled]="selectedSeniors?.length === 0"
            (click)="save()">
      {{ 'COMMON.ADD' | translate }}
    </button>
  </div>
</form>