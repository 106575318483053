import { Injectable, Injector } from '@angular/core';
import { MembershipPlansModalComponent } from 'app/component/view/main/account/billingInfo/modal/MembershipPlansModalComponent';
import { UserModel } from 'app/model/UserModel';
import { SubscriptionType } from 'app/data/enum/subscription/SubscriptionType';
import { SubscriptionDTO } from 'app/data/enum/subscription/SubscriptionDTO';
import { Observable } from 'rxjs';
import { SubscriptionChangeRequestDTO } from 'app/data/dto/subscription/SubscriptionChangeRequestDTO';
import { ViewUtil } from 'app/util/ViewUtil';
import { SeniorService } from 'app/service/SeniorService';
import { map, switchMap, tap } from 'rxjs/operators';
import { fromPromise } from 'rxjs/internal-compatibility';
import { BsModalService } from 'ngx-bootstrap/modal';

@Injectable({ providedIn: 'root' })
export class SubscriptionModel {
  public get isSubscriptionActive(): boolean {
    return this.subscription?.active;
  }

  public get isSubscriptionInactive(): boolean {
    return !this.isSubscriptionActive;
  }

  public get subscriptionRetailType(): boolean {
    return this.subscription?.type === SubscriptionType.RETAIL;
  }

  public get subscriptionOrganizationType(): boolean {
    return !this.subscriptionRetailType;
  }

  public get subscriptionPlanSelected(): boolean {
    return (this.subscriptionRetailType && !!this.subscription?.retailSubscriptionDetails) || (this.subscriptionOrganizationType && !!this.subscription?.organization);
  }

  public get subscriptionExpired(): boolean {
    return this.isSubscriptionInactive && (this.subscriptionOrganizationType || (this.subscriptionRetailType && this.subscriptionPlanSelected));
  }

  constructor(private readonly userModel: UserModel,
              private readonly seniorService: SeniorService,
              private readonly injector: Injector,
              private readonly viewUtil: ViewUtil) {
  }

  public changeSubscriptionType(data: SubscriptionChangeRequestDTO): Observable<void> {
    return this.seniorService.changeSubscriptionType(data).pipe(
      tap(() => {
        this.viewUtil.showToastSuccess('VIEW.MAIN.ACCOUNT.BILLING_INFO.CHANGE_SUBSCRIPTION.MESSAGE.SUCCESS');
      }),
      switchMap(() => fromPromise(this.userModel.getCurrentUser()).pipe(map(_ => void 0)))
    );
  }

  public openMembershipPlanModal(): void {
    this.injector.get(BsModalService).show(MembershipPlansModalComponent);
  }

  public openMembershipPlanModalIfNeeded(): void {
    const { isSenior } = this.userModel.currentUser;

    if (isSenior && this.subscriptionRetailType && !this.subscriptionPlanSelected) {
      this.openMembershipPlanModal();
    }
  }

  private get subscription(): SubscriptionDTO {
    return this.userModel?.currentUser?.subscription;
  }
}