import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-content-and-image-column-layout',
  templateUrl: './ContentAndImageColumnLayoutComponent.html',
  styleUrls: [ './ContentAndImageColumnLayoutComponent.scss' ]
})
export class ContentAndImageColumnLayoutComponent {
  @Input()
  public imageSrc: string;

  constructor() {
  }
}
