<ng-template #headingTemplate>
  <h1>{{ 'VIEW.MAIN.USER.USER_DETAILS' | translate }}</h1>

  <div class="space-x-4">
    <button type="button" class="btn btn-outline-primary" (click)="onBackClick()">
      <svg-icon src="asset/image/icon/chevron-left.svg"></svg-icon>
      <span>{{ 'COMMON.BACK' | translate }}</span>
    </button>

    <button *ngIf="activeTab === UserTabs.PROFILE" type="button" class="btn btn-primary" (click)="onEditClick()">
      {{ 'COMMON.EDIT' | translate }}
    </button>
  </div>
</ng-template>

<section class="section">
  <app-tabs
    class="mr-auto"
    [translationPrefix]="'VIEW.MAIN.USER.TABS.'"
    [tabs]="tabs"
    (tabChanged)="onTabChanged($event)"
  ></app-tabs>

  <div>
    <ng-container [ngSwitch]="activeTab">
      <app-user-details-profile *ngSwitchCase="UserTabs.PROFILE"></app-user-details-profile>
      <app-account-details-preferences
        [embeddedMode]="true"
        [userType]="UserType.ADMIN"
        *ngSwitchCase="UserTabs.PREFERENCES"
      ></app-account-details-preferences>
    </ng-container>
  </div>
</section>
