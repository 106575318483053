<ng-template #headingTemplate>
  <h1>{{ 'VIEW.MAIN.DASHBOARD.MY_SCHEDULE' | translate }}</h1>
</ng-template>

<section class="section">
  <div class="control-panel p-4 d-flex flex-column w-100 w-lg-auto flex-lg-row align-items-center justify-content-between">
    <div class="d-flex justify-content-between align-items-center w-100 w-lg-auto mb-4 mb-lg-0">
      <button id="chevron-left"
              class="btn btn-link button"
              mwlCalendarPreviousView
              [view]="view"
              [viewDate]="viewDate"
              (viewDateChange)="handleViewDateChange(-1)"
      >
        <svg-icon src="asset/image/icon/chevron-left.svg"></svg-icon>
      </button>

      <div class="d-flex flex-column align-items-center">
        <div (click)="dp.toggle()">
          <span class="btn btn-link">{{ weekDisplay }}</span>
        </div>
        <input type="text"
               class="text-like-input"
               bsDatepicker
               [bsConfig]="bsConfig"
               [(ngModel)]="viewDate"
               #dp="bsDatepicker"
               (bsValueChange)="onDateChange($event)"
               readonly/>
      </div>

      <button id="chevron-right"
              class="btn btn-link button"
              mwlCalendarNextView
              [view]="view"
              [viewDate]="viewDate"
              (viewDateChange)="handleViewDateChange(+1)"
      >
        <svg-icon src="asset/image/icon/chevron-right.svg"></svg-icon>
      </button>
    </div>

    <div class="d-flex flex-column w-100 w-lg-auto flex-lg-row align-items-center">
      <button id="print-button"
              type="button"
              class="btn btn-outline-success btn-block mb-4 mb-lg-0 mr-lg-4"
              (click)="onPrintClick()"
      >
        <svg-icon class="mr-2" src="asset/image/icon/printer.svg"></svg-icon>
        {{ 'COMMON.PRINT' | translate }}
      </button>
    </div>
  </div>

  <div>
    <ng-template #weekHeaderTemplate let-days="days" let-locale="locale" let-dayHeaderClicked="dayHeaderClicked">
      <div class="cal-day-headers mr-0">
        <div
          class="cal-header"
          *ngFor="let day of days"
          [class.cal-past]="day.isPast"
          [class.cal-today]="day.isToday"
          [class.cal-future]="day.isFuture"
          [class.cal-weekend]="day.isWeekend"
          (click)="dayHeaderClicked.emit({day: day})"
        >
          <span>
            <p class="mb-0 font-size-sm font-weight-600">
              {{ day.date | calendarDate:'weekViewColumnHeader':locale | slice:0:3 | uppercase }}
            </p>
          </span>
          <span>
            <p class="mb-0 font-size-1-5 font-weight-600">
              {{ day.date | calendarDate:'weekViewColumnSubHeader':locale | date:'d' }}
            </p>
          </span>
        </div>
      </div>
    </ng-template>

    <div class="scroll-container" #scrollContainer>
      <mwl-calendar-week-view [headerTemplate]="weekHeaderTemplate"
                              [viewDate]="viewDate"
                              [events]="events"
                              [hourSegments]="2"
                              [dayStartHour]="0"
                              [dayEndHour]="24"
                              (eventClicked)="handleEvent('Clicked', $event.event)"
                              (eventTimesChanged)="eventTimesChanged($event)"
                              [refresh]="refresh"
                              [daysInWeek]="daysInWeek">
      </mwl-calendar-week-view>
    </div>
  </div>

  <div class="mt-6 border-radius-8">
    <div class="row no-gutters gap-4">
      <div *ngIf="recentRatings" class="col d-flex">
        <div class="card w-100">
          <div class="card-header mb-4">
            {{ 'VIEW.MAIN.COACH.MY_SCHEDULE.RECENT_RATINGS' | translate }}
          </div>
          <div class="card-body">
            <div class="mb-4">
              <p class="font-size-sm details-label">
                {{ 'VIEW.MAIN.COACH.MY_SCHEDULE.NAME' | translate }}
                <span class="float-right">{{ 'VIEW.MAIN.COACH.MY_SCHEDULE.RATINGS' | translate }}</span>
              </p>
            </div>
            <div class="mb-2 font-weight-600">{{ 'VIEW.MAIN.COACH.MY_SCHEDULE.LIVE_CLASSES' | translate }}</div>

            <div class="mb-2 d-flex justify-content-between">
              <p class="font-size-sm">
                {{ recentRatings.scheduledLiveClassRatings[0]?.scheduledLiveClass.title | replaceEmpty }}
              </p>
              <app-rating-stars [value]="recentRatings.scheduledLiveClassRatings[0]?.rating ?? 0">
              </app-rating-stars>
            </div>

            <div class="mb-2 font-weight-600">{{ 'VIEW.MAIN.COACH.MY_SCHEDULE.ON_DEMAND_VIDEOS' | translate }}</div>

            <div class="d-flex justify-content-between">
              <p class="font-size-sm">
                {{ recentRatings.videoRatings[0]?.video.title | replaceEmpty }}
              </p>
              <app-rating-stars [value]="recentRatings.videoRatings[0]?.rating ?? 0">
              </app-rating-stars>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col d-flex">
        <div class="card w-100">
          <div class="card-header mb-4">
            {{ 'VIEW.MAIN.COACH.MY_SCHEDULE.GENERAL_STATS' | translate }}
            <span class="float-right">&lt; Month &gt;</span>
          </div>
          <div class="card-body">
            <div class="mb-4">
              <p>
                {{ 'VIEW.MAIN.COACH.MY_SCHEDULE.TITLE' | translate }}
                <span class="float-right">{{ 'VIEW.MAIN.COACH.MY_SCHEDULE.NUMBER' | translate }}</span>
              </p>
            </div>

            <div class="mb-2">
              {{ 'VIEW.MAIN.COACH.MY_SCHEDULE.CLASSES_TAUGHT' | translate }}
              <span class="float-right">15</span>
            </div>

            <div class="">
              {{ 'VIEW.MAIN.COACH.MY_SCHEDULE.TOTAL_VIDEO_VIEWS' | translate }}
              <span class="float-right">1132</span>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</section>