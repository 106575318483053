import { Injectable } from '@angular/core';
import { UserModel } from 'app/model/UserModel';
import { SubscriptionModel } from 'app/model/SubscriptionModel';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserDTO } from 'app/data/dto/user/UserDTO';

@Injectable({ providedIn: 'root' })
export class AccessControlModel {
  public isLimitedAccess$: Observable<boolean> = this.userModel.currentUser$.asObservable()
    .pipe(
      map(() => this.isLimitedAccess)
    );

  public isFullAccess$: Observable<boolean> = this.isLimitedAccess$.pipe(map(access => !access));

  constructor(private readonly userModel: UserModel,
              private readonly subscriptionModel: SubscriptionModel) {

  }

  public get isLimitedAccess(): boolean {
    return !this.isAccess(this.userModel.currentUser);
  }

  public get isFullAccess(): boolean {
    return this.isAccess(this.userModel.currentUser);
  }

  private isAccess(user?: UserDTO): boolean {
    return !!user && this.subscriptionModel.isSubscriptionActive;
  }
}