<app-data-grid
  [data]="page?.content"
  [criteria]="criteria"
  [showFilters]="false"
  [showSearch]="false"
  [showPagination]="false"
  [pageTotal]="page?.totalPages"
  [itemTotal]="page?.totalElements"
  [showItemsPerPage]="true"
  [showTotalItems]="false"
  [selectionEnabled]="true"
  [multiSelectionEnabled]="true"
  [useCheckboxSelection]="true"
  (criteriaChanged)="onCriteriaChanged($event)"
  [columns]="$any([
        { headerName: ('COMMON.DATE' | translate), minWidth: 90, maxWidth: 90, field: 'startDate', cellRendererFramework: DataGridTemplateCellRendererComponent, cellRendererParams: { ngTemplate: dateTemplate } },
        { headerName: ('COMMON.TIME' | translate), minWidth: 90, maxWidth: 90, field: 'startDate', cellRendererFramework: DataGridTemplateCellRendererComponent, cellRendererParams: { ngTemplate: timeTemplate } },
        { headerName: ('VIEW.MAIN.ON_DEMAND.TITLE' | translate), minWidth: 250, maxWidth: 250, field: 'video.title' },
        { headerName: ('DOMAIN.WATCH_PARTY.DATA.HOST.LABEL' | translate), minWidth: 200, maxWidth: 200, cellRendererFramework: DataGridTemplateCellRendererComponent, cellRendererParams: { ngTemplate: hostTemplate } },
        { headerName: ('DOMAIN.WATCH_PARTY.DATA.CAPACITY.LABEL' | translate), minWidth: 100, maxWidth: 100, field: 'capacity', cellRendererFramework: DataGridTemplateCellRendererComponent, cellRendererParams: { ngTemplate: capacityTemplate } },
        { headerName: ('VIEW.MAIN.ON_DEMAND.CATEGORY' | translate), minWidth: 120, maxWidth: 120, field: 'video.category', cellRendererFramework: DataGridTemplateCellRendererComponent, cellRendererParams: { ngTemplate: videoCategoryTemplate } },
        { headerName: '', headerClass: 'header-right', suppressSizeToFit: true, flex: 1, cellClass: 'cell-fullwidth', cellRendererFramework: DataGridTemplateCellRendererComponent, cellRendererParams: { ngTemplate: actionsTemplate } },
      ])">
</app-data-grid>

<div class="d-flex justify-content-center" *ngIf="page?.isMore">
  <button type="button" class="btn btn-link" (click)="nextPage()">{{ 'COMMON.LOAD_MORE' | translate }}</button>
</div>

<!--Grid templates-->
<ng-template #dateTemplate let-value="value">{{ value | date:'shortDate' | replaceEmpty }}</ng-template>
<ng-template #timeTemplate let-value="value">{{ value | date:'shortTime' | replaceEmpty }}</ng-template>
<ng-template #hostTemplate let-data>
  <div class="d-flex align-items-center h-100 line-height-normal gap-3">
    <app-avatar [src]="data.host?.avatar?.thumbnailAsset?.url" size="40px" [circle]="true"></app-avatar>
    <span class="cut-long-text">{{ data.host?.fullName | replaceEmpty }}</span>
  </div>
</ng-template>
<ng-template #videoCategoryTemplate
             let-value="value">{{ value | prefix: 'ENUM.VIDEO_CATEGORY.' | translate | replaceEmpty }}
</ng-template>
<ng-template #capacityTemplate let-data let-value="value">
  <span>{{ data.participants?.length | replaceEmpty:'0' }}/{{ value | replaceEmpty:'0' }}</span>
</ng-template>

<ng-template #actionsTemplate let-data>
  <div class="d-flex justify-content-end align-items-center h-100 gap-3">
    <button type="button" class="btn btn-sm btn-link"
            [uiSref]="State.MAIN.SENIOR.ON_DEMAND.WATCH_PARTY.DETAILS"
            [uiParams]="{id: data.id}">
      <div class="d-flex align-items-center gap-2">
        <svg-icon class="d-flex" src="asset/image/icon/eye.svg"></svg-icon>
        <span>{{ 'COMMON.DETAILS' | translate }}</span>
      </div>
    </button>

    <button type="button" class="btn btn-sm btn-link"
            *appCanPerformAction="watchPartyModel.logic.isOpenForRegistration;data:data,else:closedTemplate"
            (click)="signup(data)">
      <div class="d-flex align-items-center gap-2">
        <svg-icon class="d-flex" src="asset/image/icon/circle-chevron-right.svg"></svg-icon>
        <span>{{ 'DOMAIN.WATCH_PARTY.ACTION.SIGN_UP' | translate }}</span>
      </div>
    </button>
  </div>
</ng-template>

<ng-template #closedTemplate>
  <span>{{ 'DOMAIN.WATCH_PARTY.CLOSED' | translate }}</span>
</ng-template>