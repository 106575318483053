import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AccessControlModel } from 'app/model/AccessControlModel';
import { BaseLiveClass } from 'app/data/dto/scheduledLiveClass/BaseLiveClass';
import { LiveClassModel } from 'app/model/LiveClassModel';

@Component({
  selector: 'app-live-classes-selected-action-bar',
  templateUrl: './LiveClassesSelectedActionBarComponent.html',
  styleUrls: [ './LiveClassesSelectedActionBarComponent.scss' ]
})
export class LiveClassesSelectedActionBarComponent implements OnChanges {
  @Input()
  public selectedClasses: BaseLiveClass[] = [];

  @Input()
  public adminMode: boolean = false;

  @Input()
  public seniorMode: boolean = false;

  @Output()
  public deleteClicked: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public detailsClick: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public favoriteClick: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public registerClick: EventEmitter<any> = new EventEmitter<any>();

  public classesText: string = 'class';

  constructor(private readonly translate: TranslateService,
              public readonly accessControlModel: AccessControlModel,
              public readonly liveClassModel: LiveClassModel) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { selectedClasses } = changes;
    if (selectedClasses) {
      const translationKey =
        this.selectedClasses.length === 1 ? 'VIEW.MAIN.LIVE_CLASSES.CLASS' : 'VIEW.MAIN.LIVE_CLASSES.CLASSES';
      this.classesText = this.translate.instant(translationKey);
    }
  }

  public onDeleteClick(): void {
    this.deleteClicked.emit();
  }

  public onFavoriteClick(): void {
    this.favoriteClick.emit(this.selectedClasses);
  }

  public onRegisterClick(): void {
    this.registerClick.emit(this.selectedClasses);
  }
}
