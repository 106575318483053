import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-to-program-modal',
  templateUrl: './AddToProgramModalComponent.html',
  styleUrls: ['./AddToProgramModalComponent.scss'],
})
export class AddToProgramModalComponent implements OnInit{
  public form: FormGroup
  programs = [
    { name: 'Up in Arms', label: 'Up in Arms', value: 'Up in Arms' },
    {
      name: 'Cooking CLub: White Chicken Chili',
      label: 'Cooking CLub: White Chicken Chili',
      value: 'Cooking CLub: White Chicken Chili',
    },
  ];

  constructor(
    private fb:FormBuilder
  ){}

  ngOnInit(): void {
    this.setupForm()
  }

  private setupForm(): void{
    this.form = this.fb.group({
      program:[null, Validators.required]
    })
  }
  public onSaveClick(): void {
    console.log("form: ", this.form.value)
  }
}
