import { Type } from 'class-transformer';
import { UserPreferencesDTO } from './UserPreferencesDTO';
import { IdentifiableObjectDTO } from '../IdentifiableObjectDTO';

export class SeniorPreferencesResponseAdminDTO extends IdentifiableObjectDTO<number> {
  seniorActivityFrequency?: string;

  seniorHealth?: string;

  seniorInterests: Array<string>;

  @Type(() => UserPreferencesDTO)
  user: UserPreferencesDTO;
}
