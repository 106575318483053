<div id="how-does-it-work-container" class="p-4">
  <div class="row no-gutters content">
    <div class="col-lg-12">
      <span class="mb-0">{{ 'VIEW.PRELIMINARY.HOWDOESITWORK.WATCH' | translate | uppercase }}</span>
      <h1>{{ 'VIEW.PRELIMINARY.HOWDOESITWORK.VIDEO_TUTORIAL' | translate }}</h1>
    </div>

    <div class="col-lg-12 p-0 mb-6 mt-6">
      <div id="vimeo-player"></div>
    </div>

    <div class="col-12 col-sm-6 p-0 d-flex align-items-center mb-4 mb-sm-0">
      <span>{{ 'VIEW.PRELIMINARY.HOWDOESITWORK.FAQ_MESSAGE' | translate }} <a href="#">FAQ</a></span>
    </div>
    <div class="col-12 col-sm-6 p-0 d-flex flex-column justify-content-center">
      <div class="d-flex flex-wrap justify-content-end">
        <button class="btn btn-primary" (click)="goToDashboard()">
          <span> {{ "COMMON.DONE" | translate }}!</span>
        </button>
      </div>
    </div>
  </div>
</div>
