<ng-template #headingTemplate>
  <h1>{{ 'VIEW.MAIN.HELP.HELP' | translate }}</h1>
</ng-template>

<section class="section">
  <app-tabs [translationPrefix]="'VIEW.MAIN.HELP.'" [tabs]="helpTabs" (tabChanged)="onTabChanged($event)"></app-tabs>

  <ng-container [ngSwitch]="activeTab">
    <app-help-faq *ngSwitchCase="availableTabs.FAQ"></app-help-faq>
    <app-help-tutorial *ngSwitchCase="availableTabs.TUTORIAL"></app-help-tutorial>
    <ng-container *ngIf="!isFaqRoute()">
      <app-help-contact *ngSwitchCase="availableTabs.CONTACT"></app-help-contact>
    </ng-container>
  </ng-container>
</section>
