import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { StateService, Transition } from '@uirouter/core';
import { Constant } from 'app/common/Constant';
import { State } from 'app/common/State';
import { ScheduledLiveClassCoachDetailsResponseDTO } from 'app/data/dto/scheduledLiveClass/coach/ScheduledLiveClassCoachDetailsResponseDTO';
import { ApplicationModel } from 'app/model/ApplicationModel';
import { CoachModel } from 'app/model/CoachModel';
import { StateUtil } from 'app/util/StateUtil';
import { ViewUtil } from 'app/util/ViewUtil';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { LiveClassSeriesAttachmentResponseDTO } from 'app/data/dto/liveClassSeries/LiveClassSeriesAttachmentResponseDTO';
import { IntensityLevel } from 'app/data/enum/IntensityLevel';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import { TemplatePortal } from '@angular/cdk/portal';
import { PortalUtil } from 'app/util/PortalUtil';

@Component({
  selector: 'app-live-classes-details-coach',
  templateUrl: './LiveClassDetailsCoachComponent.html',
  styleUrls: [ './LiveClassDetailsCoachComponent.scss' ]
})
export class LiveClassDetailsCoachComponent implements OnInit, OnDestroy {
  @ViewChild('headingTemplate', { static: true })
  private readonly headingTemplate: TemplateRef<any>;

  private destroy$: Subject<void> = new Subject<void>();
  public id: number;
  public scheduledLiveClass$: Observable<ScheduledLiveClassCoachDetailsResponseDTO>;

  public readonly IntensityLevel: typeof IntensityLevel = IntensityLevel;
  public readonly Constant: typeof Constant = Constant;

  constructor(private applicationModel: ApplicationModel,
              private transition: Transition,
              private coachModel: CoachModel,
              private stateService: StateService,
              private viewUtil: ViewUtil,
              public stateUtil: StateUtil,
              private portalUtil: PortalUtil,
              private viewContainerRef: ViewContainerRef) {
    this.id = this.transition.params().id;
  }

  public ngOnInit(): void {
    this.applicationModel.selectSideBarItemWithState(State.MAIN.COACH.LIVE_CLASSES.LIST);
    
    this.portalUtil.attachPortalTo(
      MainLayoutComponent.PORTAL_OUTLET.HEADING,
      new TemplatePortal(this.headingTemplate, this.viewContainerRef)
    );

    this.getScheduledLiveClass(this.id);
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.portalUtil.detachPortalFrom(MainLayoutComponent.PORTAL_OUTLET.HEADING);
  }

  public onBackClick(): void {
    this.stateService.go(State.MAIN.COACH.LIVE_CLASSES.LIST);
  }

  private getScheduledLiveClass(id: number): void {
    this.coachModel
      .getScheduledLiveClassDetails(id)
      .pipe(
        takeUntil(this.destroy$),
        tap((scheduledLiveClassDTO) => {
          this.scheduledLiveClass$ = of(scheduledLiveClassDTO);
        }),
        catchError((err) => {
          this.viewUtil.handleServerError(err);
          return throwError(() => err);
        })
      )
      .subscribe();
  }
}
