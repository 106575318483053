export class Event {

  public static readonly AUTH = {
    LOGIN: {
      SUCCESS:        'auth_login_success',
      ERROR:          'auth_login_error'
    },
    LOGOUT: {
      SUCCESS:        'auth_logout_success',
      ERROR:          'auth_logout_error'
    },
    ERROR: {
      UNAUTHORIZED:   'auth_error_unauthorized',
      FORBIDDEN:      'auth_error_forbidden',
    },
    SESSION_TIMEOUT:  'auth_sessionTimeout',
    TOKEN_REFRESH:    'auth_tokenRefresh'
  };

  public static readonly USER = {
    GET_CURRENT: {
      SUCCESS:        'user_getCurrent_success',
      ERROR:          'user_getCurrent_error'
    },
    UPDATE_CURRENT: {
      SUCCESS:        'user_updateCurrent_success',
      ERROR:          'user_updateCurrent_error'
    },
    SECURITY_DATA:    'user_securityData'
  };

  public static readonly SYSTEM = {
    LOADING:            'system_loading',
    GENERAL_ERROR:      'system_generalError',
    GENERAL_MESSAGE:    'system_generalMessage',
    SET_LANGUAGE:       'system_setLanguage',
    REDRAW:             'system_redraw'
  };

}
