import { Component } from '@angular/core';
import { StateUtil } from 'app/util/StateUtil';

@Component({
  selector: 'app-error-not-found',
  templateUrl: 'ErrorNotFoundComponent.html',
  styleUrls: [ 'ErrorNotFoundComponent.scss' ]
})
export class ErrorNotFoundComponent {
  constructor(private stateUtil: StateUtil) {
  }

  public goToDashboard(): void {
    this.stateUtil.goToDashboard();
  }
}
