import { Component, ElementRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Transition } from '@uirouter/angular';
import { StateService } from '@uirouter/core';
import { Constant } from 'app/common/Constant';
import { State } from 'app/common/State';
import { AuthModel } from 'app/model/AuthModel';
import { ViewUtil } from 'app/util/ViewUtil';

@Component({
  selector: 'app-reset-password-complete',
  templateUrl: 'VerificationCodeComponent.html',
  styleUrls: [ 'VerificationCodeComponent.scss' ]
})
export class VerificationCodeComponent {
  @ViewChild('resetPasswordForm')
  private resetPasswordForm: NgForm;

  @ViewChild('input0')
  public input0: ElementRef;

  @ViewChild('input1')
  public input1: ElementRef;

  @ViewChild('input2')
  public input2: ElementRef;

  @ViewChild('input3')
  public input3: ElementRef;

  @ViewChild('input4')
  public input4: ElementRef;

  @ViewChild('input5')
  public input5: ElementRef;

  public usernameParam: string;
  public verificationCodeParam: string;
  public formData: {
    username: string;
    password: string;
    passwordRepeat: string;
    verificationCode: string;
    verificationCode0: string;
    verificationCode1: string;
    verificationCode2: string;
    verificationCode3: string;
    verificationCode4: string;
    verificationCode5: string;
  } = {
    username: null,
    password: null,
    passwordRepeat: null,
    verificationCode: null,
    verificationCode0: null,
    verificationCode1: null,
    verificationCode2: null,
    verificationCode3: null,
    verificationCode4: null,
    verificationCode5: null
  };

  public Constant: typeof Constant = Constant;

  constructor(private stateService: StateService,
              private transition: Transition,
              public viewUtil: ViewUtil,
              private authModel: AuthModel) {
    this.usernameParam = this.transition.params().username ? unescape(transition.params().username) : null;

    if (this.usernameParam) {
      this.formData.username = this.usernameParam;
    }

    this.verificationCodeParam = transition.params().verificationCode;

    if (this.verificationCodeParam) {
      this.formData.verificationCode = this.verificationCodeParam;
    }
  }

  public continue(): void {
    this.resetPasswordForm.onSubmit(null);
    if (this.resetPasswordForm.form.valid && this.formData.username) {
      const verificationCode =
        this.formData.verificationCode0 +
        this.formData.verificationCode1 +
        this.formData.verificationCode2 +
        this.formData.verificationCode3 +
        this.formData.verificationCode4 +
        this.formData.verificationCode5;
      this.stateService.go(State.PRELIMINARY.RESET_PASSWORD_COMPLETE, {
        username: this.formData.username,
        verificationCode: verificationCode
      });
    }
  }

  public resendConfirmationLink(): void {
    this.authModel
      .startPasswordReset(this.usernameParam)
      .then(() => {
        this.stateService.go(State.PRELIMINARY.RESET_PASSWORD_VERIFICATION_CODE, { username: this.formData.username });
        this.viewUtil.showToastSuccess('VIEW.PRELIMINARY.RESET_PASSWORD.RESET_VERIFICATION_CODE');
      })
      .catch((error) => {
        if (error && error.code === 'LimitExceededException') {
          this.viewUtil.showToastError('VIEW.PRELIMINARY.RESET_PASSWORD.TO_MANY_ATTEMPTS');
          return;
        }
        this.viewUtil.handleServerError(error);
      });
  }

  public setFocusOnInput(inputElement: ElementRef): void {
    inputElement.nativeElement.focus();
    inputElement.nativeElement.select();
  }

  public onBackClick(): void {
    this.stateService.go(State.PRELIMINARY.RESET_PASSWORD_START);
  }

  public onKey(event: KeyboardEvent | ClipboardEvent, index: number): void {
    const input = event.target as HTMLInputElement;
    let nextIndex: number | null = null;

    if (event.type === 'paste' && event instanceof ClipboardEvent) {
      event.preventDefault();
      const pastedData = event.clipboardData?.getData('text') || '';

      const characters = pastedData.split('');
      characters.forEach((char, i) => {
        if (i < 6) {
          const targetInput = this[`input${ index + i }`] as ElementRef<HTMLInputElement>;
          if (targetInput) {
            targetInput.nativeElement.value = char;
            this.formData[`verificationCode${ index + i }`] = char;
          }
        }
      });

      nextIndex = index + pastedData.length;
      nextIndex = nextIndex > 5 ? 5 : nextIndex;
    }
    else if (event instanceof KeyboardEvent) {
      if (event.key === 'Backspace' && (!input.value.length || input.selectionStart === 0)) {
        nextIndex = index - 1;
      }
      else if (input.value.length && input.selectionStart === 1) {
        nextIndex = index + 1;
      }
      else if (event.key === 'ArrowLeft' && input.selectionStart === 0) {
        nextIndex = index - 1;
      }
      else if (event.key === 'ArrowRight' && input.selectionStart === 1) {
        nextIndex = index + 1;
      }
    }

    if (nextIndex !== null && nextIndex >= 0 && nextIndex <= 5) {
      const nextInput = this[`input${ nextIndex }`] as ElementRef<HTMLInputElement>;
      if (nextInput) {
        this.setFocusOnInput(nextInput);
      }
    }
  }
}
