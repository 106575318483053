import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Observable, of, Subscription, timer } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Directive({
  selector: '[appCanPerformAction]'
})
export class CanPerformActionDirective implements OnInit, OnDestroy {
  @Input() public appCanPerformAction: (data?: unknown) => Observable<boolean>;
  @Input() public appCanPerformActionData: unknown;
  @Input() public appCanPerformActionRetry: number = 3000; // seconds
  @Input() public appCanPerformActionElse: TemplateRef<any>;

  private subscription: Subscription = new Subscription();

  constructor(private readonly view: ViewContainerRef,
              private readonly templateRef: TemplateRef<any>) { }

  public ngOnInit(): void {
    this.subscription.add(
      (this.appCanPerformActionRetry ? timer(0, this.appCanPerformActionRetry) : of(void 0))
        .pipe(
          switchMap(() => this.appCanPerformAction(this.appCanPerformActionData)
            .pipe(catchError(() => of(false)))),
          distinctUntilChanged()
        )
        .subscribe((result: boolean) => {
            this.view.clear();

            if (result) {
              this.view.createEmbeddedView(this.templateRef);
            }
            else {
              this.appCanPerformActionElse ? this.view.createEmbeddedView(this.appCanPerformActionElse) : this.view.clear();
            }
          }
        )
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
