import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import { ChangeDetectorRef, Directive, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import * as moment from 'moment';

export const minDateTimeValidator = (value: Date): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors => {
    if (!control.value) {
      return null;
    }
    else {
      const date: Date = control.value;
      return moment(date).isBefore(value, 'second') ? { minDateTime: true } : null;
    }
  };
};

@Directive({
  selector: '[appMinDateTime][formControlName],[appMinDateTime][formControl],[appMinDateTime][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: MinDateTimeValidator,
      multi: true
    }
  ]
})
export class MinDateTimeValidator implements Validator, OnInit, OnChanges {

  @Input()
  public appMinDateTime: Date;

  private validator: ValidatorFn;

  private onChange: () => void;

  constructor(private changeDetector: ChangeDetectorRef) {
  }

  public ngOnInit(): void {
    this.validator = minDateTimeValidator(this.appMinDateTime);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    for (const key in changes) {
      if (key === 'appMinDateTime') {
        this.validator = minDateTimeValidator(changes[key]?.currentValue);
        if (this.onChange) {
          this.onChange();
          this.changeDetector.detectChanges();  //  there are some issues in angular with detecting valid/invalid state and binding to ngClass, for example. This fixes it.
        }
      }
    }
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    return this.appMinDateTime !== null ? this.validator(control) : null;
  }

  public registerOnValidatorChange(fn: () => void): void {
    this.onChange = fn;
  }
}
