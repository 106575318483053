<div class="d-flex gap-4 flex-wrap">
  <div *ngFor="let day of availableDays let i = index" class="custom-control custom-checkbox">
    <input
      type="checkbox"
      class="custom-control-input"
      [id]="'day_' + day"
      [name]="'day_' + day"
      [(ngModel)]="val[day.toLowerCase()]"
      (ngModelChange)="change()"
    />
    <label class="custom-control-label"
           [for]="'day_' + day">{{ ('ENUM.DAYS.' + day) | uppercase | translate }}</label>
  </div>
</div>