import { Directive, OnInit } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';

export const memberCodeValidator = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors => {
    if (!control.value) {
      return null;
    }
    else {
      const pattern: RegExp = new RegExp(/^[a-zA-Z0-9]{4,16}$/i);
      return pattern.test(control.value) ? null : { memberCode: true };
    }
  };
};

@Directive({
  selector: '[appMemberCodeValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: MemberCodeValidator,
      multi: true
    }
  ]
})
export class MemberCodeValidator implements Validator, OnInit {
  private validator: ValidatorFn;

  ngOnInit(): void {
    this.validator = memberCodeValidator();
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    return this.validator(control);
  }
}
