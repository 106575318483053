import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { DataGridContext } from 'app/component/ui/dataGrid/DataGridContext';

@Component({
  selector: 'app-data-grid-file-cell-renderer',
  templateUrl: 'DataGridFileCellRendererComponent.html'
})
export class DataGridFileCellRendererComponent implements ICellRendererAngularComp {

  public params: ICellRendererParams;
  public value: any;
  public data: any;
  public context: DataGridContext;

  public agInit(params: ICellRendererParams): void {
    this.params = params;
    this.value = params.value;
    this.data = params.data;
    this.context = params.context;
  }

  public refresh(params: ICellRendererParams): boolean {
    return false;
  }

  public preventGridSelection(): void {
    const previousValue = this.params.node['gridOptionsWrapper'].gridOptions.suppressRowClickSelection;
    this.params.node['gridOptionsWrapper'].gridOptions.suppressRowClickSelection = true;

    setTimeout(() => {
      this.params.node['gridOptionsWrapper'].gridOptions.suppressRowClickSelection = previousValue;
    });
  }

}
