import { Type } from 'class-transformer';
import { IdentifiableObjectDTO } from '../IdentifiableObjectDTO';
import { ImageResponseDTO } from '../images/ImageResponseDTO';
import { VideoRatingEssentialsResponseDTO } from 'app/data/dto/onDemandVideo/VideoRatingEssentialsResponseDTO';

export class VideoEssentialsSeniorProgramListDTO extends IdentifiableObjectDTO<number> {
  @Type(() => VideoRatingEssentialsResponseDTO)
  public currentSeniorRating: VideoRatingEssentialsResponseDTO;

  @Type(() => ImageResponseDTO)
  public thumbnail: ImageResponseDTO;

  public title: string;
}
