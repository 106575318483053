import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-subscription-store',
  template: ''
})
export class SubscriptionStoreComponent implements OnDestroy {
  protected storeSubscription: Subscription;

  constructor() {
    this.storeSubscription = new Subscription();
  }

  protected set subscription(subscription: Subscription) {
    this.storeSubscription.add(subscription);
  }

  public ngOnDestroy(): void {
    this.storeSubscription.unsubscribe();
  }
}
