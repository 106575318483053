<app-content-and-image-column-layout [imageSrc]="'asset/image/login_background.png'">
  <form id="content" class="d-flex flex-column p-4" #loginForm="ngForm" novalidate (keyup.enter)="login()">
    <div class="d-flex flex-column">
      <img class="logo" src="asset/image/logo.png" alt="Logo"/>

      <h2 class="h4 m-0">{{ 'VIEW.PRELIMINARY.LOGIN.WELCOME' | translate | uppercase }}</h2>
      <h1 class="font-weight-bold mb-6">{{ 'VIEW.PRELIMINARY.LOGIN.LOGIN_HEADER' | translate }}</h1>
    </div>

    <div class="row d-flex justify-content-center">
      <div class="form-group col-12 col-lg-6 d-flex flex-column">
        <label for="username">{{ 'VIEW.PRELIMINARY.LOGIN.USERNAME' | translate }}</label>
        <input
          type="text"
          id="username"
          name="username"
          #username="ngModel"
          class="form-control"
          placeholder="{{ 'VIEW.PRELIMINARY.LOGIN.USERNAME_PLACEHOLDER' | translate }}"
          [ngClass]="{
            'is-invalid': loginForm.submitted && username.invalid,
            'is-valid': loginForm.submitted && username.valid
          }"
          [(ngModel)]="formData.username"
          required
          [appMaxLength]="Constant.TEXT_MAX_LENGTH_150"
        />
        <div class="invalid-feedback" *ngIf="loginForm.submitted && username.invalid">
          <div class="d-flex align-items-center" *ngIf="username.errors['required']">
            <svg-icon class="mr-2 fill-danger" src="asset/image/icon/alert.svg"></svg-icon>
            {{ 'VALIDATION.REQUIRED' | translate }}
          </div>
          <div *ngIf="username.errors['maxLength']">
            {{ 'VALIDATION.CHAR_MAX' | translate: { max: Constant.TEXT_MAX_LENGTH_150 } }}
          </div>
        </div>
      </div>

      <div class="form-group col-12 col-lg-6 d-flex flex-column">
        <label for="password">{{ 'VIEW.PRELIMINARY.LOGIN.PASSWORD' | translate }}</label>
        <input
          type="password"
          id="password"
          name="password"
          #password="ngModel"
          class="form-control"
          placeholder="{{ 'VIEW.PRELIMINARY.LOGIN.PASSWORD_PLACEHOLDER' | translate }}"
          [ngClass]="{'is-invalid': loginForm.submitted && password.invalid, 'is-valid': loginForm.submitted && password.valid }"
          [(ngModel)]="formData.password"
          required
          appInputPasswordToggle
          autocomplete="password"
        />
        <div class="invalid-feedback" *ngIf="loginForm.submitted && password.invalid">
          <div class="d-flex align-items-center" *ngIf="password.errors['required']">
            <svg-icon class="mr-2 fill-danger" src="asset/image/icon/alert.svg"></svg-icon>
            {{ 'VALIDATION.REQUIRED' | translate }}
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start"></div>
      <div class="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end">
        <a href="javascript:void(0)" (click)="resetPassword(); $event.preventDefault()">
          <span>{{ 'VIEW.PRELIMINARY.LOGIN.FORGOT_PASSWORD' | translate }}</span>
        </a>
      </div>
    </div>

    <div class="row no gutters">
      <div class="col-12 col-sm-6 d-flex flex-column align-items-center align-items-sm-start justify-content-sm-end mb-4 mb-sm-0">
        <p class="m-0">
          {{ 'VIEW.PRELIMINARY.LOGIN.DONT_HAVE_ACCOUNT' | translate }}
          <button type="button" class="btn btn-link font-weight-600" (click)="register()">
            {{ 'VIEW.PRELIMINARY.REGISTRATION.REGISTER' | translate }}
          </button>
        </p>
        <p type="button" class="m-0" (click)="onFaqClick()">
          {{ 'VIEW.PRELIMINARY.LOGIN.NEED_HELP_MESSAGE' | translate }}
          <button type="button" class="btn btn-link font-weight-600">
            {{ 'COMMON.FAQ' | translate }}
          </button>
        </p>
      </div>
      <div class="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end align-items-center">
        <button id="login-button" type="button" class="btn btn btn-primary" (click)="login()">
          <span>{{ 'VIEW.PRELIMINARY.LOGIN.LOGIN' | translate }}</span>
        </button>
      </div>
    </div>
  </form>
</app-content-and-image-column-layout>
