import { Injectable } from '@angular/core';
import { PageDTO } from 'app/data/dto/PageDTO';
import { ScheduledLiveClassSeniorPageCriteriaDTO } from 'app/data/dto/scheduledLiveClass/ScheduledLiveClassSeniorPageCriteriaDTO';
import { ScheduledLiveClassCoachPageCriteriaDTO } from 'app/data/dto/scheduledLiveClass/coach/ScheduledLiveClassCoachPageCriteriaDTO';
import { ScheduledLiveClassPageCriteriaDTO } from 'app/data/dto/scheduledLiveClass/ScheduledLiveClassPageCriteriaDTO';
import { ScheduledLiveClassDetailsPageDTO } from 'app/data/dto/scheduledLiveClass/admin/ScheduledLiveClassDetailsPageDTO';
import { ScheduledLiveClassDetailsResponseDTO } from 'app/data/dto/scheduledLiveClass/admin/ScheduledLiveClassDetailsResponseDTO';
import { ScheduledLiveClassUpdateRequestDTO } from 'app/data/dto/scheduledLiveClass/admin/ScheduledLiveClassUpdateRequestDTO';
import { ScheduledLiveClassCoachDetailsPageDTO } from 'app/data/dto/scheduledLiveClass/coach/ScheduledLiveClassCoachDetailsPageDTO';
import { ScheduledLiveClassSeniorDetailsPageDTO } from 'app/data/dto/scheduledLiveClass/senior/ScheduledLiveClassSeniorDetailsPageDTO';
import { ScheduledLiveClassSeniorDetailsResponseDTO } from 'app/data/dto/scheduledLiveClass/senior/ScheduledLiveClassSeniorDetailsResponseDTO';
import { ScheduledLiveClassService } from 'app/service/ScheduledLiveClassService';
import { Observable } from 'rxjs';
import { LiveClassSeriesUpdateRequestDTO } from 'app/data/dto/liveClassSeries/admin/LiveClassSeriesUpdateRequestDTO';
import { LiveClassSeriesService } from 'app/service/LiveClassSeriesService';
import { LiveClassSeriesDetailsResponseDTO } from 'app/data/dto/liveClassSeries/admin/LiveClassSeriesDetailsResponseDTO';
import { ScheduledLiveClassRatingRequestDTO } from 'app/data/dto/scheduledLiveClass/ScheduledLiveClassRatingRequestDTO';
import { ScheduledLiveClassRatingResponseDTO } from 'app/data/dto/scheduledLiveClass/ScheduledLiveClassRatingResponseDTO';
import { LiveClassLogic } from 'app/domain/liveClass/service/LiveClassLogic';

@Injectable({ providedIn: 'root' })
export class LiveClassModel {
  constructor(public readonly logic: LiveClassLogic,
              private readonly scheduledLiveClassService: ScheduledLiveClassService,
              private readonly liveClassSeriesService: LiveClassSeriesService) {
  }

  public getScheduledLiveClassesPageAdmin(
    criteria: ScheduledLiveClassPageCriteriaDTO
  ): Observable<PageDTO<ScheduledLiveClassDetailsPageDTO>> {
    return this.scheduledLiveClassService.getScheduledLiveClassesPageAdmin(criteria);
  }

  public getScheduledLiveClassesPageCoach(
    criteria: ScheduledLiveClassCoachPageCriteriaDTO
  ): Observable<PageDTO<ScheduledLiveClassCoachDetailsPageDTO>> {
    return this.scheduledLiveClassService.getScheduledLiveClassesPageCoach(criteria);
  }

  public getScheduledLiveClassesPageUser(
    criteria: ScheduledLiveClassSeniorPageCriteriaDTO
  ): Observable<PageDTO<ScheduledLiveClassSeniorDetailsPageDTO>> {
    return this.scheduledLiveClassService.getScheduledLiveClassesPageUser(criteria);
  }

  public updateScheduledLiveClass(
    id: number,
    scheduledLiveClass: ScheduledLiveClassUpdateRequestDTO
  ): Observable<ScheduledLiveClassDetailsResponseDTO> {
    return this.scheduledLiveClassService.updateScheduledLiveClassAdmin(id, scheduledLiveClass);
  }

  public updateLiveClassSeries(
    id: number,
    liveClassSeries: LiveClassSeriesUpdateRequestDTO
  ): Observable<LiveClassSeriesDetailsResponseDTO> {
    return this.liveClassSeriesService.updateLiveClassSeries(id, liveClassSeries);
  }

  public getHappeningNowClasses(): Observable<ScheduledLiveClassSeniorDetailsResponseDTO[]> {
    return this.scheduledLiveClassService.getHappeningNowClasses();
  }

  public rateScheduledLiveClass(
    scheduledLiveClassId: number,
    ratingRequest: ScheduledLiveClassRatingRequestDTO
  ): Observable<ScheduledLiveClassRatingResponseDTO> {
    return this.scheduledLiveClassService.rateScheduledLiveClass(scheduledLiveClassId, ratingRequest);
  }
}
