import { SubscriptionType } from 'app/data/enum/subscription/SubscriptionType';
import { RegistrationType } from 'app/data/enum/user/RegistrationType';

export class SeniorValidationRequestDTO {

  public email: string;

  public memberCode: string;

  public organizationId: number;

  public phone: string;

  public registrationType: RegistrationType;

  public subscriptionType: SubscriptionType;
}