import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';
import { State } from 'app/common/State';
import { VideoSeniorRecommendedDTO } from 'app/data/dto/onDemandVideo/senior/VideoSeniorRecommendedDTO';
import { BsModalService } from 'ngx-bootstrap/modal';
import { OnDemandVideoModel } from 'app/model/OnDemandVideoModel';
import { DashboardSeniorAllRecommendedModalComponent } from 'app/component/view/main/dashboard/senior/modal/DashboardSeniorAllRecommendedModalComponent';

@Component({
  selector: 'app-dashboard-senior-recommended',
  templateUrl: './DashboardSeniorRecommendedComponent.html',
  styleUrls: [ './DashboardSeniorRecommendedComponent.scss' ]
})
export class DashboardSeniorRecommendedComponent implements OnInit {
  public recommendedVideos: VideoSeniorRecommendedDTO[] = [];

  constructor(
    private onDemandVideoModel: OnDemandVideoModel,
    private stateService: StateService,
    private modalService: BsModalService
  ) {
  }

 public ngOnInit(): void {
    this.onDemandVideoModel.getRecommendedVideosRandom().subscribe((videos) => (this.recommendedVideos = videos));
  }

  public playVideo(videoId: number): void {
    this.stateService.go(State.MAIN.SENIOR.ON_DEMAND.VIDEO.PLAY, { videoId });
  }

  public showAllRecommended(): void {
    this.modalService.show(DashboardSeniorAllRecommendedModalComponent, { class: 'recommended-videos-modal modal-dialog-centered' });
  }
}
