import { IdentifiableObjectDTO } from 'app/data/dto/IdentifiableObjectDTO';
import { VideoCategory } from 'app/data/enum/video/VideoCategory';
import { VideoSubcategory } from 'app/data/enum/video/VideoSubcategory';

export class BaseVideoDTO extends IdentifiableObjectDTO<number> {
  public title: string;

  public category: VideoCategory;

  public subcategories: VideoSubcategory[];
}