<app-content-and-image-column-layout [imageSrc]="'asset/image/register-background.jpg'">
  <div
    id="content"
    *ngIf="userRegistration && organizationsList$ | async as organizationsList"
    class="p-4 d-flex flex-column"
  >
    <img class="logo" src="asset/image/logo.png" alt="Logo"/>

    <div class="d-flex justify-content-between w-100">
      <h4 class="mr-2">{{ 'VIEW.PRELIMINARY.REGISTRATION.GETTING_STARTED' | translate }}</h4>
      <h4>{{ 'VIEW.PRELIMINARY.REGISTRATION.STEP_OF' | translate: { current: currentStep, total: noOfSteps } }}</h4>
    </div>
    <h1 class="mb-6">{{ 'VIEW.PRELIMINARY.REGISTRATION.CREATE_ACCOUNT' | translate }}</h1>

    <form #registrationForm="ngForm" novalidate (keyup.enter)="validateUserAndGoToNextStep()">
      <div class="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2 row-cols-xl-2">
        <div class="form-group col d-flex flex-column">
          <label class="w-100">{{ 'VIEW.PRELIMINARY.REGISTRATION.USERNAME_TYPE' | translate }}</label>
          <div class="flex-grow-1 d-flex align-items-center">
            <div class="custom-control custom-radio custom-control-inline">
              <input type="radio"
                     id="registrationTypeEmail"
                     name="registrationType"
                     #registrationTypeModel="ngModel"
                     [value]="RegistrationType.EMAIL"
                     class="custom-control-input"
                     [ngClass]="{
                      'is-invalid': registrationForm.submitted && registrationTypeModel.invalid,
                      'is-valid': registrationForm.submitted && registrationTypeModel.valid
                     }"
                     [(ngModel)]="userRegistration.user.registrationType"
                     required
              />
              <label class="custom-control-label" for="registrationTypeEmail">
                {{ 'VIEW.PRELIMINARY.REGISTRATION.EMAIL' | translate }}
              </label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
              <input type="radio"
                     id="registrationTypePhone"
                     name="registrationType"
                     #registrationTypeModel="ngModel"
                     [value]="RegistrationType.PHONE"
                     class="custom-control-input"
                     [ngClass]="{
                      'is-invalid': registrationForm.submitted && registrationTypeModel.invalid,
                      'is-valid': registrationForm.submitted && registrationTypeModel.valid
                     }"
                     [(ngModel)]="userRegistration.user.registrationType"
                     required
              />
              <label class="custom-control-label" for="registrationTypePhone">
                {{ 'VIEW.PRELIMINARY.REGISTRATION.PHONE_NUMBER' | translate }}
              </label>
            </div>
            <div class="invalid-feedback" *ngIf="registrationForm.submitted && registrationTypeModel.invalid">
              <div *ngIf="registrationTypeModel.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
            </div>
          </div>
        </div>
        <div *ngIf="userRegistration.user.registrationType === RegistrationType.EMAIL" class="form-group col">
          <label for="email">{{ 'VIEW.PRELIMINARY.REGISTRATION.EMAIL' | translate }}</label>
          <input
            type="email"
            id="email"
            name="email"
            #email="ngModel"
            class="form-control"
            placeholder="{{ 'VIEW.PRELIMINARY.REGISTRATION.EMAIL' | translate }}"
            [ngClass]="{
              'is-invalid': registrationForm.submitted && email.invalid,
              'is-valid': registrationForm.submitted && email.valid
            }"
            [(ngModel)]="userRegistration.user.email"
            required
            ngvemail
            [appMaxLength]="Constant.TEXT_MAX_LENGTH_150"
          />
          <div class="invalid-feedback" *ngIf="registrationForm.submitted && email.invalid">
            <div *ngIf="email.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
            <div *ngIf="email.errors['email']">{{ 'VALIDATION.EMAIL' | translate }}</div>
            <div *ngIf="email.errors['maxLength']">
              {{ 'VALIDATION.CHAR_MAX' | translate: { max: Constant.TEXT_MAX_LENGTH_150 } }}
            </div>
          </div>
        </div>
        <div *ngIf="userRegistration.user.registrationType === RegistrationType.PHONE" class="form-group col">
          <label for="phoneNumber">{{ 'VIEW.PRELIMINARY.REGISTRATION.PHONE_NUMBER' | translate }}</label>
          <input
            type="text"
            id="phoneNumber"
            name="phoneNumber"
            #phoneNumber="ngModel"
            class="form-control"
            [ngClass]="{
              'is-invalid': registrationForm.submitted && phoneNumber.invalid,
              'is-valid': registrationForm.submitted && phoneNumber.valid && userRegistration.user.phone
            }"
            [(ngModel)]="userRegistration.user.phone"
            [showMaskTyped]="true"
            [prefix]="Constant.PHONE_NUMBER_RULES.prefix + ' '"
            [shownMaskExpression]="Constant.PHONE_NUMBER_RULES.expression"
            [mask]="Constant.PHONE_NUMBER_RULES.mask"
            [pattern]="Constant.PHONE_NUMBER_RULES.pattern"
            required
          />
          <div class="invalid-feedback" *ngIf="registrationForm.submitted && phoneNumber.invalid">
            <div *ngIf="phoneNumber.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
            <div *ngIf="phoneNumber.errors['pattern']">{{ 'VALIDATION.PHONE' | translate }}</div>
          </div>
        </div>
        <div class="form-group col">
          <label for="firstName">{{ 'VIEW.PRELIMINARY.REGISTRATION.FIRST_NAME' | translate }}</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            #firstName="ngModel"
            class="form-control"
            placeholder="{{ 'VIEW.PRELIMINARY.REGISTRATION.FIRST_NAME' | translate }}"
            [ngClass]="{'is-invalid': registrationForm.submitted && firstName.invalid, 'is-valid': registrationForm.submitted && firstName.valid }"
            [(ngModel)]="userRegistration.user.firstName"
            required
            [appMaxLength]="Constant.TEXT_MAX_LENGTH_150"
          />
          <div class="invalid-feedback" *ngIf="registrationForm.submitted && firstName.invalid">
            <div *ngIf="firstName.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
            <div *ngIf="firstName.errors['maxLength']">
              {{ 'VALIDATION.CHAR_MAX' | translate: { max: Constant.TEXT_MAX_LENGTH_150 } }}
            </div>
          </div>
        </div>
        <div class="form-group col">
          <label for="lastName">{{ 'VIEW.PRELIMINARY.REGISTRATION.LAST_NAME' | translate }}</label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            #lastName="ngModel"
            class="form-control"
            placeholder="{{ 'VIEW.PRELIMINARY.REGISTRATION.LAST_NAME' | translate }}"
            [ngClass]="{'is-invalid': registrationForm.submitted && lastName.invalid, 'is-valid': registrationForm.submitted && lastName.valid }"
            [(ngModel)]="userRegistration.user.lastName"
            required
            [appMaxLength]="Constant.TEXT_MAX_LENGTH_150"
          />
          <div class="invalid-feedback" *ngIf="registrationForm.submitted && lastName.invalid">
            <div *ngIf="lastName.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
            <div *ngIf="lastName.errors['maxLength']">
              {{ 'VALIDATION.CHAR_MAX' | translate: { max: Constant.TEXT_MAX_LENGTH_150 } }}
            </div>
          </div>
        </div>
        <div class="form-group col">
          <label for="dateOfBirth">{{ 'VIEW.PRELIMINARY.REGISTRATION.DATE_OF_BIRTH' | translate }}</label>
          <input
            type="text"
            id="dateOfBirth"
            name="dateOfBirth"
            #dateOfBirth="ngModel"
            class="form-control full-width"
            placeholder="{{ 'VIEW.PRELIMINARY.REGISTRATION.DATE_OF_BIRTH' | translate }}"
            [ngClass]="{'is-invalid': registrationForm.submitted && dateOfBirth.invalid, 'is-valid': registrationForm.submitted && dateOfBirth.valid }"
            [(ngModel)]="userRegistration.dateOfBirth"
            required
            [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY', containerClass: 'theme-custom',  maxDate: maxDateOfBirth}"
            [appMaxDate]="maxDateOfBirth"
            bsDatepicker
          />
          <div class="invalid-feedback" *ngIf="registrationForm.submitted && dateOfBirth.invalid">
            <div *ngIf="dateOfBirth.errors['maxDate']">
              {{ 'VALIDATION.DATE_MAX' | translate: { dateString: maxDateOfBirth.toDateString() } }}
            </div>
            <div *ngIf="dateOfBirth.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
          </div>
        </div>
        <div class="form-group col">
          <div class="d-flex justify-content-between">
            <label for="gender">{{ 'VIEW.PRELIMINARY.REGISTRATION.GENDER' | translate }}</label>
          </div>
          <ng-select
            type="text"
            id="gender"
            name="gender"
            #gender="ngModel"
            placeholder="{{ 'VIEW.PRELIMINARY.REGISTRATION.GENDER' | translate }}"
            [(ngModel)]="userRegistration.gender"
          >
            <ng-option *ngFor="let option of genderList"
                       [value]="option.value">{{ option.label | translate }}
            </ng-option>
          </ng-select>
          <div class="text-sm-light color-orange ml-4 mt-1">* {{ 'COMMON.OPTIONAL' | translate }}</div>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-12">
          <h2 class="mb-6">{{ 'VIEW.PRELIMINARY.REGISTRATION.CHOOSE_SUBSCRIPTION' | translate }}</h2>

          <div class="btn-group btn-tiles" btnRadioGroup name="subscriptionType"
               #subscriptionType="ngModel"
               [ngClass]="{'is-invalid': registrationForm.submitted && subscriptionType.invalid, 'is-valid': registrationForm.submitted && subscriptionType.valid }"
               [(ngModel)]="userRegistration.subscriptionType" required>
            <label class="btn" [btnRadio]="SubscriptionType.ORGANIZATION">
              <var>{{ SubscriptionType.ORGANIZATION | prefix: 'VIEW.PRELIMINARY.REGISTRATION.SUBSCRIPTION_TYPE.' | translate }}</var>
            </label>
            <label class="btn" [btnRadio]="SubscriptionType.RETAIL">
              <var>{{ SubscriptionType.RETAIL | prefix: 'VIEW.PRELIMINARY.REGISTRATION.SUBSCRIPTION_TYPE.' | translate }}</var>
            </label>
          </div>
          <div class="invalid-feedback" *ngIf="registrationForm.submitted && subscriptionType.invalid">
            <div *ngIf="subscriptionType.errors['required']">{{ 'VALIDATION.CHOOSE_TYPE_SUBSCRIPTION' | translate }}</div>
          </div>
        </div>
      </div>

      <ng-container *ngIf="userRegistration.subscriptionType === SubscriptionType.ORGANIZATION">
        <div class="row">
          <div class="form-group col-12 col-sm-6">
            <label for="provider">{{ 'VIEW.PRELIMINARY.REGISTRATION.PROVIDER' | translate }}</label>
            <ng-select
              type="text"
              id="provider"
              name="provider"
              #provider="ngModel"
              placeholder="{{ 'VIEW.PRELIMINARY.REGISTRATION.PROVIDER' | translate }}"
              class="provider-select"
              [ngClass]="{'is-invalid': registrationForm.submitted && provider.invalid, 'is-valid': registrationForm.submitted && provider.valid }"
              [(ngModel)]="userRegistration.organizationId"
              required
            >
              <ng-option *ngFor="let option of organizationsList" [value]="option.id">{{ option.name }}</ng-option>
            </ng-select>
            <div class="invalid-feedback" *ngIf="registrationForm.submitted && provider.invalid">
              <div *ngIf="provider.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
            </div>
            <div class="text-sm-light color-orange ml-4 mt-1">
              {{ 'VIEW.PRELIMINARY.REGISTRATION.CHECK_FAQ' | translate }}
            </div>
          </div>
          <ng-container *ngIf="userRegistration.organizationId && userRegistration.organizationId !== -1">
            <div class="form-group col-12 col-sm-6">
              <label for="memberCode">{{ 'VIEW.PRELIMINARY.REGISTRATION.MEMBER_CODE' | translate }}</label>
              <input
                type="text"
                id="memberCode"
                name="memberCode"
                #memberCode="ngModel"
                class="form-control"
                placeholder="{{ 'VIEW.PRELIMINARY.REGISTRATION.MEMBER_CODE' | translate }}"
                [ngClass]="{'is-invalid': registrationForm.submitted && memberCode.invalid, 'is-valid': registrationForm.submitted && memberCode.valid }"
                [(ngModel)]="userRegistration.memberCode"
                required
                appMemberCodeValidator
              />
              <div class="invalid-feedback" *ngIf="registrationForm.submitted && memberCode.invalid">
                <div *ngIf="memberCode.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
                <div *ngIf="memberCode.errors['memberCode']">{{ 'VALIDATION.MEMBER_CODE' | translate }}</div>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </form>

    <div class="d-flex flex-wrap justify-content-between align-items-center mt-6">
      <p class="m-0">
        {{ 'VIEW.PRELIMINARY.REGISTRATION.ALREADY_HAVE_ACCOUNT' | translate }}
        <button type="button" class="btn btn-link font-weight-600" (click)="backToLogin()">
          {{ 'VIEW.PRELIMINARY.LOGIN.LOGIN' | translate }}
        </button>
      </p>

      <button type="button" id="next-btn" class="btn btn-primary" (click)="validateUserAndGoToNextStep()">
        <div class="d-flex flex-row align-items-center justify-content-center">
          <span>{{ 'COMMON.NEXT' | translate }}</span>
          <svg-icon class="d-flex" src="asset/image/icon/chevron-right.svg"></svg-icon>
        </div>
      </button>
    </div>
  </div>
</app-content-and-image-column-layout>
