import { Transform } from 'class-transformer';
import { DateUtil } from 'app/util/DateUtil';
import { BaseFileDTO } from 'app/data/dto/file/BaseFileDTO';
import { FileType } from 'app/data/enum/file/FileType';

export class FileDTO extends BaseFileDTO {

  public name: string;

  public extension: string;

  public size: number;

  public contentType: string;

  public type: FileType;

  @Transform(DateUtil.dateTimeConversion)
  public createdDate: Date;

}


