import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { VideoRatingRequestDTO } from 'app/data/dto/onDemandVideo/VideoRatingRequestDTO';
import { NgForm } from '@angular/forms';
import { ViewUtil } from 'app/util/ViewUtil';

@Component({
  selector: 'app-rating-form',
  templateUrl: './RatingFormComponent.html',
  styleUrls: [ './RatingFormComponent.scss' ]
})
export class RatingFormComponent {
  @ViewChild('ratingForm')
  public ratingForm: NgForm;

  @Input()
  public heading: string = 'COMPONENT.RATING.HEADING';

  @Input()
  public successMessage: string = 'COMPONENT.RATING.SUCCESS_MESSAGE';

  @Output()
  public rate: EventEmitter<VideoRatingRequestDTO> = new EventEmitter<VideoRatingRequestDTO>();

  public formData: VideoRatingRequestDTO = new VideoRatingRequestDTO();

  constructor(private viewUtil: ViewUtil) {}

  public submit(): void {
    if (!this.ratingForm.valid) {
      return;
    }

    this.rate.emit(this.formData);
    this.viewUtil.showToastSuccess(this.successMessage);
  }
}