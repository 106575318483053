import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Constant } from 'app/common/Constant';
import { State } from 'app/common/State';
import { ApplicationConfig } from 'app/config/ApplicationConfig';
import { ServerErrorCode } from 'app/data/enum/ServerErrorCode';
import { AuthMode } from 'app/data/local/AuthMode';
import { ApplicationModel } from 'app/model/ApplicationModel';
import { AuthModel } from 'app/model/AuthModel';
import { UserModel } from 'app/model/UserModel';
import { StateUtil } from 'app/util/StateUtil';
import { ViewUtil } from 'app/util/ViewUtil';

@Component({
  selector: 'app-account-change-password',
  templateUrl: 'AccountChangePasswordComponent.html',
  styleUrls: [ 'AccountChangePasswordComponent.scss' ]
})
export class AccountChangePasswordComponent {
  public formData: {
    currentPassword: string;
    newPassword: string;
    newPasswordRepeat: string;
  } = {
    currentPassword: null,
    newPassword: null,
    newPasswordRepeat: null
  };

  public forcedPasswordChangeMode = false;
  public editMode = false;
  public Constant: typeof Constant = Constant;
  @ViewChild('changePasswordForm')
  private changePasswordForm: NgForm;

  constructor(
    public viewUtil: ViewUtil,
    public stateUtil: StateUtil,
    private applicationModel: ApplicationModel,
    private userModel: UserModel,
    private authModel: AuthModel
  ) {
    this.forcedPasswordChangeMode =
      applicationModel.currentState.state.name === State.MAIN.ACCOUNT.PASSWORD_CHANGE_REQUIRED;
  }

  public cancelEdit(): void {
    this.formData = {
      currentPassword: null,
      newPassword: null,
      newPasswordRepeat: null
    };
    this.editMode = false;
  }

  public changePassword(): void {
    this.changePasswordForm.onSubmit(null);

    if (this.changePasswordForm.form.valid) {
      let passwordPromise: Promise<void>;

      if (this.forcedPasswordChangeMode) {
        passwordPromise = this.authModel.changePasswordForced(this.formData.newPassword);
      }
      else {
        passwordPromise = this.authModel.changePassword(this.formData.currentPassword, this.formData.newPassword);
      }

      passwordPromise
        .then(() => {
          if (this.forcedPasswordChangeMode) {
            if (ApplicationConfig.authMode === AuthMode.CUSTOM_OAUTH2) {
              this.viewUtil.showToastSuccess('VIEW.MAIN.ACCOUNT.CHANGE_PASSWORD.CHANGE_PASSWORD_SUCCESS');

              // update current user in the app model (in case something changes in UserDTO as a result of a forced password change)
              this.userModel
                .getCurrentUser()
                .then(() => {
                  this.stateUtil.goToDashboard();
                })
                .catch((error) => {
                  this.viewUtil.handleServerError(error);
                });
            }
            else if (ApplicationConfig.authMode === AuthMode.COGNITO) {
              this.authModel
                .logout()
                .then(() => {
                  this.viewUtil.showToastSuccess('VIEW.MAIN.ACCOUNT.CHANGE_PASSWORD.CHANGE_PASSWORD_REQUIRED_SUCCESS');
                })
                .catch(() => {
                });
            }
          }
          else {
            this.viewUtil.showToastSuccess('VIEW.MAIN.ACCOUNT.CHANGE_PASSWORD.CHANGE_PASSWORD_SUCCESS');
            this.formData = {
              currentPassword: null,
              newPassword: null,
              newPasswordRepeat: null
            };
            this.editMode = false;
          }
        })
        .catch((error) => {
          this.viewUtil.handleServerError(error);
          if (error?.error?.errorCode === ServerErrorCode.INVALID_PASSWORD) {
            this.changePasswordForm.form.get('currentPassword').setErrors({ invalid: true });
          }
        });
    }
  }
}
