import { VideoCategory } from 'app/data/enum/video/VideoCategory';
import { Transform, Type } from 'class-transformer';
import { DateUtil } from 'app/util/DateUtil';

import { IdentifiableObjectDTO } from '../IdentifiableObjectDTO';
import { ImageResponseDTO } from '../images/ImageResponseDTO';
import { CoachSeniorDetailsPageDTO } from 'app/data/dto/liveClassSeries/senior/CoachSeniorDetailsPageDTO';

export class ProgramAdminDetailsPageDTO extends IdentifiableObjectDTO<number> {
  public category: VideoCategory;

  @Type(() => CoachSeniorDetailsPageDTO)
  public coaches: CoachSeniorDetailsPageDTO[];

  @Transform(DateUtil.dateTimeConversion)
  public createdDate: Date;

  @Type(() => ImageResponseDTO)
  public thumbnail: ImageResponseDTO;

  public title: string;

  public videoCount: string;

  public viewCount: string;
}
