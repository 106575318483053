import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, SuppressKeyboardEventParams } from 'ag-grid-community';
import { DataGridContext } from 'app/component/ui/dataGrid/DataGridContext';
import { ResponsiveDataGridComponent } from 'app/component/ui/responsiveDataGrid/ResponsiveDataGridComponent';
import { UserDTO } from 'app/data/dto/user/UserDTO';
import { UserListTabs } from 'app/data/enum/userList/UserListTabs';

import { DataGridComponent } from '../../../../../ui/dataGrid/DataGridComponent';

@Component({
  selector: 'app-user-list-actions-cell-renderer',
  styleUrls: [ 'UserListActionsCellRendererComponent.scss' ],
  templateUrl: 'UserListActionsCellRendererComponent.html'
})
export class UserListActionsCellRendererComponent implements ICellRendererAngularComp {
  public static readonly ACTION_DETAILS_USER: string = 'details_user';
  public static readonly ACTION_DELETE_USER: string = 'delete_user';
  public static readonly ACTION_EDIT: string = 'edit';
  public static readonly ACTION_GENERATE_NEW_PASSWORD: string = 'generate_new_password';
  public static readonly ACTION_RESEND_TEMPORARY_PASSWORD: string = 'resend_temporary_password';
  public static readonly ACTION_ENABLE: string = 'enable';
  public static readonly ACTION_DISABLE: string = 'disable';
  public static readonly ACTION_DELETE: string = 'delete';

  public params: ICellRendererParams;
  public user: UserDTO;
  public context: DataGridContext;
  public activeTab: UserListTabs;
  public userListTabs = UserListTabs;

  public agInit(params: ICellRendererParams): void {
    this.params = params;
    this.user = params.data;
    this.context = params.context;
    this.activeTab = params.context.activeTab;
  }

  public refresh(params: ICellRendererParams): boolean {
    return false;
  }

  public preventGridSelection(event: Event): void {
    if (this.context.dataGridHost instanceof DataGridComponent) {
      const previousValue = this.params.node['gridOptionsWrapper'].gridOptions.suppressRowClickSelection;
      this.params.node['gridOptionsWrapper'].gridOptions.suppressRowClickSelection = true;

      setTimeout(() => {
        this.params.node['gridOptionsWrapper'].gridOptions.suppressRowClickSelection = previousValue;
      });
    }
    else if (this.context.dataGridHost instanceof ResponsiveDataGridComponent) {
      event.stopImmediatePropagation();
      event.preventDefault();
    }
  }

  public onDetailsClick(): void {
    this.context.dataGridAction(UserListActionsCellRendererComponent.ACTION_DETAILS_USER, [ this.user ]);
  }

  public onDeleteClick(): void {
    this.context.dataGridAction(UserListActionsCellRendererComponent.ACTION_DELETE_USER, [ this.user ]);
  }

  public static suppressKeyboardEvent(params: SuppressKeyboardEventParams): boolean {
    return true;
  }
}
