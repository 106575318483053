import { Component, OnDestroy } from '@angular/core';
import { CoachDTO } from 'app/data/dto/coach/CoachDTO';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { CoachSeniorDetailsPageDTO } from 'app/data/dto/liveClassSeries/senior/CoachSeniorDetailsPageDTO';

@Component({
  selector: 'app-coach-details',
  templateUrl: './CoachDetailsComponent.html',
  styleUrls: [ './CoachDetailsComponent.scss' ]
})
export class CoachDetailsComponent implements OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();

  public coaches: CoachSeniorDetailsPageDTO[];
  public coachesList: CoachDTO[] = [];

  constructor(public bsModalRef: BsModalRef) {
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onCloseClick(): void {
    this.bsModalRef.hide();
  }
}
