import { Injectable, Injector } from '@angular/core';
import { OrganizationEssentialsDTO } from 'app/data/dto/registration/OrganizationEssentialsDTO';
import { OrganizationListDTO } from 'app/data/dto/registration/OrganizationListDTO';
import { SeniorRegistrationDTO } from 'app/data/dto/registration/SeniorRegistrationDTO';
import { SeniorValidationRequestDTO } from 'app/data/dto/registration/SeniorValdiationRequestDTO';
import { SeniorValidationResponseDTO } from 'app/data/dto/registration/SeniorValidationResponseDTO';
import { UserRegistrationService } from 'app/service/UserRegistrationService';
import { UserRegistrationServiceInterface } from 'app/service/interface/UserRegistrationServiceInterface';
import { EventManager } from 'app/util/other/EventManager';
import { Observable } from 'rxjs';
import { RegistrationType } from 'app/data/enum/user/RegistrationType';
import { Constant } from 'app/common/Constant';

@Injectable({ providedIn: 'root' })
export class UserRegistrationModel {
  private userRegistrationService: UserRegistrationServiceInterface; // injected in constructor body

  constructor(
    private eventManager: EventManager,
    private injector: Injector
  ) {
    this.setupService();
    this.setupListeners();
  }

  private setupService(): void {
    this.userRegistrationService = this.injector.get(UserRegistrationService);
  }

  private setupListeners(): void {
  }

  public registerSenior(userRegistration: SeniorRegistrationDTO): Observable<unknown> {
    return this.userRegistrationService.registerSenior(userRegistration);
  }

  public validateSenior(seniorValidation: SeniorValidationRequestDTO): Observable<SeniorValidationResponseDTO> {
    return this.userRegistrationService.validateSenior(seniorValidation);
  }

  public getOrganizationsList(): Observable<OrganizationListDTO[]> {
    return this.userRegistrationService.getOrganizationsList();
  }

  public getOrganizationsDetails(organizationStub: string): Observable<OrganizationEssentialsDTO> {
    return this.userRegistrationService.getOrganizationsDetails(organizationStub);
  }

  public confirmRegistration(username: string, confirmationCode: string): Observable<unknown> {
    const registrationType: RegistrationType = username.match(Constant.EMAIL_ADDRESS_PATTERN) ? RegistrationType.EMAIL : RegistrationType.PHONE;
    return this.userRegistrationService.confirmRegistration(username, registrationType, confirmationCode);
  }
}
