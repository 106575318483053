import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpEventType, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { ApplicationConfig } from 'app/config/ApplicationConfig';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FileTokenDTO } from 'app/data/dto/file/FileTokenDTO';
import { Constant } from 'app/common/Constant';
import { ObjectUtil } from 'app/util/ObjectUtil';
import { FileDTO } from 'app/data/dto/file/FileDTO';
import { CustomHttpParameterEncoder } from 'app/util/other/CustomHttpParameterEncoder';
import * as _ from 'lodash';
import { UrlDTO } from 'app/data/dto/file/UrlDTO';
import { FileServiceInterface } from 'app/service/interface/FileServiceInterface';
import { FileUtil } from 'app/util/FileUtil';

@Injectable({ providedIn: 'root' })
export class FileService implements FileServiceInterface {

  constructor(private http: HttpClient,
              private fileUtil: FileUtil) {
  }

  public uploadFile(file: File, silent: boolean): Observable<FileTokenDTO | number> {
    const requestData: FormData = new FormData();
    let context: HttpContext = new HttpContext();

    requestData.append('file', file, file.name);

    if (silent) {
      context.set(Constant.HTTP_SUPPRESS_LOADING_TOKEN, true);
    }

    const request: HttpRequest<FormData> =
      new HttpRequest('POST', `${ ApplicationConfig.fileUrl }/files`, requestData, {
        reportProgress: true,
        context: context
      });

    return this.http.request(request)
      .pipe(
        map((event: any) => {
          if (event.type === HttpEventType.Sent) {
            return 0;
          }
          else if (event.type === HttpEventType.UploadProgress) {
            return Math.round(100 * event.loaded / event.total);
          }
          else if (event.type === HttpEventType.ResponseHeader) {
            return 100;
          }
          else if (event.type === HttpEventType.DownloadProgress) {
            return 100;
          }
          else if (event.type === HttpEventType.Response && (event instanceof HttpResponse)) {
            const fileToken: FileTokenDTO = ObjectUtil.plainToClass(FileTokenDTO, event.body);
            return fileToken;
          }
          else {
            return null;
          }
        })
      );
  }

  public uploadAuthorizedFile(file: File, silent: boolean): Observable<FileDTO | number> {
    const requestData: FormData = new FormData();
    let context: HttpContext = new HttpContext();

    requestData.append('file', file, file.name);

    if (silent) {
      context.set(Constant.HTTP_SUPPRESS_LOADING_TOKEN, true);
    }

    const request: HttpRequest<FormData> =
      new HttpRequest('POST', `${ ApplicationConfig.fileUrl }/files/authorized-upload`, requestData, {
        reportProgress: true,
        context: context
      });

    return this.http.request(request)
      .pipe(
        map((event: any) => {
          if (event.type === HttpEventType.Sent) {
            return 0;
          }
          else if (event.type === HttpEventType.UploadProgress) {
            return Math.round(100 * event.loaded / event.total);
          }
          else if (event.type === HttpEventType.ResponseHeader) {
            return 100;
          }
          else if (event.type === HttpEventType.DownloadProgress) {
            return 100;
          }
          else if (event.type === HttpEventType.Response && (event instanceof HttpResponse)) {
            const fileDto: FileDTO = ObjectUtil.plainToClass(FileDTO, event.body);
            return fileDto;
          }
          else {
            return null;
          }
        })
      );
  }

  public getFile(fileId: number): Observable<FileDTO> {
    return this.http.get(`${ ApplicationConfig.fileUrl }/files/${ fileId }`)
      .pipe(
        map((response: any) => {
          const file: FileDTO = ObjectUtil.plainToClass(FileDTO, response);
          return file;
        })
      );
  }

  public getFiles(fileIds: number[]): Observable<{ [key: string]: FileDTO }> {
    const params: HttpParams = new HttpParams({
      encoder: new CustomHttpParameterEncoder(),
      fromObject: {
        ids: fileIds.join(',')
      }
    });

    return this.http.get(`${ ApplicationConfig.fileUrl }/files`, { params: params })
      .pipe(
        map((response: any) => {
          const result: { [key: string]: FileDTO } = {};

          _.forOwn(response, (value: any, key: string) => {
            result[key] = ObjectUtil.plainToClass(FileDTO, value);
          });

          return result;
        })
      );
  }

  public deleteFile(fileId: number): Observable<void> {
    return this.http.delete(`${ ApplicationConfig.fileUrl }/files/${ fileId }`)
      .pipe(
        map((response: any) => {
          return;
        })
      );
  }

  public getFileDownloadUrl(fileId: number): Observable<UrlDTO> {
    return this.http.get(`${ ApplicationConfig.fileUrl }/files/${ fileId }/download-url`)
      .pipe(
        map((response: any) => {
          const url: UrlDTO = ObjectUtil.plainToClass(UrlDTO, response);
          return url;
        })
      );
  }

  public getFilesDownloadUrls(fileIds: number[]): Observable<{ [key: string]: UrlDTO }> {
    const params: HttpParams = new HttpParams({
      encoder: new CustomHttpParameterEncoder(),
      fromObject: {
        ids: fileIds.join(',')
      }
    });

    return this.http.get(`${ ApplicationConfig.fileUrl }/files/download-urls`, { params: params })
      .pipe(
        map((response: any) => {
          const result: { [key: string]: UrlDTO } = {};

          _.forOwn(response, (value: any, key: string) => {
            result[key] = ObjectUtil.plainToClass(UrlDTO, value);
          });

          return result;
        })
      );
  }

  public downloadFile(fileId: number): Observable<void> {
    const params: HttpParams = new HttpParams({
      encoder: new CustomHttpParameterEncoder(),
      fromObject: {
        download: 'true'
      }
    });

    return this.http.get(`${ ApplicationConfig.fileUrl }/files/${ fileId }/resource`, {
      params: params,
      observe: 'response',
      responseType: 'blob'
    })
      .pipe(
        map((response: HttpResponse<Blob>) => {
          this.fileUtil.saveFileFromResponse(response);
          return;
        })
      );
  }

  public loadFile(fileId: number): Observable<Blob> {
    const params: HttpParams = new HttpParams({
      encoder: new CustomHttpParameterEncoder(),
      fromObject: {
        download: 'false'
      }
    });

    return this.http.get(`${ ApplicationConfig.fileUrl }/files/${ fileId }/resource`, {
      params: params,
      observe: 'response',
      responseType: 'blob'
    })
      .pipe(
        map((response: HttpResponse<Blob>) => {
          return response.body;
        })
      );
  }

  public downloadFileByToken(fileToken: string): Observable<void> {
    const params: HttpParams = new HttpParams({
      encoder: new CustomHttpParameterEncoder(),
      fromObject: {
        download: 'true'
      }
    });

    return this.http.get(`${ ApplicationConfig.fileUrl }/files/token/${ fileToken }/resource`, {
      params: params,
      observe: 'response',
      responseType: 'blob'
    })
      .pipe(
        map((response: HttpResponse<Blob>) => {
          this.fileUtil.saveFileFromResponse(response);
          return;
        })
      );
  }

  public loadFileByToken(fileToken: string): Observable<Blob> {
    const params: HttpParams = new HttpParams({
      encoder: new CustomHttpParameterEncoder(),
      fromObject: {
        download: 'false'
      }
    });

    return this.http.get(`${ ApplicationConfig.fileUrl }/files/token/${ fileToken }/resource`, {
      params: params,
      observe: 'response',
      responseType: 'blob'
    })
      .pipe(
        map((response: HttpResponse<Blob>) => {
          return response.body;
        })
      );
  }

}
