import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ValueFormatterParams } from 'ag-grid-community';
import { DataGridAction } from 'app/component/ui/dataGrid/DataGridAction';
import { PopupConfirmationComponent } from 'app/component/ui/popup/PopupConfirmationComponent';
import { PageCriteriaDTO } from 'app/data/dto/PageCriteriaDTO';
import { PageDTO } from 'app/data/dto/PageDTO';
import { ProgramAdminDetailsPageDTO } from 'app/data/dto/programs/ProgramAdminDetailsPageDTO';
import { ProgramAdminPageCriteriaDTO } from 'app/data/dto/programs/ProgramAdminPageCriteriaDTO';
import { ProgramIdListDTO } from 'app/data/dto/programs/ProgramIdListDTO';
import { OrganizationListDTO } from 'app/data/dto/registration/OrganizationListDTO';
import { SeniorDetailsPageDTO } from 'app/data/dto/senior/SeniorDetailsPageDTO';
import { AdminModel } from 'app/model/AdminModel';
import { StateUtil } from 'app/util/StateUtil';
import { ViewUtil } from 'app/util/ViewUtil';
import { FullNamePipe } from 'app/util/pipe/FullNamePipe';
import { PrefixPipe } from 'app/util/pipe/PrefixPipe';
import _ from 'lodash';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, of, pipe, Subject } from 'rxjs';
import { catchError, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { State } from 'app/common/State';
import { ProgramSortBy } from 'app/data/enum/program/ProgramSortBy';
import { OptionItem } from 'app/data/local/generic/OptionItem';
import { BaseCriteriaSortOrder } from 'app/data/dto/BaseCriteriaDTO';
import { SortDirection } from 'app/data/enum/SortDirection';
import { ProgramListActionsComponent } from './components/cellRender/ProgramListActionsComponent';
import { OnDemandVideoFilterModalComponent } from '../../common/filters/OnDemandVideoFilterModalComponent';
import { ProgramsFilterModalComponent } from '../../common/filters/ProgramsFilterModalComponent';
import { DataGridStarRatingCellRendererComponent } from 'app/component/ui/dataGrid/cellRenderer/DataGridStarRatingCellRendererComponent';
import { BaseUserDTO } from 'app/data/dto/user/BaseUserDTO';

@Component({
  selector: 'app-program-admin-list',
  templateUrl: './ProgramAdminListComponent.html',
  styleUrls: [ './ProgramAdminListComponent.scss' ]
})
export class ProgramAdminListComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();

  public page: PageDTO<ProgramAdminDetailsPageDTO>;
  public criteria: ProgramAdminPageCriteriaDTO = new ProgramAdminPageCriteriaDTO();
  public selectedUsers: SeniorDetailsPageDTO[] = [];
  public organizationsList$: Observable<OrganizationListDTO[]>;
  public selectedItems: ProgramAdminDetailsPageDTO[] = [];
  public sortByOptions: OptionItem<string>[] = this.viewUtil.createEnumSelectOptions(ProgramSortBy, 'VIDEO_SORT_BY');

  private program: ProgramAdminDetailsPageDTO;

  public StarRatingCellRendererComponent: typeof DataGridStarRatingCellRendererComponent = DataGridStarRatingCellRendererComponent;
  public ActionsCellRendererComponent: typeof ProgramListActionsComponent = ProgramListActionsComponent;

  public activeTab: string = 'ACTIVE';

  public coachFormatter: (params: ValueFormatterParams) => any = (params: ValueFormatterParams) => {
    const coachUser: BaseUserDTO = params.data.coaches[0].user as BaseUserDTO;
    const fullName: string = this.fullNamePipe.transform(coachUser.firstName, coachUser.lastName) as string;
    return fullName;
  };

  public enumCategoryFormatter: (params: ValueFormatterParams) => any = (params: ValueFormatterParams) =>
    this.viewUtil.dataGridPipeValueFormatterWithTranslate(params, PrefixPipe, 'ENUM.VIDEO_CATEGORY.');

  public dateValueFormatter: (params: ValueFormatterParams) => any = (params: ValueFormatterParams) => {
    const date: string = this.viewUtil.dataGridPipeValueFormatter(params, DatePipe, 'MM/dd/yyyy');
    return `${ date }`;
  };

  constructor(
    private adminModel: AdminModel,
    public viewUtil: ViewUtil,
    private fullNamePipe: FullNamePipe,
    private modalService: BsModalService,
    private stateUtil: StateUtil
  ) {
  }

  public ngOnInit(): void {
    this.getPrograms();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public checkboxSelectionFunction(TData: any, TValue: any): boolean {
    const { data } = TData;
    return data.id !== null && data.name !== null;
  }

  public openFiltersModal(): void {
    const { anyOfCoachIds, anyOfCategories } = this.criteria;
    const modal: BsModalRef = this.modalService.show(ProgramsFilterModalComponent, {
      initialState: {
        anyOfCoachIds,
        anyOfCategories
      },
      class: 'modal-dialog-centered modal-filterLiveClasses'
    });
    modal.onHide
      .pipe(
        filter(
          (reason) =>
            reason === OnDemandVideoFilterModalComponent.FILTER_DATA ||
            reason === OnDemandVideoFilterModalComponent.CLEAR_FILTERS
        ),
        tap(() => {
          const { categories, coaches } = modal.content.filters;
          this.criteria = {
            ...this.criteria,
            anyOfCategories: categories.selectedCategories.length > 0 ? categories.selectedCategories : undefined,
            anyOfCoachIds: coaches.selectedCoaches.length > 0 ? coaches.selectedCoaches : undefined
          } as ProgramAdminPageCriteriaDTO;
          this.getPrograms();
        })
      )
      .subscribe();
  }

  public onCriteriaChanged(changedCriteria: PageCriteriaDTO): void {
    const criteriaCopy: ProgramAdminPageCriteriaDTO = _.cloneDeep(this.criteria);
    _.assignIn(criteriaCopy, changedCriteria);
    this.criteria = criteriaCopy;
    this.getPrograms();
  }

  public onGridAction(action: DataGridAction): void {
    this.program = action.args[0] as ProgramAdminDetailsPageDTO;
    if (action.actionName === ProgramListActionsComponent.ADMIN_DELETE) {
      this.deletePrograms({ ids: [ this.program.id ] });
    }
  }

  public onSelectionChange(selectedItems: ProgramAdminDetailsPageDTO[]): void {
    this.selectedItems = selectedItems;
  }

  public onViewClick({ id }: ProgramAdminDetailsPageDTO): void {
    this.stateUtil.goToState(State.MAIN.ADMIN.PROGRAM.DETAILS, { id });
  }

  public onDeleteClick(program: ProgramAdminDetailsPageDTO): void {
    this.program = program;
    this.deletePrograms({ ids: [ program.id ] });
  }

  public onSortOptionChange(sortBy: ProgramSortBy): void {
    if (!sortBy) {
      delete this.criteria.sortOrders;
      this.getPrograms();
      return;
    }

    let sortOrders: BaseCriteriaSortOrder;
    let sortField: string;
    const sortDirection: SortDirection = SortDirection.DESCENDING;

    switch (sortBy) {
      case ProgramSortBy.MOST_RECENT: {
        sortField = 'createdDate';
        break;
      }
      case ProgramSortBy.VIEW_COUNT: {
        sortField = 'viewCount';
        break;
      }
      case ProgramSortBy.RATING: {
        sortField = 'rating';
        break;
      }
    }

    sortOrders = `${ sortField } ${ sortDirection }`;
    this.criteria.sortOrders = [ sortOrders ];
    this.getPrograms();
  }


  private getPrograms(): void {
    this.adminModel
      .getProgramPageAdmin(this.criteria)
      .pipe(
        tap((response) => {
          this.page = response;
        }),
        takeUntil(this.destroy$),
        catchError((err) => {
          this.viewUtil.handleServerError(err);
          return of(null);
        })
      )
      .subscribe();
  }

  private deletePrograms(ids: ProgramIdListDTO): void {
    const modal: BsModalRef = this.modalService.show(PopupConfirmationComponent, {
      initialState: {
        message: 'VIEW.MAIN.USER.DELETE_PROGRAM_WARNING',
        messageVariables: { title: this.program.title },
        okText: 'COMMON.YES',
        cancelText: 'COMMON.NO'
      },
      class: 'modal-dialog-centered'
    });

    modal.onHide
      .pipe(
        filter((reason) => reason === PopupConfirmationComponent.POPUP_RESULT_CONFIRM),
        switchMap(() => this.adminModel.deleteProgram(ids)),
        pipe(
          tap(() => {
            this.viewUtil.showToastSuccess('COMMON.SUCCESS');
            this.getPrograms();
          })
        ),
        takeUntil(this.destroy$),
        catchError((err) => {
          this.viewUtil.handleServerError(err);
          return of(null);
        })
      )
      .subscribe();
  }

}
