import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Constant } from 'app/common/Constant';
import { ScheduledLiveClassRatingRequestDTO } from 'app/data/dto/scheduledLiveClass/ScheduledLiveClassRatingRequestDTO';
import { ScheduledLiveClassUnratedResponseDTO } from 'app/data/dto/scheduledLiveClass/ScheduledLiveClassUnratedResponseDTO';
import { UserModel } from 'app/model/UserModel';
import { ViewUtil } from 'app/util/ViewUtil';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { forkJoin, of, Subject } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-thank-you-user',
  templateUrl: './ThankYouComponent.html',
  styleUrls: [ './ThankYouComponent.scss' ]
})
export class ThankYouComponent implements OnInit, OnDestroy {
  @ViewChild('form') form: NgForm;
  public ratingRequests: ScheduledLiveClassRatingRequestDTO[] = [];

  private destroy$: Subject<void> = new Subject<void>();

  // This component may well support several classes but then BE need to support skipping
  //this.userModel.getUnratedScheduledLiveClasses()
  public unratedScheduledLiveClasses: ScheduledLiveClassUnratedResponseDTO[] = [];
  public onlyOneClass: boolean = false;
  public Constant: typeof Constant = Constant;


  public activeTabIndex: number = 0;

  constructor(
    private viewUtil: ViewUtil,
    private bsModalRef: BsModalRef,
    private userModel: UserModel
  ) {
  }

  public ngOnInit(): void {
    this.onlyOneClass = this.unratedScheduledLiveClasses.length === 1;
    this.ratingRequests = this.unratedScheduledLiveClasses.map(() => ({
      feedback: '',
      rating: 0
    }));
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public changeTab(index: number): void {
    if (index >= 0 && index < this.unratedScheduledLiveClasses.length) {
      this.activeTabIndex = index;
    }
  }

  public isFirstTab(): boolean {
    return this.activeTabIndex === 0;
  }

  public isLastTab(): boolean {
    return this.activeTabIndex === this.unratedScheduledLiveClasses.length - 1;
  }

  public onHideClick(): void {
    this.bsModalRef.hide();
  }

  public onRatingChange(rating: number): void {
    this.ratingRequests[this.activeTabIndex].rating = rating;
  }

  public onSendClick(): void {
    this.form.onSubmit(undefined);
    const filledRatingRequests = this.unratedScheduledLiveClasses
      .map((req, index) => ({ id: req.id, ratingRequest: this.ratingRequests[index] }))
      .filter(({ ratingRequest }) => ratingRequest.rating > 0 && ratingRequest.feedback.trim() !== '');

    const ratingSubmissions = filledRatingRequests.map(({ id, ratingRequest }) =>
      this.userModel.rateScheduledLiveClass(id, ratingRequest)
    );
    if (ratingSubmissions.length > 0) {
      forkJoin(ratingSubmissions)
        .pipe(
          takeUntil(this.destroy$),
          tap(() => {
            this.bsModalRef.hide(), this.viewUtil.showToastSuccess('COMMON.SUCCESS');
          }),

          catchError((err) => {
            this.viewUtil.handleServerError(err);
            return of(null);
          })
        )
        .subscribe();
    }
  }
}
