import { Component } from '@angular/core';
import { StateService, Transition } from '@uirouter/angular';
import { State } from 'app/common/State';
import { UserModel } from 'app/model/UserModel';
import { ViewUtil } from 'app/util/ViewUtil';

@Component({
  selector: 'app-reset-password-complete',
  templateUrl: 'WithoutVerificationComponent.html',
  styleUrls: [ 'WithoutVerificationComponent.scss' ]
})
export class WithoutVerificationComponent {
  private username: string;

  constructor(
    private stateService: StateService,
    private transition: Transition,
    private userModel: UserModel,
    private viewUtil: ViewUtil
  ) {
    const username = transition.params().username;

    if (!username) {
      this.goBack();
    }
    else {
      this.username = username;
    }
  }

  public goBack(): void {
    this.stateService.go(State.PRELIMINARY.LOGIN);
  }

  public resendConfirmationLink(): void {
    this.userModel.resendConfirmationCode(this.username).subscribe(
      () => {
        this.viewUtil.showToastSuccess('COMMON.SUCCESS');
      },
      (err) => {
        this.viewUtil.handleServerError(err);
      }
    );
  }
}
