export class SeniorActivitySummaryDTO {
  public activeDays: number;

  public badgesEarnedCount: number;

  public liveClassesTakenCount: number;

  public liveClassesTakenDuration: number;

  public videosWatchedCount: number;

  public videosWatchedDuration: number;
}
