import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationConfig } from 'app/config/ApplicationConfig';
import { ObjectUtil } from 'app/util/ObjectUtil';
import { WatchPartyCreateDTO } from 'app/data/dto/watchParty/WatchPartyCreateDTO';
import { HttpClient, HttpParams } from '@angular/common/http';
import { WatchPartyUpdateDTO } from 'app/data/dto/watchParty/WatchPartyUpdateDTO';
import { WatchPartyDTO } from 'app/data/dto/watchParty/WatchPartyDTO';
import { map } from 'rxjs/operators';
import { PageDTO } from 'app/data/dto/PageDTO';
import { CustomHttpParameterEncoder } from 'app/util/other/CustomHttpParameterEncoder';
import { WatchPartyPageCriteriaDTO } from 'app/data/dto/watchParty/WatchPartyPageCriteriaDTO';

@Injectable({ providedIn: 'root' })
export class WatchPartyService {

  constructor(private readonly http: HttpClient) {}

  public getById(id: number): Observable<WatchPartyDTO> {
      return this.http.get<WatchPartyDTO>(`${ ApplicationConfig.apiUrl }/watchparties/${ id }`)
      .pipe(
        map((result: WatchPartyDTO) => ObjectUtil.plainToClass(WatchPartyDTO, result))
      );
  }

  public getPage(criteria: WatchPartyPageCriteriaDTO): Observable<PageDTO<WatchPartyDTO>> {
    const params: HttpParams = new HttpParams({
      encoder: new CustomHttpParameterEncoder(),
      fromObject: ObjectUtil.valueAsString(ObjectUtil.classToPlain(criteria, true, true, true, true))
    });
    return this.http.get(`${ ApplicationConfig.apiUrl }/watchparties/page`, { params: params }).pipe(
    map((response: any) => ObjectUtil.plainToClassFromExisting(new PageDTO<WatchPartyDTO>(WatchPartyDTO), response))
    );
  }

  public create(data: WatchPartyCreateDTO): Observable<void> {
    return this.http.post<void>(`${ ApplicationConfig.apiUrl }/watchparties`, data);
  }

  public update(id: number, data: WatchPartyUpdateDTO): Observable<void> {
    return this.http.put<void>(`${ ApplicationConfig.apiUrl }/watchparties/${ id }`, ObjectUtil.classToPlain(data));
  }

  public delete(id: number): Observable<void> {
    return this.http.delete<void>(`${ ApplicationConfig.apiUrl }/watchparties/${ id }`);
  }

  public drop(id: number): Observable<void> {
    return this.http.post<void>(`${ ApplicationConfig.apiUrl }/watchparties/${ id }/drop`, undefined);
  }

  public reject(id: number): Observable<void> {
    return this.http.post<void>(`${ ApplicationConfig.apiUrl }/watchparties/${ id }/reject`, undefined);
  }

  public accept(id: number): Observable<void> {
    return this.http.post<void>(`${ ApplicationConfig.apiUrl }/watchparties/${ id }/accept`, undefined);
  }

  public signup(id: number): Observable<void> {
    return this.http.post<void>(`${ ApplicationConfig.apiUrl }/watchparties/${ id }/signup`, undefined);
  }
}