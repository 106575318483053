<app-data-grid
  [data]="page?.content"
  [criteria]="criteria"
  [context]="{ host: this, additionalData: { currentUser } }"
  [showFilters]="false"
  [showSearch]="false"
  [showPagination]="false"
  [pageTotal]="page?.totalPages"
  [itemTotal]="page?.totalElements"
  [showItemsPerPage]="true"
  [showTotalItems]="false"
  [selectionEnabled]="true"
  [multiSelectionEnabled]="true"
  [useCheckboxSelection]="true"
  (criteriaChanged)="onCriteriaChanged($event)"
  [columns]="$any([
        { headerName: ('DOMAIN.USER.DATA.FIRST_NAME' | translate), minWidth: 150, maxWidth: 250, field: 'user.firstName', cellRendererFramework: DataGridTemplateCellRendererComponent, cellRendererParams: { ngTemplate: firstNameTemplate } },
        { headerName: ('DOMAIN.USER.DATA.LAST_NAME' | translate), minWidth: 120, maxWidth: 200, field: 'user.lastName' },
        { headerName: ('DOMAIN.FRIEND.DATA.LIVE_CLASSES' | translate), minWidth: 120, maxWidth: 200, field: 'liveClassesCount', cellRendererFramework: DataGridTemplateCellRendererComponent, cellRendererParams: { ngTemplate: amountTemplate }},
        { headerName: ('DOMAIN.FRIEND.DATA.ON_DEMAND' | translate), minWidth: 110, maxWidth: 180, field: 'videosCount', cellRendererFramework: DataGridTemplateCellRendererComponent, cellRendererParams: { ngTemplate: amountTemplate }},
        { headerName: ('DOMAIN.FRIEND.DATA.BADGES' | translate), minWidth: 100, maxWidth: 160, field: 'badges', cellRendererFramework: DataGridTemplateCellRendererComponent, cellRendererParams: { ngTemplate: badgesTemplate } },
        { headerName: ('DOMAIN.FRIEND.DATA.SENT' | translate), minWidth: 140, maxWidth: 220, field: 'invitationSendDate', cellRendererFramework: DataGridTemplateCellRendererComponent, cellRendererParams: { ngTemplate: dateTemplate } },
        { headerName: '', headerClass: 'header-right', suppressSizeToFit: true, flex: 1, cellClass: 'cell-fullwidth', cellRendererFramework: DataGridTemplateCellRendererComponent, cellRendererParams: { ngTemplate: actionsTemplate } },
      ])">
</app-data-grid>

<div class="d-flex justify-content-center" *ngIf="page?.isMore">
  <button type="button" class="btn btn-link" (click)="nextPage()">{{ 'COMMON.LOAD_MORE' | translate }}</button>
</div>

<!--Grid templates-->
<ng-template #firstNameTemplate let-data let-value="value">
  <div class="d-flex align-items-center h-100 line-height-normal gap-3">
    <app-avatar [src]="data.user?.avatar?.thumbnailAsset?.url" size="40px" [circle]="true"></app-avatar>
    <span>{{ value | replaceEmpty }}</span>
  </div>
</ng-template>
<ng-template #amountTemplate let-value="value">{{ value || '?' }}</ng-template>
<ng-template #dateTemplate let-value="value">{{ value | date:'shortDate' | replaceEmpty }}</ng-template>
<ng-template #badgesTemplate let-value="value">
  <span tooltip="{{value | mapCollection:'shortDescription' | arrayJoin:','}}"
        container="body"
        placement="left">{{ value?.length || '?' }}</span>
</ng-template>
<ng-template #actionsTemplate let-data>
  <div class="d-flex justify-content-end align-items-center h-100">
    <button type="button" class="btn btn-sm btn-link btn-link--danger" (click)="removeInvitations(data)">
      <div class="d-flex align-items-center gap-2">
        <svg-icon class="d-flex" src="asset/image/icon/circle-minus.svg"></svg-icon>
        <span>{{ 'DOMAIN.FRIEND_INVITATION.ACTION.REMOVE' | translate }}</span>
      </div>
    </button>
  </div>
</ng-template>