import { Component, Input } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'app-form-nested',
  templateUrl: 'FormNestedComponent.html',
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class FormNestedComponent {

  @Input()
  public data: {
    external: string;
    internal: string;
  };

  constructor(public form: NgForm) {
  }
}
