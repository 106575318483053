import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, SuppressKeyboardEventParams } from 'ag-grid-community';
import { State } from 'app/common/State';
import { DataGridComponent } from 'app/component/ui/dataGrid/DataGridComponent';
import { DataGridContext } from 'app/component/ui/dataGrid/DataGridContext';
import { ResponsiveDataGridComponent } from 'app/component/ui/responsiveDataGrid/ResponsiveDataGridComponent';
import { ScheduledLiveClassDetailsPageDTO } from 'app/data/dto/scheduledLiveClass/admin/ScheduledLiveClassDetailsPageDTO';
import { ScheduledLiveClassCoachDetailsPageDTO } from 'app/data/dto/scheduledLiveClass/coach/ScheduledLiveClassCoachDetailsPageDTO';
import { ScheduledLiveClassSeniorDetailsPageDTO } from 'app/data/dto/scheduledLiveClass/senior/ScheduledLiveClassSeniorDetailsPageDTO';
import { LiveClassesTabs } from 'app/data/enum/liveClass/LiveClassesTabs';
import { StateUtil } from 'app/util/StateUtil';
import { UserDTO } from 'app/data/dto/user/UserDTO';
import { addMinutes, isFuture, isPast, subMinutes } from 'date-fns';
import { Constant } from 'app/common/Constant';
import { SubscriptionModel } from 'app/model/SubscriptionModel';
import { AccessControlModel } from 'app/model/AccessControlModel';

@Component({
  selector: 'app-live-classes-list-action-cell-renderer',
  templateUrl: './LiveClassesListActionCellRendererComponent.html',
  styleUrls: [ './LiveClassesListActionCellRendererComponent.scss' ]
})
export class LiveClassesListActionCellRendererComponent implements ICellRendererAngularComp {
  public static readonly ACTION_EDIT: string = 'edit';
  public static readonly ACTION_DETAILS_ADMIN: string = 'details_admin';
  public static readonly ACTION_DETAILS_COACH: string = 'details_coach';
  public static readonly ACTION_DETAILS_USER: string = 'details_user';
  public static readonly ACTION_FAVORITE: string = 'favorite';
  public static readonly ACTION_UNFAVORITE: string = 'unfavorite';
  public static readonly ACTION_REGISTER_USER: string = 'register_user';
  public static readonly ACTION_DELETE: string = 'delete';
  public static readonly ACTION_JOIN: string = 'join';
  public static readonly ACTION_DROP: string = 'drop';
  public static readonly ACTION_JOIN_WAITING_LIST: string = 'join_waiting_list';
  public static readonly ACTION_DROP_WAITING_LIST: string = 'drop_waiting_list';

  public liveClassesTabs: typeof LiveClassesTabs = LiveClassesTabs;
  public params: ICellRendererParams;
  public adminClassDetails: ScheduledLiveClassDetailsPageDTO;
  public coachClassDetails: ScheduledLiveClassCoachDetailsPageDTO;
  public seniorClassDetails: ScheduledLiveClassSeniorDetailsPageDTO;
  public context: DataGridContext;
  public currentUser: UserDTO;
  public activeTab: LiveClassesTabs;

  private isBeforeStartDate: boolean = false;
  private isAfterEndDate: boolean = false;

  private isCapacityExceeded: boolean = false;

  private isCurrentSeniorEnrolled: boolean = false;
  private isCurrentSeniorDropped: boolean = false;
  private isCurrentSeniorOnWaitingList: boolean = false;

  public isDetailsButtonAvailable: boolean = true;

  public isRegisterButtonAvailable: boolean = false;
  public isRegisterButtonDisabled: boolean = false;

  public isJoinButtonAvailable: boolean = false;
  public isJoinButtonDisabled: boolean = false;

  public isDropButtonAvailable: boolean = false;

  public isWaitingListButtonAvailable: boolean = false;
  public isWaitingListButtonDisabled: boolean = false;

  constructor(private readonly stateUtil: StateUtil,
              public readonly accessControlModel: AccessControlModel,
              private readonly subscriptionModel: SubscriptionModel) {
  }

  public agInit(params: ICellRendererParams): void {
    this.params = params;
    this.context = params.context as DataGridContext;
    this.currentUser = this.context.additionalData?.['currentUser'];

    if (this.currentUser?.isSenior) {
      this.seniorClassDetails = params.data as ScheduledLiveClassSeniorDetailsPageDTO;

      this.isBeforeStartDate = isFuture(subMinutes(this.seniorClassDetails.startDate, Constant.DEFAULT_MINUTES_THRESHOLD));
      this.isAfterEndDate = isPast(addMinutes(this.seniorClassDetails.startDate, this.seniorClassDetails.duration));

      this.isCapacityExceeded = this.seniorClassDetails.capacity && (this.seniorClassDetails.enrolledSeniorsCount >= this.seniorClassDetails.capacity);
      this.isCurrentSeniorEnrolled = !!this.seniorClassDetails.currentSeniorEnrollment;
      this.isCurrentSeniorDropped = this.seniorClassDetails.currentSeniorEnrollment?.dropped;
      this.isCurrentSeniorOnWaitingList = this.seniorClassDetails.isCurrentSeniorOnWaitingList;

      this.isDetailsButtonAvailable = this.subscriptionModel.isSubscriptionActive;
      this.isRegisterButtonAvailable = this.subscriptionModel.isSubscriptionActive && !this.isCapacityExceeded && (!this.isCurrentSeniorEnrolled || this.isCurrentSeniorDropped);
      this.isRegisterButtonDisabled = this.isAfterEndDate;
      this.isJoinButtonAvailable = this.subscriptionModel.isSubscriptionActive && this.isCurrentSeniorEnrolled && !this.isCurrentSeniorDropped;
      this.isJoinButtonDisabled = this.isBeforeStartDate || this.isAfterEndDate;
      this.isDropButtonAvailable = this.isCurrentSeniorOnWaitingList || (this.isCurrentSeniorEnrolled && !this.isCurrentSeniorDropped);
      this.isWaitingListButtonAvailable = this.subscriptionModel.isSubscriptionActive && !this.isCurrentSeniorOnWaitingList && (!this.isCurrentSeniorEnrolled || this.isCurrentSeniorDropped) && this.isCapacityExceeded;
      this.isWaitingListButtonDisabled = this.isAfterEndDate;
    }
    else if (this.currentUser?.isCoach) {
      this.coachClassDetails = params.data as ScheduledLiveClassCoachDetailsPageDTO;
      this.activeTab = params.context.activeTab;

      this.isBeforeStartDate = isFuture(subMinutes(this.coachClassDetails.startDate, Constant.DEFAULT_MINUTES_THRESHOLD));
      this.isAfterEndDate = isPast(addMinutes(this.coachClassDetails.startDate, this.coachClassDetails.duration));

      this.isJoinButtonDisabled = this.isBeforeStartDate || this.isAfterEndDate;
    }
    else if (this.currentUser?.isAdmin) {
      this.adminClassDetails = params.data as ScheduledLiveClassDetailsPageDTO;
    }
  }

  public refresh(params: ICellRendererParams): boolean {
    return false;
  }

  public preventGridSelection(event: Event): void {
    if (this.context.dataGridHost instanceof DataGridComponent) {
      const previousValue = this.params.node['gridOptionsWrapper'].gridOptions.suppressRowClickSelection;
      this.params.node['gridOptionsWrapper'].gridOptions.suppressRowClickSelection = true;

      setTimeout(() => {
        this.params.node['gridOptionsWrapper'].gridOptions.suppressRowClickSelection = previousValue;
      });
    }
    else if (this.context.dataGridHost instanceof ResponsiveDataGridComponent) {
      event.stopImmediatePropagation();
      event.preventDefault();
    }
  }

  public onEditClick(): void {
    this.context.dataGridAction(LiveClassesListActionCellRendererComponent.ACTION_EDIT, [ this.adminClassDetails ]);
  }

  public onDeleteClick(): void {
    this.context.dataGridAction(LiveClassesListActionCellRendererComponent.ACTION_DELETE, [ this.adminClassDetails ]);
  }

  public onDetailsClickAdmin(): void {
    this.context.dataGridAction(LiveClassesListActionCellRendererComponent.ACTION_DETAILS_ADMIN, [
      this.adminClassDetails
    ]);
  }

  public onDetailsClickCoach(): void {
    this.context.dataGridAction(LiveClassesListActionCellRendererComponent.ACTION_DETAILS_COACH, [
      this.coachClassDetails
    ]);
  }

  public onDetailsClickUser(): void {
    this.context.dataGridAction(LiveClassesListActionCellRendererComponent.ACTION_DETAILS_USER, [
      this.seniorClassDetails
    ]);
  }

  public onJoinClick(): void {
    this.context.dataGridAction(LiveClassesListActionCellRendererComponent.ACTION_JOIN, [ this.seniorClassDetails ]);
  }

  public onDropClick(): void {
    if (this.isCurrentSeniorOnWaitingList) {
      this.context.dataGridAction(LiveClassesListActionCellRendererComponent.ACTION_DROP_WAITING_LIST, [ this.seniorClassDetails ]);
    }
    else {
      this.context.dataGridAction(LiveClassesListActionCellRendererComponent.ACTION_DROP, [ this.seniorClassDetails ]);
    }
  }

  public onRegisterClick(): void {
    this.context.dataGridAction(LiveClassesListActionCellRendererComponent.ACTION_REGISTER_USER, [
      this.seniorClassDetails
    ]);
  }

  public onFavoriteClick(): void {
    this.context.dataGridAction(LiveClassesListActionCellRendererComponent.ACTION_FAVORITE, [ this.seniorClassDetails ]);
  }

  public onUnfavoriteClick(): void {
    this.context.dataGridAction(LiveClassesListActionCellRendererComponent.ACTION_UNFAVORITE, [ this.seniorClassDetails ]);
  }

  public onJoinClickForCoach(): void {
    this.stateUtil.goToState(State.MAIN.COACH.LIVE_CLASSES.ZOOM, { id: this.coachClassDetails.id });
  }

  public onJoinWaitingListClick(): void {
    this.context.dataGridAction(LiveClassesListActionCellRendererComponent.ACTION_JOIN_WAITING_LIST, [ this.seniorClassDetails ]);
  }

  public static suppressKeyboardEvent(params: SuppressKeyboardEventParams): boolean {
    return true;
  }
}
