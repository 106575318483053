import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { State } from 'app/common/State';
import { StateUtil } from 'app/util/StateUtil';
import { PortalUtil } from 'app/util/PortalUtil';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import { TemplatePortal } from '@angular/cdk/portal';
import { SeniorModel } from 'app/model/SeniorModel';
import { BadgeResponseDTO } from 'app/data/dto/badge/BadgeResponseDTO';
import { zip } from 'rxjs';

@Component({
  selector: 'app-all-badges',
  templateUrl: 'AllBadgesComponent.html',
  styleUrls: [ 'AllBadgesComponent.scss' ]
})
export class AllBadgesComponent implements OnInit, OnDestroy {
  @ViewChild('headingTemplate', { static: true })
  private readonly headingTemplate: TemplateRef<any>;

  public availableBadges: BadgeResponseDTO[];

  public earnedBadges: BadgeResponseDTO[];

  constructor(private stateUtil: StateUtil,
              private portalUtil: PortalUtil,
              private viewContainerRef: ViewContainerRef,
              private seniorModel: SeniorModel) {
  }

  public ngOnInit(): void {
    this.portalUtil.attachPortalTo(
      MainLayoutComponent.PORTAL_OUTLET.HEADING,
      new TemplatePortal(this.headingTemplate, this.viewContainerRef)
    );

    this.getAllBadges();
  }

  public ngOnDestroy(): void {
    this.portalUtil.detachPortalFrom(MainLayoutComponent.PORTAL_OUTLET.HEADING);
  }

  public onBackClick(): void {
    this.stateUtil.goToState(State.MAIN.SENIOR.MY_ACTIVITY.LIST);
  }

  private getAllBadges(): void {
    zip(this.seniorModel.getEarnedBadgesForCurrentSenior(), this.seniorModel.getAvailableBadgesForCurrentSenior())
      .subscribe((response: [ BadgeResponseDTO[], BadgeResponseDTO[] ]) => {
        this.earnedBadges = response[0];
        this.availableBadges = response[1];
      });
  }
}