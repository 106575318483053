import { DateUtil } from 'app/util/DateUtil';
import { IdentifiableObjectDTO } from '../IdentifiableObjectDTO';
import { Transform } from 'class-transformer';
import { OrganizationType } from 'app/data/enum/organizations/OrganizationType';
import { IBasicName } from 'app/data/local/common/IBasicName';

export class OrganizationListAdminDTO extends IdentifiableObjectDTO<number> implements IBasicName {
  public apiUrl: string;

  @Transform(DateUtil.dateTimeConversion)
  public createdDate: Date;

  public name: string;

  public stub: string;

  public type: OrganizationType;
}