import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import { Directive, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment';

export const ageOver18Validator = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors => {
    if (!control.value) {
      return null;
    }
    else {
      const momentValue: Moment = moment(control.value, 'YYYY-MM-DD');
      const now: Moment = moment();

      const difference: number = now.diff(momentValue, 'years');
      if (difference >= 18) {
        return null;
      }
      else {
        return { ageOver18: true };
      }
    }
  };
};

@Directive({
  selector: '[appAgeOver18][formControlName],[appAgeOver18][formControl],[appAgeOver18][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: AgeOver18Validator,
      multi: true
    }
  ]
})
export class AgeOver18Validator implements Validator, OnInit {

  private validator: ValidatorFn;

  public ngOnInit(): void {
    this.validator = ageOver18Validator();
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    return this.validator(control);
  }
}
