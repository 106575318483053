import { PageCriteriaDTO } from 'app/data/dto/PageCriteriaDTO';
import { Expose, Transform } from 'class-transformer';
import { IBasicName } from 'app/data/local/common/IBasicName';
import { SubscriptionType } from 'app/data/enum/subscription/SubscriptionType';

export class SeniorPageCriteriaDTO extends PageCriteriaDTO {
  public enabled: boolean;

  @Expose({ name: 'anyOfOrganizationIds', toPlainOnly: true })
  @Transform(({ value }) => value?.map(s => s.id), { toPlainOnly: true })
  public organizations: IBasicName[];

  public subscriptionType: SubscriptionType;

  public userEnabledEq?: boolean;
}
