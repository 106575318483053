import { Injectable, Pipe, PipeTransform } from '@angular/core';
import * as URI from 'urijs';
import { AuthModel } from 'app/model/AuthModel';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'fileUrlLink' })
export class FileUrlLinkPipe implements PipeTransform {

  constructor(private authModel: AuthModel) {
  }

  public transform(value: string, download: boolean = false, authorized: boolean = true): any {
    if (value) {
      const fileUrl: URI = URI(value);

      if (download) {
        fileUrl.addQuery('download', true);
      }

      if (authorized && this.authModel.token?.accessToken) {
        fileUrl.addQuery('accessToken', this.authModel.token.accessToken);
      }

      return fileUrl.href();
    }
    else {
      return null;
    }
  }
}
