export enum VideoListTabsSenior {
  ALL = 'ALL',
  FAVORITES = 'FAVORITES',
  PROGRAMS = 'PROGRAMS',
  WATCH_PARTIES = 'WATCH_PARTIES'
}

export enum VideoListTabsAdmin {
  ALL = 'ALL',
  PROGRAMS = 'PROGRAMS',
  RECOMMENDED = 'RECOMMENDED'
}

export enum VideoListTabsCoach {
  MY_VIDEOS = 'MY_VIDEOS',
  ALL_VIDEOS = 'ALL_VIDEOS',
  PROGRAMS = 'PROGRAMS'
}
