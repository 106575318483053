import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ValueFormatterParams } from 'ag-grid-community';
import { DataGridAction } from 'app/component/ui/dataGrid/DataGridAction';
import { PageCriteriaDTO } from 'app/data/dto/PageCriteriaDTO';
import { PageDTO } from 'app/data/dto/PageDTO';
import { ProgramAdminDetailsPageDTO } from 'app/data/dto/programs/ProgramAdminDetailsPageDTO';
import { ProgramAdminPageCriteriaDTO } from 'app/data/dto/programs/ProgramAdminPageCriteriaDTO';
import { StateUtil } from 'app/util/StateUtil';
import { ViewUtil } from 'app/util/ViewUtil';
import { FullNamePipe } from 'app/util/pipe/FullNamePipe';
import { PrefixPipe } from 'app/util/pipe/PrefixPipe';
import _ from 'lodash';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { State } from 'app/common/State';
import { ProgramSortBy } from 'app/data/enum/program/ProgramSortBy';
import { OptionItem } from 'app/data/local/generic/OptionItem';
import { BaseCriteriaSortOrder } from 'app/data/dto/BaseCriteriaDTO';
import { SortDirection } from 'app/data/enum/SortDirection';
import { OnDemandVideoFilterModalComponent } from '../../../common/filters/OnDemandVideoFilterModalComponent';
import { DataGridStarRatingCellRendererComponent } from 'app/component/ui/dataGrid/cellRenderer/DataGridStarRatingCellRendererComponent';
import { ProgramCoachListActionsComponent } from 'app/component/view/main/onDemand/coach/list/cellRenderer/ProgramCoachListActionsComponent';
import { ProgramCoachFilterModalComponent } from 'app/component/view/main/onDemand/coach/list/modal/ProgramCoachFilterModalComponent';
import { CoachModel } from 'app/model/CoachModel';
import { ProgramCoachDetailsPageDTO } from 'app/data/dto/programs/ProgramCoachDetailsPageDTO';
import { BaseUserDTO } from 'app/data/dto/user/BaseUserDTO';

@Component({
  selector: 'app-program-coach-list-tab',
  templateUrl: './ProgramCoachListTabComponent.html',
  styleUrls: [ './ProgramCoachListTabComponent.scss' ]
})
export class ProgramCoachListTabComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();

  public page: PageDTO<ProgramAdminDetailsPageDTO>;
  public criteria: ProgramAdminPageCriteriaDTO = new ProgramAdminPageCriteriaDTO();

  public selectedItems: ProgramAdminDetailsPageDTO[] = [];
  public sortByOptions: OptionItem<string>[] = this.viewUtil.createEnumSelectOptions(ProgramSortBy, 'VIDEO_SORT_BY');

  public StarRatingCellRendererComponent: typeof DataGridStarRatingCellRendererComponent = DataGridStarRatingCellRendererComponent;
  public ActionsCellRendererComponent: typeof ProgramCoachListActionsComponent = ProgramCoachListActionsComponent;

  public activeTab: string = 'ACTIVE';

  public coachFormatter: (params: ValueFormatterParams) => any = (params: ValueFormatterParams) => {
    const coachUser: BaseUserDTO = params.data.coaches[0].user as BaseUserDTO;
    return this.fullNamePipe.transform(coachUser.firstName, coachUser.lastName) as string;
  };

  public enumCategoryFormatter: (params: ValueFormatterParams) => any = (params: ValueFormatterParams) =>
    this.viewUtil.dataGridPipeValueFormatterWithTranslate(params, PrefixPipe, 'ENUM.VIDEO_CATEGORY.');

  public dateValueFormatter: (params: ValueFormatterParams) => any = (params: ValueFormatterParams) => {
    return this.viewUtil.dataGridPipeValueFormatter(params, DatePipe, 'MM/dd/yyyy');
  };

  constructor(
    private coachModel: CoachModel,
    public viewUtil: ViewUtil,
    private fullNamePipe: FullNamePipe,
    private modalService: BsModalService,
    private stateUtil: StateUtil
  ) {
  }

  public ngOnInit(): void {
    this.getPrograms();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public openFiltersModal(): void {
    const { anyOfCoachIds, anyOfCategories } = this.criteria;
    const modal: BsModalRef = this.modalService.show(ProgramCoachFilterModalComponent, {
      initialState: {
        anyOfCoachIds,
        anyOfCategories
      },
      class: 'modal-dialog-centered modal-filterLiveClasses'
    });
    modal.onHide
      .pipe(
        filter(
          (reason) =>
            reason === OnDemandVideoFilterModalComponent.FILTER_DATA ||
            reason === OnDemandVideoFilterModalComponent.CLEAR_FILTERS
        )
      )
      .subscribe((_reason) => {
        const { categories, coaches } = modal.content.filters;
        this.criteria = {
          ...this.criteria,
          anyOfCategories: categories.selectedCategories.length > 0 ? categories.selectedCategories : undefined,
          anyOfCoachIds: coaches.selectedCoaches.length > 0 ? coaches.selectedCoaches : undefined
        } as ProgramAdminPageCriteriaDTO;
        this.getPrograms();
      });
  }

  public onCriteriaChanged(changedCriteria: PageCriteriaDTO): void {
    const criteriaCopy: ProgramAdminPageCriteriaDTO = _.cloneDeep(this.criteria);
    _.assignIn(criteriaCopy, changedCriteria);
    this.criteria = criteriaCopy;
    this.getPrograms();
  }

  public onGridAction(action: DataGridAction): void {
    const program: ProgramCoachDetailsPageDTO = action.args[0] as ProgramCoachDetailsPageDTO;

    if (action.actionName === ProgramCoachListActionsComponent.ACTION.SHOW_DETAILS) {
      this.showProgramDetails(program);
    }
  }

  public showProgramDetails({ id }: ProgramCoachDetailsPageDTO): void {
    this.stateUtil.goToState(State.MAIN.COACH.ON_DEMAND.PROGRAM, { id });
  }

  public onSortOptionChange(sortBy: ProgramSortBy): void {
    if (!sortBy) {
      delete this.criteria.sortOrders;
      this.getPrograms();
      return;
    }

    let sortOrders: BaseCriteriaSortOrder;
    let sortField: string;
    const sortDirection: SortDirection = SortDirection.DESCENDING;

    switch (sortBy) {
      case ProgramSortBy.MOST_RECENT: {
        sortField = 'createdDate';
        break;
      }
      case ProgramSortBy.VIEW_COUNT: {
        sortField = 'viewCount';
        break;
      }
      case ProgramSortBy.RATING: {
        sortField = 'rating';
        break;
      }
    }
    sortOrders = `${ sortField } ${ sortDirection }`;
    this.criteria.sortOrders = [ sortOrders ];
    this.getPrograms();
  }

  private getPrograms(): void {
    this.coachModel
      .getProgramPage(this.criteria)
      .subscribe((response: PageDTO<ProgramCoachDetailsPageDTO>) => {
          this.page = response;
        },
        (error) => {
          this.viewUtil.handleServerError(error);
        });
  }
}
