import { FAQItem } from './FAQItem';

export const questionsList: FAQItem[] = [
  {
    question: 'How do I update my password?',
    answer:
      'On the Sign In page, click Forgot password below the password field. Enter your email address used to sign in and press Reset password. A code will be sent to your email; copy that code into the password reset field.  Choose a new password, type that password into both fields, and click Create. Sign in using the new password.',
  },
  {
    question: 'How do I make videos full screen?',
    answer: 'Hover over the video and click the four arrows ⛶ in the bottom right corner to make a video full screen.',
  },
  {
    question: 'What health plans/benefits are you partnered with?',
    answer: 'We are currently partnered with RenewActive, OnePass, and Carelon Health.',
  },
  {
    question: 'How much does it cost? ',
    answer:
      'Wellness Everywhere is free for those with RenewActive or OnePass, and most Carelon Health members. If you don’t have one of these health plans, you can still access Wellness Everywhere with a retail membership – coming soon!',
  },

  {
    question: 'How do I add my photo?',
    answer:
      'Click Account in the lower left corner of your dashboard to go to account settings. Once in account setting, click Change Photo in the top left corner below your name. Click choose file, select an image from your computer, and click Save.',
  },

  {
    question: 'How do I register for classes?',
    answer:
      'Navigate to the Live Classes tab in the left column where you can find the list of future live classes. If you don’t see any classes, select Next Days to see more classes. To register for a specific class, click Register in that row. To register for multiple classes, check the boxes next to the classes you want to register for and click Register in the bottom right corner.',
  },

  {
    question: 'How do I know what classes I already registered for?',
    answer:
      'Navigate to the My Schedule tab in the left column to see all classes you’ve registered for. In the Live Classes tab, if the class says “drop” instead of “register” you have already registered for that class.',
  },

  {
    question: 'How do I cancel a class?',
    answer:
      'Click Drop next to the class you wish to drop. This can be done from the Dashboard and Live Classes tabs, or in the My Schedule tab by clicking the class and selecting Drop on the details pop-up.',
  },

  {
    question: 'Why do I need to update my password?',
    answer:
      'Changing your password regularly is great practice for cyber security to ensure your information and account are protected.',
  },

  {
    question: 'Where can I see how many videos I’ve watched?',
    answer:
      'Navigate to the My Activity tab in the left column to see your statistics. You can see how many On-demand videos you’ve watched, how many live classes you’ve attended, and more. You can also filter between This Month, This Year, This Day, and This Week to see your statistics over time.',
  },

  {
    question: 'Where can I see how many live classes I’ve joined?',
    answer:
      'Navigate to the My Activity tab in the left column to see your statistics. You can see how many On-demand videos you’ve watched, how many live classes you’ve attended, and more. You can also filter between This Month, This Year, This Day, and This Week to see your statistics over time.',
  },

  {
    question: 'Can I create a Favorites list of On-Demand Videos?',
    answer:
      'Yes! You can favorite On-Demand videos by clicking the heart on the top left corner of the video’s thumbnail. This will add the video to your favorites list which can be found in the On-Demand tab under Favorites in the top left corner.',
  },

  {
    question: 'How do I change my time zone?',
    answer:
      'Your time zone should automatically update based on your location, but if you need to manually update it, you can do that under Account Settings. Click Account in the left column, select Preferences, and click the Time Zone button to change it.',
  },

  {
    question: 'How do I register for several classes at one time?',
    answer:
      'Navigate to the Live Classes tab in the left column where you can find the list of future live classes. If you don’t see any classes, select Next Days to see more classes. To register for a specific class, click Register in that row. To register for multiple classes, check the boxes next to the classes you want to register for and click Register in the bottom right corner.',
  },

  {
    question: 'How do I view my live class schedule?',
    answer:
      'To see which live classes you have registered for, click the My Schedule tab in the left corner. This will take you to a calendar with your classes. You can click on the arrows for future days or click the date to select from a calendar.',
  },

  {
    question: 'What happens if I register for a class, and I can’t make it?',
    answer:
      'Registered but can’t make it? No problem! There is no penalty for missing a live class, but the class won’t be counted towards your class statistics or badges.',
  },

  {
    question: 'How do I watch a video?',
    answer:
      'Navigate to the On-Demand tab in the left column. Scroll through the catalogue or filter to find a video. You can hover over the thumbnail and select More info to learn more about each video. Once you’ve selected a video, click Play on the details pop-up or by hovering over the video tile.',
  },

  {
    question: 'How do I search for a video?',
    answer:
      'You can use the filter by function to find videos under certain criteria or search for a specific video by typing into the search bar in the top right and hitting enter.',
  },
];
