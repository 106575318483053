<form #ratingForm="ngForm" (ngSubmit)="submit()" novalidate>
  <fieldset>
    <legend>{{ this.heading | translate }}</legend>

    <div class="form-group">
      <app-rating-stars class="d-flex"
                        [displayOnly]="ratingForm.submitted"
                        (rate)="formData.rating = $event">
      </app-rating-stars>
    </div> <!-- /.form-group -->

    <div class="form-group">
      <textarea id="feedback"
                class="form-control"
                name="feedback"
                rows="3"
                [placeholder]="('COMMON.START_TYPING' | translate) + '...'"
                [(ngModel)]="formData.feedback"
                [disabled]="ratingForm.submitted"
      ></textarea>
    </div> <!-- /.form-group -->

    <div class="text-right">
      <button type="submit" class="btn btn-primary" [disabled]="!formData.rating || ratingForm.submitted">
        {{ 'COMMON.SUBMIT' | translate }}
      </button>
    </div>
  </fieldset>
</form>
