import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationConfig } from 'app/config/ApplicationConfig';
import { PageDTO } from 'app/data/dto/PageDTO';
import { PasswordResendStatusResponseDTO } from 'app/data/dto/auth/PasswordResendStatusResponseDTO';
import { CoachDetailsPageDTO } from 'app/data/dto/coach/CoachDetailsPageDTO';
import { CoachesPageCriteriaDTO } from 'app/data/dto/coach/CoachesPageCriteriaDTO';
import { SeniorDetailsPageDTO } from 'app/data/dto/senior/SeniorDetailsPageDTO';
import { SeniorPageCriteriaDTO } from 'app/data/dto/senior/SeniorPageCriteriaDTO';
import { SeniorProfileResponseAdminDTO } from 'app/data/dto/senior/SeniorProfileResponseAdminDTO';
import { ObjectUtil } from 'app/util/ObjectUtil';
import { CustomHttpParameterEncoder } from 'app/util/other/CustomHttpParameterEncoder';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SubscriptionType } from 'app/data/enum/subscription/SubscriptionType';
import { SeniorUpdateRequestDTO } from 'app/data/dto/senior/SeniorUpdateRequestDTO';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  constructor(private http: HttpClient) {
  }

  public getSeniorsPage(criteria: SeniorPageCriteriaDTO): Observable<PageDTO<SeniorDetailsPageDTO>> {
    const params: HttpParams = new HttpParams({
      encoder: new CustomHttpParameterEncoder(),
      fromObject: ObjectUtil.valueAsString(ObjectUtil.classToPlain(criteria, true, true, true, true))
    });
    return this.http.get(`${ ApplicationConfig.apiUrl }/seniors/admins/page`, { params }).pipe(
      map((r) => {
        const page = ObjectUtil.plainToClassFromExisting(new PageDTO<SeniorDetailsPageDTO>(SeniorDetailsPageDTO), r);
        return page;
      })
    );
  }

  public updateSenior(id: number, senior: SeniorUpdateRequestDTO): Observable<SeniorProfileResponseAdminDTO> {
    return this.http
      .put(`${ ApplicationConfig.apiUrl }/seniors/${ id }/admins/profile`, ObjectUtil.classToPlain(senior))
      .pipe(
        map((response: SeniorProfileResponseAdminDTO) => ObjectUtil.plainToClass(SeniorProfileResponseAdminDTO, response))
      );
  }

  public getSeniorDetails(id: number): Observable<SeniorProfileResponseAdminDTO> {
    return this.http.get(`${ ApplicationConfig.apiUrl }/seniors/${ id }/admins/profile`).pipe(
      map((response: SeniorProfileResponseAdminDTO) => {
        return ObjectUtil.plainToClass(SeniorProfileResponseAdminDTO, response);
      })
    );
  }

  public activateSenior(id: number): Observable<void> {
    return this.http.put<void>(`${ ApplicationConfig.apiUrl }/seniors/${ id }/enabled`, true).pipe(
      map(() => {})
    );
  }

  public deactivateSeniors(ids: number[]): Observable<void> {
    return this.http.delete<void>(`${ ApplicationConfig.apiUrl }/seniors/${ ids }`).pipe(
      map(() => {})
    );
  }

  public getCoachesPage(criteria: CoachesPageCriteriaDTO): Observable<PageDTO<CoachDetailsPageDTO>> {
    const params: HttpParams = new HttpParams({
      encoder: new CustomHttpParameterEncoder(),
      fromObject: ObjectUtil.valueAsString(ObjectUtil.classToPlain(criteria, true, true, true, true))
    });
    return this.http.get(`${ ApplicationConfig.apiUrl }/coaches/admins/page`, { params }).pipe(
      map((r) => {
        const page = ObjectUtil.plainToClassFromExisting(new PageDTO<CoachDetailsPageDTO>(CoachDetailsPageDTO), r);
        return page;
      })
    );
  }

  public resetPasswordForSeniorCreatedByAdmin(id: number): Observable<PasswordResendStatusResponseDTO> {
    return this.http.post(`${ ApplicationConfig.apiUrl }/seniors/${ id }/welcomeMessageResend`, null).pipe(
      map((response: any) => {
        return ObjectUtil.plainToClass(PasswordResendStatusResponseDTO, response);
      })
    );
  }
}
