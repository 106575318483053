import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appRepeatElement]'
})
export class RepeatElementDirective implements OnInit {

  @Input('appRepeatElement')
  public amount: number = 0;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef
  ) {
  }

  public ngOnInit(): void {
    for (let i = 0; i < this.amount; i++) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }
}
