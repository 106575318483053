import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { WatchPartyCreateDTO } from 'app/data/dto/watchParty/WatchPartyCreateDTO';
import { WatchPartyService } from 'app/service/WatchPartyService';
import { WatchPartyUpdateDTO } from 'app/data/dto/watchParty/WatchPartyUpdateDTO';
import { concatMap, switchMap, tap } from 'rxjs/operators';
import { ViewUtil } from 'app/util/ViewUtil';
import { WatchPartyDTO } from 'app/data/dto/watchParty/WatchPartyDTO';
import { PageDTO } from 'app/data/dto/PageDTO';
import { WatchPartyPageCriteriaDTO } from 'app/data/dto/watchParty/WatchPartyPageCriteriaDTO';
import { WatchPartyLogic } from 'app/component/domain/watchparty/service/WatchPartyLogic';
import { PopupConfirmationComponent } from 'app/component/ui/popup/PopupConfirmationComponent';
import { StateUtil } from 'app/util/StateUtil';
import { State } from 'app/common/State';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UserModel } from 'app/model/UserModel';
import { fromPromise } from 'rxjs/internal-compatibility';

@Injectable({
  providedIn: 'root'
})
export class WatchPartyModel {
  constructor(public readonly logic: WatchPartyLogic,
              private readonly injector: Injector,
              private readonly stateUtil: StateUtil,
              private readonly userModel: UserModel,
              private readonly watchPartyService: WatchPartyService,
              private readonly viewUtil: ViewUtil) {
  }

  public getById(id: number): Observable<WatchPartyDTO> {
    return this.watchPartyService.getById(id);
  }

  public getPage(criteria: WatchPartyPageCriteriaDTO): Observable<PageDTO<WatchPartyDTO>> {
    return this.watchPartyService.getPage(criteria);
  }

  public create(data: WatchPartyCreateDTO): Observable<void> {
    return this.watchPartyService.create(data)
      .pipe(
        tap(() => {
          this.viewUtil.showToastSuccess('DOMAIN.WATCH_PARTY.MESSAGE.CREATE.SUCCESS');
          this.userModel.getCurrentUser();
        })
      );
  }

  public update(id: number, data: WatchPartyUpdateDTO): Observable<void> {
    return this.watchPartyService.update(id, data)
      .pipe(
        tap(() => {
          this.viewUtil.showToastSuccess('DOMAIN.WATCH_PARTY.MESSAGE.UPDATE.SUCCESS');
        })
      );
  }

  public join(id: number): void {
    this.stateUtil.goToState(State.MAIN.SENIOR.ON_DEMAND.WATCH_PARTY.EVENT, { id });
  }

  public cancel(id: number): Observable<void> {
    return this.injector.get(BsModalService).show(PopupConfirmationComponent, {
      initialState: {
        title: 'DOMAIN.WATCH_PARTY.MESSAGE.DELETE.TITLE',
        message: 'DOMAIN.WATCH_PARTY.MESSAGE.DELETE.DESCRIPTION',
        okText: 'COMMON.CONFIRM'
      },
      class: 'modal-dialog-centered'
    }).content.close$
      .pipe(
        switchMap(() => this.watchPartyService.delete(id)),
        tap(() => {
          this.viewUtil.showToastSuccess('DOMAIN.WATCH_PARTY.MESSAGE.DELETE.SUCCESS');
          this.userModel.getCurrentUser();
        })
      );
  }

  public drop(id: number): Observable<void> {
    return this.injector.get(BsModalService).show(PopupConfirmationComponent, {
      initialState: {
        title: 'DOMAIN.WATCH_PARTY.MESSAGE.DROP.TITLE',
        message: 'DOMAIN.WATCH_PARTY.MESSAGE.DROP.DESCRIPTION',
        okText: 'COMMON.CONFIRM'
      },
      class: 'modal-dialog-centered'
    }).content.close$
      .pipe(
        switchMap(() => this.watchPartyService.drop(id)),
        tap(() => {
          this.viewUtil.showToastSuccess('DOMAIN.WATCH_PARTY.MESSAGE.DROP.SUCCESS');
          this.userModel.getCurrentUser();
        })
      );
  }

  public signup(id: number): Observable<void> {
    return this.injector.get(BsModalService).show(PopupConfirmationComponent, {
      initialState: {
        title: 'DOMAIN.WATCH_PARTY.MESSAGE.SIGN_UP.TITLE',
        message: 'DOMAIN.WATCH_PARTY.MESSAGE.SIGN_UP.DESCRIPTION',
        okText: 'COMMON.CONFIRM'
      },
      class: 'modal-dialog-centered'
    }).content.close$
      .pipe(
        switchMap(() => this.watchPartyService.signup(id)),
        tap(() => {
          this.viewUtil.showToastSuccess('DOMAIN.WATCH_PARTY.MESSAGE.SIGN_UP.SUCCESS');
          this.userModel.getCurrentUser();
        })
      );
  }

  public accept(id: number): Observable<void> {
    return this.injector.get(BsModalService).show(PopupConfirmationComponent, {
      initialState: {
        title: 'DOMAIN.WATCH_PARTY.MESSAGE.ACCEPT.TITLE',
        message: 'DOMAIN.WATCH_PARTY.MESSAGE.ACCEPT.DESCRIPTION',
        okText: 'COMMON.CONFIRM'
      },
      class: 'modal-dialog-centered'
    }).content.close$
      .pipe(
        switchMap(() => this.watchPartyService.accept(id)),
        tap(() => {
          this.viewUtil.showToastSuccess('DOMAIN.WATCH_PARTY.MESSAGE.ACCEPT.SUCCESS');
          this.userModel.getCurrentUser();
        })
      );
  }

  public reject(id: number): Observable<void> {
    return this.injector.get(BsModalService).show(PopupConfirmationComponent, {
      initialState: {
        title: 'DOMAIN.WATCH_PARTY.MESSAGE.REJECT.TITLE',
        message: 'DOMAIN.WATCH_PARTY.MESSAGE.REJECT.DESCRIPTION',
        okText: 'COMMON.CONFIRM'
      },
      class: 'modal-dialog-centered'
    }).content.close$
      .pipe(
        switchMap(() => this.watchPartyService.reject(id)),
        tap(() => {
          this.viewUtil.showToastSuccess('DOMAIN.WATCH_PARTY.MESSAGE.REJECT.SUCCESS');
          this.userModel.getCurrentUser();
        })
      );
  }
}