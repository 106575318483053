import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Constant } from 'app/common/Constant';
import _ from 'lodash';
import { tap } from 'rxjs/operators';

export interface Items {
  item: string;

  id: number;
}

export interface HasKeyName {
  [key: string]: any;
}

@Component({
  selector: 'app-dynamic-input',
  templateUrl: './DynamicInputComponent.html',
  styleUrls: [ './DynamicInputComponent.scss' ]
})
export class DynamicInputComponent<T extends HasKeyName> implements OnInit, AfterViewInit {
  @Input()
  public items: T[];
  @Input()
  public label: string;
  @Input()
  public placeholder: string;
  @Input()
  public addButtonText: string;
  @Input()
  public disabled: boolean = false;
  @Input()
  public appMaxLength: number = Constant.TEXT_MAX_LENGTH_255;
  @Input()
  public formSubmitted: boolean = false;
  @Input()
  public keyName: string;
  @Input()
  public isRequired: boolean = false;
  @Output()
  public updatedItems: EventEmitter<T[]> = new EventEmitter<T[]>();
  @Output()
  public formStatus: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('form')
  public form: NgForm;

  public Constant: typeof Constant = Constant;

  private id: number = 0;

  itemsCopy: any[] = [];

  public ngOnInit(): void {
    if (this.items.length === 0) {
      this.itemsCopy = [ { [this.keyName]: '' } ];
    }
    else {
      this.itemsCopy = _.cloneDeep(this.items);
    }
    this.id = this.items.length;
  }

  public ngAfterViewInit(): void {
    this.form.valueChanges
      .pipe(
        tap(() => {
          this.formStatus.emit(this.form.valid);
        })
      )
      .subscribe();
  }

  public addNewItem(): void {
    this.form.onSubmit(null);
    if (this.form.valid) {
      const itemsLength = this.itemsCopy.length;
      if (this.itemsCopy[itemsLength - 1][this.keyName])
        this.itemsCopy.push({
          [this.keyName]: ''
        });
    }
  }

  public removeItem(index: number): void {
    this.itemsCopy.splice(index, 1);
    this.onInputChange();
  }

  public onInputChange(): void {
    this.updatedItems.emit(this.itemsCopy);
    const isValid = this.form.valid;
    this.formStatus.emit(isValid);
  }

  public onSubmit(): void {
    this.form.onSubmit(null);
  }
}
