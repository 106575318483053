import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { take } from 'lodash';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'arrayJoin' })
export class ArrayJoinPipe implements PipeTransform {

  public transform(value: any[], separator: string = ', ', max: number = -1): any {
    const arrayLength = value.length;

    if (value && arrayLength) {
      const cut = max !== -1;
      const arr = take(value, cut ? max : arrayLength);
      const diff = arrayLength - max;

      return arr.join(separator) + (cut && diff > 0 ? ` +${ diff } more` : '');
    }
    else {
      return null;
    }
  }
}
