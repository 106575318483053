<ng-template #headingTemplate>
  <h1>{{ 'VIEW.MAIN.ON_DEMAND.WATCH_PARTY.EDIT.TITLE' | translate }}</h1>

  <div class="d-flex gap-4">
    <button type="button" class="btn btn-outline-primary"
            [uiSref]="State.MAIN.SENIOR.ON_DEMAND.WATCH_PARTY.LIST">
      <svg-icon src="asset/image/icon/chevron-left.svg"></svg-icon>
      <span>{{ 'COMMON.BACK' | translate }}</span>
    </button>

    <button type="button" class="btn btn-primary" (click)="ngForm.onSubmit(null)">
      {{ 'COMMON.SAVE' | translate }}
    </button>
  </div>
</ng-template>

<form [formGroup]="form" #ngForm="ngForm" (ngSubmit)="save()" novalidate>
  <section class="section no-overflow-y">
    <app-watch-party-form [submitted]="ngForm.submitted"
                          [form]="form"
                          [editingMode]="true"
                          [minDate]="minDate"></app-watch-party-form>
  </section>
</form>