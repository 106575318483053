import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationConfig } from 'app/config/ApplicationConfig';
import { PageCriteriaDTO } from 'app/data/dto/PageCriteriaDTO';
import { PageDTO } from 'app/data/dto/PageDTO';
import { OrganizationCreateRequestDTO } from 'app/data/dto/organization/OrganizationCreateRequestDTO';
import { OrganizationDetailsResponseDTO } from 'app/data/dto/organization/OrganizationDetailsResponseDTO';
import { OrganizationDetailsResponsePageDTO } from 'app/data/dto/organization/OrganizationDetailsResponsePageDTO';
import { OrganizationListAdminDTO } from 'app/data/dto/organization/OrganizationListAdminDTO';
import { OrganizationUpdateRequestDTO } from 'app/data/dto/organization/OrganizationUpdateRequestDTO';
import { ObjectUtil } from 'app/util/ObjectUtil';
import { CustomHttpParameterEncoder } from 'app/util/other/CustomHttpParameterEncoder';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  constructor(private http: HttpClient) {
  }

  public getOrganizationPage(criteria: PageCriteriaDTO): Observable<PageDTO<OrganizationDetailsResponsePageDTO>> {
    const params: HttpParams = new HttpParams({
      encoder: new CustomHttpParameterEncoder(),
      fromObject: ObjectUtil.valueAsString(ObjectUtil.classToPlain(criteria, true, true, true, true))
    });

    return this.http.get(`${ ApplicationConfig.apiUrl }/organizations/page`, { params }).pipe(
      map((response: any) => {
        const page = ObjectUtil.plainToClassFromExisting(
          new PageDTO<OrganizationDetailsResponsePageDTO>(OrganizationDetailsResponsePageDTO),
          response
        );
        return page;
      })
    );
  }

  public getAllOrganizationList(): Observable<OrganizationListAdminDTO[]> {
    return this.http.get(`${ ApplicationConfig.apiUrl }/organizations/admins/list`).pipe(
      map((response: OrganizationListAdminDTO[]) => {
        return response.map((organization: OrganizationListAdminDTO) =>
          ObjectUtil.plainToClass(OrganizationListAdminDTO, organization)
        );
      })
    );
  }

  public getOrganizationDetails(id: number): Observable<OrganizationDetailsResponseDTO> {
    return this.http.get(`${ ApplicationConfig.apiUrl }/organizations/${ id }`).pipe(
      map((response: OrganizationDetailsResponseDTO) => {
        return ObjectUtil.plainToClass(OrganizationDetailsResponseDTO, response);
      })
    );
  }

  public updateOrganization(
    id: number,
    organization: OrganizationUpdateRequestDTO
  ): Observable<OrganizationDetailsResponseDTO> {
    return this.http.put(`${ ApplicationConfig.apiUrl }/organizations/${ id }`, ObjectUtil.classToPlain(organization)).pipe(
      map((response: OrganizationDetailsResponseDTO) => {
        return ObjectUtil.plainToClass(OrganizationDetailsResponseDTO, response);
      })
    );
  }

  public createOrganization(organization: OrganizationCreateRequestDTO): Observable<OrganizationDetailsResponseDTO> {
    return this.http.post(`${ ApplicationConfig.apiUrl }/organizations`, ObjectUtil.classToPlain(organization)).pipe(
      map((response: OrganizationDetailsResponseDTO) => {
        return ObjectUtil.plainToClass(OrganizationDetailsResponseDTO, response);
      })
    );
  }
}
