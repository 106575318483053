import { Transform } from 'class-transformer';
import { PageCriteriaDTO } from '../PageCriteriaDTO';
import { DateUtil } from 'app/util/DateUtil';
import { LiveClassCategory } from 'app/data/enum/liveClass/LiveClassCategory';
import { DayOfWeek } from 'app/data/enum/DayOfWeek';
import { TimeOfDay } from 'app/data/enum/TimeOfDay';
import { IntensityLevel } from 'app/data/enum/IntensityLevel';

export class ScheduledLiveClassPageCriteriaDTO extends PageCriteriaDTO {
  public anyOfCategories?: LiveClassCategory[];

  public anyOfCoachIds?: number[];

  public anyOfIntensities?: IntensityLevel[];

  public anyOfLanguages?: string[];

  public consecutiveDays?: number; // 1-30

  public daysOfWeek?: DayOfWeek[];

  public hasNotEnded?: boolean; // "true" for SENIOR, "false" for ADMIN

  @Transform(DateUtil.dateConversion)
  public selectedDate: Date;

  public timesOfDay: TimeOfDay[];

  // set default values
  constructor() {
    super();

    this.hasNotEnded = false;
  }
}
