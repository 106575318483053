import { Injectable } from '@angular/core';
import { Event } from 'app/common/Event';
import { PageDTO } from 'app/data/dto/PageDTO';
import { ProgramIdListDTO } from 'app/data/dto/programs/ProgramIdListDTO';
import { ProgramSeniorDetailsPageDTO } from 'app/data/dto/programs/ProgramSeniorDetailsPageDTO';
import { ProgramSeniorPageCriteriaDTO } from 'app/data/dto/programs/ProgramSeniorPageCriteriaDTO';
import { CurrentSeniorProfileDTO } from 'app/data/dto/senior/CurrentSeniorProfileDTO';
import { SeniorActivityCriteriaDTO } from 'app/data/dto/senior/SeniorActivityCriteriaDTO';
import { SeniorActivityDTO } from 'app/data/dto/senior/SeniorActivityDTO';
import { SeniorUpdateRequestDTO } from 'app/data/dto/senior/SeniorUpdateRequestDTO';
import { SeniorDTO } from 'app/data/dto/senior/SeniorDTO';
import { SeniorPageCriteriaDTO } from 'app/data/dto/senior/SeniorPageCriteriaDTO';
import { SeniorPreferencesDTO } from 'app/data/dto/senior/SeniorPreferencesDTO';
import { SeniorPreferencesRequestDTO } from 'app/data/dto/senior/SeniorPreferencesRequestDTO';
import { SeniorProfileDTO } from 'app/data/dto/senior/SeniorProfileDTO';
import { SeniorProfileResponseAdminDTO } from 'app/data/dto/senior/SeniorProfileResponseAdminDTO';
import { PermissionName } from 'app/data/enum/permission/PermissionName';
import { ProgramService } from 'app/service/ProgramService';
import { SeniorService } from 'app/service/SeniorService';
import { EventManager } from 'app/util/other/EventManager';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ProgramSeniorResponseDTO } from 'app/data/dto/programs/ProgramSeniorResponseDTO';
import { BadgeResponseDTO } from 'app/data/dto/badge/BadgeResponseDTO';

@Injectable({ providedIn: 'root' })
export class SeniorModel {
  // public currentSenior: SeniorDTO; (as getter/setter below)
  public currentSenior$: BehaviorSubject<SeniorDTO> = new BehaviorSubject<SeniorDTO>(undefined);

  constructor(private eventManager: EventManager,
              private readonly seniorService: SeniorService,
              private readonly programService: ProgramService
  ) {
    this.setupListeners();
  }

  public get currentSenior(): SeniorDTO {
    return this.currentSenior$.value;
  }

  public set currentSenior(value: SeniorDTO) {
    this.currentSenior$.next(value);
  }

  private setupListeners(): void {
    this.eventManager.on(Event.AUTH.LOGOUT.SUCCESS, () => {
      this.currentSenior = null;
    });
    this.eventManager.on(Event.AUTH.ERROR.UNAUTHORIZED, (_result) => {
      this.currentSenior = null;
    });
  }

  public getSeniorPage(criteria: SeniorPageCriteriaDTO): Observable<PageDTO<SeniorDTO>> {
    return this.seniorService.getSeniorPage(criteria).pipe(
      catchError((error) => {
        throw error;
      })
    );
  }

  public createSenior(senior: SeniorUpdateRequestDTO): Observable<SeniorProfileResponseAdminDTO> {
    return this.seniorService.createSenior(senior).pipe(
      catchError((error) => {
        throw error;
      })
    );
  }

  public deleteSenior(seniorId: number): Observable<void> {
    return this.seniorService.deleteSenior(seniorId).pipe(
      catchError((error) => {
        throw error;
      })
    );
  }

  public getCurrentSeniorProfile(): Observable<SeniorProfileDTO> {
    return this.seniorService.getCurrentSeniorProfile();
  }

  public getCurrentSeniorPreferences(): Observable<SeniorPreferencesDTO> {
    return this.seniorService.getCurrentSeniorPreferences();
  }

  public updateCurrentSeniorPreferences(seniorPreferences: SeniorPreferencesRequestDTO): Observable<unknown> {
    return this.seniorService.updateCurrentSeniorPreferences(seniorPreferences);
  }

  public updateCurrentUserProfile(seniorProfile: CurrentSeniorProfileDTO): Observable<unknown> {
    return this.seniorService.updateCurrentSeniorProfile(seniorProfile);
  }

  public currentSeniorHasPermission(permission: PermissionName): boolean {
    return this.currentSenior?.hasPermission(permission) ?? false;
  }

  public currentSeniorHasAllPermissions(permissions: PermissionName[]): boolean {
    return this.currentSenior?.hasAllPermissions(permissions) ?? false;
  }

  public currentSeniorHasAtLeastOnePermission(permissions: PermissionName[]): boolean {
    return this.currentSenior?.hasAtLeastOnePermission(permissions) ?? false;
  }

  public getCurrentSeniorActivity(criteria: SeniorActivityCriteriaDTO): Observable<SeniorActivityDTO> {
    return this.seniorService.getCurrentSeniorActivity(criteria);
  }

  public getProgramPage(criteria: ProgramSeniorPageCriteriaDTO): Observable<PageDTO<ProgramSeniorDetailsPageDTO>> {
    return this.programService.getProgramPageSenior(criteria);
  }

  public addProgramToFavorites(programIds: ProgramIdListDTO): Observable<unknown> {
    return this.programService.addProgramToFavorites(programIds);
  }

  public removeProgramsFromFavorites(programIds: ProgramIdListDTO): Observable<unknown> {
    return this.programService.removeProgramsFromFavorites(programIds);
  }

  public getProgramById(programId: number): Observable<ProgramSeniorResponseDTO> {
    return this.programService.getProgramByIdForCurrentSenior(programId);
  }

  public getEarnedBadgesForCurrentSenior(): Observable<BadgeResponseDTO[]> {
    return this.seniorService.getEarnedBadgesForCurrentSenior();
  }

  public getAvailableBadgesForCurrentSenior(): Observable<BadgeResponseDTO[]> {
    return this.seniorService.getAvailableBadgesForCurrentSenior();
  }
}
