import { UserDTO } from 'app/data/dto/user/UserDTO';
import { Transform, Type } from 'class-transformer';
import { DateUtil } from 'app/util/DateUtil';

export abstract class AuditableObjectDTO {

  @Type(() => UserDTO)
  public createdBy: UserDTO;

  @Transform(DateUtil.dateTimeConversion)
  public createdDate: Date;

  @Type(() => UserDTO)
  public lastUpdatedBy: UserDTO;

  @Transform(DateUtil.dateTimeConversion)
  public lastUpdatedDate: Date;

}
