export enum LiveClassCapacity {
  CAPACITY_0 = 0,
  CAPACITY_1 = 1,
  CAPACITY_2 = 2,
  CAPACITY_3 = 3,
  CAPACITY_4 = 4,
  CAPACITY_5 = 5,
  CAPACITY_6 = 6,
  CAPACITY_7 = 7,
  CAPACITY_8 = 8,
  CAPACITY_9 = 9,
  CAPACITY_10 = 10,
  CAPACITY_11 = 11,
  CAPACITY_12 = 12,
  CAPACITY_13 = 13,
  CAPACITY_14 = 14,
  CAPACITY_15 = 15,
  CAPACITY_16 = 16,
  CAPACITY_17 = 17,
  CAPACITY_18 = 18,
  CAPACITY_19 = 19,
  CAPACITY_20 = 20,
}
