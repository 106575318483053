import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AssetCreateRequestDTO } from 'app/data/dto/asset/AssetCreateRequestDTO';
import { AssetUploadResponseDTO } from 'app/data/dto/asset/AssetUploadResponseDTO';
import { CoachProfileUpdateRequestDTO } from 'app/data/dto/coach/CoachProfileUpdateRequestDTO';
import { ImageCreateRequestDTO } from 'app/data/dto/images/ImageCreateRequestDTO';
import { CurrentSeniorProfileDTO } from 'app/data/dto/senior/CurrentSeniorProfileDTO';
import { CurrentUserProfileDTO } from 'app/data/dto/user/CurrentUserProfileDTO';
import { UserDTO } from 'app/data/dto/user/UserDTO';
import { UserProfileDTO } from 'app/data/dto/user/UserProfileDTO';
import { UserProfileResponseAdminDTO } from 'app/data/dto/user/UserProfileResponseAdminDTO';
import { UserType } from 'app/data/enum/user/UserType';
import { UploadFile } from 'app/data/local/file/UploadFile';
import { AdminModel } from 'app/model/AdminModel';
import { CoachModel } from 'app/model/CoachModel';
import { SeniorModel } from 'app/model/SeniorModel';
import { UserModel } from 'app/model/UserModel';
import { FileUtil } from 'app/util/FileUtil';
import { ViewUtil } from 'app/util/ViewUtil';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { from, of, Subject } from 'rxjs';
import { catchError, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-change-photo',
  templateUrl: './ChangePhotoComponent.html',
  styleUrls: [ './ChangePhotoComponent.scss' ]
})
export class ChangePhotoComponent implements OnInit, OnDestroy {
  @ViewChild('thumbnailInputRef')
  public thumbnailInputRef: ElementRef<HTMLInputElement>;

  public currentUser: UserDTO;
  public uploadStarted: boolean  = false;
  public userType: UserType;
  public adminProfile: CurrentUserProfileDTO;
  public coachProfile: CoachProfileUpdateRequestDTO;
  public userProfile: CurrentSeniorProfileDTO;
  public currentUserAdminEdit: UserProfileResponseAdminDTO;
  public thumbnailUrl: string;
  public allowedExtensionsThumbnail: string[] = FileUtil.THUMBNAIL_EXTENSIONS;
  public allowedExtensionsAttachment: string[] = FileUtil.SCHEDULED_LIVE_CLASS_ATTACHMENTS_EXTENSIONS;
  public maxSize: number = 1024 * 1024 * 50; //50 MB
  public uploadThumbnailStarted: boolean = false;
  public uploadThumbnail: UploadFile = new UploadFile();
  public thumbnailAssetResponse: AssetUploadResponseDTO;
  public formStatus: boolean = true;
  public imageId: number;
  @ViewChild('form') private form: NgForm;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private viewUtil: ViewUtil,
    private bsModalRef: BsModalRef,
    private userModel: UserModel,
    public coachModel: CoachModel,
    public seniorModel: SeniorModel,
    private adminModel: AdminModel,
    private vieweUtil: ViewUtil
  ) {
  }

  public ngOnInit(): void {
    this.userType = this.currentUser.userType;
    from(this.userModel.getCurrentUser())
      .pipe(
        takeUntil(this.destroy$),
        tap((user) => {
          this.thumbnailUrl = user?.avatar?.defaultAsset?.url;
        }),
        catchError((err) => {
          this.viewUtil.handleServerError(err);
          return of(null);
        })
      )
      .subscribe();
    this.thumbnailUrl = this.currentUser?.avatar?.defaultAsset?.url;
    this.getUserBasedOnType();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public getUserBasedOnType(): void {
    switch (this.userType) {
      case UserType.SENIOR:
        this.getSeniorProfile();
        break;
      case UserType.ADMIN:
        this.getUserProfile();
        break;
      case UserType.COACH:
        this.getCoachProfile();
        break;
    }
  }

  public handleStartUpload(uploadStart: boolean): void {
    this.uploadStarted = uploadStart;
  }

  public handleImageId(imageId: number): void {
    const profileUpdateMap = {
      [UserType.COACH]: () => (this.coachProfile.user.imageId = imageId),
      [UserType.ADMIN]: () => (this.adminProfile.imageId = imageId),
      [UserType.SENIOR]: () => (this.userProfile.user.imageId = imageId)
    };
    const updateFunction = profileUpdateMap[this.userType];
    if (updateFunction) {
      updateFunction();
    }
  }

  onHideClick(): void {
    this.uploadThumbnail.reset();
    this.bsModalRef.hide();
  }

  public onSaveClick(): void {
    const actions = {
      [UserType.SENIOR]: () => ({ model: this.seniorModel, profile: this.userProfile }),
      [UserType.ADMIN]: () => ({ model: this.userModel, profile: this.adminProfile }),
      [UserType.COACH]: () => ({ model: this.coachModel, profile: this.coachProfile })
    };
    const action = actions[this.userType];
    if (!action) return;
    const { model, profile } = action();

    this.handleImageId(this.imageId);
    this.updateProfile(model, profile);
  }

  public onThumbnailChange(file: File): void {
    this.formStatus = this.form.form.valid;
    if (!file) return;
    this.uploadThumbnail.reset();
    this.uploadThumbnail.file = file;
    this.form.onSubmit(null);
    const thumbnailAsset = new AssetCreateRequestDTO();
    thumbnailAsset.fileName = (this.uploadThumbnail.file as File).name;
    if (this.form.form.valid) {
      this.createThumbnailAsset(thumbnailAsset);
    }
  }

  public onChoseFileClick(): void {
    this.thumbnailInputRef.nativeElement.click();
  }

  private getSeniorProfile(): void {
    this.seniorModel
      .getCurrentSeniorProfile()
      .pipe(
        tap(({ user, gender, dateOfBirth, address }) => {
          this.userProfile = {
            address,
            dateOfBirth,
            gender,
            user
          };
        }),
        takeUntil(this.destroy$),
        catchError((err) => {
          this.viewUtil.handleServerError(err);
          return of(null);
        })
      )
      .subscribe();
  }

  private getUserProfile(): void {
    this.adminProfile = new UserProfileDTO();
    this.userModel
      .getCurrentUserProfile()
      .pipe(
        tap(({ firstName, lastName, nickname, email, phone, avatar }) => {
          this.adminProfile = {
            firstName,
            lastName,
            nickname,
            email,
            phone,
            imageId: avatar?.id
          };
        }),
        takeUntil(this.destroy$),
        catchError((err) => {
          this.viewUtil.handleServerError(err);
          return of(null);
        })
      )
      .subscribe();
  }

  private getCoachProfile(): void {
    this.coachProfile = new CoachProfileUpdateRequestDTO();
    this.adminProfile = new UserProfileDTO();
    this.coachModel
      .getCurrentUserProfile()
      .pipe(
        tap(({ bio, coachCertifications, coachEducations, coachHobbies, location, user }) => {
          const { firstName, lastName, email, phone, nickname, avatar } = user;
          this.coachProfile = {
            bio,
            coachCertifications,
            coachEducations,
            coachHobbies,
            location,
            user: {
              firstName,
              lastName,
              email,
              phone,
              nickname,
              imageId: avatar?.id
            }
          };
        }),
        takeUntil(this.destroy$),
        catchError((err) => {
          this.viewUtil.handleServerError(err);
          return of(null);
        })
      )
      .subscribe();
  }

  private updateProfile(model, profile): void {
    model
      .updateCurrentUserProfile(profile)
      .pipe(
        tap((res) => {
          this.userModel.getCurrentUser();
          this.viewUtil.showToastSuccess('VIEW.MAIN.ACCOUNT.UPDATE.SUCCESS');
          this.bsModalRef.hide();
        }),
        catchError((err) => {
          this.viewUtil.handleServerError(err);
          return of(null);
        })
      )
      .subscribe();
  }

  private createThumbnailAsset(thumbnailAsset: AssetCreateRequestDTO): void {
    this.uploadStarted = true;
    this.uploadThumbnailStarted = true;
    this.adminModel
      .createAsset(thumbnailAsset)
      .pipe(
        takeUntil(this.destroy$),
        tap((asset) => {
          this.thumbnailAssetResponse = asset;
        }),
        switchMap(() =>
          this.adminModel.uploadThumbnailAsset(this.uploadThumbnail.file as File, this.thumbnailAssetResponse)
        ),
        switchMap(() => {
          const imageCreateRequestDTO: ImageCreateRequestDTO = {
            originalAssetId: this.thumbnailAssetResponse.id
          };
          return this.adminModel.createImages(imageCreateRequestDTO);
        }),
        tap((r) => {
          this.imageId = r.id;
          this.thumbnailUrl = r.defaultAsset.url;
          this.uploadStarted = false;
        }),
        catchError((error) => {
          this.vieweUtil.handleServerError(error);
          return of(null);
        })
      )
      .subscribe();
  }
}
