import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { StateService } from '@uirouter/core';
import { Constant } from 'app/common/Constant';
import { ApplicationConfig } from 'app/config/ApplicationConfig';
import { UserDTO } from 'app/data/dto/user/UserDTO';
import { ServerErrorCode } from 'app/data/enum/ServerErrorCode';
import { AuthMode } from 'app/data/local/AuthMode';
import { AuthModel } from 'app/model/AuthModel';
import { UserModel } from 'app/model/UserModel';
import { StateUtil } from 'app/util/StateUtil';
import { ViewUtil } from 'app/util/ViewUtil';

@Component({
  selector: 'app-account-required-change-password',
  templateUrl: 'ResetPasswordRequiredComponent.html',
  styleUrls: [ 'ResetPasswordRequiredComponent.scss' ]
})
export class ResetPasswordRequiredComponent {
  @ViewChild('changePasswordForm')
  private changePasswordForm: NgForm;

  public formData: {
    currentPassword: string;
    newPassword: string;
    newPasswordRepeat: string;
  } = {
    currentPassword: null,
    newPassword: null,
    newPasswordRepeat: null
  };

  public editMode: boolean = false;
  public Constant: typeof Constant = Constant;

  constructor(
    public viewUtil: ViewUtil,
    public stateUtil: StateUtil,
    private userModel: UserModel,
    private authModel: AuthModel,
    private stateService: StateService
  ) {
  }

  public changePassword(): void {
    this.changePasswordForm.onSubmit(null);

    if (this.changePasswordForm.form.valid) {
      let passwordPromise: Promise<void>;

      passwordPromise = this.authModel.changePasswordForced(this.formData.newPassword);

      passwordPromise
        .then(() => {
          if (ApplicationConfig.authMode === AuthMode.CUSTOM_OAUTH2) {
            this.viewUtil.showToastSuccess('VIEW.MAIN.ACCOUNT.CHANGE_PASSWORD.CHANGE_PASSWORD_SUCCESS');

            // update current user in the app model (in case something changes in UserDTO as a result of a forced password change)
            this.userModel
              .getCurrentUser()
              .then((user: UserDTO) => {
                this.stateUtil.goToDashboard();
              })
              .catch((error) => {
                this.viewUtil.handleServerError(error);
              });
          }
          else if (ApplicationConfig.authMode === AuthMode.COGNITO) {
            this.authModel
              .logout()
              .then(() => {
                this.viewUtil.showToastSuccess('VIEW.MAIN.ACCOUNT.CHANGE_PASSWORD.CHANGE_PASSWORD_REQUIRED_SUCCESS');
              })
              .catch((error) => {
              });
          }
        })
        .catch((error) => {
          this.viewUtil.handleServerError(error);
          if (error?.error?.errorCode === ServerErrorCode.INVALID_PASSWORD) {
            this.changePasswordForm.form.get('currentPassword').setErrors({ invalid: true });
          }
        });
    }
  }

  public backToLogin(): void {
    this.authModel.logout().catch((error) => {
    });
  }
}
