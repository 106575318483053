import { Component, OnInit, ViewChild } from '@angular/core';
import { StateService, Transition } from '@uirouter/core';
import { NgForm } from '@angular/forms';
import { ViewUtil } from 'app/util/ViewUtil';
import { State } from 'app/common/State';
import { UserRegistrationDTO } from 'app/data/dto/user/UserRegistrationDTO';
import { UserRegistrationModel } from 'app/model/UserRegistrationModel';
import { Constant } from 'app/common/Constant';
import { OptionItem } from 'app/data/local/generic/OptionItem';
import { HealthProvider } from 'app/data/enum/user/HealthProvider';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UserGender } from 'app/data/enum/account/UserGender';
import { SeniorRegistrationDTO } from 'app/data/dto/registration/SeniorRegistrationDTO';
import { SeniorValidationRequestDTO } from 'app/data/dto/registration/SeniorValdiationRequestDTO';
import { Observable } from 'rxjs';
import { SeniorValidationResponseDTO } from 'app/data/dto/registration/SeniorValidationResponseDTO';
import { OrganizationListDTO } from 'app/data/dto/registration/OrganizationListDTO';
import { map } from 'rxjs/operators';

import { RegistrationUserNotFoundComponent } from '../RegistrationUserNotFoundComponent';
import { SubscriptionType } from 'app/data/enum/subscription/SubscriptionType';
import { RegistrationType } from 'app/data/enum/user/RegistrationType';

@Component({
  selector: 'app-registration-start',
  templateUrl: 'RegistrationStartComponent.html',
  styleUrls: [ 'RegistrationStartComponent.scss' ]
})
export class RegistrationStartComponent implements OnInit {
  @ViewChild('registrationForm')
  private registrationForm: NgForm;

  public userRegistration: SeniorRegistrationDTO;
  public organizationsList$: Observable<OrganizationListDTO[]>;
  public genderList: OptionItem<UserGender>[] = this.viewUtil.createEnumSelectOptions(UserGender, 'USER_GENDER');
  public healthProviders: typeof HealthProvider = HealthProvider;
  public currentStep: number = 1;
  public noOfSteps: number = 2;
  public maxDateOfBirth: Date = this.getYesterdayDate();

  public readonly Constant: typeof Constant = Constant;
  public readonly RegistrationType: typeof RegistrationType = RegistrationType;
  public readonly SubscriptionType: typeof SubscriptionType = SubscriptionType;

  private readonly individualOrgSelectionChoice: OrganizationListDTO = { name: 'Individual', id: -1 };

  constructor(private stateService: StateService,
              public viewUtil: ViewUtil,
              private userRegistrationModel: UserRegistrationModel,
              private modalService: BsModalService,
              private transition: Transition) {
    if (this.transition.params().userRegistration) {
      this.userRegistration = this.transition.params().userRegistration;
    }
    else {
      this.userRegistration = new SeniorRegistrationDTO();
      this.userRegistration.user = new UserRegistrationDTO({ registrationType: RegistrationType.EMAIL });
    }
  }

  public ngOnInit(): void {
    this.getOrganizationList();
  }

  public validateUserAndGoToNextStep(): void {
    this.registrationForm.onSubmit(null);
    if (this.registrationForm.form.valid) {
      this.validateUser().subscribe(
        (res) => this.goNext(),
        (err) => this.showUserNotFoundModal(err)
      );
    }
  }

  public backToLogin(): void {
    this.stateService.go(State.PRELIMINARY.LOGIN);
  }

  private goNext(): void {
    if (this.userRegistration.organizationId === -1) {
      delete this.userRegistration.memberCode;
    }
    this.stateService.go(State.PRELIMINARY.REGISTRATION.PASSWORD, {
      userRegistration: this.userRegistration,
      currentStep: this.currentStep + 1,
      noOfSteps: this.noOfSteps
    });
  }

  private showUserNotFoundModal(error: any) {
    this.modalService.show(RegistrationUserNotFoundComponent, {
      class: 'user-not-found',
      initialState: { error: error }
    });
  }

  private validateUser(): Observable<SeniorValidationResponseDTO> {
    const seniorValidationRequest: SeniorValidationRequestDTO = new SeniorValidationRequestDTO();

    seniorValidationRequest.registrationType = this.userRegistration.user.registrationType;
    if (seniorValidationRequest.registrationType === RegistrationType.PHONE) {
      seniorValidationRequest.phone = this.userRegistration.user.phone;
    }
    else {
      seniorValidationRequest.email = this.userRegistration.user.email;
    }

    seniorValidationRequest.subscriptionType = this.userRegistration.subscriptionType;

    if (this.userRegistration.organizationId !== -1) {
      seniorValidationRequest.organizationId = this.userRegistration.organizationId;
      seniorValidationRequest.memberCode = this.userRegistration.memberCode;
    }

    return this.userRegistrationModel.validateSenior(seniorValidationRequest);
  }

  private getOrganizationList(): void {
    this.organizationsList$ = this.userRegistrationModel.getOrganizationsList().pipe(
      map((organizations) => {
        //turned off for now, but might be needed in the future
        //organizations.push(this.individualOrgSelectionChoice);
        return organizations;
      })
    );
  }

  private getYesterdayDate(): Date {
    const date: Date = new Date();
    date.setDate(date.getDate() - 1);
    return date;
  }
}
