import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {

  transform(value: Date): string {
    if (!value) {
      return undefined;
    }

    const dateNow: number = new Date().getTime();
    const diffMs: number = (dateNow - value.getTime());

    const diffMins: number = Math.round(diffMs / (1000 * 60));
    const diffHrs: number = Math.floor(diffMs / (1000 * 60 * 60));
    const diffDays: number = Math.floor(diffMs / (1000 * 60 * 60 * 24));

    if (diffMins === 0) {
      return 'Now';
    }
    else if (diffMins < 60) {
      return diffMins > 1 ? `${ diffMins } minutes ago` : `${ diffMins } minute ago`;
    }
    else if (diffHrs < 24) {
      return diffHrs > 1 ? `${ diffHrs } hours ago` : `${ diffHrs } hour ago`;
    }
    return diffDays > 1 ? `${ diffDays } days ago` : `${ diffDays } day ago`;
  }

}
