import { Component, Input, OnInit } from '@angular/core';
import { ServerErrorDTO } from 'app/data/dto/ServerErrorDTO';
import { ServerErrorCode } from 'app/data/enum/ServerErrorCode';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-registration-user-not-found',
  templateUrl: './RegistrationUserNotFoundComponent.html',
  styleUrls: [ './RegistrationUserNotFoundComponent.scss' ]
})
export class RegistrationUserNotFoundComponent implements OnInit {
  @Input()
  public error: any;

  public headerKey: string = 'ERROR.OPERATION_UNSUCCESSFUL';
  public errorDescriptionKey: string = 'ERROR.TRY_AGAIN';

  constructor(private bsModalRef: BsModalRef) {
  }

  ngOnInit(): void {
    if (
      this.error?.error &&
      this.error.error instanceof ServerErrorDTO &&
      this.error.error?.errorCode &&
      ServerErrorCode[this.error.error.errorCode]
    ) {
      this.headerKey = `SERVER_ERROR_CODE.${ ServerErrorCode[this.error.error.errorCode] }`;
      this.errorDescriptionKey = `SERVER_ERROR_DESCRIPTION.${ ServerErrorCode[this.error.error.errorCode] }`;
    }
  }

  public close(): void {
    this.bsModalRef.hide();
  }
}
