<div *ngIf="data" class="d-flex flex-column gap-6 p-4 modal-container">
  <div class="d-flex justify-content-between">
    <h2>{{ 'VIEW.MAIN.DASHBOARD.RECOMMENDED_VIDEOS' | translate }}</h2>
    <button type="button" class="close-button align-self-start p-0" (click)="close()">
      <svg-icon class="d-flex" src="asset/image/icon/x.svg"></svg-icon>
    </button>
  </div>
  <div class="d-flex flex-column gap-4 scrollable">
    <div *ngFor="let video of data" class="d-flex" role="button" (click)="play(video.id)">
      <div class="d-flex flex-column gap-2 w-100">
        <h3 class="font-weight-600">{{ video.title }}</h3>
        <span>{{ video.description }}</span>
        <img
          class="align-self-center"
          *ngIf="video?.thumbnail?.defaultAsset?.url"
          [src]="video.thumbnail.defaultAsset.url"
        />
      </div>
    </div>
  </div>
</div>

