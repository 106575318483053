import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-popup-alert',
  templateUrl: 'PopupAlertComponent.html',
  styleUrls: [ 'PopupAlertComponent.scss' ]
})
export class PopupAlertComponent implements OnInit, OnDestroy {

  public static POPUP_RESULT_OK: string = 'PopupAlertComponent_OK';

  public title: string;
  public message: string;
  public messageVariables: { [key: string]: any };
  public okText: string = 'COMMON.OK';

  constructor(private bsModalRef: BsModalRef,
              private modalService: BsModalService) {
  }

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
  }

  public ok(): void {
    this.modalService.setDismissReason(PopupAlertComponent.POPUP_RESULT_OK);
    this.bsModalRef.hide();
  }

  public close(): void {
    this.bsModalRef.hide();
  }

}
