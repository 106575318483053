import { BaseCriteriaDTO } from './BaseCriteriaDTO';

export class PageCriteriaDTO extends BaseCriteriaDTO {

  public pageNumber: number;

  public pageSize: number;

  constructor() {
    super();

    this.pageNumber = 0;
    this.pageSize = 10;
  }

}
