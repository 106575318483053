import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { StateService, Transition } from '@uirouter/core';
import { Constant } from 'app/common/Constant';
import { State } from 'app/common/State';
import { DynamicInputComponent, Items } from 'app/component/ui/dynamicInputs/DynamicInputComponent';
import { PopupConfirmationComponent } from 'app/component/ui/popup/PopupConfirmationComponent';
import { UserGender } from 'app/data/enum/account/UserGender';
import { LiveClassCapacity } from 'app/data/enum/liveClass/LiveClassCapacity';
import { LiveClassDuration, liveClassLengths } from 'app/data/enum/liveClass/LiveClassDuration';
import { LiveClassFrequency } from 'app/data/enum/liveClass/LiveClassFrequency';
import { LiveClassLanguage } from 'app/data/enum/liveClass/LiveClassLanguage';
import { LiveClassCategory } from 'app/data/enum/liveClass/LiveClassCategory';
import { OptionItem } from 'app/data/local/generic/OptionItem';
import { AdminModel } from 'app/model/AdminModel';
import { CoachModel } from 'app/model/CoachModel';
import { LiveClassModel } from 'app/model/LiveClassModel';
import { ViewUtil } from 'app/util/ViewUtil';
import _ from 'lodash';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { forkJoin, Observable, of, pipe, Subject, throwError } from 'rxjs';
import { catchError, delay, filter, first, switchMap, takeUntil, tap } from 'rxjs/operators';

import { minDateOfCreateLiveClass } from '../../../constants';
import { LiveClassSeriesDetailsResponseDTO } from 'app/data/dto/liveClassSeries/admin/LiveClassSeriesDetailsResponseDTO';
import { ObjectUtil } from 'app/util/ObjectUtil';
import { LiveClassSeriesUpdateRequestDTO } from 'app/data/dto/liveClassSeries/admin/LiveClassSeriesUpdateRequestDTO';
import { LiveClassSeriesVisibility } from 'app/data/enum/liveClass/LiveClassSeriesVisibility';
import { IntensityLevel } from 'app/data/enum/IntensityLevel';
import { PortalUtil } from 'app/util/PortalUtil';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import { TemplatePortal } from '@angular/cdk/portal';
import { ATTACHMENT_SERVICE_TOKEN } from 'app/component/ui/fileUpload/IAttachmentService';
import { LiveClassSeriesAttachmentService } from 'app/component/view/main/liveClasses/admin/series/service/LiveClassSeriesAttachmentService';

@Component({
  selector: 'app-live-class-series-edit-admin',
  templateUrl: './LiveClassSeriesEditAdminComponent.html',
  styleUrls: [ './LiveClassSeriesEditAdminComponent.scss' ],
  providers: [ { provide: ATTACHMENT_SERVICE_TOKEN, useClass: LiveClassSeriesAttachmentService } ]
})
export class LiveClassSeriesEditAdminComponent implements OnInit, OnDestroy {
  @ViewChild('headingTemplate', { static: true })
  private readonly headingTemplate: TemplateRef<any>;

  private destroy$: Subject<void> = new Subject<void>();
  @ViewChild('form')
  public form: NgForm;
  @ViewChild(DynamicInputComponent)
  public dynamicInputComponent: DynamicInputComponent<Record<'need', string>[]>;
  public uploadStarted: boolean = false;

  frequencies: OptionItem<LiveClassFrequency>[] = this.viewUtil.createEnumSelectOptions(
    LiveClassFrequency,
    'FREQUENCIES'
  );

  intensities: OptionItem<IntensityLevel>[] = this.viewUtil.createEnumSelectOptions(
    IntensityLevel,
    'INTENSITY_LEVEL'
  );

  lengths: OptionItem<LiveClassDuration>[] = liveClassLengths;

  public languages: OptionItem<LiveClassLanguage>[] = this.viewUtil.createEnumSelectOptions(LiveClassLanguage, 'LANGUAGE');
  public capacities: OptionItem<LiveClassCapacity>[] = this.viewUtil.createEnumSelectOptions(LiveClassCapacity, 'CAPACITY');
  public categories: OptionItem<LiveClassCategory>[] = this.viewUtil.createEnumSelectOptions(LiveClassCategory, 'CATEGORY');
  public visibilities: OptionItem<LiveClassSeriesVisibility>[] = this.viewUtil.createEnumSelectOptions(LiveClassSeriesVisibility, 'LIVE_CLASS_SERIES_VISIBILITY');

  public coaches: OptionItem<number>[] = [];
  public selectedCoaches: OptionItem<number>[] = [];

  public genderList: OptionItem<UserGender>[] = this.viewUtil.createEnumSelectOptions(UserGender, 'USER_GENDER');
  public Constant: typeof Constant = Constant;
  public id: number;
  public liveClass$: Observable<LiveClassSeriesDetailsResponseDTO>;
  public liveClassSeries: LiveClassSeriesDetailsResponseDTO;
  public items: Items[] = [];
  public minDateOfCreateLiveClass: Date = minDateOfCreateLiveClass;
  public liveClassSeriesUpdateRequest: LiveClassSeriesUpdateRequestDTO;
  public numberOfClassesLeftDisabled: boolean = false;

  constructor(private stateService: StateService,
              public viewUtil: ViewUtil,
              private transition: Transition,
              private liveClassModel: LiveClassModel,
              private adminModel: AdminModel,
              private coachModel: CoachModel,
              private modalService: BsModalService,
              private portalUtil: PortalUtil,
              private viewContainerRef: ViewContainerRef) {
    this.id = this.transition.params().id;
  }

  public ngOnInit(): void {
    this.portalUtil.attachPortalTo(
      MainLayoutComponent.PORTAL_OUTLET.HEADING,
      new TemplatePortal(this.headingTemplate, this.viewContainerRef)
    );

    this.liveClassSeriesUpdateRequest = new LiveClassSeriesUpdateRequestDTO();

    forkJoin({
      liveClassSeries: this.adminModel.getLiveClassSeriesDetails(this.id),
      coaches: this.coachModel.getCoachSelectList()
    })
      .pipe(
        takeUntil(this.destroy$),
        tap(({ liveClassSeries, coaches }) => {
          this.liveClassSeries = liveClassSeries;
          this.numberOfClassesLeftDisabled = !!liveClassSeries.endDate;

          coaches.map((coach) => {
            const { firstName, lastName } = coach.user;
            const name = `${ firstName } ${ lastName }`;
            this.coaches.push({ label: name, value: coach.id });
          });

          this.selectedCoaches = liveClassSeries.coaches.map((coach) => {
            const { firstName, lastName } = coach.user;
            const name = `${ firstName } ${ lastName }`;
            return { label: name, value: coach.id };
          });
        }),

        catchError((err) => {
          this.viewUtil.handleServerError(err);
          return throwError(() => err);
        })
      )
      .subscribe();
  }

  public track(index: any, item: { item: any; id: string }): string {
    return item.id;
  }

  public handleUpdatedNeeds(items: any[]): void {
    this.liveClassSeries.liveClassSeriesNeeds = _.cloneDeep(items);
  }

  public updateAttachments(attachments: number[]): void {
    this.liveClassSeriesUpdateRequest.attachmentIds = attachments;
  }

  public handleStartUpload(uploadStart: boolean): void {
    this.uploadStarted = uploadStart;
  }

  public save(): void {
    const request = _.pick(this.liveClassSeries, [
      'capacity',
      'category',
      'description',
      'duration',
      'intensity',
      'language',
      'liveClassSeriesNeeds',
      'startDate',
      'endDate',
      'visibility',
      'daysOfWeek',
      'title',
      'url'
    ]);

    this.liveClassSeriesUpdateRequest = ObjectUtil.plainToClass(LiveClassSeriesUpdateRequestDTO, {
      ...request,
      attachmentIds: this.liveClassSeriesUpdateRequest.attachmentIds,
      coachIds: this.selectedCoaches.map((coach) => coach.value),
      numberOfClassesLeft: this.numberOfClassesLeftDisabled ? undefined : this.liveClassSeries.numberOfClassesLeft,
      imageId: this.liveClassSeriesUpdateRequest.imageId
        ? this.liveClassSeriesUpdateRequest.imageId
        : this.liveClassSeries.thumbnail.id
    });

    of(null)
      .pipe(
        delay(0),
        switchMap(() => {
          this.minDateOfCreateLiveClass = new Date();
          this.form.control.updateValueAndValidity();
          return of(this.form.form.valid);
        }),
        filter((isValid) => isValid),
        switchMap(() =>
          this.liveClassModel.updateLiveClassSeries(this.id, this.liveClassSeriesUpdateRequest)
        ),
        tap(() => {
          this.stateService.go(State.MAIN.ADMIN.LIVE_CLASSES.SERIES.DETAILS, { id: this.id });
        }),
        takeUntil(this.destroy$),
        catchError((err) => {
          this.viewUtil.handleServerError(err);
          return of(null);
        })
      )
      .subscribe();
  }

  public submit(): void {
    this.minDateOfCreateLiveClass = new Date();
    this.dynamicInputComponent.onSubmit();
    this.liveClassSeries.liveClassSeriesNeeds = this.liveClassSeries.liveClassSeriesNeeds.filter(
      (needObj) => needObj.need && needObj.need.length >= 1
    );

    this.form.onSubmit(null);

    if (this.form.valid) {
      const modal: BsModalRef = this.modalService.show(PopupConfirmationComponent, {
        initialState: {
          title: 'VIEW.MAIN.LIVE_CLASSES.SERIES.MESSAGE.EDIT.TITLE',
          message: 'VIEW.MAIN.LIVE_CLASSES.SERIES.MESSAGE.EDIT.DESCRIPTION',
          okText: 'COMMON.CONFIRM',
          cancelText: 'COMMON.CANCEL'
        },
        class: 'modal-dialog-centered'
      });

      modal.onHide.pipe(first()).subscribe((result: string) => {
        if (result === PopupConfirmationComponent.POPUP_RESULT_CONFIRM) {
          this.save();
        }
      });
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.portalUtil.detachPortalFrom(MainLayoutComponent.PORTAL_OUTLET.HEADING);
  }

  public handleCoachChange(event: OptionItem<number>[]): void {
    this.selectedCoaches = event;
  }

  public onBackClick(): void {
    history.back();
  }

  public onDeleteClick(): void {
    const modal: BsModalRef = this.modalService.show(PopupConfirmationComponent, {
      initialState: {
        message: 'VIEW.MAIN.USER.DELETE_CLASS_SERIES_WARNING',
        messageVariables: { class: this.liveClassSeries.title },
        okText: 'COMMON.YES',
        cancelText: 'COMMON.NO'
      },
      class: 'modal-dialog-centered'
    });
    modal.onHide
      .pipe(
        filter((reason) => reason === PopupConfirmationComponent.POPUP_RESULT_CONFIRM),
        switchMap(() => this.adminModel.deleteLiveClassSeries([ this.id ])),
        pipe(
          takeUntil(this.destroy$),
          tap(() => {
            this.viewUtil.showToastSuccess('COMMON.SUCCESS');
            this.stateService.go(State.MAIN.ADMIN.LIVE_CLASSES.SERIES.LIST, { id: this.id });
          }),
          catchError((err) => {
            this.viewUtil.handleServerError(err);
            return of(null);
          })
        )
      )
      .subscribe();
  }
}
