<form #subscriptionForm="ngForm" class="d-flex flex-column gap-4" novalidate (ngSubmit)="save(subscriptionForm.valid)">
  <div class="d-flex gap-4 flex-wrap justify-content-end w-100">
    <button type="button" class="btn btn-outline-primary edit-btn" (click)="changeEditMode(false)">
      <div class="d-flex align-items-center justify-content-center">
        <svg-icon class="d-flex mr-2" src="asset/image/icon/chevron-left.svg"></svg-icon>
        <span>{{ 'COMMON.CANCEL' | translate }}</span>
      </div>
    </button>

    <button type="submit" class="btn btn-primary edit-btn">
      <div class="d-flex align-items-center justify-content-center">
        <svg-icon class="d-flex mr-2" src="asset/image/icon/edit.svg"></svg-icon>
        <span>{{ 'COMMON.SAVE' | translate }}</span>
      </div>
    </button>
  </div>

  <section>
    <div class="header">{{ 'VIEW.MAIN.ACCOUNT.BILLING_INFO.CHANGE_SUBSCRIPTION.TITLE' | translate }}</div>
    <div class="row">
      <div class="form-group col-12 col-sm-6">
        <label for="provider">{{ 'VIEW.PRELIMINARY.REGISTRATION.PROVIDER' | translate }}</label>
        <ng-select
          type="text"
          labelForId="provider"
          name="provider"
          #providerModel="ngModel"
          placeholder="{{ 'VIEW.PRELIMINARY.REGISTRATION.PROVIDER' | translate }}"
          class="provider-select"
          dropdownPosition="top"
          [items]="organizations$ | async"
          bindLabel="name"
          bindValue="id"
          [ngClass]="{'is-invalid': subscriptionForm.submitted && providerModel.invalid, 'is-valid': subscriptionForm.submitted && providerModel.valid }"
          [(ngModel)]="organizationId"
          required>
        </ng-select>
        <div class="invalid-feedback" *ngIf="subscriptionForm.submitted && providerModel.invalid">
          <div *ngIf="providerModel.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
        </div>
      </div>

      <ng-container *ngIf="organizationId && organizationId !== -1">
        <div class="form-group col-12 col-sm-6">
          <label for="memberCode">{{ 'VIEW.PRELIMINARY.REGISTRATION.MEMBER_CODE' | translate }}</label>
          <input
            type="text"
            id="memberCode"
            name="memberCode"
            #memberCodeModel="ngModel"
            class="form-control"
            placeholder="{{ 'VIEW.PRELIMINARY.REGISTRATION.MEMBER_CODE' | translate }}"
            [ngClass]="{'is-invalid': subscriptionForm.submitted && memberCodeModel.invalid, 'is-valid': subscriptionForm.submitted && memberCodeModel.valid }"
            [(ngModel)]="memberCode"
            required
            appMemberCodeValidator
          />
          <div class="invalid-feedback" *ngIf="subscriptionForm.submitted && memberCodeModel.invalid">
            <div *ngIf="memberCodeModel.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
            <div *ngIf="memberCodeModel.errors['memberCode']">{{ 'VALIDATION.MEMBER_CODE' | translate }}</div>
          </div>
        </div>
      </ng-container>
    </div>
  </section>
</form>
