import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { CoachDTO } from 'app/data/dto/coach/CoachDTO';

@Component({
  selector: 'app-rating',
  styleUrls: [ './RatingComponent.scss' ],
  templateUrl: 'RatingComponent.html'
})
export class RatingComponent implements OnChanges, ICellRendererAngularComp {
  @Input()
  public rating: number = 0;

  public stars: string[] = [];

  public readonly maxRating: number = 5;

  data: CoachDTO;

  public agInit(params: ICellRendererParams): void {
    this.data = params.data;
    if (this.data && this.data.rating !== undefined && this.data.rating !== null) {
      this.rating = this.data.rating;
      this.updateStars();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.rating) {
      this.updateStars();
    }
  }

  updateStars(): void {
    this.stars = [];
    for (let i = 1; i <= 5; i++) {
      this.stars.push(i <= this.rating ? 'star-filled' : 'star-outline');
    }
  }

  public refresh(params: ICellRendererParams): boolean {
    return false;
  }
}
