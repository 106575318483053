import { Transform, Type } from 'class-transformer';
import { DateUtil } from 'app/util/DateUtil';
import { LiveClassCategory } from 'app/data/enum/liveClass/LiveClassCategory';
import { ScheduledLiveClassNeedDTO } from '../ScheduledLiveClassNeedDTO';
import { IntensityLevel } from 'app/data/enum/IntensityLevel';

export class ScheduledLiveClassUpdateRequestDTO {
  public attachmentIds: number[];

  @Transform(({ value }) => value || null, { toPlainOnly: true })
  public capacity?: number; // min 1 or null for no-limit

  public category: LiveClassCategory;

  public coachIds: number[];

  public description: string;

  public duration: number;

  public imageId: number;

  public intensity: IntensityLevel;

  public language: string;

  @Type(() => ScheduledLiveClassNeedDTO)
  public scheduledLiveClassNeeds: ScheduledLiveClassNeedDTO[];

  @Transform(DateUtil.dateTimeConversion)
  public startDate: Date;

  public title: string;

  public url: string;
}
