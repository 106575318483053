<ng-template #headingTemplate>
  <h1>{{ 'VIEW.MAIN.DASHBOARD.MY_SCHEDULE' | translate }}</h1>
</ng-template>

<section class="section">
  <div class="control-panel p-4 d-flex flex-column w-100 w-lg-auto flex-lg-row align-items-center justify-content-between">
    <div class="d-flex justify-content-between align-items-center w-100 w-lg-auto mb-4 mb-lg-0">
      <button
        id="chevron-left"
        class="btn btn-link button"
        mwlCalendarPreviousView
        [view]="view"
        [viewDate]="viewDate"
        (viewDateChange)="handleViewDateChange(-1)"
      >
        <svg-icon src="asset/image/icon/chevron-left.svg"></svg-icon>
      </button>

      <div class="d-flex flex-column align-items-center px-2">
        <div (click)="dp.toggle()">
          <span class="btn-link">{{ weekDisplay }}</span>
        </div>
        <input
          type="text"
          class="text-like-input"
          bsDatepicker
          [bsConfig]="bsConfig"
          [(ngModel)]="viewDate"
          #dp="bsDatepicker"
          (bsValueChange)="onDateChange($event)"
          readonly
        />
      </div>

      <button
        id="chevron-right"
        class="btn btn-link button"
        mwlCalendarNextView
        [view]="view"
        [viewDate]="viewDate"
        (viewDateChange)="handleViewDateChange(+1)"
      >
        <svg-icon src="asset/image/icon/chevron-right.svg"></svg-icon>
      </button>
    </div>

    <div class="d-flex flex-column w-100 w-lg-auto flex-lg-row align-items-center">
      <button
        id="print-button"
        type="button"
        class="btn btn-outline-success btn-block mb-4 mb-lg-0 mr-lg-4"
        (click)="onPrintClick()"
      >
        <svg-icon class="mr-2" src="asset/image/icon/printer.svg"></svg-icon>
        {{ 'COMMON.PRINT' | translate }}
      </button>

      <!--
      <div class="form-group m-0 position-relative w-100">
        TODO: 2nd phase
          <ng-select
          class="custom-ng-select"
          id="week"
          name="week"
          #week="ngModel"
          [placeholder]="'COMMON.CHOOSE' | translate"
          [searchable]="false"
          [(ngModel)]="formData.optionSelected1"
          (change)="setView(formData.optionSelected1.view)"
        >
          <ng-option *ngFor="let option of options" [value]="option">{{ option.name }}</ng-option>
        </ng-select>
        <img src="/asset/image/icon/schedule_green.svg" class="ng-select-icon" />
      </div>
    -->
    </div>
  </div>

  <ng-template #weekHeaderTemplate let-days="days" let-locale="locale" let-dayHeaderClicked="dayHeaderClicked">
    <div class="cal-day-headers mr-0">
      <div
        class="cal-header"
        *ngFor="let day of days"
        [class.cal-past]="day.isPast"
        [class.cal-today]="day.isToday"
        [class.cal-future]="day.isFuture"
        [class.cal-weekend]="day.isWeekend"
        (click)="dayHeaderClicked.emit({day: day})"
      >
          <span>
            <p class="mb-0 font-size-sm font-weight-600">
              {{ day.date | calendarDate:'weekViewColumnHeader':locale | slice:0:3 | uppercase }}
            </p>
          </span>
        <span>
            <p class="mb-0 font-size-1-5 font-weight-600">{{ day.date | date:'d' }}</p>
          </span>
      </div>
    </div>
  </ng-template>

  <div class="scroll-container" #scrollContainer>
    <mwl-calendar-week-view
      [headerTemplate]="weekHeaderTemplate"
      [viewDate]="viewDate"
      [events]="events"
      [hourSegments]="2"
      [dayStartHour]="0"
      [dayEndHour]="24"
      (eventClicked)="handleEvent('Clicked', $event.event)"
      (eventTimesChanged)="eventTimesChanged($event)"
      [refresh]="refresh"
      [daysInWeek]="daysInWeek"
    >
    </mwl-calendar-week-view>
  </div>

  <div class="legend-container mt-5">
    <div class="live-class">{{ 'DOMAIN.SCHEDULE.LEGEND.LIVE_CLASS' | translate }}</div>
    <div class="watch-party">{{ 'DOMAIN.SCHEDULE.LEGEND.WATCH_PARTY' | translate }}</div>
  </div>
</section>
