import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SeniorNotRelatedPageCriteriaDTO } from 'app/data/dto/senior/SeniorNotRelatedPageCriteriaDTO';
import { PageDTO } from 'app/data/dto/PageDTO';
import { SeniorNotRelatedPageDTO } from 'app/data/dto/senior/SeniorNotRelatedPageDTO';
import { ViewUtil } from 'app/util/ViewUtil';
import { NgSelectComponent } from '@ng-select/ng-select';
import { FriendSentInvitationService } from 'app/service/friend/FriendSentInvitationService';
import { FriendService } from 'app/service/friend/FriendService';

@Component({
  selector: 'app-add-friend-modal',
  templateUrl: './AddFriendModalComponent.html',
  styleUrls: [ './AddFriendModalComponent.scss' ]
})
export class AddFriendModalComponent implements OnInit {
  @ViewChild('seniorSelect')
  public seniorSelect: NgSelectComponent;

  private onClose: Subject<void> = new Subject();

  public close$: Observable<void> = this.onClose.asObservable();

  public selectedSeniors: SeniorNotRelatedPageDTO[] = [];

  public page: PageDTO<SeniorNotRelatedPageDTO>;

  private criteria: SeniorNotRelatedPageCriteriaDTO = new SeniorNotRelatedPageCriteriaDTO();

  constructor(private readonly bsModalRef: BsModalRef,
              private readonly friendService: FriendService,
              private readonly friendSentInvitationService: FriendSentInvitationService,
              private readonly viewUtil: ViewUtil) {
  }

  public ngOnInit(): void {
    this.getSeniorsPage();
  }

  public save(): void {
    const ids: number[] = this.selectedSeniors.map((s: SeniorNotRelatedPageDTO) => s.id);

    this.friendSentInvitationService.invite(ids)
      .subscribe(() => {
          this.viewUtil.showToastSuccess('DOMAIN.FRIEND_INVITATION.MESSAGE.SEND_INVITATION.SUCCESS');

          this.onClose.next();
          this.close();
        },
        (err) => this.viewUtil.handleServerError(err));
  }

  public close(): void {
    this.onClose.complete();
    this.bsModalRef.hide();
  }

  private getSeniorsPage(nextPage: boolean = false): void {
    if (nextPage && this.page) {
      this.criteria.pageNumber++;

      if (this.page.totalPages <= this.criteria.pageNumber) {
        return;
      }
    }

    this.friendService.getSeniorsPage(this.criteria)
      .subscribe((page: PageDTO<SeniorNotRelatedPageDTO>) => {
        if (nextPage) {
          this.page.content = this.page.content.concat(page.content);
        }
        else {
          this.page = page;
          this.onSeniorSelectScroll();
        }
      }, (error) => {
        this.viewUtil.handleServerError(error);
      });
  }

  public search({ term }): void {
    this.criteria.pageNumber = 0;
    this.criteria.searchPhrase = term;
    this.page = null;
    this.getSeniorsPage();
  }

  private onSeniorSelectScroll(): void {
    const dropdownElement: HTMLElement = this.seniorSelect.dropdownPanel.scrollElementRef.nativeElement;

    dropdownElement.onscroll = (): void => {
      const bottomReached: boolean = (dropdownElement.scrollHeight - dropdownElement.scrollTop) === dropdownElement.clientHeight;

      if (bottomReached) {
        this.getSeniorsPage(true);
      }
    };
  }
}
