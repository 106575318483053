<div *ngIf="data$ | async as data" class="d-flex flex-column gap-6 p-4">
  <div class="d-flex justify-content-between">
    <div class="d-flex flex-column">
      <span class="text-sm-light">{{ 'VIEW.MAIN.ON_DEMAND.TITLE' | translate }}</span>
      <span class="text-bold">{{ data.title }}</span>
    </div>
    <button type="button" class="close-button align-self-start p-0" (click)="close()">
      <svg-icon class="d-flex" src="asset/image/icon/x.svg"></svg-icon>
    </button>
  </div>
  <div class="row no-gutters gap-6">
    <div class="col d-flex flex-column">
      <span class="text-sm-light">{{ 'VIEW.MAIN.ON_DEMAND.CATEGORY' | translate }}</span>
      <span>{{ data.category | prefix: "ENUM.VIDEO_CATEGORY." | translate }}</span>
    </div>
    <div class="col d-flex flex-column">
      <span class="text-sm-light">{{ 'VIEW.MAIN.ON_DEMAND.SUBCATEGORY' | translate }}</span>
      <span *ngFor="let subcategory of data.subcategories">
        {{ subcategory | prefix: 'ENUM.VIDEO_SUBCATEGORY.' | translate }}
      </span>
    </div>
  </div>
  <div class="row no-gutters gap-6">
    <div class="col d-flex flex-column">
      <span class="text-sm-light">{{ 'VIEW.MAIN.ON_DEMAND.COACH' | translate }}</span>
      <span *ngFor="let coach of data.coaches">{{ coach.user.firstName }} {{ coach.user.lastName }}</span>
    </div>
    <div class="col d-flex flex-column">
      <span class="text-sm-light">{{ 'VIEW.MAIN.ON_DEMAND.INTENSITY' | translate }}</span>
      <span>{{ data.intensity | prefix: 'ENUM.INTENSITY_LEVEL.' | translate }}</span>
    </div>
  </div>
  <div class="row no-gutters gap-6">
    <div class="col d-flex flex-column">
      <span class="text-sm-light">{{ 'VIEW.MAIN.ON_DEMAND.DESCRIPTION' | translate }}</span>
      <span>{{ data.description }}</span>
    </div>
    <div *ngIf="data.rating >= Constant.STAR_RATING_MIN_VALUE" class="col d-flex flex-column">
      <span class="text-sm-light">{{ 'VIEW.MAIN.ON_DEMAND.RATING' | translate }}</span>
      <app-rating-stars [value]="data.rating"></app-rating-stars>
    </div>
  </div>
  <div class="divider"></div>
  <div class="d-flex justify-content-end gap-4">
    <button class="btn btn-light d-flex gap-2 justify-content-center align-items-center" (click)="close()">
      <svg-icon src="asset/image/icon/chevron-left.svg"></svg-icon>
      <span>{{ 'COMMON.BACK' | translate }}</span>
    </button>

    <button class="btn btn-outline-primary " (click)="createWatchParty(data)">
      <svg-icon src="asset/image/icon/plus.svg"></svg-icon>
      <span>{{ 'VIEW.MAIN.ON_DEMAND.CREATE_WATCH_PARTY' | translate }}</span>
    </button>

    <button class="btn btn-primary d-flex gap-2 justify-content-center align-items-center" (click)="play()">
      <svg-icon class="text-white" src="asset/image/icon/player-play.svg"></svg-icon>
      <span>{{ 'COMMON.PLAY' | translate }}</span>
    </button>
  </div>
</div>
