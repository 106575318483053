import { IdentifiableObjectDTO } from 'app/data/dto/IdentifiableObjectDTO';
import { Exclude, Type } from 'class-transformer';
import { ImageResponseDTO } from 'app/data/dto/images/ImageResponseDTO';
import * as _ from 'lodash';
import { User } from 'app/data/local/user/User';

export class BaseUserDTO extends IdentifiableObjectDTO<number> implements User {
  public firstName: string;

  public lastName: string;

  @Type(() => ImageResponseDTO)
  public avatar?: ImageResponseDTO;

  @Exclude()
  public get fullName(): string {
    return _.trim(`${ this.firstName ?? '' } ${ this.lastName ?? '' }`);
  }
}