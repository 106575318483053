<ng-container>
  <div
    *ngIf="selectedUsers?.length"
    id="selected-classes-bar"
    class="p-2 d-flex justify-content-between align-items-center mt-4"
  >
    <span class="ml-4">
      {{ selectedUsers.length }}
      {{ (selectedUsers.length > 1 ? 'VIEW.MAIN.USER.USERS' : 'VIEW.MAIN.USER.USER') | translate }}
      {{ 'COMMON.SELECTED' | translate | lowercase }}
    </span>
    <div class="d-flex">
      <div>
        <button class="btn btn-primary" (click)="emitAction()">
          <span class="d-flex gap-2 justify-content-center align-items-center">
            <svg-icon class="d-flex"
                      [src]="'asset/image/icon/toggle-' + (mode === MODE.ACTIVATE ? 'right' : 'left') + '.svg'">
            </svg-icon>
            <span>{{ (mode === MODE.ACTIVATE ? 'COMMON.ACTIVATE' : 'COMMON.DEACTIVATE') | translate }}</span>
          </span>
        </button>
      </div>
    </div>
  </div>
</ng-container>
