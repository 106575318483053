import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { VideoUploadRequestDTO } from 'app/data/dto/onDemandVideo/vimeo/VideoUploadRequestDTO';
import { VideoUploadResponseDTO } from 'app/data/dto/onDemandVideo/vimeo/VideoUploadResponseDTO';
import { UploadFile } from 'app/data/local/file/UploadFile';
import { VimeoUploadStatus } from 'app/data/local/onDemandVideo/VimeoUploadStatus';
import { FileUtil } from 'app/util/FileUtil';
import { ViewUtil } from 'app/util/ViewUtil';
import { FileUploader } from 'ng2-file-upload';
import { Upload, UploadOptions } from 'tus-js-client';
import { OnDemandVideoModel } from 'app/model/OnDemandVideoModel';

@Component({
  selector: 'app-on-demand-videos-upload-to-vimeo',
  templateUrl: './OnDemandVideosUploadToVimeoComponent.html',
  styleUrls: [ './OnDemandVideosUploadToVimeoComponent.scss' ]
})
export class OnDemandVideosUploadToVimeoComponent implements OnInit, OnDestroy {
  @Output()
  public uploadStatusChanged: EventEmitter<VideoUploadResponseDTO> = new EventEmitter<VideoUploadResponseDTO>();

  public videoUploadStatus: VimeoUploadStatus;
  public uploadFile: UploadFile = new UploadFile();
  public videoPlaceholderData: VideoUploadResponseDTO;
  public allowedExtensions: string[] = FileUtil.VIDEO_EXTENSIONS;
  public maxSize: number = 250 * 1024 * 1024 * 1024; //250 GB
  @ViewChild('fileForm')
  private fileForm: NgForm;

  constructor(
    private onDemandVideoModel: OnDemandVideoModel,
    private viewUtil: ViewUtil
  ) {
  }

  ngOnInit(): void {
    this.uploadFile.fileUploader = new FileUploader({ queueLimit: 1 });
  }

  ngOnDestroy(): void {
    //delete vimeo placeholder if the user changes view before saving without manually canceling upload
    if (this.videoUploadStatus && !this.videoUploadStatus.uploadComplete) {
      this.cancelUpload();
    }
  }

  public createVimeoPlaceholderAndUpload(file: File): void {
    const uploadRequest: VideoUploadRequestDTO = new VideoUploadRequestDTO();
    uploadRequest.uploadSizeInBytes = file.size;
    this.onDemandVideoModel.createVimeoUploadRequest(uploadRequest).subscribe(
      (res) => {
        this.videoPlaceholderData = res;
        this.uploadVideoToVimeo(file, res.uploadUrl);
      },
      (err) => {
        this.viewUtil.handleServerError(err);
      }
    );
  }

  public uploadVideoToVimeo(file: File, uploadUrl: string): void {
    this.videoUploadStatus = new VimeoUploadStatus();
    this.videoUploadStatus.progress = 0;
    const options: UploadOptions = {
      uploadUrl: uploadUrl,
      onProgress: (bytesSent, bytesTotal) => {
        const progress = Math.floor((bytesSent / bytesTotal) * 100);
        this.videoUploadStatus.progress = progress;
      },
      onSuccess: () => {
        this.videoUploadStatus.progress = 100;
        this.videoUploadStatus.uploadComplete = true;
        this.uploadStatusChanged.emit(this.videoPlaceholderData);
      },
      onError: (error) => {
        this.videoUploadStatus.error = true;
        this.uploadStatusChanged.emit(null);
      }
    };
    const upload = new Upload(file, options);
    upload.start();
  }

  public cancelUpload(): void {
    this.onDemandVideoModel.deleteVimeoPlaceholderVideo(this.videoPlaceholderData.id).subscribe(() => {
      this.videoUploadStatus = null;
      this.uploadFile.file = null;
      this.uploadStatusChanged.emit(null);
    });
  }

  public onFileChange(file: File): void {
    this.uploadFile.reset();
    this.uploadFile.file = file;
    this.fileForm.onSubmit(null);
    if (this.fileForm.form.valid) {
      this.createVimeoPlaceholderAndUpload(file);
    }
  }
}
