import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { StateService } from '@uirouter/core';
import { Constant } from 'app/common/Constant';
import { State } from 'app/common/State';
import { DynamicInputComponent, Items } from 'app/component/ui/dynamicInputs/DynamicInputComponent';
import { CoachDTO } from 'app/data/dto/coach/CoachDTO';
import { UserGender } from 'app/data/enum/account/UserGender';
import { LiveClassCapacity } from 'app/data/enum/liveClass/LiveClassCapacity';
import { LiveClassDuration, liveClassLengths } from 'app/data/enum/liveClass/LiveClassDuration';
import { LiveClassFrequency } from 'app/data/enum/liveClass/LiveClassFrequency';
import { LiveClassLanguage } from 'app/data/enum/liveClass/LiveClassLanguage';
import { LiveClassCategory } from 'app/data/enum/liveClass/LiveClassCategory';
import { OptionItem } from 'app/data/local/generic/OptionItem';
import { AdminModel } from 'app/model/AdminModel';
import { CoachModel } from 'app/model/CoachModel';
import { ViewUtil } from 'app/util/ViewUtil';
import _ from 'lodash';
import moment from 'moment';
import { of, Subject } from 'rxjs';
import { catchError, delay, filter, switchMap, takeUntil, tap } from 'rxjs/operators';

import { minDateOfCreateLiveClass } from '../../../constants';
import { LiveClassSeriesCreateRequestDTO } from 'app/data/dto/liveClassSeries/LiveClassSeriesCreateRequestDTO';
import { LiveClassSeriesDetailsResponseDTO } from 'app/data/dto/liveClassSeries/admin/LiveClassSeriesDetailsResponseDTO';
import { LiveClassSeriesVisibility } from 'app/data/enum/liveClass/LiveClassSeriesVisibility';
import { IntensityLevel } from 'app/data/enum/IntensityLevel';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import { TemplatePortal } from '@angular/cdk/portal';
import { PortalUtil } from 'app/util/PortalUtil';
import { ATTACHMENT_SERVICE_TOKEN } from 'app/component/ui/fileUpload/IAttachmentService';
import { LiveClassSingleFileUploadService } from 'app/component/view/main/liveClasses/admin/single/service/LiveClassSingleAttachmentService';
import { LiveClassSeriesAttachmentService } from 'app/component/view/main/liveClasses/admin/series/service/LiveClassSeriesAttachmentService';

@Component({
  selector: 'app-live-class-series-create-admin',
  templateUrl: './LiveClassSeriesCreateAdminComponent.html',
  styleUrls: [ './LiveClassSeriesCreateAdminComponent.scss' ],
  providers: [ { provide: ATTACHMENT_SERVICE_TOKEN, useClass: LiveClassSeriesAttachmentService } ]
})
export class LiveClassSeriesCreateAdminComponent implements OnInit, OnDestroy {
  @ViewChild('headingTemplate', { static: true })
  private readonly headingTemplate: TemplateRef<any>;

  @ViewChild('form') form: NgForm;
  @ViewChild(DynamicInputComponent) dynamicInputComponent: DynamicInputComponent<Record<'need', string>[]>;
  public uploadStarted: boolean = false;
  private destroy$: Subject<void> = new Subject<void>();
  public frequencies: OptionItem<LiveClassFrequency>[] = this.viewUtil.createEnumSelectOptions(
    LiveClassFrequency,
    'FREQUENCIES'
  );

  public intensities: OptionItem<IntensityLevel>[] = this.viewUtil.createEnumSelectOptions(
    IntensityLevel,
    'INTENSITY_LEVEL'
  );

  public lengths: OptionItem<LiveClassDuration>[] = liveClassLengths;
  public languages: OptionItem<LiveClassLanguage>[] = this.viewUtil.createEnumSelectOptions(LiveClassLanguage, 'LANGUAGE');
  public capacities: OptionItem<LiveClassCapacity>[] = this.viewUtil.createEnumSelectOptions(LiveClassCapacity, 'CAPACITY');
  public categories: OptionItem<LiveClassCategory>[] = this.viewUtil.createEnumSelectOptions(LiveClassCategory, 'CATEGORY');
  public visibilities: OptionItem<LiveClassSeriesVisibility>[] = this.viewUtil.createEnumSelectOptions(LiveClassSeriesVisibility, 'LIVE_CLASS_SERIES_VISIBILITY');

  public coaches: OptionItem<CoachDTO>[] = [];

  public liveClassSeries: LiveClassSeriesCreateRequestDTO = new LiveClassSeriesCreateRequestDTO();
  public items: Items[] = [];

  public genderList: OptionItem<UserGender>[] = this.viewUtil.createEnumSelectOptions(UserGender, 'USER_GENDER');
  public Constant = Constant;
  public minDateOfCreateLiveClass: Date = minDateOfCreateLiveClass;
  public thumbnailFormStatus: boolean = false;
  public timeHasBeenSet: boolean = false;

  constructor(public viewUtil: ViewUtil,
              private stateService: StateService,
              private adminModel: AdminModel,
              private coachModel: CoachModel,
              private portalUtil: PortalUtil,
              private viewContainerRef: ViewContainerRef) {
  }

  public ngOnInit(): void {
    this.portalUtil.attachPortalTo(
      MainLayoutComponent.PORTAL_OUTLET.HEADING,
      new TemplatePortal(this.headingTemplate, this.viewContainerRef)
    );

    this.coachModel
      .getCoachSelectList()
      .pipe(
        takeUntil(this.destroy$),
        tap((coaches: any[]) => {
          coaches.forEach(({ id, user: { firstName, lastName } }) => {
            this.coaches.push({
              label: `${ firstName } ${ lastName }`,
              value: id
            });
          });
        })
      )
      .subscribe();
  }

  public handleUpdatedNeeds(items): void {
    this.liveClassSeries.liveClassSeriesNeeds = _.cloneDeep(items);
  }

  public validateTime(timeControl: NgModel): void {
    this.timeHasBeenSet = true;
    const selectedDate = timeControl.value;
    if (moment(selectedDate).isBefore(new Date(), 'second')) {
      timeControl.control.setErrors({ minDateTime: true });
    }
    else {
      timeControl.control.setErrors(null);
    }
    this.liveClassSeries.startDate?.setSeconds(0);
  }

  public submit(): void {
    const currentDate = new Date();
    this.minDateOfCreateLiveClass = currentDate;
    this.form.control.updateValueAndValidity();

    this.liveClassSeries.liveClassSeriesNeeds = this.liveClassSeries.liveClassSeriesNeeds.filter(
      (needObj) => needObj?.need && needObj.need.length >= 1
    );

    const req: LiveClassSeriesCreateRequestDTO =
      this.liveClassSeries?.capacity === 0
        ? {
          ...this.liveClassSeries,
          capacity: null
        }
        : { ...this.liveClassSeries };

    this.form.onSubmit(null);
    of(null)
      .pipe(
        // Delay to allow time for update and validation
        delay(0),
        switchMap(() => {
          // Date update and validation initialization
          this.minDateOfCreateLiveClass = new Date();
          this.form.control.updateValueAndValidity();
          return of(this.form.form.valid && this.thumbnailFormStatus);
        }),
        filter((isValid) => isValid), // Proceed only if the form is valid
        switchMap(() => {
          return this.adminModel.createLiveClassSeries(req);
        }),
        tap(({ id }: LiveClassSeriesDetailsResponseDTO) => {
          this.stateService.go(State.MAIN.ADMIN.LIVE_CLASSES.SERIES.DETAILS, { id });
        }),
        takeUntil(this.destroy$),
        catchError((err) => {
          this.viewUtil.handleServerError(err);
          return of(null);
        })
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.portalUtil.detachPortalFrom(MainLayoutComponent.PORTAL_OUTLET.HEADING);
  }

  public handleImageId(imageId: number): void {
    this.liveClassSeries.imageId = imageId;
  }

  public handleAttachmentIds(attachmentIds: number[]): void {
    this.liveClassSeries.attachmentIds = attachmentIds;
  }

  public handleStartUpload(uploadStart: boolean): void {
    this.uploadStarted = uploadStart;
  }

  public handleThumbnailFormStatus(thumbnailStatus: boolean): void {
    this.thumbnailFormStatus = thumbnailStatus;
  }

  public onBackClick(): void {
    history.back();
  }

  public onDateChange(): void {
    if (!this.timeHasBeenSet) {
      const currentDate = new Date(this.liveClassSeries.startDate);
      const currentTime = new Date();

      currentDate.setHours(currentTime.getHours(), currentTime.getMinutes(), currentTime.getSeconds());

      this.liveClassSeries.startDate = currentDate;
    }
  }
}
