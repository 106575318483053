import { Transform, Type } from 'class-transformer';
import { DateUtil } from 'app/util/DateUtil';
import { UserSeniorUpdateRequestDTO } from '../user/UserSeniorUpdateRequestDTO';
import { UserAddressDTO } from '../user/UserAddressDTO';
import { UserSubscriptionDTO } from 'app/data/dto/user/UserSubscriptionDTO';

export class SeniorUpdateRequestDTO {
  @Type(() => UserAddressDTO)
  @Transform(({ value }) => value || new UserAddressDTO(), { toClassOnly: true })
  public address?: UserAddressDTO = new UserAddressDTO();

  @Transform(DateUtil.dateConversion)
  public dateOfBirth: Date;

  public gender?: string;

  @Type(() => UserSubscriptionDTO)
  public subscription: UserSubscriptionDTO = new UserSubscriptionDTO();

  @Type(() => UserSeniorUpdateRequestDTO)
  @Transform(({ value }) => value || new UserSeniorUpdateRequestDTO(), { toClassOnly: true })
  public user: UserSeniorUpdateRequestDTO;
}
