import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BaseCriteriaDTO } from 'app/data/dto/BaseCriteriaDTO';
import { cloneDeep, filter, isArray, isEqual } from 'lodash';
import { PageCriteriaDTO } from 'app/data/dto/PageCriteriaDTO';

@Component({
  selector: 'app-selected-filters',
  templateUrl: './SelectedFiltersComponent.html',
  styleUrls: [ './SelectedFiltersComponent.scss' ]
})
export class SelectedFiltersComponent implements OnChanges {
  @Input() criteria: PageCriteriaDTO;
  @Input() friendlyNames: { [key: string]: string };
  @Input() getLabelForKeyValue: (key: string, value: unknown) => string;
  @Output() criteriaChange: EventEmitter<PageCriteriaDTO> = new EventEmitter<PageCriteriaDTO>();

  public collection: { key: string, label: string, value: unknown[] }[];

  public getObjectKeys(obj: BaseCriteriaDTO): { key: string, label: string, value: unknown[] }[] {
    return Object.keys(obj)
      .filter((key: string) => this.friendlyNames[key] && obj[key])
      .map((key: string) => ({
        key,
        label: this.friendlyNames[key],
        value: isArray(obj[key]) ? obj[key] : [ obj[key] ]
      }));
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.criteria) {
      this.collection = this.getObjectKeys(changes.criteria.currentValue);
    }
  }

  public removeFilter(key: string, value: unknown): void {
    const criteria = cloneDeep(this.criteria);

    if (Array.isArray(criteria[key])) {
      criteria[key] = filter(criteria[key], (item) => !isEqual(item, value));
    }
    else {
      criteria[key] = undefined;
    }

    criteria.pageNumber = 0;
    this.criteriaChange.next(criteria);
  }
}
