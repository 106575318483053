<app-content-and-image-column-layout [imageSrc]="'asset/image/register-background.jpg'">
  <div id="content" class="p-4 d-flex flex-column">
    <img class="logo" src="asset/image/logo.png" alt="Logo"/>

    <h1 class="mb-6">{{ 'VIEW.PRELIMINARY.REGISTRATION.CONFIRM_REGISTRATION' | translate }}</h1>

    <form #confirmationForm="ngForm" class="row mb-6" novalidate (keyup.enter)="confirmRegistration()">
      <div class="form-group col">
        <label for="username">{{ 'VIEW.PRELIMINARY.REGISTRATION.USERNAME' | translate }}</label>
        <input
          type="text"
          id="username"
          name="username"
          #username="ngModel"
          class="form-control"
          placeholder="{{ 'VIEW.PRELIMINARY.REGISTRATION.USERNAME_PLACEHOLDER' | translate }}"
          [ngClass]="{
            'is-invalid': confirmationForm.submitted && username.invalid,
            'is-valid': confirmationForm.submitted && username.valid
          }"
          [(ngModel)]="formData.username"
          required
          disabled
        />
        <div class="invalid-feedback" *ngIf="confirmationForm.submitted && username.invalid">
          <div *ngIf="username.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
        </div>
      </div>

      <div class="form-group col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <label for="confirmationCode">{{ 'VIEW.PRELIMINARY.REGISTRATION.CONFIRMATION_CODE' | translate }}</label>
        <input
          type="text"
          id="confirmationCode"
          name="confirmationCode"
          #confirmationCode="ngModel"
          class="form-control"
          placeholder="{{ 'VIEW.PRELIMINARY.REGISTRATION.CONFIRMATION_CODE' | translate }}"
          [ngClass]="{'is-invalid': confirmationForm.submitted && confirmationCode.invalid, 'is-valid': confirmationForm.submitted && confirmationCode.valid }"
          [(ngModel)]="formData.confirmationCode"
          required
        />
        <div *ngIf="registrationType === RegistrationType.PHONE" class="form-text text-muted small">
          {{ 'VIEW.PRELIMINARY.REGISTRATION.CONFIRMATION_CODE_HELP' | translate }}
          <button type="button"
                  class="btn btn-link w-auto"
                  (click)="resendConfirmationCode()"
          >
            {{ 'VIEW.PRELIMINARY.REGISTRATION.CONFIRMATION_CODE_ACTION' | translate }}
          </button>
        </div>
        <div class="invalid-feedback" *ngIf="confirmationForm.submitted && confirmationCode.invalid">
          <div *ngIf="confirmationCode.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
        </div>
      </div>
    </form>

    <div class="d-flex justify-content-end mt-6 control-buttons">
      <button class="btn btn-light" (click)="backToLogin()">{{ 'VIEW.PRELIMINARY.LOGIN.LOGIN' | translate }}</button>
      <button class="btn btn-primary step-btn" (click)="confirmRegistration()">
        {{ 'COMMON.ACTIVATE' | translate }}
      </button>
    </div>
  </div>
</app-content-and-image-column-layout>
