import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { StateService, Transition } from '@uirouter/angular';
import { Constant } from 'app/common/Constant';
import { State } from 'app/common/State';
import { SeniorRegistrationDTO } from 'app/data/dto/registration/SeniorRegistrationDTO';
import { UserRegistrationDTO } from 'app/data/dto/user/UserRegistrationDTO';
import { ApplicationModel } from 'app/model/ApplicationModel';
import { UserRegistrationModel } from 'app/model/UserRegistrationModel';
import { StateUtil } from 'app/util/StateUtil';
import { ViewUtil } from 'app/util/ViewUtil';
import _ from 'lodash';
import { privacyPolicyUrl, termsAndConditionsUrl } from 'src/asset/files/files';

@Component({
  selector: 'app-registration-password',
  templateUrl: './RegistrationPasswordComponent.html',
  styleUrls: [ './RegistrationPasswordComponent.scss' ]
})
export class RegistrationPasswordComponent {
  @ViewChild('registrationForm')
  private registrationForm: NgForm;

  public privacyPolicyUrl: string = privacyPolicyUrl;
  public termsAndConditionsUrl: string = termsAndConditionsUrl;
  public userRegistration: SeniorRegistrationDTO;
  public formData: {
    passwordRepeat: string;
  } = {
    passwordRepeat: null
  };

  public Constant: typeof Constant = Constant;
  public currentStep: number;
  public noOfSteps: number;

  constructor(
    private transition: Transition,
    private stateService: StateService,
    private applicationModel: ApplicationModel,
    private userRegistrationModel: UserRegistrationModel,
    public viewUtil: ViewUtil,
    private stateUtil: StateUtil
  ) {
    const userRegistrationParam = transition.params().userRegistration;
    if (!userRegistrationParam) {
      this.userRegistration = new SeniorRegistrationDTO();
      this.userRegistration.user = new UserRegistrationDTO();
      //this.redirectToStart();
    }
    else {
      this.userRegistration = userRegistrationParam;
    }
    this.currentStep = this.transition.params().currentStep;
    this.noOfSteps = this.transition.params().noOfSteps;
  }

  public register(): void {
    this.registrationForm.onSubmit(null);
    if (this.registrationForm.form.valid) {
      const registrationData: SeniorRegistrationDTO = _.cloneDeep(this.userRegistration);
      if (registrationData.organizationId === -1) {
        delete registrationData.organizationId;
        delete registrationData.memberCode;
      }
      this.userRegistrationModel.registerSenior(registrationData).subscribe(
        () => {
          if (registrationData.user.email) {
            this.stateService.go(State.PRELIMINARY.REGISTRATION_COMPLETE, { username: this.userRegistration.user.email });
          }
          else {
            this.stateService.go(State.PRELIMINARY.REGISTRATION_CONFIRM, { username: this.userRegistration.user.phone });
          }
        },
        (err) => this.viewUtil.handleServerError(err)
      );
    }
  }

  public goBack(): void {
    if (this.applicationModel.currentState.state.name === State.PRELIMINARY.REGISTRATION_BRANDED.PASSWORD) {
      this.stateService.go(State.PRELIMINARY.REGISTRATION_BRANDED.USER_DATA, {
        userRegistration: this.userRegistration,
        organizationStub: this.transition.params().organizationStub
      });
    }
    else if (this.applicationModel.currentState.state.name === State.PRELIMINARY.REGISTRATION.PASSWORD) {
      this.stateService.go(State.PRELIMINARY.REGISTRATION.START, { userRegistration: this.userRegistration });
    }
  }

  public onResourceClick(source: string): void {
    this.stateUtil.goToUrl(source, true);
  }

  private redirectToStart(): void {
    if (this.applicationModel.currentState.state.name === State.PRELIMINARY.REGISTRATION_BRANDED.PASSWORD) {
      this.stateService.go(State.PRELIMINARY.REGISTRATION_BRANDED.START, {
        userRegistration: this.userRegistration,
        organizationStub: this.transition.params().organizationStub
      });
    }
    else if (this.applicationModel.currentState.state.name === State.PRELIMINARY.REGISTRATION.PASSWORD) {
      this.stateService.go(State.PRELIMINARY.REGISTRATION.START, { userRegistration: this.userRegistration });
    }
  }
}
