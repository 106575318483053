import { IdentifiableObjectDTO } from 'app/data/dto/IdentifiableObjectDTO';
import { OrganizationEssentialsDTO } from 'app/data/dto/organization/OrganizationEssentialsDTO';
import { Expose, Type } from 'class-transformer';
import { SubscriptionType } from 'app/data/enum/subscription/SubscriptionType';
import { RetailSubscriptionDTO } from 'app/data/enum/subscription/RetailSubscriptionDTO';

export class SubscriptionDTO extends IdentifiableObjectDTO<number> {
  @Type(() => OrganizationEssentialsDTO)
  public organization: OrganizationEssentialsDTO;

  @Type(() => RetailSubscriptionDTO)
  public retailSubscriptionDetails: RetailSubscriptionDTO;

  @Expose({ name: 'subscriptionType' })
  public type: SubscriptionType;

  public active: boolean;
}

