<ng-template #headingTemplate>
  <h1>{{ 'VIEW.MAIN.MY_ACTIVITY.ALL_BADGES' | translate }}</h1>

  <div class="d-flex flex-wrap gap-4 align-items-center">
    <p class="font-size-lg mb-0">
      {{ 'VIEW.MAIN.MY_ACTIVITY.YOUR_BADGES' | translate: { count: (earnedBadges?.length | replaceEmpty: '0') } }}
    </p>

    <button type="button" class="btn btn-outline-primary" (click)="onBackClick()">
      <svg-icon src="asset/image/icon/chevron-left.svg"></svg-icon>
      <span>{{ 'COMMON.BACK' | translate }}</span>
    </button>
  </div>
</ng-template>

<section class="section">
  <ng-container *ngIf="earnedBadges && availableBadges">
    <ul>
      <li *ngFor="let badge of earnedBadges">
        <app-badge-template [data]="badge" description="long" size="64px" [disabled]="false"></app-badge-template>
      </li>
      <li *ngFor="let badge of availableBadges">
        <app-badge-template [data]="badge" description="long" size="64px" [disabled]="true"></app-badge-template>
      </li>
    </ul>
  </ng-container>
</section>
