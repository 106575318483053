<div class="filter-menu">
  <div class="container-fluid px-0">
    <div class="row">
      <div class="col-12 d-flex justify-content-between align-items-center">
        <p class="mb-0 p-0">{{ 'COMMON.FILTERS' | translate }}</p>
        <svg-icon class="d-flex btn m-0 p-0" (click)="onHideFiltersClick()" src="asset/image/icon/x.svg"></svg-icon>
      </div> <!-- /.col -->
    </div> <!-- /.row -->

    <div class="row gap-4 gap-sm-0">
      <div class="col col-sm-4">
        <p class="font-weight-600 mb-2">{{ 'COMPONENT.CLASS_FILTERS.CLASS_CATEGORY' | translate }}</p>
        <div class="custom-control custom-checkbox d-flex flex-column">
          <input
            type="checkbox"
            class="custom-control-input"
            id="all"
            name="all"
            [(ngModel)]="filters.categories.allChecked"
            (change)="onAllCategoryChange()"
          />
          <label class="custom-control-label" for="all">{{ 'COMPONENT.CLASS_FILTERS.ALL' | translate }}</label>
        </div>
        <ng-container *ngFor="let category of categories">
          <div class="custom-control custom-checkbox d-flex flex-column">
            <input
              type="checkbox"
              class="custom-control-input"
              [id]="category.value | lowercase"
              [name]="category.value | lowercase"
              [(ngModel)]="filters.categories.checked[category.value]"
              (change)="onCategoryCheckboxChange()"
            />
            <label class="custom-control-label" [for]="category.value | lowercase">
              {{ category.label | translate }}
            </label>
          </div>
        </ng-container>
      </div> <!-- /.col -->

      <div class="col col-sm-4">
        <p class="font-weight-600 mb-2">{{ 'COMPONENT.CLASS_FILTERS.INTENSITY' | translate }}</p>
        <div class="custom-control custom-checkbox d-flex flex-column">
          <input
            type="checkbox"
            class="custom-control-input"
            id="allIntensity"
            name="allIntensity"
            [(ngModel)]="filters.intensities.allChecked"
            (change)="onAllIntensityChange()"
          />
          <label class="custom-control-label" for="allIntensity">{{ 'COMPONENT.CLASS_FILTERS.ALL' | translate }}</label>
        </div>
        <ng-container *ngFor="let intensity of intensities">
          <div class="custom-control custom-checkbox d-flex flex-column">
            <input
              type="checkbox"
              class="custom-control-input"
              [id]="intensity.value | lowercase"
              [name]="intensity.value | lowercase"
              [(ngModel)]="filters.intensities.checked[intensity.value]"
              (change)="onIntensityCheckboxChange()"
            />
            <label class="custom-control-label" [for]="intensity.value | lowercase">
              {{ intensity.label | translate }}
            </label>
          </div>
        </ng-container>
      </div> <!-- /.col -->

      <div class="col col-sm-4">
        <p class="font-weight-600 mb-2">{{ 'COMPONENT.CLASS_FILTERS.LANGUAGE' | translate }}</p>
        <div class="custom-control custom-checkbox d-flex flex-column">
          <input
            type="checkbox"
            class="custom-control-input"
            id="language"
            name="language"
            [(ngModel)]="filters.languages.allChecked"
            (change)="onAllLanguageChange()"
          />
          <label class="custom-control-label" for="language">{{ 'COMPONENT.CLASS_FILTERS.ALL' | translate }}</label>
        </div>
        <ng-container *ngFor="let language of languages">
          <div class="custom-control custom-checkbox d-flex flex-column">
            <input
              type="checkbox"
              class="custom-control-input"
              [id]="language.value"
              [name]="language.value"
              [(ngModel)]="this.filters.languages.checked[language.value]"
              (change)="onLanguageCheckboxChange()"
            />
            <label class="custom-control-label" [for]="language.value">{{ language.label | translate }}</label>
          </div>
        </ng-container>
      </div> <!-- /.col -->
    </div> <!-- /.row -->

    <div class="row mt-4">
      <div class="col-12 col-sm-6" *ngIf="!hideDaysOfWeek">
        <p class="font-weight-600 mb-2">{{ 'COMPONENT.CLASS_FILTERS.DAY_OF_THE_WEEK' | translate }}</p>
        <div class="mr-2 mb-0">
          <ng-select
            id="days-ahead"
            name="days-ahead"
            [placeholder]="'COMPONENT.CLASS_FILTERS.SELECT_DAY' | translate"
            [searchable]="true"
            [style.--icon]="'url(/asset/image/icon/calendar-due.svg)'"
            [multiple]="true"
            [(ngModel)]="filters.dayOfTheWeek"
          >
            <ng-option *ngFor="let day of days" [value]="day.value">{{ day.label | translate }}</ng-option>
          </ng-select>
        </div>
      </div> <!-- /.col -->

      <div *ngIf="!isItCoach" class="col-12 col-sm-6">
        <p class="font-weight-600 mb-2">{{ 'COMPONENT.CLASS_FILTERS.COACH' | translate }}</p>
        <div class="mb-0">
          <ng-select
            id="coachSearch"
            name="coachSearch"
            [placeholder]="'COMMON.SEARCH' | translate"
            [style.--icon]="'url(/asset/image/icon/search.svg)'"
            [searchable]="true"
            [multiple]="true"
            [(ngModel)]="filters.coaches.selectedCoaches"
          >
            <ng-option *ngFor="let coach of coaches" [value]="coach.value">{{ coach.label | translate }}</ng-option>
          </ng-select>
        </div>
      </div> <!-- /.col -->
    </div> <!-- /.row -->

    <div class="d-flex justify-content-end mt-4 gap-2">
      <button class="btn btn-outline-primary"
              (click)="onClearFiltersClick()">{{ 'COMMON.CLEAR_FILTERS' | translate }}
      </button>
      <button class="btn btn-primary" (click)="onShowFiltersClick()">{{ 'COMMON.SHOW' | translate }}</button>
    </div>
  </div> <!-- /.container-fluid -->
</div> <!-- /.filter-menu -->
