<ng-container *ngIf="adminMode">
  <div
    *ngIf="selectedClasses?.length"
    id="selected-classes-bar"
    class="p-2 d-flex justify-content-between align-items-center mt-4"
  >
    <span class="ml-4"> {{ selectedClasses.length }} {{ classesText }} {{ 'COMMON.SELECTED' | translate }} </span>
    <div class="d-flex">
      <div>
        <button class="btn btn-primary" (click)="onDeleteClick()">
          <span class="d-flex flex-row justify-content-center align-items-center">
            <svg-icon class="d-flex mr-2" src="asset/image/icon/trash.svg"></svg-icon>
            <span>{{ 'COMMON.DELETE' | translate }}</span>
          </span>
        </button>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="seniorMode">
  <div
    *ngIf="selectedClasses?.length"
    id="selected-classes-bar"
    class="p-2 d-flex justify-content-between align-items-center mt-4"
  >
    <span class="ml-4"> {{ selectedClasses.length }} {{ classesText }} {{ 'COMMON.SELECTED' | translate }} </span>
    <div class="d-flex gap-2">
      <button *ngIf="accessControlModel.isFullAccess$ | async"
              (click)="onFavoriteClick()" type="button" class="btn btn-outline-primary">
          <span class="d-flex flex-row justify-content-center align-items-center">
            <svg-icon class="d-flex mr-2" src="asset/image/icon/heart.svg"></svg-icon>
            <span>{{ 'COMMON.ADD_TO_FAVORITES' |translate }}</span>
          </span>
      </button>

      <button class="btn btn-primary"
              (click)="onRegisterClick()"
              *appCanPerformAction="liveClassModel.logic.isBulkRegistrationAllowed;data:selectedClasses;retry:0">
          <span class="d-flex flex-row justify-content-center align-items-center">
            <svg-icon class="d-flex mr-2" src="asset/image/icon/circle-chevron-right.svg"></svg-icon>
            <span>{{ 'COMMON.REGISTER' | translate }}</span>
          </span>
      </button>
    </div>
  </div>
</ng-container>
