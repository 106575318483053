export enum VideoFitnessSubcategory {
  CARDIO = 'CARDIO',
  STRENGTH = 'STRENGTH',
  BALANCE = 'BALANCE',
  STRETCH = 'STRETCH'
}

export enum VideoMindAndBodySubcategory {
  YOGA = 'YOGA',
  MEDITATION = 'MEDITATION',
  PILATES = 'PILATES',
  TAI_CHI = 'TAI_CHI'
}

export enum VideoEducationSubcategory {
  PHYSICAL_THERAPY_EDUCATION = 'PHYSICAL_THERAPY_EDUCATION',
  NUTRITION_EDUCATION = 'NUTRITION_EDUCATION',
  CHRONIC_DISEASE_EDUCATION = 'CHRONIC_DISEASE_EDUCATION',
  SENIOR_HEALTH_EDUCATION = 'SENIOR_HEALTH_EDUCATION',
  COGNITIVE_HEALTH_EDUCATION = 'COGNITIVE_HEALTH_EDUCATION',
  BITE_SIZED_EDUCATION = 'BITE_SIZED_EDUCATION'
}

export enum VideoHobbiesSubcategory {
  ARTS_AND_CRAFTS = 'ARTS_AND_CRAFTS',
  COOKING = 'COOKING'
}

export enum VideoLiveRecordingsSubcategory {
  LIVE_CARDIO = 'LIVE_CARDIO',
  LIVE_STRENGTH = 'LIVE_STRENGTH',
  LIVE_BALANCE = 'LIVE_BALANCE',
  LIVE_STRETCH = 'LIVE_STRETCH',
  LIVE_MIND_AND_BODY = 'LIVE_MIND_AND_BODY',
  LIVE_EDUCATION = 'LIVE_EDUCATION',
  LIVE_COOKING = 'LIVE_COOKING',
  LIVE_ARTS_AND_CRAFTS = 'LIVE_ARTS_AND_CRAFTS',
}

export const VideoSubcategories = { ...VideoFitnessSubcategory, ...VideoMindAndBodySubcategory, ...VideoEducationSubcategory, ...VideoHobbiesSubcategory, ...VideoLiveRecordingsSubcategory };
export type VideoSubcategory =
  VideoFitnessSubcategory
  | VideoMindAndBodySubcategory
  | VideoEducationSubcategory
  | VideoHobbiesSubcategory
  | VideoLiveRecordingsSubcategory;
