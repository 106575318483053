<form #ngForm="ngForm">
  <div class="d-flex gap-4">
    <div class="form-group mb-0">
      <label for="startDate">{{ dateLabel | translate }}</label>
      <div class="mb-0 iconbox">
        <svg-icon class="search-icon" src="asset/image/icon/search.svg"></svg-icon>
        <input
          id="startDate"
          name="date"
          type="text"
          class="form-control date-icon"
          placeholder="{{ 'COMPONENT.DATE_TIME_PICKER.DATE' | translate }}"
          #date="ngModel"
          [disabled]="disabled"
          [minDate]="minDate"
          [(ngModel)]="dateTime"
          [ngClass]="{'is-invalid': submitted && isInvalid(), 'is-valid': submitted && !isInvalid() }"
          [bsConfig]="{ containerClass: 'theme-custom' }"
          (ngModelChange)="onDateChange()"
          bsDatepicker
        />
      </div>
    </div>

    <div class="form-group mb-0">
      <label for="time">{{ 'COMPONENT.DATE_TIME_PICKER.TIME' | translate }}</label>
      <timepicker
        id="time"
        name="time"
        #time="ngModel"
        [(ngModel)]="dateTime"
        [ngClass]="{'is-invalid': submitted && isInvalid(), 'is-valid': submitted && !isInvalid() }"
        [minuteStep]="1"
        [disabled]="disabled"
        [showSpinners]="false"
        [showMeridian]="true"
        [mousewheel]="true"
        (ngModelChange)="onTimeChange(time)">
      </timepicker>
    </div>
  </div>
</form>