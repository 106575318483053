<ng-template #headingTemplate>
  <h1>{{ 'VIEW.MAIN.COACHES.COACHES' | translate }}</h1>

  <button class="btn btn-primary w-100 w-sm-auto" (click)="onAddNewCoachClick()">
    <svg-icon src="asset/image/icon/plus.svg"></svg-icon>
    <span>{{ 'VIEW.MAIN.COACHES.ADD_NEW_COACH' |translate }}</span>
  </button>
</ng-template>

<section class="section">
  <ng-container *ngIf="page$ | async as page">
    <app-data-grid
      [data]="page.content"
      [criteria]="criteria"
      [context]="{ host: this}"
      [showFilters]="true"
      [showPagination]="true"
      [itemTotal]="page.totalElements"
      [showItemsPerPage]="true"
      [showTotalItems]="false"
      [selectionEnabled]="true"
      [multiSelectionEnabled]="true"
      [useCheckboxSelection]="true"
      (criteriaChanged)="onCriteriaChanged($event)"
      (gridAction)="onGridAction($event)"
      (itemsSelected)="onSelectionChange($event)"
      [columns]="$any([
        { headerName: ('VIEW.MAIN.COACHES.FIRST_NAME' | translate), field: 'user.firstName', tooltipField: 'user.firstName', maxWidth: 150, checkboxSelection: checkboxSelectionFunction },
        { headerName: ('VIEW.MAIN.COACHES.LAST_NAME' | translate), field: 'user.lastName', tooltipField: 'user.lastName', maxWidth: 150, cellClass: 'text-right text-lg-left' },
        { headerName: ('VIEW.MAIN.COACHES.TYPE_OF_CLASSES' | translate), field: 'classCategories', tooltipField: 'classCategories', maxWidth: 580, valueFormatter: enumCategoryFormatter },
        { headerName: ('VIEW.MAIN.COACHES.PHONE' | translate), field: 'user.phone', tooltipField: 'user.phone', maxWidth: 150, cellClass: 'text-right text-lg-left' },
        { headerName: ('VIEW.MAIN.COACHES.STATUS' | translate), field: 'enabled', maxWidth: 100, valueFormatter: enumStatusFormatter },
        { headerName: ('VIEW.MAIN.COACHES.EMAIL' | translate), field: 'user.email', tooltipField: 'user.email', cellClass: 'text-right text-lg-left' },
        { headerName: ('VIEW.MAIN.COACHES.RATING' | translate), field: 'rating', minWidth: 140, cellRendererFramework: StarRatingCellRendererComponent },
        { headerName: '', headerClass: 'header-right', minWidth: 100, maxWidth: 150, sortable: false, cellClass: 'cell-fullwidth', suppressKeyboardEvent: CoachesListCellRendererComponent.suppressKeyboardEvent, cellRendererFramework: CoachesListCellRendererComponent },
      ])"
    >
      <app-data-grid-filters>
        <div class="form-row space-y-3 space-y-md-0 mb-4 mb-lg-0">
          <div class="col-12 col-md-6 col-lg-auto">
            <ng-select
              id="sortBy"
              name="sortBy"
              [placeholder]="'COMMON.SORT_BY' | translate"
              [searchable]="false"
              [style.--icon]="'url(/asset/image/icon/arrows-sort.svg)'"
              [(ngModel)]="sortValue"
              (change)="onSortOptionChange($event)"
            >
              <ng-option *ngFor="let sortBy of sortByOptions" [value]="sortBy.value">
                {{ sortBy.label | translate }}
              </ng-option>
            </ng-select>
          </div> <!-- /.col -->

          <div class="col-12 col-md-6 col-lg-auto">
            <ng-select
              id="filterBy"
              name="filterBy"
              [placeholder]="'COMMON.FILTER_BY' | translate"
              [searchable]="false"
              [style.--icon]="'url(/asset/image/icon/filter.svg)'"
              [(ngModel)]="filterValue"
              (change)="onFilterOptionChange($event)"
            >
              <ng-option *ngFor="let filterBy of filterBy" [value]="filterBy.value">
                {{ filterBy.label | translate }}
              </ng-option>
            </ng-select>
          </div> <!-- /.col -->
        </div> <!-- /.form-row -->
      </app-data-grid-filters>
    </app-data-grid>
  </ng-container>
</section>

<app-coaches-selected-action-bar [selectedItems]="selectedItems" (deleteClicked)="onDeleteClick()">
</app-coaches-selected-action-bar>
