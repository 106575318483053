import { Type } from 'class-transformer';
import { CoachCertificationDTO } from './CoachCertificationDTO';
import { CoachEducationDTO } from './CoachEducationDTO';
import { CoachHobbyDTO } from './CoachHobbyDTO';
import { BaseUserDTO } from 'app/data/dto/user/BaseUserDTO';

export class CoachSeniorDetailsPageDTO {
  public bio: string;

  @Type(() => CoachCertificationDTO)
  public coachCertifications?: CoachCertificationDTO[];

  @Type(() => CoachEducationDTO)
  public coachEducations?: CoachEducationDTO[];

  @Type(() => CoachHobbyDTO)
  public coachHobbies?: CoachHobbyDTO[];

  @Type(() => BaseUserDTO)
  public user: BaseUserDTO;
}