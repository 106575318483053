<ng-template #headingTemplate>
  <h1>{{ 'VIEW.MAIN.FRIENDS.FRIENDS' | translate }}</h1>
</ng-template>

<section class="section">
  <div class="d-flex justify-content-between">
    <app-route-tabs [routeTabs]="tabs"></app-route-tabs>
    <button type="button"
            class="btn btn-primary"
            (click)="addFriend()">{{ 'VIEW.MAIN.FRIENDS.ACTION.ADD' | translate }}
    </button>
  </div>

  <div class="d-flex flex-column gap-5">
    <div>
      <h3 class="mb-4">{{ 'VIEW.MAIN.FRIENDS.SENT_INVITATIONS.TITLE' | translate }}</h3>
      <app-friends-sent-invitations></app-friends-sent-invitations>
    </div>

    <div>
      <h3 class="mb-4">{{ 'VIEW.MAIN.FRIENDS.PENDING_INVITATIONS.TITLE' | translate }}</h3>
      <app-friends-pending-invitations></app-friends-pending-invitations>
    </div>
  </div>
</section>
