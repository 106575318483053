<div class="d-flex flex-column gap-6 h-100">
  <div class="d-flex flex-column gap-6 gap-xl-4">
    <ng-container *ngFor="let group of happeningNow">
      <h3>{{ group.date| date : "EEEE, MMMM d" }}</h3>
      <div
        *ngFor="let liveClass of group.items; let last = last"
        class="d-flex flex-wrap justify-content-end gap-2 gap-xl-0"
      >
        <div
          *ngIf="liveClass.isHappeningNow"
          class="d-flex flex-wrap justify-content-center align-self-center col-12 col-xl-1 event-now"
        >
          <svg-icon src="asset/image/icon/alert.svg"></svg-icon>
          <span class="ml-1">{{ 'VIEW.MAIN.DASHBOARD.NOW' | translate }}</span>
        </div>
        <div *ngIf="!liveClass.isHappeningNow" class="text-center d-flex align-items-center col-12 col-md-12 col-xl-1">
          {{ liveClass.startDate | date : 'h:mm a' }}
        </div>

        <div class="d-flex align-items-center col-12 col-xl-3 gap-2" [ngClass]="{ 'col-xl-5' : guestMode }">
          <app-avatar [src]="liveClass.thumbnail?.defaultAsset?.url" size="40px" [circle]="true"></app-avatar>

          <div class="d-flex flex-column">
            <div class="text-lg-semibold">{{ liveClass.title }}</div>
            <div>{{ liveClass.coaches[0].user | fullName }}</div>
          </div>
        </div>
        <div
          [style.visibility]="liveClass.intensity === intensity.NOT_APPLICABLE ? 'hidden' : 'visible'"
          class="d-flex flex-column col col-xl-2"
        >
          <div class="text-sm-light">{{ 'VIEW.MAIN.DASHBOARD.INTENSITY' | translate }}</div>
          <svg-icon
            *ngIf="liveClass.intensity === intensity.BEGINNER"
            src="asset/image/icon/level-1.svg"
          ></svg-icon>
          <svg-icon
            *ngIf="liveClass.intensity === intensity.INTERMEDIATE"
            src="asset/image/icon/level-2.svg"
          ></svg-icon>
          <svg-icon
            *ngIf="liveClass.intensity === intensity.ADVANCED"
            src="asset/image/icon/level-3.svg"
          ></svg-icon>
        </div>
        <div class="d-flex flex-column col col-xl-2">
          <div class="text-sm-light">{{ 'VIEW.MAIN.DASHBOARD.CATEGORY' | translate }}</div>
          <div class="text-sm">{{ 'ENUM.CATEGORY.' + liveClass.category | translate }}</div>
        </div>
        <div class="d-flex flex-column col col-xl-2">
          <div class="text-sm-light">{{ 'VIEW.MAIN.DASHBOARD.DURATION' | translate }}</div>
          <div class="text-sm">{{ liveClass.duration }} {{ 'VIEW.MAIN.DASHBOARD.MINUTES' | translate }}</div>
        </div>

        <div
          class="p-0 col-12 col-md-5 col-md-12 col-xl-2 d-flex flex-wrap justify-content-start justify-content-xl-center gap-2"
          *ngIf="shouldShowRegisterButton(liveClass)"
        >
          <button
            [disabled]="shouldDisabledRegisterButton(liveClass)"
            class="align-self-center btn btn-outline-primary activity-btn p-0"
            (click)="onRegisterClick(liveClass)"
          >
            {{ 'VIEW.MAIN.DASHBOARD.SIGN_UP' | translate }}
          </button>
          <button
            [style.visibility]="'hidden'"
            class="align-self-center btn btn-outline-danger activity-btn p-0"
            (click)="onDropClick(liveClass)"
          >
            {{ 'VIEW.MAIN.DASHBOARD.DROP' | translate }}
          </button>
        </div>
        <div
          class="p-0 col-12 col-md-5 col-md-12 col-xl-2 d-flex flex-wrap justify-content-start justify-content-xl-center gap-2"
          *ngIf="shouldShowJoinButton(liveClass)"
        >
          <button
            [disabled]="shouldDisabledJoinButton(liveClass)"
            class="align-self-center btn btn-primary activity-btn p-0"
            (click)="onJoinClick(liveClass)"
          >
            {{ 'VIEW.MAIN.DASHBOARD.JOIN' | translate }}
            <!-- class:{{class.isHappeningToday}} -->
          </button>
          <button
            [style.visibility]="!shouldShowDropButton(liveClass) ? 'hidden' : 'visible'"
            class="align-self-center btn btn-outline-danger activity-btn p-0"
            (click)="onDropClick(liveClass)"
          >
            {{ 'VIEW.MAIN.DASHBOARD.DROP' | translate }}
          </button>
        </div>

        <ng-container *ngIf="!last">
          <div class="spacer-light-grey mt-1"></div>
        </ng-container>
      </div>
    </ng-container>
    <div class="d-flex gap-4 flex-wrap justify-content-center w-100 redirect-buttons py-2">
      <button class="btn btn-outline-primary redirect-btn" (click)="goToLiveClasses()">
        <svg-icon src="asset/image/icon/movie.svg"></svg-icon>
        {{ 'VIEW.MAIN.DASHBOARD.LIVE_CLASSES' | translate }}
      </button>
      <button class="btn btn-outline-primary redirect-btn" (click)="goToMySchedule()">
        <svg-icon src="asset/image/icon/calendar-due.svg"></svg-icon>
        {{ 'VIEW.MAIN.DASHBOARD.MY_SCHEDULE' | translate }}
      </button>
    </div>
  </div>
</div>
