import { TranslateCompiler, TranslateLoader, TranslateModuleConfig, TranslateParser } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { TranslateMultipleLineParser } from 'app/util/other/TranslateMultipleLineParser';
import { ApplicationConfig } from 'app/config/ApplicationConfig';
import { from, Observable } from 'rxjs';

// newer, webpack approach (compiled-in direct import (webpackMode: 'eager') or lazy import (webpackMode: 'lazy') + cache busting during production build by webpack)
const WebpackTranslateLoaderFactory: () => TranslateLoader = () => {
  class WebpackTranslateLoader implements TranslateLoader {
    public getTranslation(lang: string): Observable<any> {
      return from(import(
        /* webpackChunkName: "[request]" */
        /* webpackMode: "eager" */
        /* webpackPrefetch: true */
        /* webpackPreload: true */
        `../../asset/locale/${ lang }.json`
        ));
    }
  }

  return new WebpackTranslateLoader();
};

// older, http approach (loading via http request + manual cache busting by application version string)
// if you want to use it, you have to edit the architect build "assets" copy section in angular.json (remove "locale/**/*" from ignore property)
const HttpTranslateLoaderFactory: (http: HttpClient) => TranslateLoader = (http: HttpClient) => {
  return new TranslateHttpLoader(http, 'asset/locale/', `.json?version=${ ApplicationConfig.version.substring(0, ApplicationConfig.version.lastIndexOf(' ')) }`);
};

export const TranslateConfig: TranslateModuleConfig = {
  loader: {
    provide: TranslateLoader,
    useFactory: WebpackTranslateLoaderFactory,
    deps: [ HttpClient ]
  },
  compiler: {
    provide: TranslateCompiler,
    useClass: TranslateMessageFormatCompiler
  },
  parser: {
    provide: TranslateParser,
    useFactory: () => new TranslateMultipleLineParser()
  }
};
