import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable({ providedIn: 'root' })
@Pipe({
  name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {

  transform(value: File | Blob): string {
    const size: string = (value.size / 1024 / 1024).toFixed(2); //MB
    return `${ size } MB`;
  }

}
