<app-content-and-image-column-layout [imageSrc]="'asset/image/login_background.png'">
  <form
    id="content"
    class="d-flex flex-column p-4"
    #resetPasswordForm="ngForm"
    novalidate
    (keyup.enter)="resetPassword()"
  >
    <div class="d-flex justify-content-center">
      <img class="logo" src="asset/image/logo.png" alt="Logo"/>
    </div>
    <div class="d-flex flex-column align-items-start">
      <h1 class="mb-0">{{ 'VIEW.PRELIMINARY.RESET_PASSWORD.RESET_PASSWORD' | translate }}</h1>
      <span>{{ 'VIEW.PRELIMINARY.RESET_PASSWORD.RESET_MESSAGE' | translate }}</span>
    </div>
    <div class="form-group my-6">
      <label for="username">{{ 'VIEW.PRELIMINARY.RESET_PASSWORD.USERNAME'| translate }}</label>
      <input
        type="text"
        id="username"
        name="username"
        #username="ngModel"
        class="form-control"
        placeholder="{{ 'VIEW.PRELIMINARY.RESET_PASSWORD.USERNAME_PLACEHOLDER' | translate }}"
        [ngClass]="{
          'is-invalid': resetPasswordForm.submitted && username.invalid,
          'is-valid': resetPasswordForm.submitted && username.valid
        }"
        [(ngModel)]="formData.username"
        required
        [appMaxLength]="Constant.TEXT_MAX_LENGTH_128"
      />
      <div class="invalid-feedback" *ngIf="resetPasswordForm.submitted && username.invalid">
        <div *ngIf="username.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
        <div *ngIf="username.errors['maxLength']">
          {{ 'VALIDATION.CHAR_MAX' | translate: { max: Constant.TEXT_MAX_LENGTH_128 } }}
        </div>
      </div>
    </div>
    <div class="d-flex flex-column flex-sm-row justify-content-end">
      <button type="button" class="btn btn-light" (click)="backToLogin()">
        <svg-icon class="mr-2" src="asset/image/icon/chevron-left.svg"></svg-icon>
        <span>{{ 'COMMON.BACK' | translate }}</span>
      </button>
      <button type="button" class="btn btn-primary" (click)="resetPassword()">
        {{ 'VIEW.PRELIMINARY.RESET_PASSWORD.RESET_PASSWORD_START_ACTION' | translate }}
      </button>
    </div>
  </form>
</app-content-and-image-column-layout>
