import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, SuppressKeyboardEventParams } from 'ag-grid-community';
import { DataGridComponent } from 'app/component/ui/dataGrid/DataGridComponent';
import { DataGridContext } from 'app/component/ui/dataGrid/DataGridContext';
import { ResponsiveDataGridComponent } from 'app/component/ui/responsiveDataGrid/ResponsiveDataGridComponent';
import { ScheduledLiveClassSeniorDetailsPageDTO } from 'app/data/dto/scheduledLiveClass/senior/ScheduledLiveClassSeniorDetailsPageDTO';
import { UserDTO } from 'app/data/dto/user/UserDTO';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { CoachDetailsComponent } from '../coachDetailsModal/CoachDetailsComponent';
import { UserListActionsCellRendererComponent } from '../../../user/components/cellRenderer/UserListActionsCellRendererComponent';

@Component({
  selector: 'app-live-classes-list-coach-cell-renderer',
  templateUrl: './LiveClassesListCoachCellRendererComponent.html',
  styleUrls: [ './LiveClassesListCoachCellRendererComponent.scss' ]
})
export class LiveClassesListCoachCellRendererComponent implements ICellRendererAngularComp {
  public params: ICellRendererParams;
  public value: any;
  public context: DataGridContext;

  public currentUser: UserDTO;
  public coach: UserDTO;
  public userClassDetails: ScheduledLiveClassSeniorDetailsPageDTO;

  constructor(public modalService: BsModalService) {
  }

  public agInit(params: ICellRendererParams): void {
    this.params = params;
    this.value = params.value;
    this.context = params.context;

    this.currentUser = this.context.additionalData['currentUser'];

    if (this.currentUser) {
      this.userClassDetails = params.data as ScheduledLiveClassSeniorDetailsPageDTO;
    }
  }

  public refresh(params: ICellRendererParams): boolean {
    return false;
  }

  public edit(user: UserDTO): void {
    this.context.dataGridAction(UserListActionsCellRendererComponent.ACTION_EDIT, [ user ]);
  }

  public preventGridSelection(event: Event): void {
    if (this.context.dataGridHost instanceof DataGridComponent) {
      const previousValue: any = this.params.node['gridOptionsWrapper'].gridOptions.suppressRowClickSelection;
      this.params.node['gridOptionsWrapper'].gridOptions.suppressRowClickSelection = true;

      setTimeout(() => {
        this.params.node['gridOptionsWrapper'].gridOptions.suppressRowClickSelection = previousValue;
      });
    }
    else if (this.context.dataGridHost instanceof ResponsiveDataGridComponent) {
      event.stopImmediatePropagation();
      event.preventDefault();
    }
  }

  public onCoachClick(): void {
    const modal: BsModalRef = this.modalService.show(CoachDetailsComponent, {
      class: 'modal-CoachDetails modal-dialog-centered',
      initialState: {
        coaches: this.userClassDetails.coaches
      }
    });
  }

  public static suppressKeyboardEvent(_params: SuppressKeyboardEventParams): boolean {
    return true;
  }
}
