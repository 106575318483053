import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationConfig } from 'app/config/ApplicationConfig';
import { CoachProfileCreateAdminRequestDTO } from 'app/data/dto/coach/CoachProfileCreateAdminRequestDTO';
import { CoachProfileResponseAdminDTO } from 'app/data/dto/coach/CoachProfileResponseAdminDTO';
import { CoachProfileResponseDTO } from 'app/data/dto/coach/CoachProfileResponseDTO';
import { CoachProfileUpdateAdminRequestDTO } from 'app/data/dto/coach/CoachProfileUpdateAdminRequestDTO';
import { CoachProfileUpdateRequestDTO } from 'app/data/dto/coach/CoachProfileUpdateRequestDTO';
import { MyScheduleCriteriaDTO } from 'app/data/dto/schedule/MyScheduleCriteriaDTO';
import { ScheduledLiveClassDetailsResponseDTO } from 'app/data/dto/scheduledLiveClass/admin/ScheduledLiveClassDetailsResponseDTO';
import { CoachListDTO } from 'app/data/dto/scheduledLiveClass/coach/CoachListDTO';
import { ObjectUtil } from 'app/util/ObjectUtil';
import { CustomHttpParameterEncoder } from 'app/util/other/CustomHttpParameterEncoder';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CoachRecentRatingsResponseDTO } from 'app/data/dto/coach/CoachRecentRatingsResponseDTO';

@Injectable({
  providedIn: 'root'
})
export class CoachService {
  constructor(private http: HttpClient) {
  }

  public getActiveCoachesList(): Observable<CoachListDTO[]> {
    return this.http.get(`${ ApplicationConfig.apiUrl }/coaches/list`).pipe(
      map((response: CoachListDTO[]) => {
        return response.map((item) => ObjectUtil.plainToClass(CoachListDTO, item));
      })
    );
  }

  public getMeSchedule(criteria: MyScheduleCriteriaDTO): Observable<ScheduledLiveClassDetailsResponseDTO[]> {
    const params: HttpParams = new HttpParams({
      encoder: new CustomHttpParameterEncoder(),
      fromObject: ObjectUtil.valueAsString(ObjectUtil.classToPlain(criteria, true, true, true, true))
    });
    return this.http.get(`${ ApplicationConfig.apiUrl }/coaches/me/schedule`, { params: params }).pipe(
      map((response: any[]) => {
        return response.map((item) => ObjectUtil.plainToClass(ScheduledLiveClassDetailsResponseDTO, item));
      })
    );
  }

  public createCoach(coach: CoachProfileCreateAdminRequestDTO): Observable<CoachProfileResponseAdminDTO> {
    return this.http.post(`${ ApplicationConfig.apiUrl }/coaches/admins/profile`, ObjectUtil.classToPlain(coach)).pipe(
      map((response: CoachProfileResponseAdminDTO) => {
        return ObjectUtil.plainToClass(CoachProfileResponseAdminDTO, response);
      })
    );
  }

  public getCoach(id: number): Observable<CoachProfileResponseAdminDTO> {
    return this.http.get(`${ ApplicationConfig.apiUrl }/coaches/${ id }/admins/profile`).pipe(
      map((response: CoachProfileResponseAdminDTO) => {
        return ObjectUtil.plainToClass(CoachProfileResponseAdminDTO, response);
      })
    );
  }

  public updateCoach(id: number, coach: CoachProfileUpdateAdminRequestDTO): Observable<CoachProfileResponseAdminDTO> {
    return this.http
      .put(`${ ApplicationConfig.apiUrl }/coaches/${ id }/admins/profile`, ObjectUtil.classToPlain(coach))
      .pipe(
        map((response) => {
          const updatedCoach: CoachProfileResponseAdminDTO = ObjectUtil.plainToClass(
            CoachProfileResponseAdminDTO,
            response
          );
          return updatedCoach;
        })
      );
  }

  deleteCoach(id: number[]): Observable<void> {
    return this.http.delete(`${ ApplicationConfig.apiUrl }/coaches/${ id }`).pipe(
      map((response) => {
        return;
      })
    );
  }

  public getCurrentUserProfile(): Observable<CoachProfileResponseDTO> {
    return this.http.get(`${ ApplicationConfig.apiUrl }/coaches/me/profile`).pipe(
      map((response: any) => {
        return ObjectUtil.plainToClass(CoachProfileResponseDTO, response);
      })
    );
  }

  public updateCurrentUserProfile(user: CoachProfileUpdateRequestDTO): Observable<CoachProfileResponseDTO> {
    return this.http.put(`${ ApplicationConfig.apiUrl }/coaches/me/profile`, ObjectUtil.classToPlain(user)).pipe(
      map((response: any) => {
        return ObjectUtil.plainToClass(CoachProfileResponseDTO, response);
      })
    );
  }

  public getCurrentCoachRecentRatings(count: number): Observable<CoachRecentRatingsResponseDTO> {
    return this.http.get(`${ ApplicationConfig.apiUrl }/coaches/me/ratings/recent/${ count }`).pipe(
      map((response: any) => {
        return ObjectUtil.plainToClass(CoachRecentRatingsResponseDTO, response);
      })
    );
  }
}
