import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import { ChangeDetectorRef, Directive, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

export const mustBeEmptyValidator = (value: boolean): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors => {
    if (control?.value === undefined || control?.value === null) {
      return null;
    }
    else {
      return value ? { mustBeEmpty: true } : null;
    }
  };
};

@Directive({
  selector: '[appMustBeEmpty][formControlName],[appMustBeEmpty][formControl],[appMustBeEmpty][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: MustBeEmptyValidator,
      multi: true
    }
  ]
})
export class MustBeEmptyValidator implements Validator, OnInit, OnChanges {

  @Input()
  public appMustBeEmpty: boolean;

  private validator: ValidatorFn;

  private onChange: () => void;

  constructor(private changeDetector: ChangeDetectorRef) {
  }

  public ngOnInit(): void {
    this.validator = mustBeEmptyValidator(this.appMustBeEmpty);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    for (const key in changes) {
      if (key === 'appMustBeEmpty') {
        this.validator = mustBeEmptyValidator(changes[key].currentValue);
        if (this.onChange) {
          this.onChange();
          this.changeDetector.detectChanges();  //  there are some issues in angular with detecting valid/invalid state and binding to ngClass, for example. This fixes it.
        }
      }
    }
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    return this.appMustBeEmpty !== null ? this.validator(control) : null;
  }

  public registerOnValidatorChange(fn: () => void): void {
    this.onChange = fn;
  }
}
