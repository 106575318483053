<form #form="ngForm">
  <div class="form-group">
    <label for="dynamicInput">{{ label }}</label>
    <div
      *ngFor="let item of itemsCopy; let i = index"
      class="d-flex flex-column flex-md-row align-items-md-center mb-4"
    >
      <label *ngIf="items?.length > 0 && i !== 0" for="dynamicInput_{{i}}" class="mr-4"
      >{{ 'COMMON.MORE' | translate }}</label
      >
      <div class="flex-grow-1">
        <input
          type="text"
          id="dynamicInput_{{i}}"
          name="dynamicInput_{{i}}"
          class="form-control"
          placeholder="{{ placeholder }}"
          [(ngModel)]="item[keyName]"
          [appMaxLength]="appMaxLength"
          (ngModelChange)="onInputChange()"
          [ngClass]="{'is-invalid': (form.submitted || formSubmitted) && form.controls['dynamicInput_' + i]?.invalid, 'is-valid': form.submitted && form.controls['dynamicInput_' + i]?.valid }"
          [disabled]="disabled"
          [required]="isRequired"
        />

        <div class="invalid-feedback" *ngIf="(form.submitted || formSubmitted)">
          <div *ngIf="form.controls['dynamicInput_' + i]?.errors?.maxLength?.actualLength > appMaxLength">
            {{ 'VALIDATION.CHAR_MAX' | translate: { max: appMaxLength } }}
          </div>
          <div *ngIf="form.controls['dynamicInput_' + i]?.errors?.required">
            {{ 'VALIDATION.REQUIRED' | translate }}
          </div>
        </div>
      </div>
      <button
        *ngIf="items?.length > 0 && i !== 0"
        type="button"
        class="btn btn-warning m-0 ml-md-4 mt-2 mt-md-0"
        (click)="removeItem(i)"
        [disabled]="disabled"
      >
        {{ "COMMON.REMOVE" | translate }}
      </button>
    </div>
    <button type="button" class="btn btn-outline-primary mr-2" (click)="addNewItem()" [disabled]="disabled">
      <span class="d-flex flex-row justify-content-center align-items-center">
        <svg-icon class="d-flex" src="asset/image/icon/plus.svg"></svg-icon>
        <span>{{ addButtonText }}</span>
      </span>
    </button>
  </div>
</form>
