import { PageCriteriaDTO } from 'app/data/dto/PageCriteriaDTO';
import { VideoCategory } from 'app/data/enum/video/VideoCategory';
import { VideoSubcategory } from 'app/data/enum/video/VideoSubcategory';
import { IntensityLevel } from 'app/data/enum/IntensityLevel';

export class VideoPageCriteriaDTO extends PageCriteriaDTO {

  public anyOfCategories?: VideoCategory[];

  public anyOfCoachIds?: number[];

  public anyOfIntensities?: IntensityLevel[];

  public anyOfSubcategories?: VideoSubcategory[];

  public anyOfLanguages?: string[];

  public favorites?: boolean;

  public recommended?: boolean;

  public pageSize: number = 25;

}
