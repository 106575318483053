import { LiveClassCategory } from 'app/data/enum/liveClass/LiveClassCategory';
import { LiveClassVisibility } from 'app/data/enum/liveClass/LiveClassVisibility';
import { IntensityLevel } from 'app/data/enum/IntensityLevel';
import { DaysDTO } from 'app/data/dto/DaysDTO';
import { Transform, Type } from 'class-transformer';
import { DateUtil } from 'app/util/DateUtil';
import { ScheduledLiveClassNeedDTO } from 'app/data/dto/scheduledLiveClass/ScheduledLiveClassNeedDTO';
import * as _ from 'lodash';

export class LiveClassSeriesCreateRequestDTO {
  public attachmentIds: number[] = [];

  @Transform(({ value }) => value || null, { toPlainOnly: true })
  public capacity?: number;

  public category: LiveClassCategory;

  public coachIds: number[];

  public description: string;

  public duration: number;

  public numberOfClassesLeft: number;

  @Type(() => DaysDTO)
  public daysOfWeek: DaysDTO = new DaysDTO();

  public imageId: number;

  public intensity: IntensityLevel;

  public language: string;

  @Type(() => ScheduledLiveClassNeedDTO)
  public liveClassSeriesNeeds?: ScheduledLiveClassNeedDTO[];

  @Transform(DateUtil.dateTimeConversion)
  public startDate: Date;

  @Transform(DateUtil.dateTimeConversion)
  public endDate: Date;

  public title: string;

  public url: string;

  public visibility: LiveClassVisibility;

  constructor(data?: Partial<LiveClassSeriesCreateRequestDTO>) {
    _.defaults(this, data, {
      attachmentIds: [],
      liveClassSeriesNeeds: []
    });
  }
}
