<ng-template #headingTemplate>
  <h1>{{ title | translate }}</h1>
</ng-template>

<div class="d-flex justify-content-end mb-3">
  <button type="button" class="btn btn-outline-primary" (click)="onBackClick()">
    <svg-icon src="asset/image/icon/chevron-left.svg"></svg-icon>
    <span>{{ 'COMMON.BACK' | translate }}</span>
  </button>
</div>


<section class="section">
  <ng-container *ngIf="liveClassDetails">
    <h2 class="mb-4">{{liveClassDetails.title}}</h2>
    <h3>{{'VIEW.MAIN.LIVE_CLASSES.COACHES' | translate}}</h3>
    <ol>
      <li *ngFor="let coach of liveClassDetails.coaches">{{coach.user.firstName}} {{coach.user.lastName}}</li>
    </ol>
  </ng-container>
  <div class="zoom-container">
    <app-zoom-complete-view></app-zoom-complete-view>
  </div>
</section>