import { Type } from 'class-transformer';
import { BaseUserDTO } from 'app/data/dto/user/BaseUserDTO';

export class VideoCoachDetailsDTO {

  public bio: string;

  public coachCertifications: Record<'certification', string>[];

  public coachEducations: Record<'education', string>[];

  public coachHobbies: Record<'hobby', string>[];

  @Type(() => BaseUserDTO)
  public user: BaseUserDTO;

}

