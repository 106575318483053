<div class="d-flex gap-4 mb-1 category-tabs">
  <div class="category-tab" [class.selected]="!selected" (click)="selectedChange.emit(null)">
    <svg-icon src="asset/image/icon/all.svg"></svg-icon>
    <span class="text-sm">{{ 'ENUM.VIDEO_CATEGORY.ALL' | translate }}</span>
  </div>
  <div
    *ngFor="let category of categories"
    class="category-tab"
    [class.selected]="selected === category.value"
    (click)="selectedChange.emit(category.value)">
    <svg-icon [src]="category.svgSrc"></svg-icon>
    <span class="text-sm">{{ 'ENUM.VIDEO_CATEGORY.' + (category.value || 'ALL') | translate }}</span>
  </div>
</div>