import { Component, OnDestroy, OnInit } from '@angular/core';
import { CoachDTO } from 'app/data/dto/coach/CoachDTO';
import { DayOfWeek } from 'app/data/enum/DayOfWeek';
import { LiveClassLanguage } from 'app/data/enum/liveClass/LiveClassLanguage';
import { LiveClassCategory } from 'app/data/enum/liveClass/LiveClassCategory';
import { OptionItem } from 'app/data/local/generic/OptionItem';
import { CoachModel } from 'app/model/CoachModel';
import { ViewUtil } from 'app/util/ViewUtil';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { IntensityLevel } from 'app/data/enum/IntensityLevel';

@Component({
  selector: 'app-live-classes-filter',
  templateUrl: 'LiveClassesFilterComponent.html',
  styleUrls: [ 'LiveClassesFilterComponent.scss' ]
})
export class LiveClassesFilterComponent implements OnInit, OnDestroy {
  public static SHOW_FILTERS: string = 'showFilters';
  public static CLEAR_FILTERS: string = 'clearFilters';
  private destroy$: Subject<void> = new Subject<void>();
  public allCategoryChecked: boolean = false;
  public checkedCategories: any = {};
  public allCoachesChecked: boolean = false;
  public checkedCoaches: any = {};
  public allLanguageChecked: boolean = false;
  public checkedLanguages: any = {};

  public anyOfCategories: string[];
  public anyOfCoachIds: number[];
  public anyOfIntensities: string[];
  public anyOfLanguages: string[];
  public daysOfWeek: string[];

  public isItCoach: boolean = false;
  public hideDaysOfWeek: boolean = false;

  public categories: OptionItem<LiveClassCategory>[] = this.viewUtil.createEnumSelectOptions(
    LiveClassCategory,
    'CATEGORY'
  );

  public intensities: OptionItem<IntensityLevel>[] = this.viewUtil.createEnumSelectOptions(
    IntensityLevel,
    'INTENSITY_LEVEL'
  );

  public days: OptionItem<DayOfWeek>[] = this.viewUtil.createEnumSelectOptions(DayOfWeek, 'DAYS');
  public languages: OptionItem<LiveClassLanguage>[] = this.viewUtil.createEnumSelectOptions(LiveClassLanguage, 'LANGUAGE');

  public coaches: OptionItem<CoachDTO>[] = [];
  public filters = {
    categories: {
      allChecked: false,
      checked: {}
    },
    intensities: {
      allChecked: false,
      checked: {}
    },
    languages: {
      allChecked: false,
      checked: {}
    },
    coaches: {
      checked: {},
      selectedCoaches: []
    },
    dayOfTheWeek: []
  };

  constructor(
    private viewUtil: ViewUtil,
    private coachModel: CoachModel,
    private bsModalRef: BsModalRef,
    public modalService: BsModalService
  ) {
  }

  ngOnInit(): void {
    this.getCoachSelectList();
    if (this.anyOfCategories) {
      this.anyOfCategories.forEach((category) => {
        this.filters.categories.checked[category] = true;
      });
      this.onCategoryCheckboxChange();
    }
    if (this.anyOfIntensities) {
      this.anyOfIntensities.forEach((intensity) => {
        this.filters.intensities.checked[intensity] = true;
      });
      this.onIntensityCheckboxChange();
    }
    if (this.daysOfWeek) {
      this.daysOfWeek.forEach((day) => {
        this.filters.dayOfTheWeek.push(day);
      });
    }
    if (this.anyOfLanguages) {
      this.anyOfLanguages.forEach((language) => {
        this.filters.languages.checked[language] = true;
      });
      this.onLanguageCheckboxChange();
    }
    if (this.anyOfCoachIds) {
      this.anyOfCoachIds.forEach((coachId) => {
        this.filters.coaches.checked[coachId] = true;
        this.filters.coaches.selectedCoaches.push(coachId);
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onAllCategoryChange(): void {
    this.categories.forEach((category) => {
      this.filters.categories.checked[category.value] = this.filters.categories.allChecked;
    });
  }

  public onCategoryCheckboxChange(): void {
    const allChecked = this.categories.every((category) => this.filters.categories.checked[category.value]);
    this.filters.categories.allChecked = allChecked;
  }

  public onAllIntensityChange(): void {
    this.intensities.forEach((intensity) => {
      this.filters.intensities.checked[intensity.value] = this.filters.intensities.allChecked;
    });
  }

  public onIntensityCheckboxChange(): void {
    const allChecked = this.intensities.every((intensity) => this.filters.intensities.checked[intensity.value]);
    this.filters.intensities.allChecked = allChecked;
  }

  public onAllLanguageChange(): void {
    this.languages.forEach((language) => {
      this.filters.languages.checked[language.value] = this.filters.languages.allChecked;
    });
  }

  public onLanguageCheckboxChange(): void {
    const allChecked = this.languages.every((language) => {
      return this.filters.languages.checked[language.value];
    });
    this.filters.languages.allChecked = allChecked;
  }

  public onClearFiltersClick(): void {
    // Reset categories
    this.filters.categories.allChecked = false;
    Object.keys(this.filters.categories.checked).forEach((key) => {
      this.filters.categories.checked[key] = false;
    });

    // Reset languages
    this.filters.languages.allChecked = false;
    Object.keys(this.filters.languages.checked).forEach((key) => {
      this.filters.languages.checked[key] = false;
    });

    // Reset coaches
    this.filters.coaches.selectedCoaches = [];
    Object.keys(this.filters.coaches.checked).forEach((key) => {
      this.filters.coaches.checked[key] = false;
    });

    // Reset intensities
    this.filters.intensities.allChecked = false;
    Object.keys(this.filters.intensities.checked).forEach((key) => {
      this.filters.intensities.checked[key] = false;
    });

    //reset Day of the week
    this.filters.dayOfTheWeek = [];
    this.bsModalRef.hide();
    this.modalService.setDismissReason(LiveClassesFilterComponent.CLEAR_FILTERS);
  }

  public onShowFiltersClick(): void {
    this.modalService.setDismissReason(LiveClassesFilterComponent.SHOW_FILTERS);
    this.bsModalRef.hide();
  }

  public onHideFiltersClick(): void {
    this.bsModalRef.hide();
  }

  private getCoachSelectList(): void {
    this.coachModel
      .getCoachSelectList()
      .pipe(
        takeUntil(this.destroy$),
        tap((coaches: any[]) => {
          coaches.forEach(({ id, user: { firstName, lastName } }) => {
            this.coaches.push({
              label: `${ firstName } ${ lastName }`,
              value: id
            });
          });
        })
      )
      .subscribe();
  }
}
