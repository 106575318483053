import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { StateService, Transition } from '@uirouter/core';
import { State } from 'app/common/State';
import { CoachProfileResponseAdminDTO } from 'app/data/dto/coach/CoachProfileResponseAdminDTO';
import { ApplicationModel } from 'app/model/ApplicationModel';
import { CoachModel } from 'app/model/CoachModel';
import { ViewUtil } from 'app/util/ViewUtil';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import { TemplatePortal } from '@angular/cdk/portal';
import { PortalUtil } from 'app/util/PortalUtil';

@Component({
  selector: 'app-coaches-details',
  templateUrl: './CoachesDetailsComponent.html',
  styleUrls: [ './CoachesDetailsComponent.scss' ]
})
export class CoachesDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('headingTemplate', { static: true })
  private readonly headingTemplate: TemplateRef<any>;

  public id: number;

  public page$: Observable<CoachProfileResponseAdminDTO>;

  constructor(private applicationModel: ApplicationModel,
              private transition: Transition,
              private coachModel: CoachModel,
              private stateService: StateService,
              private viewUtil: ViewUtil,
              private portalUtil: PortalUtil,
              private viewContainerRef: ViewContainerRef) {
    this.applicationModel.selectSideBarItemWithState(State.MAIN.ADMIN.COACHES.LIST);
    this.id = this.transition.params().id;
  }

  public ngOnInit(): void {
    this.portalUtil.attachPortalTo(
      MainLayoutComponent.PORTAL_OUTLET.HEADING,
      new TemplatePortal(this.headingTemplate, this.viewContainerRef)
    );

    this.getCoach();
  }

  public ngOnDestroy(): void {
    this.portalUtil.detachPortalFrom(MainLayoutComponent.PORTAL_OUTLET.HEADING);
  }

  public onBackClick(): void {
    this.stateService.go(State.MAIN.ADMIN.COACHES.LIST);
  }

  public onEditClick(): void {
    this.stateService.go(State.MAIN.ADMIN.COACHES.EDIT, { id: this.id });
  }

  private getCoach(): void {
    this.page$ = this.coachModel.getCoach(this.id).pipe(
      catchError((err) => {
        this.viewUtil.handleServerError(err);
        return throwError(() => err);
      })
    );
  }
}
