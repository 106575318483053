import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, SuppressKeyboardEventParams } from 'ag-grid-community';
import { DataGridComponent } from 'app/component/ui/dataGrid/DataGridComponent';
import { DataGridContext } from 'app/component/ui/dataGrid/DataGridContext';
import { ResponsiveDataGridComponent } from 'app/component/ui/responsiveDataGrid/ResponsiveDataGridComponent';
import { VideoAdminDTO } from 'app/data/dto/onDemandVideo/admin/VideoAdminDTO';

@Component({
  selector: 'app-on-demand-videos-list-admin-action-cell-renderer',
  templateUrl: './OnDemandVideosListAdminActionCellRendererComponent.html',
  styleUrls: [ './OnDemandVideosListAdminActionCellRendererComponent.scss' ]
})
export class OnDemandVideosListAdminActionCellRendererComponent implements ICellRendererAngularComp {
  public static readonly ACTION_VIEW: string = 'view';
  public static readonly ACTION_EDIT: string = 'edit';
  public static readonly ACTION_DELETE: string = 'delete';

  public params: ICellRendererParams;
  public data: VideoAdminDTO;
  public context: DataGridContext;

  public showAdminActions: boolean = false;

  public refresh(params: ICellRendererParams): boolean {
    return false;
  }

  public agInit(params: ICellRendererParams): void {
    this.params = params;
    this.data = params.data;
    this.context = params.context;
  }

  public preventGridSelection(event: Event): void {
    if (this.context.dataGridHost instanceof DataGridComponent) {
      const previousValue = this.params.node['gridOptionsWrapper'].gridOptions.suppressRowClickSelection;
      this.params.node['gridOptionsWrapper'].gridOptions.suppressRowClickSelection = true;

      setTimeout(() => {
        this.params.node['gridOptionsWrapper'].gridOptions.suppressRowClickSelection = previousValue;
      });
    }
    else if (this.context.dataGridHost instanceof ResponsiveDataGridComponent) {
      event.stopImmediatePropagation();
      event.preventDefault();
    }
  }

  public edit(video: VideoAdminDTO): void {
    this.context.dataGridAction(OnDemandVideosListAdminActionCellRendererComponent.ACTION_EDIT, [ video ]);
  }

  public delete(video: VideoAdminDTO): void {
    this.context.dataGridAction(OnDemandVideosListAdminActionCellRendererComponent.ACTION_DELETE, [ video ]);
  }

  public view(video: VideoAdminDTO): void {
    this.context.dataGridAction(OnDemandVideosListAdminActionCellRendererComponent.ACTION_VIEW, [ video ]);
  }

  public static suppressKeyboardEvent(params: SuppressKeyboardEventParams): boolean {
    return true;
  }
}
