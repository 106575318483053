import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Transition } from '@uirouter/angular';
import { StateService } from '@uirouter/core';
import { Constant } from 'app/common/Constant';
import { State } from 'app/common/State';
import { OrganizationListDTO } from 'app/data/dto/registration/OrganizationListDTO';
import { SeniorProfileResponseAdminDTO } from 'app/data/dto/senior/SeniorProfileResponseAdminDTO';
import { UserGender } from 'app/data/enum/account/UserGender';
import { OptionItem } from 'app/data/local/generic/OptionItem';
import { AdminModel } from 'app/model/AdminModel';
import { StateUtil } from 'app/util/StateUtil';
import { ViewUtil } from 'app/util/ViewUtil';
import { Observable } from 'rxjs';
import { PortalUtil } from 'app/util/PortalUtil';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import { TemplatePortal } from '@angular/cdk/portal';
import { SubscriptionType } from 'app/data/enum/subscription/SubscriptionType';
import moment from 'moment/moment';
import { SeniorUpdateRequestDTO } from 'app/data/dto/senior/SeniorUpdateRequestDTO';
import { ObjectUtil } from 'app/util/ObjectUtil';
import { ImageResponseDTO } from 'app/data/dto/images/ImageResponseDTO';
import { IdentifiableObjectDTO } from 'app/data/dto/IdentifiableObjectDTO';

@Component({
  selector: 'app-user-edit',
  templateUrl: 'UserEditComponent.html',
  styleUrls: [ 'UserEditComponent.scss' ]
})
export class UserEditComponent implements OnInit, OnDestroy {
  @ViewChild('headingTemplate', { static: true })
  private readonly headingTemplate: TemplateRef<any>;

  @ViewChild('userForm')
  private userForm: NgForm;

  public uploadStarted: boolean = false;

  public id: number;

  public avatar: ImageResponseDTO;
  public senior: SeniorUpdateRequestDTO;

  public Constant: typeof Constant = Constant;
  public SubscriptionType: typeof SubscriptionType = SubscriptionType;

  public statusOptions: OptionItem<boolean>[] = [
    {
      value: true,
      label: 'ENUM.STATUS.ACTIVE'
    },
    {
      value: false,
      label: 'ENUM.STATUS.INACTIVE'
    }
  ];

  public subscriptionTypeOptions: OptionItem<SubscriptionType>[] = this.viewUtil
    .createEnumSelectOptions(SubscriptionType, 'SUBSCRIPTION_TYPE');
  public genderOptions: OptionItem<UserGender>[] = this.viewUtil.createEnumSelectOptions(UserGender, 'USER_GENDER');
  public organizationsList$: Observable<OrganizationListDTO[]> = this.adminModel.getAllOrganizationList();
  public maxDateOfBirth: Date = moment().add(-1, 'days').toDate();


  constructor(private stateService: StateService,
              public viewUtil: ViewUtil,
              public stateUtil: StateUtil,
              private transition: Transition,
              private adminModel: AdminModel,
              private portalUtil: PortalUtil,
              private viewContainerRef: ViewContainerRef) {
    this.id = this.transition.params().id;
  }

  public ngOnInit(): void {
    this.portalUtil.attachPortalTo(
      MainLayoutComponent.PORTAL_OUTLET.HEADING,
      new TemplatePortal(this.headingTemplate, this.viewContainerRef)
    );

    this.getSenior();
  }

  public ngOnDestroy(): void {
    this.portalUtil.detachPortalFrom(MainLayoutComponent.PORTAL_OUTLET.HEADING);
  }

  public updateSenior(): void {
    this.userForm.onSubmit(null);

    if (this.userForm.valid) {
      this.adminModel
        .updateSenior(this.id, this.senior)
        .subscribe({
          next: ({ id }: IdentifiableObjectDTO<number>) => {
            this.stateService.go(State.MAIN.ADMIN.USER.DETAILS, { id });
          },
          error: (err) => this.viewUtil.handleServerError(err)
        });

    }
  }

  public getSenior(): void {
    this.adminModel
      .getSeniorDetails(this.id)
      .subscribe({
        next: (senior: SeniorProfileResponseAdminDTO) => {
          this.senior = ObjectUtil.plainToClass(SeniorUpdateRequestDTO, senior);
          this.senior.subscription = senior.user.subscription;
          this.avatar = senior.user.avatar;
        },
        error: (err) => this.viewUtil.handleServerError(err)
      });
  }

  public handleStartUpload(uploadStart: boolean): void {
    this.uploadStarted = uploadStart;
  }

  public handleImageId(imageId: number): void {
    this.senior.user.imageId = imageId;
  }

  public onBackClick(): void {
    this.stateService.go(State.MAIN.ADMIN.USER.DETAILS, { id: this.id });
  }

  public onProviderChange(): void {
    const { subscription } = this.senior;

    subscription.memberCode = undefined;
  }

  public onSubscriptionTypeChange(): void {
    const { subscription } = this.senior;

    subscription.memberCode = undefined;
    subscription.organization = undefined;
  }
}
