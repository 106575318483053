import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-data-grid-star-rating-cell-renderer',
  templateUrl: './DataGridStarRatingCellRendererComponent.html'
})
export class DataGridStarRatingCellRendererComponent implements ICellRendererAngularComp {
  public params: ICellRendererParams;
  public value: number;

  public agInit(params: ICellRendererParams): void {
    this.params = params;
    this.value = params.value;
  }

  public refresh(params: ICellRendererParams): boolean {
    return false;
  }

  public preventGridSelection(): void {
    const previousValue = this.params.node['gridOptionsWrapper'].gridOptions.suppressRowClickSelection;
    this.params.node['gridOptionsWrapper'].gridOptions.suppressRowClickSelection = true;

    setTimeout(() => {
      this.params.node['gridOptionsWrapper'].gridOptions.suppressRowClickSelection = previousValue;
    });
  }
}
