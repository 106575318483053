import { Injectable, Injector } from '@angular/core';
import { FriendService } from 'app/service/friend/FriendService';
import { Friend } from 'app/data/local/friend/Friend';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PopupConfirmationComponent } from 'app/component/ui/popup/PopupConfirmationComponent';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { ViewUtil } from 'app/util/ViewUtil';
import { PageCriteriaDTO } from 'app/data/dto/PageCriteriaDTO';
import { PageDTO } from 'app/data/dto/PageDTO';
import { FriendDTO } from 'app/data/dto/friend/FriendDTO';

@Injectable({ providedIn: 'root' })
export class FriendModel {
  constructor(private readonly friendService: FriendService,
              private readonly viewUtil: ViewUtil,
              private readonly injector: Injector) {

  }

  public getFriendsPage(criteria: PageCriteriaDTO): Observable<PageDTO<FriendDTO>> {
    return this.friendService.getFriendsPage(criteria);
  }

  public removeFriend(friend: Friend): Observable<void> {
    const { firstName, lastName } = friend?.user;

    return this.injector.get(BsModalService).show(PopupConfirmationComponent, {
      initialState: {
        title: 'DOMAIN.FRIEND.MESSAGE.DELETE.TITLE',
        message: 'DOMAIN.FRIEND.MESSAGE.DELETE.DESCRIPTION',
        okText: 'COMMON.CONFIRM',
        cancelText: 'COMMON.CANCEL',
        messageVariables: { firstName, lastName }
      },
      class: 'modal-dialog-centered'
    }).content.close$
      .pipe(
        switchMap(() => this.friendService.removeFriend(friend.id)),
        tap(() => this.viewUtil.showToastSuccess('DOMAIN.FRIEND.MESSAGE.DELETE.SUCCESS'))
      );
  }
}