import { Component, OnInit } from '@angular/core';
import { PageCriteriaDTO } from 'app/data/dto/PageCriteriaDTO';
import { PageDTO } from 'app/data/dto/PageDTO';
import { FriendDTO } from 'app/data/dto/friend/FriendDTO';
import { UserDTO } from 'app/data/dto/user/UserDTO';
import { DataGridTemplateCellRendererComponent } from 'app/component/ui/dataGrid/cellRenderer/DataGridTemplateCellRendererComponent';
import { Constant } from 'app/common/Constant';
import { UserModel } from 'app/model/UserModel';
import { FriendInvitationModel } from 'app/model/friend/FriendInvitationModel';

@Component({
  selector: 'app-friends-sent-invitations',
  templateUrl: './FriendsSentInvitationsComponent.html'
})
export class FriendsSentInvitationsComponent implements OnInit {
  public criteria: PageCriteriaDTO = new PageCriteriaDTO();
  public page: PageDTO<FriendDTO>;
  public currentUser: UserDTO = this.userModel.currentUser;
  public listWindowSize: number = 3;

  public Constant: typeof Constant = Constant;
  public DataGridTemplateCellRendererComponent: typeof DataGridTemplateCellRendererComponent =
    DataGridTemplateCellRendererComponent;

  constructor(private readonly friendInvitationModel: FriendInvitationModel,
              private readonly userModel: UserModel) {
    this.criteria.pageSize = this.listWindowSize;
  }

  ngOnInit(): void {
    this.loadData();
  }

  public onCriteriaChanged(criteria: PageCriteriaDTO): void {
    this.criteria = criteria;

    this.loadData();
  }

  public nextPage(): void {
    this.criteria.pageSize += this.listWindowSize;

    this.onCriteriaChanged(this.criteria);
  }

  public loadData(): void {
    this.friendInvitationModel.getSentInvitationsPage(this.criteria)
      .subscribe((result) => {
        this.page = result;
      });
  }

  public removeInvitations(friend: FriendDTO): void {
    this.friendInvitationModel.remove(friend)
      .subscribe({
        next: () => {
          this.loadData();
        }
      });
  }
}
