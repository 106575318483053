import { DateUtil } from 'app/util/DateUtil';
import { Transform, Type } from 'class-transformer';
import { IdentifiableObjectDTO } from '../IdentifiableObjectDTO';
import { OrganizationAddressDTO } from './OrganizationAddressDTO';
import { ImageResponseDTO } from '../images/ImageResponseDTO';

export class OrganizationDetailsResponseDTO extends IdentifiableObjectDTO<number> {
  @Type(() => OrganizationAddressDTO)
  address?: OrganizationAddressDTO;
  apiUrl?: string;
  contactName?: string;

  @Transform(DateUtil.dateTimeConversion)
  createdDate: Date;
  logo?: ImageResponseDTO;
  name: string;
  phone?: string;
  stub: string;
  type: string;
  usersCount?: number;
}
