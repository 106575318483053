import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { StateService, Transition } from '@uirouter/core';
import { State } from 'app/common/State';
import { OrganizationDetailsResponseDTO } from 'app/data/dto/organization/OrganizationDetailsResponseDTO';
import { OrganizationType } from 'app/data/enum/organizations/OrganizationType';
import { ApplicationModel } from 'app/model/ApplicationModel';
import { OrganizationModel } from 'app/model/OrganizationModel';
import { Observable } from 'rxjs';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import { TemplatePortal } from '@angular/cdk/portal';
import { PortalUtil } from 'app/util/PortalUtil';

@Component({
  selector: 'app-organizations-details',
  templateUrl: './OrganizationsDetailsComponent.html',
  styleUrls: [ './OrganizationsDetailsComponent.scss' ]
})
export class OrganizationsDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('headingTemplate', { static: true })
  private readonly headingTemplate: TemplateRef<any>;

  organization$: Observable<OrganizationDetailsResponseDTO>;
  id: number;
  OrganizationType = OrganizationType;

  constructor(private organizationModel: OrganizationModel,
              private transition: Transition,
              private stateService: StateService,
              private applicationModel: ApplicationModel,
              private portalUtil: PortalUtil,
              private viewContainerRef: ViewContainerRef) {
  }

  public ngOnInit(): void {
    this.id = this.transition.params().id;

    this.applicationModel.selectSideBarItemWithState(State.MAIN.ADMIN.ORGANIZATIONS.LIST);

    this.portalUtil.attachPortalTo(
      MainLayoutComponent.PORTAL_OUTLET.HEADING,
      new TemplatePortal(this.headingTemplate, this.viewContainerRef)
    );

    this.organization$ = this.organizationModel.getOrganizationDetails(this.id);
  }

  public ngOnDestroy(): void {
    this.portalUtil.detachPortalFrom(MainLayoutComponent.PORTAL_OUTLET.HEADING);
  }

  public onBackClick(): void {
    this.stateService.go(State.MAIN.ADMIN.ORGANIZATIONS.LIST);
  }

  public onEditClick(): void {
    this.stateService.go(State.MAIN.ADMIN.ORGANIZATIONS.EDIT, { id: this.id });
  }
}
