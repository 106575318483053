<div id="preferences-container">
  <div id="preferences">
    <div id="logo">
      <img src="asset/image/logo.png" alt="Logo"/>
    </div>

    <div>{{ 'VIEW.PRELIMINARY.PREFERENCES.ABOUT_YOU' | translate }}</div>
    <h1>{{ 'VIEW.PRELIMINARY.PREFERENCES.TELL_ABOUT_YOURSELF' | translate }}</h1>
    <div class="font-weight-bold mb-12">{{ 'VIEW.PRELIMINARY.PREFERENCES.FILL_QUESTIONNAIRE' | translate }}</div>

    <app-account-details-preferences
      *ngIf="currentUser"
      [userType]="currentUser.userType"
      [embeddedMode]="true"
      [savePreferences$]="sendPreferencesEvent.asObservable()"
      [redirectionState]="redirectionState"
    ></app-account-details-preferences>

    <div class="d-flex flex-wrap gap-4 justify-content-end mt-12">
      <button class="btn btn-outline-primary" (click)="skip()">{{ 'COMMON.SKIP' | translate }}</button>
      <button class="btn btn-primary" (click)="send()">{{ 'COMMON.SEND' | translate }}</button>
    </div>
  </div>
</div>
