import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FileUploader } from 'ng2-file-upload';
import { HtmlUtil } from 'app/util/HtmlUtil';
import { ViewUtil } from 'app/util/ViewUtil';
import { ApplicationConfig } from 'app/config/ApplicationConfig';
import { UploadFile } from 'app/data/local/file/UploadFile';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FileModel } from 'app/model/FileModel';
import { FileTokenDTO } from 'app/data/dto/file/FileTokenDTO';
import * as _ from 'lodash';
import { EventManager } from 'app/util/other/EventManager';
import { FileUtil } from 'app/util/FileUtil';

@Component({
  selector: 'app-file-upload-popup',
  templateUrl: 'FileUploadPopupComponent.html',
  styleUrls: [ 'FileUploadPopupComponent.scss' ]
})
export class FileUploadPopupComponent implements OnInit, OnDestroy {

  public static POPUP_RESULT_OK: string = 'FileUploadPopupComponent_OK';

  @ViewChild('fileForm')
  private fileForm: NgForm;

  public title: string = 'COMPONENT.FILE_UPLOAD.FILE_UPLOAD';
  public useName: boolean = false;
  public maxFileSize: number = ApplicationConfig.maxFileSize; // in KBytes
  public allowedExtensions: string[] = [];

  public formData: {
    name: string;
    file: UploadFile;
  } = {
    name: null,
    file: new UploadFile()
  };

  public resultData: {
    name: string;
    fileToken: FileTokenDTO;
  } = {
    name: null,
    fileToken: null
  };

  constructor(public bsModalRef: BsModalRef,
              public modalService: BsModalService,
              private eventManager: EventManager,
              public viewUtil: ViewUtil,
              private fileUtil: FileUtil,
              private fileModel: FileModel) {

    this.formData.file.fileUploader = new FileUploader({ queueLimit: 1 });
  }

  public ngOnInit(): void {
    //
  }

  public ngOnDestroy(): void {
    //
  }

  public onFileChange(file: File, uploadFile: UploadFile): void {
    uploadFile.reset();
    uploadFile.file = file;

    console.log(URL.createObjectURL(file));

    if (this.useName) {
      this.formData.name = file.name;
    }
  }

  public resetFile(element: HTMLInputElement, uploadFile: UploadFile): void {
    HtmlUtil.resetFileInput(element);
    uploadFile.reset();

    if (this.useName) {
      this.formData.name = null;
    }
  }

  public ok(): void {
    this.fileForm.onSubmit(null);

    if (this.fileForm.form.valid) {
      this.formData.file.uploading = true;

      this.formData.file.uploadSubscription = this.fileModel.uploadFile(this.formData.file.file as File, true)
        .subscribe((result: FileTokenDTO | number) => {
          if (result instanceof FileTokenDTO) {
            this.formData.file.uploaded = true;
            this.formData.file.uploadError = false;
            this.formData.file.uploading = false;
            this.formData.file.uploadSubscription = null;

            this.resultData.fileToken = result;
            if (this.useName) {
              this.resultData.name = this.formData.name;
            }

            this.modalService.setDismissReason(FileUploadPopupComponent.POPUP_RESULT_OK);
            this.bsModalRef.hide();
          }
          else if (_.isNumber(result)) {
            this.formData.file.uploadProgress = result;
            this.formData.file.uploading = true;
          }
        }, (error) => {
          this.formData.file.uploaded = false;
          this.formData.file.uploadError = true;
          this.formData.file.uploading = false;
          this.formData.file.uploadSubscription = null;

          this.viewUtil.handleServerError(error);
        });
    }
  }

  public close(): void {
    if (this.formData.file.uploadSubscription) {
      this.formData.file.uploadSubscription.unsubscribe();
      this.formData.file.uploadSubscription = null;
    }

    this.bsModalRef.hide();
  }

}
