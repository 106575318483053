import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { StateService, Transition } from '@uirouter/core';
import { SeniorPreferencesDTO } from 'app/data/dto/senior/SeniorPreferencesDTO';
import { SeniorPreferencesResponseAdminDTO } from 'app/data/dto/senior/SeniorPreferencesResponseAdminDTO';
import { UserActivityFrequency } from 'app/data/enum/account/UserActivityFrequency';
import { UserHealth } from 'app/data/enum/account/UserHealth';
import { UserInterest } from 'app/data/enum/account/UserInterest';
import { UserType } from 'app/data/enum/user/UserType';
import { AdminModel } from 'app/model/AdminModel';
import { SeniorModel } from 'app/model/SeniorModel';
import { ViewUtil } from 'app/util/ViewUtil';
import _ from 'lodash';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Constant } from 'app/common/Constant';
import { SubscriptionStoreComponent } from 'app/component/SubscriptionStoreComponent';

@Component({
  selector: 'app-account-details-preferences',
  templateUrl: './AccountDetailsPreferencesComponent.html',
  styleUrls: [ './AccountDetailsPreferencesComponent.scss' ]
})
export class AccountDetailsPreferencesComponent extends SubscriptionStoreComponent implements OnInit {
  @Input()
  public embeddedMode: boolean = false;

  @Input()
  public savePreferences$: Observable<void>;

  @Input()
  public redirectionState: string;

  @Input() userType: UserType;
  public id: number;

  public form: FormGroup;
  public possibleInterests: UserInterest[] = Object.values(UserInterest);
  public possibleActivity: UserActivityFrequency[] = Object.values(UserActivityFrequency);
  public possibleHealth: UserHealth[] = Object.values(UserHealth);
  public userTimezone: string;
  public userPreferences: SeniorPreferencesDTO;
  public seniorPreferences: SeniorPreferencesResponseAdminDTO;

  public readonly Constant: typeof Constant = Constant;

  constructor(
    private fb: FormBuilder,
    private seniorModel: SeniorModel,
    public viewUtil: ViewUtil,
    private stateService: StateService,
    private adminModel: AdminModel,
    private transition: Transition
  ) {
    super();
  }

  get seniorInterestsControls(): FormControl[] {
    return (this.form?.get('seniorInterests') as FormArray)?.controls as FormControl[];
  }

  ngOnInit(): void {
    this.id = this.transition.params().id;
    this.getUserBasedOnType();
    this.getUserTimezone();
    if (this.embeddedMode && this.savePreferences$) {
      this.subscription = this.savePreferences$.subscribe(() => this.saveChanges());
    }
  }

  public editPreferences(): void {
    this.form.enable();
  }

  public cancelEdit(): void {
    this.form.disable();
  }

  public saveChanges(): void {
    if (this.form.valid) {
      const formValue = _.cloneDeep(this.form.value);
      const mappedInterest = [];
      formValue.seniorInterests?.forEach((value, index) => {
        if (value) {
          mappedInterest.push(this.possibleInterests[index]);
        }
      });
      formValue.seniorInterests = mappedInterest;

      this.seniorModel.updateCurrentSeniorPreferences(formValue).subscribe(
        (res) => {
          if (this.embeddedMode && this.redirectionState) {
            this.stateService.go(this.redirectionState);
          }
          else {
            this.viewUtil.showToastSuccess('VIEW.MAIN.ACCOUNT.UPDATE.SUCCESS');
            this.form.disable();
          }
        },
        (err) => this.viewUtil.handleServerError(err)
      );
    }
  }

  private getUserBasedOnType(): void {
    switch (this.userType) {
      case UserType.SENIOR:
        this.getUserPreferencesAndSetupForm();
        break;
      case UserType.ADMIN:
        this.getSeniorPreferencesByIdAndSetupForm(this.id);
        break;
      case UserType.COACH:
        break;
    }
  }

  private getUserPreferencesAndSetupForm(): void {
    this.seniorModel.getCurrentSeniorPreferences().subscribe((preferences) => {
      this.userPreferences = preferences;
      this.setupForm(preferences);
    });
  }

  private getSeniorPreferencesByIdAndSetupForm(id: number): void {
    this.adminModel
      .getSeniorPreferencesById(id)
      .pipe(
        tap((preferences) => {
          this.seniorPreferences = preferences;
          this.setupFormAdmin(preferences);
        }),
        catchError((error) => {
          this.viewUtil.handleServerError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  private setupForm(preferences: SeniorPreferencesDTO): void {
    if (this.embeddedMode) {
      this.setupEmptyForm();
    }
    else {
      this.setupPrePopulatedForm(preferences);
    }
  }

  private setupPrePopulatedForm(preferences: SeniorPreferencesDTO): void {
    this.form = this.fb.group({
      seniorActivityFrequency: [ this.userPreferences.seniorActivityFrequency ],
      seniorInterests: this.createPrePopulatedUserInterestsFormArray(preferences),
      seniorHealth: [ this.userPreferences.seniorHealth ],
      user: this.fb.group({
        mailNotificationsEnabled: [
          this.userPreferences.user.mailNotificationsEnabled,
          this.mailNotificationValidator(this.userPreferences.user.email)
        ],
        smsNotificationsEnabled: [
          this.userPreferences.user.smsNotificationsEnabled,
          this.smsNotificationValidator(this.userPreferences.user.phone)
        ],
        phone: [ this.userPreferences.user.phone ]
      })
    });
    this.form.disable();
  }

  private setupFormAdmin(preferences: SeniorPreferencesResponseAdminDTO): void {
    this.form = this.fb.group({
      seniorActivityFrequency: [ preferences.seniorActivityFrequency ],
      seniorInterests: this.createPrePopulatedUserInterestsFormArray(preferences),
      seniorHealth: [ preferences.seniorHealth ],
      user: this.fb.group({
        mailNotificationsEnabled: [
          preferences.user.mailNotificationsEnabled,
          this.mailNotificationValidator(preferences.user.email)
        ],
        smsNotificationsEnabled: [
          preferences.user.smsNotificationsEnabled,
          this.smsNotificationValidator(preferences.user.phone)
        ]
      })
    });
    this.form.disable();
  }

  private setupEmptyForm(): void {
    const phoneValidators: ValidatorFn[] = [ Validators.required, Validators.pattern(Constant.PHONE_NUMBER_RULES.pattern) ];
    const emailValidators: ValidatorFn[] = [ Validators.required, Validators.email ];

    this.form = this.fb.group({
      seniorActivityFrequency: [ null ],
      seniorInterests: this.createEmptyUserInterestsFormArray(),
      seniorHealth: [ null ],
      user: this.fb.group({
        mailNotificationsEnabled: [ this.userPreferences.user.mailNotificationsEnabled ],
        smsNotificationsEnabled: [ this.userPreferences.user.smsNotificationsEnabled ],
        phone: [ this.userPreferences.user.phone, (this.userPreferences.user.smsNotificationsEnabled ? phoneValidators : []) ],
        email: [ this.userPreferences.user.email, (this.userPreferences.user.mailNotificationsEnabled ? emailValidators : []) ]
      })
    });

    this.subscription = this.form.get('user.mailNotificationsEnabled').valueChanges.subscribe(value => {
      if (value) {
        this.form.get('user.email').setValidators(emailValidators);
      }
      else {
        this.form.get('user.email').clearValidators();
      }

      this.form.get('user.email').updateValueAndValidity();
    });

    this.subscription = this.form.get('user.smsNotificationsEnabled').valueChanges.subscribe(value => {
      if (value) {
        this.form.get('user.phone').setValidators(phoneValidators);
      }
      else {
        this.form.get('user.phone').clearValidators();
      }

      this.form.get('user.phone').updateValueAndValidity();
    });
  }

  private mailNotificationValidator(email: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return (control.value && _.isEmpty(email)) ? { invalidMailNotification: true } : null;
    };
  }

  private smsNotificationValidator(phone: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return (control.value && _.isEmpty(phone)) ? { invalidSmsNotification: true } : null;
    };
  }

  private createPrePopulatedUserInterestsFormArray(preferences): FormArray {
    const mappedArray: boolean[] = [];
    Object.values(UserInterest).forEach((interest) => {
      mappedArray.push(preferences.seniorInterests.includes(interest));
    });
    return this.fb.array(mappedArray);
  }

  private createEmptyUserInterestsFormArray(): FormArray {
    const mappedArray = Object.values(UserInterest).map(() => false);
    return this.fb.array(mappedArray);
  }

  private getUserTimezone(): void {
    const timezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const utcOffset: number = moment().utcOffset() / 60;
    this.userTimezone = `UTC${ utcOffset < 0 ? '' : '+' }${ utcOffset } | ${ timezone }`;
  }
}
