<div class="container-fluid px-0 my-6" *ngIf="showFilters || showSearch">
  <div class="row no-gutters">
    <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
      <div id="filters" *ngIf="showFilters">
        <ng-content select="app-data-grid-filters"></ng-content>
      </div>
    </div>
    <div
      class="d-flex col-12 col-lg-4 col-xl-4 text-right p-0 justify-content-end align-items-start gap-2"
    >
      <ng-content select="app-extra-control"></ng-content>
      <div id="searchbox" class="form-group pl-0 mb-0 w-100 w-lg-50" *ngIf="showSearch">
        <input
          class="form-control"
          type="text"
          name="search"
          id="search"
          [ngClass]="{ 'with-content': internalSearchPhrase }"
          placeholder="{{ 'COMPONENT.DATA_GRID.SEARCH' | translate }}"
          #search="ngModel"
          [(ngModel)]="internalSearchPhrase"
          (keyup.enter)="onSearchCommitted($event)"
        />
        <button
          *ngIf="internalSearchPhrase"
          class="search-clear-button btn btn-sm btn-outline-primary"
          type="button"
          (click)="clearSearch()"
        >
          <div class="d-flex flex-row align-items-center justify-content-center">
            <svg-icon class="d-flex" src="asset/image/icon/x.svg"></svg-icon>
          </div>
        </button>
        <svg-icon class="search-icon" src="asset/image/icon/search.svg"></svg-icon>
      </div>
    </div>
  </div>
</div>

<ng-content select="app-filters-labels"></ng-content>
<ng-content select="app-selected-filters"></ng-content>

<ag-grid-angular
  *ngIf="gridOptions && gridOptionsReady"
  [style.visibility]="gridVisible ? 'visible' : 'hidden'"
  class="ag-theme-bootstrap"
  [ngClass]="{ 'with-row-click-selection': (gridOptions.rowSelection !== 'null') && !gridOptions.suppressRowClickSelection }"
  [gridOptions]="gridOptions"
  [rowData]="data"
  [columnDefs]="columns"
  [suppressHorizontalScroll]="true"
  [rowHeight]="60"
  [rowStyle]="{'max-height': '56px'}"
  (gridReady)="onGridReady($event)"
  (rowSelected)="onRowSelected($event)"
  (rowDoubleClicked)="onRowDoubleClicked($event)"
  (selectionChanged)="onSelectionChanged($event)"
  (sortChanged)="onSortChanged($event)"
>
</ag-grid-angular>

<div
  class="container-fluid px-0 mt-4"
  *ngIf="showPagination || showItemsPerPage || showTotalItems"
  [style.visibility]="gridVisible ? 'visible' : 'hidden'"
>
  <div class="row">
    <!-- <div [ngClass]="{ 'col-md-8': useAlternativePagination, 'col-md-6': !useAlternativePagination }" class="border border-warning">
      <div id="paginationInfo" class="form-group mb-0" *ngIf="showItemsPerPage || showTotalItems">
        <span for="entries" *ngIf="showItemsPerPage">{{ 'COMPONENT.DATA_GRID.SHOW' | translate }}</span>
        <ng-select *ngIf="showItemsPerPage" name="entries" id="entries" #entries="ngModel" [searchable]="false" [clearable]="false"
                   class="mx-2"
                   [(ngModel)]="internalItemsPerPage" (ngModelChange)="onItemsPerPageChange($event)">
          <ng-option *ngFor="let itemsPerPageOption of itemsPerPageOptions" [value]="itemsPerPageOption">{{ (itemsPerPageOption === itemsPerPageAllValue) ? ('COMPONENT.DATA_GRID.ITEMS_PER_PAGE_ALL' | translate) : itemsPerPageOption }}</ng-option>
        </ng-select>
        <span *ngIf="showItemsPerPage">{{ 'COMPONENT.DATA_GRID.ITEMS_PER_PAGE' | translate: { itemLabel: (itemLabel | translate) } }}<ng-container *ngIf="showTotalItems">&nbsp;|&nbsp;</ng-container></span>
        <span *ngIf="showTotalItems && (itemTotal > 0) && (internalItemsPerPage !== itemsPerPageAllValue)">
          {{ 'COMPONENT.DATA_GRID.ITEMS_SHOWING' | translate: { itemLow: (criteria.pageNumber * criteria.pageSize) + 1, itemHigh: Math.min((criteria.pageNumber * criteria.pageSize) + criteria.pageSize, itemTotal), itemLabel: (itemLabel | translate), itemTotal: itemTotal, pageTotal: pageTotal } }}
        </span>
      </div>
    </div> -->
    <div [ngClass]="{ 'col-12': useAlternativePagination, 'col-md-12': !useAlternativePagination }">
      <nav
        class="d-flex justify-content-center align-items-center"
        *ngIf="showPagination && (itemTotal > 0) && (internalItemsPerPage !== itemsPerPageAllValue)"
      >
        <pagination
          class="justify-content-center mb-0"
          [ngClass]="{ 'alternative' : useAlternativePagination }"
          [totalItems]="itemTotal"
          [itemsPerPage]="criteria.pageSize"
          [maxSize]="useAlternativePagination ? 1 : 7"
          [rotate]="true"
          [boundaryLinks]="false"
          [customFirstTemplate]="firstTemplate"
          [customLastTemplate]="lastTemplate"
          [customPreviousTemplate]="prevTemplate"
          [customNextTemplate]="nextTemplate"
          [(ngModel)]="internalPage"
          (pageChanged)="onPageChanged($event)"
        >
        </pagination>
      </nav>
    </div>
  </div>
</div>

<ng-template #firstTemplate let-disabled="disabled" let-currentPage="currentPage">
  <svg-icon src="asset/image/icon/chevrons-left.svg"></svg-icon>
</ng-template>

<ng-template #lastTemplate let-disabled="disabled" let-currentPage="currentPage">
  <svg-icon src="asset/image/icon/chevrons-right.svg"></svg-icon>
</ng-template>

<ng-template #prevTemplate let-disabled="disabled" let-currentPage="currentPage">
  <svg-icon src="asset/image/icon/chevron-left.svg"></svg-icon>
</ng-template>

<ng-template #nextTemplate let-disabled="disabled" let-currentPage="currentPage">
  <svg-icon [style.cursor]="'pointer'" src="asset/image/icon/chevron-right.svg"></svg-icon>
</ng-template>
