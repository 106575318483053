<ng-template #headingTemplate>
  <h1>{{ 'VIEW.MAIN.LIVE_CLASSES.DETAILS' | translate }}</h1>

  <div *ngIf="liveClassSeries" class="d-flex flex-wrap gap-4">
    <button type="button" class="btn btn-outline-primary" (click)="onBackClick()">
      <svg-icon src="asset/image/icon/chevron-left.svg"></svg-icon>
      <span>{{ 'COMMON.BACK' | translate }}</span>
    </button>

    <button type="button" class="btn btn-primary" (click)="onEditClick()">
      {{ 'COMMON.EDIT' | translate }}
    </button>
  </div>
</ng-template>

<section class="section">
  <ng-container *ngIf="liveClassSeries">
    <div class="row p-0 m-0">
      <div class="col-2 p-0 d-none d-lg-block">
        <div class="d-flex flex-column">
          <img class="d-block img-fluid mx-0 mb-4 radius-24" [src]="liveClassSeries.thumbnail.defaultAsset.url" alt=""/>
          <div class="align-self-center intensity-wrapper">
            <span
              *ngIf="liveClassSeries.intensity !== IntensityLevel.NOT_APPLICABLE"
              class="my-auto mr-2 intensity-label"
            >{{ "VIEW.MAIN.LIVE_CLASSES.INTENSITY" | translate }}</span
            >
            <ng-container [ngSwitch]="liveClassSeries.intensity">
              <svg-icon
                class="fill"
                *ngSwitchCase="IntensityLevel.BEGINNER"
                src="asset/image/icon/intensity_1.svg"
              ></svg-icon>
              <svg-icon
                class="fill"
                *ngSwitchCase="IntensityLevel.INTERMEDIATE"
                src="asset/image/icon/intensity_2.svg"
              ></svg-icon>
              <svg-icon
                class="fill"
                *ngSwitchCase="IntensityLevel.ADVANCED"
                src="asset/image/icon/intensity_3.svg"
              ></svg-icon>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="col-12 p-0 d-lg-none d-block mt-4 mb-4">
        <div class="d-flex flex-column">
          <img
            class="d-block img-fluid mx-0 mb-4 radius-24 custom-image-style"
            [src]="liveClassSeries.thumbnail.defaultAsset.url"
          />
          <div class="d-flex justify-content-center live-class-intensity-container">
            <span
              *ngIf="liveClassSeries.intensity !== IntensityLevel.NOT_APPLICABLE"
              class="my-auto mr-2 intensity-label"
            >
              {{ "VIEW.MAIN.LIVE_CLASSES.INTENSITY" | translate }}
            </span>
            <ng-container [ngSwitch]="liveClassSeries.intensity">
              <svg-icon
                class="fill"
                *ngSwitchCase="IntensityLevel.BEGINNER"
                src="asset/image/icon/intensity_1.svg"
              ></svg-icon>
              <svg-icon
                class="fill"
                *ngSwitchCase="IntensityLevel.INTERMEDIATE"
                src="asset/image/icon/intensity_2.svg"
              ></svg-icon>
              <svg-icon
                class="fill"
                *ngSwitchCase="IntensityLevel.ADVANCED"
                src="asset/image/icon/intensity_3.svg"
              ></svg-icon>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="pl-lg-6 col-12 col-lg-10 p-0">
        <h2 class="mb-8 word-break">{{ liveClassSeries.title }}</h2>

        <div class="row mb-4">
          <div class="col-sm-6 col-md-3 d-flex gap-0 flex-column mb-4">
            <p class="details-label">{{ "VIEW.MAIN.LIVE_CLASSES.SERIES.START_DATE" | translate }}</p>
            <p>{{ liveClassSeries.startDate | date:'MM/dd/yyyy' }}</p>
          </div>
          <div class="col-sm-6 col-md-3 d-flex gap-0 flex-column mb-4">
            <p class="details-label">{{ "VIEW.MAIN.LIVE_CLASSES.TIME" | translate }}:</p>
            <p>{{ liveClassSeries.startDate | date:'shortTime' }}</p>
          </div>
          <div class="col-sm-6 col-md-3 d-flex gap-0 flex-column mb-4">
            <p class="details-label">{{ "VIEW.MAIN.LIVE_CLASSES.LENGTH" | translate }}:</p>
            <p>{{ liveClassSeries.duration }}</p>
          </div>
          <div class="col-sm-6 col-md-3 d-flex gap-0 flex-column">
            <p class="details-label">{{ "VIEW.MAIN.LIVE_CLASSES.COACH" | translate }}:</p>
            <ng-container *ngFor="let coach of liveClassSeries.coaches">
              <p>{{ coach.user | fullName }}</p>
            </ng-container>
          </div>
        </div>

        <div class="row mb-8">
          <div class="col-sm-6 col-md-3 d-flex gap-0 flex-column mb-4">
            <p class="details-label">{{ "VIEW.MAIN.LIVE_CLASSES.SERIES.END_DATE" | translate }}:</p>
            <p>{{ liveClassSeries.endDate | date:'MM/dd/yyyy' | replaceEmpty:'-' }}</p>
          </div>
          <div class="col-sm-6 col-md-3 d-flex gap-0 flex-column mb-4">
            <p class="details-label">{{ "VIEW.MAIN.LIVE_CLASSES.SERIES.LEFT_CLASSES" | translate }}:</p>
            <p>{{ liveClassSeries.numberOfClassesLeft }}</p>
          </div>
          <div class="col-sm-6 col-md-3 d-flex gap-0 flex-column mb-4">
            <p class="details-label">{{ "VIEW.MAIN.LIVE_CLASSES.SERIES.DAYS" | translate }}:</p>
            <p>{{ liveClassSeries.daysOfWeek | days:'ENUM.DAYS_SHORT' | arrayJoin | replaceEmpty }}</p>
          </div>
          <div class="col-sm-6 col-md-3 d-flex gap-0 flex-column mb-4">
            <p class="details-label">{{ "VIEW.MAIN.LIVE_CLASSES.SERIES.VISIBILITY" | translate }}:</p>
            <p>{{ liveClassSeries.visibility | prefix:'ENUM.LIVE_CLASS_SERIES_VISIBILITY.' | translate }}</p>
          </div>
        </div>

        <div class="row mb-8">
          <div class="col-sm-6 col-md-3 d-flex gap-0 flex-column mb-4">
            <p class="details-label">{{ "VIEW.MAIN.LIVE_CLASSES.CATEGORY" | translate }}:</p>
            <p>{{ "ENUM.CATEGORY." + liveClassSeries.category | translate }}</p>
          </div>
          <div class="col-sm-6 col-md-3 d-flex gap-0 flex-column mb-4">
            <p class="details-label">{{ "VIEW.MAIN.LIVE_CLASSES.LANGUAGE" | translate }}:</p>
            <p>{{ "ENUM.LANGUAGE." + liveClassSeries.language | translate }}</p>
          </div>
          <div *ngIf="liveClassSeries.capacity" class="col-sm-6 col-md-3 d-flex gap-0 flex-column mb-4">
            <p class="details-label">{{ "VIEW.MAIN.LIVE_CLASSES.CAPACITY" | translate }}:</p>
            <p>{{ liveClassSeries.capacity | liveClassCapacity | translate }}</p>
          </div>
          <div class="col-sm-6 col-md-3 d-flex gap-0 flex-column mb-4">
            <p class="details-label">{{ 'VIEW.MAIN.LIVE_CLASSES.RATING' | translate }}:</p>
            <p>
              <app-rating-stars [value]="liveClassSeries.rating"></app-rating-stars>
            </p>
          </div>
        </div>

        <div class="mb-8">
          <p class="details-label">{{ "VIEW.MAIN.LIVE_CLASSES.DESCRIPTION" | translate }}:</p>
          <p>{{ liveClassSeries.description }}</p>
        </div>

        <div class="row no-gutters">
          <div
            *ngIf="liveClassSeries.liveClassSeriesNeeds?.length > 0"
            class="col-sm-6 col-md-3 d-flex gap-0 flex-column flex-wrap mb-4 mb-md-0"
          >
            <p class="details-label">{{ "VIEW.MAIN.LIVE_CLASSES.RECOMMENDED_EQUIPMENT" | translate }}:</p>
            <ul *ngFor="let need of liveClassSeries.liveClassSeriesNeeds" class="mb-1">
              <li>{{ need.need }}</li>
            </ul>
          </div>

          <div
            *ngIf="liveClassSeries.attachments.length > 0"
            class="col-md-9 d-flex gap-2 gap-md-0 flex-column pl-lg-0 pl-md-6 pl-0"
          >
            <span class="mb-4 details-label">{{ "VIEW.MAIN.LIVE_CLASSES.ATTACHMENTS" | translate }}:</span>

            <div class="d-flex flex-column flex-md-row gap-md-6 gap-2 flex-wrap">
              <app-attachments [attachments]="liveClassSeries.attachments"></app-attachments>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</section>
