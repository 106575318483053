import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  ViewChild
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { AssetCreateRequestDTO } from 'app/data/dto/asset/AssetCreateRequestDTO';
import { AssetUploadResponseDTO } from 'app/data/dto/asset/AssetUploadResponseDTO';
import { ImageCreateRequestDTO } from 'app/data/dto/images/ImageCreateRequestDTO';
import { UploadFile } from 'app/data/local/file/UploadFile';
import { FileUtil } from 'app/util/FileUtil';
import { ViewUtil } from 'app/util/ViewUtil';
import { FileUploader } from 'ng2-file-upload';
import { of, Subject } from 'rxjs';
import { catchError, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Attachment } from 'app/data/local/attachment/Attachment';
import { ATTACHMENT_SERVICE_TOKEN, IAttachmentService } from 'app/component/ui/fileUpload/IAttachmentService';
import { ImageService } from 'app/service/ImagesService';
import { AssetService } from 'app/service/AssetService';

@Component({
  selector: 'app-thumbnail-and-files-upload',
  templateUrl: './ThumbnailAndFilesUploadComponent.html',
  styleUrls: [ './ThumbnailAndFilesUploadComponent.scss' ]
})
export class ThumbnailAndFilesUploadComponent<T extends Attachment> implements OnInit, OnDestroy, AfterViewInit {
  private destroy$ = new Subject<void>();
  @Input()
  public formSubmitted = false;

  @Input()
  public thumbnailUrl: string;

  @Input()
  public thumbnailMode = false;

  @Input()
  public attachmentMode = false;

  @Input()
  public thumbnailPlaceholder: string;

  @Input()
  public isOnDemand = false;

  @Input()
  public isThumbnailRequired = true;

  @Input()
  public attachments: T[];

  @Output()
  public imageId: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  public attachmentIds: EventEmitter<number[]> = new EventEmitter<number[]>();

  @Output()
  public startUpload = new EventEmitter<boolean>();

  @Output()
  public thumbnailFormStatus: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('form') public form: NgForm;
  public thumbnailInputSubmitted = false;
  public attachmentSubmitted = false;
  public attachmentIdsArray: number[] = [];
  public uploadStarted = false;
  public uploadThumbnailStarted = false;
  public uploadAttachmentStarted = false;
  public maxSize = 1024 * 1024 * 50; //50 MB
  public allowedExtensionsThumbnail = FileUtil.THUMBNAIL_EXTENSIONS;
  public allowedExtensionsAttachment = FileUtil.SCHEDULED_LIVE_CLASS_ATTACHMENTS_EXTENSIONS;
  public uploadThumbnail: UploadFile = new UploadFile();
  public pendingUploadAttachment: UploadFile = new UploadFile();
  public uploadedAttachments: UploadFile[] = [];
  private thumbnailAssetResponse: AssetUploadResponseDTO;
  private attachmentAssetResponse: AssetUploadResponseDTO;

  constructor(@Optional() @Inject(ATTACHMENT_SERVICE_TOKEN) private readonly attachmentService: IAttachmentService,
              private readonly imageService: ImageService,
              private readonly assetService: AssetService,
              private readonly viewUtil: ViewUtil) {
  }

  ngOnInit(): void {
    this.uploadThumbnail.fileUploader = new FileUploader({ queueLimit: 1 });
    this.pendingUploadAttachment.fileUploader = new FileUploader({ queueLimit: 1 });
    if (this.attachments) {
      this.attachments.forEach((attachment) => {
        this.attachmentIdsArray.push(attachment.id);
      });
    }
    if (this.attachments) {
      this.attachmentIds.emit(this.attachments.map((attachment) => attachment.id));
    }
  }

  public ngAfterViewInit(): void {
    this.form.statusChanges
      .pipe(
        takeUntil(this.destroy$),
        tap(() => this.thumbnailFormStatus.emit(this.form.valid))
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public removeAttachment(id: number): void {
    this.uploadedAttachments = this.uploadedAttachments.filter((attachment) => attachment.id !== id);
    this.attachmentIdsArray = this.attachmentIdsArray.filter((attachmentId) => attachmentId !== id);
    this.attachmentIds.emit(this.attachmentIdsArray);
  }

  public removeAttachmentEdit(id: number): void {
    this.attachments = this.attachments.filter((attachment) => attachment.id !== id);
    const attachmentIdsArray = this.attachments.map((attachment) => attachment.id);
    this.attachmentIds.emit(attachmentIdsArray);
  }

  public onAttachmentChange(file: File): void {
    this.pendingUploadAttachment.reset();
    this.pendingUploadAttachment.file = file;
    this.attachmentSubmitted = true;
    const attachmentAsset = new AssetCreateRequestDTO();
    attachmentAsset.fileName = (this.pendingUploadAttachment.file as File).name;
    if (this.form.form.controls.fileModel.valid) {
      this.createAttachmentAsset(attachmentAsset);
    }
  }

  public onThumbnailChange(file: File): void {
    if (!file) return;
    this.uploadThumbnail.reset();
    this.uploadThumbnail.file = file;
    this.thumbnailInputSubmitted = true;
    const thumbnailAsset = new AssetCreateRequestDTO();
    thumbnailAsset.fileName = (this.uploadThumbnail.file as File).name;
    if (this.form.form.valid) {
      this.createThumbnailAsset(thumbnailAsset);
    }
  }

  private createAttachmentAsset(attachmentAsset: AssetCreateRequestDTO): void {
    this.uploadStarted = true;
    this.uploadAttachmentStarted = true;
    this.startUpload.emit(true);

    this.assetService
      .createAsset(attachmentAsset)
      .pipe(
        takeUntil(this.destroy$),
        tap((asset) => {
          this.attachmentAssetResponse = asset;
        }),
        switchMap(() =>
          this.assetService.uploadAttachmentAsset(this.pendingUploadAttachment.file as File, this.attachmentAssetResponse)
        ),
        switchMap(() => {
          const attachmentCreateRequest = {
            assetId: this.attachmentAssetResponse.id
          };

          return this.attachmentService.createAttachment(attachmentCreateRequest);
        }),
        tap((r) => {
          const newAttachment = new UploadFile();
          newAttachment.file = this.pendingUploadAttachment.file;
          newAttachment.id = r.id;
          this.uploadedAttachments.push(newAttachment);
          this.uploadStarted = false;
          this.uploadAttachmentStarted = false;
          this.attachmentIdsArray.push(r.id);
          this.startUpload.emit(false);
          this.attachmentIds.emit(this.attachmentIdsArray);
        }),
        catchError((error) => {
          this.viewUtil.handleServerError(error);
          this.uploadStarted = false;
          this.uploadAttachmentStarted = false;
          return of(null);
        })
      )
      .subscribe();
  }

  private createThumbnailAsset(thumbnailAsset: AssetCreateRequestDTO): void {
    this.uploadStarted = true;
    this.uploadThumbnailStarted = true;
    this.startUpload.emit(true);
    this.assetService
      .createAsset(thumbnailAsset)
      .pipe(
        takeUntil(this.destroy$),
        tap((asset) => {
          this.thumbnailAssetResponse = asset;
        }),
        switchMap(() =>
          this.assetService.uploadThumbnailAsset(this.uploadThumbnail.file as File, this.thumbnailAssetResponse)
        ),
        switchMap(() => {
          const imageCreateRequestDTO: ImageCreateRequestDTO = {
            originalAssetId: this.thumbnailAssetResponse.id
          };

          return this.imageService.createImages(imageCreateRequestDTO);
        }),
        tap((r) => {
          this.imageId.emit(r.id);
          this.thumbnailUrl = r.defaultAsset.url;
          this.uploadStarted = false;
          this.uploadThumbnailStarted = false;
          this.startUpload.emit(false);
        }),
        catchError((error) => {
          this.viewUtil.handleServerError(error);
          this.uploadStarted = false;
          this.uploadThumbnailStarted = false;
          return of(null);
        })
      )
      .subscribe();
  }
}
