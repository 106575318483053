import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApplicationConfig } from 'app/config/ApplicationConfig';
import { Constant } from 'app/common/Constant';
import { ApplicationModel } from 'app/model/ApplicationModel';

@Injectable()
export class AppLanguageInterceptor implements HttpInterceptor {

  constructor(private applicationModel: ApplicationModel) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.method && (request.url?.indexOf(ApplicationConfig.apiUrl) > -1)) {
      const appLanguageRequest: HttpRequest<any> = request.clone({
        setHeaders: {
          [Constant.HTTP_APP_LANGUAGE_HEADER]: this.applicationModel.language
        }
      });

      return next.handle(appLanguageRequest);
    }
    else {
      return next.handle(request);
    }
  }
}
