import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-angular',
  templateUrl: 'ApplicationComponent.html'
})
export class ApplicationComponent implements OnInit, OnDestroy {

  constructor() {
  }

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
  }

}
