import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { StateService } from '@uirouter/core';
import { Constant } from 'app/common/Constant';
import { State } from 'app/common/State';
import { ServerErrorCode } from 'app/data/enum/ServerErrorCode';
import { AuthModel } from 'app/model/AuthModel';
import { ViewUtil } from 'app/util/ViewUtil';
import { RegistrationType } from 'app/data/enum/user/RegistrationType';

@Component({
  selector: 'app-login',
  templateUrl: 'LoginComponent.html',
  styleUrls: [ 'LoginComponent.scss' ]
})
export class LoginComponent {
  @ViewChild('loginForm')
  private loginForm: NgForm;

  public formData: {
    username: string;
    password: string;
    rememberMe: boolean;
  } = {
    username: null,
    password: null,
    rememberMe: true
  };

  private usernameType: RegistrationType;

  public readonly Constant: typeof Constant = Constant;

  constructor(private stateService: StateService,
              public viewUtil: ViewUtil,
              private authModel: AuthModel) {
  }

  public login(): void {
    this.loginForm.onSubmit(null);

    if (this.loginForm.form.valid) {
      this.usernameType = this.determineUsernameType(this.formData.username);
      this.formData.username = this.formatUsername(this.formData.username, this.usernameType);

      this.authModel.setRememberLogin(this.formData.rememberMe);
      this.authModel.login(this.formData.username, this.formData.password).catch((error) => {
        if (error?.error?.errorCode === ServerErrorCode.NOT_VERIFIED) {
          if (this.usernameType === RegistrationType.EMAIL) {
            this.stateService.go(State.PRELIMINARY.WITHOUT_VERIFICATION, { username: this.formData.username });
          }
          else {
            this.stateService.go(State.PRELIMINARY.REGISTRATION_CONFIRM, { username: this.formData.username });
          }
        }
        else {
          this.viewUtil.handleServerError(error);
        }
      });
    }
  }

  public register(): void {
    this.stateService.go(State.PRELIMINARY.REGISTRATION.START);
  }

  public resetPassword(): void {
    this.stateService.go(State.PRELIMINARY.RESET_PASSWORD_START);
  }

  public onFaqClick(): void {
    this.stateService.go(State.MAIN.FAQ);
  }

  private determineUsernameType(username: string): RegistrationType {
    if (username.match(Constant.EMAIL_ADDRESS_PATTERN)) {
      return RegistrationType.EMAIL;
    }
    else {
      return RegistrationType.PHONE;
    }
  }

  private formatUsername(username: string, usernameType: RegistrationType): string {
    username = username.replace(/ /g, '');

    if (usernameType === RegistrationType.PHONE && !username.startsWith(Constant.PHONE_NUMBER_RULES.prefix)) {
      username = Constant.PHONE_NUMBER_RULES.prefix + username;
    }

    return username;
  }
}
