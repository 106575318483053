import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Transition } from '@uirouter/angular';
import Player, { Options } from '@vimeo/player';
import { Constant } from 'app/common/Constant';
import { State } from 'app/common/State';
import { PopupConfirmationComponent } from 'app/component/ui/popup/PopupConfirmationComponent';
import { ActiveCoachDTO } from 'app/data/dto/coach/ActiveCoachDTO';
import { VideoCreateRequestDTO } from 'app/data/dto/onDemandVideo/VideoCreateRequestDTO';
import { VideoDetailsDTO } from 'app/data/dto/onDemandVideo/VideoDetailsDTO';
import { LiveClassLanguage } from 'app/data/enum/liveClass/LiveClassLanguage';
import { VideoCategory } from 'app/data/enum/video/VideoCategory';
import { VideoSubcategories } from 'app/data/enum/video/VideoSubcategory';
import { OptionItem } from 'app/data/local/generic/OptionItem';
import { StateUtil } from 'app/util/StateUtil';
import { ViewUtil } from 'app/util/ViewUtil';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { of, pipe, Subject } from 'rxjs';
import { catchError, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { OnDemandVideoModel } from 'app/model/OnDemandVideoModel';
import { IntensityLevel } from 'app/data/enum/IntensityLevel';
import { PortalUtil } from 'app/util/PortalUtil';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import { TemplatePortal } from '@angular/cdk/portal';
import { ATTACHMENT_SERVICE_TOKEN } from 'app/component/ui/fileUpload/IAttachmentService';
import { OnDemandVideoAttachmentService } from '../service/OnDemandVideoAttachmentService';

@Component({
  selector: 'app-on-demand-videos-admin-edit',
  templateUrl: './OnDemandVideosAdminEditComponent.html',
  styleUrls: [ './OnDemandVideosAdminEditComponent.scss' ],
  providers: [ { provide: ATTACHMENT_SERVICE_TOKEN, useClass: OnDemandVideoAttachmentService } ]
})
export class OnDemandVideosAdminEditComponent implements OnInit, OnDestroy {
  @ViewChild('headingTemplate', { static: true })
  private readonly headingTemplate: TemplateRef<any>;

  private destroy$: Subject<void> = new Subject<void>();
  public videoId: number;
  public video: VideoDetailsDTO;
  public Constant: typeof Constant = Constant;
  public uploadStarted: boolean = false;

  public videoData: VideoCreateRequestDTO = new VideoCreateRequestDTO();
  // public files = ['File1.pdf', 'File2.pdf', 'File3.pdf'];
  public files: File[] = [];
  //public tabs: string[] = ['Details', 'Ratings', 'Stats'];
  public coaches: ActiveCoachDTO[];
  //TODO 2nd phase?
  public player: Player;
  public subcategories: OptionItem<string>[] = this.viewUtil.createEnumSelectOptions(
    VideoSubcategories,
    'VIDEO_SUBCATEGORY'
  );

  public languages: OptionItem<LiveClassLanguage>[] = this.viewUtil.createEnumSelectOptions(LiveClassLanguage, 'LANGUAGE');
  public intensities: OptionItem<IntensityLevel>[] = this.viewUtil.createEnumSelectOptions(IntensityLevel, 'INTENSITY_LEVEL');
  public categories: OptionItem<VideoCategory>[] = this.viewUtil.createEnumSelectOptions(
    VideoCategory,
    'VIDEO_CATEGORY'
  );

  @ViewChild('form')
  private form: NgForm;

  constructor(private onDemandVideoModel: OnDemandVideoModel,
              private viewUtil: ViewUtil,
              private transition: Transition,
              public stateUtil: StateUtil,
              private modalService: BsModalService,
              private portalUtil: PortalUtil,
              private viewContainerRef: ViewContainerRef) {
    this.videoId = this.transition.params().videoId;
    this.getVideoAndSetupForm();
  }

  public ngOnInit(): void {
    this.portalUtil.attachPortalTo(
      MainLayoutComponent.PORTAL_OUTLET.HEADING,
      new TemplatePortal(this.headingTemplate, this.viewContainerRef)
    );

    this.getCoachesList();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.portalUtil.detachPortalFrom(MainLayoutComponent.PORTAL_OUTLET.HEADING);
  }

  public saveChanges(): void {
    this.form.onSubmit(null);
    if (this.form.form.valid) {
      this.onDemandVideoModel.updateVideo(this.videoData, this.videoId).subscribe(
        (res) => {
          this.viewUtil.showToastSuccess('COMMON.SUCCESS');
          this.stateUtil.goToState(State.MAIN.ADMIN.ON_DEMAND.DETAILS, { videoId: this.videoId });
        },
        (error) => {
          this.viewUtil.handleServerError(error);
        }
      );
    }
  }

  public deleteVideo(): void {
    const modal: BsModalRef = this.modalService.show(PopupConfirmationComponent, {
      initialState: {
        message: 'VIEW.MAIN.USER.DELETE_VIDEO_WARNING',
        messageVariables: { video: this.video.title },
        okText: 'COMMON.YES',
        cancelText: 'COMMON.NO'
      },
      class: 'modal-dialog-centered'
    });
    modal.onHide
      .pipe(
        filter((reason) => reason === PopupConfirmationComponent.POPUP_RESULT_CONFIRM),
        switchMap(() => this.onDemandVideoModel.deleteVideos({ ids: [ this.videoId ] })),
        pipe(
          takeUntil(this.destroy$),
          tap(() => {
            this.viewUtil.showToastSuccess('COMMON.SUCCESS');
            this.stateUtil.goToState(State.MAIN.ADMIN.ON_DEMAND.LIST);
          }),
          catchError((err) => {
            this.viewUtil.handleServerError(err);
            return of(null);
          })
        )
      )
      .subscribe();
  }

  public handleStartUpload(uploadStart: boolean): void {
    this.uploadStarted = uploadStart;
  }

  public updateAttachments(attachments: number[]): void {
    this.videoData.attachmentIds = attachments;
  }

  public onBackClick(): void {
    this.stateUtil.goToState(State.MAIN.ADMIN.ON_DEMAND.LIST);
  }

  private getVideoAndSetupForm(): void {
    this.onDemandVideoModel.getVideo(this.videoId).subscribe((video) => {
      this.video = video;
      this.videoData.imageId = this.video.thumbnail.id;
      this.videoData.attachmentIds = this.video.attachments.map((attachments) => attachments.id);
      this.setupVimeoPlayer();
      this.setupFormData();
    });
  }

  private getCoachesList(): void {
    this.onDemandVideoModel.getActiveCoachList().subscribe((coaches) => {
      this.coaches = coaches;
    });
  }

  private setupVimeoPlayer(): void {
    const options: Options = {
      url: this.video.vimeoVideo.embedUrl,
      byline: false,
      portrait: false,
      responsive: true,
      title: false
    };
    this.player = new Player('vimeo', options);
  }

  private setupFormData(): void {
    Object.assign(this.videoData, this.video);
    this.videoData.coachIds = this.video.coachIds;
    this.videoData.vimeoVideoId = this.video.vimeoVideo.id;
    delete this.videoData['coaches'];
    delete this.videoData['vimeoVideo'];
  }
}
