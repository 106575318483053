import { DateUtil } from 'app/util/DateUtil';
import { Transform } from 'class-transformer';

export class SeniorActivityCriteriaDTO {

  @Transform(DateUtil.dateTimeConversion)
  public dateFrom: Date;

  @Transform(DateUtil.dateTimeConversion)
  public dateTo: Date;
}