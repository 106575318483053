import { Component, Input, OnInit } from '@angular/core';
import { BadgeResponseDTO } from 'app/data/dto/badge/BadgeResponseDTO';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-badge-template',
  templateUrl: 'BadgeTemplateComponent.html',
  styleUrls: [ 'BadgeTemplateComponent.scss' ]
})
export class BadgeTemplateComponent implements OnInit {
  @Input()
  public data: BadgeResponseDTO;

  @Input()
  public description: 'short' | 'long' = 'short';

  @Input()
  public disabled: boolean = false;

  @Input()
  public size: string = '48px';

  public translatedTitle: string;

  constructor(private translateService: TranslateService) {
  }

  public ngOnInit(): void {
    this.generateTranslatedTitle();
  }

  private generateTranslatedTitle(): void {
    const interpolateParams: { [key: string]: string } = {};

    if (this.data.multiple) {
      interpolateParams['multiple'] = this.data.multiple.toString();
    }

    if (this.data.tier) {
      interpolateParams['tier'] = this.translateService.instant(`ENUM.BADGE_TIER.${ this.data.tier }`);
    }

    interpolateParams['category'] = this.data?.liveClassCategory;

    this.translatedTitle = this.translateService.instant(`ENUM.BADGE_KIND.${ this.data.title }`, interpolateParams);
  }
}