import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-avatar',
  templateUrl: 'AvatarComponent.html',
  styleUrls: [ 'AvatarComponent.scss' ]
})
export class AvatarComponent {
  private static readonly DEFAULT_PLACEHOLDER: string = 'asset/image/icon/user.svg';

  @Input()
  public size: string = '60px';

  @Input()
  public circle: boolean = false;

  @Input()
  public indicator: boolean = false;

  @Input()
  public placeholder: string = AvatarComponent.DEFAULT_PLACEHOLDER;

  public isSvg: boolean = true;

  private _src: string;

  @Input()
  public set src(value: string) {
    this._src = value ?? this.placeholder;

    const extension: string = this._src.split('.').pop();
    this.isSvg = extension === 'svg';
  }

  public get src(): string {
    return this._src;
  }
}
