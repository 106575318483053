import { Type } from 'class-transformer';
import { SeniorActivitySummaryDTO } from './SeniorActivitySummaryDTO';
import { ScheduledLiveClassUnratedResponseDTO } from 'app/data/dto/scheduledLiveClass/ScheduledLiveClassUnratedResponseDTO';
import { VideoUnratedResponseDTO } from 'app/data/dto/onDemandVideo/VideoUnratedResponseDTO';

export class SeniorActivityDTO {
  @Type(() => ScheduledLiveClassUnratedResponseDTO)
  public liveClassesToRate: ScheduledLiveClassUnratedResponseDTO[];

  @Type(() => SeniorActivitySummaryDTO)
  public summary: SeniorActivitySummaryDTO;

  @Type(() => VideoUnratedResponseDTO)
  public videosToRate: VideoUnratedResponseDTO[];

  public weekStreak: number;
}
