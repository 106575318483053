<ng-container *ngIf="currentUser">
  <div (click)="onCoachClick()" class="d-flex align-items-center gap-2 cursor-pointer">
    <app-avatar [src]="userClassDetails.coaches[0].user?.avatar?.thumbnailAsset?.url"
                [size]="'40px'"
                [circle]="true">
    </app-avatar>
    <div>
      {{ userClassDetails.coaches[0].user | fullName }}<span
      *ngIf="userClassDetails.coaches.length > 1"
    >,</span
    >
    </div>
    <div *ngIf="userClassDetails.coaches.length > 1" class="text-semibold">
      <p class="m-0">...</p>
    </div>
  </div>
</ng-container>
