import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { Transition } from '@uirouter/angular';

import uitoolkit from "@zoom/videosdk-ui-toolkit";
import { ZoomConfigDTO } from 'app/component/ui/zoom/data/ZoomConfigDTO';
import { IZoomServiceInterface, ZOOM_SERVICE_TOKEN } from 'app/component/ui/zoom/IZoomServiceInterface';

@Component({
  selector: 'app-zoom-complete-view',
  templateUrl: './zoom-complete-view.component.html',
  styleUrls: ['./zoom-complete-view.component.scss'],
})
export class ZoomCompleteViewComponent {

  sessionContainer: HTMLElement;
  inSession: boolean = false
  config: ZoomConfigDTO;


  constructor(@Inject(ZOOM_SERVICE_TOKEN) private zoomService: IZoomServiceInterface,
              private transition: Transition,
              @Inject(DOCUMENT) document: any) {

  }

  getVideoSDKJWT() {
    const meetingId = this.transition.params().id;
    this.zoomService.getRoomData(meetingId).then((data) => {
      this.config = {
          videoSDKJWT: data.jwt,
          sessionName: data.sessionName,
          userName: data.userIdentity,
          features: ['preview', 'video', 'audio', 'settings', 'users', 'chat', 'share'],
          options: { init: {
              enforceMultipleVideos: true,
            }, audio: {}, video: {}, share: {}},
          virtualBackground: {
            allowVirtualBackground: true,
            allowVirtualBackgroundUpload: true,
            virtualBackgrounds: ['https://images.unsplash.com/photo-1715490187538-30a365fa05bd?q=80&w=1945&auto=format&fit=crop']
          }
        };
      this.sessionContainer = document.getElementById('sessionContainer')

      this.joinSession()
    })

  }

  joinSession() {
    uitoolkit.joinSession(this.sessionContainer, this.config)
    this.inSession = true

    uitoolkit.onSessionClosed(this.sessionClosed)
  }

  sessionClosed = (() => {
    uitoolkit.closeSession(this.sessionContainer)
    this.inSession = false
  })
}