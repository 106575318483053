<ng-template #headingTemplate>
  <h1>{{ 'VIEW.MAIN.LIVE_CLASSES.DETAILS' | translate }}</h1>

  <button type="button" class="btn btn-outline-primary" (click)="onBackClick()">
    <svg-icon src="asset/image/icon/chevron-left.svg"></svg-icon>
    <span>{{ 'COMMON.BACK' | translate }}</span>
  </button>
</ng-template>

<section class="section">
  <ng-container *ngIf="scheduledLiveClass$ | async as scheduledLiveClass">
    <div class="row p-0 m-0">
      <div class="col-2 p-0 d-none d-lg-block">
        <div class="d-flex flex-column">
          <img
            class="d-block img-fluid mx-0 mb-4 radius-24"
            [src]="scheduledLiveClass.thumbnail.defaultAsset.url"
            alt=""
          />
          <div class="align-self-center d-flex align-items-center intensity-wrapper">
            <span
              *ngIf="scheduledLiveClass.intensity !== IntensityLevel.NOT_APPLICABLE"
              class="mr-2 intensity-label"
            >{{ "VIEW.MAIN.LIVE_CLASSES.INTENSITY" | translate }}</span
            >
            <ng-container [ngSwitch]="scheduledLiveClass.intensity">
              <svg-icon class="fill" *ngSwitchCase="IntensityLevel.NOT_APPLICABLE"></svg-icon>
              <svg-icon
                class="fill"
                *ngSwitchCase="IntensityLevel.BEGINNER"
                src="asset/image/icon/intensity_1.svg"
              ></svg-icon>
              <svg-icon
                class="fill"
                *ngSwitchCase="IntensityLevel.INTERMEDIATE"
                src="asset/image/icon/intensity_2.svg"
              ></svg-icon>
              <svg-icon
                class="fill"
                *ngSwitchCase="IntensityLevel.ADVANCED"
                src="asset/image/icon/intensity_3.svg"
              ></svg-icon>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="col-12 p-0 d-lg-none d-block mt-4 mb-4">
        <div class="d-flex flex-column">
          <img
            class="d-block img-fluid mx-0 mb-4 radius-24 custom-image-style"
            [src]="scheduledLiveClass.thumbnail.defaultAsset.url"
          />
          <div class="d-flex justify-content-center live-class-intensity-container">
            <span
              *ngIf="scheduledLiveClass.intensity !== IntensityLevel.NOT_APPLICABLE"
              class="my-auto mr-2 intensity-label"
            >{{ "VIEW.MAIN.LIVE_CLASSES.INTENSITY" | translate }}</span
            >
            <ng-container [ngSwitch]="scheduledLiveClass.intensity">
              <svg-icon class="fill" *ngSwitchCase="IntensityLevel.NOT_APPLICABLE"></svg-icon>
              <svg-icon
                class="fill"
                *ngSwitchCase="IntensityLevel.BEGINNER"
                src="asset/image/icon/intensity_1.svg"
              ></svg-icon>
              <svg-icon
                class="fill"
                *ngSwitchCase="IntensityLevel.INTERMEDIATE"
                src="asset/image/icon/intensity_2.svg"
              ></svg-icon>
              <svg-icon
                class="fill"
                *ngSwitchCase="IntensityLevel.ADVANCED"
                src="asset/image/icon/intensity_3.svg"
              ></svg-icon>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="pl-lg-6 col-12 col-lg-10 p-0">
        <h2 class="margin-bottom-32 word-break">{{ scheduledLiveClass.title }}</h2>

        <div class="row mb-4">
          <div class="col-sm-6 col-md-3 d-flex gap-0 flex-column mb-4">
            <p class="details-label">{{ "VIEW.MAIN.LIVE_CLASSES.DATE" | translate }}</p>
            <p>{{ scheduledLiveClass.startDate | date:'MM/dd/yyyy' }}</p>
          </div>
          <div class="col-sm-6 col-md-3 d-flex gap-0 flex-column mb-4">
            <p class="details-label">{{ "VIEW.MAIN.LIVE_CLASSES.TIME" | translate }}:</p>
            <p>{{ scheduledLiveClass.startDate | date:'shortTime' }}</p>
          </div>
          <div class="col-sm-6 col-md-3 d-flex gap-0 flex-column mb-4">
            <p class="details-label">{{ "VIEW.MAIN.LIVE_CLASSES.LENGTH" | translate }}:</p>
            <p>{{ scheduledLiveClass.duration }}</p>
          </div>
          <div class="col-sm-6 col-md-3 d-flex gap-0 flex-column">
            <p class="details-label">{{ "VIEW.MAIN.LIVE_CLASSES.COACH" | translate }}:</p>
            <p *ngFor="let user of scheduledLiveClass.coaches">{{ user.user| fullName }}</p>
          </div>
        </div>

        <div class="row margin-bottom-32">
          <div class="col-sm-6 col-md-3 d-flex gap-0 flex-column mb-4">
            <p class="details-label">{{ "VIEW.MAIN.LIVE_CLASSES.CATEGORY" | translate }}:</p>
            <p>{{ "ENUM.CATEGORY." + scheduledLiveClass.category | translate }}</p>
          </div>
          <div class="col-sm-6 col-md-3 d-flex gap-0 flex-column mb-4">
            <p class="details-label">{{ "VIEW.MAIN.LIVE_CLASSES.LANGUAGE" | translate }}:</p>
            <p>{{ "ENUM.LANGUAGE." + scheduledLiveClass.language | translate }}</p>
          </div>
          <div
            *ngIf="scheduledLiveClass.capacity"
            class="col-sm-6 col-md-3 d-flex gap-0 flex-column mb-4"
          >
            <p class="details-label">{{ "VIEW.MAIN.LIVE_CLASSES.CAPACITY" | translate }}:</p>
            <p>{{ scheduledLiveClass.capacity | liveClassCapacity | translate }}</p>
          </div>
        </div>

        <div class="margin-bottom-32">
          <p class="details-label">{{ "VIEW.MAIN.LIVE_CLASSES.DESCRIPTION" | translate }}:</p>
          <p>{{ scheduledLiveClass.description }}</p>
        </div>

        <div class="row no-gutters">
          <div
            *ngIf="scheduledLiveClass.scheduledLiveClassNeeds.length > 0"
            class="col-sm-6 col-md-3 d-flex gap-0 flex-column flex-wrap mb-4 mb-md-0"
          >
            <p class="details-label">{{ "VIEW.MAIN.LIVE_CLASSES.RECOMMENDED_EQUIPMENT" | translate }}:</p>
            <ul *ngFor="let need of scheduledLiveClass.scheduledLiveClassNeeds" class="mb-1">
              <li>{{ need.need }}</li>
            </ul>
          </div>

          <div class="col-md-9 d-flex gap-2 gap-md-0 flex-column pl-lg-0 pl-md-6 pl-0">
            <ng-container *ngIf="scheduledLiveClass.attachments.length > 0">
              <span class="mb-4 details-label">{{ "VIEW.MAIN.LIVE_CLASSES.ATTACHMENTS" | translate }}:</span>
              <div class="d-flex flex-column flex-md-row gap-md-6 gap-2 flex-wrap">
                <app-attachments [attachments]="scheduledLiveClass.attachments"></app-attachments>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</section>
