import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { AdminModel } from 'app/model/AdminModel';
import { SeniorPageCriteriaDTO } from 'app/data/dto/senior/SeniorPageCriteriaDTO';
import { IBasicName } from 'app/data/local/common/IBasicName';
import { cloneDeep } from 'lodash';
import { SubscriptionType } from 'app/data/enum/subscription/SubscriptionType';

@Component({
  selector: 'app-user-filter',
  templateUrl: './UserFilterComponent.html',
  styleUrls: [ './UserFilterComponent.scss' ]
})
export class UserFilterComponent {
  @Input() set criteria(value: SeniorPageCriteriaDTO) {
    this._criteria = cloneDeep(value);
  }

  @Output() criteriaChange: EventEmitter<SeniorPageCriteriaDTO> = new EventEmitter<SeniorPageCriteriaDTO>();

  public modalRef?: BsModalRef;

  public organizationOptions$: Observable<IBasicName[]> = this.adminModel.getAllOrganizationList();
  public subscriptionTypeOptions: string[] = Object.keys(SubscriptionType);

  public SubscriptionType: typeof SubscriptionType = SubscriptionType;

  public _criteria: SeniorPageCriteriaDTO;

  constructor(private readonly modalService: BsModalService,
              private readonly adminModel: AdminModel) { }

  public openFilterModal(template: TemplateRef<void>): void {
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered'
    });
  }

  public onSubscriptionTypeChange(): void {
    if (this._criteria.subscriptionType === SubscriptionType.RETAIL) {
      this._criteria.organizations = undefined;
    }
  }

  public save(): void {
    this.updateCriteria(this._criteria);
    this.modalRef.hide();
  }

  public clearFilters(): void {
    this._criteria.organizations = undefined;
    this._criteria.subscriptionType = undefined;

    this.updateCriteria(this._criteria);
    this.modalRef.hide();
  }

  private updateCriteria(criteria: SeniorPageCriteriaDTO): void {
    criteria.pageNumber = 0;
    this.criteriaChange.next(criteria);
  }

}
