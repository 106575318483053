import { Component, OnInit } from '@angular/core';
import { RouteTab } from 'app/component/ui/routeTabs/RouteTab';
import { State } from 'app/common/State';
import { AccessControlModel } from 'app/model/AccessControlModel';

@Component({
  selector: 'app-on-demand-tabs',
  templateUrl: './OnDemandTabsComponent.html',
  styleUrls: [ './OnDemandTabsComponent.scss' ]
})
export class OnDemandTabsComponent implements OnInit {
  public tabs: RouteTab[] = [
    {
      name: 'VIEW.MAIN.ON_DEMAND.TABS.ALL',
      state: State.MAIN.SENIOR.ON_DEMAND.VIDEO.LIST
    }
  ];

  constructor(private readonly accessControlModel: AccessControlModel) { }

  ngOnInit(): void {
    if (this.accessControlModel.isFullAccess) {
      this.tabs = [
        ...this.tabs,
        {
          name: 'VIEW.MAIN.ON_DEMAND.TABS.FAVORITES',
          state: State.MAIN.SENIOR.ON_DEMAND.VIDEO.FAVORITES
        },
        {
          name: 'VIEW.MAIN.ON_DEMAND.TABS.PROGRAMS',
          state: State.MAIN.SENIOR.ON_DEMAND.PROGRAM.LIST
        },
        {
          name: 'VIEW.MAIN.ON_DEMAND.TABS.WATCH_PARTIES',
          state: State.MAIN.SENIOR.ON_DEMAND.WATCH_PARTY.LIST
        }
      ];
    }
  }

}
