import { DateUtil } from 'app/util/DateUtil';
import { Exclude, Transform, Type } from 'class-transformer';
import { ImageResponseDTO } from '../images/ImageResponseDTO';
import _ from 'lodash';

export class CoachUserDetailsProfileResponseAdminDTO {
  @Type(() => ImageResponseDTO)
  avatar?: ImageResponseDTO;
  email: string;
  enabled: boolean;
  firstName: string;
  lastName: string;
  phone: string;
  @Transform(DateUtil.dateTimeConversion)
  createdDate: Date;

  @Exclude()
  public get fullName(): string {
    return _.trim(`${ this.firstName ?? '' } ${ this.lastName ?? '' }`);
  }
}
