import { VideoCategory } from 'app/data/enum/video/VideoCategory';
import { Type } from 'class-transformer';
import { ImageResponseDTO } from 'app/data/dto/images/ImageResponseDTO';
import { CoachEssentialsDTO } from 'app/data/dto/coach/CoachEssentialsDTO';
import { VideoProgramSeniorResponseDTO } from 'app/data/dto/programs/VideoProgramSeniorResponseDTO';
import { IntensityLevel } from 'app/data/enum/IntensityLevel';

export class ProgramSeniorResponseDTO {
  public category: VideoCategory;

  @Type(() => CoachEssentialsDTO)
  public coaches: CoachEssentialsDTO[];

  public description: string;

  public intensity: IntensityLevel;

  public rating: number;

  @Type(() => ImageResponseDTO)
  public thumbnail: ImageResponseDTO;

  public title: string;

  @Type(() => VideoProgramSeniorResponseDTO)
  public videoPrograms: VideoProgramSeniorResponseDTO[];

  public viewCount: number;
}