import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import { TemplatePortal } from '@angular/cdk/portal';
import { PortalUtil } from 'app/util/PortalUtil';
import { State } from 'app/common/State';
import { WatchPartyPlannedListComponent } from 'app/component/view/main/onDemand/senior/view/watchParty/component/plannedList/WatchPartyPlannedListComponent';
import { WatchPartyAvailableListComponent } from 'app/component/view/main/onDemand/senior/view/watchParty/component/availableList/WatchPartyAvailableListComponent';
import { WatchPartyModel } from 'app/model/WatchPartyModel';
import { UserModel } from 'app/model/UserModel';

@Component({
  selector: 'app-watch-party-list',
  templateUrl: './WatchPartyListComponent.html'
})
export class WatchPartyListComponent implements OnInit, OnDestroy {
  @ViewChild(WatchPartyPlannedListComponent)
  private readonly watchPartyPlannedListComponent: WatchPartyPlannedListComponent;

  @ViewChild(WatchPartyAvailableListComponent)
  private readonly watchPartyAvailableListComponent: WatchPartyAvailableListComponent;

  @ViewChild('headingTemplate', { static: true })
  private readonly headingTemplate: TemplateRef<any>;

  public State: typeof State = State;

  constructor(private readonly portalUtil: PortalUtil,
              public readonly watchPartyModel: WatchPartyModel,
              public userModel: UserModel,
              private readonly viewContainerRef: ViewContainerRef) { }

  public ngOnInit(): void {
    this.portalUtil.attachPortalTo(
      MainLayoutComponent.PORTAL_OUTLET.HEADING,
      new TemplatePortal(this.headingTemplate, this.viewContainerRef)
    );
  }

  public ngOnDestroy(): void {
    this.portalUtil.detachPortalFrom(MainLayoutComponent.PORTAL_OUTLET.HEADING);
  }

  public onAvailableListRefresh(): void {
    this.watchPartyPlannedListComponent.loadData();
  }

  public onPlannedListRefresh(): void {
    this.watchPartyAvailableListComponent.loadData();
  }

}
