<app-content-and-image-column-layout *ngIf="userRegistration" [imageSrc]="'asset/image/register-background.jpg'">
  <div id="content" class="p-4 d-flex flex-column">
    <img class="logo" src="../../../../../../asset/image/logo.png"/>

    <div class="d-flex justify-content-between w-100">
      <h4 class="mr-2">{{ 'VIEW.PRELIMINARY.REGISTRATION.GETTING_STARTED' | translate }}</h4>
      <h4 *ngIf="currentStep && noOfSteps">step {{ currentStep }} of {{ noOfSteps }}</h4>
    </div>
    <h1 class="mb-6">{{ 'VIEW.PRELIMINARY.REGISTRATION.CREATE_ACCOUNT' | translate }}</h1>

    <form
      #registrationForm="ngForm"
      class="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2 row-cols-xl-2"
      novalidate
      (keyup.enter)="goToNextStep()"
    >
      <div class="form-group col">
        <label for="firstName">{{ 'VIEW.PRELIMINARY.REGISTRATION.FIRST_NAME' | translate }}</label>
        <input
          type="text"
          id="firstName"
          name="firstName"
          #firstName="ngModel"
          class="form-control"
          placeholder="{{ 'VIEW.PRELIMINARY.REGISTRATION.FIRST_NAME' | translate }}"
          [ngClass]="{'is-invalid': registrationForm.submitted && firstName.invalid, 'is-valid': registrationForm.submitted && firstName.valid }"
          [(ngModel)]="userRegistration.user.firstName"
          required
          [appMaxLength]="Constant.TEXT_MAX_LENGTH_150"
        />
        <div class="invalid-feedback" *ngIf="registrationForm.submitted && firstName.invalid">
          <div *ngIf="firstName.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
          <div *ngIf="firstName.errors['maxLength']">
            {{ 'VALIDATION.CHAR_MAX' | translate: { max: Constant.TEXT_MAX_LENGTH_150 } }}
          </div>
        </div>
      </div>
      <div class="form-group col">
        <label for="lastName">{{ 'VIEW.PRELIMINARY.REGISTRATION.LAST_NAME' | translate }}</label>
        <input
          type="text"
          id="lastName"
          name="lastName"
          #lastName="ngModel"
          class="form-control"
          placeholder="{{ 'VIEW.PRELIMINARY.REGISTRATION.LAST_NAME' | translate }}"
          [ngClass]="{'is-invalid': registrationForm.submitted && lastName.invalid, 'is-valid': registrationForm.submitted && lastName.valid }"
          [(ngModel)]="userRegistration.user.lastName"
          required
          [appMaxLength]="Constant.TEXT_MAX_LENGTH_150"
        />
        <div class="invalid-feedback" *ngIf="registrationForm.submitted && lastName.invalid">
          <div *ngIf="lastName.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
          <div *ngIf="lastName.errors['maxLength']">
            {{ 'VALIDATION.CHAR_MAX' | translate: { max: Constant.TEXT_MAX_LENGTH_150 } }}
          </div>
        </div>
      </div>
      <div class="form-group col">
        <label for="dateOfBirth">{{ 'VIEW.PRELIMINARY.REGISTRATION.DATE_OF_BIRTH' | translate }}</label>
        <input
          type="text"
          id="dateOfBirth"
          name="dateOfBirth"
          #dateOfBirth="ngModel"
          class="form-control full-width"
          placeholder="{{ 'VIEW.PRELIMINARY.REGISTRATION.DATE_OF_BIRTH' | translate }}"
          [ngClass]="{'is-invalid': registrationForm.submitted && dateOfBirth.invalid, 'is-valid': registrationForm.submitted && dateOfBirth.valid }"
          [(ngModel)]="userRegistration.dateOfBirth"
          required
          [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY', containerClass: 'theme-custom',  maxDate: maxDateOfBirth}"
          [appMaxDate]="maxDateOfBirth"
          bsDatepicker
        />
        <div class="invalid-feedback" *ngIf="registrationForm.submitted && dateOfBirth.invalid">
          <div *ngIf="dateOfBirth.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
          <div *ngIf="dateOfBirth.errors['maxDate']">
            {{ 'VALIDATION.DATE_MAX' | translate: { dateString: maxDateOfBirth.toDateString() } }}
          </div>
        </div>
      </div>
      <div class="form-group col">
        <div class="d-flex justify-content-between">
          <label for="gender">{{ 'VIEW.PRELIMINARY.REGISTRATION.GENDER' | translate }}</label>
        </div>
        <ng-select
          type="text"
          id="gender"
          name="gender"
          #gender="ngModel"
          placeholder="{{ 'VIEW.PRELIMINARY.REGISTRATION.GENDER' | translate }}"
          [(ngModel)]="userRegistration.gender"
        >
          <ng-option *ngFor="let option of genderList" [value]="option.value">{{ option.label | translate }}</ng-option>
        </ng-select>
        <div class="text-sm-light color-orange ml-4 mt-1">* {{ 'COMMON.OPTIONAL' | translate }}</div>
      </div>
      <div class="form-group col">
        <label for="phoneNumber">{{ 'VIEW.PRELIMINARY.REGISTRATION.PHONE_NUMBER' | translate }}</label>
        <input
          type="text"
          id="phoneNumber"
          name="phoneNumber"
          #phoneNumber="ngModel"
          class="form-control"
          placeholder="{{ 'VIEW.PRELIMINARY.REGISTRATION.PHONE_NUMBER' | translate }}"
          [ngClass]="{
            'is-invalid': registrationForm.submitted && phoneNumber.invalid,
            'is-valid': userRegistration.user.phone && registrationForm.submitted && phoneNumber.valid
          }"
          [(ngModel)]="userRegistration.user.phone"
          [showMaskTyped]="true"
          [prefix]="Constant.PHONE_NUMBER_RULES.prefix + ' '"
          [shownMaskExpression]="Constant.PHONE_NUMBER_RULES.expression"
          [mask]="Constant.PHONE_NUMBER_RULES.mask"
          [pattern]="Constant.PHONE_NUMBER_RULES.pattern"
        />
        <div class="invalid-feedback" *ngIf="registrationForm.submitted && phoneNumber.invalid">
          <div *ngIf="phoneNumber.errors['pattern']">{{ 'VALIDATION.PHONE' | translate }}</div>
        </div>
        <div class="text-sm-light color-orange ml-4 mt-1">* {{ 'COMMON.OPTIONAL' | translate }}</div>
      </div>
    </form>
    <div class="d-flex flex-wrap justify-content-between mt-6">
      <div class="d-flex justify-content-start align-items-center">
        <span>{{ 'VIEW.PRELIMINARY.REGISTRATION.ALREADY_HAVE_ACCOUNT' | translate }}</span>
        <button class="btn btn-light login-btn" (click)="backToLogin()">
          {{ 'VIEW.PRELIMINARY.LOGIN.LOGIN' | translate }}
        </button>
      </div>
      <div class="d-flex justify-content-end control-buttons">
        <button class="btn btn-outline-primary mr-2 step-btn"
                (click)="goBack()">{{ 'COMMON.BACK' | translate }}
        </button>
        <button class="btn btn-primary step-btn" (click)="goToNextStep()">{{ 'COMMON.NEXT' | translate }}</button>
      </div>
    </div>
  </div>
</app-content-and-image-column-layout>
