import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StateService } from '@uirouter/core';
import { State } from 'app/common/State';
import { ProgramSeniorDetailsPageDTO } from 'app/data/dto/programs/ProgramSeniorDetailsPageDTO';
import { BsModalService } from 'ngx-bootstrap/modal';

import { ProgramItemDetailsModalComponent } from 'app/component/view/main/onDemand/senior/view/program/modal/ProgramItemDetailsModalComponent';
import { Constant } from 'app/common/Constant';

@Component({
  selector: 'app-programs-list-item',
  templateUrl: './ProgramListItemComponent.html',
  styleUrls: [ './ProgramListItemComponent.scss' ]
})
export class ProgramListItemComponent {
  @Input()
  public data: ProgramSeniorDetailsPageDTO;

  @Output()
  public favoriteClicked: EventEmitter<any> = new EventEmitter();

  public displayOverlay: boolean = false;
  public mappedIntensity: number;

  public readonly Constant: typeof Constant = Constant;

  constructor(public modalService: BsModalService,
              private stateService: StateService) {
  }

  public changeIsFavorite(): void {
    this.favoriteClicked.emit(null);
  }

  public onDetailsClick(): void {
    this.modalService.show(ProgramItemDetailsModalComponent, {
      initialState: {
        data: this.data
      },
      class: 'modal-dialog-centered program-modal-details'
    });
  }

  public onPlayClick(): void {
    this.stateService.go(State.MAIN.SENIOR.ON_DEMAND.PROGRAM.PLAY, { id: this.data.id });
  }
}
