import { IAttachmentService } from 'app/component/ui/fileUpload/IAttachmentService';
import { Asset, Attachment } from 'app/data/local/attachment/Attachment';
import { Observable } from 'rxjs';
import { ScheduledLiveClassService } from 'app/service/ScheduledLiveClassService';
import { Injectable } from '@angular/core';

@Injectable()
export class LiveClassSingleFileUploadService implements IAttachmentService {
  constructor(private readonly scheduledLiveClassService: ScheduledLiveClassService) {}

  public createAttachment(asset: Asset): Observable<Attachment> {
    return this.scheduledLiveClassService.createAttachmentAsset(asset);
  }

}