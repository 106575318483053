import { DateUtil } from 'app/util/DateUtil';
import { IdentifiableObjectDTO } from '../IdentifiableObjectDTO';
import { Transform, Type } from 'class-transformer';
import { UserGender } from 'app/data/enum/account/UserGender';
import { UserDetailsPageDTO } from '../user/UserDetailsPageDTO';

export class SeniorDetailsPageDTO extends IdentifiableObjectDTO<number> {
  @Transform(DateUtil.dateConversion)
  public dateOfBirth: Date;

  public gender?: UserGender;

  @Type(() => UserDetailsPageDTO)
  public user: UserDetailsPageDTO;
}