import { WatchPartyParticipantEnrolmentStatus } from 'app/data/dto/watchParty/WatchPartyParticipantEnrolmentStatus';
import { ImageResponseDTO } from 'app/data/dto/images/ImageResponseDTO';

export class WatchPartyParticipantDTO {
  public avatar: ImageResponseDTO;

  public enrollmentStatus: WatchPartyParticipantEnrolmentStatus;

  public firstName: string;

  public lastName: string;
}