import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '@uirouter/core';
import { ValueFormatterParams } from 'ag-grid-community';
import { State } from 'app/common/State';
import { DataGridAction } from 'app/component/ui/dataGrid/DataGridAction';
import { PageCriteriaDTO } from 'app/data/dto/PageCriteriaDTO';
import { PageDTO } from 'app/data/dto/PageDTO';
import { CoachDTO } from 'app/data/dto/coach/CoachDTO';
import { ScheduledLiveClassCoachPageCriteriaDTO } from 'app/data/dto/scheduledLiveClass/coach/ScheduledLiveClassCoachPageCriteriaDTO';
import { ScheduledLiveClassCoachDetailsResponseDTO } from 'app/data/dto/scheduledLiveClass/coach/ScheduledLiveClassCoachDetailsResponseDTO';
import { ScheduledLiveClassCoachDetailsPageDTO } from 'app/data/dto/scheduledLiveClass/coach/ScheduledLiveClassCoachDetailsPageDTO';
import { Language } from 'app/data/enum/Language';
import { LiveClassCategory } from 'app/data/enum/liveClass/LiveClassCategory';
import { DayOfWeek } from 'app/data/enum/DayOfWeek';
import { LiveClassesDaysAhead } from 'app/data/enum/liveClass/LiveClassesDaysAhead';
import { LiveClassesTabs } from 'app/data/enum/liveClass/LiveClassesTabs';
import { TimeOfDay } from 'app/data/enum/TimeOfDay';
import { OptionItem } from 'app/data/local/generic/OptionItem';
import { ApplicationModel } from 'app/model/ApplicationModel';
import { CoachModel } from 'app/model/CoachModel';
import { ObjectUtil } from 'app/util/ObjectUtil';
import { ViewUtil } from 'app/util/ViewUtil';
import { PrefixPipe } from 'app/util/pipe/PrefixPipe';
import * as _ from 'lodash';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, of, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

import { LiveClassesListActionCellRendererComponent } from '../components/cellRenderer/LiveClassesListActionCellRendererComponent';
import { LiveClassesFilterComponent } from '../components/filter/LiveClassesFilterComponent';
import { UserModel } from 'app/model/UserModel';
import { UserDTO } from 'app/data/dto/user/UserDTO';
import { DataGridTemplateCellRendererComponent } from 'app/component/ui/dataGrid/cellRenderer/DataGridTemplateCellRendererComponent';
import { IntensityLevel } from 'app/data/enum/IntensityLevel';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import { TemplatePortal } from '@angular/cdk/portal';
import { PortalUtil } from 'app/util/PortalUtil';
import { BaseCriteriaDTO } from 'app/data/dto/BaseCriteriaDTO';

@Component({
  selector: 'app-live-classes-list-coach',
  templateUrl: './LiveClassesListCoachComponent.html',
  styleUrls: [ './LiveClassesListCoachComponent.scss' ]
})
export class LiveClassesListCoachComponent implements OnInit, OnDestroy {
  @ViewChild('headingTemplate', { static: true })
  private readonly headingTemplate: TemplateRef<any>;

  private destroy$: Subject<void> = new Subject<void>();
  private _activeTab: string;
  public selectedDaysAhead = LiveClassesDaysAhead;
  public bsConfig: Partial<BsDatepickerConfig>;
  public page$: Observable<PageDTO<ScheduledLiveClassCoachDetailsPageDTO>>;
  public criteria: ScheduledLiveClassCoachPageCriteriaDTO = new ScheduledLiveClassCoachPageCriteriaDTO();
  public consecutiveDays: string;
  public selectedItems: ScheduledLiveClassCoachDetailsPageDTO[] = [];
  public selectedDate: Date = new Date();
  public currentUser: UserDTO;
  public readonly tabs: string[] = [ LiveClassesTabs.MY_CLASSES, LiveClassesTabs.ALL_CLASSES ];

  public liveClassesTab: typeof LiveClassesTabs = LiveClassesTabs;
  public coaches: OptionItem<CoachDTO>[] = [];
  public categories: OptionItem<LiveClassCategory>[] = this.viewUtil.createEnumSelectOptions(
    LiveClassCategory,
    'CATEGORY'
  );

  public intensities: OptionItem<IntensityLevel>[] = this.viewUtil.createEnumSelectOptions(
    IntensityLevel,
    'INTENSITY_LEVEL'
  );

  public days: OptionItem<DayOfWeek>[] = this.viewUtil.createEnumSelectOptions(DayOfWeek, 'DAYS');
  timeOfTheDay: OptionItem<TimeOfDay>[] = this.viewUtil.createEnumSelectOptions(
    TimeOfDay,
    'TIME_OF_THE_DAY'
  );

  public languages: OptionItem<Language>[] = this.viewUtil.createEnumSelectOptions(Language, 'LANGUAGE');
  public daysAheadSelectList: OptionItem<LiveClassesDaysAhead>[] = this.viewUtil.createEnumSelectOptions(
    LiveClassesDaysAhead,
    'DAYS_AHEAD'
  );

  public DataGridTemplateCellRendererComponent: typeof DataGridTemplateCellRendererComponent =
    DataGridTemplateCellRendererComponent;

  public ActionCellRendererComponent: typeof LiveClassesListActionCellRendererComponent = LiveClassesListActionCellRendererComponent;

  public getLabelForKeyValueBind: (key: string, value: string) => string = this.getLabelForKeyValue.bind(this);

  public friendlyNames = {
    anyOfCategories: 'COMPONENT.CLASS_FILTERS.CLASS_CATEGORY',
    anyOfIntensities: 'COMPONENT.CLASS_FILTERS.INTENSITY',
    daysOfWeek: 'COMPONENT.CLASS_FILTERS.DAY_OF_THE_WEEK',
    anyOfCoachIds: 'COMPONENT.CLASS_FILTERS.COACH',
    anyOfLanguages: 'COMPONENT.CLASS_FILTERS.LANGUAGE'
  };

  public startDateValueFormatter: (params: ValueFormatterParams) => any = (params: ValueFormatterParams) =>
    this.viewUtil.dataGridPipeValueFormatter(params, DatePipe, 'MM/dd/yyyy');

  public startTimeValueFormatter: (params: ValueFormatterParams) => any = (params: ValueFormatterParams) => {
    return this.viewUtil.dataGridPipeValueFormatter(params, DatePipe, 'h:mm a');
  };

  public dateTimeValueFormatter: (params: ValueFormatterParams) => any = (params: ValueFormatterParams) => {
    const date: string = this.viewUtil.dataGridPipeValueFormatter(params, DatePipe, 'MM/dd/yyyy');
    const time: string = this.viewUtil.dataGridPipeValueFormatter(params, DatePipe, 'h:mm a');
    return `${ time } ${ date }`;
  };

  public enumCategoryFormatter: (params: ValueFormatterParams) => any = (params: ValueFormatterParams) =>
    this.viewUtil.dataGridPipeValueFormatterWithTranslate(params, PrefixPipe, 'ENUM.CATEGORY.');

  public enumLengthFormatter: (params: ValueFormatterParams) => any = (params: ValueFormatterParams) =>
    this.viewUtil.dataGridPipeValueFormatterWithTranslate(params, PrefixPipe, 'ENUM.DURATION.');

  public enumIntensityFormatter: (params: ValueFormatterParams) => any = (params: ValueFormatterParams) =>
    this.viewUtil.dataGridPipeValueFormatterWithTranslate(params, PrefixPipe, 'ENUM.INTENSITY_LEVEL.');

  public durationFormatter: (params: ValueFormatterParams) => any = (params: ValueFormatterParams) => {
    const duration: string = `${ params.data.duration } min`;
    return duration;
  };

  public get activeTab(): string {
    return this._activeTab;
  }

  constructor(public modalService: BsModalService,
              private stateService: StateService,
              public viewUtil: ViewUtil,
              private applicationModel: ApplicationModel,
              private coachModel: CoachModel,
              private userModel: UserModel,
              private translate: TranslateService,
              private portalUtil: PortalUtil,
              private viewContainerRef: ViewContainerRef) {
    this.currentUser = this.userModel.currentUser;
  }

  public ngOnInit(): void {
    this.applicationModel.selectSideBarItemWithState(State.MAIN.COACH.LIVE_CLASSES.LIST);

    this.portalUtil.attachPortalTo(
      MainLayoutComponent.PORTAL_OUTLET.HEADING,
      new TemplatePortal(this.headingTemplate, this.viewContainerRef)
    );

    this.criteria.selectedDate = new Date();

    this.activeTab = LiveClassesTabs.MY_CLASSES;
  }

  public set activeTab(value: string) {
    this._activeTab = value;
    this.criteria.myClasses = this._activeTab === LiveClassesTabs.MY_CLASSES;
    this.getScheduledLiveClassesPage();
  }

  public changeToNextDay(): void {
    const currentDate = new Date(this.selectedDate);
    currentDate.setDate(currentDate.getDate() + 1);
    this.selectedDate = currentDate;
    this.criteria.selectedDate = this.selectedDate;
    this.resetConsecutiveDaysIfNotToday();
    this.onCriteriaChanged(this.criteria);
  }

  public changeToPreviousDay(): void {
    const currentDate = new Date(this.selectedDate);
    currentDate.setDate(currentDate.getDate() - 1);
    this.selectedDate = currentDate;
    this.criteria.selectedDate = this.selectedDate;
    this.resetConsecutiveDaysIfNotToday();
    this.onCriteriaChanged(this.criteria);
  }

  public detailsLiveClass(scheduledLiveClass): void {
    const { id } = scheduledLiveClass;
    this.stateService.go(State.MAIN.COACH.LIVE_CLASSES.DETAILS, { id });
  }

  public showFilterClick(): void {
    const modal: BsModalRef = this.modalService.show(LiveClassesFilterComponent, {
      initialState: {
        anyOfCategories: this.criteria.anyOfCategories,
        anyOfIntensities: this.criteria.anyOfIntensities,
        anyOfLanguages: this.criteria.anyOfLanguages,
        daysOfWeek: this.criteria.daysOfWeek,
        isItCoach: true
      },
      class: 'modal-filterLiveClasses modal-dialog-centered'
    });

    modal.onHide
      .pipe(
        filter((reason) => reason === LiveClassesFilterComponent.SHOW_FILTERS),
        tap(() => {
          const { categories, dayOfTheWeek, languages, intensities } = modal.content.filters;
          this.criteria = {
            ...this.criteria,
            anyOfCategories:
              Object.keys(categories.checked).length > 0
                ? Object.keys(categories.checked).filter((key) => categories.checked[key])
                : undefined,
            anyOfIntensities:
              Object.keys(intensities.checked).length > 0
                ? Object.keys(intensities.checked).filter((key) => intensities.checked[key])
                : undefined,
            daysOfWeek: dayOfTheWeek,
            anyOfLanguages:
              Object.keys(languages.checked).length > 0
                ? Object.keys(languages.checked).filter((key) => languages.checked[key])
                : undefined
          } as ScheduledLiveClassCoachPageCriteriaDTO;
          this.criteria = ObjectUtil.plainToClass(ScheduledLiveClassCoachPageCriteriaDTO, this.criteria);
          this.onCriteriaChanged(this.criteria);
        })
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.portalUtil.detachPortalFrom(MainLayoutComponent.PORTAL_OUTLET.HEADING);
  }

  public getLabelForKeyValue(key: string, value: string): string {
    let optionsSet: OptionItem<any>[] = [];

    switch (key) {
      case 'anyOfCategories':
        optionsSet = this.categories;
        break;
      case 'anyOfIntensities':
        optionsSet = this.intensities;
        break;
      case 'daysOfWeek':
        optionsSet = this.days;
        break;
      case 'anyOfLanguages':
        optionsSet = this.languages;
        break;
    }

    const foundItem = optionsSet.find((item) => item.value === value);
    return foundItem ? foundItem.label : value;
  }

  public onCriteriaChanged(changedCriteria: BaseCriteriaDTO): void {
    const criteriaCopy: ScheduledLiveClassCoachPageCriteriaDTO = _.cloneDeep(this.criteria);
    _.assignIn(criteriaCopy, changedCriteria);

    this.criteria = criteriaCopy;

    this.getScheduledLiveClassesPage();
  }

  public onDaysAheadChange(selectedDaysAhead: string): void {
    if (selectedDaysAhead) {
      const daysAhead = this.mapEnumToDays(selectedDaysAhead);
      this.criteria.consecutiveDays = daysAhead;
    }
    else {
      this.criteria.consecutiveDays = null;
      this.consecutiveDays = this.selectedDaysAhead.TODAY;
    }
    this.onCriteriaChanged(this.criteria);
  }

  public onDateChange(event: any): void {
    this.selectedDate = new Date(event);
    this.criteria.selectedDate = this.selectedDate;
    this.resetConsecutiveDaysIfNotToday();
    this.onCriteriaChanged(this.criteria);
  }

  public onTodayClick(): void {
    const today = new Date();
    this.selectedDate = today;
    this.criteria.selectedDate = this.selectedDate;
    this.criteria.consecutiveDays = null;
    this.consecutiveDays = LiveClassesDaysAhead.TODAY;
    this.onCriteriaChanged(this.criteria);
  }

  public onGridAction(action: DataGridAction): void {
    const scheduledLiveClass = action.args[0] as ScheduledLiveClassCoachDetailsResponseDTO;

    if (action.actionName === LiveClassesListActionCellRendererComponent.ACTION_DETAILS_COACH) {
      this.detailsLiveClass(scheduledLiveClass);
    }
  }

  public onSelectionChange(selectedItems: ScheduledLiveClassCoachDetailsPageDTO[]) {
    this.selectedItems = selectedItems;
  }

  public onAddNewClassClick(): void {
    this.stateService.go(State.MAIN.ADMIN.LIVE_CLASSES.SINGLE.CREATE);
  }

  private getScheduledLiveClassesPage(): void {
    this.criteria.pageSize = 8;
    this.coachModel
      .getScheduledLiveClassesPage(this.criteria)
      .pipe(
        takeUntil(this.destroy$),
        tap((page: PageDTO<ScheduledLiveClassCoachDetailsPageDTO>) => {
          this.page$ = of(page);
        })
      )
      .subscribe();
  }

  private mapEnumToDays(value: string | number): number | null {
    switch (value) {
      case LiveClassesDaysAhead.TODAY:
        return null;
      case LiveClassesDaysAhead.SHOW_3_DAYS:
        return 3;
      case LiveClassesDaysAhead.SHOW_10_DAYS:
        return 10;
      case LiveClassesDaysAhead.SHOW_30_DAYS:
        return 30;
      default:
        return 0;
    }
  }

  private resetConsecutiveDaysIfNotToday() {
    const selectedDateMidnight = new Date(this.selectedDate).setHours(0, 0, 0, 0);
    const todayMidnight = new Date().setHours(0, 0, 0, 0);
    if (selectedDateMidnight !== todayMidnight && this.consecutiveDays === this.selectedDaysAhead.TODAY) {
      this.consecutiveDays = null;
    }
  }
}
