import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { State } from 'app/common/State';
import { DataGridAction } from 'app/component/ui/dataGrid/DataGridAction';
import { PageDTO } from 'app/data/dto/PageDTO';
import { ApplicationModel } from 'app/model/ApplicationModel';
import { VideoCoachDTO } from 'app/data/dto/onDemandVideo/coach/VideoCoachDTO';
import { ValueFormatterParams } from 'ag-grid-community';
import { FullNamePipe } from 'app/util/pipe/FullNamePipe';
import { UserDTO } from 'app/data/dto/user/UserDTO';
import { VideoPageCriteriaDTO } from 'app/data/dto/onDemandVideo/VideoPageCriteriaDTO';
import { PageCriteriaDTO } from 'app/data/dto/PageCriteriaDTO';
import _ from 'lodash';
import { UserModel } from 'app/model/UserModel';
import { StateService } from '@uirouter/angular';
import { PrefixPipe } from 'app/util/pipe/PrefixPipe';
import { ViewUtil } from 'app/util/ViewUtil';
import { VideoListTabsCoach, VideoListTabsSenior } from 'app/data/enum/video/VideoListTabs';
import { VideoSortBy } from 'app/data/enum/video/VideoSortBy';
import { OptionItem } from 'app/data/local/generic/OptionItem';
import { BaseCriteriaSortOrder } from 'app/data/dto/BaseCriteriaDTO';
import { SortDirection } from 'app/data/enum/SortDirection';
import { OnDemandVideosListCoachActionCellRendererComponent } from './cellRenderer/OnDemandVideosListCoachActionCellRendererComponent';
import { CoachModel } from 'app/model/CoachModel';
import { CoachProfileResponseDTO } from 'app/data/dto/coach/CoachProfileResponseDTO';
import { of, Subject } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { OnDemandVideoModel } from 'app/model/OnDemandVideoModel';
import { DataGridStarRatingCellRendererComponent } from 'app/component/ui/dataGrid/cellRenderer/DataGridStarRatingCellRendererComponent';
import { PortalUtil } from 'app/util/PortalUtil';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import { TemplatePortal } from '@angular/cdk/portal';

@Component({
  selector: 'app-on-demand-coach-list',
  templateUrl: './OnDemandCoachListComponent.html',
  styleUrls: [ './OnDemandCoachListComponent.scss' ]
})
export class OnDemandCoachListComponent implements OnInit, OnDestroy {
  @ViewChild('headingTemplate', { static: true })
  private readonly headingTemplate: TemplateRef<any>;

  private destroy$: Subject<void> = new Subject<void>();
  public page: PageDTO<VideoCoachDTO>;
  public criteria: VideoPageCriteriaDTO = new VideoPageCriteriaDTO();
  public currentUser: UserDTO;
  public currentCoachProfile: CoachProfileResponseDTO;
  public sortByOptions: OptionItem<string>[] = this.viewUtil.createEnumSelectOptions(VideoSortBy, 'VIDEO_SORT_BY');
  public sortValue: string;

  public ActionCellRendererComponent: typeof OnDemandVideosListCoachActionCellRendererComponent =
    OnDemandVideosListCoachActionCellRendererComponent;

  public StarRatingCellRendererComponent: typeof DataGridStarRatingCellRendererComponent
    = DataGridStarRatingCellRendererComponent;

  public activeTab: VideoListTabsCoach = VideoListTabsCoach.MY_VIDEOS;
  public tabs: VideoListTabsCoach[] = [ VideoListTabsCoach.MY_VIDEOS, VideoListTabsCoach.ALL_VIDEOS, VideoListTabsCoach.PROGRAMS ];

  public enumCategoryFormatter: (params: ValueFormatterParams) => any = (params: ValueFormatterParams) =>
    this.viewUtil.dataGridPipeValueFormatterWithTranslate(params, PrefixPipe, 'ENUM.VIDEO_CATEGORY.');

  public enumIntensityFormatter: (params: ValueFormatterParams) => any = (params: ValueFormatterParams) =>
    this.viewUtil.dataGridPipeValueFormatterWithTranslate(params, PrefixPipe, 'ENUM.INTENSITY_LEVEL.');

  public durationFormatter: ({ value }: { value: number }) => any = ({ value }) => value + ' min';

  public coachFormatter: (params: ValueFormatterParams) => any = (params: ValueFormatterParams) => {
    const coachUser: UserDTO = params.data.coaches[0].user;
    const fullName: string = this.fullNamePipe.transform(coachUser.firstName, coachUser.lastName);
    return fullName;
  };

  public readonly VideoListTabs: typeof VideoListTabsCoach = VideoListTabsCoach;

  constructor(private applicationModel: ApplicationModel,
              private onDemandVideoModel: OnDemandVideoModel,
              private fullNamePipe: FullNamePipe,
              private userModel: UserModel,
              private coachModel: CoachModel,
              private stateService: StateService,
              private viewUtil: ViewUtil,
              private portalUtil: PortalUtil,
              private viewContainerRef: ViewContainerRef) {
    this.currentUser = this.userModel.currentUser;
  }

  public ngOnInit(): void {
    this.applicationModel.selectSideBarItemWithState(State.MAIN.COACH.ON_DEMAND.LIST);

    this.portalUtil.attachPortalTo(
      MainLayoutComponent.PORTAL_OUTLET.HEADING,
      new TemplatePortal(this.headingTemplate, this.viewContainerRef)
    );

    this.getCoachProfile();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.portalUtil.detachPortalFrom(MainLayoutComponent.PORTAL_OUTLET.HEADING);
  }

  public goToDetails(video: VideoCoachDTO): void {
    this.stateService.go(State.MAIN.COACH.ON_DEMAND.DETAILS, { videoId: video.id, isAdmin: false });
  }

  public onTabChanged(tabName: string): void {
    if (this.activeTab === tabName) {
      return;
    }
    this.activeTab = tabName as VideoListTabsCoach;
    this.setupVideoCriteria();
    this.getOnDemandVideosPage();
  }

  public onCriteriaChanged(changedCriteria: PageCriteriaDTO): void {
    const criteriaCopy: VideoPageCriteriaDTO = _.cloneDeep(this.criteria);
    _.assignIn(criteriaCopy, changedCriteria);

    this.criteria = criteriaCopy;

    this.getOnDemandVideosPage();
  }

  public onGridAction(action: DataGridAction): void {
    const video: VideoCoachDTO = action.args[0] as VideoCoachDTO;

    if (action.actionName === OnDemandVideosListCoachActionCellRendererComponent.ACTION_VIEW) {
      this.goToDetails(video);
    }
  }

  public onSortOptionChange(sortBy: VideoSortBy): void {
    if (!sortBy) {
      delete this.criteria.sortOrders;
      this.getOnDemandVideosPage();
      return;
    }
    let sortOrders: BaseCriteriaSortOrder;
    let sortField: string;
    const sortDirection: SortDirection = SortDirection.DESCENDING;
    switch (sortBy) {
      case VideoSortBy.ALPHABETIC: {
        sortField = 'title';
        break;
      }
      case VideoSortBy.MOST_RECENT: {
        sortField = 'createdDate';
        break;
      }
      case VideoSortBy.RATING: {
        sortField = 'rating';
        break;
      }
    }
    sortOrders = `${ sortField } ${ sortDirection }`;
    this.criteria.sortOrders = [ sortOrders ];
    this.getOnDemandVideosPage();
  }

  private setupVideoCriteria(): void {
    this.sortValue = null;
    this.criteria = new VideoPageCriteriaDTO();

    if (this.activeTab === VideoListTabsCoach.MY_VIDEOS) {
      this.criteria.anyOfCoachIds = [ this.currentCoachProfile.id ];
    }
    else {
      delete this.criteria.anyOfCoachIds;
    }
  }

  private getCoachProfile(): void {
    this.coachModel
      .getCurrentUserProfile()
      .pipe(
        tap((coachProfile: CoachProfileResponseDTO) => {
          this.currentCoachProfile = coachProfile;
          this.setupVideoCriteria();
          this.getOnDemandVideosPage();
        }),
        takeUntil(this.destroy$),
        catchError((err) => {
          this.viewUtil.handleServerError(err);
          return of(null);
        })
      )
      .subscribe();
  }

  private getOnDemandVideosPage(): void {
    this.onDemandVideoModel.getVideosPageCoach(this.criteria).subscribe((page) => (this.page = page));
  }

  protected readonly VideoListTabsSenior = VideoListTabsSenior;
}
