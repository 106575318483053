import fileType, { IFileTypeInfo } from '@marklb/file-type';
import { HttpClient, HttpContext, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/common/Constant';
import FileSaver from 'file-saver';
import * as moment from 'moment';
import * as URI from 'urijs';
import * as blueImpLoadImage from 'blueimp-load-image';
import { Base64 } from 'js-base64';
import { TransformationType, TransformFnParams } from 'class-transformer';

@Injectable({ providedIn: 'root' })
export class FileUtil {

  public static readonly IMAGE_EXTENSIONS: string[] = [ 'png', 'jpg', 'jpeg', 'jfif', 'bmp', 'gif', 'tiff', 'exif' ];
  public static readonly AUDIO_EXTENSIONS: string[] = [ 'mp3', 'wav', 'aac', 'ogg', 'm4a', 'ac3', 'flac', 'ape' ];
  public static readonly VIDEO_EXTENSIONS: string[] = [ '.avi', '.flv', '.wmv', '.mov', '.mp4', '.mkv' ];
  public static readonly DOC_EXTENSIONS: string[] = [ 'doc', 'docx', 'xls', 'xlsx', 'odt', 'ods', 'odp', 'txt', 'rtf' ];
  public static readonly PDF_EXTENSIONS: string[] = [ 'pdf' ];
  public static readonly THUMBNAIL_EXTENSIONS: string[] = [ '.jpg', '.png', '.jpeg' ];
  public static readonly SCHEDULED_LIVE_CLASS_ATTACHMENTS_EXTENSIONS: string[] = [ '.doc', '.docx', '.pdf', '.txt' ];

  constructor(private http: HttpClient) {
  }

  // @TODO is this still needed (especially on mobile)? I'm not sure anymore.
  public getFileReader(): FileReader {
    const fileReader: FileReader = new FileReader();
    const zoneOriginalInstanceFileReader: FileReader = fileReader['__zone_symbol__originalInstance'] as FileReader;
    return zoneOriginalInstanceFileReader || fileReader;
  }

  public isBase64Data(input: any): boolean {
    try {
      return Base64.btoa(Base64.atob(input)) === input;
    } catch (err) {
      return false;
    }
  }

  public base64DataToBlob(data: string): Blob {
    const byteString: string = Base64.atob(data);
    const byteArray: Uint8Array = new Uint8Array(byteString.length);

    for (let i = 0; i < byteString.length; i++) {
      byteArray[i] = byteString.charCodeAt(i);
    }
    const fileTypeResult: IFileTypeInfo = fileType(byteArray);

    return new Blob([ byteArray ], { type: fileTypeResult.mime });
  }

  public blobToBase64Data(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {

      const reader: FileReader = new FileReader();

      reader.onload = (event: ProgressEvent): void => {
        resolve((event.target as FileReader).result as string);
      };

      reader.onerror = (event: ProgressEvent): void => {
        reject();
      };

      reader.onabort = (event: ProgressEvent): void => {
        reject();
      };

      reader.readAsDataURL(blob);
    });
  }

  public arrayBufferToBase64Data(arrayBuffer): string {
    let binaryString: string = '';
    const byteArray: Uint8Array = new Uint8Array(arrayBuffer);

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < byteArray.length; i++) {
      binaryString += String.fromCharCode(byteArray[i]);
    }

    return Base64.btoa(binaryString);
  }

  public urlToBlob(url: string): Promise<Blob> {
    return new Promise((resolve, reject) => {
      this.http.get(url, { responseType: 'blob' })
        .subscribe((response: Blob) => {
            resolve(response);
          },
          (errorResponse: HttpErrorResponse) => {
            reject(errorResponse);
          });
    });
  }

  public urlToBase64Data(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.http.get(url, { responseType: 'arraybuffer' })
        .subscribe((response: ArrayBuffer) => {
            resolve(this.arrayBufferToBase64Data(response));
          },
          (errorResponse: HttpErrorResponse) => {
            reject(errorResponse);
          });
    });
  }

  public blobToFile(blob: Blob, fileName: string): File {
    return new File([ blob ], fileName);
  }

  public fileAsImage(imageFile: File): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      blueImpLoadImage(imageFile, (image, metaData) => {
        resolve(image as HTMLImageElement);
      }, { orientation: true, noRevoke: true });
    });
  }

  public fileAsCanvas(imageFile: File): Promise<HTMLCanvasElement> {
    return new Promise((resolve, reject) => {
      blueImpLoadImage(imageFile, (canvas, metaData) => {
        resolve(canvas as HTMLCanvasElement);
      }, { canvas: true, orientation: true });
    });
  }

  public fileAsBase64(imageFile: File): Promise<string> {
    return new Promise((resolve, reject) => {
      blueImpLoadImage(imageFile, (canvas, metaData) => {
        resolve((canvas as HTMLCanvasElement).toDataURL());
      }, { canvas: true, orientation: true });
    });
  }

  public getFileName(blob: Blob, prefix: string = 'file'): string {
    let fileName: string = `${ prefix }.ext`;

    if (blob.type === 'image/png') {
      fileName = fileName.replace('ext', 'png');
    }
    else if (blob.type === 'image/jpeg') {
      fileName = fileName.replace('ext', 'jpg');
    }

    return fileName;
  }

  public getFileNameFromContentDisposition(contentDisposition: string): string {
    const pattern = /filename="([^;]+)"/ig;
    const matches = pattern.exec(contentDisposition);

    if (matches?.[1]) {
      return matches[1].trim();
    }
    else {
      return 'untitled';
    }
  }

  public downloadFile(url: string, forceDownload: boolean = false): void {
    if (forceDownload) {
      const forcedUrl: URI = URI(url);
      forcedUrl.addQuery('forceDownload', true);
      window.open(forcedUrl.href(), '_blank');
    }
    else {
      window.open(url, '_blank');
    }
  }

  public saveFileFromResponse(response: HttpResponse<Blob>): void {
    const contentDisposition: string = response.headers.get(Constant.HTTP_CONTENT_DISPOSITION_HEADER);
    const fileName: string = this.getFileNameFromContentDisposition(contentDisposition);
    FileSaver.saveAs(response.body, fileName);
  }

  public saveFileFromBlob(blob: Blob, name: string = null): void {
    let blobFileName: string = name;
    if (!blobFileName) {
      blobFileName = this.getFileName(blob);
    }
    FileSaver.saveAs(blob, blobFileName);
  }

  public waitForResource(url: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const maxWaitTime: number = moment.duration(5, 'seconds').as('milliseconds');
      const tick: number = moment.duration(0.5, 'seconds').as('milliseconds');
      let currentWaitTime: number = 0;
      let promiseFinished: boolean = false;

      // it's slightly clunky with that interval and promiseFinished (I don't really chain calls and await responses in order),
      // but it was just/easier to implement (otherwise, there would be other time period synchronous problems to handle)

      let interval = setInterval(() => {
        this.getResourceStatus(url)
          .then((status: number) => {
            if (status === 200) {
              clearInterval(interval);
              interval = null;

              if (!promiseFinished) {
                promiseFinished = true;
                resolve();
              }
            }
            else {
              currentWaitTime = currentWaitTime + tick;

              if (currentWaitTime >= maxWaitTime) {
                clearInterval(interval);
                interval = null;

                if (!promiseFinished) {
                  promiseFinished = true;
                  resolve();
                }
              }
            }
          });
      }, tick);
    });
  }

  public static convertFromBase64(value: string): string {
    return atob(value);
  }

  public static convertToBase64(value: string): string {
    return btoa(value);
  }

  public static base64Conversion(params: TransformFnParams): any {
    if (params.type === TransformationType.CLASS_TO_PLAIN) {
      return FileUtil.convertToBase64(params.value);
    }
    else if (params.type === TransformationType.PLAIN_TO_CLASS) {
      return FileUtil.convertFromBase64(params.value);
    }
  }

  private getResourceStatus(url: string): Promise<number> {
    return new Promise((resolve, reject) => {
      let context: HttpContext = new HttpContext();
      context.set(Constant.HTTP_SUPPRESS_ERRORS_TOKEN, true);
      context.set(Constant.HTTP_SUPPRESS_LOADING_TOKEN, true);

      this.http.get(url, { observe: 'response', responseType: 'blob', context: context })
        .subscribe((response: HttpResponse<Blob>) => {
            resolve(response.status);
          },
          (errorResponse: HttpErrorResponse) => {
            resolve(errorResponse.status);
          });
    });
  }

}
