import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable({ providedIn: 'root' })
@Pipe({
  name: 'fullName'
})
export class FullNamePipe implements PipeTransform {
  transform(value, lastName?: string, abbreviate = false): any {
    if (!value) return '';

    if (value && lastName) {
      if (abbreviate) {
        const newLastName = lastName.slice(0, 1);
        return `${ value } ${ newLastName }.`;
      }
      return `${ value } ${ lastName }`;
    }
    return `${ value.firstName } ${ value.lastName }`;
  }
}
