import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DaysDTO } from 'app/data/dto/DaysDTO';
import { ViewUtil } from 'app/util/ViewUtil';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'days' })
export class DaysPipe implements PipeTransform {
  constructor(private readonly viewUtil: ViewUtil) {}

  public transform(days: DaysDTO, translation: string = 'ENUM.DAYS'): any {
    if (!days) {
      return null;
    }

    return Object.keys(days)
      .filter((day: string) => days[day])
      .map((day: string) => this.viewUtil.translateInstant(`${ translation }.${ day.toUpperCase() }`));
  }
}
