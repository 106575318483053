import { VideoCategory } from 'app/data/enum/video/VideoCategory';
import { Type } from 'class-transformer';
import { IdentifiableObjectDTO } from '../IdentifiableObjectDTO';
import { CoachAdminEssentialsDTO } from '../admin/CoachAdminEssentialsDTO';
import { ImageResponseDTO } from '../images/ImageResponseDTO';
import { VideoProgramDTO } from './VideoProgramDTO';
import { IntensityLevel } from 'app/data/enum/IntensityLevel';

export class ProgramResponseDTO extends IdentifiableObjectDTO<number> {
  public category: VideoCategory;

  @Type(() => CoachAdminEssentialsDTO)
  public coaches: CoachAdminEssentialsDTO[];

  public description: string;

  public intensity: IntensityLevel;

  public rating: number;

  @Type(() => ImageResponseDTO)
  public thumbnail: ImageResponseDTO;

  public title: string;

  @Type(() => VideoProgramDTO)
  public videoPrograms: VideoProgramDTO[];

  public viewCount: number;
}
