import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SeniorPageCriteriaDTO } from 'app/data/dto/senior/SeniorPageCriteriaDTO';

@Component({
  selector: 'app-user-selected-filter',
  templateUrl: './UserSelectedFilterComponent.html',
  styleUrls: [ './UserSelectedFilterComponent.scss' ]
})
export class UserSelectedFilterComponent {
  @Input() criteria: SeniorPageCriteriaDTO;
  @Output() criteriaChange: EventEmitter<SeniorPageCriteriaDTO> = new EventEmitter<SeniorPageCriteriaDTO>();

  public friendlyNames: { [key: string]: string } = {
    organizations: 'COMPONENT.USER_FILTERS.ORGANIZATIONS',
    subscriptionType: 'COMPONENT.USER_FILTERS.SUBSCRIPTION_TYPE'
  };

  public getLabelForKeyValue(key: string, value: unknown): string {
    switch (key) {
      case 'organizations':
        return value?.['name'];
      case 'subscriptionType':
        return `ENUM.SUBSCRIPTION_TYPE.${ value }`;
      default:
        return '-';
    }
  }
}
