import { LiveClassSeriesNeedDTO } from '../LiveClassSeriesNeedDTO';
import { LiveClassCategory } from 'app/data/enum/liveClass/LiveClassCategory';
import { LiveClassVisibility } from 'app/data/enum/liveClass/LiveClassVisibility';
import { Transform, Type } from 'class-transformer';
import { DateUtil } from 'app/util/DateUtil';
import { IntensityLevel } from 'app/data/enum/IntensityLevel';
import { DaysDTO } from 'app/data/dto/DaysDTO';
import * as _ from 'lodash';

export class LiveClassSeriesUpdateRequestDTO {
  public attachmentIds: number[];

  @Transform(({ value }) => value || null, { toPlainOnly: true })
  public capacity?: number;

  public category: LiveClassCategory;

  public coachIds: number[];

  public description: string;

  public duration: number; // min 1

  public imageId: number;

  public intensity: IntensityLevel;

  public language: string;

  public liveClassSeriesNeeds: LiveClassSeriesNeedDTO[];

  @Type(() => DaysDTO)
  public daysOfWeek: DaysDTO;

  public numberOfClassesLeft: number;

  @Transform(DateUtil.dateTimeConversion)
  public startDate: Date;

  public title: string;

  public url: string;

  public visibility: LiveClassVisibility;

  constructor(data?: Partial<LiveClassSeriesUpdateRequestDTO>) {
    _.defaults(this, data, {
      attachmentIds: [],
      liveClassSeriesNeeds: []
    });
  }
}
