import { Type } from 'class-transformer';
import { IdentifiableObjectDTO } from '../IdentifiableObjectDTO';
import { OrganizationAddressDTO } from './OrganizationAddressDTO';
import { ImageResponseDTO } from '../images/ImageResponseDTO';

export class OrganizationDetailsResponsePageDTO extends IdentifiableObjectDTO<number> {
  contactName: string;
  @Type(() => OrganizationAddressDTO)
  address: OrganizationAddressDTO;
  name: string;
  phone: string;
  usersCount: number;
  @Type(() => ImageResponseDTO)
  logo?: ImageResponseDTO;
}
