import { Component, OnDestroy } from '@angular/core';
import { UserModel } from 'app/model/UserModel';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { StateUtil } from 'app/util/StateUtil';
import { ViewUtil } from 'app/util/ViewUtil';
import { of, Subject } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { ScheduledLiveClassJoinComponentInput } from './JoinUserInterface';
import { LiveClassSeriesAttachmentResponseDTO } from 'app/data/dto/liveClassSeries/LiveClassSeriesAttachmentResponseDTO';
import { ApplicationModel } from 'app/model/ApplicationModel';
import { State } from 'app/common/State';
import { CoachModel } from 'app/model/CoachModel';

@Component({
  selector: 'app-join-user',
  templateUrl: './JoinUserComponent.html',
  styleUrls: [ './JoinUserComponent.scss' ]
})
export class JoinUserComponent implements OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  public static DROP: string = 'drop';
  public static JOIN: string = 'join';

  public scheduledLiveClass: ScheduledLiveClassJoinComponentInput;
  public coachMode: boolean = false;

  constructor(
    public bsModalRef: BsModalRef,
    public modalService: BsModalService,
    private userModel: UserModel,
    private coachModel: CoachModel,
    public viewUtil: ViewUtil,
    public stateUtil: StateUtil,
    private applicationModel: ApplicationModel
  ) {
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public shouldShowJoinButton(): boolean {
    return (
      this.scheduledLiveClass.currentSeniorEnrollment &&
      !this.scheduledLiveClass.currentSeniorEnrollment.attended &&
      !this.scheduledLiveClass.currentSeniorEnrollment.dropped
    );
  }

  public disableJoinButton(): boolean {
    const now = new Date();
    const minStartDate = new Date(this.scheduledLiveClass.startDate);
    const endDate = new Date(this.scheduledLiveClass.startDate);
    minStartDate.setMinutes(minStartDate.getMinutes() - 15);
    endDate.setMinutes(endDate.getMinutes() + this.scheduledLiveClass.duration);

    return now < minStartDate || now > endDate;
  }

  public onCloseClick(): void {
    this.bsModalRef.hide();
  }

  public onJoinClick(): void {
    if (!this.coachMode) {
      this.userModel
        .joinScheduledLiveClasses(this.scheduledLiveClass.id, this.applicationModel.platform)
        .pipe(
          takeUntil(this.destroy$),
          tap(() => {
            this.modalService.setDismissReason(JoinUserComponent.JOIN);
            this.viewUtil.showToastSuccess('COMMON.SUCCESS');
            this.bsModalRef.hide();
            this.stateUtil.goToState(State.MAIN.SENIOR.LIVE_CLASSES.ZOOM, { id: this.scheduledLiveClass.id });
          }),
          catchError((err) => {
            this.viewUtil.handleServerError(err);
            return of(null);
          })
        )
        .subscribe();
    }
    else {
      this.stateUtil.goToState(State.MAIN.COACH.LIVE_CLASSES.ZOOM, { id: this.scheduledLiveClass.id });
      this.modalService.setDismissReason(JoinUserComponent.JOIN);
      this.bsModalRef.hide();
    }
  }

  public onDropClick(): void {
    this.userModel
      .dropFromLiveClass(this.scheduledLiveClass)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.modalService.setDismissReason(JoinUserComponent.DROP);
          this.bsModalRef.hide();
        }),
        catchError((err) => {
          this.viewUtil.handleServerError(err);
          return of(null);
        })
      )
      .subscribe();
  }
}
