import { DateUtil } from 'app/util/DateUtil';
import { Transform, Type } from 'class-transformer';
import { VideoCoachDetailsDTO } from './coach/VideoCoachDetailsDTO';
import { IntensityLevel } from 'app/data/enum/IntensityLevel';
import { BaseVideoDTO } from 'app/data/dto/onDemandVideo/BaseVideoDTO';

export abstract class VideoDTO extends BaseVideoDTO {

  @Type(() => VideoCoachDetailsDTO)
  public coaches: VideoCoachDetailsDTO[];

  @Transform(DateUtil.dateTimeConversion)
  public createdDate: Date;

  public duration: number;

  public description: string;

  public language: string;

  public intensity: IntensityLevel;

  public recommended: boolean;

  public rating: number;

}