export class OrganizationEssentialsDTO {

  public id: number;

  public logoUrl?: string;

  public name: string;

  public type: string;
}
