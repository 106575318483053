import { ScheduledLiveClassCoachRatingDetailsDTO } from 'app/data/dto/scheduledLiveClass/coach/ScheduledLiveClassCoachRatingDetailsDTO';
import { Type } from 'class-transformer';

export class ScheduledLiveClassRatingCoachDTO {
  public feedback: string;

  public rating: number;

  @Type(() => ScheduledLiveClassCoachRatingDetailsDTO)
  public scheduledLiveClass: ScheduledLiveClassCoachRatingDetailsDTO;
}