import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Constant } from 'app/common/Constant';
import { State } from 'app/common/State';
import { PageCriteriaDTO } from 'app/data/dto/PageCriteriaDTO';
import { ActiveCoachDTO } from 'app/data/dto/coach/ActiveCoachDTO';
import { VideoEssentialsAdminPageDTO } from 'app/data/dto/onDemandVideo/admin/VideoEssentialsAdminPageDTO';
import { VideoCategory } from 'app/data/enum/video/VideoCategory';
import { OptionItem } from 'app/data/local/generic/OptionItem';
import { AdminModel } from 'app/model/AdminModel';
import { ApplicationModel } from 'app/model/ApplicationModel';
import { StateUtil } from 'app/util/StateUtil';
import { ViewUtil } from 'app/util/ViewUtil';
import { of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { OnDemandVideoModel } from 'app/model/OnDemandVideoModel';
import { IntensityLevel } from 'app/data/enum/IntensityLevel';
import { PortalUtil } from 'app/util/PortalUtil';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import { TemplatePortal } from '@angular/cdk/portal';

@Component({
  selector: 'app-program-admin-new',
  templateUrl: './ProgramAdminNewComponent.html',
  styleUrls: [ './ProgramAdminNewComponent.scss' ]
})
export class ProgramAdminNewComponent implements OnInit, OnDestroy {
  @ViewChild('headingTemplate', { static: true })
  private readonly headingTemplate: TemplateRef<any>;

  private destroy$: Subject<void> = new Subject<void>();
  public submitted: boolean = false;
  public criteria: PageCriteriaDTO;
  public uploadStarted: boolean = false;
  public thumbnailFormStatus: boolean = false;

  public items: VideoEssentialsAdminPageDTO[] = [];
  public loading: boolean = false;
  public pageSize: number = 50;
  public totalPages: number = 1;

  public intensities: OptionItem<IntensityLevel>[] = this.viewUtil.createEnumSelectOptions(IntensityLevel, 'INTENSITY_LEVEL');
  public categories: OptionItem<VideoCategory>[] = this.viewUtil.createEnumSelectOptions(
    VideoCategory,
    'VIDEO_CATEGORY'
  );

  public searchPhrase$: Subject<string> = new Subject<string>();
  public coaches: ActiveCoachDTO[];
  public form: FormGroup;

  public Constant: typeof Constant = Constant;

  constructor(private applicationModel: ApplicationModel,
              private fb: FormBuilder,
              private viewUtil: ViewUtil,
              private onDemandVideoModel: OnDemandVideoModel,
              private adminModel: AdminModel,
              private stateUtil: StateUtil,
              private portalUtil: PortalUtil,
              private viewContainerRef: ViewContainerRef) {
  }

  public ngOnInit(): void {
    this.portalUtil.attachPortalTo(
      MainLayoutComponent.PORTAL_OUTLET.HEADING,
      new TemplatePortal(this.headingTemplate, this.viewContainerRef)
    );

    this.criteria = new PageCriteriaDTO();
    this.criteria.pageSize = this.pageSize;
    this.fetchData();
    this.searchPhrase$.pipe(debounceTime(300), distinctUntilChanged()).subscribe((searchPhrase) => {
      this.criteria.searchPhrase = searchPhrase;
      this.criteria.pageNumber = 0;
      this.totalPages = 1;
      this.fetchData();
    });

    this.getCoachesList();
    this.setupForm();
    this.applicationModel.selectSideBarItemWithState(State.MAIN.ADMIN.ON_DEMAND.LIST);
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.portalUtil.detachPortalFrom(MainLayoutComponent.PORTAL_OUTLET.HEADING);
  }

  public handleImageId(imageId: number): void {
    this.form.patchValue({
      imageId
    });
  }

  public handleThumbnailFormStatus(thumbnailStatus: boolean): void {
    this.thumbnailFormStatus = thumbnailStatus;
  }

  public handleStartUpload(uploadStart: boolean): void {
    this.uploadStarted = uploadStart;
  }

  public onSearch(event: KeyboardEvent): void {
    if (event.key === 'Backspace') {
      event.stopPropagation();
    }
    const inputElement = event.target as HTMLInputElement;
    this.searchPhrase$.next(inputElement.value);
  }

  public onScrollToEnd(): void {
    this.fetchData();
  }

  public onSaveClick(): void {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.adminModel
      .createProgram(this.form.value)
      .pipe(
        tap(({ id }) => {
          this.viewUtil.showToastSuccess('COMMON.SUCCESS');
          this.stateUtil.goToState(State.MAIN.ADMIN.PROGRAM.DETAILS, { id });
        }),
        takeUntil(this.destroy$),
        catchError((err) => {
          this.viewUtil.handleServerError(err);
          return of(null);
        })
      )
      .subscribe();
  }

  public onBackClick(): void {
    this.stateUtil.goToState(State.MAIN.ADMIN.ON_DEMAND.LIST);
  }

  private fetchData(): void {
    if (this.loading || this.criteria.pageNumber >= this.totalPages) return;

    this.loading = true;
    this.onDemandVideoModel
      .getVideosEssentialsPage(this.criteria)
      .pipe(
        tap((response) => {
          this.loading = false;
          this.totalPages = response.totalPages;
          this.items = response.content;
          this.criteria.pageNumber++;
        }),
        takeUntil(this.destroy$),
        catchError((err) => {
          this.loading = false;
          this.viewUtil.handleServerError(err);
          return of(null);
        })
      )
      .subscribe();
  }

  private setupForm(): void {
    this.form = this.fb.group({
      category: [ null, Validators.required ],
      coachIds: [ null, Validators.required ],
      description: [ null, [ Validators.required, Validators.maxLength(Constant.TEXT_MAX_LENGTH_512) ] ],
      imageId: [ null, Validators.required ],
      intensity: [ null, Validators.required ],
      title: [ null, [ Validators.required, Validators.maxLength(Constant.TEXT_MAX_LENGTH_128) ] ],
      videoIds: [ null, Validators.required ]
    });
  }

  private getCoachesList(): void {
    this.onDemandVideoModel.getActiveCoachList().subscribe((coaches) => {
      this.coaches = coaches;
    });
  }
}
