import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { StateService, Transition } from '@uirouter/core';
import { Constant } from 'app/common/Constant';
import { State } from 'app/common/State';
import { SeniorRegistrationDTO } from 'app/data/dto/registration/SeniorRegistrationDTO';
import { UserGender } from 'app/data/enum/account/UserGender';
import { HealthProvider } from 'app/data/enum/user/HealthProvider';
import { OptionItem } from 'app/data/local/generic/OptionItem';
import { ViewUtil } from 'app/util/ViewUtil';

@Component({
  selector: 'app-registration-branded-user-data',
  templateUrl: './RegistrationBrandedUserDataComponent.html',
  styleUrls: [ './RegistrationBrandedUserDataComponent.scss' ]
})
export class RegistrationBrandedUserDataComponent {
  public userRegistration: SeniorRegistrationDTO;
  public Constant: typeof Constant = Constant;
  public healthProviderList: OptionItem<HealthProvider>[] = this.viewUtil.createEnumSelectOptions(
    HealthProvider,
    'HEALTH_PROVIDER'
  );

  public genderList: OptionItem<UserGender>[] = this.viewUtil.createEnumSelectOptions(UserGender, 'USER_GENDER');
  public healthProviders: typeof HealthProvider = HealthProvider;
  public currentStep: number;
  public noOfSteps: number;
  public maxDateOfBirth: Date = this.getYesterdayDate();
  @ViewChild('registrationForm')
  private registrationForm: NgForm;

  constructor(
    private stateService: StateService,
    public viewUtil: ViewUtil,
    private transition: Transition
  ) {
    const userRegistrationParam = this.transition.params().userRegistration;
    if (!userRegistrationParam) {
      this.redirectToStart();
    }
    else {
      this.userRegistration = userRegistrationParam;
    }
    this.currentStep = this.transition.params().currentStep;
    this.noOfSteps = this.transition.params().noOfSteps;
  }

  public goToNextStep(): void {
    this.registrationForm.onSubmit(null);
    if (this.registrationForm.form.valid) {
      this.stateService.go(State.PRELIMINARY.REGISTRATION_BRANDED.PASSWORD, {
        userRegistration: this.userRegistration,
        currentStep: this.currentStep + 1,
        noOfSteps: this.noOfSteps
      });
    }
  }

  public backToLogin(): void {
    this.stateService.go(State.PRELIMINARY.LOGIN);
  }

  public goBack(): void {
    this.stateService.go(State.PRELIMINARY.REGISTRATION_BRANDED.START, {
      userRegistration: this.userRegistration,
      organizationStub: this.transition.params().organizationStub
    });
  }

  private redirectToStart(): void {
    this.stateService.go(State.PRELIMINARY.REGISTRATION_BRANDED.START, {
      organizationStub: this.transition.params().organizationStub
    });
  }

  private getYesterdayDate(): Date {
    const date = new Date();
    date.setDate(date.getDate() - 1);
    return date;
  }
}
