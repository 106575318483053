<ng-container [ngSwitch]="intensity">
  <ng-container *ngSwitchCase="IntensityLevel.BEGINNER">
    <svg-icon class="fill" src="asset/image/icon/intensity_1.svg"></svg-icon>
  </ng-container>

  <ng-container *ngSwitchCase="IntensityLevel.INTERMEDIATE">
    <svg-icon class="fill" src="asset/image/icon/intensity_2.svg"></svg-icon>
  </ng-container>

  <ng-container *ngSwitchCase="IntensityLevel.ADVANCED">
    <svg-icon class="fill" src="asset/image/icon/intensity_3.svg"></svg-icon>
  </ng-container>

  <ng-container *ngSwitchDefault>-</ng-container>
</ng-container>