import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Token } from 'app/data/local/auth/Token';
import { CustomHttpParameterEncoder } from 'app/util/other/CustomHttpParameterEncoder';
import { ApplicationConfig } from 'app/config/ApplicationConfig';
import { OAuth2TokenDTO } from 'app/data/dto/auth/OAuth2TokenDTO';
import { AuthServiceInterface } from 'app/service/interface/AuthServiceInterface';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { SecurityDataDTO } from 'app/data/dto/auth/SecurityDataDTO';
import { StorageUtil } from 'app/util/StorageUtil';

@Injectable({ providedIn: 'root' })
export class CustomOAuth2Service implements AuthServiceInterface {

  private static readonly TOKEN_KEY: string = 'token';

  private static readonly OAUTH_SCOPE_READ_WRITE: string = 'read write';
  private static readonly OAUTH_GRANT_TYPE_PASSWORD: string = 'password';
  private static readonly OAUTH_GRANT_TYPE_REFRESH_TOKEN: string = 'refresh_token';

  private rememberLogin: boolean = false;

  constructor(private http: HttpClient,
              private storageUtil: StorageUtil) {
  }

  public setRememberLogin(remember: boolean): Observable<void> {
    this.rememberLogin = remember;
    return of();
  }

  public login(email: string, password: string): Observable<{ token: Token, securityData: SecurityDataDTO }> {
    const request: HttpParams = new HttpParams({
      encoder: new CustomHttpParameterEncoder(),
      fromObject: {
        username: email,
        password: password,
        grant_type: CustomOAuth2Service.OAUTH_GRANT_TYPE_PASSWORD,
        scope: CustomOAuth2Service.OAUTH_SCOPE_READ_WRITE,
        client_secret: encodeURIComponent(ApplicationConfig.customOAuth2.customOAuth2ClientSecret),
        client_id: ApplicationConfig.customOAuth2.customOAuth2ClientId
      }
    });

    const headers: HttpHeaders = new HttpHeaders({
      Authorization: 'Basic ' + btoa(`${ ApplicationConfig.customOAuth2.customOAuth2ClientId }:${ ApplicationConfig.customOAuth2.customOAuth2ClientSecret }`),
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: 'application/json'
    });

    return this.http.post(`${ ApplicationConfig.customOAuth2.customOAuth2Url }/token`, request, { headers: headers })
      .pipe(
        map((response: any) => {
          const token: OAuth2TokenDTO = OAuth2TokenDTO.fromOAuth2Object(response);
          return { token: token, securityData: undefined };
        })
      );
  }

  public logout(): Observable<void> {
    return this.http.post(`${ ApplicationConfig.apiUrl }/logout`, {})
      .pipe(
        map((response: any) => {
          return;
        })
      );
  }

  public refresh(token: Token): Observable<{ token: Token, securityData: SecurityDataDTO }> {
    const request: HttpParams = new HttpParams({
      fromObject: {
        grant_type: CustomOAuth2Service.OAUTH_GRANT_TYPE_REFRESH_TOKEN,
        refresh_token: token.refreshToken
      }
    });

    const headers: HttpHeaders = new HttpHeaders({
      Authorization: 'Basic ' + btoa(`${ ApplicationConfig.customOAuth2.customOAuth2ClientId }:${ ApplicationConfig.customOAuth2.customOAuth2ClientSecret }`),
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: 'application/json'
    });

    return this.http.post(`${ ApplicationConfig.customOAuth2.customOAuth2Url }/token`, request, { headers: headers })
      .pipe(
        map((response: any) => {
          const refreshedToken: OAuth2TokenDTO = OAuth2TokenDTO.fromOAuth2Object(response);
          return { token: refreshedToken, securityData: undefined };
        })
      );
  }

  public startPasswordReset(email: string): Observable<void> {
    return this.http.delete(`${ ApplicationConfig.apiUrl }/users/${ encodeURIComponent(email) }/password`)
      .pipe(
        map((response: any) => {
          return;
        })
      );
  }

  public completePasswordReset(email: string, verificationCode: string, newPassword: string): Observable<void> {
    const request: {
      verificationCode: string;
      newPassword: string;
    } = {
      verificationCode: verificationCode,
      newPassword: newPassword
    };

    return this.http.patch(`${ ApplicationConfig.apiUrl }/users/${ encodeURIComponent(email) }/password`, request)
      .pipe(
        map((response: any) => {
          return;
        })
      );
  }

  public changePassword(currentPassword: string, newPassword: string): Observable<void> {
    const request: {
      oldPassword: string;
      newPassword: string;
    } = {
      oldPassword: currentPassword,
      newPassword: newPassword
    };

    return this.http.put(`${ ApplicationConfig.apiUrl }/users/me/password`, request)
      .pipe(
        map((response: any) => {
          return;
        })
      );
  }

  public changePasswordForced(newPassword: string): Observable<void> {
    const request: {
      newPassword: string;
    } = {
      newPassword: newPassword
    };

    return this.http.put(`${ ApplicationConfig.apiUrl }/users/me/passwordForced`, request)
      .pipe(
        map((response: any) => {
          return;
        })
      );
  }

  public setToken(token: Token): Observable<Token> {
    return new Observable<Token>((observer) => {
      // null values aren't even saved to storage, so this basically does nothing but resolves fine
      this.storageUtil.saveData<OAuth2TokenDTO>(this.rememberLogin ? token as OAuth2TokenDTO : null, CustomOAuth2Service.TOKEN_KEY)
        .then((result: OAuth2TokenDTO) => {
          observer.next(result);
          observer.complete();
        })
        .catch((error) => {
          observer.error(error);
        });
    });
  }

  public getToken(): Observable<Token> {
    return new Observable<Token>((observer) => {
      this.storageUtil.loadData<OAuth2TokenDTO>(CustomOAuth2Service.TOKEN_KEY, OAuth2TokenDTO)
        .then((result: OAuth2TokenDTO) => {
          observer.next(result);
          observer.complete();
        })
        .catch((error) => {
          observer.error(error);
        });
    });
  }

  public deleteToken(): Observable<void> {
    return new Observable<void>((observer) => {
      this.storageUtil.deleteData(CustomOAuth2Service.TOKEN_KEY)
        .then(() => {
          observer.next();
          observer.complete();
        })
        .catch((error) => {
          observer.error(error);
        });
    });
  }

}
