export class UserAddressDTO {
  public city: string;

  public country: string = 'us';

  public state: string;

  public street: string;

  public zipCode: string;
}
