import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { StateService, Transition } from '@uirouter/core';
import { Constant } from 'app/common/Constant';
import { State } from 'app/common/State';
import { RegistrationType } from 'app/data/enum/user/RegistrationType';
import { UserRegistrationModel } from 'app/model/UserRegistrationModel';
import { ViewUtil } from 'app/util/ViewUtil';
import { UserModel } from 'app/model/UserModel';

@Component({
  selector: 'app-registration-confirm',
  templateUrl: './RegistrationConfirmComponent.html',
  styleUrls: [ './RegistrationConfirmComponent.scss' ]
})
export class RegistrationConfirmComponent {
  private static readonly RESEND_CONFIRMATION_DELAY: number = 60000;

  @ViewChild('confirmationForm')
  private confirmationForm: NgForm;

  public usernameParam: string;
  public confirmationCodeParam: string;

  public registrationType: RegistrationType;

  public formData: {
    username: string;
    confirmationCode: string;
  } = {
    username: null,
    confirmationCode: null
  };

  private canResendConfirmation: boolean = true;

  public Constant: typeof Constant = Constant;
  public RegistrationType: typeof RegistrationType = RegistrationType;

  constructor(private stateService: StateService,
              private transition: Transition,
              public viewUtil: ViewUtil,
              private userRegistrationModel: UserRegistrationModel,
              private userModel: UserModel) {
    this.usernameParam = this.transition.params().username ? unescape(this.transition.params().username) : null;
    this.confirmationCodeParam = transition.params().confirmationCode;

    if (this.usernameParam) {
      this.formData.username = unescape(this.usernameParam);
      this.registrationType = this.formData.username.match(Constant.EMAIL_ADDRESS_PATTERN) ? RegistrationType.EMAIL : RegistrationType.PHONE;
    }

    if (this.confirmationCodeParam) {
      this.formData.confirmationCode = this.confirmationCodeParam;
    }
  }

  public resendConfirmationCode(): void {
    if (!this.canResendConfirmation) {
      this.viewUtil.showToastError('VIEW.PRELIMINARY.REGISTRATION.RESEND_CONFIRMATION_FAILURE');
      return;
    }

    this.userModel.resendConfirmationCode(this.formData.username).subscribe(() => {
        this.canResendConfirmation = false;

        setTimeout(() => {
          this.canResendConfirmation = true;
        }, RegistrationConfirmComponent.RESEND_CONFIRMATION_DELAY);

        this.viewUtil.showToastSuccess('VIEW.PRELIMINARY.REGISTRATION.RESEND_CONFIRMATION_SUCCESS');
      },
      (error) => {
        this.viewUtil.handleServerError(error);
      });
  }

  public confirmRegistration(): void {
    this.confirmationForm.onSubmit(null);

    if (this.confirmationForm.form.valid) {
      this.userRegistrationModel.confirmRegistration(this.formData.username, this.formData.confirmationCode).subscribe(
        (res) => {
          this.viewUtil.showToastSuccess('VIEW.PRELIMINARY.REGISTRATION.REGISTRATION_COMPLETE_SUCCESS');
          this.backToLogin();
        },
        (error) => {
          this.viewUtil.handleServerError(error);
        }
      );
    }
  }

  public backToLogin(): void {
    this.stateService.go(State.PRELIMINARY.LOGIN);
  }
}
