import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-users-capacity-cell-renderer',
  templateUrl: './UsersCapacityCellRendererComponent.html',
  styleUrls: [ './UsersCapacityCellRendererComponent.scss' ]
})
export class UsersCapacityCellRendererComponent implements ICellRendererAngularComp {
  public params: ICellRendererParams;
  public enrolledSeniorsCount: string;
  public capacity: string;

  public agInit(params: ICellRendererParams): void {
    this.params = params;
    this.enrolledSeniorsCount = params.data.enrolledSeniorsCount;
    this.capacity = params.valueFormatted.split('/')[1];
  }

  public refresh(params: ICellRendererParams): boolean {
    return false;
  }
}
