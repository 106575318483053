import { DateUtil } from 'app/util/DateUtil';
import { Exclude, Transform, Type } from 'class-transformer';
import { addMinutes, differenceInMinutes, isFuture, isPast, isToday, subMinutes } from 'date-fns';
import { IdentifiableObjectDTO } from '../../IdentifiableObjectDTO';
import { ScheduledLiveClassEnrollmentDetailsDTO } from './ScheduledLiveClassEnrollmentDetailsDTO';
import { ImageResponseDTO } from '../../images/ImageResponseDTO';
import { ScheduledLiveClassNeedDTO } from '../ScheduledLiveClassNeedDTO';
import { CoachAdminEssentialsDTO } from '../../admin/CoachAdminEssentialsDTO';
import { LiveClassCategory } from 'app/data/enum/liveClass/LiveClassCategory';
import { ScheduledLiveClassAttachmentResponseDTO } from '../ScheduledLiveClassAttachmentResponseDTO';
import { IntensityLevel } from 'app/data/enum/IntensityLevel';
import { BaseLiveClass } from 'app/data/dto/scheduledLiveClass/BaseLiveClass';
import { Constant } from 'app/common/Constant';

export class ScheduledLiveClassSeniorDetailsResponseDTO extends IdentifiableObjectDTO<number> implements BaseLiveClass {
  @Type(() => ScheduledLiveClassAttachmentResponseDTO)
  public attachments: ScheduledLiveClassAttachmentResponseDTO[];

  public capacity?: number;

  public category: LiveClassCategory;

  @Type(() => CoachAdminEssentialsDTO)
  public coaches: CoachAdminEssentialsDTO[];

  @Type(() => ScheduledLiveClassEnrollmentDetailsDTO)
  public currentSeniorEnrollment?: ScheduledLiveClassEnrollmentDetailsDTO;

  public description: string;

  public duration: number;

  public enrolledSeniorsCount: number;

  public intensity: IntensityLevel;

  public isCurrentSeniorOnWaitingList: boolean;

  public language: string;

  public liveClassSeriesId?: number;

  @Type(() => ScheduledLiveClassNeedDTO)
  public scheduledLiveClassNeeds: ScheduledLiveClassNeedDTO[];

  @Transform(DateUtil.dateTimeConversion)
  public startDate: Date;

  @Type(() => ImageResponseDTO)
  public thumbnail: ImageResponseDTO;

  public title: string;

  public url: string;

  @Exclude()
  public get isHappeningNow(): boolean {
    const diff: number = differenceInMinutes(this.startDate, new Date());
    return diff <= Constant.DEFAULT_MINUTES_THRESHOLD;
  }

  @Exclude()
  public get isHappeningToday(): boolean {
    return isToday(this.startDate);
  }

  @Exclude()
  public get isOngoing(): boolean {
    const isAfterStartDate: boolean = isPast(subMinutes(this.startDate, Constant.DEFAULT_MINUTES_THRESHOLD));
    const isBeforeEndDate: boolean = isFuture(addMinutes(this.startDate, this.duration));
    return isAfterStartDate && isBeforeEndDate;
  }

  @Exclude()
  public get isExceeded(): boolean {
    return this.capacity === null ? false : this.enrolledSeniorsCount >= this.capacity;
  }
}
