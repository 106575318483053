import { Component } from '@angular/core';
import { LanguageVersion } from 'app/data/local/LanguageVersion';
import { ViewUtil } from 'app/util/ViewUtil';

@Component({
  selector: 'app-preliminary-layout',
  templateUrl: 'PreliminaryLayoutComponent.html',
  styleUrls: [ 'PreliminaryLayoutComponent.scss' ]
})
export class PreliminaryLayoutComponent {
  public LanguageVersion: typeof LanguageVersion = LanguageVersion;

  constructor(public viewUtil: ViewUtil) {
  }
}
