import { Component, OnInit } from '@angular/core';
import Player from '@vimeo/player';
import { environment } from 'environment/environment';

@Component({
  selector: 'app-help-tutorial',
  templateUrl: './HelpTutorialComponent.html',
  styleUrls: [ './HelpTutorialComponent.scss' ]
})
export class HelpTutorialComponent implements OnInit {
  private player: Player;

  public ngOnInit(): void {
    this.setupVimeoPlayer();
  }

  private setupVimeoPlayer(): void {
    const options: Player.Options = {
      id: environment.tutorialId,
      loop: false,
      responsive: true,
      byline: false,
      portrait: false,
      title: false
    };
    this.player = new Player('vimeo-player', options);
  }
}
