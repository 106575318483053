import { Component, TemplateRef } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-data-grid-template-cell-renderer-component',
  template: '<div class="template-cell" (click)="preventGridSelection()"><ng-container *ngTemplateOutlet="template; context: templateContext"></ng-container></div>',
  styles: [ ':host { width: 100%; height:100% } .template-cell { text-overflow: ellipsis; white-space: nowrap; overflow: hidden; height: 100% }' ]
})
export class DataGridTemplateCellRendererComponent implements ICellRendererAngularComp {
  public template: TemplateRef<any>;

  public templateContext: { $implicit: any, params: any, value: any };

  public params: any;

  public agInit(params: ICellRendererParams): void {
    this.template = params['ngTemplate'];
    this.templateContext = {
      $implicit: params.data,
      params: params,
      value: params.value
    };
  }

  public refresh(params: any): boolean {
    return false;
  }

  public preventGridSelection(): void {
    try {
      const previousValue = this.params?.node['gridOptionsWrapper']?.gridOptions?.suppressRowClickSelection;
      this.params.node['gridOptionsWrapper'].gridOptions.suppressRowClickSelection = true;

      setTimeout(() => {
        this.params.node['gridOptionsWrapper'].gridOptions.suppressRowClickSelection = previousValue;
      });
    } catch (ex) {
      //
    }
  }
}
