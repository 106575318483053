<ng-template #headingTemplate>
  <div>
    <h3>{{ 'VIEW.MAIN.DASHBOARD.WELCOME' | translate }}</h3>
    <h1 class="word-break">{{ currentUser?.firstName | replaceEmpty: ('VIEW.MAIN.DASHBOARD.GUEST' | translate) }}</h1>
  </div>
  <div *ngIf="seniorActivity" [tourWizardAnchor]="TourWizardAnchor.STATS" class="d-flex gap-4 flex-wrap align-items-end color-green-dark">
    <div class="profile-stats align-items-center d-flex gap-2">
      <span>{{ 'VIEW.MAIN.DASHBOARD.WEEK_STREAK' | translate }}</span>
      <h2 class="m-0">{{ seniorActivity.weekStreak }}</h2>
    </div>
    <div class="profile-stats align-items-center d-flex gap-2">
      <span>{{ 'VIEW.MAIN.DASHBOARD.BADGES_EARNED' | translate }}</span>
      <h2 class="m-0">{{ seniorActivity.summary?.badgesEarnedCount || '0'}}</h2>
    </div>
    <div class="profile-stats align-items-center d-flex gap-2">
      <span>{{ 'VIEW.MAIN.DASHBOARD.CLASSES_THIS_MONTH' | translate }}</span>
      <h2 class="m-0">{{ seniorActivity.summary?.liveClassesTakenCount || '0' }}</h2>
    </div>
    <button class="btn btn-primary see-all" (click)="onSeeAllClick()">
      <span>{{ 'VIEW.MAIN.DASHBOARD.SEE_ALL' | translate }}</span>
      <svg-icon src="asset/image/icon/chevron-right.svg"></svg-icon>
    </button>
  </div>
</ng-template>

<div class="container-fluid px-0">
  <div class="row">
    <div class="col-12 col-lg-8 col-xxl-9">
      <section [tourWizardAnchor]="TourWizardAnchor.HAPPENING_NOW" class="section space-y-4">
        <h2>{{ 'VIEW.MAIN.DASHBOARD.HAPPENNING_NOW' | translate }}</h2>
        <hr>
        <app-dashboard-senior-happening-now [guestMode]="accessControlModel.isLimitedAccess$ | async"></app-dashboard-senior-happening-now>
      </section>
    </div> <!-- /.col -->

    <div class="col-12 col-lg-4 col-xxl-3 mt-4 mt-lg-0">
      <section [tourWizardAnchor]="TourWizardAnchor.RECOMMENDED" class="section space-y-4">
        <h2 class="align-self-start">{{ 'VIEW.MAIN.DASHBOARD.RECOMMENDED' | translate }}</h2>
        <hr>
        <h3>{{ 'VIEW.MAIN.DASHBOARD.ON_DEMAND' | translate }}</h3>
        <app-dashboard-senior-recommended></app-dashboard-senior-recommended>
      </section>
    </div> <!-- /.col -->
  </div> <!-- /.row -->
</div> <!-- /.container-fluid -->
