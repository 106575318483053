import { Component } from '@angular/core';
import { StateService } from '@uirouter/core';
import { State } from 'app/common/State';
import { ProgramSeniorDetailsPageDTO } from 'app/data/dto/programs/ProgramSeniorDetailsPageDTO';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { IntensityLevel } from 'app/data/enum/IntensityLevel';
import { Constant } from 'app/common/Constant';

@Component({
  selector: 'app-program-item-details-modal',
  templateUrl: './ProgramItemDetailsModalComponent.html',
  styleUrls: [ './ProgramItemDetailsModalComponent.scss' ]
})
export class ProgramItemDetailsModalComponent {
  public data: ProgramSeniorDetailsPageDTO;

  public readonly IntensityLevel: typeof IntensityLevel = IntensityLevel;
  public readonly Constant: typeof Constant = Constant;

  constructor(
    public modalService: BsModalService,
    private bsModalRef: BsModalRef,
    private stateService: StateService
  ) {
  }

  public onHideClick(): void {
    this.bsModalRef.hide();
  }

  public onPlayClick(): void {
    this.stateService.go(State.MAIN.SENIOR.ON_DEMAND.PROGRAM.PLAY, { id: this.data.id });
    this.bsModalRef.hide();
  }
}
