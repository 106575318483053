<app-content-and-image-column-layout [imageSrc]="'asset/image/register-background.jpg'">
  <div id="content" class="p-4 d-flex flex-column">
    <img class="logo" src="asset/image/logo.png" alt="Logo"/>

    <div class="d-flex w-100">
      <h4 class="mr-2">{{ 'VIEW.PRELIMINARY.REGISTRATION.GETTING_STARTED' | translate }}</h4>
    </div>
    <h1 class="mb-6">{{ 'VIEW.PRELIMINARY.REGISTRATION.SUCCESS' | translate }}</h1>
    <div class="text-semibold mt-6">{{ 'VIEW.PRELIMINARY.REGISTRATION.SENT_LINK' | translate }}</div>
    <div class="mt-6">
      {{ 'VIEW.PRELIMINARY.REGISTRATION.LINK_ACTIVE_TIME' | translate }}
      <span class="text-semibold">24h.</span>
    </div>

    <div class="d-flex flex-wrap justify-content-end mt-6 control-buttons">
      <button class="btn btn-light" (click)="backToLogin()">{{ 'VIEW.PRELIMINARY.LOGIN.LOGIN' | translate }}</button>
      <button class="btn btn-primary" (click)="resendConfirmationLink()">
        {{ 'VIEW.PRELIMINARY.REGISTRATION.RESEND_CONFIRMATION' | translate }}
      </button>
    </div>
  </div>
</app-content-and-image-column-layout>
