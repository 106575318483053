import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { ApplicationConfig } from 'app/config/ApplicationConfig';
import { ApplicationErrorDTO } from 'app/data/dto/ApplicationErrorDTO';
import { Constant } from 'app/common/Constant';
import { ObjectUtil } from 'app/util/ObjectUtil';
import { ApplicationServiceInterface } from 'app/service/interface/ApplicationServiceInterface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ApplicationService implements ApplicationServiceInterface {

  constructor(private http: HttpClient) {
  }

  public sendApplicationError(applicationError: ApplicationErrorDTO): Observable<void> {
    let context: HttpContext = new HttpContext();
    context.set(Constant.HTTP_SUPPRESS_ERRORS_TOKEN, true);
    context.set(Constant.HTTP_SUPPRESS_LOADING_TOKEN, true);

    return this.http.post(`${ ApplicationConfig.apiUrl }/angularExceptions`,
      ObjectUtil.classToPlain(applicationError, true, true), { context: context })
      .pipe(
        map((response: any) => {
          return;
        })
      );
  }

}
