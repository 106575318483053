import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { StateService, Transition } from '@uirouter/core';
import { State } from 'app/common/State';
import { UserTabs } from 'app/data/enum/user/UserTabs';
import { UserType } from 'app/data/enum/user/UserType';
import { PortalUtil } from 'app/util/PortalUtil';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import { TemplatePortal } from '@angular/cdk/portal';

@Component({
  selector: 'app-user-details',
  templateUrl: './UserDetailsComponent.html',
  styleUrls: [ './UserDetailsComponent.scss' ]
})
export class UserDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('headingTemplate', { static: true })
  private readonly headingTemplate: TemplateRef<any>;

  public id: number;
  public readonly tabs: string[] = [ 'PROFILE', 'PREFERENCES' ];
  public activeTab: UserTabs = UserTabs.PROFILE;
  public UserTabs: typeof UserTabs = UserTabs;
  public UserType: typeof UserType = UserType;

  constructor(private stateService: StateService,
              private transition: Transition,
              private portalUtil: PortalUtil,
              private viewContainerRef: ViewContainerRef) {
  }

  public ngOnInit(): void {
    this.id = this.transition.params().id;

    this.portalUtil.attachPortalTo(
      MainLayoutComponent.PORTAL_OUTLET.HEADING,
      new TemplatePortal(this.headingTemplate, this.viewContainerRef)
    );
  }

  public ngOnDestroy(): void {
    this.portalUtil.detachPortalFrom(MainLayoutComponent.PORTAL_OUTLET.HEADING);
  }


  public onTabChanged(tab: string) {
    this.activeTab = tab as UserTabs;
  }

  public onBackClick(): void {
    this.stateService.go(State.MAIN.ADMIN.USER.LIST);
  }

  public onEditClick(): void {
    this.stateService.go(State.MAIN.ADMIN.USER.EDIT, { id: this.id });
  }
}
