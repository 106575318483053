import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { UserDTO } from 'app/data/dto/user/UserDTO';
import { UserModel } from 'app/model/UserModel';
import { SeniorService } from 'app/service/SeniorService';
import { SubscriptionModel } from 'app/model/SubscriptionModel';
import { RetailSubscriptionDTO } from 'app/data/enum/subscription/RetailSubscriptionDTO';

@Component({
  selector: 'app-account-billing-info',
  templateUrl: './AccountBillingInfoComponent.html'
})
export class AccountBillingInfoComponent {
  public currentUser$: Observable<UserDTO> = this.userModel.currentUserObservable$;
  public editMode: boolean = false;

  constructor(private readonly userModel: UserModel,
              public readonly subscriptionModel: SubscriptionModel,
              private readonly seniorService: SeniorService) { }

  public manageSubscription(subscription?: RetailSubscriptionDTO): void {
    if (subscription) {
      this.seniorService.getStripeUrlForMembershipManagement()
        .subscribe((url: string) => window.location.href = url);
    }
    else {
      this.subscriptionModel.openMembershipPlanModal();
    }
  }
}
