import { Injectable } from '@angular/core';
import { Token } from 'app/data/local/auth/Token';
import { ApplicationConfig } from 'app/config/ApplicationConfig';
import { AuthServiceInterface } from 'app/service/interface/AuthServiceInterface';
import { Observable, of } from 'rxjs';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
  IAuthenticationDetailsData,
  ICognitoStorage,
  ICognitoUserData,
  ICognitoUserPoolData
} from 'amazon-cognito-identity-js';
import * as _ from 'lodash';
import { SecurityDataDTO } from 'app/data/dto/auth/SecurityDataDTO';
import { CognitoTokenDTO } from 'app/data/dto/auth/CognitoTokenDTO';
import { ObjectUtil } from 'app/util/ObjectUtil';
import { ServerErrorDTO } from 'app/data/dto/ServerErrorDTO';
import { ServerErrorCode } from 'app/data/enum/ServerErrorCode';

@Injectable({ providedIn: 'root' })
export class CognitoAuthService implements AuthServiceInterface {

  private cognitoUser: CognitoUser;
  private cognitoUserAttributes: { [key: string]: any };
  private cognitoUserRequiredAttributeNames: string[];

  private rememberLogin: boolean = false;

  private nullStorage: ICognitoStorage = {
    setItem: (key: string, value: string): void => null,
    getItem: (key: string): string | null => null,
    removeItem: (key: string): void => null,
    clear: (): void => null
  };

  constructor() {
  }

  public setRememberLogin(remember: boolean): Observable<void> {
    this.rememberLogin = remember;
    return of();
  }

  public login(username: string, password: string): Observable<{ token: Token, securityData: SecurityDataDTO }> {
    const authenticationData: IAuthenticationDetailsData = {
      Username: username,
      Password: password
    };
    const authenticationDetails: AuthenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);

    let cognitoUser: CognitoUser = this.getCognitoUser(username);

    return new Observable<{ token: Token, securityData: SecurityDataDTO }>((observer) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (session: CognitoUserSession, userConfirmationNecessary: boolean) => {
          this.cognitoUser = cognitoUser;
          const token: CognitoTokenDTO = CognitoTokenDTO.fromCognitoSession(session);

          observer.next({ token: token, securityData: undefined });
          observer.complete();
        },
        newPasswordRequired: (userAttributes: { [key: string]: any }, requiredAttributes: string[]) => {
          this.cognitoUser = cognitoUser;
          this.cognitoUserAttributes = userAttributes;
          this.cognitoUserRequiredAttributeNames = requiredAttributes;

          let securityData: SecurityDataDTO = new SecurityDataDTO();
          securityData.requireNewPassword = true;

          observer.next({ token: null, securityData: securityData });
          observer.complete();
        },
        onFailure: (error: any) => {
          this.cognitoUser = null;
          this.cognitoUserAttributes = null;
          this.cognitoUserRequiredAttributeNames = null;

          if (error instanceof Error && (error.name === 'NotAuthorizedException')) {
            if (error.message.toLowerCase().includes('user is disabled')) {
              observer.error({
                error: ObjectUtil.plainToClass(ServerErrorDTO, {
                  errorCode: ServerErrorCode.ACCOUNT_IS_LOCKED
                })
              });
            }
            else {
              observer.error({
                error: ObjectUtil.plainToClass(ServerErrorDTO, {
                  errorCode: ServerErrorCode.BAD_CREDENTIALS
                })
              });
            }
          }
          else if (error instanceof Error && (error.name === 'UserNotConfirmedException')) {
            observer.error({
              error: ObjectUtil.plainToClass(ServerErrorDTO, {
                errorCode: ServerErrorCode.NOT_VERIFIED
              })
            });
          }
          else {
            if (error instanceof Error) {
              // for debugging
              console.error(`Cognito error: ${ error.name } - ${ error.message }`);
            }
            observer.error(error);
          }
        }
      });
    });
  }

  public logout(): Observable<void> {
    return new Observable<void>((observer) => {
      if (this.cognitoUser) {
        this.cognitoUser.signOut(() => {
          this.cognitoUser = null;
          this.cognitoUserAttributes = null;
          this.cognitoUserRequiredAttributeNames = null;

          observer.next();
          observer.complete();
        });
      }
      else {
        this.cognitoUserAttributes = null;
        this.cognitoUserRequiredAttributeNames = null;
        observer.error();
      }
    });
  }

  public refresh(token: Token): Observable<{ token: Token, securityData: SecurityDataDTO }> {
    return new Observable<{ token: Token, securityData: SecurityDataDTO }>((observer) => {
      if (this.cognitoUser) {
        this.cognitoUser.refreshSession((token as CognitoTokenDTO).cognitoRefreshToken, (error: any, session: CognitoUserSession) => {
          if (!error) {
            const refreshedToken: CognitoTokenDTO = CognitoTokenDTO.fromCognitoSession(session);

            observer.next({ token: refreshedToken, securityData: undefined });
            observer.complete();
          }
          else {
            this.cognitoUser = null;
            this.cognitoUserAttributes = null;
            this.cognitoUserRequiredAttributeNames = null;

            if (error instanceof Error) {
              // for debugging
              console.error(`Cognito error: ${ error.name } - ${ error.message }`);
            }

            observer.error(error);
          }
        });
      }
      else {
        this.cognitoUserAttributes = null;
        this.cognitoUserRequiredAttributeNames = null;

        observer.error();
      }
    });
  }

  public startPasswordReset(username: string): Observable<void> {
    let cognitoUser: CognitoUser = this.getCognitoUser(username);

    return new Observable<void>((observer) => {
      cognitoUser.forgotPassword({
        onSuccess: (data: any) => {
          observer.next();
          observer.complete();
        },
        inputVerificationCode: (data: any) => {
          observer.next();
          observer.complete();
        },
        onFailure: (error: any) => {
          if (error instanceof Error) {
            // for debugging
            console.error(`Cognito error: ${ error.name } - ${ error.message }`);
          }
          observer.error(error);
        }
      });
    });
  }

  public completePasswordReset(username: string, verificationCode: string, newPassword: string): Observable<void> {
    let cognitoUser: CognitoUser = this.getCognitoUser(username);

    return new Observable<void>((observer) => {
      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onSuccess: (success: string) => {
          observer.next();
          observer.complete();
        },
        onFailure: (error: any) => {
          if (error instanceof Error) {
            // for debugging
            console.error(`Cognito error: ${ error.name } - ${ error.message }`);
          }
          observer.error(error);
        }
      });
    });
  }

  public changePassword(currentPassword: string, newPassword: string): Observable<void> {
    return new Observable<void>(observer => {
      if (this.cognitoUser) {
        this.cognitoUser.changePassword(currentPassword, newPassword, (error: any, result: string) => {
          if (!error) {
            observer.next();
            observer.complete();
          }
          else {
            if (error instanceof Error && (error.name === 'NotAuthorizedException')) {
              observer.error({
                error: ObjectUtil.plainToClass(ServerErrorDTO, {
                  errorCode: ServerErrorCode.INVALID_PASSWORD
                })
              });
            }
            else {
              if (error instanceof Error) {
                // for debugging
                console.error(`Cognito error: ${ error.name } - ${ error.message }`);
              }
              observer.error(error);
            }
          }
        });
      }
      else {
        observer.error();
      }
    });
  }

  public changePasswordForced(newPassword: string): Observable<void> {
    return new Observable<void>(observer => {
      if (this.cognitoUser) {
        // it copies required attributes from this.cognitoUserAttributes, but what if some are missing?
        // (is it even possible?) seems like every time cognitoUserRequiredAttributeNames is empty anyway...
        let requiredAttributeData: { [key: string]: any } = _.zipObject(this.cognitoUserRequiredAttributeNames);
        _.forEach(_.keys(requiredAttributeData), (key: string) => {
          if (_.has(this.cognitoUserAttributes, key)) {
            requiredAttributeData[key] = this.cognitoUserAttributes[key];
          }
        });

        this.cognitoUser.completeNewPasswordChallenge(newPassword, requiredAttributeData, {
          onSuccess: (session: CognitoUserSession, userConfirmationNecessary: boolean) => {
            this.cognitoUserAttributes = null;
            this.cognitoUserRequiredAttributeNames = null;
            observer.next();
            observer.complete();
          },
          onFailure: (error: any) => {
            if (error instanceof Error) {
              // for debugging
              console.error(`Cognito error: ${ error.name } - ${ error.message }`);
            }
            observer.error(error);
          }
        });
      }
      else {
        observer.error();
      }
    });
  }

  public setToken(token: Token): Observable<Token> {
    // not needed, Cognito manages tokens automatically, using own storage
    return of(token);
  }

  public getToken(): Observable<Token> {
    return new Observable<Token>((observer) => {
      const poolData: ICognitoUserPoolData = {
        UserPoolId: ApplicationConfig.cognito.cognitoUserPoolId,
        ClientId: ApplicationConfig.cognito.cognitoClientId
      };

      if (!this.rememberLogin) {
        // don't store at all
        poolData.Storage = this.nullStorage;
        // store in the scope of current tab, as soon as you close it or open in another, it will be gone
        // poolData.Storage = window.sessionStorage;
      }

      const userPool: CognitoUserPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
      const cognitoUser: CognitoUser = userPool.getCurrentUser();

      if (cognitoUser !== null) {
        cognitoUser.getSession((error: any, session: CognitoUserSession) => {
          if (error) {
            if (error instanceof Error) {
              // for debugging
              console.error(`Cognito error: ${ error.name } - ${ error.message }`);
            }
            observer.error(error);
          }
          else {
            this.cognitoUser = cognitoUser;

            let token: CognitoTokenDTO = CognitoTokenDTO.fromCognitoSession(session);

            observer.next(token);
            observer.complete();
          }
        });
      }
      else {
        observer.error();
      }
    });
  }

  public deleteToken(): Observable<void> {
    // not needed, Cognito manages tokens automatically, using own storage
    return of();
  }

  // --

  private getCognitoUser(username: string): CognitoUser {
    const poolData: ICognitoUserPoolData = {
      UserPoolId: ApplicationConfig.cognito.cognitoUserPoolId,
      ClientId: ApplicationConfig.cognito.cognitoClientId
    };

    if (!this.rememberLogin) {
      // don't store at all
      poolData.Storage = this.nullStorage;
      // store in the scope of current tab, as soon as you close it or open in another, it will be gone
      // poolData.Storage = window.sessionStorage;
    }

    const userPool: CognitoUserPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    const userData: ICognitoUserData = {
      Username: username,
      Pool: userPool
    };

    if (!this.rememberLogin) {
      // don't store at all
      userData.Storage = this.nullStorage;
      // store in the scope of current tab, as soon as you close it or open in another, it will be gone
      // userData.Storage = window.sessionStorage;
    }

    return new AmazonCognitoIdentity.CognitoUser(userData);
  }

}
