import { Type } from 'class-transformer';
import { BaseUserDTO } from 'app/data/dto/user/BaseUserDTO';
import { UserSubscriptionDTO } from 'app/data/dto/user/UserSubscriptionDTO';

export class UserDetailsPageDTO extends BaseUserDTO {
  public email: string;

  public hasSelfRegistered?: boolean;

  public phone?: string;

  @Type(() => UserSubscriptionDTO)
  public subscription: UserSubscriptionDTO;
}