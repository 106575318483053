import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Constant } from 'app/common/Constant';
import { State } from 'app/common/State';
import { ActiveCoachDTO } from 'app/data/dto/coach/ActiveCoachDTO';
import { VideoCreateRequestDTO } from 'app/data/dto/onDemandVideo/VideoCreateRequestDTO';
import { VideoUploadResponseDTO } from 'app/data/dto/onDemandVideo/vimeo/VideoUploadResponseDTO';
import { LiveClassLanguage } from 'app/data/enum/liveClass/LiveClassLanguage';
import { VideoCategory } from 'app/data/enum/video/VideoCategory';
import { VideoSubcategories } from 'app/data/enum/video/VideoSubcategory';
import { OptionItem } from 'app/data/local/generic/OptionItem';
import { StateUtil } from 'app/util/StateUtil';
import { ViewUtil } from 'app/util/ViewUtil';
import { OnDemandVideoModel } from 'app/model/OnDemandVideoModel';
import { IntensityLevel } from 'app/data/enum/IntensityLevel';
import { PortalUtil } from 'app/util/PortalUtil';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import { TemplatePortal } from '@angular/cdk/portal';
import { ATTACHMENT_SERVICE_TOKEN } from 'app/component/ui/fileUpload/IAttachmentService';
import { OnDemandVideoAttachmentService } from 'app/component/view/main/onDemand/admin/videos/service/OnDemandVideoAttachmentService';

@Component({
  selector: 'app-on-demand-videos-admin-new',
  templateUrl: './OnDemandVideosAdminNewComponent.html',
  styleUrls: [ './OnDemandVideosAdminNewComponent.scss' ],
  providers: [ { provide: ATTACHMENT_SERVICE_TOKEN, useClass: OnDemandVideoAttachmentService } ]
})
export class OnDemandVideosAdminNewComponent implements OnInit, OnDestroy {
  @ViewChild('headingTemplate', { static: true })
  private readonly headingTemplate: TemplateRef<any>;

  public uploadStarted: boolean = false;
  public Constant: typeof Constant = Constant;
  public thumbnailFormStatus: boolean = false;

  public videoData: VideoCreateRequestDTO = new VideoCreateRequestDTO();
  // public files = [];
  public coaches: ActiveCoachDTO[];
  // public files = ['File1.pdf', 'File2.pdf', 'File3.pdf'];
  public uploadedVideo: VideoUploadResponseDTO;
  //public tabs: string[] = ['Details', 'Ratings', 'Stats'];
  public subcategories: OptionItem<string>[] = this.viewUtil.createEnumSelectOptions(
    VideoSubcategories,
    'VIDEO_SUBCATEGORY'
  );

  //TODO 2nd phase?
  public languages: OptionItem<LiveClassLanguage>[] = this.viewUtil.createEnumSelectOptions(LiveClassLanguage, 'LANGUAGE');
  public intensities: OptionItem<IntensityLevel>[] = this.viewUtil.createEnumSelectOptions(IntensityLevel, 'INTENSITY_LEVEL');
  public categories: OptionItem<VideoCategory>[] = this.viewUtil.createEnumSelectOptions(
    VideoCategory,
    'VIDEO_CATEGORY'
  );

  @ViewChild('form')
  private form: NgForm;

  constructor(private onDemandVideoModel: OnDemandVideoModel,
              private viewUtil: ViewUtil,
              public stateUtil: StateUtil,
              private portalUtil: PortalUtil,
              private viewContainerRef: ViewContainerRef) {
  }

  public ngOnInit(): void {
    this.portalUtil.attachPortalTo(
      MainLayoutComponent.PORTAL_OUTLET.HEADING,
      new TemplatePortal(this.headingTemplate, this.viewContainerRef)
    );

    this.getCoachesList();
    this.videoData.attachmentIds = [];
  }

  public ngOnDestroy(): void {
    this.portalUtil.detachPortalFrom(MainLayoutComponent.PORTAL_OUTLET.HEADING);
  }

  public save(): void {
    this.form.onSubmit(null);
    if (this.form.form.valid && this.uploadedVideo && this.thumbnailFormStatus) {
      this.videoData.vimeoVideoId = this.uploadedVideo.id;
      this.onDemandVideoModel.createVideo(this.videoData).subscribe(
        (res) => {
          this.viewUtil.showToastSuccess('COMMON.SUCCESS');
          this.stateUtil.goToState(State.MAIN.ADMIN.ON_DEMAND.DETAILS, { videoId: res.id });
        },
        (error) => {
          this.viewUtil.handleServerError(error);
        }
      );
    }
  }

  public handleStartUpload(uploadStart: boolean): void {
    this.uploadStarted = uploadStart;
  }

  public handleImageId(imageId: number): void {
    this.videoData.imageId = imageId;
  }

  public handleAttachmentIds(attachmentIds: number[]): void {
    this.videoData.attachmentIds = attachmentIds;
  }

  public onVideoUploadStatusChange(upload: VideoUploadResponseDTO): void {
    this.uploadedVideo = upload;
  }

  public handleThumbnailFormStatus(thumbnailStatus: boolean): void {
    this.thumbnailFormStatus = thumbnailStatus;
  }

  private getCoachesList(): void {
    this.onDemandVideoModel.getActiveCoachList().subscribe((coaches) => {
      this.coaches = coaches;
    });
  }
}
