import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationConfig } from 'app/config/ApplicationConfig';
import { PageDTO } from 'app/data/dto/PageDTO';
import { ProgramAdminDetailsPageDTO } from 'app/data/dto/programs/ProgramAdminDetailsPageDTO';
import { ProgramAdminPageCriteriaDTO } from 'app/data/dto/programs/ProgramAdminPageCriteriaDTO';
import { ProgramCreateDTO } from 'app/data/dto/programs/ProgramCreateDTO';
import { ProgramIdListDTO } from 'app/data/dto/programs/ProgramIdListDTO';
import { ProgramResponseDTO } from 'app/data/dto/programs/ProgramResponseDTO';
import { ProgramSeniorDetailsPageDTO } from 'app/data/dto/programs/ProgramSeniorDetailsPageDTO';
import { ProgramSeniorPageCriteriaDTO } from 'app/data/dto/programs/ProgramSeniorPageCriteriaDTO';
import { ProgramUpdateDTO } from 'app/data/dto/programs/ProgramUpdateDTO';
import { ObjectUtil } from 'app/util/ObjectUtil';
import { CustomHttpParameterEncoder } from 'app/util/other/CustomHttpParameterEncoder';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProgramSeniorResponseDTO } from 'app/data/dto/programs/ProgramSeniorResponseDTO';
import { ProgramCoachDetailsPageDTO } from 'app/data/dto/programs/ProgramCoachDetailsPageDTO';
import { ProgramCoachPageCriteriaDTO } from 'app/data/dto/programs/ProgramCoachPageCriteriaDTO';

@Injectable({
  providedIn: 'root'
})
export class ProgramService {
  constructor(private http: HttpClient) {
  }

  public createProgram(program: ProgramCreateDTO): Observable<ProgramResponseDTO> {
    return this.http
      .post(`${ ApplicationConfig.apiUrl }/programs`, ObjectUtil.classToPlain(program))
      .pipe(map((response) => ObjectUtil.plainToClass(ProgramResponseDTO, response)));
  }

  public getProgramById(id: number): Observable<ProgramResponseDTO> {
    return this.http
      .get(`${ ApplicationConfig.apiUrl }/programs/${ id }`)
      .pipe(map((response) => ObjectUtil.plainToClass(ProgramResponseDTO, response)));
  }

  public updateProgram(id: number, program: ProgramUpdateDTO): Observable<ProgramResponseDTO> {
    return this.http
      .put(`${ ApplicationConfig.apiUrl }/programs/${ id }`, ObjectUtil.classToPlain(program))
      .pipe(map((response) => ObjectUtil.plainToClass(ProgramResponseDTO, response)));
  }

  public deletePrograms(ids: ProgramIdListDTO): Observable<void> {
    return this.http.delete(`${ ApplicationConfig.apiUrl }/programs`, { body: ObjectUtil.classToPlain(ids) }).pipe(
      map(() => {
        return;
      })
    );
  }

  public getProgramPageAdmin(criteria: ProgramAdminPageCriteriaDTO): Observable<PageDTO<ProgramAdminDetailsPageDTO>> {
    const params: HttpParams = new HttpParams({
      encoder: new CustomHttpParameterEncoder(),
      fromObject: ObjectUtil.valueAsString(ObjectUtil.classToPlain(criteria, true, true, true, true))
    });

    return this.http.get(`${ ApplicationConfig.apiUrl }/programs/admins/page`, { params }).pipe(
      map((response) => {
        const page: PageDTO<ProgramAdminDetailsPageDTO> = ObjectUtil.plainToClassFromExisting(
          new PageDTO<ProgramAdminDetailsPageDTO>(ProgramAdminDetailsPageDTO),
          response
        );
        return page;
      })
    );
  }

  public getProgramPageCoach(criteria: ProgramCoachPageCriteriaDTO): Observable<PageDTO<ProgramCoachDetailsPageDTO>> {
    const params: HttpParams = new HttpParams({
      encoder: new CustomHttpParameterEncoder(),
      fromObject: ObjectUtil.valueAsString(ObjectUtil.classToPlain(criteria, true, true, true, true))
    });

    return this.http.get(`${ ApplicationConfig.apiUrl }/programs/coaches/page`, { params }).pipe(
      map((response) => {
        return ObjectUtil.plainToClassFromExisting(
          new PageDTO<ProgramCoachDetailsPageDTO>(ProgramAdminDetailsPageDTO),
          response
        );
      })
    );
  }

  public getProgramPageSenior(criteria: ProgramSeniorPageCriteriaDTO): Observable<PageDTO<ProgramSeniorDetailsPageDTO>> {
    const params: HttpParams = new HttpParams({
      encoder: new CustomHttpParameterEncoder(),
      fromObject: ObjectUtil.valueAsString(ObjectUtil.classToPlain(criteria, true, true, true, true))
    });

    return this.http.get(`${ ApplicationConfig.apiUrl }/programs/seniors/page`, { params }).pipe(
      map((response) => {
        const page: PageDTO<ProgramSeniorDetailsPageDTO> = ObjectUtil.plainToClassFromExisting(
          new PageDTO<ProgramSeniorDetailsPageDTO>(ProgramSeniorDetailsPageDTO),
          response
        );
        return page;
      })
    );
  }

  public getProgramByIdForCurrentSenior(programId: number): Observable<ProgramSeniorResponseDTO> {
    return this.http.get(`${ ApplicationConfig.apiUrl }/programs/${ programId }/seniors/details`)
      .pipe(map((response) => ObjectUtil.plainToClass(ProgramSeniorResponseDTO, response)));
  }

  public addProgramToFavorites(programIds: ProgramIdListDTO): Observable<unknown> {
    return this.http.post(`${ ApplicationConfig.apiUrl }/programs/me/favorite`, ObjectUtil.classToPlain(programIds));
  }

  public removeProgramsFromFavorites(programIds: ProgramIdListDTO): Observable<unknown> {
    return this.http.put(`${ ApplicationConfig.apiUrl }/programs/me/favorite`, ObjectUtil.classToPlain(programIds));
  }
}
