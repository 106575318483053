import { Transform, Type } from 'class-transformer';
import { ScheduledLiveClassEnrollmentParticipationDTO } from './ScheduledLiveClassEnrollmentParticipationDTO';
import { DateUtil } from 'app/util/DateUtil';
import { CoachAdminEssentialsDTO } from '../../admin/CoachAdminEssentialsDTO';
import { ScheduledLiveClassNeedDTO } from '../ScheduledLiveClassNeedDTO';
import { ImageResponseDTO } from '../../images/ImageResponseDTO';
import { ScheduledLiveClassAttachmentResponseDTO } from '../ScheduledLiveClassAttachmentResponseDTO';
import { IntensityLevel } from 'app/data/enum/IntensityLevel';
import { LiveClassCategory } from 'app/data/enum/liveClass/LiveClassCategory';

export class ScheduledLiveClassCoachDetailsResponseDTO {
  @Type(() => ScheduledLiveClassAttachmentResponseDTO)
  public attachments: ScheduledLiveClassAttachmentResponseDTO[];

  public capacity?: number;

  public category: LiveClassCategory;

  @Type(() => CoachAdminEssentialsDTO)
  public coaches: CoachAdminEssentialsDTO[];

  public description: string;

  public duration: number;

  @Type(() => ScheduledLiveClassEnrollmentParticipationDTO)
  public enrollments?: ScheduledLiveClassEnrollmentParticipationDTO[];

  public intensity: IntensityLevel;

  public language: string;

  public liveClassSeriesId?: number;

  @Type(() => ScheduledLiveClassNeedDTO)
  public scheduledLiveClassNeeds: ScheduledLiveClassNeedDTO[];

  @Transform(DateUtil.dateTimeConversion)
  public startDate: string;

  @Type(() => ImageResponseDTO)
  public thumbnail: ImageResponseDTO;

  public title: string;

  public url: string;
}
