<div *ngIf="coaches" class="coach-details row no-gutters">
  <div class="col-12 p-0 mb-4 d-flex align-items-start">
    <img
      class="rounded-circle mx-auto d-block custom-img-size"
      *ngIf="coaches[0].user?.avatar?.thumbnailAsset?.url"
      [src]="coaches[0].user.avatar.thumbnailAsset.url"
    />
    <button type="button" class="close" aria-label="Close" (click)="onCloseClick()"></button>
  </div>
  <div class="col-12 d-flex justify-content-center mb-4">
    <h1>{{ coaches[0].user | fullName }}</h1>
  </div>
  <div class="col-12 p-0 mb-4">
    <p class="m-0 font-size-lg">{{ 'VIEW.MAIN.COACHES.BIO' | translate | uppercase }}:</p>
  </div>
  <div class="col-12 p-0 mb-4">
    <p class="m-0">{{ coaches[0].bio }}</p>
  </div>
  <div class="col-12 p-0 mb-4">
    <p class="m-0">{{ 'VIEW.MAIN.COACHES.HOBBIES_PASSIONS' | translate | uppercase }}:</p>
  </div>
  <div class="col-12 p-0 mb-4">
    <ul>
      <li *ngFor="let hobby of coaches[0].coachHobbies">{{ hobby.hobby }}</li>
    </ul>
  </div>
  <div class="col-12 p-0 mb-4">
    <p class="m-0">{{ 'VIEW.MAIN.COACHES.EDUCATION' | translate | uppercase }}:</p>
  </div>
  <div class="col-12 p-0 mb-4">
    <ul>
      <li *ngFor="let education of coaches[0].coachEducations">{{ education.education }}</li>
    </ul>
  </div>
  <div class="col-12 p-0 mb-4">
    <p class="m-0">{{ 'VIEW.MAIN.COACHES.CERTIFICATIONS' | translate | uppercase }}:</p>
  </div>
  <div class="col-12 p-0 mb-4 d-flex flex-wrap">
    <ul>
      <li *ngFor="let certificate of coaches[0].coachCertifications">{{ certificate.certification }}</li>
    </ul>
  </div>
</div>
