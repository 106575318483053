import { HttpClient } from '@angular/common/http';
import { ApplicationConfig } from 'app/config/ApplicationConfig';
import { AssetCreateRequestDTO } from 'app/data/dto/asset/AssetCreateRequestDTO';
import { AssetUploadResponseDTO } from 'app/data/dto/asset/AssetUploadResponseDTO';
import { ObjectUtil } from 'app/util/ObjectUtil';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export abstract class AssetService {
  constructor(private readonly http: HttpClient) {
  }

  public createAsset(asset: AssetCreateRequestDTO): Observable<AssetUploadResponseDTO> {
    return this.http
      .post(`${ ApplicationConfig.apiUrl }/assets`, ObjectUtil.classToPlain(asset))
      .pipe(map((response) => ObjectUtil.plainToClass(AssetUploadResponseDTO, response)));
  }

  public uploadThumbnailAsset(file: File, assetResponse: AssetUploadResponseDTO): Observable<any> {
    const { url, mimeType } = assetResponse;
    return this.http
      .put(url, file, {
        headers: {
          'Content-Type': mimeType
        }
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  public uploadAttachmentAsset(file: File, assetResponse: AssetUploadResponseDTO): Observable<any> {
    const { url, mimeType } = assetResponse;
    return this.http
      .put(url, file, {
        headers: {
          'Content-Type': mimeType
        }
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
}
