import { Type } from 'class-transformer';
import { IdentifiableObjectDTO } from '../IdentifiableObjectDTO';
import { AssetPublicDownloadResponseDTO } from '../images/AssetPublicDownloadResponseDTO';
import { Attachment } from 'app/data/local/attachment/Attachment';

export class ScheduledLiveClassAttachmentResponseDTO extends IdentifiableObjectDTO<number> implements Attachment {
  @Type(() => AssetPublicDownloadResponseDTO)
  public asset: AssetPublicDownloadResponseDTO;

  public title: string;
}
