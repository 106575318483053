import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationConfig } from 'app/config/ApplicationConfig';
import { PageDTO } from 'app/data/dto/PageDTO';
import { ActiveCoachDTO } from 'app/data/dto/coach/ActiveCoachDTO';
import { VideoAttachmentCreateRequestDTO } from 'app/data/dto/onDemandVideo/VideoAttachmentCreateRequestDTO';
import { VideoAttachmentResponseDTO } from 'app/data/dto/onDemandVideo/VideoAttachmentResponseDTO';
import { VideoCreateRequestDTO } from 'app/data/dto/onDemandVideo/VideoCreateRequestDTO';
import { VideoDetailsDTO } from 'app/data/dto/onDemandVideo/VideoDetailsDTO';
import { VideoIdsDTO } from 'app/data/dto/onDemandVideo/VideoIdsDTO';
import { VideoPageCriteriaDTO } from 'app/data/dto/onDemandVideo/VideoPageCriteriaDTO';
import { VideoAdminDTO } from 'app/data/dto/onDemandVideo/admin/VideoAdminDTO';
import { VideoCoachDTO } from 'app/data/dto/onDemandVideo/coach/VideoCoachDTO';
import { VideoSeniorDTO } from 'app/data/dto/onDemandVideo/senior/VideoSeniorDTO';
import { VideoSeniorRecommendedDTO } from 'app/data/dto/onDemandVideo/senior/VideoSeniorRecommendedDTO';
import { VideoUploadRequestDTO } from 'app/data/dto/onDemandVideo/vimeo/VideoUploadRequestDTO';
import { VideoUploadResponseDTO } from 'app/data/dto/onDemandVideo/vimeo/VideoUploadResponseDTO';
import { ObjectUtil } from 'app/util/ObjectUtil';
import { CustomHttpParameterEncoder } from 'app/util/other/CustomHttpParameterEncoder';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PageCriteriaDTO } from 'app/data/dto/PageCriteriaDTO';
import { VideoEssentialsAdminPageDTO } from 'app/data/dto/onDemandVideo/admin/VideoEssentialsAdminPageDTO';
import { OnDemandVideoServiceInterface } from './interface/OnDemandVideoServiceInterface';
import { PlatformDataRequestDTO } from 'app/data/dto/PlatformDataRequestDTO';
import { VideoRatingAdminPageCriteriaDTO } from 'app/data/dto/onDemandVideo/admin/VideoRatingAdminPageCriteriaDTO';
import { VideoRatingAdminPageDTO } from 'app/data/dto/onDemandVideo/admin/VideoRatingAdminPageDTO';
import { VideoRatingRequestDTO } from 'app/data/dto/onDemandVideo/VideoRatingRequestDTO';
import { VideoRatingResponseDTO } from 'app/data/dto/onDemandVideo/VideoRatingResponseDTO';

@Injectable({
  providedIn: 'root'
})
export class OnDemandVideoService implements OnDemandVideoServiceInterface {
  constructor(private http: HttpClient) {
  }

  public deleteVideos(videoIds: VideoIdsDTO): Observable<unknown> {
    return this.http.delete(`${ ApplicationConfig.apiUrl }/videos`, { body: ObjectUtil.classToPlain(videoIds) });
  }

  public createVideo(video: VideoCreateRequestDTO): Observable<VideoDetailsDTO> {
    return this.http.post(`${ ApplicationConfig.apiUrl }/videos`, ObjectUtil.classToPlain(video)).pipe(
      map((response: any) => {
        const video: VideoDetailsDTO = ObjectUtil.plainToClass(VideoDetailsDTO, response);
        return video;
      })
    );
  }

  public addVideosToFavorites(videoIds: VideoIdsDTO): Observable<unknown> {
    return this.http.post(`${ ApplicationConfig.apiUrl }/videos/me/favorite`, ObjectUtil.classToPlain(videoIds));
  }

  public removeVideosFromFavorites(videoIds: VideoIdsDTO): Observable<unknown> {
    return this.http.put(`${ ApplicationConfig.apiUrl }/videos/me/favorite`, ObjectUtil.classToPlain(videoIds));
  }

  public sendVideoPlayedEvent(videoId: number, platform: PlatformDataRequestDTO): Observable<unknown> {
    return this.http.post(`${ ApplicationConfig.apiUrl }/videos/play/${ videoId }`, ObjectUtil.classToPlain(platform));
  }

  public markVideosAsRecommended(videoIds: VideoIdsDTO, recommend: boolean): Observable<unknown> {
    return this.http.post(
      `${ ApplicationConfig.apiUrl }/videos/recommended/${ recommend }`,
      ObjectUtil.classToPlain(videoIds)
    );
  }

  public getVideo(videoId: number): Observable<VideoDetailsDTO> {
    return this.http.get(`${ ApplicationConfig.apiUrl }/videos/${ videoId }`).pipe(
      map((response: any) => {
        const video: VideoDetailsDTO = ObjectUtil.plainToClass(VideoDetailsDTO, response);
        return video;
      })
    );
  }

  public updateVideo(video: VideoCreateRequestDTO, videoId: number): Observable<VideoDetailsDTO> {
    return this.http.put(`${ ApplicationConfig.apiUrl }/videos/${ videoId }`, ObjectUtil.classToPlain(video)).pipe(
      map((response: any) => {
        const video: VideoDetailsDTO = ObjectUtil.plainToClass(VideoDetailsDTO, response);
        return video;
      })
    );
  }

  public getVideosPageAdmin(criteria: VideoPageCriteriaDTO): Observable<PageDTO<VideoAdminDTO>> {
    const params: HttpParams = new HttpParams({
      encoder: new CustomHttpParameterEncoder(),
      fromObject: ObjectUtil.valueAsString(ObjectUtil.classToPlain(criteria, true, true, true, true))
    });

    return this.http.get(`${ ApplicationConfig.apiUrl }/videos/admins/page`, { params: params }).pipe(
      map((response: any) => {
        const page: PageDTO<VideoAdminDTO> = ObjectUtil.plainToClassFromExisting(
          new PageDTO<VideoAdminDTO>(VideoAdminDTO),
          response
        );
        return page;
      })
    );
  }

  public getVideosEssentialsPage(criteria: PageCriteriaDTO): Observable<PageDTO<VideoEssentialsAdminPageDTO>> {
    const params: HttpParams = new HttpParams({
      encoder: new CustomHttpParameterEncoder(),
      fromObject: ObjectUtil.valueAsString(ObjectUtil.classToPlain(criteria, true, true, true, true))
    });

    return this.http.get(`${ ApplicationConfig.apiUrl }/videos/essentials/admins/page`, { params }).pipe(
      map((response) => {
        return ObjectUtil.plainToClassFromExisting(new PageDTO<VideoEssentialsAdminPageDTO>(VideoEssentialsAdminPageDTO),
          response);
      })
    );
  }

  public getVideosPageSenior(criteria: VideoPageCriteriaDTO): Observable<PageDTO<VideoSeniorDTO>> {
    const params: HttpParams = new HttpParams({
      encoder: new CustomHttpParameterEncoder(),
      fromObject: ObjectUtil.valueAsString(ObjectUtil.classToPlain(criteria, true, true, true, true))
    });

    return this.http.get(`${ ApplicationConfig.apiUrl }/videos/seniors/page`, { params: params }).pipe(
      map((response: any) => {
        const page: PageDTO<VideoSeniorDTO> = ObjectUtil.plainToClassFromExisting(
          new PageDTO<VideoSeniorDTO>(VideoSeniorDTO),
          response
        );
        return page;
      })
    );
  }

  public getVideosPageCoach(criteria: VideoPageCriteriaDTO): Observable<PageDTO<VideoCoachDTO>> {
    const params: HttpParams = new HttpParams({
      encoder: new CustomHttpParameterEncoder(),
      fromObject: ObjectUtil.valueAsString(ObjectUtil.classToPlain(criteria, true, true, true, true))
    });

    return this.http.get(`${ ApplicationConfig.apiUrl }/videos/coaches/page`, { params: params }).pipe(
      map((response: any) => {
        const page: PageDTO<VideoCoachDTO> = ObjectUtil.plainToClassFromExisting(
          new PageDTO<VideoCoachDTO>(VideoCoachDTO),
          response
        );
        return page;
      })
    );
  }

  public getActiveCoachList(): Observable<ActiveCoachDTO[]> {
    return this.http.get(`${ ApplicationConfig.apiUrl }/coaches/list`).pipe(
      map((response: any) => {
        const coaches: ActiveCoachDTO[] = ObjectUtil.plainToClassArray(ActiveCoachDTO, response);
        return coaches;
      })
    );
  }

  public getRecommendedVideosRandom(): Observable<VideoSeniorRecommendedDTO[]> {
    return this.http.get(`${ ApplicationConfig.apiUrl }/videos/recommended/random`).pipe(
      map((response: any) => {
        const video: VideoSeniorRecommendedDTO[] = ObjectUtil.plainToClassArray(VideoSeniorRecommendedDTO, response);
        return video;
      })
    );
  }

  public getRecommendedVideosAll(): Observable<VideoSeniorRecommendedDTO[]> {
    return this.http.get(`${ ApplicationConfig.apiUrl }/videos/recommended/all`).pipe(
      map((response: any) => {
        const video: VideoSeniorRecommendedDTO[] = ObjectUtil.plainToClassArray(VideoSeniorRecommendedDTO, response);
        return video;
      })
    );
  }

  public createVimeoUploadRequest(request: VideoUploadRequestDTO): Observable<VideoUploadResponseDTO> {
    return this.http.post(`${ ApplicationConfig.apiUrl }/vimeovideos`, ObjectUtil.classToPlain(request)).pipe(
      map((response: any) => {
        const uploadResponse: VideoUploadResponseDTO = ObjectUtil.plainToClass(VideoUploadResponseDTO, response);
        return uploadResponse;
      })
    );
  }

  public deleteVimeoPlaceholderVideo(videoId: number): Observable<unknown> {
    return this.http.delete(`${ ApplicationConfig.apiUrl }/vimeovideos/${ videoId }`);
  }

  public createAttachmentAsset(asset: VideoAttachmentCreateRequestDTO): Observable<VideoAttachmentResponseDTO> {
    return this.http.post(`${ ApplicationConfig.apiUrl }/videoattachments`, ObjectUtil.classToPlain(asset)).pipe(
      map((response: any) => {
        const attachment: VideoAttachmentResponseDTO = ObjectUtil.plainToClass(VideoAttachmentResponseDTO, response);
        return attachment;
      })
    );
  }

  public getVideoRatingPage(criteria: VideoRatingAdminPageCriteriaDTO): Observable<PageDTO<VideoRatingAdminPageDTO>> {
    const params: HttpParams = new HttpParams({
      encoder: new CustomHttpParameterEncoder(),
      fromObject: ObjectUtil.valueAsString(ObjectUtil.classToPlain(criteria, true, true, true, true))
    });

    return this.http.get(`${ ApplicationConfig.apiUrl }/videoratings/admins/page`, { params: params }).pipe(
      map((response: any) => {
        return ObjectUtil.plainToClassFromExisting(new PageDTO<VideoRatingAdminPageDTO>(VideoRatingAdminPageDTO), response);
      })
    );
  }

  public rateVideo(videoId: number, rating: VideoRatingRequestDTO): Observable<VideoRatingResponseDTO> {
    return this.http.put(`${ ApplicationConfig.apiUrl }/videos/${ videoId }/me/rate`, ObjectUtil.classToPlain(rating)).pipe(
      map((response: any) => {
        return ObjectUtil.plainToClass(VideoRatingResponseDTO, response);
      })
    );
  }
}
