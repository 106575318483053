import { VideoCoachRatingDetailsDTO } from 'app/data/dto/onDemandVideo/coach/VideoCoachRatingDetailsDTO';
import { Type } from 'class-transformer';

export class VideoRatingCoachDTO {
  public feedback: string;

  public rating: number;

  @Type(() => VideoCoachRatingDetailsDTO)
  public video: VideoCoachRatingDetailsDTO;
}