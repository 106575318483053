import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationConfig } from 'app/config/ApplicationConfig';
import { CurrentUserFeedbackRequestDTO } from 'app/data/dto/feedback/CurrentUserFeedbackRequestDTO';
import { ObjectUtil } from 'app/util/ObjectUtil';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class FeedbackService {
  constructor(private http: HttpClient) {
  }

  public sendUserFeedback(feedback: CurrentUserFeedbackRequestDTO): Observable<void> {
    return this.http.post(`${ ApplicationConfig.apiUrl }/users/feedback`, ObjectUtil.classToPlain(feedback)).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
}
