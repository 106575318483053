import { DateUtil } from 'app/util/DateUtil';
import { PageCriteriaDTO } from '../PageCriteriaDTO';
import { Transform } from 'class-transformer';
import { LiveClassCategory } from 'app/data/enum/liveClass/LiveClassCategory';
import { DayOfWeek } from 'app/data/enum/DayOfWeek';
import { TimeOfDay } from 'app/data/enum/TimeOfDay';
import { IntensityLevel } from 'app/data/enum/IntensityLevel';

export class ScheduledLiveClassSeniorPageCriteriaDTO extends PageCriteriaDTO {
  public anyOfCategories?: LiveClassCategory[];

  public anyOfCoachIds?: number[];

  public anyOfIntensities?: IntensityLevel[];

  public anyOfLanguages?: string[];

  public consecutiveDays?: number; // from 1 to 30

  public daysOfWeek?: DayOfWeek[];

  public favorites?: boolean;

  public hasNotEnded: boolean = true;

  @Transform(DateUtil.dateConversion)
  public selectedDate: Date;

  public timesOfDay: TimeOfDay[];
}
