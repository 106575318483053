import { Type } from 'class-transformer';
import { IdentifiableObjectDTO } from '../IdentifiableObjectDTO';
import { ImageResponseDTO } from '../images/ImageResponseDTO';

export class VideoEssentialsResponseDTO extends IdentifiableObjectDTO<number> {
  public title: string;

  @Type(() => ImageResponseDTO)
  public thumbnail: ImageResponseDTO;
}
