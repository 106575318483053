import { IAttachmentService } from 'app/component/ui/fileUpload/IAttachmentService';
import { Asset, Attachment } from 'app/data/local/attachment/Attachment';
import { Observable } from 'rxjs';
import { LiveClassSeriesService } from 'app/service/LiveClassSeriesService';
import { Injectable } from '@angular/core';

@Injectable()
export class LiveClassSeriesAttachmentService implements IAttachmentService {
  constructor(private readonly liveClassSeriesService: LiveClassSeriesService) {}

  public createAttachment(asset: Asset): Observable<Attachment> {
    return this.liveClassSeriesService.createAttachmentAsset(asset);
  }
}