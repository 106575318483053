import { ScheduledLiveClassDetailsResponseDTO } from 'app/data/dto/scheduledLiveClass/admin/ScheduledLiveClassDetailsResponseDTO';
import { WatchPartyDTO } from 'app/data/dto/watchParty/WatchPartyDTO';
import { Type } from 'class-transformer';

export class MyScheduleResponseDTO {
  @Type(() => ScheduledLiveClassDetailsResponseDTO)
  public scheduledLiveClasses: ScheduledLiveClassDetailsResponseDTO[];

  @Type(() => WatchPartyDTO)
  public watchParties: WatchPartyDTO[];
}