export enum ServerErrorCode {

  // general

  INVALID_ID          = 'INVALID_ID',
  INVALID_BODY        = 'INVALID_BODY',
  INVALID_EMAIL       = 'INVALID_EMAIL',
  DUPLICATED_EMAIL    = 'DUPLICATED_EMAIL',
  DUPLICATE_USER_EMAIL= 'DUPLICATE_USER_EMAIL', // which is correct?
  NO_PERMISSION       = 'NO_PERMISSION',
  NOT_FOUND           = 'NOT_FOUND',
  NOT_VERIFIED        = 'NOT_VERIFIED',
  UNKNOWN_ERROR       = 'UNKNOWN_ERROR',

  // auth

  BAD_CREDENTIALS       = 'BAD_CREDENTIALS',
  INVALID_PASSWORD      = 'INVALID_PASSWORD',
  INCORRECT_PASSWORD    = 'INCORRECT_PASSWORD', // which is correct?
  INVALID_CREDENTIALS   = 'INVALID_CREDENTIALS',
  INVALID_TOKEN         = 'INVALID_TOKEN',
  INVALID_GRANT         = 'INVALID_GRANT',
  INVALID_GRANT_TYPE    = 'INVALID_GRANT_TYPE',
  ACCOUNT_IS_LOCKED     = 'ACCOUNT_IS_LOCKED',

  // upload

  CANNOT_UPLOAD_FILE = 'CANNOT_UPLOAD_FILE',  // still used?

  // password management

  USER_PASSWORD_RESET_TOKEN_NOT_FOUND = 'USER_PASSWORD_RESET_TOKEN_NOT_FOUND',
  INCORRECT_OLD_PASSWORD = 'INCORRECT_OLD_PASSWORD',
  NEW_PASSWORD_CANNOT_BE_THE_SAME_AS_OLD_PASSWORD = 'NEW_PASSWORD_CANNOT_BE_THE_SAME_AS_OLD_PASSWORD',

  //registration

  DUPLICATE_EMAIL = 'DUPLICATE_EMAIL',
  DUPLICATE_MEMBER_CODE = 'DUPLICATE_MEMBER_CODE',
  ORGANIZATION_MEMBERSHIP_NOT_FOUND = 'ORGANIZATION_MEMBERSHIP_NOT_FOUND',
  ORGANIZATION_MEMBERSHIP_INACTIVE = 'ORGANIZATION_MEMBERSHIP_INACTIVE',
  ORGANIZATION_MEMBERSHIP_INCOMPLETE_DATA = 'ORGANIZATION_MEMBERSHIP_INCOMPLETE_DATA'

}
