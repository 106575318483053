import { Type } from 'class-transformer';
import { IdentifiableObjectDTO } from '../IdentifiableObjectDTO';
import { CoachEssentialsDTO } from '../coach/CoachEssentialsDTO';

export class VideoUnratedResponseDTO extends IdentifiableObjectDTO<number> {
  @Type(() => CoachEssentialsDTO)
  public coaches: CoachEssentialsDTO[];

  public title: string;
}
