import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApplicationConfig } from 'app/config/ApplicationConfig';
import { FeedbackCategory } from 'app/data/enum/feedback/FeedbackCategory';
import { OptionItem } from 'app/data/local/generic/OptionItem';
import { UserModel } from 'app/model/UserModel';
import { ViewUtil } from 'app/util/ViewUtil';
import { Subject, throwError } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-help-contact',
  templateUrl: './HelpContactComponent.html',
  styleUrls: [ './HelpContactComponent.scss' ]
})
export class HelpContactComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();

  public questionCategories: OptionItem<FeedbackCategory>[] = this.viewUtil.createEnumSelectOptions(
    FeedbackCategory,
    'FEEDBACK_CATEGORY'
  );

  public submitted: boolean = false;
  public form: FormGroup;

  constructor(private viewUtil: ViewUtil,
              private userModel: UserModel) {
  }

  public ngOnInit(): void {
    this.setupForm();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public submit(): void {
    this.submitted = true;
    if (this.form.valid) {
      const { category, title, content } = this.form.value;
      const applicationVersion = ApplicationConfig.version;
      this.userModel
        .sendUserFeedback({ applicationVersion, category, content, title })
        .pipe(
          takeUntil(this.destroy$),
          tap(() => {
            this.viewUtil.showToastSuccess('COMMON.FEEDBACK_SENT_SUCCESSFULLY');
            this.submitted = false;
          }),
          catchError((err) => {
            this.viewUtil.handleServerError(err);
            return throwError(() => err);
          })
        )
        .subscribe();
    }
  }

  public onFacebookClick(): void {
    window.open('https://www.facebook.com/EncoreWellnessEverywhere/', '_blank');
  }

  private setupForm(): void {
    FeedbackCategory;
    this.form = new FormGroup({
      category: new FormControl(null, Validators.required),
      title: new FormControl('', Validators.required),
      content: new FormControl('', Validators.required)
    });
  }
}
