import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-page-header',
  templateUrl: './PageHeaderComponent.html',
  styleUrls: [ './PageHeaderComponent.scss' ]
})
export class PageHeaderComponent {

  @Input()
  public translationKey: string;

  constructor() {
  }

}
