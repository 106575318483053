<ng-container *ngIf="currentUser$ | async as currentUser">
  <div class="page">
    <ng-container *ngIf="!editMode">
      <section>
        <div class="header align-items-center justify-content-between">
          <div class="text">{{ 'VIEW.MAIN.ACCOUNT.BILLING_INFO.CURRENT_SUBSCRIPTION.TITLE' | translate }}</div>
          <button type="button" *ngIf="subscriptionModel.subscriptionRetailType"
                  class="btn btn-primary"
                  (click)="editMode = true">{{ 'VIEW.MAIN.ACCOUNT.BILLING_INFO.CURRENT_SUBSCRIPTION.ACTION.CHANGE' | translate }}
          </button>
        </div>

        <div>{{ currentUser.subscription.type | prefix: 'VIEW.MAIN.ACCOUNT.BILLING_INFO.CURRENT_SUBSCRIPTION.SUBSCRIPTION.' |  translate:{name: currentUser.subscription?.organization?.name} }}</div>
      </section>

      <ng-container *ngIf="subscriptionModel.subscriptionRetailType">
        <hr>
        <!--        Active subscription & Not canceled-->
        <ng-container *ngIf="subscriptionModel.isSubscriptionActive && subscriptionModel.subscriptionPlanSelected && !currentUser.subscription.retailSubscriptionDetails.canceled">
          <ng-container [ngTemplateOutlet]="activeMembershipViewTemplate"
                        [ngTemplateOutletContext]="{$implicit: currentUser.subscription.retailSubscriptionDetails}"></ng-container>
        </ng-container>

        <!--        Not chosen subscription-->
        <ng-container *ngIf="subscriptionModel.isSubscriptionInactive && !subscriptionModel.subscriptionPlanSelected">
          <ng-container [ngTemplateOutlet]="notChosenMembershipViewTemplate"></ng-container>
        </ng-container>

        <!--         Canceled Subscription -->
        <ng-container *ngIf="subscriptionModel.subscriptionPlanSelected && currentUser.subscription.retailSubscriptionDetails.canceled">
          <ng-container [ngTemplateOutlet]="canceledMembershipViewTemplate"
                        [ngTemplateOutletContext]="{$implicit: currentUser.subscription.retailSubscriptionDetails}"></ng-container>
        </ng-container>
      </ng-container>
    </ng-container>


    <!--Edit mode-->
    <ng-container *ngIf="editMode">
      <app-account-subscription-change-partial
        [(editMode)]="editMode"></app-account-subscription-change-partial>
    </ng-container>
  </div>


  <!--  Templates-->
  <ng-template #activeMembershipViewTemplate let-membershipDetails>
    <section>
      <div class="header align-items-center justify-content-between">
        <span class="text">{{ 'VIEW.MAIN.ACCOUNT.BILLING_INFO.MEMBERSHIP.PLAN' | translate }}</span>
        <button type="button"
                class="btn btn-outline-primary"
                (click)="manageSubscription(membershipDetails)">
          {{ 'VIEW.MAIN.ACCOUNT.BILLING_INFO.MEMBERSHIP.ACTION.CHANGE' | translate }}
        </button>
      </div>

      <div>{{ membershipDetails.name }}</div>

      <div class="header mt-6">{{ 'VIEW.MAIN.ACCOUNT.BILLING_INFO.MEMBERSHIP.EXPIRATION_DATE' | translate }}</div>
      <div>{{ membershipDetails.endPeriod | date }}</div>
    </section>

    <ng-container *ngIf="currentUser.subscription.retailSubscriptionDetails as retailSubscriptionDetails">
      <hr>

      <section>
        <div class="header">{{ 'VIEW.MAIN.ACCOUNT.BILLING_INFO.CANCEL_SUBSCRIPTION.TITLE' | translate }}</div>

        <div>
          <div [innerHTML]="'VIEW.MAIN.ACCOUNT.BILLING_INFO.CANCEL_SUBSCRIPTION.DESCRIPTION' | translate"></div>
          <button type="button"
                  class="btn btn-outline-primary mt-6"
                  (click)="manageSubscription(retailSubscriptionDetails)">{{ 'VIEW.MAIN.ACCOUNT.BILLING_INFO.CANCEL_SUBSCRIPTION.ACTION.CANCEL' | translate }}
          </button>
        </div>
      </section>
    </ng-container>
  </ng-template>

  <ng-template #notChosenMembershipViewTemplate>
    <section>
      <div class="header align-items-center justify-content-between">
        <span class="text">{{ 'VIEW.MAIN.ACCOUNT.BILLING_INFO.MEMBERSHIP.PLAN' | translate }}</span>
        <button type="button"
                class="btn btn-outline-primary"
                (click)="manageSubscription()">
          {{ 'VIEW.MAIN.ACCOUNT.BILLING_INFO.MEMBERSHIP.ACTION.CHOOSE' | translate }}
        </button>
      </div>
    </section>
  </ng-template>

  <ng-template #canceledMembershipViewTemplate let-membershipDetails>
    <section>
      <div class="header align-items-center justify-content-between">
        <span class="text">{{ 'VIEW.MAIN.ACCOUNT.BILLING_INFO.MEMBERSHIP.PLAN' | translate }}</span>

        <div class="d-flex gap-4">
          <button type="button"
                  *ngIf="subscriptionModel.isSubscriptionActive"
                  class="btn btn-outline-primary"
                  (click)="manageSubscription(membershipDetails)">
            {{ 'VIEW.MAIN.ACCOUNT.BILLING_INFO.MEMBERSHIP.ACTION.RENEW' | translate }}
          </button>

          <button type="button"
                  *ngIf="subscriptionModel.isSubscriptionInactive"
                  class="btn btn-outline-primary"
                  (click)="manageSubscription()">
            {{ 'VIEW.MAIN.ACCOUNT.BILLING_INFO.MEMBERSHIP.ACTION.RENEW' | translate }}
          </button>

          <button type="button"
                  class="btn btn-primary"
                  (click)="manageSubscription(membershipDetails)">{{ 'VIEW.MAIN.ACCOUNT.BILLING_INFO.MEMBERSHIP.ACTION.BILLING_HISTORY' | translate }}
          </button>
        </div>
      </div>

      <div>{{ membershipDetails.name }}</div>

      <div class="header mt-6">{{ 'VIEW.MAIN.ACCOUNT.BILLING_INFO.MEMBERSHIP.ENJOY_PLATFORM_TILL' | translate }}</div>
      <div>{{ membershipDetails.endPeriod | date }}</div>
      <div class="color-red">{{ 'VIEW.MAIN.ACCOUNT.BILLING_INFO.MEMBERSHIP.MEMBERSHIP_CANCELED' | translate }}</div>
    </section>
  </ng-template>
</ng-container>
