<app-content-and-image-column-layout *ngIf="userRegistration" [imageSrc]="'asset/image/register-background.jpg'">
  <div id="content" class="p-4 d-flex flex-column">
    <img class="logo" src="asset/image/logo.png" alt="Logo"/>

    <div class="d-flex justify-content-between w-100">
      <h4 class="mr-2">{{ 'VIEW.PRELIMINARY.REGISTRATION.GETTING_STARTED' | translate }}</h4>
      <h4 *ngIf="currentStep && noOfSteps">step {{ currentStep }} of {{ noOfSteps }}</h4>
    </div>
    <h1 class="mb-6">{{ 'VIEW.PRELIMINARY.REGISTRATION.CREATE_PASSWORD' | translate }}</h1>

    <form #registrationForm="ngForm" class="row mb-6" novalidate (keyup.enter)="register()">
      <div class="form-group col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <label for="password">{{ 'VIEW.PRELIMINARY.REGISTRATION.PASSWORD' | translate }}</label>
        <input
          type="password"
          id="password"
          name="password"
          #password="ngModel"
          class="form-control"
          placeholder="{{ 'VIEW.PRELIMINARY.REGISTRATION.PASSWORD' | translate }}"
          [ngClass]="{'is-invalid': registrationForm.submitted && password.invalid, 'is-valid': registrationForm.submitted && password.valid }"
          [(ngModel)]="userRegistration.user.password"
          appPasswordRequirements
          appInputPasswordToggle
          required
          [pattern]="Constant.PASSWORD_PATTERN"
        />
        <div class="invalid-feedback" *ngIf="registrationForm.submitted && password.invalid">
          <div *ngIf="password.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
          <div *ngIf="password.errors['pattern']">
            {{ 'VALIDATION.PASSWORD' | translate: { passwordLength: Constant.PASSWORD_MIN_LENGTH } }}
          </div>
          <div *ngIf="password.errors?.passwordRequirements?.maxLength">
            {{ 'VALIDATION.PASSWORD_REQUIREMENTS.MAX_LENGTH' | translate: { max: Constant.PASSWORD_MAX_LENGTH_256 } }}
          </div>
        </div>
      </div>

      <div class="form-group col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <label for="passwordRepeat">{{ 'VIEW.PRELIMINARY.REGISTRATION.REPEAT_PASSWORD' | translate }}</label>
        <input
          type="password"
          id="passwordRepeat"
          name="passwordRepeat"
          #passwordRepeat="ngModel"
          class="form-control"
          placeholder="{{ 'VIEW.PRELIMINARY.REGISTRATION.REPEAT_PASSWORD' | translate }}"
          [ngClass]="{'is-invalid': registrationForm.submitted && passwordRepeat.invalid, 'is-valid': registrationForm.submitted && passwordRepeat.valid }"
          [(ngModel)]="formData.passwordRepeat"
          appInputPasswordToggle
          appPasswordRequirements
          required
          [pattern]="Constant.PASSWORD_PATTERN"
          [equalTo]="password.control"
        />
        <div class="invalid-feedback" *ngIf="registrationForm.submitted && passwordRepeat.invalid">
          <div *ngIf="passwordRepeat.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
          <div *ngIf="passwordRepeat.errors['pattern']">
            {{ 'VALIDATION.PASSWORD' | translate: { passwordLength: Constant.PASSWORD_MIN_LENGTH } }}
          </div>
          <div *ngIf="passwordRepeat.errors['equalTo']">{{ 'VALIDATION.PASSWORD_MATCH' | translate }}</div>
          <div *ngIf="passwordRepeat.errors?.passwordRequirements?.maxLength">
            {{ 'VALIDATION.PASSWORD_REQUIREMENTS.MAX_LENGTH' | translate: { max: Constant.PASSWORD_MAX_LENGTH_256 } }}
          </div>
        </div>
      </div>
      <div class="password-requirements col-12">
        <div
          class="password-req-item"
          [ngClass]="{'is-invalid': !password.value || password.errors?.passwordRequirements?.minLength}"
        >
          {{ 'VALIDATION.PASSWORD_REQUIREMENTS.MIN_LENGTH' | translate: { min: Constant.PASSWORD_MIN_LENGTH } }}
        </div>
        <div
          class="password-req-item"
          [ngClass]="{'is-invalid': !password.value || password.errors?.passwordRequirements?.upperCase}"
        >
          {{ 'VALIDATION.PASSWORD_REQUIREMENTS.UPPERCASE' | translate }}
        </div>
        <div
          class="password-req-item"
          [ngClass]="{'is-invalid': !password.value || password.errors?.passwordRequirements?.lowerCase}"
        >
          {{ 'VALIDATION.PASSWORD_REQUIREMENTS.LOWERCASE' | translate }}
        </div>
        <div
          class="password-req-item"
          [ngClass]="{'is-invalid': !password.value || password.errors?.passwordRequirements?.numberChar}"
        >
          {{ 'VALIDATION.PASSWORD_REQUIREMENTS.NUMBER' | translate }}
        </div>
        <div
          class="password-req-item"
          [ngClass]="{'is-invalid': !password.value || password.errors?.passwordRequirements?.specialChar}"
        >
          {{ 'VALIDATION.PASSWORD_REQUIREMENTS.SPECIAL' | translate }}
        </div>
      </div>
    </form>

    <div>
      {{ 'VIEW.PRELIMINARY.REGISTRATION.AGREE_TERMS' | translate }}
      <span type="button" (click)="onResourceClick(termsAndConditionsUrl)" class="font-weight-bold link"
      >Encore Wellness Terms</span
      >
      {{ 'COMMON.AND' | translate }}
      <span type="button" (click)="onResourceClick(privacyPolicyUrl)" class="font-weight-bold link">Privacy policy</span
      >.
    </div>

    <div class="d-flex justify-content-end mt-6 control-buttons">
      <button class="btn btn-light mr-2 step-btn" (click)="goBack()">{{ 'COMMON.BACK' | translate }}</button>
      <button class="btn btn-primary step-btn" (click)="register()">
        {{ 'VIEW.PRELIMINARY.REGISTRATION.REGISTER' | translate }}
      </button>
    </div>
  </div>
</app-content-and-image-column-layout>
