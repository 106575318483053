<div class="modal-header">
  <h4 class="modal-title">{{ title | translate }}</h4>
  <div class="modal-control">
    <button type="button" class="btn" (click)="close()">
      <svg-icon class="d-flex" src="asset/image/icon/x.svg"></svg-icon>
    </button>
  </div>
</div>

<div class="modal-body">

  <form #fileForm="ngForm" novalidate>

    <div class="row" *ngIf="useName">
      <div class="col-12">
        <div class="form-group">
          <label for="name">{{ 'COMPONENT.FILE_UPLOAD.NAME' | translate }}</label>
          <input type="text" id="name" name="name" #name="ngModel" class="form-control"
                 placeholder="{{ 'COMPONENT.FILE_UPLOAD.NAME' | translate }}"
                 [ngClass]="{'is-invalid': fileForm.submitted && name.invalid, 'is-valid': fileForm.submitted && name.valid }"
                 [(ngModel)]="formData.name" required>
          <div class="invalid-feedback" *ngIf="fileForm.submitted && name.invalid">
            <div *ngIf="name.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
            <div *ngIf="name.errors['maxlength']">{{ 'VALIDATION.MAXLENGTH' | translate:{ requiredLength: name.errors.maxlength.requiredLength } }}</div>
            <div *ngIf="name.errors['minlength']">{{ 'VALIDATION.MINLENGTH' | translate:{ requiredLength: name.errors.minlength.requiredLength } }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label for="fileModel">{{ 'COMPONENT.FILE_UPLOAD.FILE' | translate }}</label>
          <div class="custom-file" ng2FileDrop [uploader]="formData.file.fileUploader"
               (fileOver)="formData.file.dropOver = $event"
               (onFileDrop)="onFileChange($event[0], formData.file)"
               [ngClass]="{'is-invalid': fileForm.submitted && fileModel.invalid, 'is-valid': fileForm.submitted && fileModel.valid }">
            <input type="file" class="custom-file-input" name="fileModel" id="fileModel"
                   #fileElement #fileModel="ngModel"
                   [ngClass]="{'is-invalid': fileForm.submitted && fileModel.invalid, 'is-valid': fileForm.submitted && fileModel.valid }"
                   [(ngModel)]="formData.file.file" (ngModelChange)="onFileChange($event, formData.file)"
                   [appFileAllowedExtensions]="allowedExtensions" [appFileMaxSize]="maxFileSize" appFileRequired>
            <label class="custom-file-label with-custom-file-button with-drop"
                   [ngClass]="{ 'file-over': formData.file.dropOver, 'empty': !formData.file.file, 'with-custom-file-clear-button': formData.file.file }">
              <ng-container
                *ngIf="!formData.file.file">{{ 'COMPONENT.FILE_UPLOAD.NO_FILE' | translate }}
              </ng-container>
              <div class="d-flex align-items-center" *ngIf="formData.file.file">
                <svg-icon class="d-flex" src="asset/image/icon/check.svg" *ngIf="formData.file.uploaded"></svg-icon>
                <span [ngClass]="{ 'ml-2': formData.file.uploaded }">{{ formData.file.name }}</span>
              </div>
            </label>
            <button class="custom-file-button btn btn-sm btn-primary" type="button">
              <div class="d-flex flex-row align-items-center justify-content-center">
                <svg-icon class="d-flex mr-2" src="asset/image/icon/file-upload.svg"></svg-icon>
                <span>{{ 'COMPONENT.FILE_UPLOAD.CHOOSE_FILE' | translate }}</span>
              </div>
            </button>
            <button *ngIf="formData.file.file && formData.file.uploaded"
                    class="custom-file-clear-button btn btn-sm btn-outline-primary" type="button"
                    (click)="resetFile(fileElement, formData.file)">
              <span>×</span>
            </button>
            <div class="invalid-feedback" *ngIf="fileForm.submitted && fileModel.invalid">
              <div *ngIf="fileModel.errors.required">{{ 'VALIDATION.REQUIRED' | translate }}</div>
              <div *ngIf="fileModel.errors.fileMaxSize">{{ 'VALIDATION.FILE_SIZE_MAX' | translate:{ maxSizeString: (fileModel.errors.fileMaxSize.requiredSize | filesize) } }}</div>
              <div *ngIf="fileModel.errors.fileAllowedExtensions">{{ 'VALIDATION.FILE_ALLOWED_EXTENSIONS' | translate:{ extensions: fileModel.errors.fileAllowedExtensions.allowedExtensions } }}</div>
            </div>
            <div class="custom-file-progress" *ngIf="formData.file.file && formData.file.uploadProgress"
                 [style.width]="formData.file.uploadProgress + '%'"></div>
          </div>
        </div>
      </div>
    </div>

  </form>

</div>

<div class="modal-footer justify-content-center">
  <button type="button" class="btn btn-outline-primary w-25" (click)="close()">
    <div class="d-flex align-items-center justify-content-center">
      <svg-icon class="d-flex mr-2" src="asset/image/icon/x.svg"></svg-icon>
      <span>{{ 'COMMON.CANCEL' | translate }}</span>
    </div>
  </button>
  <button type="button" class="btn btn-primary w-25" (click)="ok()" [disabled]="formData.file?.uploading">
    <div class="d-flex align-items-center justify-content-center">
      <svg-icon class="d-flex mr-2" src="asset/image/icon/file-upload.svg"></svg-icon>
      <span>{{ 'COMPONENT.FILE_UPLOAD.UPLOAD' | translate }}</span>
    </div>
  </button>
</div>
