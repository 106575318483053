import { Component } from '@angular/core';
import { UserDTO } from 'app/data/dto/user/UserDTO';
import { UserModel } from 'app/model/UserModel';
import { UserType } from 'app/data/enum/user/UserType';

@Component({
  selector: 'app-dashboard',
  templateUrl: './DashboardComponent.html',
  styleUrls: [ './DashboardComponent.scss' ]
})
export class DashboardComponent {
  public currentUser: UserDTO;

  public readonly UserType: typeof UserType = UserType;

  constructor(private userModel: UserModel) {
    this.currentUser = this.userModel.currentUser;
  }

}