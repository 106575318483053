import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Constant } from 'app/common/Constant';
import { State } from 'app/common/State';
import {
  DataGridTemplateCellRendererComponent
} from 'app/component/ui/dataGrid/cellRenderer/DataGridTemplateCellRendererComponent';
import { PageCriteriaDTO } from 'app/data/dto/PageCriteriaDTO';
import { PageDTO } from 'app/data/dto/PageDTO';
import { WatchPartyDTO } from 'app/data/dto/watchParty/WatchPartyDTO';
import { WatchPartyPageCriteriaDTO } from 'app/data/dto/watchParty/WatchPartyPageCriteriaDTO';
import { WatchPartyModel } from 'app/model/WatchPartyModel';

@Component({
  selector: 'app-watch-party-available-list',
  templateUrl: './WatchPartyAvailableListComponent.html'
})
export class WatchPartyAvailableListComponent implements OnInit {
  @Output() public refresh: EventEmitter<void> = new EventEmitter<void>();

  public criteria: WatchPartyPageCriteriaDTO = new WatchPartyPageCriteriaDTO();
  public page: PageDTO<WatchPartyDTO>;
  public listWindowSize: number = 3;

  public Constant: typeof Constant = Constant;
  public State: typeof State = State;
  public DataGridTemplateCellRendererComponent: typeof DataGridTemplateCellRendererComponent =
    DataGridTemplateCellRendererComponent;

  constructor(public readonly watchPartyModel: WatchPartyModel) {
    this.criteria.pageSize = this.listWindowSize;
    this.criteria.type = 'OPEN';
  }

  ngOnInit(): void {
    this.loadData();
  }

  public onCriteriaChanged(criteria: PageCriteriaDTO): void {
    this.criteria = criteria as WatchPartyPageCriteriaDTO;

    this.loadData();
  }

  public nextPage(): void {
    this.criteria.pageSize += this.listWindowSize;

    this.onCriteriaChanged(this.criteria);
  }

  public loadData(): void {
    this.watchPartyModel.getPage(this.criteria)
      .subscribe((result) => {
        this.page = result;
      });
  }

  public signup(data: WatchPartyDTO): void {
    this.watchPartyModel.signup(data.id)
      .subscribe(() => {
        this.loadData();
        this.refresh.emit();
      });
  }
}
