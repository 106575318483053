import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subject } from 'rxjs';
import { LiveClassType } from 'app/data/enum/liveClass/LiveClassType';

@Component({
  selector: 'app-live-class-series-decision',
  templateUrl: './LiveClassSeriesDecisionModalComponent.html'
})
export class LiveClassSeriesDecisionModalComponent {
  public title: string = 'COMPONENT.POPUP.CONFIRMATION.TITLE';

  private onClose: Subject<LiveClassType> = new Subject();

  public close$: Observable<LiveClassType> = this.onClose.asObservable();

  public LiveClassType: typeof LiveClassType = LiveClassType;

  constructor(private bsModalRef: BsModalRef) {

  }

  public ok(value: LiveClassType): void {
    this.onClose.next(value);
    this.exit();
  }

  public exit(): void {
    this.onClose.complete();
    this.bsModalRef.hide();
  }
}
