<div *ngFor="let class of unratedScheduledLiveClasses; let i = index">
  <div *ngIf="activeTabIndex === i" class="container">
    <div class="row no-gutters mt-4">
      <div class="col-12 mb-6 d-flex justify-content-center">
        <h1 class="m-0 mx-auto d-block">{{ "VIEW.MAIN.THANK_TOU.THANK_TOU" |translate }}</h1>
        <button (click)="onHideClick()" type="button" class="close" aria-label="Close">
          <svg-icon src="asset/image/icon/x.svg"></svg-icon>
        </button>
      </div>

      <div class="col-12 mb-6 d-flex justify-content-center">
        <div class="col-8 text-center">
          {{ "VIEW.MAIN.THANK_TOU.FOR_PARTICIPATION_IN" |translate }}:
          <span class="font-weight-600">{{ class.title }}</span>
        </div>
      </div>
      <div class="col-12 mb-0">{{ "VIEW.MAIN.THANK_TOU.RATE_THE_CLASS" |translate }}:</div>
      <div class="col-12 mb-6 d-flex flex-column align-items-center">
        <div class="col-4">
          <app-rating-stars
            [value]="ratingRequests[i].rating"
            [max]="5"
            [displayOnly]="false"
            (rate)="onRatingChange($event)"
          ></app-rating-stars>
        </div>
        <div class="col-7">
          <div
            class="invalidFeedback"
            [style.visibility]="form.submitted && ratingRequests[i].rating === 0 ? 'visible' : 'hidden'"
          >
            <div>{{ 'ERROR.RATING_MUST_BE_EQ_OR_GREATER_THAN_1' | translate }}</div>
          </div>
        </div>
      </div>

      <div class="col-12 mb-6">
        <p class="m-0">{{ "VIEW.MAIN.THANK_TOU.MESSAGE" |translate }}</p>
      </div>

      <div class="col-12">
        <div class="form-group">
          <form #form="ngForm">
            <label for="feedback">{{ 'VIEW.MAIN.THANK_TOU.SEND_YOUR_FEEDBACK' | translate }}</label>
            <textarea
              id="feedback"
              name="feedback"
              #feedback="ngModel"
              class="form-control"
              [placeholder]="'COMMON.START_TYPING' | translate"
              rows="5"
              maxlength="500"
              [ngClass]="{'is-invalid': form.submitted && feedback.invalid, 'is-valid': form.submitted && feedback.valid }"
              [(ngModel)]="ratingRequests[i].feedback"
              required
              [appMaxLength]="Constant.TEXT_MAX_LENGTH_LG"
            ></textarea>
            <div class="invalid-feedback" *ngIf="form.submitted && feedback.invalid">
              <div *ngIf="feedback.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
              <div *ngIf="feedback.errors['maxLength']">
                {{ 'VALIDATION.CHAR_MAX' | translate: { max: Constant.TEXT_MAX_LENGTH_LG } }}
              </div>
            </div>
            <div class="d-flex justify-content-end">{{ feedback.value ? feedback.value.length : 0 }}/500</div>
          </form>
        </div>
      </div>
      <div class="col-12">
        <div class="d-flex flex-column flex-sm-row justify-content-end">
          <button (click)="onHideClick()" id="back-button" type="button" class="btn btn-outline-dark mr-2">
            <span class="d-flex flex-row justify-content-center align-items-center">
              <span>{{ 'COMMON.SKIP' | translate }}</span>
              <svg-icon class="d-flex" src="asset/image/icon/chevron-right.svg"></svg-icon>
            </span>
          </button>
          <button
            *ngIf="!onlyOneClass"
            [disabled]="isFirstTab()"
            id="previous-button"
            type="button"
            class="btn btn-outline-dark mr-2"
            (click)="changeTab(activeTabIndex - 1)"
          >
            <span class="d-flex flex-row justify-content-center align-items-center">
              <svg-icon class="d-flex" src="asset/image/icon/chevron-left.svg"></svg-icon>
              <span>{{ 'COMMON.PREVIOUS' | translate }}</span>
            </span>
          </button>
          <button (click)="onSendClick()" id="reset-password-button" type="button" class="btn btn-primary px-4 mr-2">
            <span class="font-weight-normal">{{ 'COMMON.SEND' | translate }}</span>
          </button>
          <button
            *ngIf="!onlyOneClass"
            [disabled]="isLastTab()"
            id="next-button"
            type="button"
            class="btn btn-outline-dark mr-2 mr-md-0"
            (click)="changeTab(activeTabIndex + 1)"
          >
            <span class="d-flex flex-row justify-content-center align-items-center">
              <span>{{ 'COMMON.NEXT' | translate }}</span>
              <svg-icon class="d-flex" src="asset/image/icon/chevron-right.svg"></svg-icon>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
