import { Component } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { State } from 'app/common/State';
import { UserDTO } from 'app/data/dto/user/UserDTO';
import { UserModel } from 'app/model/UserModel';
import { ViewUtil } from 'app/util/ViewUtil';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-first-login-preferences',
  templateUrl: './FirstLoginPreferencesComponent.html',
  styleUrls: [ './FirstLoginPreferencesComponent.scss' ]
})
export class FirstLoginPreferencesComponent {
  public sendPreferencesEvent: Subject<void> = new Subject<void>();

  public currentUser: UserDTO;

  public redirectionState: string = State.PRELIMINARY.HOW_DOES_IT_WORK;

  constructor(private stateService: StateService,
              public userModel: UserModel,
              public viewUtil: ViewUtil) {
    this.currentUser = this.userModel.currentUser;
  }

  public skip(): void {
    this.stateService.go(this.redirectionState);
  }

  public send(): void {
    this.sendPreferencesEvent.next();
  }
}
