import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/common/Constant';
import { ApplicationConfig } from 'app/config/ApplicationConfig';
import { NotificationDTO } from 'app/data/dto/notifications/NotificationDTO';
import { NotificationPriority } from 'app/data/enum/notifications/NotificationPriority';
import { ObjectUtil } from 'app/util/ObjectUtil';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  private _notifications: BehaviorSubject<NotificationDTO[]> = new BehaviorSubject<NotificationDTO[]>([]);
  private _hasUnreadNotifications: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public notifications$: Observable<NotificationDTO[]> = this._notifications.asObservable();
  public hasUnreadNotifications$: Observable<boolean> = this._hasUnreadNotifications.asObservable();
  private _hasUnreadCriticalNotifications: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public hasUnreadCriticalNotifications$: Observable<boolean> = this._hasUnreadCriticalNotifications.asObservable();

  get notifications(): NotificationDTO[] {
    return this._notifications.getValue();
  }

  set notifications(val: NotificationDTO[]) {
    this._notifications.next(val);
  }

  get hasUnreadNotifications(): boolean {
    return this._hasUnreadNotifications.getValue();
  }

  set hasUnreadNotifications(val: boolean) {
    this._hasUnreadNotifications.next(val);
  }

  constructor(private http: HttpClient) {
  }

  public getNotification(): Observable<NotificationDTO[]> {
    let context: HttpContext = new HttpContext();

    context.set(Constant.HTTP_SUPPRESS_ERRORS_TOKEN, true);
    context.set(Constant.HTTP_SUPPRESS_LOADING_TOKEN, true);

    return this.http.get(`${ ApplicationConfig.apiUrl }/users/notifications/me/all`, { context }).pipe(
      map((response: NotificationDTO[]) => {
        const notifications: NotificationDTO[] = ObjectUtil.plainToClassArray(NotificationDTO, response);

        this._notifications.next(notifications);
        this.updateUnreadNotificationsStatus(notifications);

        return notifications;
      })
    );
  }

  public markNotificationsAsRead(id: number): Observable<void> {
    return this.http
      .post(`${ ApplicationConfig.apiUrl }/users/notifications/mark-read/${ id }`, ObjectUtil.classToPlain(id))
      .pipe(map((response: any) => response));
  }

  private updateUnreadNotificationsStatus(notifications: NotificationDTO[]): void {
    const hasUnread = notifications.some((notification) => !notification.read);
    const hasUnreadCritical = notifications.some(
      (notification) => !notification.read && notification.priority === NotificationPriority.CRITICAL
    );

    this._hasUnreadNotifications.next(hasUnread);
    this._hasUnreadCriticalNotifications.next(hasUnreadCritical);
  }
}
