<div class="text-green-dark" [ngSwitch]="intensity">
  <div *ngSwitchCase="IntensityLevel.BEGINNER">
    <svg-icon src="asset/image/icon/level-1.svg"></svg-icon>
  </div>
  <div *ngSwitchCase="IntensityLevel.INTERMEDIATE">
    <svg-icon src="asset/image/icon/level-2.svg"></svg-icon>
  </div>
  <div *ngSwitchCase="IntensityLevel.ADVANCED">
    <svg-icon src="asset/image/icon/level-3.svg"></svg-icon>
  </div>
</div>
