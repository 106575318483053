import { Injectable, Injector } from '@angular/core';
import { PageCriteriaDTO } from 'app/data/dto/PageCriteriaDTO';
import { PageDTO } from 'app/data/dto/PageDTO';
import { OrganizationCreateRequestDTO } from 'app/data/dto/organization/OrganizationCreateRequestDTO';
import { OrganizationDetailsResponseDTO } from 'app/data/dto/organization/OrganizationDetailsResponseDTO';
import { OrganizationDetailsResponsePageDTO } from 'app/data/dto/organization/OrganizationDetailsResponsePageDTO';
import { OrganizationListAdminDTO } from 'app/data/dto/organization/OrganizationListAdminDTO';
import { OrganizationUpdateRequestDTO } from 'app/data/dto/organization/OrganizationUpdateRequestDTO';
import { OrganizationService } from 'app/service/OrganizationService';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OrganizationModel {
  private organizationService;

  constructor(private injector: Injector) {
    this.organizationService = this.injector.get(OrganizationService);
  }

  public getOrganizationPage(criteria: PageCriteriaDTO): Observable<PageDTO<OrganizationDetailsResponsePageDTO>> {
    return this.organizationService.getOrganizationPage(criteria);
  }

  public getAllOrganizationList(): Observable<OrganizationListAdminDTO[]> {
    return this.organizationService.getAllOrganizationList();
  }

  public getOrganizationDetails(id: number): Observable<OrganizationDetailsResponseDTO> {
    return this.organizationService.getOrganizationDetails(id);
  }

  public updateOrganization(
    id: number,
    organization: OrganizationUpdateRequestDTO
  ): Observable<OrganizationDetailsResponseDTO> {
    return this.organizationService.updateOrganization(id, organization);
  }

  public createOrganization(organization: OrganizationCreateRequestDTO): Observable<OrganizationDetailsResponseDTO> {
    return this.organizationService.createOrganization(organization);
  }
}
