import { VideoCategory } from 'app/data/enum/video/VideoCategory';
import { VideoStatus } from 'app/data/enum/video/VideoStatus';
import { VideoSubcategory } from 'app/data/enum/video/VideoSubcategory';
import { VideoVisibility } from 'app/data/enum/video/VideoVisibility';
import { IntensityLevel } from 'app/data/enum/IntensityLevel';

export class VideoCreateRequestDTO {
  public attachmentIds: number[];

  public imageId: number;

  public category: VideoCategory;

  public coachIds: number[];

  public description: string;

  public duration: number;

  public featured: boolean;

  public intensity: IntensityLevel;

  public language: string;

  public ratingEnabled: boolean;

  public recommended: boolean;

  public status: VideoStatus;

  public subcategories: VideoSubcategory[];

  public title: string;

  public url: string;

  public visibility?: VideoVisibility;

  public vimeoVideoId: number;
}