import { ApplicationRef, ComponentFactoryResolver, Injectable, Injector } from '@angular/core';
import { DomPortalOutlet, Portal } from '@angular/cdk/portal';

import * as _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class PortalUtil {
  private portalOutlets: { [key: string]: DomPortalOutlet } = {};

  constructor(private readonly componentFactoryResolver: ComponentFactoryResolver,
              private readonly applicationRef: ApplicationRef,
              private readonly injector: Injector) {
  }

  /*
    <div #portalOutlet></div>

    @ViewChild('portalOutlet', { static: true })
    private set portalOutlet(elementRef: ElementRef<HTMLElement>) {
      this.portalUtil.registerPortalOutlet(MainLayoutComponent.PORTAL_OUTLET_HEADER, elementRef.nativeElement);
    }
   */
  public registerPortalOutlet(name: string, element: HTMLElement): void {
    this.portalOutlets[name] = new DomPortalOutlet(element, this.componentFactoryResolver, this.applicationRef, this.injector, document);
  }

  public unregisterPortalOutlet(name: string): void {
    if (_.has(this.portalOutlets, name)) {
      this.portalOutlets[name].dispose();
      _.unset(this.portalOutlets, name);
    }
  }

  /*
    <p #portalElement>Lorem ipsum...</p>

    @ViewChild('portalElement', { static: true })
    private set portalElement(elemRef: ElementRef<HTMLElement>) {
      this.portalUtil.attachPortalTo(MainLayoutComponent.PORTAL_OUTLET_HEADER, new DomPortal(elemRef));
    }
   */
  public attachPortalTo(portalOutletName: string, portal: Portal<any>): void {
    this.portalOutlets[portalOutletName]?.attach(portal);
  }

  public detachPortalFrom(portalOutletName: string): void {
    this.portalOutlets[portalOutletName]?.detach();
  }
}
