import { HttpContextToken } from '@angular/common/http';

export class Constant {
  public static readonly HTTP_TIMEZONE_HEADER: string = 'X-Timezone';
  public static readonly HTTP_UTC_OFFSET_HEADER: string = 'X-UTC-Offset';
  public static readonly HTTP_APP_LANGUAGE_HEADER: string = 'X-AppLanguage';
  public static readonly HTTP_APP_TYPE_HEADER: string = 'X-AppType';
  public static readonly HTTP_APP_VERSION_HEADER: string = 'X-AppVersion';
  public static readonly HTTP_CONTENT_DISPOSITION_HEADER: string = 'Content-Disposition';

  public static readonly HTTP_SUPPRESS_ERRORS_TOKEN: HttpContextToken<boolean> = new HttpContextToken<boolean>(() => false);
  public static readonly HTTP_SUPPRESS_LOADING_TOKEN: HttpContextToken<boolean> = new HttpContextToken<boolean>(() => false);

  public static readonly PASSWORD_MIN_LENGTH: number = 12;
  public static readonly PASSWORD_MAX_LENGTH_256: number = 256;
  public static readonly PASSWORD_PATTERN: string = `(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*[\\~!@#\\$%\\^&\\*\\(\\)_+]).{${ Constant.PASSWORD_MIN_LENGTH },}`;

  public static readonly PHONE_NUMBER_RULES: { mask: string, expression: string, prefix: string, pattern: RegExp } = {
    mask: '0000000000',
    expression: '__________',
    prefix: '+1',
    pattern: /^\d{10}$/ // more complex: /^([\+]?[\s0-9]+)(\d{3}|[\(]?[0-9]+[\)])?([\-]?[\s]?[0-9])+$/;
  };

  public static readonly EMAIL_ADDRESS_PATTERN: RegExp = /^\S+@\S+\.\S+$/;

  public static readonly TEXT_MAX_LENGTH_128: number = 128;
  public static readonly TEXT_MAX_LENGTH_255: number = 255;
  public static readonly TEXT_MAX_LENGTH_LG: number = 500;
  public static readonly TEXT_MAX_LENGTH_XLG: number = 1024;
  public static readonly TEXT_MAX_LENGTH_XL: number = 4000;
  public static readonly TEXT_MAX_LENGTH_150: number = 150;
  public static readonly TEXT_MAX_LENGTH_512: number = 512;

  public static readonly STAR_RATING_MIN_VALUE: number = 4;

  public static readonly DEFAULT_MINUTES_THRESHOLD: number = 15;

  // format
  public static readonly DATE_FORMAT = 'MM/dd/yyyy';

  // violet gradient
  public static readonly CHART_COLORS: string[] = [
    '#353e81',
    '#49518D',
    '#5d649a',
    '#7177a6',
    '#858bb3',
    '#9a9ec0',
    '#aeb1cc',
    '#c2c5d9',
    '#d6d8e5'
  ];
}
