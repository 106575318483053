import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationConfig } from 'app/config/ApplicationConfig';
import { PageDTO } from 'app/data/dto/PageDTO';
import { ObjectUtil } from 'app/util/ObjectUtil';
import { CustomHttpParameterEncoder } from 'app/util/other/CustomHttpParameterEncoder';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LiveClassSeriesDetailsPageDTO } from 'app/data/dto/liveClassSeries/admin/LiveClassSeriesDetailsPageDTO';
import { LiveClassSeriesPageCriteriaDTO } from 'app/data/dto/liveClassSeries/LiveClassSeriesPageCriteriaDTO';
import { LiveClassSeriesCreateRequestDTO } from 'app/data/dto/liveClassSeries/LiveClassSeriesCreateRequestDTO';
import { LiveClassSeriesDetailsResponseDTO } from 'app/data/dto/liveClassSeries/admin/LiveClassSeriesDetailsResponseDTO';
import { LiveClassSeriesUpdateRequestDTO } from 'app/data/dto/liveClassSeries/admin/LiveClassSeriesUpdateRequestDTO';
import { Asset } from 'app/data/local/attachment/Attachment';
import { LiveClassSeriesAttachmentResponseDTO } from 'app/data/dto/liveClassSeries/LiveClassSeriesAttachmentResponseDTO';

@Injectable({
  providedIn: 'root'
})
export class LiveClassSeriesService {
  constructor(private http: HttpClient) {
  }

  public getLiveClassSeriesPageAdmin(
    criteria: LiveClassSeriesPageCriteriaDTO
  ): Observable<PageDTO<LiveClassSeriesDetailsPageDTO>> {
    const params: HttpParams = new HttpParams({
      encoder: new CustomHttpParameterEncoder(),
      fromObject: ObjectUtil.valueAsString(ObjectUtil.classToPlain(criteria, true, true, true, true))
    });

    return this.http.get(`${ ApplicationConfig.apiUrl }/liveclassseries/admins/page`, { params: params }).pipe(
      map((response: any) => ObjectUtil.plainToClassFromExisting(new PageDTO<LiveClassSeriesDetailsPageDTO>(LiveClassSeriesDetailsPageDTO), response))
    );
  }

  public createLiveClassSeries(
    liveClassSeries: LiveClassSeriesCreateRequestDTO
  ): Observable<LiveClassSeriesDetailsResponseDTO> {
    return this.http
      .post(`${ ApplicationConfig.apiUrl }/liveclassseries`, ObjectUtil.classToPlain(liveClassSeries))
      .pipe(
        map((response: any) => ObjectUtil.plainToClass(LiveClassSeriesDetailsResponseDTO, response))
      );
  }

  public updateLiveClassSeries(
    id: number,
    liveClassSeries: LiveClassSeriesUpdateRequestDTO
  ): Observable<LiveClassSeriesDetailsResponseDTO> {
    return this.http
      .put(`${ ApplicationConfig.apiUrl }/liveclassseries/${ id }`, ObjectUtil.classToPlain(liveClassSeries))
      .pipe(
        map((response: any) => ObjectUtil.plainToClass(LiveClassSeriesDetailsResponseDTO, response))
      );
  }

  public getLiveClassSeriesDetailsAdmin(id: number): Observable<LiveClassSeriesDetailsResponseDTO> {
    return this.http.get(`${ ApplicationConfig.apiUrl }/liveclassseries/${ id }/admins/details`).pipe(
      map((response: any) => ObjectUtil.plainToClass(LiveClassSeriesDetailsResponseDTO, response))
    );
  }

  public createAttachmentAsset(asset: Asset): Observable<LiveClassSeriesAttachmentResponseDTO> {
    return this.http.post(`${ ApplicationConfig.apiUrl }/liveclassseriesattachments`, ObjectUtil.classToPlain(asset))
      .pipe(
        map((response: any) => ObjectUtil.plainToClass(LiveClassSeriesAttachmentResponseDTO, response))
      );
  }

  public deleteLiveClassSeries(ids: number[]): Observable<void> {
    return this.http.delete(`${ ApplicationConfig.apiUrl }/liveclassseries/${ ids }`).pipe(
      map((response: any) => {
        return;
      })
    );
  }

}
