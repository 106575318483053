import { Directive, HostListener } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

// this sets FileList taken directly from input[type=file] as value (some browsers have problems with obtaining a proper value from this input)
// because it binds to <input type="file" multiple>, it works automatically, as long as it is included in module

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'input[type=file][formControlName][multiple],input[type=file][formControl][multiple],input[type=file][ngModel][multiple]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputMultipleFileValueAccessorDirective,
      multi: true
    }
  ]
})
export class InputMultipleFileValueAccessorDirective implements ControlValueAccessor {

  public value: any;

  public onChange = (_: any): void => {
  };

  public onTouched = (): void => {
  };

  @HostListener('change', [ '$event.target.files' ])
  public onInputChange(value: FileList): void {
    this.onChange((value) ? value : null);
  }

  @HostListener('blur')
  public onInputBlur(): void {
    this.onTouched();
  }

  public writeValue(value: any): void {
  }

  public registerOnChange(fn: () => any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => any): void {
    this.onTouched = fn;
  }

}
