import { IdentifiableObjectDTO } from 'app/data/dto/IdentifiableObjectDTO';
import { UserDTO } from 'app/data/dto/user/UserDTO';
import { Transform, Type } from 'class-transformer';
import { BadgeResponseDTO } from 'app/data/dto/badge/BadgeResponseDTO';
import { DateUtil } from 'app/util/DateUtil';
import { Friend } from 'app/data/local/friend/Friend';

export class FriendDTO extends IdentifiableObjectDTO<number> implements Friend {
  @Type(() => UserDTO)
  public user: UserDTO;

  @Type(() => BadgeResponseDTO)
  public badges: BadgeResponseDTO[];

  public liveClassesCount: number;

  public videosCount: number;

  @Transform(DateUtil.dateTimeConversion)
  public invitationReceivementDate: Date;

  @Transform(DateUtil.dateTimeConversion)
  public invitationAcceptanceDate: Date;

  @Transform(DateUtil.dateTimeConversion)
  public invitationSendDate: Date;
}