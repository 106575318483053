<div class="d-flex flex-column gap-2">
  <div *ngFor="let video of recommendedVideos" class="video-card" role="button" (click)="playVideo(video.id)">
    <img
      *ngIf="video?.thumbnail?.defaultAsset?.url"
      class="image-webkit-fill-available"
      [src]="video.thumbnail.defaultAsset.url"
    />
    <div class="overlay d-flex flex-column align-items-center justify-content-end p-4">
      <div class="text-lg">{{ video.title }}</div>
      <!-- TODO 2nd phase
      <app-star-rating [currentRating]="4" [noOfStars]="5"></app-star-rating> -->
    </div>
  </div>
  <button id="all-btn" class="btn btn-outline-primary mt-6 mb-1" (click)="showAllRecommended()">
    {{ 'VIEW.MAIN.DASHBOARD.ALL_RECOMMENDED' | translate }}
  </button>
</div>
