<div class="p-32">
  <div class="d-flex justify-content-center position-relative">
    <p class="text-center mb-4 font-size-lg">{{ "VIEW.MAIN.ACCOUNT.DETAILS.CHANGE_PHOTO" | translate }}</p>
    <svg-icon
      type="button"
      aria-label="Close"
      (click)="onHideClick()"
      class="position-absolute"
      [style.top]="'-10px'"
      [style.right]="'-10px'"
      src="asset/image/icon/x.svg"
    ></svg-icon>
  </div>
  <ng-container *ngIf="currentUser">
    <div>
      <form #form="ngForm">
        <div id="upload-thumbnail" class="position-relative rounded-circle">
          <div class="placeholder position-relative rounded-circle">
            <svg-icon class="position-absolute" [style.zIndex]="1" src="asset/image/icon/grey-blur.svg"></svg-icon>
            <svg-icon class="position-absolute" [style.zIndex]="2" src="asset/image/icon/thumbnailFile.svg"></svg-icon>
            <span *ngIf="uploadStarted" [style.zIndex]="3" class="loader-white-lg position-absolute"></span>

            <input
              type="file"
              #thumbnailInput="ngModel"
              #thumbnailInputRef
              id="thumbnailInput"
              name="thumbnailInput"
              class="custom-file-input rounded-circle"
              [ngModel]="uploadThumbnail.file"
              (ngModelChange)="onThumbnailChange($event)"
              accept="image/*"
              [appFileAllowedExtensions]="allowedExtensionsThumbnail"
              [appFileMaxSize]="maxSize"
            />
            <div
              class="thumbnail position-absolute rounded-circle"
              [ngStyle]="thumbnailUrl ? {
              'background-image': 'url(' + thumbnailUrl + ')',
              'background-size': 'cover',
              'background-repeat': 'no-repeat',
              'background-position': 'center center',
              'z-index': (uploadThumbnailStarted && !uploadStarted) ? 1 : 0

            } : {}"
            ></div>
          </div>
        </div>
        <span *ngIf="!formStatus" class="my-4 text-center d-flex flex-column align-items-center text-danger">
          <svg-icon class="mb-2" src="asset/image/icon/circle_exclamation.svg"></svg-icon>
          <span class="font-weight-600"> {{ 'VALIDATION.THIS_FILE_EXTENSION_IS_NOT_SUPPORTED' | translate }} </span>
        </span>
        <span class="my-4 text-center d-flex flex-column align-items-center font-size-sm">
          Drag your image here or

          <span (click)="onChoseFileClick()" class="btn-link font-weight-600 display-contents"
          >{{ 'choose file' | translate }}</span
          >
          to upload.
        </span>
        <span class="my-4 text-center d-flex flex-column align-items-center font-size-13">
          You can attach jpg, jpeg or png.
        </span>
      </form>
    </div>
  </ng-container>
  <div class="d-flex justify-content-between">
    <button
      type="button"
      class="btn btn-light d-flex justify-content-center align-items-center py-6 mr-4 w-100"
      (click)="onHideClick()"
    >
      <svg-icon class="mr-2" src="asset/image/icon/chevron-left.svg"></svg-icon>
      <span>{{ "COMMON.BACK" | translate }}</span>
    </button>
    <button
      class="btn btn-primary edit-btn position-relative d-flex align-items-center justify-content-center py-6 w-100"
      [disabled]="!uploadThumbnailStarted || uploadStarted"
      (click)="onSaveClick()"
    >
      <div class="d-flex align-items-center justify-content-center">
        <!-- <span *ngIf="uploadStarted" class="loader-white position-absolute"></span> -->
        <span>{{ 'COMMON.SAVE' | translate }}</span>
      </div>
    </button>
  </div>
</div>
