import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { StateService, Transition } from '@uirouter/core';
import { Constant } from 'app/common/Constant';
import { State } from 'app/common/State';
import { PopupConfirmationComponent } from 'app/component/ui/popup/PopupConfirmationComponent';
import { CoachProfileResponseAdminDTO } from 'app/data/dto/coach/CoachProfileResponseAdminDTO';
import { CoachProfileUpdateAdminRequestDTO } from 'app/data/dto/coach/CoachProfileUpdateAdminRequestDTO';
import { CoachType } from 'app/data/enum/coach/CoachType';
import { OptionItem } from 'app/data/local/generic/OptionItem';
import { ApplicationModel } from 'app/model/ApplicationModel';
import { CoachModel } from 'app/model/CoachModel';
import { StateUtil } from 'app/util/StateUtil';
import { ViewUtil } from 'app/util/ViewUtil';
import _ from 'lodash';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { of, pipe, Subject, throwError } from 'rxjs';
import { catchError, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { PortalUtil } from 'app/util/PortalUtil';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import { TemplatePortal } from '@angular/cdk/portal';

@Component({
  selector: 'app-coaches-edit',
  templateUrl: './CoachesEditComponent.html',
  styleUrls: [ './CoachesEditComponent.scss' ]
})
export class CoachesEditComponent implements OnInit, OnDestroy {
  @ViewChild('headingTemplate', { static: true })
  private readonly headingTemplate: TemplateRef<any>;

  @ViewChild('form')
  public form: NgForm;

  private destroy$: Subject<void> = new Subject<void>();

  public hobbiesFormStatus: boolean = false;
  public educationFormStatus: boolean = false;
  public certificationFormStatus: boolean = false;
  public id: number;
  public coach: CoachProfileResponseAdminDTO;
  public uploadStarted: boolean = false;
  public updatedCoach: CoachProfileUpdateAdminRequestDTO;

  public types: OptionItem<CoachType>[] = this.viewUtil.createEnumSelectOptions(CoachType, 'TYPE');

  public formData: any = {
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
    location: null,
    status: null,
    typeOfCoach: null,
    bio: null,
    education: null,
    certifications: null
  };

  public statuses: OptionItem<boolean>[] = [
    {
      value: true,
      label: 'ENUM.STATUS.ACTIVE'
    },
    {
      value: false,
      label: 'ENUM.STATUS.INACTIVE'
    }
  ];

  public Constant = Constant;

  constructor(public viewUtil: ViewUtil,
              private applicationModel: ApplicationModel,
              private stateUtil: StateUtil,
              private transition: Transition,
              private coachModel: CoachModel,
              private stateService: StateService,
              private modalService: BsModalService,
              private portalUtil: PortalUtil,
              private viewContainerRef: ViewContainerRef) {
    this.id = this.transition.params().id;
  }

  public ngOnInit(): void {
    this.applicationModel.selectSideBarItemWithState(State.MAIN.ADMIN.COACHES.LIST);

    this.portalUtil.attachPortalTo(
      MainLayoutComponent.PORTAL_OUTLET.HEADING,
      new TemplatePortal(this.headingTemplate, this.viewContainerRef)
    );

    this.getCoach();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.portalUtil.detachPortalFrom(MainLayoutComponent.PORTAL_OUTLET.HEADING);
  }

  public track(index: any, item: { item: any; id: string }): string {
    return item.id;
  }

  public submit(): void {
    this.form.onSubmit(null);
    if (this.form.valid && this.hobbiesFormStatus && this.educationFormStatus && this.certificationFormStatus) {
      if (this.updatedCoach.coachCertifications.every((cert) => cert.certification === '')) {
        this.updatedCoach.coachCertifications = [];
      }
      if (this.updatedCoach.coachEducations.every((hobby) => hobby.education === '')) {
        this.updatedCoach.coachEducations = [];
      }
      if (this.updatedCoach.coachHobbies.every((hobby) => hobby.hobby === '')) {
        this.updatedCoach.coachHobbies = [];
      }

      this.coachModel
        .updateCoach(this.id, this.updatedCoach)
        .pipe(
          takeUntil(this.destroy$),
          tap((coach) => {
            this.coach = coach;
            this.viewUtil.showToastSuccess('COMMON.SUCCESS');
          }),
          catchError((err) => {
            this.viewUtil.handleServerError(err);
            return of(null);
          })
        )
        .subscribe(() => {
          this.stateUtil.goToState(State.MAIN.ADMIN.COACHES.DETAILS, { id: this.id });
        });
    }
  }

  public handleUpdatedHobbies(items): void {
    this.updatedCoach.coachHobbies = _.cloneDeep(items);
  }

  public handleUpdatedEducations(items): void {
    this.updatedCoach.coachEducations = _.cloneDeep(items);
  }

  public handleUpdatedCertification(items): void {
    this.updatedCoach.coachCertifications = _.cloneDeep(items);
  }

  public handleStartUpload(uploadStart: boolean): void {
    this.uploadStarted = uploadStart;
  }

  public handleImageId(imageId: number): void {
    this.updatedCoach.user.imageId = imageId;
  }

  public onBackClick(): void {
    this.stateUtil.goToState(State.MAIN.ADMIN.COACHES.LIST);
  }

  public onDeleteClick(): void {
    const modal: BsModalRef = this.modalService.show(PopupConfirmationComponent, {
      initialState: {
        message: 'VIEW.MAIN.USER.DEACTIVATE_WARNING',
        messageVariables: { user: this.coach.user.fullName },
        okText: 'COMMON.YES',
        cancelText: 'COMMON.NO'
      },
      class: 'modal-dialog-centered'
    });
    modal.onHide
      .pipe(
        filter((reason) => reason === PopupConfirmationComponent.POPUP_RESULT_CONFIRM),
        switchMap(() => this.coachModel.deleteCoach([ this.id ])),
        pipe(
          takeUntil(this.destroy$),
          tap(() => {
            this.viewUtil.showToastSuccess('COMMON.SUCCESS');
            this.stateUtil.goToState(State.MAIN.ADMIN.COACHES.DETAILS, { id: this.id });
          }),
          catchError((err) => {
            this.viewUtil.handleServerError(err);
            return of(null);
          })
        )
      )
      .subscribe();
  }

  public onHobbiesFormStatusChange(status: boolean): void {
    this.hobbiesFormStatus = status;
  }

  public onEducationFormStatusChange(status: boolean): void {
    this.educationFormStatus = status;
  }

  public onCertificationFormStatusChange(status: boolean): void {
    this.certificationFormStatus = status;
  }

  private getCoach(): void {
    this.coachModel
      .getCoach(this.id)
      .pipe(
        tap((coach) => {
          this.coach = coach;
          this.updatedCoach = {
            ...this.coach,
            user: {
              firstName: this.coach.user.firstName,
              lastName: this.coach.user.lastName,
              email: this.coach.user.email,
              phone: this.coach.user.phone,
              enabled: this.coach.user.enabled,
              imageId: this.coach.user.avatar?.id
            }
          };
        }),
        catchError((err) => {
          this.viewUtil.handleServerError(err);
          return throwError(() => err);
        })
      )
      .subscribe();
  }


}
