import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { ApplicationConfig } from 'app/config/ApplicationConfig';
import { Observable } from 'rxjs';
import { AuthModel } from 'app/model/AuthModel';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authModel: AuthModel) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authModel.token && request.method && (
      (request.url?.indexOf(ApplicationConfig.apiUrl) > -1) ||
      (request.url?.indexOf(ApplicationConfig.fileUrl) > -1)
    )) {
      const authorizedRequest: HttpRequest<any> = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + this.authModel.token.accessToken
        }
      });

      return next.handle(authorizedRequest);
    }
    else {
      return next.handle(request);
    }
  }
}
