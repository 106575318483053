import { Type } from 'class-transformer';
import { AssetPublicDownloadResponseDTO } from './AssetPublicDownloadResponseDTO';
import { IdentifiableObjectDTO } from '../IdentifiableObjectDTO';

export class ImageResponseDTO extends IdentifiableObjectDTO<number> {
  @Type(() => AssetPublicDownloadResponseDTO)
  public defaultAsset: AssetPublicDownloadResponseDTO;

  @Type(() => AssetPublicDownloadResponseDTO)
  public originalAsset: AssetPublicDownloadResponseDTO;

  @Type(() => AssetPublicDownloadResponseDTO)
  public thumbnailAsset: AssetPublicDownloadResponseDTO;
}
