import { Transform, Type } from 'class-transformer';
import { IdentifiableObjectDTO } from '../../IdentifiableObjectDTO';
import { DateUtil } from 'app/util/DateUtil';
import { LiveClassCategory } from 'app/data/enum/liveClass/LiveClassCategory';
import { CoachDetailsLiveClassPageDTO } from '../../coach/CoachDetailsLiveClassPageDTO';
import { DaysDTO } from 'app/data/dto/DaysDTO';
import { IntensityLevel } from 'app/data/enum/IntensityLevel';

export class LiveClassSeriesDetailsPageDTO extends IdentifiableObjectDTO<number> {
  @Transform(({ value }) => value || 0, { toClassOnly: true })
  public capacity?: number;

  public category: LiveClassCategory;

  @Type(() => CoachDetailsLiveClassPageDTO)
  public coaches: CoachDetailsLiveClassPageDTO[];

  public numberOfClassesLeft: number;

  @Type(() => DaysDTO)
  public daysOfWeek: DaysDTO;

  public duration: number;

  public enrolledSeniorsCount: number;

  public intensity: IntensityLevel;

  public language: string;

  public liveClassSeriesId?: number;

  @Transform(DateUtil.dateTimeConversion)
  public startDate: Date;

  public title: string;
}
