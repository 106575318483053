import { Component, OnInit } from '@angular/core';
import { Transition } from '@uirouter/angular';
import { ZOOM_SERVICE_TOKEN } from 'app/component/ui/zoom/IZoomServiceInterface';
import {
  WatchPartyVideoCallService
} from 'app/component/view/main/onDemand/senior/view/watchParty/view/zoom/service/WatchPartyVideoCallService';
import { WatchPartyDTO } from 'app/data/dto/watchParty/WatchPartyDTO';
import { WatchPartyModel } from 'app/model/WatchPartyModel';
import { PortalUtil } from 'app/util/PortalUtil';
import { OnDemandVideoModel } from 'app/model/OnDemandVideoModel';
import { VideoDetailsDTO } from 'app/data/dto/onDemandVideo/VideoDetailsDTO';
import { ViewUtil } from 'app/util/ViewUtil';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-watch-party-event',
  templateUrl: './WatchPartyEventComponent.html',
  styleUrls: [ './WatchPartyEventComponent.scss' ],
  providers: [
    { provide: ZOOM_SERVICE_TOKEN, useClass: WatchPartyVideoCallService },
  ]
})
export class WatchPartyEventComponent implements OnInit {
  public watchPartyDetails: WatchPartyDTO

  public videoDetails: VideoDetailsDTO;

  constructor(private transition: Transition,
              private portalUtil: PortalUtil,
              private viewUtil: ViewUtil,
              private onDemandVideoModel: OnDemandVideoModel,
              private watchPartyModel: WatchPartyModel) { }

  public ngOnInit(): void {
    this.getRelatedData(this.transition.params().id);
  }

  private getRelatedData(watchPartId: number): void {
    this.watchPartyModel.getById(watchPartId).pipe(
      switchMap((watchPartDetails: WatchPartyDTO) => {
        this.watchPartyDetails = watchPartDetails;
        return this.onDemandVideoModel.getVideo(this.watchPartyDetails.video.id);
      })
    ).subscribe(
      (video: VideoDetailsDTO) => this.videoDetails = video,
      (error) => this.viewUtil.handleServerError(error)
    );
  }
}
