import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/common/Constant';
import { Event } from 'app/common/Event';
import { ServerErrorDTO } from 'app/data/dto/ServerErrorDTO';
import { ObjectUtil } from 'app/util/ObjectUtil';
import { EventManager } from 'app/util/other/EventManager';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private eventManager: EventManager) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: any, caught: Observable<HttpEvent<any>>) => {
        if (error instanceof HttpErrorResponse) {
          let suppressErrors: boolean = request.context.get(Constant.HTTP_SUPPRESS_ERRORS_TOKEN);
          if (suppressErrors) {
            // pass, it is probably handled elsewhere
          }
          else if (error.status === 401) {
            this.eventManager.broadcast(Event.AUTH.ERROR.UNAUTHORIZED);
          }
          else if (error.status === 403) {
            this.eventManager.broadcast(Event.AUTH.ERROR.FORBIDDEN);
          }
          else if (
            (error.status === 422 || error.status === 400) &&
            (error.error instanceof Blob || error.error?.errorCode)
          ) {
            // pass, it is probably handled elsewhere (specific cases)
            // but before that, translate the error object
            return new Observable<HttpEvent<any>>((observer) => {
              let errorResolvePromise: Promise<any>;

              // for responses that are expected to be binary, but still carry json with error
              if (error.error instanceof Blob) {
                errorResolvePromise = (error.error as Blob)
                  .text()
                  .then((textString: string) => {
                    return JSON.parse(textString);
                  })
                  .catch((parseError) => {
                    return null;
                  });
              }
              // for standard json responses
              else {
                errorResolvePromise = Promise.resolve(error.error);
              }

              errorResolvePromise
                .then((errorObject: any) => {
                  const updatedErrorResponse: HttpErrorResponse = new HttpErrorResponse({
                    error: ObjectUtil.plainToClass(ServerErrorDTO, errorObject),
                    headers: error.headers,
                    status: error.status,
                    statusText: error.statusText,
                    url: error.url
                  });

                  observer.error(updatedErrorResponse);
                })
                .catch((promiseError) => {
                  // if something goes wrong, better return the original error
                  observer.error(error);
                });
            });
          }
          else {
            this.eventManager.broadcast(Event.SYSTEM.GENERAL_ERROR, error);
          }
        }

        return throwError(error);
      })
    );
  }
}
