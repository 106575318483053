import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { StateService } from '@uirouter/core';
import { State } from 'app/common/State';
import { BaseCriteriaSortOrder } from 'app/data/dto/BaseCriteriaDTO';
import { PageCriteriaDTO } from 'app/data/dto/PageCriteriaDTO';
import { PageDTO } from 'app/data/dto/PageDTO';
import { OrganizationDetailsResponsePageDTO } from 'app/data/dto/organization/OrganizationDetailsResponsePageDTO';
import { SortDirection } from 'app/data/enum/SortDirection';
import { OrganizationSortBy } from 'app/data/enum/organizations/OrganizationSortBy';
import { OptionItem } from 'app/data/local/generic/OptionItem';
import { ApplicationModel } from 'app/model/ApplicationModel';
import { OrganizationModel } from 'app/model/OrganizationModel';
import { StateUtil } from 'app/util/StateUtil';
import { ViewUtil } from 'app/util/ViewUtil';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { of, Subject } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import { TemplatePortal } from '@angular/cdk/portal';
import { PortalUtil } from 'app/util/PortalUtil';

@Component({
  selector: 'app-organizations',
  templateUrl: './OrganizationsListComponent.html',
  styleUrls: [ './OrganizationsListComponent.scss' ]
})
export class OrganizationsListComponent implements OnInit, OnDestroy {
  @ViewChild('headingTemplate', { static: true })
  private readonly headingTemplate: TemplateRef<any>;

  public internalPage: number = 1;
  private destroy$: Subject<void> = new Subject<void>();
  public organizationPage: PageDTO<OrganizationDetailsResponsePageDTO>;
  public criteria: PageCriteriaDTO = new PageCriteriaDTO();
  public sortByOptions: OptionItem<string>[] = this.viewUtil.createEnumSelectOptions(
    OrganizationSortBy,
    'ORGANIZATION_SORT_BY'
  );

  public sortValue: string;

  constructor(private organizationModel: OrganizationModel,
              private applicationModel: ApplicationModel,
              private stateUtil: StateUtil,
              private stateService: StateService,
              private viewUtil: ViewUtil,
              private portalUtil: PortalUtil,
              private viewContainerRef: ViewContainerRef) {
  }

  public ngOnInit(): void {
    this.portalUtil.attachPortalTo(
      MainLayoutComponent.PORTAL_OUTLET.HEADING,
      new TemplatePortal(this.headingTemplate, this.viewContainerRef)
    );

    this.getOrganizationList();
    this.applicationModel.selectSideBarItemWithState(State.MAIN.ADMIN.ORGANIZATIONS.LIST);
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.portalUtil.detachPortalFrom(MainLayoutComponent.PORTAL_OUTLET.HEADING);
  }

  public getOrganizationList(): void {
    this.organizationModel
      .getOrganizationPage(this.criteria)
      .pipe(
        tap((page) => {
          this.organizationPage = page;
        }),
        takeUntil(this.destroy$),
        catchError((err) => {
          this.viewUtil.handleServerError(err);
          return of(null);
        })
      )

      .subscribe();
  }

  public onViewClick(id: number): void {
    this.stateUtil.goToState(State.MAIN.ADMIN.ORGANIZATIONS.DETAILS, { id });
  }

  public onAddClick(): void {
    this.stateService.go(State.MAIN.ADMIN.ORGANIZATIONS.CREATE);
  }

  public onSortOptionChange(sortBy: OrganizationSortBy): void {
    if (!sortBy) {
      delete this.criteria.sortOrders;
      this.getOrganizationList();

      return;
    }
    let sortOrders: BaseCriteriaSortOrder;
    let sortField: string;
    const sortDirection: SortDirection = SortDirection.ASCENDING;
    switch (sortBy) {
      case OrganizationSortBy.NAME: {
        sortField = 'name';
        break;
      }
      case OrganizationSortBy.CONTACT_NAME: {
        sortField = 'contactName';
        break;
      }
    }
    sortOrders = `${ sortField } ${ sortDirection }`;
    this.criteria.sortOrders = [ sortOrders ];
    this.getOrganizationList();
  }

  public onPageChanged(event: PageChangedEvent): void {
    const newPage: number = event.page;
    const newPageIndex: number = newPage - 1;
    this.criteria.pageNumber = newPageIndex;

    this.getOrganizationList();
  }

  public onEditClick(id: number): void {
    this.stateService.go(State.MAIN.ADMIN.ORGANIZATIONS.EDIT, { id });
  }

  public onDeleteClick(): void {
  }
}
