import { Injectable, Injector } from '@angular/core';
import { VideoIdsDTO } from 'app/data/dto/onDemandVideo/VideoIdsDTO';
import { Observable } from 'rxjs';
import { VideoCreateRequestDTO } from 'app/data/dto/onDemandVideo/VideoCreateRequestDTO';
import { VideoDetailsDTO } from 'app/data/dto/onDemandVideo/VideoDetailsDTO';
import { VideoPageCriteriaDTO } from 'app/data/dto/onDemandVideo/VideoPageCriteriaDTO';
import { VideoAdminDTO } from 'app/data/dto/onDemandVideo/admin/VideoAdminDTO';
import { PageDTO } from 'app/data/dto/PageDTO';
import { VideoSeniorDTO } from 'app/data/dto/onDemandVideo/senior/VideoSeniorDTO';
import { ActiveCoachDTO } from 'app/data/dto/coach/ActiveCoachDTO';
import { VideoCoachDTO } from 'app/data/dto/onDemandVideo/coach/VideoCoachDTO';
import { VideoSeniorRecommendedDTO } from 'app/data/dto/onDemandVideo/senior/VideoSeniorRecommendedDTO';
import { VideoUploadRequestDTO } from 'app/data/dto/onDemandVideo/vimeo/VideoUploadRequestDTO';
import { VideoUploadResponseDTO } from 'app/data/dto/onDemandVideo/vimeo/VideoUploadResponseDTO';
import { VideoAttachmentCreateRequestDTO } from 'app/data/dto/onDemandVideo/VideoAttachmentCreateRequestDTO';
import { VideoAttachmentResponseDTO } from 'app/data/dto/onDemandVideo/VideoAttachmentResponseDTO';
import { OnDemandVideoService } from 'app/service/OnDemandVideoService';
import { PlatformDataRequestDTO } from 'app/data/dto/PlatformDataRequestDTO';
import { VideoEssentialsAdminPageDTO } from 'app/data/dto/onDemandVideo/admin/VideoEssentialsAdminPageDTO';
import { PageCriteriaDTO } from 'app/data/dto/PageCriteriaDTO';
import { VideoRatingAdminPageCriteriaDTO } from 'app/data/dto/onDemandVideo/admin/VideoRatingAdminPageCriteriaDTO';
import { VideoRatingAdminPageDTO } from 'app/data/dto/onDemandVideo/admin/VideoRatingAdminPageDTO';
import { VideoRatingRequestDTO } from 'app/data/dto/onDemandVideo/VideoRatingRequestDTO';
import { VideoRatingResponseDTO } from 'app/data/dto/onDemandVideo/VideoRatingResponseDTO';

@Injectable({ providedIn: 'root' })
export class OnDemandVideoModel {
  private onDemandVideoService: OnDemandVideoService;

  constructor(private injector: Injector) {
    this.setupService();
  }

  private setupService(): void {
    this.onDemandVideoService = this.injector.get(OnDemandVideoService);
  }

  public deleteVideos(videoIds: VideoIdsDTO): Observable<unknown> {
    return this.onDemandVideoService.deleteVideos(videoIds);
  }

  public createVideo(video: VideoCreateRequestDTO): Observable<VideoDetailsDTO> {
    return this.onDemandVideoService.createVideo(video);
  }

  public addVideosToFavorites(videoIds: VideoIdsDTO): Observable<unknown> {
    return this.onDemandVideoService.addVideosToFavorites(videoIds);
  }

  public removeVideosFromFavorites(videoIds: VideoIdsDTO): Observable<unknown> {
    return this.onDemandVideoService.removeVideosFromFavorites(videoIds);
  }

  public sendVideoPlayedEvent(videoId: number, platform: PlatformDataRequestDTO): Observable<unknown> {
    return this.onDemandVideoService.sendVideoPlayedEvent(videoId, platform);
  }

  public markVideosAsRecommended(videoIds: VideoIdsDTO, recommend: boolean): Observable<unknown> {
    return this.onDemandVideoService.markVideosAsRecommended(videoIds, recommend);
  }

  public getVideo(videoId: number): Observable<VideoDetailsDTO> {
    return this.onDemandVideoService.getVideo(videoId);
  }

  public updateVideo(video: VideoCreateRequestDTO, videoId: number): Observable<VideoDetailsDTO> {
    return this.onDemandVideoService.updateVideo(video, videoId);
  }

  public getVideosPageAdmin(criteria: VideoPageCriteriaDTO): Observable<PageDTO<VideoAdminDTO>> {
    return this.onDemandVideoService.getVideosPageAdmin(criteria);
  }

  public getVideosEssentialsPage(criteria: PageCriteriaDTO): Observable<PageDTO<VideoEssentialsAdminPageDTO>> {
    return this.onDemandVideoService.getVideosEssentialsPage(criteria);
  }

  public getVideosPageSenior(criteria: VideoPageCriteriaDTO): Observable<PageDTO<VideoSeniorDTO>> {
    return this.onDemandVideoService.getVideosPageSenior(criteria);
  }

  public getVideosPageCoach(criteria: VideoPageCriteriaDTO): Observable<PageDTO<VideoCoachDTO>> {
    return this.onDemandVideoService.getVideosPageCoach(criteria);
  }

  public getActiveCoachList(): Observable<ActiveCoachDTO[]> {
    return this.onDemandVideoService.getActiveCoachList();
  }

  public getRecommendedVideosRandom(): Observable<VideoSeniorRecommendedDTO[]> {
    return this.onDemandVideoService.getRecommendedVideosRandom();
  }

  public getRecommendedVideosAll(): Observable<VideoSeniorRecommendedDTO[]> {
    return this.onDemandVideoService.getRecommendedVideosAll();
  }

  public createVimeoUploadRequest(request: VideoUploadRequestDTO): Observable<VideoUploadResponseDTO> {
    return this.onDemandVideoService.createVimeoUploadRequest(request);
  }

  public deleteVimeoPlaceholderVideo(videoId: number): Observable<unknown> {
    return this.onDemandVideoService.deleteVimeoPlaceholderVideo(videoId);
  }

  public createAttachmentAsset(asset: VideoAttachmentCreateRequestDTO): Observable<VideoAttachmentResponseDTO> {
    return this.onDemandVideoService.createAttachmentAsset(asset);
  }

  public getVideoRatingPage(criteria: VideoRatingAdminPageCriteriaDTO): Observable<PageDTO<VideoRatingAdminPageDTO>> {
    return this.onDemandVideoService.getVideoRatingPage(criteria);
  }

  public rateVideo(videoId: number, rating: VideoRatingRequestDTO): Observable<VideoRatingResponseDTO> {
    return this.onDemandVideoService.rateVideo(videoId, rating);
  }
}