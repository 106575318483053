<ng-template #headingTemplate>
  <h1>{{ 'VIEW.MAIN.ON_DEMAND.ON_DEMAND' | translate }}</h1>
</ng-template>

<section class="section">
  <div class="d-flex justify-content-between">
    <app-on-demand-tabs></app-on-demand-tabs>

    <ng-container *appCanPerformAction="watchPartyModel.logic.isCreationAllowed;else createWatchPartyNotAllowedTemplate">
      <button type="button"
              class="btn btn-primary"
              [uiSref]="State.MAIN.SENIOR.ON_DEMAND.WATCH_PARTY.CREATE">
        <span>{{ 'VIEW.MAIN.ON_DEMAND.WATCH_PARTY.ACTION.CREATE' | translate }}</span>
      </button>
    </ng-container>
  </div>

  <div class="d-flex flex-column gap-5">
    <div>
      <h3 class="mb-4">{{ 'VIEW.MAIN.ON_DEMAND.WATCH_PARTY.LIST.PLANNED' | translate }}</h3>
      <app-watch-party-planned-list
        (refresh)="onPlannedListRefresh()"></app-watch-party-planned-list>
    </div>

    <div>
      <h3 class="mb-4">{{ 'VIEW.MAIN.ON_DEMAND.WATCH_PARTY.LIST.AVAILABLE' | translate }}</h3>
      <app-watch-party-available-list
        (refresh)="onAvailableListRefresh()"></app-watch-party-available-list>
    </div>
  </div>
</section>


<!--Templates-->
<ng-template #createWatchPartyNotAllowedTemplate>
  <button type="button"
          class="btn btn-primary"
          [disabled]="true"
          tooltip="{{'DOMAIN.WATCH_PARTY.MESSAGE.LIMIT_REACHED.DESCRIPTION' | translate}}"
          container="body"
          placement="left">
    <span>{{ 'VIEW.MAIN.ON_DEMAND.WATCH_PARTY.ACTION.CREATE' | translate }}</span>
  </button>
</ng-template>