<div class="container-fluid px-0 mb-4" *ngIf="showFilters || showSearch">
  <div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9">
      <div id="filters" *ngIf="showFilters">
        <ng-content select="app-data-grid-filters"></ng-content>
      </div>
    </div>
    <div class="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 text-right">
      <div id="searchbox" class="form-group mb-0" *ngIf="showSearch">
        <svg-icon class="search-icon" src="asset/image/icon/search.svg"></svg-icon>
        <input class="form-control"
               type="text"
               name="search"
               id="search"
               [ngClass]="{ 'with-content': internalSearchPhrase }"
               placeholder="{{ 'COMPONENT.DATA_GRID.SEARCH' | translate }}"
               #search="ngModel"
               [(ngModel)]="internalSearchPhrase"
               (keyup.enter)="onSearchCommitted($event)">
        <button *ngIf="internalSearchPhrase"
                class="search-clear-button btn btn-sm btn-outline-primary"
                type="button"
                (click)="clearSearch()">
          <div class="d-flex flex-row align-items-center justify-content-center">
            <svg-icon class="d-flex" src="asset/image/icon/x.svg"></svg-icon>
          </div>
        </button>
      </div>
    </div>
  </div>
</div>

<table class="table responsiveDataGrid" [id]="'responsiveDataGrid-' + internalId">
  <tr *ngFor="let dataItem of data"
      [ngClass]="selectionEnabled ? 'selectable' : undefined"
      (click)="onRowClicked(dataItem)">
    <td>
      <table class="table table-striped mb-0">
        <tr *ngFor="let column of columns; index as index">
          <th scope="row" width="50%" *ngIf="!column.hide">{{ column.headerName | translate }}</th>
          <td width="50%" *ngIf="!column.hide">
            <ng-container *ngIf="column.cellRendererFramework && !column.valueFormatter">
              <app-responsive-data-grid-dynamic-element [cellRenderer]="column.cellRendererFramework"
                                                        [params]="getParams(dataItem, column.field )"></app-responsive-data-grid-dynamic-element>
            </ng-container>
            <ng-container *ngIf="!column.cellRendererFramework && column.valueFormatter">{{ getValueFormatterValue(dataItem, column.field, column.valueFormatter) }}</ng-container>
            <ng-container *ngIf="!column.cellRendererFramework && !column.valueFormatter">{{ getValue(dataItem, column.field) }}</ng-container>
          </td>
        </tr>
      </table>
    </td>
  </tr>
</table>

<div class="container-fluid px-0 mt-4" *ngIf="showPagination || showItemsPerPage || showTotalItems">
  <div class="row">
    <div [ngClass]="{ 'col-md-8': useAlternativePagination, 'col-md-6': !useAlternativePagination }">
      <div id="paginationInfo" class="form-group mb-0" *ngIf="showItemsPerPage || showTotalItems">
        <span for="entries" *ngIf="showItemsPerPage">{{ 'COMPONENT.DATA_GRID.SHOW' | translate }}</span>
        <ng-select *ngIf="showItemsPerPage"
                   name="entries"
                   id="entries"
                   #entries="ngModel"
                   [searchable]="false"
                   [clearable]="false"
                   class="mx-2"
                   [(ngModel)]="internalItemsPerPage"
                   (ngModelChange)="onItemsPerPageChange($event)">
          <ng-option *ngFor="let itemsPerPageOption of itemsPerPageOptions"
                     [value]="itemsPerPageOption">{{ (itemsPerPageOption === itemsPerPageAllValue) ? ('COMPONENT.DATA_GRID.ITEMS_PER_PAGE_ALL' | translate) : itemsPerPageOption }}
          </ng-option>
        </ng-select>
        <span *ngIf="showItemsPerPage">{{ 'COMPONENT.DATA_GRID.ITEMS_PER_PAGE' | translate: { itemLabel: (itemLabel | translate) } }}
          <ng-container *ngIf="showTotalItems">&nbsp;|&nbsp;</ng-container></span>
        <span *ngIf="showTotalItems && (itemTotal > 0) && (internalItemsPerPage !== itemsPerPageAllValue)">
          {{
            'COMPONENT.DATA_GRID.ITEMS_SHOWING' | translate: {
              itemLow: (criteria.pageNumber * criteria.pageSize) + 1,
              itemHigh: Math.min((criteria.pageNumber * criteria.pageSize) + criteria.pageSize, itemTotal),
              itemLabel: (itemLabel | translate),
              itemTotal: itemTotal,
              pageTotal: pageTotal
            }
          }}
        </span>
      </div>
    </div>
    <div [ngClass]="{ 'col-md-4': useAlternativePagination, 'col-md-6': !useAlternativePagination }">
      <nav *ngIf="showPagination && (itemTotal > 0) && (internalItemsPerPage !== itemsPerPageAllValue)">
        <pagination class="justify-content-end mb-0"
                    [ngClass]="{ 'alternative' : useAlternativePagination }"
                    [totalItems]="itemTotal"
                    [itemsPerPage]="criteria.pageSize"
                    [maxSize]="useAlternativePagination ? 1 : 7"
                    [rotate]="true"
                    [boundaryLinks]="true"
                    [customFirstTemplate]="firstTemplate"
                    [customLastTemplate]="lastTemplate"
                    [customPreviousTemplate]="prevTemplate"
                    [customNextTemplate]="nextTemplate"
                    [(ngModel)]="internalPage"
                    (pageChanged)="onPageChanged($event)"></pagination>
      </nav>
    </div>
  </div>
</div>

<ng-template #firstTemplate let-disabled="disabled" let-currentPage="currentPage">
  <svg-icon src="asset/image/icon/chevrons-left.svg"></svg-icon>
</ng-template>

<ng-template #lastTemplate let-disabled="disabled" let-currentPage="currentPage">
  <svg-icon src="asset/image/icon/chevrons-right.svg"></svg-icon>
</ng-template>

<ng-template #prevTemplate let-disabled="disabled" let-currentPage="currentPage">
  <svg-icon src="asset/image/icon/chevron-left.svg"></svg-icon>
</ng-template>

<ng-template #nextTemplate let-disabled="disabled" let-currentPage="currentPage">
  <svg-icon src="asset/image/icon/chevron-right.svg"></svg-icon>
</ng-template>
