import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-live-class-type-icon',
  template: `
    <svg-icon class="d-flex"
              [src]="seriesId ? 'asset/image/icon/series.svg' : 'asset/image/icon/single.svg'"></svg-icon>`
})
export class LiveClassTypeIconComponent {
  @Input() seriesId?: number;
}
