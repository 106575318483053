import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RatingStarsInterface } from './RatingStarsInterface';

@Component({
  selector: 'app-rating-stars',
  templateUrl: './RatingStarsComponent.html',
  styleUrls: [ './RatingStarsComponent.scss' ]
})
export class RatingStarsComponent implements OnInit {
  @Input()
  public max: number = 5;

  @Input()
  public value: number = 0;

  @Input()
  public displayOnly: boolean = true;

  @Output()
  public rate: EventEmitter<number> = new EventEmitter<number>();

  public stars: RatingStarsInterface[] = [];

  public ngOnInit(): void {
    for (let i = 1; i <= Math.round(this.value); i++) {
      this.stars.push({ id: i, isChecked: true });
    }
    for (let i = Math.round(this.value) + 1; i <= this.max; i++) {
      this.stars.push({ id: i, isChecked: false });
    }
  }

  public onStarClicked(clickedStar: RatingStarsInterface): void {
    if (this.displayOnly) {
      return;
    }

    this.stars.forEach((star) => {
      star.isChecked = star.id <= clickedStar.id;
    });
    this.rate.emit(clickedStar.id);
  }
}
