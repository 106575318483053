<ng-template #headingTemplate>
  <h1>{{ 'VIEW.MAIN.ON_DEMAND.PROGRAM.NEW_PROGRAM' | translate }}</h1>

  <div class="d-flex flex-wrap gap-4">
    <button (click)="onBackClick()" class="btn btn-outline-primary w-100 w-sm-auto">
      <span class="d-flex flex-row justify-content-center align-items-center">
        <svg-icon class="d-flex" src="asset/image/icon/chevron-left.svg"></svg-icon>
        <span>{{ 'COMMON.BACK' | translate }}</span>
      </span>
    </button>

    <button type="button" [disabled]="uploadStarted" (click)="onSaveClick()" class="btn btn-primary w-100 w-sm-auto">
      <span *ngIf="uploadStarted" class="loader-white"></span>
      <span>{{ 'COMMON.SAVE' | translate }}</span>
    </button>
  </div>
</ng-template>

<section class="section">
  <div class="row no-gutters">
    <div [formGroup]="form" class="col-xl-6">
      <div class="form-group mb-4">
        <label for="title">{{ 'VIEW.MAIN.ON_DEMAND.PROGRAM.TITLE' | translate }}</label>
        <input
          type="text"
          id="title"
          class="form-control"
          [placeholder]="'VIEW.MAIN.ON_DEMAND.PROGRAM.TITLE' | translate"
          [class.is-invalid]="submitted && form.controls.title.invalid"
          formControlName="title"
        />
        <div class="invalid-feedback" *ngIf="submitted && form.controls.title.invalid">
          <div *ngIf="form.controls.title.errors.required">{{ 'VALIDATION.REQUIRED' | translate }}</div>
          <div *ngIf="form.controls.title.errors.maxlength">
            {{
              'VALIDATION.MAXLENGTH' | translate: {
                requiredLength:
                form.controls.title.errors.maxlength.requiredLength
              }
            }}
          </div>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-12 col-md mr-4">
          <div class="mb-4">
            <label for="video">{{ 'VIEW.MAIN.ON_DEMAND.PROGRAM.CHOOSE_A_VIDEO' | translate }}</label>
            <ng-select
              id="video"
              [items]="items"
              [searchable]="false"
              bindLabel="title"
              bindValue="id"
              [class.is-invalid]="submitted && form.controls.videoIds.invalid"
              [virtualScroll]="true"
              formControlName="videoIds"
              [multiple]="true"
              (scrollToEnd)="onScrollToEnd()"
              [placeholder]="'VIEW.MAIN.ON_DEMAND.PROGRAM.CHOOSE_A_VIDEO' | translate"
            >
              <ng-template ng-header-tmp>
                <input class="form-control" type="text" (keydown)="onSearch($event)"/>
              </ng-template>
            </ng-select>
            <div class="invalid-feedback" *ngIf="submitted && form.controls.videoIds.invalid">
              <div *ngIf="form.controls.videoIds.errors.required">{{ 'VALIDATION.REQUIRED' | translate }}</div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md">
          <div class="mb-4">
            <label for="category">{{ 'VIEW.MAIN.ON_DEMAND.PROGRAM.CHOOSE_A_CATEGORY' | translate }}</label>
            <ng-select
              id="category"
              name="category"
              [placeholder]="'VIEW.MAIN.ON_DEMAND.PROGRAM.CHOOSE_A_CATEGORY' | translate"
              [searchable]="false"
              class="with-placeholder-as-value"
              [class.is-invalid]="submitted && form.controls.category.invalid"
              formControlName="category"
            >
              <ng-option *ngFor="let item of categories" [value]="item.value">
                {{ item.label | translate }}
              </ng-option>
            </ng-select>
            <div class="invalid-feedback" *ngIf="submitted && form.controls.category.invalid">
              <div *ngIf="form.controls.category.errors.required">{{ 'VALIDATION.REQUIRED' | translate }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-12 col-md mr-4">
          <div class="mb-4">
            <label for="coach">{{ 'VIEW.MAIN.ON_DEMAND.PROGRAM.CHOOSE_A_COACH' | translate }}</label>
            <ng-select
              id="coach"
              name="coach"
              [placeholder]="'VIEW.MAIN.ON_DEMAND.PROGRAM.CHOOSE_A_COACH' | translate"
              [searchable]="false"
              class="with-placeholder-as-value"
              formControlName="coachIds"
              [multiple]="true"
              [class.is-invalid]="submitted && form.controls.coachIds.invalid"
            >
              <ng-option *ngFor="let item of coaches" [value]="item.id">
                {{ item.user | fullName }}
              </ng-option>
            </ng-select>
            <div class="invalid-feedback" *ngIf="submitted && form.controls.coachIds.invalid">
              <div *ngIf="form.controls.coachIds.errors.required">{{ 'VALIDATION.REQUIRED' | translate }}</div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md">
          <div class="form-group mb-4">
            <label for="intensity">{{ 'VIEW.MAIN.ON_DEMAND.PROGRAM.CHOOSE_A_INTENSITY' | translate }}</label>
            <ng-select
              id="intensity"
              name="intensity"
              [placeholder]="'VIEW.MAIN.ON_DEMAND.PROGRAM.CHOOSE_A_INTENSITY' | translate"
              [searchable]="false"
              class="with-placeholder-as-value"
              formControlName="intensity"
              [class.is-invalid]="submitted && form.controls.intensity.invalid"
            >
              <ng-option *ngFor="let item of intensities" [value]="item.value"
              >{{ item.label | translate }}
              </ng-option
              >
            </ng-select>
            <div class="invalid-feedback" *ngIf="submitted && form.controls.intensity.invalid">
              <div *ngIf="form.controls.intensity.errors.required">{{ 'VALIDATION.REQUIRED' | translate }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group mb-4">
        <div class="form-group">
          <label for="description">{{ 'VIEW.MAIN.ON_DEMAND.PROGRAM.DESCRIPTION' | translate }}</label>
          <textarea
            id="description"
            name="description"
            formControlName="description"
            class="form-control"
            [placeholder]="'VIEW.MAIN.ON_DEMAND.PROGRAM.DESCRIPTION' | translate"
            rows="5"
            [maxlength]="Constant.TEXT_MAX_LENGTH_512"
            [class.is-invalid]="submitted && form.controls.description.invalid"
          ></textarea>
          <div class="invalid-feedback" *ngIf="submitted && form.controls.description.invalid">
            <div *ngIf="form.controls.description.errors.required">{{ 'VALIDATION.REQUIRED' | translate }}</div>
            <div *ngIf="form.controls.description.errors.maxlength">
              {{
                'VALIDATION.MAXLENGTH' | translate: {
                  requiredLength:
                  form.controls.description.errors.maxlength.requiredLength
                }
              }}
            </div>
          </div>
          <div class="d-flex justify-content-end">
            {{ form.get('description').value?.length || 0 }}/{{ Constant.TEXT_MAX_LENGTH_512 }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-9 mb-4">
          <app-thumbnail-and-files-upload
            [thumbnailMode]="true"
            [thumbnailPlaceholder]="'COMPONENT.FILE_UPLOAD.THUMBNAIL' | translate"
            (startUpload)="handleStartUpload($event)"
            (imageId)="handleImageId($event)"
            [formSubmitted]="submitted"
            [isThumbnailRequired]="true"
            (thumbnailFormStatus)="handleThumbnailFormStatus($event)"
          ></app-thumbnail-and-files-upload>
        </div>
      </div>
    </div>
  </div>
</section>
