import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-account-refer-friend',
  templateUrl: './AccountReferFriendComponent.html',
  styleUrls: [ './AccountReferFriendComponent.scss' ]
})
export class AccountReferFriendComponent implements OnInit {
  public form: FormGroup;

  constructor(public bsModalRef: BsModalRef) {
  }

  public ngOnInit(): void {
    this.form = new FormGroup({
      name: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required)
    });
  }

  public close(): void {
    this.bsModalRef.hide();
  }
}
