import { LiveClassCategory } from 'app/data/enum/liveClass/LiveClassCategory';
import { IdentifiableAuditableObjectDTO } from '../IdentifiableAuditableObjectDTO';
import { UserDetailsPageDTO } from '../user/UserDetailsPageDTO';
import { Type } from 'class-transformer';

export class CoachDetailsPageDTO extends IdentifiableAuditableObjectDTO<number> {
  public classCategories: LiveClassCategory[];

  public enabled: boolean;

  @Type(() => UserDetailsPageDTO)
  public user: UserDetailsPageDTO;
}