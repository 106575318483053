import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { Transition } from '@uirouter/core';
import Player, { Options } from '@vimeo/player';
import { State } from 'app/common/State';
import { VideoDetailsDTO } from 'app/data/dto/onDemandVideo/VideoDetailsDTO';
import { VideoPageCriteriaDTO } from 'app/data/dto/onDemandVideo/VideoPageCriteriaDTO';
import { ApplicationModel } from 'app/model/ApplicationModel';
import { SeniorModel } from 'app/model/SeniorModel';
import { StateUtil } from 'app/util/StateUtil';
import { ViewUtil } from 'app/util/ViewUtil';
import { OnDemandVideoModel } from 'app/model/OnDemandVideoModel';
import { ProgramSeniorResponseDTO } from 'app/data/dto/programs/ProgramSeniorResponseDTO';
import { VideoRatingRequestDTO } from 'app/data/dto/onDemandVideo/VideoRatingRequestDTO';
import { PortalUtil } from 'app/util/PortalUtil';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import { TemplatePortal } from '@angular/cdk/portal';

@Component({
  selector: 'app-on-demand-program-play',
  templateUrl: './OnDemandProgramPlayComponent.html',
  styleUrls: [ './OnDemandProgramPlayComponent.scss' ]
})
export class OnDemandProgramPlayComponent implements OnInit, OnDestroy {
  @ViewChild('headingTemplate', { static: true })
  private readonly headingTemplate: TemplateRef<any>;

  public readonly pageSize: number = 25;
  public player: Player;
  public programId: number;
  public video: VideoDetailsDTO;
  private videoPlayed: boolean = false;
  public criteria: VideoPageCriteriaDTO;
  public program: ProgramSeniorResponseDTO;
  public currentVideoId: number;
  public videoDetailsAreVisible: boolean = false;

  constructor(
    private transition: Transition,
    public stateUtil: StateUtil,
    private onDemandVideoModel: OnDemandVideoModel,
    private seniorModel: SeniorModel,
    private viewUtil: ViewUtil,
    private applicationModel: ApplicationModel,
    private portalUtil: PortalUtil,
    private viewContainerRef: ViewContainerRef
  ) {
    this.programId = this.transition.params().id as number;
    this.applicationModel.selectSideBarItemWithState(State.MAIN.SENIOR.ON_DEMAND.VIDEO.LIST);
  }

  public ngOnInit(): void {
    this.portalUtil.attachPortalTo(
      MainLayoutComponent.PORTAL_OUTLET.HEADING,
      new TemplatePortal(this.headingTemplate, this.viewContainerRef)
    );

    this.getProgram();
  }

  public ngOnDestroy(): void {
    this.portalUtil.detachPortalFrom(MainLayoutComponent.PORTAL_OUTLET.HEADING);
  }

  public setCurrentVideoId(newVideoId: number): void {
    this.currentVideoId = newVideoId;
    this.getCurrentVideo();
  }

  private setupVimeoPlayer(): void {
    if (this.player) {
      this.player.destroy();
    }

    const options: Options = {
      url: this.video.vimeoVideo.embedUrl,
      byline: false,
      portrait: false,
      responsive: true,
      title: false
    };

    this.player = new Player('vimeo', options);

    this.captureVideoPlayedEvent();
  }

  private captureVideoPlayedEvent(): void {
    this.player.on('play', () => {
      if (!this.videoPlayed) {
        this.onDemandVideoModel.sendVideoPlayedEvent(this.currentVideoId, this.applicationModel.platform)
          .subscribe(() => {
            this.videoPlayed = true;
          });
      }
    });
  }

  private getProgram(): void {
    this.seniorModel.getProgramById(this.programId)
      .subscribe((program: ProgramSeniorResponseDTO) => {
          this.program = program;
          this.currentVideoId = program.videoPrograms[0].video.id;
          this.getCurrentVideo();
        },
        (error) => {
          this.viewUtil.handleServerError(error);
        });
  }

  private getCurrentVideo(): void {
    this.onDemandVideoModel.getVideo(this.currentVideoId)
      .subscribe((video: VideoDetailsDTO) => {
          this.video = video;
          this.setupVimeoPlayer();
        },
        (error) => {
          this.viewUtil.handleServerError(error);
        });
  }

  public rateVideo(rating: VideoRatingRequestDTO): void {
    this.onDemandVideoModel.rateVideo(this.currentVideoId, rating)
      .subscribe(() => void 0, (error) => {
        this.viewUtil.handleServerError(error);
      });
  }
}
