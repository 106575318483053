<ng-select #select [items]="data$ | async"
           bindLabel="fullName"
           dropdownPosition="bottom"
           [ngClass]="{'is-invalid': submitted && isInvalid(), 'is-valid': submitted && !isInvalid() }"
           [(ngModel)]="selected"
           [placeholder]="placeholder | translate"
           [searchable]="false"
           [closeOnSelect]="false"
           [disabled]="disabled"
           [hideSelected]="true"
           [clearOnBackspace]="false"
           [multiple]="multiple"
           [virtualScroll]="true"
           (scrollToEnd)="onScrollToEnd()"
           (ngModelChange)="onSelectionChange()">
  <ng-template ng-multi-label-tmp>
    <span>{{ selected | mapCollection: 'user' | mapCollection: 'fullName' | arrayJoin:', ':2 }}</span>
  </ng-template>
  <ng-template ng-header-tmp>
    <div class="header">
      <ng-container *ngIf="selected?.length === 0">
        <span>{{ 'COMMON.NOT_SELECTED' | translate }}</span>
      </ng-container>

      <div class="element" *ngFor="let item of selected">
        <div class="custom-control custom-checkbox">
          <input [id]="'item-' + item.id"
                 [name]="'item-' + item.id"
                 type="checkbox"
                 class="custom-control-input"
                 (change)="select.clearItem(item)"
                 checked>
          <label class="custom-control-label d-flex gap-2 align-items-center" [for]="'item-' + item.id">
            <app-avatar [src]="item.user.avatar?.defaultAsset?.url"
                        [circle]="true"
                        size="24px"
                        class="leading-none">
            </app-avatar>
            <span>{{ item.user.fullName }}</span>
          </label>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template ng-option-tmp let-item="item" let-item$="item$">
    <div class="custom-control custom-checkbox">
      <input [id]="'item-' + item.id"
             [name]="'item-' + item.id"
             type="checkbox"
             class="custom-control-input"
             [ngModel]="item$.selected">
      <label class="custom-control-label d-flex gap-2 align-items-center" [for]="'item-' + item.id">
        <app-avatar [src]="item.user.avatar?.defaultAsset?.url"
                    [circle]="true"
                    size="24px"
                    class="leading-none">
        </app-avatar>
        <span>{{ item.user.fullName }}</span>
      </label>
    </div>
  </ng-template>
</ng-select>
