<div class="modal-header d-flex justify-content-end border-0">
  <div class="modal-control">
    <button type="button" class="btn" (click)="close()">
      <svg-icon class="d-flex" src="asset/image/icon/x.svg"></svg-icon>
    </button>
  </div>
</div>
<div id="modal-body" class="d-flex flex-column align-items-center justify-content-center p-4">
  <img src="asset/image/register-not-found.png" alt=""/>
  <h1>{{ headerKey | translate }}</h1>
  <div>{{ errorDescriptionKey | translate }}</div>
  <div>
    <h2>Contact us:</h2>
    <div>(855) 236-4389</div>
  </div>
  <button id="close-btn" class="btn btn-primary" (click)="close()">Got it!</button>
</div>
