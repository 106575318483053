import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ScheduledLiveClassSeniorDetailsPageDTO } from 'app/data/dto/scheduledLiveClass/senior/ScheduledLiveClassSeniorDetailsPageDTO';
import { IntensityLevel } from 'app/data/enum/IntensityLevel';

@Component({
  selector: 'app-intensity-cell-renderer',
  templateUrl: './IntensityCellRendererComponent.html',
  styleUrls: [ './IntensityCellRendererComponent.scss' ]
})
export class IntensityCellRendererComponent implements ICellRendererAngularComp {
  public data: ScheduledLiveClassSeniorDetailsPageDTO;

  public intensity: IntensityLevel;

  public readonly IntensityLevel: typeof IntensityLevel = IntensityLevel;

  public agInit(params: ICellRendererParams): void {
    this.data = params.data;
    this.intensity = this.data.intensity;
  }

  public refresh(params: ICellRendererParams): boolean {
    return false;
  }
}
