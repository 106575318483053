<ng-template #headingTemplate>
  <h1>{{ 'COMMON.DETAILS' | translate }}</h1>

  <div class="d-flex flex-wrap gap-4">
    <button class="btn btn-outline-primary w-100 w-sm-auto" (click)="stateUtil.goBack()">
      {{ 'COMMON.BACK' | translate }}
    </button>

    <button *ngIf="isAdmin" class="btn btn-primary w-100 w-sm-auto" (click)="edit()">
      {{ 'COMMON.EDIT' | translate }}
    </button>
  </div>
</ng-template>

<section class="section">
  <ng-container *ngIf="video">
    <!-- TODO 2nd phase?
    <app-tabs [tabs]="tabs" [translationPrefix]="''"></app-tabs>
  -->
    <div class="d-flex gap-6 flex-wrap flex-lg-nowrap">
      <div class="d-flex flex-column align-items-center gap-4">
        <img id="thumbnail" [src]="video.thumbnail.defaultAsset.url" alt=""/>
        <div *ngIf="video.intensity !== IntensityLevel.NOT_APPLICABLE">
          <span class="mr-1">{{ 'VIEW.MAIN.ON_DEMAND.INTENSITY' | translate }}</span>
          <svg-icon
            *ngIf="video.intensity === IntensityLevel.BEGINNER"
            [src]="'asset/image/icon/level-1.svg'"
          ></svg-icon>
          <svg-icon
            *ngIf="video.intensity === IntensityLevel.INTERMEDIATE"
            [src]="'asset/image/icon/level-2.svg'"
          ></svg-icon>
          <svg-icon
            *ngIf="video.intensity === IntensityLevel.ADVANCED"
            [src]="'asset/image/icon/level-3.svg'"
          ></svg-icon>
        </div>
      </div>

      <div class="container-fluid flex-grow-1">
        <header>
          <p class="font-weight-normal text-grey mb-0">{{ 'VIEW.MAIN.ON_DEMAND.TITLE' | translate }}</p>
          <h2>{{ video.title }}</h2>
        </header>

        <hr>

        <div class="row">
          <div class="col-12 col-lg-8">
            <dl class="col-count-md-2">
              <div>
                <dt class="font-weight-normal text-grey">{{ 'VIEW.MAIN.ON_DEMAND.COACH' | translate }}</dt>
                <dd class="pl-2">
                  <ul class="list-unstyled">
                    <li *ngFor="let coach of video.coaches">
                      {{ coach.user.firstName }} {{ coach.user.lastName }}
                    </li>
                  </ul>
                </dd>
              </div>

              <div>
                <dt class="font-weight-normal text-grey">{{ 'VIEW.MAIN.ON_DEMAND.CATEGORY' | translate }}</dt>
                <dd class="pl-2">{{ 'ENUM.VIDEO_CATEGORY.' + video.category | translate }}</dd>
              </div>

              <div class="break-after-column">
                <dt class="font-weight-normal text-grey">{{ 'VIEW.MAIN.ON_DEMAND.TYPE' | translate }}</dt>
                <dd class="pl-2">
                  <ul class="list-unstyled">
                    <li *ngFor="let subcategory of video.subcategories">
                      {{ 'ENUM.VIDEO_SUBCATEGORY.' + subcategory | translate }}
                    </li>
                  </ul>
                </dd>
              </div>

              <div>
                <dt class="font-weight-normal text-grey">{{ 'VIEW.MAIN.ON_DEMAND.DURATION' | translate }}</dt>
                <dd class="pl-2">{{ video.duration }} min</dd>
              </div>

              <div>
                <dt class="font-weight-normal text-grey">{{ 'VIEW.MAIN.ON_DEMAND.LANGUAGE' | translate }}</dt>
                <dd class="pl-2">{{ video.language | uppercase }}</dd>
              </div>

              <div>
                <dt class="font-weight-normal text-grey">{{ 'VIEW.MAIN.ON_DEMAND.RATING' | translate }}</dt>
                <dd class="pl-2">
                  <app-rating-stars [value]="video.rating"></app-rating-stars>
                </dd>
              </div>

              <div class="col-span-all">
                <dt class="font-weight-normal text-grey">{{ 'VIEW.MAIN.ON_DEMAND.DESCRIPTION' | translate }}</dt>
                <dd class="pl-2">{{ video.description }}</dd>
              </div>
            </dl>

            <div class="w-100 w-lg-50 mt-4 mb-4" id="vimeo"></div>

            <ng-container *ngIf="video.attachments.length > 0">
              <hr>
              <div class="d-flex justify-content-between font-weight-normal text-grey">
                <span>{{ 'VIEW.MAIN.ON_DEMAND.FILES_ATTACHED' | translate }}</span>
              </div>

              <div class="mt-2">
                <app-attachments [attachments]="video.attachments"></app-attachments>
              </div>
            </ng-container>
          </div> <!-- /.col -->

          <div class="col-12 col-lg-4">
            <div class="custom-control custom-checkbox text-right">
              <input
                type="checkbox"
                class="custom-control-input"
                id="recommended"
                name="recommended"
                disabled
                [(ngModel)]="video.recommended"
              />
              <label class="custom-control-label" for="recommended">
                {{ 'VIEW.MAIN.ON_DEMAND.RECOMMENDED' | translate }}
              </label>
            </div>

            <aside class="mt-6">
              <h3 class="mb-4">{{ 'VIEW.MAIN.ON_DEMAND.FEEDBACK' | translate }}</h3>

              <ng-container *ngIf="videoRatingPage?.totalElements; else noRatings">
                <ul id="feedback-list"
                    infiniteScroll
                    [infiniteScrollDistance]="2"
                    [scrollWindow]="false"
                    (scrolled)="getVideoRatingPage(true)"
                >
                  <li *ngFor="let rating of videoRatingPage.content" class="media">
                    <div class="media-body overflow-hidden">
                      <div class="d-flex align-items-center gap-2">
                        <app-avatar [src]="rating.senior.user.avatar?.defaultAsset?.url"
                                    size="21px"
                                    [circle]="true">
                        </app-avatar>
                        <h5 class="d-flex align-items-center gap-1 font-weight-normal text-grey min-w-0 mt-0 mb-1">
                          <span class="text-truncate">{{ rating.senior.user | fullName }}</span>
                          <span>&#183;</span>
                          <span>{{ rating.rating }}/5</span>
                        </h5>
                      </div>
                      <p class="font-weight-light font-size-sm">{{ rating.feedback }}</p>
                    </div>
                  </li>
                </ul>
              </ng-container>

              <ng-template #noRatings>
                <p>{{ 'VIEW.MAIN.ON_DEMAND.FEEDBACK_EMPTY' | translate }}</p>
              </ng-template>
            </aside>
          </div> <!-- /.col -->
        </div> <!-- /.row -->
      </div> <!-- /.container-fluid -->
    </div>
  </ng-container>
</section>
