import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'validProperties'
})
export class ValidPropertiesPipe implements PipeTransform {
  transform(value: any): boolean {
    if (value === null || value === undefined) {
      return false;
    }
    return Object.values(value).some((val) => val !== null && val !== '');
  }
}
