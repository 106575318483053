<div class="p-4">
  <div class="filter-panel">
    <div class="row w-100 no-gutters">
      <div class="col-12 mb-4 d-flex justify-content-between align-items-center">
        <p (click)="onClearFiltersClick()" class="mb-0 btn p-0">
          {{ 'COMPONENT.VIDEO_FILTERS.CLEAR_FILTERS' | translate }}
        </p>
        <svg-icon class="d-flex btn m-0 p-0" (click)="onHideFiltersClick()" src="asset/image/icon/x.svg"></svg-icon>
      </div>

      <div class="col-12 col-sm mr-4">
        <p class="font-weight-600 mb-2">{{ "COMPONENT.VIDEO_FILTERS.VIDEO_SUBCATEGORY" | translate }}</p>
        <div class="form-group">
          <ng-select
            id="typeSearch"
            name="typeSearch"
            [placeholder]="'COMMON.SEARCH' | translate"
            [style.--icon]="'url(/asset/image/icon/search.svg)'"
            [searchable]="true"
            [multiple]="true"
            [(ngModel)]="filters.categories.selectedCategories"
          >
            <ng-option *ngFor="let category of categories" [value]="category.value">
              {{ category.label | translate }}
            </ng-option>
          </ng-select>
        </div>
      </div>
      <div class="col-12 col-sm mr-4">
        <p class="font-weight-600 mb-2">{{ "COMPONENT.VIDEO_FILTERS.COACH" | translate }}</p>
        <div class="form-group">
          <ng-select
            id="coachSearch"
            name="coachSearch"
            [placeholder]="'COMMON.SEARCH' | translate"
            [style.--icon]="'url(/asset/image/icon/search.svg)'"
            [searchable]="true"
            [multiple]="true"
            [(ngModel)]="filters.coaches.selectedCoaches"
          >
            <ng-option *ngFor="let coach of coaches" [value]="coach.id">
              {{ coach.user.firstName }} {{ coach.user.lastName }}
            </ng-option>
          </ng-select>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <button class="btn btn-primary" (click)="onShowFiltersClick()">
        {{ 'VIEW.MAIN.ON_DEMAND.SHOW' | translate }}
      </button>
    </div>
  </div>
</div>
