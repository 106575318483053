import { TemplatePortal } from '@angular/cdk/portal';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Transition } from '@uirouter/angular';
import { State } from 'app/common/State';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import { WatchPartyCreateDTO } from 'app/data/dto/watchParty/WatchPartyCreateDTO';
import { WatchPartyModel } from 'app/model/WatchPartyModel';
import { ObjectUtil } from 'app/util/ObjectUtil';
import { PortalUtil } from 'app/util/PortalUtil';
import { StateUtil } from 'app/util/StateUtil';
import { minDateTimeValidator } from 'app/util/validator/MinDateTimeValidator';
import { ViewUtil } from 'app/util/ViewUtil';
import { Base64 } from 'js-base64';

@Component({
  selector: 'app-watch-party-create',
  templateUrl: './WatchPartyCreateComponent.html'
})
export class WatchPartyCreateComponent implements OnInit, OnDestroy {
  @ViewChild('headingTemplate', { static: true })
  private readonly headingTemplate: TemplateRef<any>;

  public State: typeof State = State;

  public minDate: Date = new Date();

  public form: FormGroup = this.fb.group({
    type: [ undefined, Validators.required ],
    category: [ undefined ],
    subcategory: [ undefined ],
    video: [ undefined, Validators.required ],
    startDate: [ undefined, [ Validators.required, minDateTimeValidator(this.minDate) ] ],
    participants: [ undefined, [ Validators.required, Validators.maxLength(3) ] ]
  });

  constructor(private readonly portalUtil: PortalUtil,
              private readonly viewContainerRef: ViewContainerRef,
              private readonly fb: FormBuilder,
              private readonly stateUtil: StateUtil,
              private readonly viewUtil: ViewUtil,
              private readonly transition: Transition,
              private readonly watchPartyModel: WatchPartyModel) { }

  public ngOnInit(): void {
    const defaultVideo = this.transition.params()?.video;

    this.portalUtil.attachPortalTo(
      MainLayoutComponent.PORTAL_OUTLET.HEADING,
      new TemplatePortal(this.headingTemplate, this.viewContainerRef)
    );

    if (defaultVideo) {
      const videoDecoded = JSON.parse(Base64.decode(defaultVideo));
      this.form.patchValue(videoDecoded);
    }
  }

  public ngOnDestroy(): void {
    this.portalUtil.detachPortalFrom(MainLayoutComponent.PORTAL_OUTLET.HEADING);
  }


  public save(): void {
    if (this.form.valid) {
      const payload: WatchPartyCreateDTO = ObjectUtil.plainToClass(WatchPartyCreateDTO, this.form.value);
      if (!payload.participantIds) {
        payload.participantIds = [];
      }
      this.watchPartyModel.create(payload)
        .subscribe({
          next: () => this.stateUtil.goBack(),
          error: (err) => this.viewUtil.handleServerError(err)
        });
    }
  }
}
