import { BaseVideoDTO } from 'app/data/dto/onDemandVideo/BaseVideoDTO';
import { IntensityLevel } from 'app/data/enum/IntensityLevel';
import { Type } from 'class-transformer';
import { ImageResponseDTO } from 'app/data/dto/images/ImageResponseDTO';

export class WatchPartyVideoDTO extends BaseVideoDTO {
  public description: string;

  public duration: number;

  public intensity: IntensityLevel;

  public language: string;

  @Type(() => ImageResponseDTO)
  public thumbnail: ImageResponseDTO;
}