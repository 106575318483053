import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CalendarEvent, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { State } from 'app/common/State';
import { MyScheduleCriteriaDTO } from 'app/data/dto/schedule/MyScheduleCriteriaDTO';
import { ScheduledLiveClassDetailsResponseDTO } from 'app/data/dto/scheduledLiveClass/admin/ScheduledLiveClassDetailsResponseDTO';
import { ApplicationModel } from 'app/model/ApplicationModel';
import { CoachModel } from 'app/model/CoachModel';
import { ViewUtil } from 'app/util/ViewUtil';
import {
  addDays,
  addMinutes,
  differenceInMinutes,
  endOfDay,
  format,
  startOfDay,
  startOfHour,
  startOfWeek
} from 'date-fns';
import _ from 'lodash';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subject } from 'rxjs';
import { debounceTime, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { JoinUserComponent } from '../liveClasses/components/join/JoinUserComponent';
import { ScheduledLiveClassJoinComponentInput } from '../liveClasses/components/join/JoinUserInterface';
import { CoachRecentRatingsResponseDTO } from 'app/data/dto/coach/CoachRecentRatingsResponseDTO';
import { PortalUtil } from 'app/util/PortalUtil';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import { TemplatePortal } from '@angular/cdk/portal';

@Component({
  selector: 'app-dashboard-coach',
  templateUrl: 'DashboardCoachComponent.html',
  styleUrls: [ 'DashboardCoachComponent.scss' ]
})
export class DashboardCoachComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('headingTemplate', { static: true })
  private readonly headingTemplate: TemplateRef<any>;

  @ViewChild('scrollContainer')
  public scrollContainer: ElementRef<HTMLElement>;

  @ViewChild('headerTemplate', { static: true })
  public headerTemplate: TemplateRef<any>;

  private destroy$: Subject<void> = new Subject<void>();
  public view: CalendarView = CalendarView.Month;
  public CalendarView: typeof CalendarView = CalendarView;
  public viewDate: Date = new Date();
  public activeDayIsOpen: boolean = true;
  public daysInWeek: number = 7;
  public criteria: MyScheduleCriteriaDTO = new MyScheduleCriteriaDTO();
  public weekDisplay: string;
  public bsConfig: Partial<BsDatepickerConfig>;

  public options: { name: string; view: CalendarView }[];

  public refresh: Subject<any> = new Subject<void>();

  public events: CalendarEvent[] = [];

  public recentRatings: CoachRecentRatingsResponseDTO;

  constructor(public viewUtil: ViewUtil,
              private applicationModel: ApplicationModel,
              private changeDetector: ChangeDetectorRef,
              private breakpointObserver: BreakpointObserver,
              private coachModel: CoachModel,
              private translate: TranslateService,
              private modalService: BsModalService,
              private portalUtil: PortalUtil,
              private viewContainerRef: ViewContainerRef) {
    this.bsConfig = Object.assign(
      {},
      {
        dateInputFormat: 'MMMM, YYYY'
      }
    );
    this.updateCalendarView();
  }

  public updateCalendarView(): void {
    const weekLaterDate: Date = addDays(this.viewDate, this.daysInWeek - 1);

    if (this.viewDate.getMonth() !== weekLaterDate.getMonth()) {
      this.weekDisplay = `${ format(this.viewDate, 'MMMM') }/${ format(weekLaterDate, 'MMMM, yyyy') }`;
    }
    else {
      this.weekDisplay = format(this.viewDate, 'MMMM, yyyy');
    }
  }

  public ngOnInit(): void {
    this.applicationModel.selectSideBarItemWithState(State.MAIN.COACH.MY_SCHEDULE);

    this.portalUtil.attachPortalTo(
      MainLayoutComponent.PORTAL_OUTLET.HEADING,
      new TemplatePortal(this.headingTemplate, this.viewContainerRef)
    );

    this.options = [
      // TODO for second phase
      // { name: this.translate.instant('COMMON.MONTH'), view: CalendarView.Month },
      { name: this.translate.instant('COMMON.WEEK'), view: CalendarView.Week }
      // { name: this.translate.instant('COMMON.DAY'), view: CalendarView.Day },
    ];

    const CALENDAR_RESPONSIVE = {
      small: {
        breakpoint: '(max-width: 576px)',
        daysInWeek: 2
      },
      medium: {
        breakpoint: '(max-width: 768px)',
        daysInWeek: 3
      },
      large: {
        breakpoint: '(max-width: 960px)',
        daysInWeek: 5
      },
      extraLarge: {
        breakpoint: '(max-width: 961px)',
        daysInWeek: 7
      }
    };

    this.breakpointObserver
      .observe(Object.values(CALENDAR_RESPONSIVE).map(({ breakpoint }) => breakpoint))
      .pipe(
        takeUntil(this.destroy$),
        filter((state: BreakpointState) => {
          const foundBreakpoint = Object.values(CALENDAR_RESPONSIVE).find(
            ({ breakpoint }) => !!state.breakpoints[breakpoint]
          );
          if (foundBreakpoint) {
            this.daysInWeek = foundBreakpoint.daysInWeek;
            return true;
          }
          else {
            this.daysInWeek = 7;
            return false;
          }
        }),
        debounceTime(500),
        switchMap(() => {
          const startOfTheWeek = this.viewDate;
          this.criteria.dateFrom = startOfDay(this.viewDate);
          this.criteria.dateTo = endOfDay(addDays(startOfTheWeek, this.daysInWeek - 1));
          return this.getSchedule(this.criteria);
        }),
        tap((calendarEvents: CalendarEvent[]) => {
          this.events = calendarEvents;
        })
      )
      .subscribe();

    this.getRecentRatings();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.portalUtil.detachPortalFrom(MainLayoutComponent.PORTAL_OUTLET.HEADING);
  }

  public ngAfterViewInit(): void {
    const defaultOption = this.options.find((option) => option.view === CalendarView.Week);
    this.setView(defaultOption.view);
    this.changeDetector.detectChanges();

    this.scrollToCurrentView();
  }

  public handleViewDateChange(daysInWeek: number): void {
    this.activeDayIsOpen = false;
    this.viewDate = addDays(this.viewDate, daysInWeek);
    const startOfTheWeek = startOfWeek(this.viewDate);
    this.criteria.dateFrom = startOfDay(this.viewDate);
    this.criteria.dateTo = addDays(startOfTheWeek, this.daysInWeek + 1);
  }

  public setView(view: CalendarView): void {
    this.view = view;
  }

  public handleEvent(action: string, event: CalendarEvent): void {
    const { meta } = event;
    this.showScheduledDetails(meta);
  }

  public eventTimesChanged({ event, newStart, newEnd }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  public onDateChange(event: any) {
    if (event) {
      this.viewDate = event;
      this.updateCalendarView();
      const startOfTheWeek = this.viewDate;
      this.criteria.dateFrom = startOfDay(this.viewDate);
      this.criteria.dateTo = endOfDay(addDays(startOfTheWeek, this.daysInWeek - 1));
      this.getSchedule(this.criteria).pipe(takeUntil(this.destroy$)).subscribe();
    }
  }

  public onPrintClick(): void {
    window.print();
  }

  private getSchedule(criteria: MyScheduleCriteriaDTO): Observable<CalendarEvent[]> {
    return this.coachModel.getMeSchedule(criteria).pipe(
      takeUntil(this.destroy$),
      map((scheduledLiveClasses: ScheduledLiveClassDetailsResponseDTO[]) =>
        scheduledLiveClasses.map((scheduledLiveClass) => ({
          title: `${ format(scheduledLiveClass.startDate, 'h:mmaaaaa\'m\'').toLowerCase() } ${
            scheduledLiveClass.title
          }`,
          start: new Date(scheduledLiveClass.startDate),
          end: addMinutes(new Date(scheduledLiveClass.startDate), scheduledLiveClass.duration),
          meta: scheduledLiveClass
        }))
      ),
      tap((calendarEvents: CalendarEvent[]) => {
        this.events = calendarEvents;
      })
    );
  }

  private showScheduledDetails(scheduledLiveClassDetails: ScheduledLiveClassDetailsResponseDTO): void {
    const scheduledLiveClass: ScheduledLiveClassJoinComponentInput = {
      ..._.cloneDeep(scheduledLiveClassDetails),
      currentSeniorEnrollment: {
        attended: false,
        dropped: false
      }
    };
    const modal: BsModalRef = this.modalService.show(JoinUserComponent, {
      initialState: {
        scheduledLiveClass: scheduledLiveClass,
        coachMode: true
      },
      class: 'modal-dialog-centered'
    });

    modal.onHide
      .pipe(
        takeUntil(this.destroy$),
        filter((reason) => reason === JoinUserComponent.DROP || reason === JoinUserComponent.JOIN),
        switchMap(() => this.getSchedule(this.criteria))
      )
      .subscribe();
  }

  private scrollToCurrentView() {
    if (this.view === CalendarView.Week || CalendarView.Day) {
      const minutesSinceStartOfDay: number = differenceInMinutes(startOfHour(new Date()), startOfDay(new Date()));
      this.scrollContainer.nativeElement.scrollTop = minutesSinceStartOfDay;
    }
  }

  private getRecentRatings(): void {
    this.coachModel.getCurrentCoachRecentRatings(1)
      .subscribe((response: CoachRecentRatingsResponseDTO) => {
        this.recentRatings = response;
      }, (error) => {
        this.viewUtil.handleServerError(error);
      });
  }

}
