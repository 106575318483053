<app-content-and-image-column-layout [imageSrc]="'asset/image/register-background.jpg'">
  <div id="content" class="p-4 d-flex flex-column">
    <img class="logo" src="../../../../../asset/image/logo.png"/>

    <h1 class="mb-6">{{ 'VIEW.PRELIMINARY.LOGIN.RESET_TEMPORARY_PASSWORD' | translate }}</h1>

    <form #changePasswordForm="ngForm" class="row mb-6" novalidate>
      <div class="form-group col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <label for="newPassword">{{ 'VIEW.MAIN.ACCOUNT.CHANGE_PASSWORD.NEW_PASSWORD' | translate }}</label>
        <input
          type="password"
          id="newPassword"
          name="newPassword"
          #newPassword="ngModel"
          class="form-control"
          placeholder="{{ 'VIEW.PRELIMINARY.REGISTRATION.REPEAT_PASSWORD' | translate }}"
          [ngClass]="{'is-invalid': changePasswordForm.submitted && newPassword.invalid, 'is-valid': changePasswordForm.submitted && newPassword.valid }"
          [(ngModel)]="formData.newPassword"
          required
          [pattern]="Constant.PASSWORD_PATTERN"
          appPasswordRequirements
          appInputPasswordToggle
          autocapitalize="new-password"
        />
        <div class="invalid-feedback" *ngIf="changePasswordForm.submitted && newPassword.invalid">
          <div *ngIf="newPassword.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
          <div *ngIf="newPassword.errors['pattern']">
            {{ 'VALIDATION.PASSWORD' | translate: { passwordLength: Constant.PASSWORD_MIN_LENGTH } }}
          </div>
          <div *ngIf="newPassword.errors['equalTo']">{{ 'VALIDATION.PASSWORD_MATCH' | translate }}</div>
          <div *ngIf="newPassword.errors?.passwordRequirements?.maxLength">
            {{ 'VALIDATION.PASSWORD_REQUIREMENTS.MAX_LENGTH' | translate: { max: Constant.PASSWORD_MAX_LENGTH_256 } }}
          </div>
        </div>
      </div>
      <div class="form-group col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <label for="newPasswordRepeat">{{ 'VIEW.MAIN.ACCOUNT.CHANGE_PASSWORD.REPEAT_NEW_PASSWORD' | translate }}</label>
        <input
          type="password"
          id="passwordRepeat"
          name="newPasswordRepeat"
          #newPasswordRepeat="ngModel"
          class="form-control"
          placeholder="{{ 'VIEW.MAIN.ACCOUNT.CHANGE_PASSWORD.REPEAT_NEW_PASSWORD' | translate }}"
          [ngClass]="{'is-invalid': changePasswordForm.submitted && newPasswordRepeat.invalid, 'is-valid': changePasswordForm.submitted && newPasswordRepeat.valid }"
          [(ngModel)]="formData.newPasswordRepeat"
          required
          [pattern]="Constant.PASSWORD_PATTERN"
          [equalTo]="newPassword.control"
          appPasswordRequirements
          appInputPasswordToggle
          autocomplete="new-password"
        />
        <div class="invalid-feedback" *ngIf="changePasswordForm.submitted && newPasswordRepeat.invalid">
          <div *ngIf="newPasswordRepeat.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
          <div *ngIf="newPasswordRepeat.errors['pattern']">
            {{ 'VALIDATION.PASSWORD' | translate: { passwordLength: Constant.PASSWORD_MIN_LENGTH } }}
          </div>
          <div *ngIf="newPasswordRepeat.errors?.passwordRequirements?.maxLength">
            {{ 'VALIDATION.PASSWORD_REQUIREMENTS.MAX_LENGTH' | translate: { max: Constant.PASSWORD_MAX_LENGTH_256 } }}
          </div>
          <div *ngIf="newPasswordRepeat.errors['equalTo']">{{ 'VALIDATION.PASSWORD_MATCH' | translate }}</div>
        </div>
      </div>
      <div class="password-requirements col-12">
        <div
          class="password-req-item"
          [ngClass]="{'is-invalid': !newPassword.value || newPassword.errors?.passwordRequirements?.minLength}"
        >
          {{ 'VALIDATION.PASSWORD_REQUIREMENTS.MIN_LENGTH' | translate: { min: Constant.PASSWORD_MIN_LENGTH } }}
        </div>
        <div
          class="password-req-item"
          [ngClass]="{'is-invalid': !newPassword.value || newPassword.errors?.passwordRequirements?.upperCase}"
        >
          {{ 'VALIDATION.PASSWORD_REQUIREMENTS.UPPERCASE' | translate }}
        </div>
        <div
          class="password-req-item"
          [ngClass]="{'is-invalid': !newPassword.value || newPassword.errors?.passwordRequirements?.lowerCase}"
        >
          {{ 'VALIDATION.PASSWORD_REQUIREMENTS.LOWERCASE' | translate }}
        </div>
        <div
          class="password-req-item"
          [ngClass]="{'is-invalid': !newPassword.value || newPassword.errors?.passwordRequirements?.numberChar}"
        >
          {{ 'VALIDATION.PASSWORD_REQUIREMENTS.NUMBER' | translate }}
        </div>
        <div
          class="password-req-item"
          [ngClass]="{'is-invalid': !newPassword.value || newPassword.errors?.passwordRequirements?.specialChar}"
        >
          {{ 'VALIDATION.PASSWORD_REQUIREMENTS.SPECIAL' | translate }}
        </div>
      </div>
    </form>

    <div class="d-flex justify-content-end mt-6 control-buttons">
      <button class="btn btn-light mr-2 step-btn" (click)="backToLogin()">{{ 'COMMON.BACK' | translate }}</button>
      <button class="btn btn-primary step-btn" (click)="changePassword()">
        {{ 'VIEW.PRELIMINARY.LOGIN.CREATE' | translate }}
      </button>
    </div>
  </div>
</app-content-and-image-column-layout>
