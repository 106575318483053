<ng-template #headingTemplate>
  <h1>{{ 'VIEW.MAIN.LIVE_CLASSES.CLASSES' | translate }}</h1>
</ng-template>

<section class="section">
  <ng-container *ngIf="page$ | async as page">
    <app-tabs
      [translationPrefix]="'VIEW.MAIN.LIVE_CLASSES.'"
      [tabs]="tabs"
      (tabChanged)="activeTab = $event"
    ></app-tabs>

    <app-data-grid
      [data]="page.content"
      [criteria]="criteria"
      [context]="{ host: this, additionalData: { currentUser } }"
      [selectionEnabled]="false"
      [showFilters]="true"
      [showPagination]="true"
      [pageTotal]="page.totalPages"
      [itemTotal]="page.totalElements"
      [showItemsPerPage]="true"
      [showTotalItems]="false"
      [multiSelectionEnabled]="true"
      [useCheckboxSelection]="true"
      (gridAction)="onGridAction($event)"
      (itemsSelected)="onSelectionChange($event)"
      (criteriaChanged)="onCriteriaChanged($event)"
      [columns]="$any([
        { headerName: '', field: 'liveClassSeriesId', minWidth: 50, maxWidth: 50, cellRendererFramework: DataGridTemplateCellRendererComponent, cellRendererParams: { ngTemplate: typeIconTemplate } },
        { headerName: ('VIEW.MAIN.LIVE_CLASSES.NAME' | translate), field: 'title', tooltipField: 'title', minWidth: 200, maxWidth: 300 },
        { headerName: ('VIEW.MAIN.LIVE_CLASSES.TIME' | translate), field: 'startDate', minWidth: 160, maxWidth: 170, cellClass: 'text-right text-lg-left', valueFormatter: dateTimeValueFormatter } ,
        { headerName: ('VIEW.MAIN.LIVE_CLASSES.CATEGORY' | translate), field: 'category', minWidth: 100, maxWidth: 150, valueFormatter: enumCategoryFormatter },
        { headerName: ('VIEW.MAIN.LIVE_CLASSES.LENGTH' | translate), field: 'duration', minWidth: 100, maxWidth: 100, cellClass: 'text-right text-lg-left', valueFormatter: durationFormatter },
        { headerName: ('VIEW.MAIN.LIVE_CLASSES.INTENSITY' | translate), field: 'intensity', minWidth: 100, maxWidth: 100, valueFormatter: enumIntensityFormatter },
        { headerName: ('VIEW.MAIN.LIVE_CLASSES.CAPACITY' | translate), field: 'capacity', minWidth: 80, maxWidth: 100, cellClass: 'text-right text-lg-left' },
        { headerName: '', headerClass: 'header-right', minWidth: 150, maxWidth: 150, cellClass: 'cell-fullwidth', suppressKeyboardEvent: ActionCellRendererComponent.suppressKeyboardEvent, cellRendererFramework: ActionCellRendererComponent }
      ])"
    >
      <app-data-grid-filters>
        <div class="form-row mb-4 mb-lg-0">
          <div class="col-6 col-md-auto">
            <button (click)="onTodayClick()" class="btn btn-outline-primary w-100">
              {{ 'VIEW.MAIN.LIVE_CLASSES.TODAY' | translate }}
            </button>
          </div> <!-- /.col -->

          <div class="col-6 col-md-auto">
            <ng-select
              id="days-ahead"
              name="days-ahead"
              [placeholder]="'VIEW.MAIN.LIVE_CLASSES.NEXT_DAYS' | translate"
              [searchable]="false"
              [style.--icon]="'url(/asset/image/icon/calendar-due.svg)'"
              (ngModelChange)="onDaysAheadChange($event)"
              [(ngModel)]="consecutiveDays"
            >
              <ng-option *ngFor="let daysAhead of daysAheadSelectList" [value]="daysAhead.value">
                {{ daysAhead.label | translate }}
              </ng-option>
            </ng-select>
          </div> <!-- /.col -->

          <div class="col-12 col-md-auto">
            <div id="filter-date" class="mt-4 mt-md-0">
              <svg-icon
                src="asset/image/icon/chevron-left.svg"
                class="mr-auto mr-md-2 btn-link"
                (click)="changeToPreviousDay()"
              ></svg-icon>
              <div class="d-flex flex-column align-items-center">
                <div (click)="dp.toggle()">
                  <span class="btn-link">{{ selectedDate | date: 'EEE, MMM d, yyyy' }}</span>
                </div>
                <input
                  type="text"
                  class="text-like-input"
                  bsDatepicker
                  [bsConfig]="bsConfig"
                  #dp="bsDatepicker"
                  (bsValueChange)="onDateChange($event)"
                  readonly
                />
              </div>
              <svg-icon
                class="ml-auto ml-md-2 btn-link"
                src="asset/image/icon/chevron-right.svg"
                (click)="changeToNextDay()"
              ></svg-icon>
            </div>
          </div> <!-- /.col -->
        </div> <!-- /.form-row -->
      </app-data-grid-filters>

      <app-extra-control>
        <ng-select
          id="filter-classes"
          [placeholder]="'VIEW.MAIN.LIVE_CLASSES.FILTER_BY' | translate"
          [searchable]="false"
          [style.--icon]="'url(/asset/image/icon/filter.svg)'"
          (click)="showFilterClick()"
        >
        </ng-select>
      </app-extra-control>

      <app-selected-filters [criteria]="criteria"
                            [friendlyNames]="friendlyNames"
                            [getLabelForKeyValue]="getLabelForKeyValueBind"
                            (criteriaChange)="onCriteriaChanged($event)"></app-selected-filters>
    </app-data-grid>
  </ng-container>
</section>

<!-- Grid Templates -->
<ng-template #typeIconTemplate let-value="value">
  <div class="d-flex align-items-center h-100">
    <app-live-class-type-icon [seriesId]="value"></app-live-class-type-icon>
  </div>
</ng-template>