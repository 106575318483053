import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { Transition } from '@uirouter/core';
import { State } from 'app/common/State';
import { PopupConfirmationComponent } from 'app/component/ui/popup/PopupConfirmationComponent';
import { PageCriteriaDTO } from 'app/data/dto/PageCriteriaDTO';
import { ProgramResponseDTO } from 'app/data/dto/programs/ProgramResponseDTO';
import { AdminModel } from 'app/model/AdminModel';
import { StateUtil } from 'app/util/StateUtil';
import { ViewUtil } from 'app/util/ViewUtil';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { of, pipe, Subject } from 'rxjs';
import { catchError, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { UserModel } from 'app/model/UserModel';
import { UserDTO } from 'app/data/dto/user/UserDTO';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import { TemplatePortal } from '@angular/cdk/portal';
import { PortalUtil } from 'app/util/PortalUtil';

@Component({
  selector: 'app-program-admin-details',
  templateUrl: './ProgramAdminDetailsComponent.html',
  styleUrls: [ './ProgramAdminDetailsComponent.scss' ]
})
export class ProgramAdminDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('headingTemplate', { static: true })
  private readonly headingTemplate: TemplateRef<any>;
  
  private destroy$: Subject<void> = new Subject<void>();
  private programId: number;
  public program: ProgramResponseDTO;
  public criteria: PageCriteriaDTO;

  public currentUser: UserDTO;

  constructor(public stateUtil: StateUtil,
              private transition: Transition,
              private adminModel: AdminModel,
              private viewUtil: ViewUtil,
              private modalService: BsModalService,
              private userModel: UserModel,
              private portalUtil: PortalUtil,
              private viewContainerRef: ViewContainerRef) {
    this.currentUser = this.userModel.currentUser;
  }

  public ngOnInit(): void {
    this.programId = this.transition.params().id;

    this.portalUtil.attachPortalTo(
      MainLayoutComponent.PORTAL_OUTLET.HEADING,
      new TemplatePortal(this.headingTemplate, this.viewContainerRef)
    );

    this.getProgramById();

    this.criteria = new PageCriteriaDTO();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.portalUtil.detachPortalFrom(MainLayoutComponent.PORTAL_OUTLET.HEADING);
  }

  public onEditClick(): void {
    this.stateUtil.goToState(State.MAIN.ADMIN.PROGRAM.EDIT, { id: this.programId });
  }

  public onDeleteClick(): void {
    const modal: BsModalRef = this.modalService.show(PopupConfirmationComponent, {
      initialState: {
        message: 'VIEW.MAIN.USER.DELETE_PROGRAM_WARNING',
        messageVariables: { title: this.program.title },
        okText: 'COMMON.YES',
        cancelText: 'COMMON.NO'
      },
      class: 'modal-dialog-centered'
    });

    modal.onHide
      .pipe(
        filter((reason) => reason === PopupConfirmationComponent.POPUP_RESULT_CONFIRM),
        switchMap(() => this.adminModel.deleteProgram({ ids: [ this.program.id ] })),
        pipe(
          tap(() => {
            this.viewUtil.showToastSuccess('COMMON.SUCCESS');
            this.stateUtil.goToState(State.MAIN.ADMIN.ON_DEMAND.LIST);
          })
        ),
        takeUntil(this.destroy$),
        catchError((err) => {
          this.viewUtil.handleServerError(err);
          return of(null);
        })
      )
      .subscribe();
  }

  private getProgramById(): void {
    this.adminModel
      .getProgramById(this.programId)
      .pipe(
        tap((program) => {
          this.program = program;
        }),
        takeUntil(this.destroy$),
        catchError((err) => {
          this.viewUtil.handleServerError(err);
          return of(null);
        })
      )
      .subscribe();
  }
}
