import { SeniorProfileRatingDTO } from 'app/data/dto/senior/SeniorProfileRatingDTO';
import { Type } from 'class-transformer';

export class ScheduledLiveClassRatingAdminPageDTO {
  public feedback: string;

  public rating: number;

  @Type(() => SeniorProfileRatingDTO)
  public senior: SeniorProfileRatingDTO;
}