import { Layout } from 'app/common/Layout';

export class State {

  public static readonly RAW = {};

  public static readonly PRELIMINARY = {
    LOGIN: `${ Layout.PRELIMINARY }.login`,
    WITHOUT_VERIFICATION: `${ Layout.PRELIMINARY }.withoutVerification`,
    RESET_PASSWORD_START: `${ Layout.PRELIMINARY }.resetPasswordStart`,
    RESET_PASSWORD_VERIFICATION_CODE: `${ Layout.PRELIMINARY }.resetPasswordVerificationCode`,
    RESET_PASSWORD_COMPLETE: `${ Layout.PRELIMINARY }.resetPasswordComplete`,
    PASSWORD_CHANGE_REQUIRED: `${ Layout.PRELIMINARY }.resetPasswordRequired`,
    REGISTRATION: {
      REGISTRATION: `${ Layout.PRELIMINARY }.registration`,
      START: `${ Layout.PRELIMINARY }.registration.start`,
      PASSWORD: `${ Layout.PRELIMINARY }.registration.password`
    },
    HOW_DOES_IT_WORK: `${ Layout.PRELIMINARY }.howDoesItWork`,
    REGISTRATION_BRANDED: {
      REGISTRATION_BRANDED: `${ Layout.PRELIMINARY }.registrationBranded`,
      START: `${ Layout.PRELIMINARY }.registrationBranded.start`,
      USER_DATA: `${ Layout.PRELIMINARY }.registrationBranded.userData`,
      PASSWORD: `${ Layout.PRELIMINARY }.registrationBranded.password`
    },
    REGISTRATION_COMPLETE: `${ Layout.PRELIMINARY }.registrationComplete`,
    REGISTRATION_CONFIRM: `${ Layout.PRELIMINARY }.registrationConfirm`,
    PREFERENCES: `${ Layout.PRELIMINARY }.preferences`,
    ERROR: {
      ERROR: `${ Layout.PRELIMINARY }.error`,
      NOT_FOUND: `${ Layout.PRELIMINARY }.error.notFound`,
      ACCESS_DENIED: `${ Layout.PRELIMINARY }.error.accessDenied`
    }
  };

  public static readonly MAIN = {
    DASHBOARD: `${ Layout.MAIN }.dashboard`,
    HELP: `${ Layout.MAIN }.help`,
    FAQ: `${ Layout.MAIN }.faq`,
    ACCOUNT: {
      ACCOUNT: `${ Layout.MAIN }.account`,
      DETAILS: `${ Layout.MAIN }.account.details`,
      EDIT: `${ Layout.MAIN }.account.edit`,
      CHANGE_PASSWORD: `${ Layout.MAIN }.account.changePassword`,
      PASSWORD_CHANGE_REQUIRED: `${ Layout.MAIN }.account.passwordChangeRequired`,
      LOGOUT: `${ Layout.MAIN }.account.logout`
    },
    SENIOR: {
      SENIOR: `${ Layout.MAIN }.senior`,
      LIST: `${ Layout.MAIN }.senior.list`,
      CREATE: `${ Layout.MAIN }.senior.create`,
      EDIT: `${ Layout.MAIN }.senior.edit`,
      LIVE_CLASSES: {
        LIVE_CLASSES: `${ Layout.MAIN }.senior.liveClasses`,
        LIST: `${ Layout.MAIN }.senior.liveClasses.list`,
        DETAILS: `${ Layout.MAIN }.senior.liveClasses.details`,
        ZOOM: `${ Layout.MAIN }.senior.liveClasses.zoom`
      },
      FRIEND: {
        FRIEND: `${ Layout.MAIN }.friend`,
        LIST: `${ Layout.MAIN }.friend.list`,
        INVITATIONS: `${ Layout.MAIN }.friend.invitations`,
      },
      ON_DEMAND: {
        ON_DEMAND: `${ Layout.MAIN }.onDemand`,
        VIDEO: {
          VIDEO: `${ Layout.MAIN }.onDemand.video`,
          LIST: `${ Layout.MAIN }.onDemand.video.list`,
          PLAY: `${ Layout.MAIN }.onDemand.video.play`,
          FAVORITES: `${ Layout.MAIN }.onDemand.video.favorites`,
        },
        PROGRAM: {
          PROGRAM: `${ Layout.MAIN }.onDemand.program`,
          LIST: `${ Layout.MAIN }.onDemand.program.list`,
          PLAY: `${ Layout.MAIN }.onDemand.program.play`,
        },
        WATCH_PARTY: {
          WATCH_PARTY: `${ Layout.MAIN }.onDemand.watchParty`,
          LIST: `${ Layout.MAIN }.onDemand.watchParty.list`,
          CREATE: `${ Layout.MAIN }.onDemand.watchParty.create`,
          EDIT: `${ Layout.MAIN }.onDemand.watchParty.edit`,
          DETAILS: `${ Layout.MAIN }.onDemand.watchParty.details`,
          EVENT: `${ Layout.MAIN }.onDemand.watchParty.event`,
        }
      },
      MY_SCHEDULE: `${ Layout.MAIN }.senior.mySchedule`,
      MY_ACTIVITY: {
        MY_ACTIVITY: `${ Layout.MAIN }.senior.myActivity`,
        LIST: `${ Layout.MAIN }.senior.myActivity.list`,
        ALL_BADGES: `${ Layout.MAIN }.senior.myActivity.allBadges`
      }
    },
    ADMIN: {
      ORGANIZATIONS: {
        ORGANIZATIONS: `${ Layout.MAIN }.admin.organizations`,
        LIST: `${ Layout.MAIN }.admin.organizations.list`,
        DETAILS: `${ Layout.MAIN }.admin.organizations.details`,
        CREATE: `${ Layout.MAIN }.admin.organizations.create`,
        EDIT: `${ Layout.MAIN }.admin.organizations.edit`
      },
      ADMIN: `${ Layout.MAIN }.admin`,
      ON_DEMAND: {
        ON_DEMAND: `${ Layout.MAIN }.admin.onDemand`,
        LIST: `${ Layout.MAIN }.admin.onDemand.list`,
        DETAILS: `${ Layout.MAIN }.admin.onDemand.details`,
        EDIT: `${ Layout.MAIN }.admin.onDemand.edit`,
        NEW_ON_DEMAND: `${ Layout.MAIN }.admin.onDemand.newOnDemand`
      },
      PROGRAM: {
        PROGRAM: `${ Layout.MAIN }.admin.program`,
        LIST: `${ Layout.MAIN }.admin.program.list`,
        DETAILS: `${ Layout.MAIN }.admin.program.details`,
        EDIT: `${ Layout.MAIN }.admin.program.edit`,
        CREATE: `${ Layout.MAIN }.admin.program.create`
      },
      LIVE_CLASSES: {
        LIVE_CLASSES: `${ Layout.MAIN }.admin.liveClasses`,
        SINGLE: {
          SINGLE: `${ Layout.MAIN }.admin.liveClasses.single`,
          LIST: `${ Layout.MAIN }.admin.liveClasses.single.list`,
          CREATE: `${ Layout.MAIN }.admin.liveClasses.single.create`,
          DETAILS: `${ Layout.MAIN }.admin.liveClasses.single.details`,
          EDIT: `${ Layout.MAIN }.admin.liveClasses.single.edit`
        },
        SERIES: {
          SERIES: `${ Layout.MAIN }.admin.liveClasses.series`,
          LIST: `${ Layout.MAIN }.admin.liveClasses.series.list`,
          CREATE: `${ Layout.MAIN }.admin.liveClasses.series.create`,
          DETAILS: `${ Layout.MAIN }.admin.liveClasses.series.details`,
          EDIT: `${ Layout.MAIN }.admin.liveClasses.series.edit`
        }
      },
      COACHES: {
        COACHES: `${ Layout.MAIN }.admin.coaches`,
        DETAILS: `${ Layout.MAIN }.admin.coaches.details`,
        LIST: `${ Layout.MAIN }.admin.coaches.list`,
        CREATE: `${ Layout.MAIN }.admin.coaches.create`,
        EDIT: `${ Layout.MAIN }.admin.coaches.edit`
      },
      USER: {
        USER: `${ Layout.MAIN }.admin.user`,
        LIST: `${ Layout.MAIN }.admin.user.list`,
        DETAILS: `${ Layout.MAIN }.admin.user.details`,
        CREATE: `${ Layout.MAIN }.admin.user.create`,
        EDIT: `${ Layout.MAIN }.admin.user.edit`,
        DELETE: `${ Layout.MAIN }.admin.user.delete`
      }
    },
    COACH: {
      MY_SCHEDULE: `${ Layout.MAIN }.coach.mySchedule`,
      COACH: `${ Layout.MAIN }.coach`,
      LIVE_CLASSES: {
        LIVE_CLASSES: `${ Layout.MAIN }.coach.liveClasses`,
        LIST: `${ Layout.MAIN }.coach.liveClasses.list`,
        DETAILS: `${ Layout.MAIN }.coach.liveClasses.details`,
        ZOOM: `${ Layout.MAIN }.coach.liveClasses.zoom`
      },
      ON_DEMAND: {
        ON_DEMAND: `${ Layout.MAIN }.coach.onDemand`,
        LIST: `${ Layout.MAIN }.coach.onDemand.list`,
        DETAILS: `${ Layout.MAIN }.coach.onDemand.details`,
        PROGRAM: `${ Layout.MAIN }.coach.onDemand.program`
      }
    }
  };
}
