import { Transform } from 'class-transformer';
import { IdentifiableObjectDTO } from '../IdentifiableObjectDTO';
import { DateUtil } from 'app/util/DateUtil';

export class AssetUploadResponseDTO extends IdentifiableObjectDTO<number> {
  @Transform(DateUtil.dateTimeConversion)
  public expirationDate: Date;

  public mimeType: string;

  public url: string;
}