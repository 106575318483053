import { DateUtil } from 'app/util/DateUtil';
import { Transform, Type } from 'class-transformer';
import { IdentifiableObjectDTO } from '../../IdentifiableObjectDTO';
import { LiveClassCategory } from 'app/data/enum/liveClass/LiveClassCategory';
import { CoachAdminEssentialsDTO } from '../../admin/CoachAdminEssentialsDTO';
import { ScheduledLiveClassNeedDTO } from '../ScheduledLiveClassNeedDTO';
import { ImageResponseDTO } from '../../images/ImageResponseDTO';
import { ScheduledLiveClassAttachmentResponseDTO } from '../ScheduledLiveClassAttachmentResponseDTO';
import { IntensityLevel } from 'app/data/enum/IntensityLevel';

export class ScheduledLiveClassDetailsResponseDTO extends IdentifiableObjectDTO<number> {
  @Type(() => ScheduledLiveClassAttachmentResponseDTO)
  public attachments: ScheduledLiveClassAttachmentResponseDTO[];

  @Transform(({ value }) => value || 0, { toClassOnly: true })
  public capacity?: number; // min 1 or null for no-limit

  public category: LiveClassCategory;

  @Type(() => CoachAdminEssentialsDTO)
  public coaches: CoachAdminEssentialsDTO[];

  public description: string;

  public duration: number;

  public intensity: IntensityLevel;

  public language: string;

  public liveClassSeriesId?: number;

  public rating: number;

  @Type(() => ScheduledLiveClassNeedDTO)
  public scheduledLiveClassNeeds: ScheduledLiveClassNeedDTO[];

  @Transform(DateUtil.dateTimeConversion)
  public startDate: Date;

  @Type(() => ImageResponseDTO)
  public thumbnail: ImageResponseDTO;

  public title: string;

  public url: string;
}
