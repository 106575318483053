import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PageDTO } from 'app/data/dto/PageDTO';
import { Constant } from 'app/common/Constant';
import { DataGridTemplateCellRendererComponent } from 'app/component/ui/dataGrid/cellRenderer/DataGridTemplateCellRendererComponent';
import { WatchPartyModel } from 'app/model/WatchPartyModel';
import { WatchPartyDTO } from 'app/data/dto/watchParty/WatchPartyDTO';
import { WatchPartyPageCriteriaDTO } from 'app/data/dto/watchParty/WatchPartyPageCriteriaDTO';
import { PageCriteriaDTO } from 'app/data/dto/PageCriteriaDTO';
import { State } from 'app/common/State';

@Component({
  selector: 'app-watch-party-planned-list',
  templateUrl: './WatchPartyPlannedListComponent.html'
})
export class WatchPartyPlannedListComponent implements OnInit {
  @Output() public refresh: EventEmitter<void> = new EventEmitter<void>();

  public criteria: WatchPartyPageCriteriaDTO = new WatchPartyPageCriteriaDTO();
  public page: PageDTO<WatchPartyDTO>;
  public listWindowSize: number = 3;

  public Constant: typeof Constant = Constant;
  public State: typeof State = State;
  public DataGridTemplateCellRendererComponent: typeof DataGridTemplateCellRendererComponent =
    DataGridTemplateCellRendererComponent;

  constructor(public readonly watchPartyModel: WatchPartyModel) {
    this.criteria.pageSize = this.listWindowSize;
    this.criteria.type = 'MINE';
  }

  ngOnInit(): void {
    this.loadData();
  }

  public onCriteriaChanged(criteria: PageCriteriaDTO): void {
    this.criteria = criteria as WatchPartyPageCriteriaDTO;

    this.loadData();
  }

  public nextPage(): void {
    this.criteria.pageSize += this.listWindowSize;

    this.onCriteriaChanged(this.criteria);
  }

  public loadData(): void {
    this.watchPartyModel.getPage(this.criteria)
      .subscribe((result) => {
        this.page = result;
      });
  }

  public drop(data: WatchPartyDTO): void {
    this.watchPartyModel.drop(data.id)
      .subscribe(() => {
        this.loadData();
        this.refresh.emit();
      });
  }

  public cancel(data: WatchPartyDTO): void {
    this.watchPartyModel.cancel(data.id)
      .subscribe(() => {
        this.loadData();
        this.refresh.emit();
      });
  }

  public join(data: WatchPartyDTO): void {
    this.watchPartyModel.join(data.id);
  }

  public accept(data: WatchPartyDTO): void {
    this.watchPartyModel.accept(data.id)
      .subscribe(() => {
        this.loadData();
      });
  }

  public reject(data: WatchPartyDTO): void {
    this.watchPartyModel.reject(data.id)
      .subscribe(() => {
        this.loadData();
        this.refresh.emit();
      });
  }
}
