import { AfterViewInit, Component, forwardRef, Injector, Input, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgControl,
  NgForm,
  NgModel,
  ValidationErrors,
  Validator
} from '@angular/forms';

@Component({
  selector: 'app-form-custom',
  templateUrl: 'FormCustomComponent.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormCustomComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => FormCustomComponent)
    }
  ]
})
export class FormCustomComponent implements ControlValueAccessor, Validator, OnInit, AfterViewInit {

  @ViewChild('model')
  private model: NgModel;

  // 1. pass validator directives on host component (app-form-custom),
  // they will automatically work with local value thanks to ControlValueAccessor
  // 2. pass validator configurations as custom properties (not named like validation directives!)
  // and then use them to setup validation directives internally in HTML of this component (and use the "return this.model.error" validation trick)
  // (or just hardcode internal validation directives)

  // if the value that you pass as [(ngModel)] on host to here is the same value you want to validate against, you would usually pick #1
  // if, however, you want to bind to and validate against something else (i.e. a property inside an object), you probably want to use #2
  // (don't use any validation directives on host component in this case!)

  @Input()
  public minimumCharacters: number = 5;

  public value: string;
  public disabled: boolean;
  public touched: boolean;

  private hostForm: NgForm;
  private hostControl: NgControl;
  private hostValidators: (Function | Validator)[];

  public onChange: any = () => {
  };

  public onTouched: any = () => {
  };

  public onValidatorChange: any = () => {
  };

  public get hostValid(): boolean {
    return (this.hostForm.submitted && this.hostControl.valid);
  }

  public get hostInvalid(): boolean {
    return (this.hostForm.submitted && this.hostControl.invalid);
  }

  constructor(private injector: Injector) {
  }

  public ngOnInit(): void {
    // for various access purposes
    this.hostForm = this.injector.get(NgForm);
    this.hostControl = this.injector.get(NgControl);
    this.hostValidators = this.injector.get(NG_VALIDATORS);
  }

  public ngAfterViewInit(): void {
    // wait for @ViewChild that binds to this.model, this is later used in validate()
    this.onValidatorChange();
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public registerOnValidatorChange(fn: any): void {
    this.onValidatorChange = fn;
  }

  public writeValue(value: any): void {
    if (value && this.value !== value) {
      this.value = value;
    }
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    // custom validation logic (run your own validators here)

    // an example - "hack", to pass errors from internal control with ngModel
    return this.model?.errors ?? null;
  }

  // --

  public onModelChange(value: string): void {
    this.markAsTouched();
    // this.value = value; (value is already set by ngModel binding in template)

    // setTimeout is only needed if you're going to pass this.model.errors in validate above
    // as you need to give time to local validators to recalculate, so that proper this.model.errors is passed upon validation in next cycle
    setTimeout(() => {
      this.onChange(this.value);
    });
  }

  public duplicate(): void {
    this.markAsTouched();
    this.value = this.value + this.value;

    // setTimeout is only needed if you're going to pass this.model.errors in validate above
    // as you need to give time to local validators to recalculate, so that proper this.model.errors is passed upon validation in next cycle
    setTimeout(() => {
      this.onChange(this.value);
    });
  }

}
