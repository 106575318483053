import { VideoDTO } from '../VideoDTO';
import { VideoAttachmentResponseDTO } from '../VideoAttachmentResponseDTO';
import { ImageResponseDTO } from '../../images/ImageResponseDTO';
import { Type } from 'class-transformer';

export class VideoSeniorDTO extends VideoDTO {
  @Type(() => VideoAttachmentResponseDTO)
  public attachments: VideoAttachmentResponseDTO[];

  @Type(() => ImageResponseDTO)
  public thumbnail: ImageResponseDTO;

  public isFavorite: boolean;
}
