import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Ng2StateDeclaration, Transition, TransitionService } from '@uirouter/angular';
import { StateService } from '@uirouter/core';
import { HttpErrorResponse } from '@angular/common/http';
import dedent from 'dedent';
import { ApplicationModel } from 'app/model/ApplicationModel';
import { ApplicationConfig } from 'app/config/ApplicationConfig';
import { LocalMessage } from 'app/data/local/LocalMessage';
import { Event } from 'app/common/Event';
import { Token } from 'app/data/local/auth/Token';
import { UserDTO } from 'app/data/dto/user/UserDTO';
import { AuthModel } from 'app/model/AuthModel';
import { UserModel } from 'app/model/UserModel';
import { ViewUtil } from 'app/util/ViewUtil';
import * as _ from 'lodash';
import { State } from 'app/common/State';
import { HtmlUtil } from 'app/util/HtmlUtil';
import { ApplicationState } from 'app/data/local/ApplicationState';
import { EventManager } from 'app/util/other/EventManager';
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { LocalMessageType } from 'app/data/local/LocalMessageType';
import { LayoutType } from 'app/common/Layout';
import { StateUtil } from 'app/util/StateUtil';
import { PermissionName } from 'app/data/enum/permission/PermissionName';
import { UserType } from './data/enum/user/UserType';
import { NotificationService } from './service/NotificationService';
import { interval, Subject } from 'rxjs';
import { startWith, switchMap, takeUntil, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class Application implements OnDestroy {
  public destroy$: Subject<void> = new Subject<void>();
  private notificationInterval: number = 1000 * 1 * 60; // 1 minute

  // states that are available for not logged in user
  private publicStates: string[] = [
    State.PRELIMINARY.LOGIN,
    State.PRELIMINARY.WITHOUT_VERIFICATION,
    State.PRELIMINARY.RESET_PASSWORD_START,
    State.PRELIMINARY.RESET_PASSWORD_VERIFICATION_CODE,
    State.PRELIMINARY.RESET_PASSWORD_COMPLETE,
    State.PRELIMINARY.REGISTRATION.START,
    State.PRELIMINARY.REGISTRATION_BRANDED.START,
    State.PRELIMINARY.REGISTRATION_BRANDED.USER_DATA,
    State.PRELIMINARY.REGISTRATION_BRANDED.PASSWORD,
    State.PRELIMINARY.REGISTRATION.PASSWORD,
    State.PRELIMINARY.REGISTRATION_COMPLETE,
    State.PRELIMINARY.REGISTRATION_CONFIRM,
    State.PRELIMINARY.ERROR.NOT_FOUND,
    State.PRELIMINARY.ERROR.ACCESS_DENIED,
    State.PRELIMINARY.RESET_PASSWORD_START,
    State.PRELIMINARY.HOW_DOES_IT_WORK,

    State.MAIN.FAQ
  ];

  // states that should redirect "into" main application, if user is logged in
  private transientStates: string[] = [
    State.PRELIMINARY.LOGIN
  ];

  // Angular application can get stable "too quick" for the first time, which the messes with the HMR code
  // (html input restoration doesn't work properly, because html inputs aren't created yet at that stage)
  // so we intentionally destabilize it by introducing a fake timeout,
  // that will be released as soon as the first initial navigation will complete successfully
  // This guarantees that html inputs will be there by that time and HMR restoration can proceed
  private angularApplicationRefStabilityTimeoutId: ReturnType<typeof setTimeout>;

  constructor(private eventManager: EventManager,
              private transitionService: TransitionService,
              private stateService: StateService,
              private viewUtil: ViewUtil,
              private stateUtil: StateUtil,
              private zone: NgZone,
              private applicationModel: ApplicationModel,
              private authModel: AuthModel,
              private userModel: UserModel,
              private notificationService: NotificationService
  ) {

    this.angularApplicationRefStabilityTimeoutId = setTimeout(() => {
    }, 9999);

    this.configureTransitions();
    this.configureListeners();
    this.configureTranslations();
    this.configurePlatform();
    this.configureInterface();
    this.introduce();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private configureTransitions(): void {
    this.transitionService.onBefore({}, (transition: Transition) => {
      const toState: Ng2StateDeclaration = transition.to();
      const toStateParams: { [paramName: string]: any } = transition.params('entering');

      if (!this.authModel.isLoggedIn && _.includes(this.transientStates, toState.name) && !this.applicationModel.goingToTransientState) {
        this.applicationModel.goingToTransientState = true;

        return this.authModel.recoverToken()
          .then((token: Token) => {
            this.eventManager.broadcast(Event.AUTH.LOGIN.SUCCESS);
            return false;
          })
          .catch((error) => {
            if (this.applicationModel.resolvingUnauthorizedState) {
              // we might be resolving unauthorized, but still another transition could sneak in, so we need to hold it
              if (toState.name !== State.PRELIMINARY.LOGIN) {
                return false;
              }
              else {
                return true; // proceed with transition
              }
            }
            else {
              return this.stateService.target(toState.name, toStateParams);
            }
          });
      }
      else if (!this.authModel.isLoggedIn && !_.includes(this.publicStates, toState.name)) {
        this.applicationModel.stateBeforeLogin = new ApplicationState(toState, toStateParams);

        return this.authModel.recoverToken()
          .then((token: Token) => {
            this.eventManager.broadcast(Event.AUTH.LOGIN.SUCCESS);
            return false;
          })
          .catch((error) => {
            if (this.applicationModel.resolvingUnauthorizedState) {
              // we might be resolving unauthorized, but still another transition could sneak in, so we need to hold it
              if (toState.name !== State.PRELIMINARY.LOGIN) {
                return false;
              }
              else {
                return true; // proceed with transition
              }
            }
            else if (toState.data?.availableToGuest) {
              return true; // pass if it should be visible for guest (non-logged in)
            }
            else {
              return this.stateService.target(State.PRELIMINARY.LOGIN);
            }
          });
      }
      else if (this.authModel.securityData?.requireNewPassword && (toState.name !== State.PRELIMINARY.PASSWORD_CHANGE_REQUIRED)) {
        return this.stateService.target(State.PRELIMINARY.PASSWORD_CHANGE_REQUIRED);
      }
      else if (toState.data?.allowedUserTypes) {
        return this.userModel.currentUserHasAnyOfType(toState.data.allowedUserTypes) ? true : this.stateService.target(State.PRELIMINARY.ERROR.ACCESS_DENIED);
      }
      else if (toState.data?.requiredPermissions) {
        const accessCheckArray: boolean[] = [];

        _.forEach(toState.data.requiredPermissions, (permission: PermissionName | PermissionName[]) => {
          // if 1st level is also array, treat permissions listed there as logical OR
          if (_.isArray(permission)) {
            let orResult: boolean = false;

            // @ts-ignore
            _.forEach(permission, (orPermission: PermissionName) => {
              if (this.userModel.currentUserHasPermission(orPermission)) {
                orResult = true;
                return false;
              }
            });

            accessCheckArray.push(orResult);
          }
          else {
            accessCheckArray.push(this.userModel.currentUserHasPermission(permission));
          }
        });

        if (_.every(accessCheckArray)) {
          return true;
        }
        else {
          return this.stateService.target(State.PRELIMINARY.ERROR.ACCESS_DENIED);
        }
      }
      else {
        return true;
      }
    });

    this.transitionService.onSuccess({}, (transition: Transition) => {
      const toState = transition.to();
      const toStateParams = transition.params('entering');
      const fromState = transition.from();
      const fromStateParams = transition.params('exiting');

      if (this.applicationModel.goingToPreviousState) {
        this.applicationModel.goingToPreviousState = false;
      }
      else {
        if (fromState.name && !_.includes(this.publicStates, fromState.name)) {
          this.applicationModel.stateHistory.push(new ApplicationState(fromState, fromStateParams));
        }
      }

      if (this.applicationModel.goingToTransientState) {
        this.applicationModel.goingToTransientState = false;
      }

      if (this.applicationModel.resolvingUnauthorizedState && (toState.name === State.PRELIMINARY.LOGIN)) {
        this.viewUtil.showToastError('ERROR.UNAUTHORIZED', 'COMMON.ERROR', false);
        this.applicationModel.resolvingUnauthorizedState = false;
      }

      this.applicationModel.currentState = new ApplicationState(toState, toStateParams);
      this.applicationModel.currentUrl = this.stateService.href(
        this.applicationModel.currentState.state,
        this.applicationModel.currentState.params,
        { absolute: true }
      );

      // run outside of zone, since it contains setTimeout, which would delay stability of ApplicationRef
      // (important for HMR among other things and no need for change detection here anyway)
      this.zone.runOutsideAngular(() => {
        HtmlUtil.scrollToTop(null, true);
      });

      const layout: LayoutType = toState.name.split('.')[0] as LayoutType;
      if (layout) {
        HtmlUtil.addLayoutClassToBody(layout);
      }

      if (this.angularApplicationRefStabilityTimeoutId) {
        setTimeout(() => {
          clearTimeout(this.angularApplicationRefStabilityTimeoutId);
          this.angularApplicationRefStabilityTimeoutId = null;
        });
      }

      return true;
    });
  }

  private configureListeners(): void {
    this.eventManager.on(Event.AUTH.LOGIN.SUCCESS, (token: Token) => {
      if (this.authModel.securityData?.requireNewPassword) {
        if (this.applicationModel.currentState?.state.name !== State.PRELIMINARY.PASSWORD_CHANGE_REQUIRED) {
          this.stateUtil.goToState(State.PRELIMINARY.PASSWORD_CHANGE_REQUIRED);
        }
      }
      else {
        this.userModel.getCurrentUser()
          .then((user: UserDTO) => {
            if (user) {
              const { userType } = user;
              userType === UserType.SENIOR && this.startNotificationTimer();
              // if password is expired, do nothing here, it will be covered by generic handler for Event.USER.GET_CURRENT.SUCCESS implemented below
              if (!this.authModel.securityData?.requireNewPassword) {
                if (this.applicationModel.goingToTransientState) {
                  this.stateUtil.goToState(State.MAIN.DASHBOARD);
                }
                else if (this.applicationModel.stateBeforeLogin) {
                  this.stateUtil.goToState(this.applicationModel.stateBeforeLogin.state.name, this.applicationModel.stateBeforeLogin.params);
                  this.applicationModel.stateBeforeLogin = null;
                }
                else if (user.firstLogin && user.userType === UserType.SENIOR) {
                  this.stateUtil.goToState(State.PRELIMINARY.PREFERENCES);
                }
                else {
                  this.stateUtil.goToState(State.MAIN.DASHBOARD);
                }
              }
            }
            else {
              // login successful, but the user returned is empty (even with 200)
              // a corner case that should not ever happen, but we cover it for safety
              this.authModel.logout()
                .then(() => {
                  this.viewUtil.showToastError('ERROR.UNAUTHORIZED', 'COMMON.ERROR', false);
                })
                .catch((error) => {
                  // logout call is likely to announce its own error (usually own ERROR.UNAUTHORIZED), so no need to show a toast here
                  // the interface will be restored by Event.AUTH.LOGOUT.ERROR handler below
                });
            }
          })
          .catch((error) => {
            // login successful, but user retrieval not
            // again, a corner case that should not ever happen, but we cover it for safety
            this.authModel.logout()
              .then(() => {
                this.viewUtil.showToastError('ERROR.UNAUTHORIZED', 'COMMON.ERROR', false);
              })
              .catch((logoutError) => {
                // logout call is likely to announce its own error (usually own ERROR.UNAUTHORIZED), so no need to show a toast here
                // the interface will be restored by Event.AUTH.LOGOUT.ERROR handler below
              });
          });
      }
    });

    this.eventManager.on(Event.AUTH.LOGOUT.SUCCESS, () => {
      this.stateUtil.goToState(State.PRELIMINARY.LOGIN);
    });

    this.eventManager.on(Event.AUTH.LOGOUT.ERROR, () => {
      // if we actually are resolving unauthorized state, it means we're going to PRELIMINARY.LOGIN anyway, no need to retrigger that
      if (!this.applicationModel.resolvingUnauthorizedState) {
        this.stateUtil.goToState(State.PRELIMINARY.LOGIN);
      }
    });

    this.eventManager.on(Event.USER.GET_CURRENT.SUCCESS, (user: UserDTO) => {
      if (this.authModel.securityData?.requireNewPassword) {
        if (this.applicationModel.currentState?.state.name !== State.PRELIMINARY.PASSWORD_CHANGE_REQUIRED) {
          this.stateUtil.goToState(State.PRELIMINARY.PASSWORD_CHANGE_REQUIRED);
        }
      }
    });

    this.eventManager.on(Event.USER.UPDATE_CURRENT.SUCCESS, (user: UserDTO) => {
      if (this.authModel.securityData?.requireNewPassword) {
        if (this.applicationModel.currentState?.state.name !== State.PRELIMINARY.PASSWORD_CHANGE_REQUIRED) {
          this.stateUtil.goToState(State.PRELIMINARY.PASSWORD_CHANGE_REQUIRED);
        }
      }
    });

    this.eventManager.on(Event.SYSTEM.LOADING, (isLoading: boolean) => {
      this.applicationModel.isLoading = isLoading;
    });

    this.eventManager.on(Event.AUTH.ERROR.FORBIDDEN, () => {
      this.viewUtil.showToastError('ERROR.FORBIDDEN', 'COMMON.ERROR', false);
    });

    this.eventManager.on(Event.AUTH.ERROR.UNAUTHORIZED, () => {
      if (!this.applicationModel.resolvingUnauthorizedState) {
        this.applicationModel.resolvingUnauthorizedState = true;

        if (this.applicationModel.currentState?.state.name !== State.PRELIMINARY.LOGIN) {
          // the rest will be handled on transition success
          this.stateUtil.goToState(State.PRELIMINARY.LOGIN);
        }
        else {
          this.viewUtil.showToastError('ERROR.UNAUTHORIZED', 'COMMON.ERROR', false);
          this.applicationModel.resolvingUnauthorizedState = false;
        }
      }
    });

    this.eventManager.on(Event.SYSTEM.GENERAL_ERROR, (error) => {
      if (this.applicationModel.devMode) {
        console.error('--- ERROR ---');

        if (_.isObject(error)) {
          console.error(JSON.stringify(error));
        }
        else if (typeof error === 'string') {
          console.error(error);
        }
        else {
          console.error(error.toString());
        }
      }

      let errorText: string;

      if (error instanceof HttpErrorResponse) {
        if (error.message) {
          errorText = error.message;
          this.viewUtil.showToastError(errorText, 'ERROR.SERVER', false);
        }
        else {
          this.viewUtil.translate([ 'ERROR.SERVER', 'COMMON.DETAILS' ])
            .then((translations: string | any) => {

              if (error.status && error.statusText && error.url) {
                errorText = translations['ERROR.SERVER'] + ' ' + translations['COMMON.DETAILS'] + ': ' + error.status + ' ' + error.statusText + ' - ' + error.url;
              }
              else if (error.url) {
                errorText = translations['ERROR.SERVER'] + ' URL: ' + error.url;
              }
              else {
                errorText = translations['ERROR.SERVER'];
              }

              this.viewUtil.showToastError(errorText, 'COMMON.ERROR', false);
            });
        }
      }
      else if (error instanceof LocalMessage) {
        errorText = `${ error.message } (${ error.details })`;
      }
      else {
        let errorString: string;
        let errorTranslateAttempt: boolean = false;

        if (_.isObject(error)) {
          errorString = JSON.stringify(error).replace(/({|:|,)/g, '$1 ').replace(/}/g, ' }'); // json with some whitespacing format
        }
        else if (typeof error === 'string') {
          errorString = error;
          errorTranslateAttempt = true;
        }
        else {
          errorString = error.toString();
        }

        this.viewUtil.translate(errorTranslateAttempt ? [ 'ERROR.SOMETHING_WENT_WRONG', 'COMMON.DETAILS', errorString ] : [ 'ERROR.SOMETHING_WENT_WRONG', 'COMMON.DETAILS' ])
          .then((translations: string | any) => {
            errorText = translations['ERROR.SOMETHING_WENT_WRONG'] + ' ' + translations['COMMON.DETAILS'] + ': ' + translations[errorString];

            this.viewUtil.showToastError(errorText, 'COMMON.ERROR', false);
          });
      }
    });

    this.eventManager.on(Event.SYSTEM.GENERAL_MESSAGE, (message) => {
      if (message instanceof LocalMessage) {
        let messageText: string;
        let messageTitle: string;

        if (message.message) {
          messageText = message.message;

          if (message.details) {
            messageText += '(' + message.details + ')';
          }
        }

        if (message.title) {
          messageTitle = message.title;
        }

        if (messageText) {
          if (message.type === LocalMessageType.INFO) {
            this.viewUtil.showToastInfo(messageText, messageTitle, false);

            if (this.applicationModel.devMode) {
              console.info(messageText);
            }
          }
          else if (message.type === LocalMessageType.WARNING) {
            this.viewUtil.showToastWarning(messageText, messageTitle, false);

            if (this.applicationModel.devMode) {
              console.warn(messageText);
            }
          }
          else if (message.type === LocalMessageType.ERROR) {
            this.viewUtil.showToastError(messageText, messageTitle, false);

            if (this.applicationModel.devMode) {
              console.error(messageText, messageTitle);
            }
          }
          else if (message.type === LocalMessageType.SUCCESS) {
            this.viewUtil.showToastSuccess(messageText, messageTitle, false);

            if (this.applicationModel.devMode) {
              console.info(messageText);
            }
          }
        }
      }
    });
  }

  private configureTranslations(): void {
    this.applicationModel.setLanguage(ApplicationConfig.defaultLanguage);
  }

  private configurePlatform(): void {
    this.applicationModel.setPlatform();
  }

  private configureInterface(): void {
    Chart.pluginService.register(ChartDataLabels);

    // run outside of zone, since it is setTimeout, which would delay stability of ApplicationRef
    // (important for HMR among other things and no need for change detection here anyway)
    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        document.documentElement.style.setProperty('--scrollbar-width', `${ HtmlUtil.getScrollbarWidth() }px`);
      }, 500);
    });
  }

  private introduce(): void {
    if (this.applicationModel.devMode) {
      console.info(dedent`
          ---------------------------------------------------------------------------
          Application "${ ApplicationConfig.applicationOwner } ${ ApplicationConfig.applicationName }" initialized.
          ---------------------------------------------------------------------------
           UI Version: ${ ApplicationConfig.version }
                  API: ${ ApplicationConfig.apiUrl }
          ---------------------------------------------------------------------------`);
    }
  }

  startNotificationTimer(): void {
    interval(this.notificationInterval).pipe(
      startWith(0),
      takeUntil(this.destroy$),
      switchMap(() => this.notificationService.getNotification()),
      tap((notifications) => {
        this.notificationService.notifications = notifications;
      })
    )
      .subscribe();
  }
}
