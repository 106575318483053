import { PageCriteriaDTO } from '../PageCriteriaDTO';
import { LiveClassCategory } from 'app/data/enum/liveClass/LiveClassCategory';
import { IntensityLevel } from 'app/data/enum/IntensityLevel';

export class LiveClassSeriesPageCriteriaDTO extends PageCriteriaDTO {
  public anyOfCategories?: LiveClassCategory[];

  public anyOfCoachIds?: number[];

  public anyOfIntensities?: IntensityLevel[];

  public anyOfLanguages?: string[];

  // set default values
  constructor() {
    super();
  }
}
