import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CoachDetailsPageDTO } from 'app/data/dto/coach/CoachDetailsPageDTO';

@Component({
  selector: 'app-coaches-selected-action-bar',
  templateUrl: './CoachesSelectedActionBarComponent.html',
  styleUrls: [ './CoachesSelectedActionBarComponent.scss' ]
})
export class CoachesSelectedActionBarComponent implements OnChanges {
  @Input() selectedItems: CoachDetailsPageDTO[] = [];

  @Output() deleteClicked: EventEmitter<void> = new EventEmitter<void>();

  text: string = 'User';

  constructor(private translate: TranslateService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedItems) {
      const translationKey: string = this.selectedItems.length === 1 ? 'COMMON.COACH' : 'COMMON.COACHES';

      this.text = this.translate.instant(translationKey);
    }
  }

  onDeleteClick(): void {
    this.deleteClicked.emit();
  }
}
