import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { StateService } from '@uirouter/core';
import { ValueFormatterParams } from 'ag-grid-community';
import { State } from 'app/common/State';
import { DataGridAction } from 'app/component/ui/dataGrid/DataGridAction';
import { BaseCriteriaSortOrder } from 'app/data/dto/BaseCriteriaDTO';
import { PageCriteriaDTO } from 'app/data/dto/PageCriteriaDTO';
import { PageDTO } from 'app/data/dto/PageDTO';
import { CoachDTO } from 'app/data/dto/coach/CoachDTO';
import { CoachDetailsPageDTO } from 'app/data/dto/coach/CoachDetailsPageDTO';
import { CoachesPageCriteriaDTO } from 'app/data/dto/coach/CoachesPageCriteriaDTO';
import { SortDirection } from 'app/data/enum/SortDirection';
import { CoachSortBy } from 'app/data/enum/coach/CoachSortBy';
import { UserStatus } from 'app/data/enum/user/Status';
import { OptionItem } from 'app/data/local/generic/OptionItem';
import { AdminModel } from 'app/model/AdminModel';
import { ApplicationModel } from 'app/model/ApplicationModel';
import { CoachModel } from 'app/model/CoachModel';
import { StateUtil } from 'app/util/StateUtil';
import { ViewUtil } from 'app/util/ViewUtil';
import { PrefixPipe } from 'app/util/pipe/PrefixPipe';
import * as _ from 'lodash';
import { Observable, of, Subject } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';

import { CoachesListActionCellRendererComponent } from './components/cellRenderer/menu/CoachesListActionCellRendererComponent';
import { DataGridStarRatingCellRendererComponent } from 'app/component/ui/dataGrid/cellRenderer/DataGridStarRatingCellRendererComponent';
import { ReplaceEmptyPipe } from 'app/util/pipe/ReplaceEmptyPipe';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import { TemplatePortal } from '@angular/cdk/portal';
import { PortalUtil } from 'app/util/PortalUtil';

@Component({
  selector: 'app-coaches-list',
  templateUrl: './CoachesListComponent.html',
  styleUrls: [ './CoachesListComponent.scss' ]
})
export class CoachesListComponent implements OnInit, OnDestroy {
  @ViewChild('headingTemplate', { static: true })
  private readonly headingTemplate: TemplateRef<any>;

  public destroy$: Subject<void> = new Subject<void>();
  public sortByOptions: OptionItem<string>[] = this.viewUtil.createEnumSelectOptions(CoachSortBy, 'USER_SORT_BY');
  public filterBy: OptionItem<string>[] = this.viewUtil.createEnumSelectOptions(UserStatus, 'STATUS');
  public sortValue: string;
  public filterValue: string;

  public criteria: CoachesPageCriteriaDTO = new CoachesPageCriteriaDTO();
  public page$: Observable<PageDTO<CoachDetailsPageDTO>>;
  public selectedItems: CoachDetailsPageDTO[] = [];

  public CoachesListCellRendererComponent = CoachesListActionCellRendererComponent;

  public StarRatingCellRendererComponent: typeof DataGridStarRatingCellRendererComponent = DataGridStarRatingCellRendererComponent;

  public enumCategoryFormatter: (params: ValueFormatterParams) => any = (params: ValueFormatterParams) => {
    if (_.isArray(params.value)) {
      return new ReplaceEmptyPipe().transform(params.value
        .map((value) =>
          this.viewUtil.dataGridPipeValueFormatterWithTranslate(
            { ...params, value: value },
            PrefixPipe,
            'ENUM.CATEGORY.'
          )
        )
        .join(', '));
    }
    else if (!_.isNil(params.value)) {
      return this.viewUtil.dataGridPipeValueFormatterWithTranslate(params, PrefixPipe, 'ENUM.CATEGORY.');
    }
    else {
      return '–';
    }
  };

  public enumStatusFormatter: (params: ValueFormatterParams) => any = (params: ValueFormatterParams) => {
    const req = params.value ? { ...params, value: 'ACTIVE' } : { ...params, value: 'INACTIVE' };
    return this.viewUtil.dataGridPipeValueFormatterWithTranslate(req, PrefixPipe, 'ENUM.STATUS.');
  };

  public startDateValueFormatter: (params: ValueFormatterParams) => any = (params: ValueFormatterParams) =>
    this.viewUtil.dataGridPipeValueFormatter(params, DatePipe, 'd | h:mm a');

  constructor(private viewUtil: ViewUtil,
              private applicationModel: ApplicationModel,
              private coachesModel: CoachModel,
              private adminModel: AdminModel,
              private stateUtil: StateUtil,
              private stateService: StateService,
              private portalUtil: PortalUtil,
              private viewContainerRef: ViewContainerRef) {
  }

  public ngOnInit(): void {
    this.applicationModel.selectSideBarItemWithState(State.MAIN.ADMIN.COACHES.LIST);

    this.portalUtil.attachPortalTo(
      MainLayoutComponent.PORTAL_OUTLET.HEADING,
      new TemplatePortal(this.headingTemplate, this.viewContainerRef)
    );

    this.getCoachesPage();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.portalUtil.detachPortalFrom(MainLayoutComponent.PORTAL_OUTLET.HEADING);
  }

  public checkboxSelectionFunction(TData: any, TValue: any): boolean {
    const { data } = TData;
    return data.id !== null && data.name !== null;
  }

  public onCriteriaChanged(changedCriteria: PageCriteriaDTO): void {
    const criteriaCopy: CoachesPageCriteriaDTO = _.cloneDeep(this.criteria);
    _.assignIn(criteriaCopy, changedCriteria);
    this.criteria = criteriaCopy;
    this.getCoachesPage();
  }

  public onSortOptionChange(sortBy: CoachSortBy): void {
    if (!sortBy) {
      delete this.criteria.sortOrders;
      this.getCoachesPage();
      return;
    }
    let sortOrders: BaseCriteriaSortOrder;
    let sortField: string;
    let sortDirection: SortDirection = SortDirection.ASCENDING;
    switch (sortBy) {
      case CoachSortBy.MOST_RECENT: {
        sortField = 'id';
        sortDirection = SortDirection.DESCENDING;
        break;
      }
      case CoachSortBy.ALPHABETIC: {
        sortField = 'user.lastName';
        break;
      }
    }
    sortOrders = `${ sortField } ${ sortDirection }`;
    this.criteria.sortOrders = [ sortOrders ];
    this.getCoachesPage();
  }

  public onFilterOptionChange(filterBy): void {
    if (!filterBy) {
      delete this.criteria.userEnabledEq;
      this.getCoachesPage();
      return;
    }
    this.criteria.userEnabledEq = filterBy === UserStatus.ACTIVE;
    this.getCoachesPage();
  }

  public onGridAction(action: DataGridAction): void {
    const coach = action.args[0] as CoachDTO;
    const { id } = coach;
    if (action.actionName === CoachesListActionCellRendererComponent.ACTION_DETAILS) {
      this.stateService.go(State.MAIN.ADMIN.COACHES.DETAILS, { id });
    }
    if (action.actionName === CoachesListActionCellRendererComponent.ACTION_EDIT) {
      this.stateService.go(State.MAIN.ADMIN.COACHES.EDIT, { id });
    }
    if (action.actionName === CoachesListActionCellRendererComponent.ACTION_DELETE) {
      this.coachesModel
        .deleteCoach([ id ])
        .pipe(
          takeUntil(this.destroy$),
          tap(() => {
            this.getCoachesPage();
            this.viewUtil.showToastSuccess('COMMON.SUCCESS');
          }),
          catchError((err) => {
            this.viewUtil.handleServerError(err);
            return of(null);
          })
        )
        .subscribe();
    }
  }

  public onDeleteClick(item?: CoachDetailsPageDTO): void {
    const ids = this.selectedItems.length > 0 ? this.selectedItems.map((item) => item.id) : [ item.id ];
    this.coachesModel
      .deleteCoach(ids)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.getCoachesPage();
          this.viewUtil.showToastSuccess('COMMON.SUCCESS');
          this.selectedItems = [];
        }),
        catchError((err) => {
          this.viewUtil.handleServerError(err);
          return of(null);
        })
      )
      .subscribe();
  }

  public onSelectionChange(selectedItems: CoachDetailsPageDTO[]) {
    this.selectedItems = selectedItems;
  }

  public onAddNewCoachClick(): void {
    this.stateUtil.goToState(State.MAIN.ADMIN.COACHES.CREATE);
  }

  private getCoachesPage(): void {
    this.criteria.pageSize = 8;
    this.adminModel
      .getCoachesPage(this.criteria)
      .pipe(
        takeUntil(this.destroy$),
        tap((page: PageDTO<CoachDetailsPageDTO>) => {
          this.page$ = of(page);
        })
      )
      .subscribe();
  }
}
