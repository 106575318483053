import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PageDTO } from 'app/data/dto/PageDTO';
import { CustomHttpParameterEncoder } from 'app/util/other/CustomHttpParameterEncoder';
import { ObjectUtil } from 'app/util/ObjectUtil';
import { ApplicationConfig } from 'app/config/ApplicationConfig';
import { map } from 'rxjs/operators';
import { PageCriteriaDTO } from 'app/data/dto/PageCriteriaDTO';
import { FriendDTO } from 'app/data/dto/friend/FriendDTO';

@Injectable({
  providedIn: 'root'
})
export class FriendPendingInvitationService {
  constructor(private readonly http: HttpClient) {
  }

  public getPage(criteria: PageCriteriaDTO): Observable<PageDTO<FriendDTO>> {
    const params: HttpParams = new HttpParams({
      encoder: new CustomHttpParameterEncoder(),
      fromObject: ObjectUtil.valueAsString(ObjectUtil.classToPlain(criteria, true, true, true, true))
    });

    return this.http.get(`${ ApplicationConfig.apiUrl }/friends/pending/invitations/page`, { params: params }).pipe(
      map((response: any) => ObjectUtil.plainToClassFromExisting(new PageDTO<FriendDTO>(FriendDTO), response))
    );
  }

  public accept(id: number): Observable<void> {
    return this.http.put<void>(`${ ApplicationConfig.apiUrl }/friends/pending/invitations/${ id }/accept`, undefined);
  }

  public reject(id: number): Observable<void> {
    return this.http.delete<void>(`${ ApplicationConfig.apiUrl }/friends/pending/invitations/${ id }/reject`);
  }
}
