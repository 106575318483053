import { Injectable } from '@angular/core';
import { PageDTO } from 'app/data/dto/PageDTO';
import { AssetCreateRequestDTO } from 'app/data/dto/asset/AssetCreateRequestDTO';
import { AssetUploadResponseDTO } from 'app/data/dto/asset/AssetUploadResponseDTO';
import { PasswordResendStatusResponseDTO } from 'app/data/dto/auth/PasswordResendStatusResponseDTO';
import { CoachDetailsPageDTO } from 'app/data/dto/coach/CoachDetailsPageDTO';
import { CoachesPageCriteriaDTO } from 'app/data/dto/coach/CoachesPageCriteriaDTO';
import { ImageCreateRequestDTO } from 'app/data/dto/images/ImageCreateRequestDTO';
import { ImageResponseDTO } from 'app/data/dto/images/ImageResponseDTO';
import { OrganizationListAdminDTO } from 'app/data/dto/organization/OrganizationListAdminDTO';
import { ProgramAdminDetailsPageDTO } from 'app/data/dto/programs/ProgramAdminDetailsPageDTO';
import { ProgramAdminPageCriteriaDTO } from 'app/data/dto/programs/ProgramAdminPageCriteriaDTO';
import { ProgramCreateDTO } from 'app/data/dto/programs/ProgramCreateDTO';
import { ProgramIdListDTO } from 'app/data/dto/programs/ProgramIdListDTO';
import { ProgramResponseDTO } from 'app/data/dto/programs/ProgramResponseDTO';
import { ProgramUpdateDTO } from 'app/data/dto/programs/ProgramUpdateDTO';
import { ScheduledLiveClassPageCriteriaDTO } from 'app/data/dto/scheduledLiveClass/ScheduledLiveClassPageCriteriaDTO';
import { ScheduledLiveClassCreateRequestDTO } from 'app/data/dto/scheduledLiveClass/admin/ScheduledLiveClassCreateRequestDTO';
import { ScheduledLiveClassDetailsPageDTO } from 'app/data/dto/scheduledLiveClass/admin/ScheduledLiveClassDetailsPageDTO';
import { ScheduledLiveClassDetailsResponseDTO } from 'app/data/dto/scheduledLiveClass/admin/ScheduledLiveClassDetailsResponseDTO';
import { SeniorUpdateRequestDTO } from 'app/data/dto/senior/SeniorUpdateRequestDTO';
import { SeniorDetailsPageDTO } from 'app/data/dto/senior/SeniorDetailsPageDTO';
import { SeniorPageCriteriaDTO } from 'app/data/dto/senior/SeniorPageCriteriaDTO';
import { SeniorPreferencesResponseAdminDTO } from 'app/data/dto/senior/SeniorPreferencesResponseAdminDTO';
import { SeniorProfileResponseAdminDTO } from 'app/data/dto/senior/SeniorProfileResponseAdminDTO';
import { AdminService } from 'app/service/AdminService';
import { AssetService } from 'app/service/AssetService';
import { ImageService } from 'app/service/ImagesService';
import { OrganizationService } from 'app/service/OrganizationService';
import { ProgramService } from 'app/service/ProgramService';
import { ScheduledLiveClassService } from 'app/service/ScheduledLiveClassService';
import { SeniorService } from 'app/service/SeniorService';
import { concat, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LiveClassSeriesDetailsPageDTO } from 'app/data/dto/liveClassSeries/admin/LiveClassSeriesDetailsPageDTO';
import { LiveClassSeriesPageCriteriaDTO } from 'app/data/dto/liveClassSeries/LiveClassSeriesPageCriteriaDTO';
import { LiveClassSeriesService } from 'app/service/LiveClassSeriesService';
import { LiveClassSeriesCreateRequestDTO } from 'app/data/dto/liveClassSeries/LiveClassSeriesCreateRequestDTO';
import { LiveClassSeriesDetailsResponseDTO } from 'app/data/dto/liveClassSeries/admin/LiveClassSeriesDetailsResponseDTO';
import { ScheduledLiveClassRatingAdminPageCriteriaDTO } from 'app/data/dto/scheduledLiveClass/admin/ScheduledLiveClassRatingAdminPageCriteriaDTO';
import { ScheduledLiveClassRatingAdminPageDTO } from 'app/data/dto/scheduledLiveClass/admin/ScheduledLiveClassRatingAdminPageDTO';

@Injectable({ providedIn: 'root' })
export class AdminModel {
  constructor(private readonly adminService: AdminService,
              private readonly seniorService: SeniorService,
              private readonly liveClassSeriesService: LiveClassSeriesService,
              private readonly organizationService: OrganizationService,
              private readonly assetService: AssetService,
              private readonly imagesService: ImageService,
              private readonly programService: ProgramService,
              private readonly scheduledLiveClassService: ScheduledLiveClassService) {
  }

  public getScheduledLiveClassesPage(
    criteria: ScheduledLiveClassPageCriteriaDTO
  ): Observable<PageDTO<ScheduledLiveClassDetailsPageDTO>> {
    return this.scheduledLiveClassService.getScheduledLiveClassesPageAdmin(criteria);
  }

  public getLiveClassSeriesPage(
    criteria: LiveClassSeriesPageCriteriaDTO
  ): Observable<PageDTO<LiveClassSeriesDetailsPageDTO>> {
    return this.liveClassSeriesService.getLiveClassSeriesPageAdmin(criteria);
  }

  public createScheduledLiveClass(
    scheduledLiveClass: ScheduledLiveClassCreateRequestDTO
  ): Observable<ScheduledLiveClassDetailsResponseDTO> {
    return this.scheduledLiveClassService.createScheduledLiveClassAdmin(scheduledLiveClass).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  public createLiveClassSeries(
    liveClassSeries: LiveClassSeriesCreateRequestDTO
  ): Observable<LiveClassSeriesDetailsResponseDTO> {
    return this.liveClassSeriesService.createLiveClassSeries(liveClassSeries).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  public getScheduledLiveClassDetails(id: number): Observable<ScheduledLiveClassDetailsResponseDTO> {
    return this.scheduledLiveClassService.getScheduledLiveClassDetailsAdmin(id);
  }

  public getLiveClassSeriesDetails(id: number): Observable<LiveClassSeriesDetailsResponseDTO> {
    return this.liveClassSeriesService.getLiveClassSeriesDetailsAdmin(id);
  }

  public deleteScheduledLiveClass(ids: number[]): Observable<void> {
    return this.scheduledLiveClassService.deleteScheduledLiveClass(ids);
  }

  public getScheduledLiveClassRatingPage(criteria: ScheduledLiveClassRatingAdminPageCriteriaDTO): Observable<PageDTO<ScheduledLiveClassRatingAdminPageDTO>> {
    return this.scheduledLiveClassService.getScheduledLiveClassRatingPage(criteria);
  }

  public deleteLiveClassSeries(ids: number[]): Observable<void> {
    return this.liveClassSeriesService.deleteLiveClassSeries(ids);
  }

  public getAllOrganizationList(): Observable<OrganizationListAdminDTO[]> {
    return this.organizationService.getAllOrganizationList();
  }

  public createSenior(user: SeniorUpdateRequestDTO): Observable<SeniorProfileResponseAdminDTO> {
    return this.seniorService.createSenior(user);
  }

  public getSeniorsPage(criteria: SeniorPageCriteriaDTO): Observable<PageDTO<SeniorDetailsPageDTO>> {
    return this.adminService.getSeniorsPage(criteria);
  }

  public getSeniorDetails(id: number): Observable<SeniorProfileResponseAdminDTO> {
    return this.adminService.getSeniorDetails(id);
  }

  public updateSenior(id: number, senior: SeniorUpdateRequestDTO): Observable<SeniorProfileResponseAdminDTO> {
    return this.adminService.updateSenior(id, senior);
  }

  public activateSenior(id: number): Observable<void> {
    return this.adminService.activateSenior(id);
  }

  public activateSeniors(ids: number[]): Observable<void> {
    const batch: Observable<void>[] = ids.map((id) => this.activateSenior(id));
    return concat(...batch);
  }

  public deactivateSeniors(ids: number[]): Observable<void> {
    return this.adminService.deactivateSeniors(ids);
  }

  public getCoachesPage(criteria: CoachesPageCriteriaDTO): Observable<PageDTO<CoachDetailsPageDTO>> {
    return this.adminService.getCoachesPage(criteria);
  }

  public createAsset(asset: AssetCreateRequestDTO): Observable<AssetUploadResponseDTO> {
    return this.assetService.createAsset(asset);
  }

  public uploadThumbnailAsset(file: File, assetResponse: AssetUploadResponseDTO): Observable<any> {
    return this.assetService.uploadThumbnailAsset(file, assetResponse);
  }

  public createImages(imageCreateRequestDTO: ImageCreateRequestDTO): Observable<ImageResponseDTO> {
    return this.imagesService.createImages(imageCreateRequestDTO);
  }

  public resetPasswordForSeniorCreatedByAdmin(id: number): Observable<PasswordResendStatusResponseDTO> {
    return this.adminService.resetPasswordForSeniorCreatedByAdmin(id);
  }

  public getSeniorPreferencesById(id: number): Observable<SeniorPreferencesResponseAdminDTO> {
    return this.seniorService.getSeniorPreferencesById(id);
  }

  public createProgram(program: ProgramCreateDTO): Observable<ProgramResponseDTO> {
    return this.programService.createProgram(program);
  }

  public getProgramById(id: number): Observable<ProgramResponseDTO> {
    return this.programService.getProgramById(id);
  }

  public updateProgram(id: number, program: ProgramUpdateDTO): Observable<ProgramResponseDTO> {
    return this.programService.updateProgram(id, program);
  }

  public deleteProgram(ids: ProgramIdListDTO): Observable<void> {
    return this.programService.deletePrograms(ids);
  }

  public getProgramPageAdmin(criteria: ProgramAdminPageCriteriaDTO): Observable<PageDTO<ProgramAdminDetailsPageDTO>> {
    return this.programService.getProgramPageAdmin(criteria);
  }
}
