<div title="Rating: {{ rating }}/{{maxRating}}">
  <span *ngFor="let star of stars; let i = index">
    <ng-container *ngIf="star === 'star-filled'; else emptyStar">
      <img class="m-0 mb-2 mr-1 p-0" src="asset/image/icon/star_yellow.svg"/>
    </ng-container>
    <ng-template #emptyStar>
      <img class="m-0 mb-2 mr-1 p-0" src="asset/image/icon/star_black.svg"/>
    </ng-template>
  </span>
</div>
