import { RegistrationType } from 'app/data/enum/user/RegistrationType';

export class UserSeniorUpdateRequestDTO {
  public email?: string;

  public enabled: boolean;

  public firstName: string;

  public lastName: string;

  public nickname?: string;

  public phone?: string;

  public imageId?: number;

  public registrationType: RegistrationType;

  constructor(data?: Partial<UserSeniorUpdateRequestDTO>) {
    Object.assign(this, data);
  }
}
