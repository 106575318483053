<div class="modal-header">
  <h4 class="modal-title">{{ title | translate:messageVariables }}</h4>
  <div class="modal-control">
    <button type="button" class="btn" (click)="close()">
      <svg-icon src="asset/image/icon/x.svg"></svg-icon>
    </button>
  </div>
</div>
<div class="modal-body">
  <p class="lead mb-0 word-break">{{ message | translate: messageVariables }}</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary w-25" (click)="cancel()">{{ cancelText | translate }}</button>
  <button type="button" class="btn btn-primary w-25" (click)="ok()">{{ okText | translate }}</button>
</div>
