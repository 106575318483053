import { RegistrationType } from 'app/data/enum/user/RegistrationType';

export class UserRegistrationDTO {

  public firstName: string;

  public lastName: string;

  public email: string;

  public phone: string;

  public registrationType: RegistrationType;

  public password: string;

  constructor(data?: Partial<UserRegistrationDTO>) {
    Object.assign(this, data);
  }

}

