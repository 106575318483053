import { Type } from 'class-transformer';
import { IdentifiableObjectDTO } from '../IdentifiableObjectDTO';
import { UserProfileDTO } from '../user/UserProfileDTO';
import { CoachCertificateDTO } from './CertificateDTO';
import { CoachHobbyDTO } from './CoachHobbyDTO';
import { CoachEducationDTO } from './EducationDTO';

export class CoachProfileResponseDTO extends IdentifiableObjectDTO<number> {
  bio: string;

  @Type(() => CoachCertificateDTO)
  coachCertifications: CoachCertificateDTO[];

  @Type(() => CoachEducationDTO)
  coachEducations: CoachEducationDTO[];

  @Type(() => CoachHobbyDTO)
  coachHobbies: CoachHobbyDTO[];

  location: string;

  @Type(() => UserProfileDTO)
  user: UserProfileDTO;
}
