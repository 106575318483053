import { Transform } from 'class-transformer';
import { DateUtil } from 'app/util/DateUtil';

export class RetailSubscriptionDTO {
  public name: string;

  @Transform(DateUtil.dateTimeConversion)
  public endPeriod: Date;

  public currency: string;

  public price: number;

  public canceled: boolean;
}