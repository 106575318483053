import { UserActivityFrequency } from 'app/data/enum/account/UserActivityFrequency';
import { UserGender } from 'app/data/enum/account/UserGender';
import { UserHealth } from 'app/data/enum/account/UserHealth';
import { UserInterest } from 'app/data/enum/account/UserInterest';
import { DateUtil } from 'app/util/DateUtil';
import { Transform, Type } from 'class-transformer';
import * as _ from 'lodash';
import { IdentifiableObjectDTO } from '../IdentifiableObjectDTO';
import { UserAddressDTO } from '../user/UserAddressDTO';
import { UserDTO } from '../user/UserDTO';
import { PermissionName } from 'app/data/enum/permission/PermissionName';


export class SeniorDTO extends IdentifiableObjectDTO<number> {

  @Type(() => UserAddressDTO)
  public address: UserAddressDTO;

  @Transform(DateUtil.dateConversion)
  public dateOfBirth: Date;

  public gender: UserGender;

  @Type(() => UserDTO)
  public user: UserDTO;

  public seniorActivityFrequency: UserActivityFrequency;

  public seniorHealth: UserHealth;

  public seniorInterests: UserInterest[];

  public hasPermission(permission: PermissionName): boolean {
    return _.includes(this.user.permissions, permission);
  }

  public hasAllPermissions(permissions: PermissionName[]): boolean {
    // could also be implemented alternatively (and more efficiently) as
    // return (_.difference(permissions, this.currentUser?.permissions).length === 0)
    // but this below is just easier to understand when looking at

    let results: boolean[] = [];

    _.forEach(permissions, (permission: PermissionName) => {
      results.push(this.hasPermission(permission));
    });

    return _.every(results, (result: boolean) => (result === true));
  }

  public hasAtLeastOnePermission(permissions: PermissionName[]): boolean {
    // could also be implemented alternatively (and more efficiently) as
    // return (_.intersection(permissions, this.currentUser?.permissions).length > 0)
    // but this below is just easier to understand when looking at
    let results: boolean[] = [];

    _.forEach(permissions, (permission: PermissionName) => {
      results.push(this.hasPermission(permission));
    });

    return _.includes(results, true);
  }

}