import { SubscriptionType } from 'app/data/enum/subscription/SubscriptionType';
import { RetailSubscriptionDTO } from 'app/data/enum/subscription/RetailSubscriptionDTO';
import { OrganizationEssentialsDTO } from 'app/data/dto/organization/OrganizationEssentialsDTO';
import { Exclude, Expose, Transform } from 'class-transformer';

export class UserSubscriptionDTO {
  public subscriptionType: SubscriptionType;

  public memberCode?: string;

  @Transform(({ value }: { value: OrganizationEssentialsDTO }) => value?.id, { toPlainOnly: true })
  @Expose({ name: 'organizationId', toPlainOnly: true })
  public organization?: OrganizationEssentialsDTO;

  @Exclude({ toPlainOnly: true })
  public retailSubscriptionDetails?: RetailSubscriptionDTO;
}