<ng-template #headingTemplate>
  <h1>{{ 'VIEW.MAIN.ON_DEMAND.WATCH_PARTY.CREATE.TITLE' | translate }}</h1>

  <div class="d-flex gap-4">
    <button type="button" class="btn btn-outline-primary"
            [uiSref]="State.MAIN.SENIOR.ON_DEMAND.WATCH_PARTY.LIST">
      <svg-icon src="asset/image/icon/chevron-left.svg"></svg-icon>
      <span>{{ 'COMMON.BACK' | translate }}</span>
    </button>

    <button type="button" class="btn btn-primary"
            [uiSref]="State.MAIN.SENIOR.ON_DEMAND.WATCH_PARTY.EDIT"
            [uiParams]="{id: this.id}">
      <svg-icon src="asset/image/icon/edit.svg"></svg-icon>
      <span>{{ 'COMMON.EDIT' | translate }}</span>
    </button>
  </div>
</ng-template>

<section class="section no-overflow-y" *ngIf="watchParty$ | async as watchParty">
  <div class="row p-0 m-0">
    <div class="col-12 col-lg-2 p-0 mb-4 mb-lg-0">
      <div class="d-flex flex-column video-thumbnail-container">
        <img class="d-block img-fluid mx-0 mb-4 radius-24" [src]="watchParty.video.thumbnail.defaultAsset.url" alt=""/>

        <div class="d-flex justify-content-center gap-2">
          <span>{{ "VIEW.MAIN.LIVE_CLASSES.INTENSITY" | translate }}</span>
          <app-intensity-level [intensity]="watchParty.video.intensity"></app-intensity-level>
        </div>
      </div>
    </div>

    <div class="col-12 col-lg-10 p-0 pl-lg-6">
      <div class="row">
        <div class="col-12">
          <span class="details-label">{{ 'VIEW.MAIN.ON_DEMAND.NAME' | translate }}</span>
          <h2>{{ watchParty.video.title | replaceEmpty }}</h2>
          <hr>
        </div>
      </div>

      <div class="row">
        <div class="d-flex flex-column col-sm-6 col-md-3 mb-4">
          <span class="details-label">{{ 'DOMAIN.WATCH_PARTY.DATA.START_DATE.LABEL' | translate }}</span>
          <span>{{ watchParty.startDate | date:'shortDate' | replaceEmpty }}</span>
        </div>

        <div class="d-flex flex-column col-sm-6 col-md-3 mb-4">
          <span class="details-label">{{ 'DOMAIN.WATCH_PARTY.DATA.START_TIME.LABEL' | translate }}:</span>
          <span>{{ watchParty.startDate | date:'shortTime' | replaceEmpty }}</span>
        </div>

        <div class="d-flex flex-column col-sm-6 col-md-3 mb-4">
          <span class="details-label">{{ 'VIEW.MAIN.ON_DEMAND.LENGTH' | translate }}:</span>
          <span>{{ watchParty.video.duration | replaceEmpty }}</span>
        </div>

        <div class="d-flex flex-column col-sm-6 col-md-3 mb-4">
          <span class="details-label">{{ 'DOMAIN.WATCH_PARTY.DATA.HOST.LABEL' | translate }}:</span>
          <span>{{ watchParty.host?.user?.fullName | replaceEmpty }}</span>
        </div>
      </div>
      <div class="row">
        <div class="d-flex flex-column col-sm-6 col-md-3 mb-4">
          <span class="details-label">{{ 'VIEW.MAIN.ON_DEMAND.CATEGORY' | translate }}</span>
          <span>{{ watchParty.video.category | prefix: 'ENUM.VIDEO_CATEGORY.' | translate | replaceEmpty }}</span>
        </div>

        <div class="d-flex flex-column col-sm-6 col-md-3 mb-4">
          <span class="details-label">{{ 'VIEW.MAIN.ON_DEMAND.LANGUAGE' | translate }}</span>
          <span>{{ watchParty.video.language | prefix: 'ENUM.LANGUAGE.' | translate | replaceEmpty }}</span>
        </div>

        <div class="d-flex flex-column col-sm-6 col-md-3 mb-4">
          <span class="details-label">{{ 'DOMAIN.WATCH_PARTY.DATA.TYPE.LABEL' | translate }}</span>
          <span>{{ watchParty.type | prefix: 'ENUM.WATCH_PARTY_TYPE.' | translate | replaceEmpty }}</span>
        </div>

        <div class="d-flex flex-column col-sm-6 col-md-3 mb-4">
          <span class="details-label">{{ 'DOMAIN.WATCH_PARTY.DATA.CAPACITY.LABEL' | translate }}</span>
          <span>
            {{ watchParty.participants?.length | replaceEmpty:'0' }}/{{ watchParty.capacity | replaceEmpty:'0' }}
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <hr>
          <div class="d-flex flex-column">
            <span class="details-label">{{ 'VIEW.MAIN.ON_DEMAND.DESCRIPTION' | translate }}</span>
            <span>{{ watchParty.video.description | replaceEmpty }}</span>
          </div>
          <hr>
        </div>
      </div>

      <!-- Participants -->
      <div class="row">
        <div class="col-12">
          <span class="details-label">{{ 'DOMAIN.WATCH_PARTY.DATA.PARTICIPANTS.LABEL' | translate }}</span>
          <div class="d-flex flex-wrap gap-3 participants">
            <div class="d-flex gap-2 participant" *ngFor="let participant of watchParty.participants">
              <app-avatar class="avatar" size="24px"
                          [src]="participant.avatar?.thumbnailAsset?.url" [circle]="true"></app-avatar>
              <span>{{ participant | fullName }}</span>
            </div>
          </div>

          <div class="col-12 gap-3 justify-content-end">
            <button type="button"
                    class="btn btn-primary"
                    *appCanPerformAction="watchPartyModel.logic.isJoinable;data:watchParty"
                    (click)="watchPartyModel.join(watchParty.id)">
              <svg-icon class="d-flex" src="asset/image/icon/circle-chevron-right.svg"></svg-icon>
              <span>{{ 'DOMAIN.WATCH_PARTY.ACTION.JOIN' | translate }}</span>
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>
