<div class="form-row">
  <div class="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9">
    <div class="row justify-content-start align-items-start">
      <div class="form-group col-12 col-lg-auto col-xl-auto pr-0">
        <ng-select
          id="sort-by"
          name="sort-by"
          [placeholder]="'COMMON.SORT_BY' | translate"
          [searchable]="false"
          [style.--icon]="'url(/asset/image/icon/arrows-sort.svg)'"
          (change)="onSortOptionChange($event)"
          [(ngModel)]="sortValue"
        >
          <ng-option *ngFor="let sortType of sortByOptions" [value]="sortType.value">
            {{ sortType.label | translate }}
          </ng-option>
        </ng-select>
      </div>
      <div class="form-group col-12 col-lg-auto col-xl-auto">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
  <div class="form-group col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
    <div id="searchbox" class="form-group mb-0">
      <input
        class="form-control"
        type="text"
        name="search"
        id="search"
        [ngClass]="{ 'with-content': internalSearchPhrase }"
        placeholder="{{ 'COMPONENT.DATA_GRID.SEARCH' | translate }}"
        #search="ngModel"
        [(ngModel)]="internalSearchPhrase"
        (keyup.enter)="onSearchCommitted()"
      />
      <button
        *ngIf="internalSearchPhrase"
        class="search-clear-button btn btn-sm btn-outline-primary"
        type="button"
        (click)="clearSearch()"
      >
        <div class="d-flex flex-row align-items-center justify-content-center">
          <svg-icon class="d-flex" src="asset/image/icon/x.svg"></svg-icon>
        </div>
      </button>
      <svg-icon class="search-icon" src="asset/image/icon/search.svg"></svg-icon>
    </div>
  </div>
</div>