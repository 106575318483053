<div class="modal-header border-bottom-0 align-items-center">
  <h4 class="modal-title">{{ title | translate }}</h4>
  <div class="modal-control">
    <button type="button" class="btn" (click)="close()">
      <svg-icon src="asset/image/icon/x.svg"></svg-icon>
    </button>
  </div>
</div>

<div class="d-flex flex-column modal-footer border-top-0">
  <div #stripePlaceholder></div>
</div>
