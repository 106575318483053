<ng-template #headingTemplate>
  <h1>{{ 'COMPONENT.ORGANIZATIONS.ORGANIZATIONS' | translate }}</h1>

  <button class="btn btn-primary w-100 w-sm-auto" (click)="onAddClick()">
    <svg-icon src="asset/image/icon/plus.svg"></svg-icon>
    <span>{{ 'COMPONENT.ORGANIZATIONS.ADD_NEW_ORGANIZATION' | translate }}</span>
  </button>
</ng-template>

<section class="section">
  <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4 my-12">
    <div class="col-12 col-sm-6 col-md-6 p-0">
      <ng-select
        id="sort-by"
        name="sort-by"
        [placeholder]="'COMMON.SORT_BY' | translate"
        [searchable]="false"
        class="mr-0 mb-sm-0 mb-4"
        [style.--icon]="'url(/asset/image/icon/arrows-sort.svg)'"
        (change)="onSortOptionChange($event)"
        [(ngModel)]="sortValue"
      >
        <ng-option *ngFor="let sortType of sortByOptions" [value]="sortType.value">
          {{ sortType.label | translate }}
        </ng-option>
      </ng-select>
    </div>
  </div>

  <div *ngIf="organizationPage" class="flex-column gap-4 px-1 px-sm-4 word-break">
    <div id="desktop-view">
      <ng-container *ngFor="let organization of organizationPage.content">
        <div class="p-2 d-flex justify-content-between organization-card align-items-center">
          <div class="row no-glutters w-100 form-row">
            <!-- Name and User Count Column -->
            <div class="d-flex d-flex align-items-center">
              <app-avatar [src]="organization?.logo?.thumbnailAsset?.url"
                          placeholder="asset/image/icon/building-skyscraper.svg"
                          [circle]="true"
                          size="54px">
              </app-avatar>
            </div>
            <div class="col d-flex d-flex align-items-center min-width-200px">
              <div class="d-flex flex-column">
                <div class="text-lg-semibold">{{ organization.name }}</div>
                <div *ngIf="organization.usersCount === 1; else multipleUsers">{{ organization.usersCount }}user
                </div>
                <ng-template #multipleUsers>
                  <div>{{ organization.usersCount }} users</div>
                </ng-template>
              </div>
            </div>
            <!-- Contact Name and Phone Column -->
            <div class="col d-flex gap-2 align-items-center min-width-200px">
              <ng-container *ngIf="organization.contactName">
                <svg-icon src="asset/image/icon/user-circle.svg"></svg-icon>
                <div class="d-flex flex-column">
                  <div>{{ organization?.contactName }}</div>
                  <div>{{ organization?.phone }}</div>
                </div>
              </ng-container>
            </div>
            <!-- Address Column -->
            <div class="col d-flex gap-2 align-items-center min-width-200px">
              <ng-container *ngIf="organization.address | validProperties">
                <svg-icon src="asset/image/icon/building-community.svg"></svg-icon>
                <div class="d-flex flex-column">
                  <div>
                    <div class="m-0 p-0">{{ organization?.address?.street }}</div>
                    <div>
                      {{ organization?.address?.city }} {{ organization?.address?.state }}
                      {{ organization?.address?.zipCode }}
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
            <!-- View Button Column -->
            <div class="col d-flex justify-content-end align-items-center max-width-220px">
              <button
                type="button"
                class="d-flex align-items-center color-green-dark mr-4"
                (click)="onViewClick(organization.id)"
              >
                <svg-icon src="asset/image/icon/eye.svg" class="mr-2"></svg-icon>
                <div>{{ 'COMMON.VIEW' | translate }}</div>
              </button>
              <div class="btn-group" dropdown container="body" placement="left bottom">
                <button
                  id="button-dropdown"
                  dropdownToggle
                  type="button"
                  class="dropdown-toggle p-0 no-caret"
                  aria-controls="dropdown-basic"
                >
                  <div class="d-flex flex-row align-items-center">
                    <svg-icon class="d-flex" src="asset/image/icon/dots-vertical.svg"></svg-icon>
                  </div>
                </button>
                <div *dropdownMenu class="dropdown-menu p-3" role="menu" aria-labelledby="button-dropdown">
                  <a role="menuitem" class="dropdown-item p-0 m-0" (click)="onEditClick(organization.id)">
                    {{ "COMMON.EDIT" | translate }}
                  </a>
                  <!-- <a role="menuitem" class="dropdown-item p-0 m-0 mb-4" (click)="onEditClick(organization.id)">
                    {{"COMMON.EDIT" | translate}}
                  </a> -->
                  <!-- <a role="menuitem" class="dropdown-item p-0 m-0" (click)="onDeleteClick()">
                    {{"COMMON.DELETE" | translate}}
                  </a> -->
                </div>
              </div>
            </div>
            <!-- Download Report Button Column -->
            <div class="col d-flex justify-content-end align-items-center p-0 max-width-220px">
              <button class="btn btn-primary download-btn">
                {{ "COMPONENT.ORGANIZATIONS.DOWNLOAD_FULL_REPORT" | translate }}
              </button>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div id="mobile-view" *ngIf="organizationPage" class="flex-column gap-4 px-1">
      <ng-container *ngFor="let organization of organizationPage.content">
        <div class="p-2 organization-card">
          <!-- First Row -->
          <div class="row no-gutters w-100 form-row">
            <!-- Address -->
            <div class="col-12 col-sm-6 d-flex gap-2 align-items-center mb-2 mb-sm-0">
              <ng-container *ngIf="organization.address | validProperties">
                <svg-icon src="asset/image/icon/building-community.svg"></svg-icon>
                <div class="d-flex flex-column">
                  <div class="m-0 p-0">{{ organization?.address?.street }}</div>
                  <div>
                    {{ organization?.address?.city }} {{ organization?.address?.state }}
                    {{ organization?.address?.zipCode }}
                  </div>
                </div>
              </ng-container>
            </div>
            <!-- Contact Name and Phone Column -->
            <div
              class="col-12 col-sm-6 gap-2 d-flex justify-content-start justify-content-sm-end align-items-center mb-2 mb-sm-0"
            >
              <ng-container *ngIf="organization.contactName">
                <svg-icon src="asset/image/icon/user-circle.svg"></svg-icon>
                <div class="d-flex flex-column">
                  <div>{{ organization?.contactName }}</div>
                  <div>{{ organization?.phone }}</div>
                </div>
              </ng-container>
            </div>
          </div>
          <!-- Second Row -->
          <div class="row no-gutters w-100 form-row mt-2">
            <!-- Name and User Count -->
            <div class="col-12 col-sm-6 d-flex align-items-center gap-2 mb-2 mb-sm-0">
              <app-avatar src="asset/image/icon/building-skyscraper.svg" [circle]="true" size="54px"></app-avatar>
              <div class="d-flex flex-column">
                <div class="text-lg-semibold">{{ organization.name }}</div>
                <div *ngIf="organization.usersCount === 1; else multipleUsers">{{ organization.usersCount }}user
                </div>
                <ng-template #multipleUsers>
                  <div>{{ organization.usersCount }} users</div>
                </ng-template>
              </div>
            </div>
            <!-- View, 3 Dots Menu, Download Report -->
            <div class="col-12 col-sm-6 d-flex flex-column align-items-sm-end justify-content-sm-center mb-2 mb-sm-0">
              <div class="d-flex justify-content-between mb-2">
                <button type="button" class="btn btn-sm btn-light mr-4" (click)="onViewClick(organization.id)">
                  <svg-icon src="asset/image/icon/eye.svg" class="mr-2"></svg-icon>
                  {{ 'COMMON.VIEW' | translate }}
                </button>
                <div class="btn-group" dropdown container="body" placement="left bottom">
                  <button
                    id="button-dropdown"
                    dropdownToggle
                    type="button"
                    class="btn btn-sm btn-light dropdown-toggle p-0"
                    aria-controls="dropdown-basic"
                  >
                    <div class="d-flex flex-row align-items-center">
                      <svg-icon class="d-flex" src="asset/image/icon/dots-vertical.svg"></svg-icon>
                    </div>
                  </button>
                  <div *dropdownMenu class="dropdown-menu p-3" role="menu" aria-labelledby="button-dropdown">
                    <a role="menuitem" class="dropdown-item p-0 m-0" (click)="onEditClick(organization.id)">
                      {{ "COMMON.EDIT" | translate }}
                    </a>
                    <!-- <a role="menuitem" class="dropdown-item p-0 m-0 mb-4" (click)="onEditClick(organization.id)">
                      {{"COMMON.EDIT" | translate}}
                    </a> -->
                    <!-- <a role="menuitem" class="dropdown-item p-0 m-0" (click)="onDeleteClick()">
                      {{"COMMON.DELETE" | translate}}
                    </a> -->
                  </div>
                </div>
              </div>

              <button class="btn btn-primary download-btn">
                {{ 'COMPONENT.ORGANIZATIONS.DOWNLOAD_FULL_REPORT' | translate }}
              </button>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div>
      <nav class="d-flex justify-content-center align-items-center mt-4" *ngIf="organizationPage?.totalElements">
        <pagination
          class="justify-content-center mb-0"
          [totalItems]="organizationPage.totalElements"
          [itemsPerPage]="criteria.pageSize"
          [maxSize]="7"
          [rotate]="true"
          [boundaryLinks]="false"
          [customFirstTemplate]="firstTemplate"
          [customLastTemplate]="lastTemplate"
          [customPreviousTemplate]="prevTemplate"
          [customNextTemplate]="nextTemplate"
          [(ngModel)]="internalPage"
          (pageChanged)="onPageChanged($event)"
        >
        </pagination>
      </nav>
    </div>
  </div>
</section>

<ng-template #firstTemplate let-disabled="disabled" let-currentPage="currentPage">
  <svg-icon src="asset/image/icon/chevrons-left.svg"></svg-icon>
</ng-template>

<ng-template #lastTemplate let-disabled="disabled" let-currentPage="currentPage">
  <svg-icon src="asset/image/icon/chevrons-right.svg"></svg-icon>
</ng-template>

<ng-template #prevTemplate let-disabled="disabled" let-currentPage="currentPage">
  <svg-icon src="asset/image/icon/chevron-left.svg"></svg-icon>
</ng-template>

<ng-template #nextTemplate let-disabled="disabled" let-currentPage="currentPage">
  <svg-icon src="asset/image/icon/chevron-right.svg"></svg-icon>
</ng-template>
