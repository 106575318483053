import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IZoomServiceInterface } from 'app/component/ui/zoom/IZoomServiceInterface';
import { ApplicationConfig } from 'app/config/ApplicationConfig';
import { RoomDTO } from 'app/component/ui/zoom/data/RoomDTO';
import { ObjectUtil } from 'app/util/ObjectUtil';
import { map } from 'rxjs/operators';

@Injectable()
export class WatchPartyVideoCallService implements IZoomServiceInterface {
  constructor(
    private httpClient: HttpClient
  ) {}

  public getRoomData(id: string): Promise<RoomDTO> {
    return this.httpClient.post<RoomDTO>(
      `${ ApplicationConfig.apiUrl }/watchparties/${ id }/join`, {}
    ).pipe(
      map((response: RoomDTO): RoomDTO =>
        ObjectUtil.plainToClass(RoomDTO, response))
    ).toPromise();
  }

}
