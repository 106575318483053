import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SeniorDetailsPageDTO } from 'app/data/dto/senior/SeniorDetailsPageDTO';

@Component({
  selector: 'app-users-selected-action-bar',
  templateUrl: './UsersSelectedActionBarComponent.html',
  styleUrls: [ './UsersSelectedActionBarComponent.scss' ]
})
export class UsersSelectedActionBarComponent {
  public static readonly MODE = {
    ACTIVATE: 'ACTIVATE',
    DEACTIVATE: 'DEACTIVATE'
  } as const;

  public readonly MODE: typeof UsersSelectedActionBarComponent.MODE = UsersSelectedActionBarComponent.MODE;

  @Input()
  public mode: keyof typeof UsersSelectedActionBarComponent.MODE;

  @Input()
  public selectedUsers: SeniorDetailsPageDTO[] = [];

  @Output()
  public action: EventEmitter<keyof typeof UsersSelectedActionBarComponent.MODE> = new EventEmitter<keyof typeof UsersSelectedActionBarComponent.MODE>();

  constructor() {
  }

  public emitAction(): void {
    this.action.emit(this.mode);
  }
}
