import { Injectable, Injector } from '@angular/core';
import { FileDTO } from 'app/data/dto/file/FileDTO';
import { FileTokenDTO } from 'app/data/dto/file/FileTokenDTO';
import { UrlDTO } from 'app/data/dto/file/UrlDTO';
import { FileService } from 'app/service/FileService';
import { FileServiceInterface } from 'app/service/interface/FileServiceInterface';
import { EventManager } from 'app/util/other/EventManager';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FileModel {
  private fileService: FileServiceInterface; // injected in constructor body

  constructor(
    private eventManager: EventManager,
    private injector: Injector
  ) {
    this.setupService();
    this.setupListeners();
  }

  private setupService(): void {
    this.fileService = this.injector.get(FileService);
  }

  private setupListeners(): void {
  }

  public uploadFile(file: File, silent: boolean = false): Observable<FileTokenDTO | number> {
    return this.fileService.uploadFile(file, silent);
  }

  public uploadAuthorizedFile(file: File, silent: boolean): Observable<FileDTO | number> {
    return this.fileService.uploadAuthorizedFile(file, silent);
  }

  public getFile(fileId: number): Promise<FileDTO> {
    return this.fileService
      .getFile(fileId)
      .toPromise()
      .then((file: FileDTO) => {
        return file;
      })
      .catch((error) => {
        throw error;
      });
  }

  public getFiles(fileIds: number[]): Promise<{ [key: string]: FileDTO }> {
    return this.fileService
      .getFiles(fileIds)
      .toPromise()
      .then((files: { [key: string]: FileDTO }) => {
        return files;
      })
      .catch((error) => {
        throw error;
      });
  }

  public deleteFile(fileId: number): Promise<void> {
    return this.fileService
      .deleteFile(fileId)
      .toPromise()
      .then(() => {
        return;
      })
      .catch((error) => {
        throw error;
      });
  }

  public getFileDownloadUrl(fileId: number): Promise<UrlDTO> {
    return this.fileService
      .getFileDownloadUrl(fileId)
      .toPromise()
      .then((url: UrlDTO) => {
        return url;
      })
      .catch((error) => {
        throw error;
      });
  }

  public getFilesDownloadUrls(fileIds: number[]): Promise<{ [key: string]: UrlDTO }> {
    return this.fileService
      .getFilesDownloadUrls(fileIds)
      .toPromise()
      .then((filesUrls: { [key: string]: UrlDTO }) => {
        return filesUrls;
      })
      .catch((error) => {
        throw error;
      });
  }

  public downloadFile(fileId: number): Promise<void> {
    return this.fileService
      .downloadFile(fileId)
      .toPromise()
      .then(() => {
        return;
      })
      .catch((error) => {
        throw error;
      });
  }

  public loadFile(fileId: number): Promise<Blob> {
    return this.fileService
      .loadFile(fileId)
      .toPromise()
      .then((file: Blob) => {
        return file;
      })
      .catch((error) => {
        throw error;
      });
  }

  public downloadFileByToken(fileToken: string): Promise<void> {
    return this.fileService
      .downloadFileByToken(fileToken)
      .toPromise()
      .then(() => {
        return;
      })
      .catch((error) => {
        throw error;
      });
  }

  public loadFileByToken(fileToken: string): Promise<Blob> {
    return this.fileService
      .loadFileByToken(fileToken)
      .toPromise()
      .then((file: Blob) => {
        return file;
      })
      .catch((error) => {
        throw error;
      });
  }
}
