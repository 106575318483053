import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import { Directive, Input, OnInit } from '@angular/core';
import { Constant } from 'app/common/Constant';
import * as _ from 'lodash';

export interface PasswordRequirementParams {
  upperCase?: boolean;

  lowerCase?: boolean;

  numberChar?: boolean;

  specialChar?: boolean;

  minLength?: number;

  maxLength?: number;
}

export interface PasswordRequirementErrors {
  upperCase?: boolean;

  lowerCase?: boolean;

  numberChar?: boolean;

  specialChar?: boolean;

  minLength?: boolean;

  maxLength?: boolean;
}

export const passwordRequirementsValidator = (params: PasswordRequirementParams): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors => {
    if (!control.value) {
      return null;
    }
    else {
      const validationErrors: PasswordRequirementErrors = {};

      if (params.upperCase) {
        validationErrors.upperCase = !(/[A-Z]/.test(control.value));
      }

      if (params.lowerCase) {
        validationErrors.lowerCase = !(/[a-z]/.test(control.value));
      }

      if (params.numberChar) {
        validationErrors.numberChar = !(/\d/.test(control.value));
      }

      if (params.specialChar) {
        validationErrors.specialChar = !(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(control.value));
      }

      if (params.minLength) {
        validationErrors.minLength = !(control.value.length >= params.minLength);
      }

      if (params.maxLength) {
        validationErrors.maxLength = control.value.length > params.maxLength;
      }

      const flattenedValidationErrors: PasswordRequirementErrors = _.pickBy(validationErrors, _.identity);

      return _.isEmpty(flattenedValidationErrors) ? null : { passwordRequirements: flattenedValidationErrors };
    }
  };
};

@Directive({
  selector: '[appPasswordRequirements][formControlName],[appPasswordRequirements][formControl],[appPasswordRequirements][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: PasswordRequirementsValidator,
      multi: true
    }
  ]
})
export class PasswordRequirementsValidator implements Validator, OnInit {

  @Input()
  public appPasswordRequirements: PasswordRequirementParams | string;

  private defaultParams: PasswordRequirementParams = {
    upperCase: true,
    lowerCase: true,
    numberChar: true,
    specialChar: true,
    minLength: Constant.PASSWORD_MIN_LENGTH,
    maxLength: Constant.PASSWORD_MAX_LENGTH_256
  };

  private validator: ValidatorFn;

  public ngOnInit(): void {
    this.validator = !_.isObject(this.appPasswordRequirements) ?
      passwordRequirementsValidator(this.defaultParams) :
      passwordRequirementsValidator({ ...this.defaultParams, ...this.appPasswordRequirements });
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    return this.validator(control);
  }

}
