import * as _ from 'lodash';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'includes' })
export class IncludesPipe implements PipeTransform {

  public transform(value: any, searchElement: any): boolean {
    return _.includes(value, searchElement);
  }

}
