<div (click)="preventGridSelection($event)" class="d-flex align-items-center justify-content-end">
  <button type="button" class="btn btn-sm btn-light mr-2" (click)="onDetailsClick()">
    <div class="d-flex flex-row align-items-center">
      <svg-icon class="d-flex mr-2" src="asset/image/icon/eye.svg"></svg-icon>
      <span>{{ 'COMMON.VIEW' | translate }}</span>
    </div>
  </button>
  <button
    *ngIf="activeTab === userListTabs.ACTIVE"
    type="button"
    class="btn btn-sm btn-light"
    (click)="onDeleteClick()"
  >
    <div class="d-flex flex-row align-items-center">
      <svg-icon class="d-flex stroke" src="asset/image/icon/trash-x.svg"></svg-icon>
    </div>
  </button>
</div>
