<div class="modal-header justify-content-end">
  <div class="modal-control">
    <button type="button" class="btn" (click)="close()">
      <svg-icon class="d-flex" src="asset/image/icon/x.svg"></svg-icon>
    </button>
  </div>
</div>

<div class="modal-body">
  <h1 class="text-center">{{ 'VIEW.MAIN.ACCOUNT.REFER_FRIEND.REFER_FRIEND' | translate }}</h1>
  <div class="mb-4 text-center">
    <p>{{ "VIEW.MAIN.ACCOUNT.REFER_FRIEND.MESSAGE" | translate }}</p>
  </div>
  <div [formGroup]="form">
    <div class="form-group" [class.disabled]="form.disabled">
      <label for="name">{{ 'VIEW.MAIN.ACCOUNT.REFER_FRIEND.NAME' | translate }}</label>
      <input
        id="name"
        type="text"
        class="form-control"
        [class.is-invalid]="form.controls.name.touched && form.controls.name.invalid"
        placeholder="{{'VIEW.MAIN.ACCOUNT.REFER_FRIEND.NAME' | translate}}"
        formControlName="name"
      />
      <div *ngIf="form.controls.name.touched && form.controls.name.invalid" class="invalid">
        <div>{{ 'VALIDATION.REQUIRED' | translate }}</div>
      </div>
    </div>
    <div class="form-group" [class.disabled]="form.disabled">
      <label for="email">{{ 'VIEW.MAIN.ACCOUNT.REFER_FRIEND.EMAIL' | translate }}</label>
      <input
        id="email"
        type="text"
        class="form-control"
        [class.is-invalid]="form.controls.email.touched && form.controls.email.invalid"
        placeholder="{{'VIEW.MAIN.ACCOUNT.REFER_FRIEND.EMAIL' | translate}}"
        formControlName="email"
      />
      <div *ngIf="form.controls.email.touched && form.controls.email.invalid" class="invalid">
        <div>{{ 'VALIDATION.REQUIRED' | translate }}</div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer justify-content-center">
  <button type="button" class="btn btn-primary w-25" (click)="close()">{{ 'COMMON.BACK' | translate }}</button>
  <button type="button" class="btn btn-primary w-25" (click)="close()">{{ 'COMMON.SEND' | translate }}</button>
</div>
