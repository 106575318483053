import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { StateService } from '@uirouter/core';
import { ValueFormatterParams } from 'ag-grid-community';
import { State } from 'app/common/State';
import { DataGridAction } from 'app/component/ui/dataGrid/DataGridAction';
import { PopupConfirmationComponent } from 'app/component/ui/popup/PopupConfirmationComponent';
import { BaseCriteriaSortOrder } from 'app/data/dto/BaseCriteriaDTO';
import { PageCriteriaDTO } from 'app/data/dto/PageCriteriaDTO';
import { PageDTO } from 'app/data/dto/PageDTO';
import { VideoPageCriteriaDTO } from 'app/data/dto/onDemandVideo/VideoPageCriteriaDTO';
import { VideoAdminDTO } from 'app/data/dto/onDemandVideo/admin/VideoAdminDTO';
import { SortDirection } from 'app/data/enum/SortDirection';
import { VideoListTabsAdmin } from 'app/data/enum/video/VideoListTabs';
import { VideoSortBy } from 'app/data/enum/video/VideoSortBy';
import { OptionItem } from 'app/data/local/generic/OptionItem';
import { ApplicationModel } from 'app/model/ApplicationModel';
import { DateUtil } from 'app/util/DateUtil';
import { StateUtil } from 'app/util/StateUtil';
import { ViewUtil } from 'app/util/ViewUtil';
import { FullNamePipe } from 'app/util/pipe/FullNamePipe';
import { PrefixPipe } from 'app/util/pipe/PrefixPipe';
import _ from 'lodash';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { of, pipe, Subject, Subscription } from 'rxjs';
import { catchError, filter, switchMap, takeUntil, tap } from 'rxjs/operators';

import { OnDemandVideoFilterModalComponent } from '../../../common/filters/OnDemandVideoFilterModalComponent';
import { OnDemandVideosListAdminActionCellRendererComponent } from '../cellRenderer/OnDemandVideosListAdminActionCellRendererComponent';
import { OnDemandVideoModel } from 'app/model/OnDemandVideoModel';
import { DataGridStarRatingCellRendererComponent } from 'app/component/ui/dataGrid/cellRenderer/DataGridStarRatingCellRendererComponent';
import { IntensityLevel } from 'app/data/enum/IntensityLevel';
import { PortalUtil } from 'app/util/PortalUtil';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import { TemplatePortal } from '@angular/cdk/portal';

@Component({
  selector: 'app-on-demand-videos-admin-list',
  templateUrl: './OnDemandVideosAdminListComponent.html',
  styleUrls: [ './OnDemandVideosAdminListComponent.scss' ]
})
export class OnDemandVideosAdminListComponent implements OnInit, OnDestroy {
  @ViewChild('headingTemplate', { static: true })
  private readonly headingTemplate: TemplateRef<any>;

  private destroy$: Subject<void> = new Subject<void>();
  public readonly pageSize: number = 10;
  public readonly tabs: VideoListTabsAdmin[] = [
    VideoListTabsAdmin.ALL,
    VideoListTabsAdmin.PROGRAMS,
    VideoListTabsAdmin.RECOMMENDED
  ];
  public activeTab: VideoListTabsAdmin = VideoListTabsAdmin.ALL;
  public VideoListTabsAdmin = VideoListTabsAdmin;

  public page: PageDTO<VideoAdminDTO>;
  public criteria: VideoPageCriteriaDTO;
  public selectedItems: VideoAdminDTO[] = [];
  public selectedDate: string = DateUtil.createDateString(new Date());
  public sortByOptions: OptionItem<string>[] = this.viewUtil.createEnumSelectOptions(VideoSortBy, 'VIDEO_SORT_BY');
  public sortValue: string;
  public clearMobileCriteria: Subject<void> = new Subject<void>();
  private subscription$: Subscription = new Subscription();

  public ActionCellRendererComponent: typeof OnDemandVideosListAdminActionCellRendererComponent =
    OnDemandVideosListAdminActionCellRendererComponent;

  public StarRatingCellRendererComponent: typeof DataGridStarRatingCellRendererComponent =
    DataGridStarRatingCellRendererComponent;

  public startDateValueFormatter: (params: ValueFormatterParams) => any = (params: ValueFormatterParams) =>
    this.viewUtil.dataGridPipeValueFormatter(params, DatePipe, 'd | h:mm a');

  public enumCategoryFormatter: (params: ValueFormatterParams) => any = (params: ValueFormatterParams) =>
    this.viewUtil.dataGridPipeValueFormatterWithTranslate(params, PrefixPipe, 'ENUM.VIDEO_CATEGORY.');

  public enumIntensityFormatter: (params: ValueFormatterParams) => any = (params: ValueFormatterParams) =>
    this.viewUtil.dataGridPipeValueFormatterWithTranslate(params, PrefixPipe, 'ENUM.INTENSITY_LEVEL.');

  public coachFormatter: (params: ValueFormatterParams) => any = (params: ValueFormatterParams) => {
    const coachUser = params.data.coaches[0].user;
    const fullName = this.fullNamePipe.transform(coachUser.firstName, coachUser.lastName);
    return fullName;
  };

  public durationFormatter: (params: ValueFormatterParams) => any = (params: ValueFormatterParams) => {
    const duration = params.data.duration;
    return `${ duration } min`;
  };

  constructor(private stateService: StateService,
              private modalService: BsModalService,
              public viewUtil: ViewUtil,
              private applicationModel: ApplicationModel,
              private onDemandVideoModel: OnDemandVideoModel,
              private stateUtil: StateUtil,
              private fullNamePipe: FullNamePipe,
              private portalUtil: PortalUtil,
              private viewContainerRef: ViewContainerRef) {
  }

  public ngOnInit(): void {
    this.applicationModel.selectSideBarItemWithState(State.MAIN.ADMIN.ON_DEMAND.LIST);

    this.portalUtil.attachPortalTo(
      MainLayoutComponent.PORTAL_OUTLET.HEADING,
      new TemplatePortal(this.headingTemplate, this.viewContainerRef)
    );

    this.criteria = new VideoPageCriteriaDTO();
    this.criteria.pageSize = this.pageSize;
    this.getOnDemandVideosPage();
  }

  public ngOnDestroy(): void {
    this.subscription$.unsubscribe();
    this.portalUtil.detachPortalFrom(MainLayoutComponent.PORTAL_OUTLET.HEADING);
  }

  public newVideo(): void {
    this.stateUtil.goToState(State.MAIN.ADMIN.ON_DEMAND.NEW_ON_DEMAND);
  }

  public goToDetails(video: VideoAdminDTO): void {
    this.stateService.go(State.MAIN.ADMIN.ON_DEMAND.DETAILS, { videoId: video.id, isAdmin: true });
  }

  public goToEdit(video: VideoAdminDTO): void {
    this.stateService.go(State.MAIN.ADMIN.ON_DEMAND.EDIT, { videoId: video.id });
  }

  public openFiltersModal(): void {
    const { anyOfCoachIds, anyOfIntensities, anyOfSubcategories } = this.criteria;
    const modal: BsModalRef = this.modalService.show(OnDemandVideoFilterModalComponent, {
      initialState: {
        anyOfCoachIds,
        anyOfIntensities,
        anyOfSubcategories,
        displayLanguages: false
      },
      class: 'modal-dialog-centered modal-filterLiveClasses'
    });
    modal.onHide
      .pipe(
        filter(
          (reason) =>
            reason === OnDemandVideoFilterModalComponent.FILTER_DATA ||
            reason === OnDemandVideoFilterModalComponent.CLEAR_FILTERS
        )
      )
      .subscribe(() => {
        const filters = modal.content.filters;
        this.criteria.anyOfSubcategories = filters.type.selectedTypes;
        this.criteria.anyOfCoachIds = filters.coaches.selectedCoaches;
        this.criteria.anyOfLanguages =
          Object.keys(filters.languages.checked).length > 0
            ? Object.keys(filters.languages.checked).filter((key) => filters.languages.checked[key])
            : null;
        this.criteria.anyOfIntensities =
          Object.keys(filters.intensities.checked).length > 0
            ? (Object.keys(filters.intensities.checked).filter(
              (key) => filters.intensities.checked[key]
            ) as IntensityLevel[])
            : null;
        this.clearMobileCriteria.next();
        this.getOnDemandVideosPage();
      });
  }

  public deleteVideos(video: VideoAdminDTO): void {
    const modal: BsModalRef = this.modalService.show(PopupConfirmationComponent, {
      initialState: {
        message: 'VIEW.MAIN.USER.DELETE_VIDEO_WARNING',
        messageVariables: { video: video.title },
        okText: 'COMMON.YES',
        cancelText: 'COMMON.NO'
      },
      class: 'modal-dialog-centered'
    });
    modal.onHide
      .pipe(
        filter((reason) => reason === PopupConfirmationComponent.POPUP_RESULT_CONFIRM),
        switchMap(() => {
          return this.onDemandVideoModel.deleteVideos({ ids: [ video.id ] });
        }),
        pipe(
          takeUntil(this.destroy$),
          tap(() => {
            this.getOnDemandVideosPage();
            this.viewUtil.showToastSuccess('COMMON.SUCCESS');
          }),
          catchError((err) => {
            this.viewUtil.handleServerError(err);
            return of(null);
          })
        )
      )
      .subscribe();
  }

  public onTabChanged(tabName: string): void {
    if (this.activeTab === tabName) {
      return;
    }
    this.selectedItems = [];
    if (tabName === VideoListTabsAdmin.PROGRAMS) {
      this.activeTab = tabName as VideoListTabsAdmin;
      return;
    }
    this.sortValue = null;
    this.activeTab = tabName as VideoListTabsAdmin;
    this.criteria = new VideoPageCriteriaDTO();

    this.clearMobileCriteria.next();
    if (this.activeTab === VideoListTabsAdmin.RECOMMENDED) {
      this.criteria.recommended = true;
    }
    else {
      this.criteria.pageSize = this.pageSize;
    }
    this.getOnDemandVideosPage();
  }

  public onCriteriaChanged(changedCriteria: PageCriteriaDTO): void {
    const criteriaCopy: VideoPageCriteriaDTO = _.cloneDeep(this.criteria);
    _.assignIn(criteriaCopy, changedCriteria);

    this.criteria = criteriaCopy;

    this.getOnDemandVideosPage();
  }

  public onSortOptionChange(sortBy: VideoSortBy): void {
    if (!sortBy) {
      delete this.criteria.sortOrders;
      this.getOnDemandVideosPage();
      return;
    }

    let sortOrders: BaseCriteriaSortOrder;
    let sortField: string;
    let sortDirection: SortDirection = SortDirection.ASCENDING;

    switch (sortBy) {
      case VideoSortBy.ALPHABETIC: {
        sortField = 'title';
        break;
      }
      case VideoSortBy.MOST_RECENT: {
        sortField = 'createdDate';
        sortDirection = SortDirection.DESCENDING;
        break;
      }
      case VideoSortBy.RATING: {
        sortField = 'rating';
        break;
      }
    }

    sortOrders = `${ sortField } ${ sortDirection }`;
    this.criteria.sortOrders = [ sortOrders ];
    this.getOnDemandVideosPage();
  }

  public onGridAction(action: DataGridAction): void {
    const video: VideoAdminDTO = action.args[0] as VideoAdminDTO;

    if (action.actionName === OnDemandVideosListAdminActionCellRendererComponent.ACTION_VIEW) {
      this.goToDetails(video);
    }
    else if (action.actionName === OnDemandVideosListAdminActionCellRendererComponent.ACTION_DELETE) {
      const modal: BsModalRef = this.modalService.show(PopupConfirmationComponent, {
        initialState: {
          message: 'VIEW.MAIN.USER.DELETE_VIDEO_WARNING',
          messageVariables: { video: video.title },
          okText: 'COMMON.YES',
          cancelText: 'COMMON.NO'
        },
        class: 'modal-dialog-centered'
      });
      modal.onHide
        .pipe(
          filter((reason) => reason === PopupConfirmationComponent.POPUP_RESULT_CONFIRM),

          takeUntil(this.destroy$),
          tap(() => {
            this.deleteVideos(video);
          }),
          catchError((err) => {
            this.viewUtil.handleServerError(err);
            return of(null);
          })
        )
        .subscribe();
    }
    else if (action.actionName === OnDemandVideosListAdminActionCellRendererComponent.ACTION_EDIT) {
      this.goToEdit(video);
    }
  }

  public onSelectionChange(selectedItems: VideoAdminDTO[]): void {
    this.selectedItems = selectedItems;
  }

  public onBulkRecommendedClick(): void {
    const selectedVideosIds = this.selectedItems.map((item) => item.id);
    const recommend = this.selectedItems.some((video) => !video.recommended);
    this.onDemandVideoModel.markVideosAsRecommended({ ids: selectedVideosIds }, recommend).subscribe(
      () => {
        this.viewUtil.showToastSuccess('COMMON.SUCCESS');
        this.getOnDemandVideosPage();
        this.selectedItems = [];
      },
      (error) => {
        this.viewUtil.handleServerError(error);
      }
    );
  }

  public onBulkDeleteClick(): void {
    const selectedVideosIds = this.selectedItems.map((item) => item.id);
    const titles =
      this.selectedItems.length > 0
        ? this.selectedItems.map((item) => item.title).join(', ')
        : this.selectedItems[0].title;
    const modal: BsModalRef = this.modalService.show(PopupConfirmationComponent, {
      initialState: {
        message: 'VIEW.MAIN.USER.DELETE_VIDEO_WARNING',
        messageVariables: { video: titles },
        okText: 'COMMON.YES',
        cancelText: 'COMMON.NO'
      },
      class: 'modal-dialog-centered'
    });
    modal.onHide
      .pipe(
        filter((reason) => reason === PopupConfirmationComponent.POPUP_RESULT_CONFIRM),
        switchMap(() => this.onDemandVideoModel.deleteVideos({ ids: selectedVideosIds })),
        pipe(
          takeUntil(this.destroy$),
          tap(() => {
            this.viewUtil.showToastSuccess('COMMON.SUCCESS');
            this.getOnDemandVideosPage();
            this.selectedItems = [];
          }),
          catchError((err) => {
            this.viewUtil.handleServerError(err);
            return of(null);
          })
        )
      )
      .subscribe();
  }

  public onAddNewClassClick(): void {
    this.stateUtil.goToState(State.MAIN.ADMIN.PROGRAM.CREATE);
  }

  private getOnDemandVideosPage(): void {
    this.onDemandVideoModel.getVideosPageAdmin(this.criteria).subscribe((page) => (this.page = page));
  }
}
