import { Injectable, Injector } from '@angular/core';
import { Friend } from 'app/data/local/friend/Friend';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { ViewUtil } from 'app/util/ViewUtil';
import { PageDTO } from 'app/data/dto/PageDTO';
import { FriendDTO } from 'app/data/dto/friend/FriendDTO';
import { FriendSentInvitationService } from 'app/service/friend/FriendSentInvitationService';
import { PageCriteriaDTO } from 'app/data/dto/PageCriteriaDTO';
import { FriendPendingInvitationService } from 'app/service/friend/FriendPendingInvitationService';
import { PopupConfirmationComponent } from 'app/component/ui/popup/PopupConfirmationComponent';
import { switchMap, tap } from 'rxjs/operators';
import { AddFriendModalComponent } from 'app/component/view/main/friends/modal/addFriend/AddFriendModalComponent';

@Injectable({ providedIn: 'root' })
export class FriendInvitationModel {
  constructor(private readonly friendSentInvitationService: FriendSentInvitationService,
              private readonly friendPendingInvitationService: FriendPendingInvitationService,
              private readonly viewUtil: ViewUtil,
              private readonly injector: Injector) {

  }

  public getSentInvitationsPage(criteria: PageCriteriaDTO): Observable<PageDTO<FriendDTO>> {
    return this.friendSentInvitationService.getPage(criteria);
  }

  public getPendingInvitationsPage(criteria: PageCriteriaDTO): Observable<PageDTO<FriendDTO>> {
    return this.friendPendingInvitationService.getPage(criteria);
  }

  public invite(): Observable<void> {
    return this.injector.get(BsModalService).show(AddFriendModalComponent, {
      class: 'modal-dialog-centered'
    }).content.close$;
  }

  public remove(friend: Friend): Observable<void> {
    const { firstName, lastName } = friend?.user;

    return this.injector.get(BsModalService).show(PopupConfirmationComponent, {
      initialState: {
        title: 'DOMAIN.FRIEND_INVITATION.MESSAGE.DELETE_SENT_INVITATION.TITLE',
        message: 'DOMAIN.FRIEND_INVITATION.MESSAGE.DELETE_SENT_INVITATION.DESCRIPTION',
        okText: 'COMMON.CONFIRM',
        cancelText: 'COMMON.CANCEL',
        messageVariables: { firstName, lastName }
      },
      class: 'modal-dialog-centered'
    }).content.close$
      .pipe(
        switchMap(() => this.friendSentInvitationService.remove(friend.id)),
        tap(() => this.viewUtil.showToastSuccess('DOMAIN.FRIEND_INVITATION.MESSAGE.DELETE_SENT_INVITATION.SUCCESS'))
      );
  }

  public accept(friend: Friend): Observable<void> {
    const { firstName, lastName } = friend?.user;

    return this.injector.get(BsModalService).show(PopupConfirmationComponent, {
      initialState: {
        title: 'DOMAIN.FRIEND_INVITATION.MESSAGE.ACCEPT_INVITATION.TITLE',
        message: 'DOMAIN.FRIEND_INVITATION.MESSAGE.ACCEPT_INVITATION.DESCRIPTION',
        okText: 'COMMON.CONFIRM',
        cancelText: 'COMMON.CANCEL',
        messageVariables: { firstName, lastName }
      },
      class: 'modal-dialog-centered'
    }).content.close$
      .pipe(
        switchMap(() => this.friendPendingInvitationService.accept(friend.id)),
        tap(() => this.viewUtil.showToastSuccess('DOMAIN.FRIEND_INVITATION.MESSAGE.ACCEPT_INVITATION.SUCCESS'))
      );
  }

  public reject(friend: Friend): Observable<void> {
    const { firstName, lastName } = friend?.user;

    return this.injector.get(BsModalService).show(PopupConfirmationComponent, {
      initialState: {
        title: 'DOMAIN.FRIEND_INVITATION.MESSAGE.REJECT_INVITATION.TITLE',
        message: 'DOMAIN.FRIEND_INVITATION.MESSAGE.REJECT_INVITATION.DESCRIPTION',
        okText: 'COMMON.CONFIRM',
        cancelText: 'COMMON.CANCEL',
        messageVariables: { firstName, lastName }
      },
      class: 'modal-dialog-centered'
    }).content.close$
      .pipe(
        switchMap(() => this.friendPendingInvitationService.reject(friend.id)),
        tap(() => this.viewUtil.showToastSuccess('DOMAIN.FRIEND_INVITATION.MESSAGE.REJECT_INVITATION.SUCCESS'))
      );
  }
}