import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { StateService } from '@uirouter/core';
import { Constant } from 'app/common/Constant';
import { State } from 'app/common/State';
import { OrganizationListDTO } from 'app/data/dto/registration/OrganizationListDTO';
import { SeniorUpdateRequestDTO } from 'app/data/dto/senior/SeniorUpdateRequestDTO';
import { UserGender } from 'app/data/enum/account/UserGender';
import { OptionItem } from 'app/data/local/generic/OptionItem';
import { AdminModel } from 'app/model/AdminModel';
import { StateUtil } from 'app/util/StateUtil';
import { ViewUtil } from 'app/util/ViewUtil';
import { Observable } from 'rxjs';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import { TemplatePortal } from '@angular/cdk/portal';
import { PortalUtil } from 'app/util/PortalUtil';
import { UserAddressDTO } from 'app/data/dto/user/UserAddressDTO';
import { UserSeniorUpdateRequestDTO } from 'app/data/dto/user/UserSeniorUpdateRequestDTO';
import { SubscriptionType } from 'app/data/enum/subscription/SubscriptionType';
import moment from 'moment';
import { IdentifiableObjectDTO } from 'app/data/dto/IdentifiableObjectDTO';
import { RegistrationType } from 'app/data/enum/user/RegistrationType';

@Component({
  selector: 'app-user-create',
  templateUrl: 'UserCreateComponent.html',
  styleUrls: [ 'UserCreateComponent.scss' ]
})
export class UserCreateComponent implements OnInit, OnDestroy {
  @ViewChild('headingTemplate', { static: true })
  private readonly headingTemplate: TemplateRef<any>;

  @ViewChild('userForm')
  private userForm: NgForm;

  public uploadStarted: boolean = false;

  public senior: SeniorUpdateRequestDTO;

  public Constant: typeof Constant = Constant;
  public SubscriptionType: typeof SubscriptionType = SubscriptionType;

  public statusOptions: OptionItem<boolean>[] = [
    {
      value: true,
      label: 'ENUM.STATUS.ACTIVE'
    },
    {
      value: false,
      label: 'ENUM.STATUS.INACTIVE'
    }
  ];

  public subscriptionTypeOptions: OptionItem<SubscriptionType>[] = this.viewUtil
    .createEnumSelectOptions(SubscriptionType, 'SUBSCRIPTION_TYPE');
  public genderOptions: OptionItem<UserGender>[] = this.viewUtil.createEnumSelectOptions(UserGender, 'USER_GENDER');
  public organizationsList$: Observable<OrganizationListDTO[]> = this.adminModel.getAllOrganizationList();
  public maxDateOfBirth: Date = moment().add(-1, 'days').toDate();

  constructor(private stateService: StateService,
              public viewUtil: ViewUtil,
              public stateUtil: StateUtil,
              private adminModel: AdminModel,
              private portalUtil: PortalUtil,
              private viewContainerRef: ViewContainerRef) {
    this.senior = new SeniorUpdateRequestDTO();
    this.senior.address = new UserAddressDTO();
    this.senior.user = new UserSeniorUpdateRequestDTO({ registrationType: RegistrationType.EMAIL });
  }

  public ngOnInit(): void {
    this.portalUtil.attachPortalTo(
      MainLayoutComponent.PORTAL_OUTLET.HEADING,
      new TemplatePortal(this.headingTemplate, this.viewContainerRef)
    );
  }

  public ngOnDestroy(): void {
    this.portalUtil.detachPortalFrom(MainLayoutComponent.PORTAL_OUTLET.HEADING);
  }

  public createUser(): void {
    this.userForm.onSubmit(null);

    if (this.userForm.form.valid) {
      this.adminModel
        .createSenior(this.senior)
        .subscribe({
          next: ({ id }: IdentifiableObjectDTO<number>) => {
            this.stateService.go(State.MAIN.ADMIN.USER.DETAILS, { id });
          },
          error: (err) => this.viewUtil.handleServerError(err)
        });
    }
  }

  public onBackClick(): void {
    this.stateService.go(State.MAIN.ADMIN.USER.LIST);
  }

  public handleStartUpload(uploadStart: boolean): void {
    this.uploadStarted = uploadStart;
  }

  public handleImageId(imageId: number): void {
    this.senior.user.imageId = imageId;
  }

  public onProviderChange(): void {
    const { subscription } = this.senior;

    subscription.memberCode = undefined;
  }

  public onSubscriptionTypeChange(): void {
    const { subscription } = this.senior;

    subscription.memberCode = undefined;
    subscription.organization = undefined;
  }
}
