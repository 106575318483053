import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { Transition } from '@uirouter/angular';
import Player, { Options } from '@vimeo/player';
import { State } from 'app/common/State';
import { VideoDetailsDTO } from 'app/data/dto/onDemandVideo/VideoDetailsDTO';
import { StateUtil } from 'app/util/StateUtil';
import { OnDemandVideoModel } from 'app/model/OnDemandVideoModel';
import { VideoRatingAdminPageCriteriaDTO } from 'app/data/dto/onDemandVideo/admin/VideoRatingAdminPageCriteriaDTO';
import { VideoRatingAdminPageDTO } from 'app/data/dto/onDemandVideo/admin/VideoRatingAdminPageDTO';
import { PageDTO } from 'app/data/dto/PageDTO';
import { ViewUtil } from 'app/util/ViewUtil';
import { IntensityLevel } from 'app/data/enum/IntensityLevel';
import { PortalUtil } from 'app/util/PortalUtil';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import { TemplatePortal } from '@angular/cdk/portal';

@Component({
  selector: 'app-on-demand-videos-admin-details',
  templateUrl: './OnDemandVideosAdminDetailsComponent.html',
  styleUrls: [ './OnDemandVideosAdminDetailsComponent.scss' ]
})
export class OnDemandVideosAdminDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('headingTemplate', { static: true })
  private readonly headingTemplate: TemplateRef<any>;

  public tabs: string[] = [ 'Details', 'Ratings', 'Stats' ];
  public videoId: number;
  public video: VideoDetailsDTO;
  public player: Player;
  public isAdmin: boolean;

  public videoRatingPage: PageDTO<VideoRatingAdminPageDTO> = new PageDTO<VideoRatingAdminPageDTO>();
  public videoRatingPageCriteria: VideoRatingAdminPageCriteriaDTO = new VideoRatingAdminPageCriteriaDTO();

  public readonly IntensityLevel: typeof IntensityLevel = IntensityLevel;

  constructor(private transition: Transition,
              private onDemandVideoModel: OnDemandVideoModel,
              public stateUtil: StateUtil,
              private viewUtil: ViewUtil,
              private portalUtil: PortalUtil,
              private viewContainerRef: ViewContainerRef) {
    this.videoRatingPageCriteria.videoIdEq = this.videoId = this.transition.params().videoId;
    this.videoRatingPageCriteria.pageSize = 5;
    this.isAdmin = this.transition.params().isAdmin;
    this.getVideo();
    this.getVideoRatingPage();
  }

  public ngOnInit() {
    this.portalUtil.attachPortalTo(
      MainLayoutComponent.PORTAL_OUTLET.HEADING,
      new TemplatePortal(this.headingTemplate, this.viewContainerRef)
    );
  }

  public ngOnDestroy() {
    this.portalUtil.detachPortalFrom(MainLayoutComponent.PORTAL_OUTLET.HEADING);
  }

  public edit(): void {
    this.stateUtil.goToState(State.MAIN.ADMIN.ON_DEMAND.EDIT, { videoId: this.videoId });
  }

  public downloadAllAttachments(): void {
    this.video.attachments.forEach((attachment) => {
      this.stateUtil.goToUrl(attachment.asset.url, true);
    });
  }

  private getVideo(): void {
    this.onDemandVideoModel.getVideo(this.videoId).subscribe((video) => {
      this.video = video;

      //this is a workaround for vimeo not setting up properly if the player is not rendered instantly, e.g. is inside ngIf statement
      setTimeout(() => this.setupVimeoPlayer());
    });
  }

  public getVideoRatingPage(nextPage: boolean = false): void {
    if (nextPage) {
      if ((this.videoRatingPage.totalPages - 1) > this.videoRatingPageCriteria.pageNumber) {
        this.videoRatingPageCriteria.pageNumber += 1;
      }
      else {
        return;
      }
    }

    this.onDemandVideoModel.getVideoRatingPage(this.videoRatingPageCriteria)
      .subscribe((page: PageDTO<VideoRatingAdminPageDTO>) => {
          if (nextPage) {
            this.videoRatingPage.content = this.videoRatingPage.content.concat(page.content);
          }
          else {
            this.videoRatingPage = page;
          }
        },
        (error) => {
          this.viewUtil.handleServerError(error);
        });
  }

  private setupVimeoPlayer(): void {
    const options: Options = {
      url: this.video.vimeoVideo.embedUrl,
      byline: false,
      portrait: false,
      responsive: true,
      title: false
    };
    this.player = new Player('vimeo', options);
  }

}
