import { Component, Input, OnInit } from '@angular/core';
import { OptionItem } from 'app/data/local/generic/OptionItem';
import { VideoCategory } from 'app/data/enum/video/VideoCategory';
import { VideoSubcategories } from 'app/data/enum/video/VideoSubcategory';
import { merge, Observable, of, Subject, Subscription } from 'rxjs';
import { VideoDictionaryPageCriteriaDTO } from 'app/data/dto/onDemandVideo/VideoDictionaryPageCriteriaDTO';
import { PageDTO } from 'app/data/dto/PageDTO';
import { BaseVideoDTO } from 'app/data/dto/onDemandVideo/BaseVideoDTO';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { ViewUtil } from 'app/util/ViewUtil';
import { DictionaryService } from 'app/service/DictionaryService';
import { WatchPartyType } from 'app/data/dto/watchParty/WatchPartyType';

@Component({
  selector: 'app-watch-party-form',
  templateUrl: './WatchPartyFormComponent.html'
})
export class WatchPartyFormComponent implements OnInit {
  @Input() public form: FormGroup;
  @Input() public submitted: boolean;
  @Input() public minDate: Date;
  @Input() public editingMode: boolean = false;

  public WatchPartyType: typeof WatchPartyType = WatchPartyType;
  public isCategoryFilterActive: boolean = true;
  public categories: OptionItem<VideoCategory>[] = this.viewUtil.createEnumSelectOptions(
    VideoCategory,
    'VIDEO_CATEGORY'
  );
  public subcategories: OptionItem<string>[] = this.viewUtil.createEnumSelectOptions(
    VideoSubcategories,
    'VIDEO_SUBCATEGORY'
  );

  private videoSubject: Subject<void> = new Subject<void>();
  private videoCriteria: VideoDictionaryPageCriteriaDTO = new VideoDictionaryPageCriteriaDTO();
  private videoPage: PageDTO<BaseVideoDTO>;
  public videoLoading: boolean = false;

  public videoPage$: Observable<PageDTO<BaseVideoDTO>> =
    merge(
      this.getVideos().pipe(first()),
      this.videoSubject.asObservable().pipe(
        switchMap(() => this.getVideos())
      )
    );

  public videos$: Observable<BaseVideoDTO[]> = this.videoPage$.pipe(
    tap((videoPage: PageDTO<BaseVideoDTO>) => {
      this.videoPage = videoPage;
    }),
    map((videoPage: PageDTO<BaseVideoDTO>) => videoPage.content)
  );

  private subscription: Subscription = new Subscription();

  constructor(private readonly viewUtil: ViewUtil,
              private readonly dictionaryService: DictionaryService) {
  }

  public ngOnInit(): void {
    this.subscription.add(merge(
      this.form.get('category').valueChanges,
      this.form.get('subcategory').valueChanges)
      .subscribe(() => {
        const category = this.form.get('category').value;
        const subcategory = this.form.get('subcategory').value;

        this.form.get('video').setValue(undefined);

        this.videoCriteria.category = category;
        this.videoCriteria.subcategory = subcategory;

        this.loadVideos();
      }));

    const type = this.form.get('type');
    const participants = this.form.get('participants');

    if (type && participants) {
      this.subscription.add(
        type.valueChanges.subscribe((type: WatchPartyType) => {
          if (type === WatchPartyType.PRIVATE) {
            this.editingMode ? participants.disable() : participants.enable();
          }
          else {
            participants.disable();
          }
        })
      );
    }

    if (this.editingMode) {
      this.isCategoryFilterActive = false;
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public loadVideos() {
    this.videoCriteria.pageSize = 10;
    this.videoSubject.next();
  }

  public showCategories(): void {
    this.form.patchValue({
      video: undefined,
      category: undefined,
      subcategory: undefined
    });

    this.isCategoryFilterActive = true;
  }

  public removeVideo(): void {
    this.form.patchValue({
      video: undefined
    });

    this.isCategoryFilterActive = true;
  }

  public onScrollToEnd(): void {
    if (!this.videoPage.isMore) {
      return;
    }
    else {
      this.videoCriteria.pageSize += 10;
      this.videoSubject.next();
    }
  }

  private getVideos(): Observable<PageDTO<BaseVideoDTO>> {
    this.videoLoading = true;

    return this.dictionaryService.getVideoPage(this.videoCriteria, true)
      .pipe(
        catchError(() => of(new PageDTO<BaseVideoDTO>())),
        tap(() => {
          this.videoLoading = false;
        })
      );
  }
}
