import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { StateService, Transition } from '@uirouter/core';
import { Constant } from 'app/common/Constant';
import { State } from 'app/common/State';
import { OrganizationAddressDTO } from 'app/data/dto/organization/OrganizationAddressDTO';
import { OrganizationDetailsResponseDTO } from 'app/data/dto/organization/OrganizationDetailsResponseDTO';
import { OrganizationUpdateRequestDTO } from 'app/data/dto/organization/OrganizationUpdateRequestDTO';
import { OrganizationType } from 'app/data/enum/organizations/OrganizationType';
import { OptionItem } from 'app/data/local/generic/OptionItem';
import { ApplicationModel } from 'app/model/ApplicationModel';
import { OrganizationModel } from 'app/model/OrganizationModel';
import { ViewUtil } from 'app/util/ViewUtil';
import { of, Subject } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { PortalUtil } from 'app/util/PortalUtil';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import { TemplatePortal } from '@angular/cdk/portal';

@Component({
  selector: 'app-organizations-edit',
  templateUrl: './OrganizationsEditComponent.html',
  styleUrls: [ './OrganizationsEditComponent.scss' ]
})
export class OrganizationsEditComponent implements OnInit, OnDestroy {
  @ViewChild('headingTemplate', { static: true })
  private readonly headingTemplate: TemplateRef<any>;

  @ViewChild('form')
  private form: NgForm;

  private destroy$: Subject<void> = new Subject<void>();
  public organization: OrganizationUpdateRequestDTO;
  public uploadStarted: boolean = false;
  public defaultAssetUrl: string;
  public organizationTypeList: OptionItem<OrganizationType>[] = [
    {
      value: OrganizationType.OFFICIAL,
      label: this.translate.instant('ENUM.STATUS.ACTIVE')
    },
    {
      value: OrganizationType.DEMO,
      label: this.translate.instant('ENUM.STATUS.INACTIVE')
    }
  ];

  public id: number;
  public Constant: typeof Constant = Constant;

  constructor(private stateService: StateService,
              private organizationModel: OrganizationModel,
              private translate: TranslateService,
              private transition: Transition,
              public viewUtil: ViewUtil,
              public applicationModel: ApplicationModel,
              private portalUtil: PortalUtil,
              private viewContainerRef: ViewContainerRef) {
  }

  public ngOnInit(): void {
    this.id = this.transition.params().id;

    this.applicationModel.selectSideBarItemWithState(State.MAIN.ADMIN.ORGANIZATIONS.LIST);

    this.portalUtil.attachPortalTo(
      MainLayoutComponent.PORTAL_OUTLET.HEADING,
      new TemplatePortal(this.headingTemplate, this.viewContainerRef)
    );

    this.organizationModel
      .getOrganizationDetails(this.id)
      .pipe(
        takeUntil(this.destroy$),
        tap((organization: OrganizationDetailsResponseDTO) => {
          this.organization = organization;
          this.defaultAssetUrl = organization?.logo?.defaultAsset?.url;
          this.organization.imageId = organization.logo?.id;
          if (!this.organization.address) {
            this.organization.address = new OrganizationAddressDTO();
          }
        }),
        catchError((err) => {
          this.viewUtil.handleServerError(err);
          return of(null);
        })
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.portalUtil.detachPortalFrom(MainLayoutComponent.PORTAL_OUTLET.HEADING);
  }

  public handleStartUpload(uploadStart: boolean): void {
    this.uploadStarted = uploadStart;
  }

  public handleImageId(imageId: number): void {
    this.organization.imageId = imageId;
  }

  public onBackClick(): void {
    this.stateService.go(State.MAIN.ADMIN.ORGANIZATIONS.LIST);
  }

  public onSaveClick(): void {
    this.form.onSubmit(null);
    if (this.form.form.valid) {
      this.organizationModel
        .updateOrganization(this.id, this.organization)
        .pipe(
          takeUntil(this.destroy$),
          tap(({ id }: OrganizationDetailsResponseDTO) => {
            this.stateService.go(State.MAIN.ADMIN.ORGANIZATIONS.DETAILS, { id });
          }),
          catchError((err) => {
            this.viewUtil.handleServerError(err);
            this.uploadStarted = false;
            return of(null);
          })
        )
        .subscribe();
    }
  }
}
