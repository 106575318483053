import { Transform, Type } from 'class-transformer';
import { IdentifiableObjectDTO } from '../../IdentifiableObjectDTO';
import { DateUtil } from 'app/util/DateUtil';
import { LiveClassCategory } from 'app/data/enum/liveClass/LiveClassCategory';
import { CoachDetailsLiveClassPageDTO } from '../../coach/CoachDetailsLiveClassPageDTO';
import { IntensityLevel } from 'app/data/enum/IntensityLevel';
import { BaseLiveClass } from 'app/data/dto/scheduledLiveClass/BaseLiveClass';

export class ScheduledLiveClassDetailsPageDTO extends IdentifiableObjectDTO<number> implements BaseLiveClass {
  public capacity?: number;

  public category: LiveClassCategory;

  @Type(() => CoachDetailsLiveClassPageDTO)
  public coaches: CoachDetailsLiveClassPageDTO[];

  public duration: number;

  public enrolledSeniorsCount: number;

  public intensity: IntensityLevel;

  public language: string;

  public liveClassSeriesId?: number;

  @Transform(DateUtil.dateTimeConversion)
  public startDate: Date;

  public title: string;
}
