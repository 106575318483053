import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { HtmlUtil } from 'app/util/HtmlUtil';

@Directive({
  selector: 'input[type="password"][appInputPasswordToggle]'
})
export class InputPasswordToggleDirective implements OnInit, OnDestroy {

  private inputParent: HTMLElement;
  private toggleIcon: HTMLSpanElement;
  private onPasswordToggleClickBoundFunction: (event: MouseEvent) => void;

  constructor(private el: ElementRef) {
  }

  public ngOnInit(): void {
    this.onPasswordToggleClickBoundFunction = this.onPasswordToggleClick.bind(this);

    this.toggleIcon = document.createElement('span');
    this.toggleIcon.classList.add('password-toggle-icon');
    this.toggleIcon.addEventListener('click', this.onPasswordToggleClickBoundFunction);

    this.inputParent = this.el.nativeElement.parentNode;
    this.inputParent.classList.add('password-toggle');
    this.inputParent.appendChild(this.toggleIcon);
  }

  public ngOnDestroy(): void {
    this.toggleIcon.removeEventListener('click', this.onPasswordToggleClickBoundFunction);
    this.onPasswordToggleClickBoundFunction = null;
  }

  private onPasswordToggleClick(event: MouseEvent): void {
    HtmlUtil.togglePasswordInput(this.el.nativeElement);
  }

}
