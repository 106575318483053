import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { StateService } from '@uirouter/core';
import { Constant } from 'app/common/Constant';
import { State } from 'app/common/State';
import { AuthModel } from 'app/model/AuthModel';
import { ViewUtil } from 'app/util/ViewUtil';

@Component({
  selector: 'app-reset-password-start',
  templateUrl: 'ResetPasswordStartComponent.html',
  styleUrls: [ 'ResetPasswordStartComponent.scss' ]
})
export class ResetPasswordStartComponent {
  @ViewChild('resetPasswordForm')
  private resetPasswordForm: NgForm;

  public formData: {
    username: string;
  } = {
    username: null
  };

  public Constant: typeof Constant = Constant;

  constructor(private stateService: StateService,
              public viewUtil: ViewUtil,
              private authModel: AuthModel) {
  }

  public resetPassword(): void {
    this.resetPasswordForm.onSubmit(null);

    if (this.resetPasswordForm.form.valid) {
      this.authModel
        .startPasswordReset(this.formData.username)
        .then(() => {
          this.stateService.go(State.PRELIMINARY.RESET_PASSWORD_VERIFICATION_CODE, { username: this.formData.username });
          this.viewUtil.showToastSuccess('VIEW.PRELIMINARY.RESET_PASSWORD.RESET_PASSWORD_START_SUCCESS');
        })
        .catch((error) => {
          this.viewUtil.handleServerError(error);
        });
    }
  }

  public backToLogin(): void {
    this.stateService.go(State.PRELIMINARY.LOGIN);
  }
}
