import { Injectable, Pipe, PipeTransform } from '@angular/core';
import * as numeral from 'numeral';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'distanceFormat' })
export class DistanceFormatPipe implements PipeTransform {

  public transform(value: number, meterString: string = 'm', kilometerString: string = 'km'): any {
    if (value) {
      if ((value < 1000) && (value > -1000)) {
        return numeral(value).format('000') + meterString;
      }
      else {
        return numeral(value / 1000).format('0.00') + kilometerString;
      }
    }
    else {
      return '-';
    }
  }
}
