import { Component } from '@angular/core';
import { StateService } from '@uirouter/core';
import { Transition } from '@uirouter/angular';
import { ViewUtil } from 'app/util/ViewUtil';
import { State } from 'app/common/State';
import { UserModel } from 'app/model/UserModel';

@Component({
  selector: 'app-registration-complete',
  templateUrl: 'RegistrationCompleteComponent.html',
  styleUrls: [ 'RegistrationCompleteComponent.scss' ]
})
export class RegistrationCompleteComponent {
  private static readonly RESEND_CONFIRMATION_DELAY: number = 60000;

  private username: string;

  private canResendConfirmation: boolean = true;

  constructor(private stateService: StateService,
              private transition: Transition,
              public viewUtil: ViewUtil,
              private userModel: UserModel) {
    const username = this.transition.params().username;

    if (!username) {
      this.stateService.go(State.PRELIMINARY.LOGIN);
    }
    else {
      this.username = username;
    }
  }

  public backToLogin(): void {
    this.stateService.go(State.PRELIMINARY.LOGIN);
  }

  public resendConfirmationLink(): void {
    if (!this.canResendConfirmation) {
      this.viewUtil.showToastError('VIEW.PRELIMINARY.REGISTRATION.RESEND_CONFIRMATION_FAILURE');
      return;
    }

    this.userModel.resendConfirmationCode(this.username).subscribe(
      () => {
        this.canResendConfirmation = false;

        setTimeout(() => {
          this.canResendConfirmation = true;
        }, RegistrationCompleteComponent.RESEND_CONFIRMATION_DELAY);

        this.viewUtil.showToastSuccess('VIEW.PRELIMINARY.REGISTRATION.RESEND_CONFIRMATION_SUCCESS');
      },
      (err) => {
        this.viewUtil.handleServerError(err);
      }
    );
  }
}
