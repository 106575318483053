import { Type } from 'class-transformer';
import { CoachCertificateDTO } from './CertificateDTO';
import { CoachEducationDTO } from './EducationDTO';
import { CoachHobbyDTO } from './CoachHobbyDTO';
import { UserCoachProfileRequestDTO } from './UserCoachProfileRequestDTO';
import { CoachType } from 'app/data/enum/coach/CoachType';

export class CoachProfileCreateAdminRequestDTO {
  public bio: string;

  @Type(() => CoachCertificateDTO)
  public coachCertifications: CoachCertificateDTO[];

  @Type(() => CoachEducationDTO)
  public coachEducations: CoachEducationDTO[];

  @Type(() => CoachHobbyDTO)
  public coachHobbies: CoachHobbyDTO[];

  public location: string;

  public rating: number;

  public type: CoachType;

  @Type(() => UserCoachProfileRequestDTO)
  public user: UserCoachProfileRequestDTO;
}