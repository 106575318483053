import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { StateService } from '@uirouter/core';
import { ValueFormatterParams } from 'ag-grid-community';
import { State } from 'app/common/State';
import { DataGridAction } from 'app/component/ui/dataGrid/DataGridAction';
import { PopupConfirmationComponent } from 'app/component/ui/popup/PopupConfirmationComponent';
import { PageDTO } from 'app/data/dto/PageDTO';
import { CoachDTO } from 'app/data/dto/coach/CoachDTO';
import { ScheduledLiveClassPageCriteriaDTO } from 'app/data/dto/scheduledLiveClass/ScheduledLiveClassPageCriteriaDTO';
import { ScheduledLiveClassDetailsPageDTO } from 'app/data/dto/scheduledLiveClass/admin/ScheduledLiveClassDetailsPageDTO';
import { Language } from 'app/data/enum/Language';
import { DayOfWeek } from 'app/data/enum/DayOfWeek';
import { LiveClassCategory } from 'app/data/enum/liveClass/LiveClassCategory';
import { LiveClassesDaysAhead } from 'app/data/enum/liveClass/LiveClassesDaysAhead';
import { TimeOfDay } from 'app/data/enum/TimeOfDay';
import { OptionItem } from 'app/data/local/generic/OptionItem';
import { AdminModel } from 'app/model/AdminModel';
import { ObjectUtil } from 'app/util/ObjectUtil';
import { ViewUtil } from 'app/util/ViewUtil';
import { FullNamePipe } from 'app/util/pipe/FullNamePipe';
import { PrefixPipe } from 'app/util/pipe/PrefixPipe';
import * as _ from 'lodash';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, of, pipe, Subject } from 'rxjs';
import { catchError, filter, takeUntil, tap } from 'rxjs/operators';

import { LiveClassesListActionCellRendererComponent } from '../../../components/cellRenderer/LiveClassesListActionCellRendererComponent';
import { UsersCapacityCellRendererComponent } from '../../../components/cellRenderer/usersCapacity/UsersCapacityCellRendererComponent';
import { LiveClassesFilterComponent } from '../../../components/filter/LiveClassesFilterComponent';
import { UserDTO } from 'app/data/dto/user/UserDTO';
import { UserModel } from 'app/model/UserModel';
import { RouteTab } from 'app/component/ui/routeTabs/RouteTab';
import { LiveClassType } from 'app/data/enum/liveClass/LiveClassType';
import { IntensityLevel } from 'app/data/enum/IntensityLevel';
import { DataGridStarRatingCellRendererComponent } from 'app/component/ui/dataGrid/cellRenderer/DataGridStarRatingCellRendererComponent';
import { PortalUtil } from 'app/util/PortalUtil';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import { TemplatePortal } from '@angular/cdk/portal';
import { BaseCriteriaDTO } from 'app/data/dto/BaseCriteriaDTO';

@Component({
  selector: 'app-live-class-single-list-admin',
  templateUrl: './LiveClassSingleListAdminComponent.html',
  styleUrls: [ './LiveClassSingleListAdminComponent.scss' ]
})
export class LiveClassSingleListAdminComponent implements OnInit, OnDestroy {
  @ViewChild('headingTemplate', { static: true })
  private readonly headingTemplate: TemplateRef<any>;

  private destroy$: Subject<void> = new Subject<void>();
  public selectedDaysAhead = LiveClassesDaysAhead;
  public bsConfig: Partial<BsDatepickerConfig>;
  public page$: Observable<PageDTO<ScheduledLiveClassDetailsPageDTO>>;
  public criteria: ScheduledLiveClassPageCriteriaDTO = new ScheduledLiveClassPageCriteriaDTO();
  public consecutiveDays: string;
  public selectedItems: ScheduledLiveClassDetailsPageDTO[] = [];
  public selectedDate: Date = new Date();
  public currentUser: UserDTO;
  public daysAheadSelectList: OptionItem<LiveClassesDaysAhead>[] = this.viewUtil.createEnumSelectOptions(
    LiveClassesDaysAhead,
    'DAYS_AHEAD'
  );

  public readonly State: typeof State = State;
  public readonly tabs: RouteTab[] = [
    {
      name: LiveClassType.SINGLE,
      state: State.MAIN.ADMIN.LIVE_CLASSES.SINGLE.LIST
    },
    {
      name: LiveClassType.SERIES,
      state: State.MAIN.ADMIN.LIVE_CLASSES.SERIES.LIST
    }
  ];

  public LiveClassesListActionCellRendererComponent: typeof LiveClassesListActionCellRendererComponent =
    LiveClassesListActionCellRendererComponent;

  public UsersCapacityCellRendererComponent: typeof UsersCapacityCellRendererComponent =
    UsersCapacityCellRendererComponent;

  public StarRatingCellRendererComponent: typeof DataGridStarRatingCellRendererComponent = DataGridStarRatingCellRendererComponent;

  public coaches: OptionItem<CoachDTO>[] = [];
  public categories: OptionItem<LiveClassCategory>[] = this.viewUtil.createEnumSelectOptions(
    LiveClassCategory,
    'CATEGORY'
  );

  public intensities: OptionItem<IntensityLevel>[] = this.viewUtil.createEnumSelectOptions(
    IntensityLevel,
    'INTENSITY_LEVEL'
  );

  public days: OptionItem<DayOfWeek>[] = this.viewUtil.createEnumSelectOptions(DayOfWeek, 'DAYS');
  public timeOfTheDay: OptionItem<TimeOfDay>[] = this.viewUtil.createEnumSelectOptions(
    TimeOfDay,
    'TIME_OF_THE_DAY'
  );

  public languages: OptionItem<Language>[] = this.viewUtil.createEnumSelectOptions(Language, 'LANGUAGE');

  public friendlyNames: { [key: string]: string } = {
    anyOfCategories: 'COMPONENT.CLASS_FILTERS.CLASS_CATEGORY',
    anyOfIntensities: 'COMPONENT.CLASS_FILTERS.INTENSITY',
    daysOfWeek: 'COMPONENT.CLASS_FILTERS.DAY_OF_THE_WEEK',
    anyOfCoachIds: 'COMPONENT.CLASS_FILTERS.COACH',
    anyOfLanguages: 'COMPONENT.CLASS_FILTERS.LANGUAGE'
  };

  public getLabelForKeyValueBind: (key: string, value: string) => string = this.getLabelForKeyValue.bind(this);

  public dateTimeValueFormatter: (params: ValueFormatterParams) => any = (params: ValueFormatterParams) => {
    const date: string = this.viewUtil.dataGridPipeValueFormatter(params, DatePipe, 'MM/dd/yyyy');
    const time: string = this.viewUtil.dataGridPipeValueFormatter(params, DatePipe, 'h:mm a');
    return `${ time } ${ date }`;
  };

  public enumCategoryFormatter: (params: ValueFormatterParams) => any = (params: ValueFormatterParams) =>
    this.viewUtil.dataGridPipeValueFormatterWithTranslate(params, PrefixPipe, 'ENUM.CATEGORY.');

  public enumIntensityFormatter: (params: ValueFormatterParams) => any = (params: ValueFormatterParams) =>
    this.viewUtil.dataGridPipeValueFormatterWithTranslate(params, PrefixPipe, 'ENUM.INTENSITY_LEVEL.');

  public coachFormatter: (params: ValueFormatterParams) => any = (params: ValueFormatterParams) => {
    const coachUser = params.data.coaches[0].user;
    const fullName = this.fullNamePipe.transform(coachUser.firstName, coachUser.lastName);
    return fullName;
  };

  public usersCapacityFormatter = (params: ValueFormatterParams) => {
    const { capacity } = params.data;
    const { enrolledSeniorsCount } = params.data;
    return `${ enrolledSeniorsCount }/${ capacity }`;
  };

  constructor(public modalService: BsModalService,
              private stateService: StateService,
              public viewUtil: ViewUtil,
              private adminModel: AdminModel,
              private fullNamePipe: FullNamePipe,
              private userModel: UserModel,
              private portalUtil: PortalUtil,
              private viewContainerRef: ViewContainerRef) {
    this.currentUser = this.userModel.currentUser;
  }

  public ngOnInit(): void {
    this.portalUtil.attachPortalTo(
      MainLayoutComponent.PORTAL_OUTLET.HEADING,
      new TemplatePortal(this.headingTemplate, this.viewContainerRef)
    );

    this.criteria.selectedDate = new Date();
    this.getScheduledLiveClassPage();
  }

  public checkboxSelectionFunction(TData: any, TValue: any): boolean {
    const { data } = TData;
    return data.id !== null && data.name !== null;
  }

  public onCriteriaChanged(changedCriteria: BaseCriteriaDTO): void {
    const criteriaCopy: ScheduledLiveClassPageCriteriaDTO = _.cloneDeep(this.criteria);
    _.assignIn(criteriaCopy, changedCriteria);

    this.criteria = criteriaCopy;

    this.getScheduledLiveClassPage();
  }

  public changeToNextDay() {
    const currentDate = new Date(this.selectedDate);
    currentDate.setDate(currentDate.getDate() + 1);
    this.selectedDate = currentDate;
    this.criteria.selectedDate = this.selectedDate;
    this.resetConsecutiveDaysIfNotToday();
    this.onCriteriaChanged(this.criteria);
  }

  public changeToPreviousDay(): void {
    const currentDate = new Date(this.selectedDate);
    currentDate.setDate(currentDate.getDate() - 1);
    this.selectedDate = currentDate;
    this.criteria.selectedDate = this.selectedDate;
    this.resetConsecutiveDaysIfNotToday();
    this.onCriteriaChanged(this.criteria);
  }

  private resetConsecutiveDaysIfNotToday() {
    const selectedDateMidnight = new Date(this.selectedDate).setHours(0, 0, 0, 0);
    const todayMidnight = new Date().setHours(0, 0, 0, 0);
    if (selectedDateMidnight !== todayMidnight && this.consecutiveDays === this.selectedDaysAhead.TODAY) {
      this.consecutiveDays = null;
    }
  }

  public editLiveClass(scheduledLiveClass: ScheduledLiveClassDetailsPageDTO): void {
    const { id } = scheduledLiveClass;
    this.stateService.go(State.MAIN.ADMIN.LIVE_CLASSES.SINGLE.EDIT, { id });
  }

  public detailsLiveClass(scheduledLiveClassDetailsPage: ScheduledLiveClassDetailsPageDTO): void {
    const { id } = scheduledLiveClassDetailsPage;
    this.stateService.go(State.MAIN.ADMIN.LIVE_CLASSES.SINGLE.DETAILS, { id });
  }

  public deleteClasses(scheduledLiveClassDetailsPage: ScheduledLiveClassDetailsPageDTO[]): void {
    const classIDs = scheduledLiveClassDetailsPage.map((scheduledLiveClass) => scheduledLiveClass.id);
    this.adminModel
      .deleteScheduledLiveClass(classIDs)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.selectedItems = [];
          this.getScheduledLiveClassPage();
          this.viewUtil.showToastSuccess('COMMON.SUCCESS');
        }),
        catchError((err) => {
          this.viewUtil.handleServerError(err);
          return of(null);
        })
      )
      .subscribe();
  }

  public showFilterClick(): void {
    const modal: BsModalRef = this.modalService.show(LiveClassesFilterComponent, {
      initialState: {
        anyOfCategories: this.criteria.anyOfCategories,
        anyOfCoachIds: this.criteria.anyOfCoachIds,
        anyOfIntensities: this.criteria.anyOfIntensities,
        anyOfLanguages: this.criteria.anyOfLanguages,
        daysOfWeek: this.criteria.daysOfWeek
      },
      class: 'modal-filterLiveClasses modal-dialog-centered'
    });

    modal.onHide
      .pipe(
        filter((reason) => reason === LiveClassesFilterComponent.SHOW_FILTERS || reason === LiveClassesFilterComponent.CLEAR_FILTERS),
        tap(() => {
          const { categories, coaches, dayOfTheWeek, languages, intensities } = modal.content.filters;
          this.criteria = {
            ...this.criteria,
            anyOfCategories:
              Object.keys(categories.checked).length > 0
                ? Object.keys(categories.checked).filter((key) => categories.checked[key])
                : undefined,
            anyOfIntensities:
              Object.keys(intensities.checked).length > 0
                ? Object.keys(intensities.checked).filter((key) => intensities.checked[key])
                : undefined,
            daysOfWeek: dayOfTheWeek,
            anyOfCoachIds: coaches.selectedCoaches.length > 0 ? coaches.selectedCoaches : undefined,
            anyOfLanguages:
              Object.keys(languages.checked).length > 0
                ? Object.keys(languages.checked).filter((key) => languages.checked[key])
                : undefined
          } as ScheduledLiveClassPageCriteriaDTO;
          this.criteria = ObjectUtil.plainToClass(ScheduledLiveClassPageCriteriaDTO, this.criteria);
          const { coaches: coachesOptionItemList } = modal.content;
          this.coaches = coachesOptionItemList;
          this.onCriteriaChanged(this.criteria);
        })
      )
      .subscribe();
  }

  public handleDeleteClick(item?: ScheduledLiveClassDetailsPageDTO): void {
    const title =
      this.selectedItems.length > 0
        ? this.selectedItems.map((item) => item.title).join(', ')
        : item.title;
    const modal: BsModalRef = this.modalService.show(PopupConfirmationComponent, {
      initialState: {
        message: 'VIEW.MAIN.USER.DELETE_CLASS_WARNING',
        messageVariables: { class: title },
        okText: 'COMMON.YES',
        cancelText: 'COMMON.NO'
      },
      class: 'modal-dialog-centered'
    });
    modal.onHidden
      .pipe(
        takeUntil(this.destroy$),
        filter((reason) => reason === PopupConfirmationComponent.POPUP_RESULT_CONFIRM),
        tap(() => {
          const req = this.selectedItems.length > 0 ? this.selectedItems : [ item ];
          return this.deleteClasses(req);
        }),
        catchError((err) => {
          this.viewUtil.handleServerError(err);
          return of(null);
        })
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.portalUtil.detachPortalFrom(MainLayoutComponent.PORTAL_OUTLET.HEADING);
  }

  public getLabelForKeyValue(key: string, value: string): string {
    let optionsSet: OptionItem<any>[] = [];

    switch (key) {
      case 'anyOfCategories':
        optionsSet = this.categories;
        break;
      case 'anyOfIntensities':
        optionsSet = this.intensities;
        break;
      case 'daysOfWeek':
        optionsSet = this.days;
        break;
      case 'anyOfCoachIds':
        optionsSet = this.coaches;
        break;
      case 'anyOfLanguages':
        optionsSet = this.languages;
        break;
    }

    const foundItem = optionsSet?.find((item) => item.value === value);
    return foundItem ? foundItem.label : value;
  }

  public onDaysAheadChange(selectedDaysAhead: string): void {
    if (selectedDaysAhead) {
      const daysAhead = this.mapEnumToDays(selectedDaysAhead);
      this.criteria.consecutiveDays = daysAhead;
    }
    else {
      this.criteria.consecutiveDays = null;
      this.consecutiveDays = this.selectedDaysAhead.TODAY;
    }
    this.onCriteriaChanged(this.criteria);
  }

  public onDateChange(event: any): void {
    this.selectedDate = new Date(event);
    this.criteria.selectedDate = this.selectedDate;
    this.onCriteriaChanged(this.criteria);
  }

  public onTodayClick(): void {
    const today = new Date();
    this.selectedDate = today;
    this.criteria.selectedDate = this.selectedDate;
    this.criteria.consecutiveDays = null;
    this.consecutiveDays = LiveClassesDaysAhead.TODAY;
    this.onCriteriaChanged(this.criteria);
  }

  public onGridAction(action: DataGridAction): void {
    const scheduledLiveClassDetailsPage: ScheduledLiveClassDetailsPageDTO = action
      .args[0] as ScheduledLiveClassDetailsPageDTO;
    if (action.actionName === LiveClassesListActionCellRendererComponent.ACTION_EDIT) {
      this.editLiveClass(scheduledLiveClassDetailsPage);
    }
    if (action.actionName === LiveClassesListActionCellRendererComponent.ACTION_DETAILS_ADMIN) {
      this.detailsLiveClass(scheduledLiveClassDetailsPage);
    }
    if (action.actionName === LiveClassesListActionCellRendererComponent.ACTION_DELETE) {
      const modal: BsModalRef = this.modalService.show(PopupConfirmationComponent, {
        initialState: {
          message: 'VIEW.MAIN.USER.DELETE_CLASS_WARNING',
          messageVariables: { class: scheduledLiveClassDetailsPage.title },
          okText: 'COMMON.YES',
          cancelText: 'COMMON.NO'
        },
        class: 'modal-dialog-centered'
      });
      modal.onHide
        .pipe(
          filter((reason) => reason === PopupConfirmationComponent.POPUP_RESULT_CONFIRM),
          pipe(
            takeUntil(this.destroy$),
            tap(() => {
              this.deleteClasses([ scheduledLiveClassDetailsPage ]);
            }),
            catchError((err) => {
              this.viewUtil.handleServerError(err);
              return of(null);
            })
          )
        )
        .subscribe();
    }
  }

  public onSelectionChange(selectedItems: ScheduledLiveClassDetailsPageDTO[]): void {
    this.selectedItems = selectedItems;
  }

  private getScheduledLiveClassPage(): void {
    this.criteria.pageSize = 8;
    this.adminModel
      .getScheduledLiveClassesPage(this.criteria)
      .pipe(
        takeUntil(this.destroy$),
        tap((page: PageDTO<ScheduledLiveClassDetailsPageDTO>) => {
          this.page$ = of(page);
        })
      )
      .subscribe();
  }

  private mapEnumToDays(value: string | number): number | null {
    switch (value) {
      case LiveClassesDaysAhead.TODAY:
        return null;
      case LiveClassesDaysAhead.SHOW_3_DAYS:
        return 3;
      case LiveClassesDaysAhead.SHOW_10_DAYS:
        return 10;
      case LiveClassesDaysAhead.SHOW_30_DAYS:
        return 30;
      default:
        return 0;
    }
  }
}
