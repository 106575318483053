import { IdentifiableObjectDTO } from 'app/data/dto/IdentifiableObjectDTO';
import { UserLiveClassParticipationDTO } from '../user/UserLiveClassParticipationDTO';
import { Type } from 'class-transformer';

export class SeniorLiveClassParticipationDTO extends IdentifiableObjectDTO<number> {
  public dateOfBirth: string;

  @Type(() => UserLiveClassParticipationDTO)
  public user: UserLiveClassParticipationDTO;
}
