import { Component, EventEmitter, Input, Output } from '@angular/core';
import { VideoCategory } from 'app/data/enum/video/VideoCategory';

@Component({
  selector: 'app-on-demand-video-category',
  templateUrl: './OnDemandVideoCategoryComponent.html',
  styleUrls: [ './OnDemandVideoCategoryComponent.scss' ]
})
export class OnDemandVideoCategoryComponent {
  @Input() public selected: VideoCategory;
  @Output() public selectedChange: EventEmitter<VideoCategory> = new EventEmitter<VideoCategory>();

  public categories: { svgSrc: string, value: VideoCategory }[] = [
    {
      svgSrc: 'asset/image/icon/fitness.svg',
      value: VideoCategory.FITNESS
    },
    {
      svgSrc: 'asset/image/icon/mind-body.svg',
      value: VideoCategory.MIND_AND_BODY
    },
    {
      svgSrc: 'asset/image/icon/education.svg',
      value: VideoCategory.EDUCATION
    },
    {
      svgSrc: 'asset/image/icon/hobbies.svg',
      value: VideoCategory.HOBBIES
    },
    {
      svgSrc: 'asset/image/icon/live-recording.svg',
      value: VideoCategory.LIVE_RECORDINGS
    }
  ];
}
