import { Component, forwardRef, Injector, Input, OnInit } from '@angular/core';
import { merge, Observable, Subject } from 'rxjs';
import { PageDTO } from 'app/data/dto/PageDTO';
import { first, map, switchMap, tap } from 'rxjs/operators';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { PageCriteriaDTO } from 'app/data/dto/PageCriteriaDTO';
import { DictionaryService } from 'app/service/DictionaryService';
import { BaseRelatedUserDTO } from 'app/data/dto/user/BaseRelatedUserDTO';

@Component({
  selector: 'app-select-friend',
  templateUrl: './SelectFriendComponent.html',
  styleUrls: [ './SelectFriendComponent.scss' ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectFriendComponent),
      multi: true
    }
  ]
})
export class SelectFriendComponent implements ControlValueAccessor, OnInit {
  @Input() public placeholder: string = 'COMMON.CHOOSE';
  @Input() public multiple: boolean = true;
  @Input() public submitted: boolean;

  public selected: BaseRelatedUserDTO[] = [];
  public criteria: PageCriteriaDTO = new PageCriteriaDTO();
  public loadData: Subject<void> = new Subject<void>();
  public disabled: boolean = false;

  public dataPage: PageDTO<BaseRelatedUserDTO>;
  public dataPage$: Observable<PageDTO<BaseRelatedUserDTO>> =
    merge(
      this.getData().pipe(first()),
      this.loadData.asObservable().pipe(
        switchMap(() => this.getData())
      )
    );

  public data$: Observable<BaseRelatedUserDTO[]> = this.dataPage$.pipe(
    tap((dataPage: PageDTO<BaseRelatedUserDTO>) => {
      this.dataPage = dataPage;
    }),
    map((result: PageDTO<BaseRelatedUserDTO>) => result.content)
  );

  private ngControl: NgControl;

  constructor(private readonly dictionaryService: DictionaryService,
              private readonly injector: Injector) { }

  public ngOnInit(): void {
    this.ngControl = this.injector.get(NgControl, null);
  }

  private onChange: (value: BaseRelatedUserDTO[]) => void;
  private onTouched: () => void;

  public writeValue(value: BaseRelatedUserDTO[]): void {
    this.selected = value;
  }

  public registerOnChange(fn: (value: BaseRelatedUserDTO[]) => void): void {
    this.onChange = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public fetchData(): void {
    this.loadData.next();
  }

  public onSelectionChange(): void {
    this.onChange(this.selected);
    this.onTouched();

    this.fetchData();
  }

  public onScrollToEnd(): void {
    if (!this.dataPage.isMore) {
      return;
    }
    else {
      this.criteria.pageSize += 10;
      this.loadData.next();
    }
  }

  public isInvalid(): boolean {
    return this.ngControl && this.ngControl.invalid;
  }

  private getData(): Observable<PageDTO<BaseRelatedUserDTO>> {
    this.criteria.excludedIds = this.selected?.map(s => s.id);

    return this.dictionaryService.getFriendsPage(this.criteria);
  }
}
