import { DateUtil } from 'app/util/DateUtil';
import { ScheduledLiveClassEnrollmentDetailsDTO } from './ScheduledLiveClassEnrollmentDetailsDTO';
import { Transform, Type } from 'class-transformer';
import { IdentifiableObjectDTO } from '../../IdentifiableObjectDTO';
import { ScheduledLiveClassNeedDTO } from '../ScheduledLiveClassNeedDTO';
import { LiveClassCategory } from 'app/data/enum/liveClass/LiveClassCategory';
import { ScheduledLiveClassAttachmentResponseDTO } from '../ScheduledLiveClassAttachmentResponseDTO';
import { CoachSeniorDetailsPageDTO } from 'app/data/dto/liveClassSeries/senior/CoachSeniorDetailsPageDTO';
import { BaseLiveClass } from 'app/data/dto/scheduledLiveClass/BaseLiveClass';
import { IntensityLevel } from 'app/data/enum/IntensityLevel';

export class ScheduledLiveClassSeniorDetailsPageDTO extends IdentifiableObjectDTO<number> implements BaseLiveClass {
  @Type(() => ScheduledLiveClassAttachmentResponseDTO)
  public attachments: ScheduledLiveClassAttachmentResponseDTO[];

  public capacity?: number;

  public category: LiveClassCategory;

  @Type(() => CoachSeniorDetailsPageDTO)
  public coaches: CoachSeniorDetailsPageDTO[];

  @Type(() => ScheduledLiveClassEnrollmentDetailsDTO)
  public currentSeniorEnrollment?: ScheduledLiveClassEnrollmentDetailsDTO;

  public description: string;

  public duration: number;

  public enrolledSeniorsCount: number;

  public intensity: IntensityLevel;

  public isCurrentSeniorOnWaitingList: boolean;

  public isFavorite: boolean;

  public language: string;

  public liveClassSeriesId?: number;

  @Type(() => ScheduledLiveClassNeedDTO)
  public scheduledLiveClassNeeds: ScheduledLiveClassNeedDTO[];

  @Transform(DateUtil.dateTimeConversion)
  public startDate: Date;

  public title: string;

  public url: string;
}
