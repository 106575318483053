import { Type } from 'class-transformer';
import { IdentifiableObjectDTO } from '../IdentifiableObjectDTO';
import { OrganizationAddressDTO } from './OrganizationAddressDTO';

export class OrganizationCreateRequestDTO extends IdentifiableObjectDTO<number> {
  @Type(() => OrganizationAddressDTO)
  address?: OrganizationAddressDTO;
  apiUrl?: string;
  contactName: string;
  imageId?: number;
  name: string;
  phone?: string;
  type: string;
}
