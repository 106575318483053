import { Component, OnInit } from '@angular/core';
import { VideoDetailsDTO } from 'app/data/dto/onDemandVideo/VideoDetailsDTO';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { OnDemandVideoModel } from 'app/model/OnDemandVideoModel';
import { Constant } from 'app/common/Constant';
import { StateUtil } from 'app/util/StateUtil';
import { State } from 'app/common/State';
import { StateService } from '@uirouter/core';
import { Base64 } from 'js-base64';

@Component({
  selector: 'app-on-demand-videos-details-modal',
  templateUrl: './OnDemandVideosDetailsModalComponent.html',
  styleUrls: [ './OnDemandVideosDetailsModalComponent.scss' ]
})
export class OnDemandVideosDetailsModalComponent implements OnInit {
  public static PLAY_VIDEO: string = 'OnDemandVideosDetailsModalComponent_PLAY';

  public videoId: number;
  public data$: Observable<VideoDetailsDTO>;

  public readonly Constant: typeof Constant = Constant;

  constructor(private readonly bsModalRef: BsModalRef,
              private readonly modalService: BsModalService,
              private readonly onDemandVideoModel: OnDemandVideoModel,
              private readonly stateUtil: StateUtil,
              private stateService: StateService
  ) {
  }

  public ngOnInit(): void {
    this.data$ = this.onDemandVideoModel.getVideo(this.videoId);
  }

  public close(): void {
    this.bsModalRef.hide();
  }

  public createWatchParty(video: VideoDetailsDTO): void {
    this.stateUtil.goToState(State.MAIN.SENIOR.ON_DEMAND.WATCH_PARTY.CREATE, {
      video: Base64.encode(JSON.stringify({
        video: {
          id: video.id,
          title: video.title
        },
        category: video.category,
        subcategory: video.subcategories?.[0]
      }))
    });

    this.close();
  }

  public play(): void {
    this.modalService.setDismissReason(OnDemandVideosDetailsModalComponent.PLAY_VIDEO);
    this.bsModalRef.hide();
  }
}
