<div class="mb-4">
  <div class="row no-gutters justify-content-between">
    <div id="vimeo-player" class="col-md-12 col-lg-12 col-xl-9"></div>
    <div
      class="col-md-12 col-lg-12 col-xl-3 d-flex justify-content-start align-items-start flex-column p-3 border-card"
    >
      <h2>Video tutorial</h2>
      <div class="spacer"></div>
      <div>
      </div>
    </div>
  </div>
</div>
