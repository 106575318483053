import { Component } from '@angular/core';
import { StateUtil } from 'app/util/StateUtil';

@Component({
  selector: 'app-error-access-denied',
  templateUrl: 'ErrorAccessDeniedComponent.html',
  styleUrls: [ 'ErrorAccessDeniedComponent.scss' ]
})
export class ErrorAccessDeniedComponent {
  constructor(private stateUtil: StateUtil) {
  }

  public goToDashboard(): void {
    this.stateUtil.goToDashboard();
  }
}
