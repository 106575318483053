import { Component } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-popup-confirmation',
  templateUrl: 'PopupConfirmationComponent.html',
  styleUrls: [ 'PopupConfirmationComponent.scss' ]
})
export class PopupConfirmationComponent {

  public static POPUP_RESULT_CONFIRM: string = 'PopupConfirmationComponent_CONFIRM';

  public title: string = 'COMPONENT.POPUP.CONFIRMATION.TITLE';
  public message: string = 'COMPONENT.POPUP.CONFIRMATION.MESSAGE';
  public messageVariables: { [key: string]: any };
  public okText: string = 'COMMON.OK';
  public cancelText: string = 'COMMON.CANCEL';

  private onClose: Subject<boolean> = new Subject();
  public close$: Observable<boolean> = this.onClose.asObservable();

  constructor(private bsModalRef: BsModalRef,
              private modalService: BsModalService) {
  }

  public ok(): void {
    this.modalService.setDismissReason(PopupConfirmationComponent.POPUP_RESULT_CONFIRM);
    this.onClose.next(true);

    this.close();
  }

  public cancel(): void {
    this.close();
  }

  public close(): void {
    this.onClose.complete();
    this.bsModalRef.hide();
  }

}
