import { ImageResponseDTO } from 'app/data/dto/images/ImageResponseDTO';
import { LiveClassCategory } from 'app/data/enum/liveClass/LiveClassCategory';
import { Transform, Type } from 'class-transformer';
import { LiveClassSeriesAttachmentResponseDTO } from '../LiveClassSeriesAttachmentResponseDTO';
import { CoachAdminEssentialsDTO } from 'app/data/dto/admin/CoachAdminEssentialsDTO';
import { IdentifiableObjectDTO } from 'app/data/dto/IdentifiableObjectDTO';
import { DateUtil } from 'app/util/DateUtil';
import { DaysDTO } from 'app/data/dto/DaysDTO';
import { ScheduledLiveClassNeedDTO } from 'app/data/dto/scheduledLiveClass/ScheduledLiveClassNeedDTO';
import { LiveClassVisibility } from 'app/data/enum/liveClass/LiveClassVisibility';
import { IntensityLevel } from 'app/data/enum/IntensityLevel';

export class LiveClassSeriesDetailsResponseDTO extends IdentifiableObjectDTO<number> {
  @Type(() => LiveClassSeriesAttachmentResponseDTO)
  public attachments: LiveClassSeriesAttachmentResponseDTO[];

  @Transform(({ value }) => value || 0, { toClassOnly: true })
  public capacity?: number; // min 1 or null for no-limit

  public category: LiveClassCategory;

  @Type(() => CoachAdminEssentialsDTO)
  public coaches: CoachAdminEssentialsDTO[];

  public description: string;

  public duration: number;

  public intensity: IntensityLevel;

  public numberOfClassesLeft: number;

  public language: string;

  @Type(() => ScheduledLiveClassNeedDTO)
  public liveClassSeriesNeeds: ScheduledLiveClassNeedDTO[];

  @Type(() => DaysDTO)
  public daysOfWeek: DaysDTO;

  public rating: number;

  @Transform(DateUtil.dateTimeConversion)
  public startDate: Date;

  @Transform(DateUtil.dateTimeConversion)
  public endDate: Date;

  @Type(() => ImageResponseDTO)
  public thumbnail: ImageResponseDTO;

  public title: string;

  public url: string;

  public visibility: LiveClassVisibility;
}
