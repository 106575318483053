import { Component, forwardRef } from '@angular/core';
import { DayOfWeek } from 'app/data/enum/DayOfWeek';
import { DaysDTO } from 'app/data/dto/DaysDTO';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as _ from 'lodash';

@Component({
  selector: 'app-days-form',
  templateUrl: './DaysFormComponent.html',
  styleUrls: [ './DaysFormComponent.scss' ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DaysFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: DaysFormComponent,
      multi: true
    }
  ]
})
export class DaysFormComponent implements ControlValueAccessor {
  public availableDays: string[] = [ ...Object.keys(DayOfWeek), 'SATURDAY', 'SUNDAY' ];

  constructor() { }

  onChange: any = () => {};
  onTouch: any = () => {};

  val: DaysDTO = new DaysDTO();

  set value(val) {
    this.val = val;

    this.onChange(val);
    this.onTouch(val);
  }

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  validate({ value }: FormControl) {
    const isValid = value && _.some(Object.values(value), (s) => s === true);

    return !isValid && {
      required: true
    };
  }

  public change(): void {
    this.onChange(this.val);
    this.onTouch(this.val);
  }
}
