import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'liveClassCapacity' })
export class LiveClassCapacityPipe implements PipeTransform {

  public transform(capacity?: number): string {
    return `ENUM.CAPACITY.CAPACITY_${ capacity || 0 }`;
  }

}
