import { Component, forwardRef, Injector, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl, NgModel } from '@angular/forms';

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './DateTimePickerComponent.html',
  styleUrls: [ './DateTimePickerComponent.scss' ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimePickerComponent),
      multi: true
    }
  ]
})
export class DateTimePickerComponent implements ControlValueAccessor, OnInit {
  @Input() public submitted: boolean;
  @Input() public minDate: Date;
  @Input() public dateLabel: string = 'COMPONENT.DATE_TIME_PICKER.DATE';

  public dateTime: Date;
  public disabled: boolean = false;
  private timeHasBeenSet: boolean = false;
  private ngControl: NgControl;

  constructor(private readonly injector: Injector) {
  }

  public ngOnInit(): void {
    this.ngControl = this.injector.get(NgControl, null);
  }

  private onChange: (value: Date) => void;
  private onTouched: () => void;

  public writeValue(value: Date): void {
    this.dateTime = value;
  }

  public registerOnChange(fn: (value: Date) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public onTimeChange(timeControl: NgModel): void {
    this.timeHasBeenSet = true;
    this.dateTime?.setSeconds(0);
    this.setDate(this.dateTime);
  }

  public onDateChange(): void {
    if (!this.timeHasBeenSet) {
      const currentDate = new Date(this.dateTime);
      const currentTime = new Date();

      currentDate.setHours(currentTime.getHours(), currentTime.getMinutes(), currentTime.getSeconds());
    }

    this.setDate(this.dateTime);
  }

  public isInvalid(): boolean {
    return this.ngControl && this.ngControl.invalid;
  }

  private setDate(value: Date): void {
    this.dateTime = value;

    this.onChange(value);
    this.onTouched();
  }
}
