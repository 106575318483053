<div class="d-flex flex-column align-items-start gap-3">
  <button *ngFor="let attachment of attachments"
          type="button" class="btn btn-link text-left" (click)="open(attachment)">
    <div class="d-flex gap-2">
      <ng-container [ngTemplateOutlet]="iconTemplate"
                    [ngTemplateOutletContext]="{$implicit: attachment}"></ng-container>
      <span>{{ attachment.title }}</span>
    </div>
  </button>
</div>

<!--Templates-->
<ng-template #iconTemplate let-data>
  <ng-container [ngSwitch]="data.asset.mimeType">
    <ng-container *ngSwitchCase="MimeType.TEXT">
      <svg-icon src="asset/image/icon/file-type-txt.svg"></svg-icon>
    </ng-container>

    <ng-container *ngSwitchCase="MimeType.PDF">
      <svg-icon src="asset/image/icon/file-type-pdf.svg"></svg-icon>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <svg-icon src="asset/image/icon/download.svg"></svg-icon>
    </ng-container>
  </ng-container>
</ng-template>