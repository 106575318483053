import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { State } from 'app/common/State';
import { DataGridContext } from 'app/component/ui/dataGrid/DataGridContext';
import { ProgramAdminDetailsPageDTO } from 'app/data/dto/programs/ProgramAdminDetailsPageDTO';
import { StateUtil } from 'app/util/StateUtil';

@Component({
  selector: 'app-program-list-actions',
  templateUrl: './ProgramListActionsComponent.html',
  styleUrls: ['./ProgramListActionsComponent.scss'],
})
export class ProgramListActionsComponent implements ICellRendererAngularComp {
  public static readonly ADMIN_DETAILS: string = 'admin_details';
  public static readonly ADMIN_DELETE: string = 'admin_delete';
  public context: DataGridContext;
  public programAdminDetailsPage: ProgramAdminDetailsPageDTO;

  constructor(private stateUtil: StateUtil) {}

  public agInit(params: ICellRendererParams): void {
    this.context = params.context;
    this.programAdminDetailsPage = params.data as ProgramAdminDetailsPageDTO;
  }

  public refresh(params: ICellRendererParams): boolean {
    return false;
  }

  public onDetailsClickAdmin(): void {
    this.stateUtil.goToState(State.MAIN.ADMIN.PROGRAM.DETAILS, { id: this.programAdminDetailsPage.id });
  }

  public onDeleteClickAdmin(): void {
    this.context.dataGridAction(ProgramListActionsComponent.ADMIN_DELETE, [this.programAdminDetailsPage]);
  }
}
