import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { UIRouterGlobals } from '@uirouter/core';
import { State } from 'app/common/State';
import { HelpTabs } from 'app/data/enum/help/HelpTabs';
import { ApplicationModel } from 'app/model/ApplicationModel';
import { PortalUtil } from 'app/util/PortalUtil';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import { TemplatePortal } from '@angular/cdk/portal';

@Component({
  selector: 'app-help',
  templateUrl: './HelpComponent.html',
  styleUrls: [ './HelpComponent.scss' ]
})
export class HelpComponent implements OnInit, OnDestroy {
  @ViewChild('headingTemplate', { static: true })
  private readonly headingTemplate: TemplateRef<any>;

  public helpTabs: string[] = [ HelpTabs.FAQ, HelpTabs.TUTORIAL, HelpTabs.CONTACT ];
  public activeTab: string = HelpTabs.FAQ;
  public availableTabs: typeof HelpTabs = HelpTabs;

  constructor(private applicationModel: ApplicationModel,
              private uiRouterGlobals: UIRouterGlobals,
              private portalUtil: PortalUtil,
              private viewContainerRef: ViewContainerRef) {
  }

  public ngOnInit(): void {
    const currentStateName: string = this.uiRouterGlobals.current.name;

    this.applicationModel.selectSideBarItemWithState(
      currentStateName === State.MAIN.FAQ
        ? State.MAIN.FAQ
        : currentStateName === State.MAIN.HELP
          ? State.MAIN.HELP
          : null
    );

    if (currentStateName === State.MAIN.FAQ) {
      this.helpTabs = [ HelpTabs.FAQ, HelpTabs.TUTORIAL ];
    }

    this.portalUtil.attachPortalTo(
      MainLayoutComponent.PORTAL_OUTLET.HEADING,
      new TemplatePortal(this.headingTemplate, this.viewContainerRef)
    );
  }

  public ngOnDestroy(): void {
    this.portalUtil.detachPortalFrom(MainLayoutComponent.PORTAL_OUTLET.HEADING);
  }

  public isFaqRoute(): boolean {
    return this.uiRouterGlobals.current.name === State.MAIN.FAQ;
  }

  public onTabChanged(activeTab: string): void {
    this.activeTab = activeTab;
  }
}
