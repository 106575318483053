import { Component, Input } from '@angular/core';
import { Attachment } from 'app/data/local/attachment/Attachment';
import { StateUtil } from 'app/util/StateUtil';
import { MimeType } from 'app/data/local/attachment/MimeType';

@Component({
  selector: 'app-attachments',
  templateUrl: './AttachmentsComponent.html',
  styleUrls: [ './AttachmentsComponent.scss' ]
})
export class AttachmentsComponent {
  @Input() public attachments: Attachment[];

  public MimeType: typeof MimeType = MimeType;

  constructor(private readonly stateUtil: StateUtil) { }

  public open(attachment: Attachment): void {
    this.stateUtil.goToUrl(attachment.asset.url, true);
  }
}
