import { Type } from 'class-transformer';
import { CurrentUserProfileDTO } from '../user/CurrentUserProfileDTO';
import { CoachCertificateDTO } from './CertificateDTO';
import { CoachHobbyDTO } from './CoachHobbyDTO';
import { CoachEducationDTO } from './EducationDTO';

export class CoachProfileUpdateRequestDTO {
  bio?: string;

  @Type(() => CoachCertificateDTO)
  coachCertifications: CoachCertificateDTO[];

  @Type(() => CoachEducationDTO)
  coachEducations: CoachEducationDTO[];

  @Type(() => CoachHobbyDTO)
  coachHobbies: CoachHobbyDTO[];

  location: string;

  @Type(() => CurrentUserProfileDTO)
  user: CurrentUserProfileDTO;
}
