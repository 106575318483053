import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AgGridColumn } from 'ag-grid-angular';
import {
  AgGridEvent,
  Column,
  ColumnApi,
  ColumnState,
  GridApi,
  GridOptions,
  RowEvent,
  RowNode,
  SortChangedEvent
} from 'ag-grid-community';
import { Event } from 'app/common/Event';
import { DataGridApis } from 'app/component/ui/dataGrid/DataGridApis';
import { DataGridInterface } from 'app/component/ui/dataGrid/DataGridInterface';
import { BaseCriteriaSortOrder } from 'app/data/dto/BaseCriteriaDTO';
import { PageCriteriaDTO } from 'app/data/dto/PageCriteriaDTO';
import { SortDirection } from 'app/data/enum/SortDirection';
import { EventManager } from 'app/util/other/EventManager';
import * as $ from 'jquery';
import * as _ from 'lodash';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { DataGridAction } from './DataGridAction';
import { DataGridContext } from './DataGridContext';

@Component({
  selector: 'app-data-grid',
  templateUrl: 'DataGridComponent.html',
  styleUrls: [ 'DataGridComponent.scss' ]
})
export class DataGridComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy, DataGridInterface {
  private static readonly ITEMS_PER_PAGE_ALL_VALUE: number = 99999;

  public itemsPerPageAllValue: number = DataGridComponent.ITEMS_PER_PAGE_ALL_VALUE;

  public internalId: number = _.random(0, 10000);

  public internalPage: number = 1;

  public internalSearchPhrase: string;

  public internalItemsPerPage: number;

  private gridApi: GridApi;

  private columnApi: ColumnApi;

  public gridOptions: GridOptions;

  private gridOptionsTemplate: GridOptions = {
    domLayout: 'autoHeight',
    rowHeight: 0,
    headerHeight: 28,
    suppressAutoSize: true,
    suppressRowClickSelection: true,
    suppressRowDeselection: true,
    suppressCellSelection: true,
    suppressMovableColumns: true,
    suppressLoadingOverlay: true,
    suppressNoRowsOverlay: false,
    suppressColumnVirtualisation: true,
    rowSelection: 'single',
    localeTextFunc: this.translationFunction.bind(this),
    getRowStyle: this.getRowStyle.bind(this),
    onRowSelected: this.onRowSelected.bind(this),
    pagination: false,
    paginationPageSize: 8,
    defaultColDef: {
      sortable: false,
      resizable: false
    }
  };

  public gridOptionsReady: boolean = false;

  public gridVisible: boolean = false;

  public Math: Math = Math;

  private redrawBoundFunction: () => void;

  private onRowClickedBoundFunction: () => void;

  @Input()
  public data: any[];

  @Input()
  public criteria: PageCriteriaDTO;

  @Input()
  public context: DataGridContext;

  @Input()
  public options: GridOptions;

  @Input()
  public columns: AgGridColumn[];

  @Input()
  public pageTotal: number = 0;

  @Input()
  public itemTotal: number = 0;

  @Input()
  public itemLabel: string = 'COMPONENT.DATA_GRID.ITEMS';

  @Input()
  public selectionEnabled: boolean = false;

  @Input()
  public deselectionEnabled: boolean = false;

  @Input()
  public doubleClickEnabled: boolean = false;

  @Input()
  public multiSelectionEnabled: boolean = false;

  @Input()
  public useCheckboxSelection: boolean = false;

  @Input()
  public rowHeight: number = 50;

  @Input()
  public itemsPerPageOptions: number[] = [ 10, 25, 50, 100, DataGridComponent.ITEMS_PER_PAGE_ALL_VALUE ];

  @Input()
  public showFilters: boolean = true;

  @Input()
  public showSearch: boolean = true;

  @Input()
  public showItemsPerPage: boolean = true;

  @Input()
  public showTotalItems: boolean = true;

  @Input()
  public showPagination: boolean = true;

  @Input()
  public useAlternativePagination: boolean = false;

  @Input()
  public fitColumns: boolean = true;

  @Input()
  public selectedRows: any[] = [];

  @Output()
  public gridReady = new EventEmitter<DataGridApis>();

  @Output()
  public gridAction = new EventEmitter<any>();

  @Output()
  public itemSelected = new EventEmitter<any>();

  @Output()
  public itemsSelected = new EventEmitter<any[]>();

  @Output()
  public itemDoubleClicked = new EventEmitter<any>();

  @Output()
  public criteriaChanged = new EventEmitter<PageCriteriaDTO>();

  constructor(
    private translateService: TranslateService,
    private eventManager: EventManager
  ) {
  }

  public ngOnInit(): void {
    if (this.options) {
      this.gridOptions = _.merge(_.cloneDeep(this.gridOptionsTemplate), this.options);
    }
    else {
      this.gridOptions = this.gridOptionsTemplate;
    }

    const context: DataGridContext = new DataGridContext();
    context.dataGridHost = this;
    context.dataGridAction = this.sendGridAction.bind(this);

    if (this.context) {
      context.host = this.context.host;
      context.additionalData = _.cloneDeep(this.context.additionalData);
    }

    this.gridOptions.context = context;
    this.gridOptions.rowHeight = this.rowHeight;
    this.gridOptions.defaultColDef = this.gridOptions.defaultColDef ? this.gridOptions.defaultColDef : {};
    this.gridOptions.defaultColDef.comparator = (valueA: any, valueB: any): number => 0; // "remove" local sorting

    this.gridOptions.unSortIcon = this.gridOptions.defaultColDef.sortable ? true : null;
    this.gridOptions.suppressRowDeselection = !this.deselectionEnabled;
    this.gridOptions.rowSelection = this.selectionEnabled ? (this.multiSelectionEnabled ? 'multiple' : 'single') : null;
    this.gridOptions.suppressRowClickSelection = this.selectionEnabled
      ? this.useCheckboxSelection
      : true;

    this.redrawBoundFunction = this.onRedraw.bind(this);
    this.eventManager.on(Event.SYSTEM.REDRAW, this.redrawBoundFunction);

    this.onRowClickedBoundFunction = this.onRowClicked.bind(this);

    this.gridOptionsReady = true;
  }

  public ngAfterViewInit(): void {
    $(window).on('resize.datagrid' + this.internalId, () => {
      this.resizeGrid();
    });
  }

  public ngOnDestroy(): void {
    $(window).off('resize.datagrid' + this.internalId);
    this.eventManager.destroyListener(Event.SYSTEM.REDRAW, this.redrawBoundFunction);

    if (this.gridApi) {
      this.gridApi['eventService'].removeEventListener('rowClicked', this.onRowClickedBoundFunction, false); // private api call
    }

    this.redrawBoundFunction = null;
    this.onRowClickedBoundFunction = null;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      if (changes.data.currentValue === null) {
        this.data = [];
      }
      else {
        this.resizeGrid();
      }
    }

    if (changes.selectedRows) {
      const currentSelectedRows = changes.selectedRows.currentValue;
      this.updateGridSelection(currentSelectedRows);
    }

    if (changes.criteria?.currentValue) {
      this.internalPage = changes.criteria.currentValue.pageNumber + 1;
      this.internalItemsPerPage = changes.criteria.currentValue.pageSize;
      this.internalSearchPhrase = changes.criteria.currentValue.searchPhrase;
    }
  }

  private updateGridSelection(selectedRows: any[]): void {
    if (this.gridApi) {
      this.gridApi.forEachNode((node) => {
        node.setSelected(selectedRows.includes(node.data));
      });
    }
  }

  private onRedraw(): void {
    setTimeout(() => {
      this.gridApi.redrawRows();
    });

    setTimeout(() => {
      this.resizeGrid();
    }, 500);
  }

  private resizeGrid(): void {
    if (this.gridApi) {
      if (this.fitColumns) {
        this.gridApi.sizeColumnsToFit();
      }

      setTimeout(() => {
        this.addCustomCellAttributes();
      });

      this.gridApi.resetRowHeights();
    }
  }

  private addCustomCellAttributes(): void {
    this.columnApi.getAllColumns().forEach((column: Column) => {
      const cellElements: HTMLElement[] = document.querySelectorAll(
        `.ag-cell[col-id="${ column.getId() }"]`
      ) as unknown as HTMLElement[];

      cellElements.forEach((cell: HTMLElement) => {
        cell.dataset['header'] = column.getColDef().headerName;
      });
    });
  }

  public onGridReady(gridEvent: AgGridEvent): void {
    this.gridApi = gridEvent.api;
    this.columnApi = gridEvent.columnApi;

    const dataGridApis: DataGridApis = new DataGridApis();
    dataGridApis.gridApi = this.gridApi;
    dataGridApis.columnApi = this.columnApi;

    this.resizeGrid();
    this.gridReady.emit(dataGridApis);

    this.gridApi['eventService'].addEventListener('rowClicked', this.onRowClickedBoundFunction, false); // private api call

    setTimeout(() => {
      this.gridVisible = true;
    }, 200);
  }

  public onRowSelected(gridEvent: RowEvent): void {
    setTimeout(() => {
      this.gridApi.redrawRows();
    }, 0);

    if (gridEvent.node.isSelected() && this.selectionEnabled && !this.multiSelectionEnabled) {
      this.itemSelected.emit(gridEvent.data);
    }
  }

  public onRowClicked(gridEvent: RowEvent): void {
    if (
      gridEvent.node.isSelected() &&
      this.selectionEnabled &&
      !this.multiSelectionEnabled &&
      !this.gridOptions.suppressRowClickSelection
    ) {
      const selectedNodes: RowNode[] = this.gridApi.getSelectedNodes();
      if (selectedNodes && selectedNodes.length === 1 && selectedNodes[0].id === gridEvent.node.id) {
        setTimeout(() => {
          this.itemSelected.emit(gridEvent.data); // row re-selection, for some reason timeout is needed, to handle events properly (i.e. if handler instantly deselects)
        });
      }
    }
  }

  public onRowDoubleClicked(gridEvent: RowEvent): void {
    if (this.doubleClickEnabled) {
      this.itemDoubleClicked.emit(gridEvent.data);
    }
  }

  public onSortChanged(event: SortChangedEvent): void {
    const sortModel: {
      colId: string;
      sort: string;
    }[] = _.filter(
      _.map(event.columnApi.getColumnState(), (columnState: ColumnState) => ({
        colId: columnState.colId,
        sort: columnState.sort
      })),
      'sort'
    );

    if (sortModel.length === 1) {
      const newCriteria = _.cloneDeep(this.criteria);
      newCriteria.pageNumber = 0;

      let overrideSortField: string;
      let additionalSortFields: string[] = [];

      let sortOrders: BaseCriteriaSortOrder[] = [];
      let sortFields: string[] = [];
      let sortDirection: SortDirection = SortDirection.ASCENDING;

      if (sortModel[0].colId) {
        if (_.isNumber(parseInt(sortModel[0].colId, 10))) {
          const column: Column = event.columnApi.getColumn(sortModel[0].colId);
          sortModel[0].colId = column.getColDef().field;

          if (column.getColDef().refData && column.getColDef().refData['overrideSortField']) {
            overrideSortField = column.getColDef().refData['overrideSortField'];
          }

          if (column.getColDef().refData && column.getColDef().refData['additionalSortFields']) {
            additionalSortFields = column.getColDef().refData['additionalSortFields'].split(',');
          }
        }

        sortFields = [ overrideSortField ? overrideSortField : sortModel[0].colId ];
        sortFields = sortFields.concat(additionalSortFields);
      }

      if (sortModel[0].sort === SortDirection.ASCENDING || sortModel[0].sort === SortDirection.DESCENDING) {
        sortDirection = sortModel[0].sort;
      }

      _.forEach(sortFields, (sortField: string) => {
        sortOrders.push(`${ sortField } ${ sortDirection }`);
      });

      newCriteria.sortOrders = sortOrders;

      this.criteriaChanged.emit(newCriteria);
    }
    else if (sortModel.length === 0) {
      const newCriteria = _.cloneDeep(this.criteria);
      newCriteria.pageNumber = 0;
      newCriteria.sortOrders = null;

      this.criteriaChanged.emit(newCriteria);
    }
  }

  public onSelectionChanged(gridEvent: AgGridEvent): void {
    if (this.selectionEnabled && this.multiSelectionEnabled) {
      const selectedNodes: RowNode[] = this.gridApi.getSelectedNodes();
      const selectedItems: any[] = [];

      _.each(selectedNodes, (selectedNode: RowNode) => {
        selectedItems.push(selectedNode.data);
      });

      this.itemsSelected.emit(selectedItems);
    }
  }

  public onItemsPerPageChange(newValue: number): void {
    const newCriteria = _.cloneDeep(this.criteria);
    newCriteria.pageNumber = 0;
    newCriteria.pageSize = this.internalItemsPerPage;

    this.criteriaChanged.emit(newCriteria);
  }

  public onSearchCommitted(event: Event | KeyboardEvent): void {
    const newCriteria = _.cloneDeep(this.criteria);
    newCriteria.pageNumber = 0;
    newCriteria.searchPhrase = this.internalSearchPhrase;
    this.criteriaChanged.emit(newCriteria);
  }

  public onPageChanged(event: PageChangedEvent): void {
    const newPage: number = event.page;
    const newPageIndex: number = newPage - 1;

    const newCriteria = _.cloneDeep(this.criteria);
    newCriteria.pageNumber = newPageIndex;
    this.criteriaChanged.emit(newCriteria);
  }

  public sendGridAction(actionName: string, args: any[]): void {
    this.gridAction.emit(new DataGridAction(actionName, args));
  }

  public clearSearch(): void {
    const newCriteria = _.cloneDeep(this.criteria);
    newCriteria.pageNumber = 0;
    newCriteria.searchPhrase = undefined;
    this.criteriaChanged.emit(newCriteria);
  }

  public translationFunction(key: string, defaultValue: string): string {
    const translationKey: string = `COMPONENT.DATA_GRID.INTERNAL.${ key.toUpperCase() }`;
    const translation: string = this.translateService.instant(translationKey);

    if (translation === translationKey) {
      return defaultValue;
    }
    else {
      return translation;
    }
  }

  private getRowStyle({ data, node }): any {
    let style = { backgroundColor: '' };
    if (data.currentSeniorEnrollment && !data.currentSeniorEnrollment.dropped) {
      style.backgroundColor = '#EDF3E8!important';
    }
    if (node.isSelected()) {
      style.backgroundColor = 'dcf4c9 !important';
    }

    return style;
  }
}
