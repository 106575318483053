import { ApplicationConfigDefinition } from '../data/local/ApplicationConfigDefinition';
import { environment } from 'environment/environment';

export const ApplicationConfig: ApplicationConfigDefinition = {
  applicationOwner: 'Apzumi',
  applicationName: 'Lab UI',
  version: '0.2.67 (2024-08-14)',
  environmentName: environment.name,
  production: environment.production,
  apiUrl: environment.apiUrl,
  fileUrl: environment.fileUrl,
  authMode: environment.authMode,
  customOAuth2: {
    customOAuth2Url: environment.customOAuth2.customOAuth2Url,
    customOAuth2ClientId: environment.customOAuth2.customOAuth2ClientId,
    customOAuth2ClientSecret: environment.customOAuth2.customOAuth2ClientSecret
  },
  stripeConfiguration: {
    pricingTableId: environment.stripeConfiguration.pricingTableId,
    publishableKey: environment.stripeConfiguration.publishableKey
  },
  cognito: {
    cognitoClientId: environment.cognito.cognitoClientId,
    cognitoUserPoolId: environment.cognito.cognitoUserPoolId
  },
  defaultLanguage: environment.defaultLanguage,
  maxFileSize: environment.maxFileSize,
  websiteUrl: environment.websiteUrl,
  ipDataCoKey: environment.ipDataCoKey,
  tutorialId: environment.tutorialId,
  statisticsUrl: environment.statisticsUrl
};
