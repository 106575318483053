import { registerLocaleData } from '@angular/common';
import localePL from '@angular/common/locales/pl';
import localeEN from '@angular/common/locales/en-GB';
import { enGbLocale as localeEN_ngx_bootstrap, plLocale as localePL_ngx_bootstrap } from 'ngx-bootstrap/locale';
import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationConfig } from 'app/config/ApplicationConfig';
import { LanguageVersion } from 'app/data/local/LanguageVersion';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';

@Injectable({ providedIn: 'root' })
export class LangUtil {

  constructor(private translateService: TranslateService,
              private localeService: BsLocaleService) {
  }

  public initialize(): void {
    this.translateService.addLangs([ LanguageVersion.EN, LanguageVersion.PL ]);
    this.translateService.setDefaultLang(ApplicationConfig.defaultLanguage);
  }

  public setLanguage(language: LanguageVersion): void {
    switch (language) {
      case LanguageVersion.EN: {
        // ngx-translate
        this.translateService.use(LanguageVersion.EN);

        // angular
        registerLocaleData(localeEN, 'en-GB');

        // ngx-bootstrap
        defineLocale('en-gb', localeEN_ngx_bootstrap);
        this.localeService.use('en-gb');

        // moment
        moment.locale('en-gb');

        break;
      }
      case LanguageVersion.PL: {
        // ngx-translate
        this.translateService.use(LanguageVersion.PL);

        // angular
        registerLocaleData(localePL, 'pl');

        // ngx-bootstrap
        defineLocale('pl', localePL_ngx_bootstrap);
        this.localeService.use('pl');

        // moment
        moment.locale('pl');

        break;
      }
    }
  }
}
