import { VideoCategory } from 'app/data/enum/video/VideoCategory';
import { PageCriteriaDTO } from '../PageCriteriaDTO';

export class ProgramSeniorPageCriteriaDTO extends PageCriteriaDTO {
  public anyOfCategories: VideoCategory[];

  public anyOfCoachIds: number[];

  public favorites: boolean;

  public pageSize: number = 25;
}
