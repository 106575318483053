// import 'zone.js/plugins/zone-error';

import { LanguageVersion } from 'app/data/local/LanguageVersion';
import { EnvironmentType } from 'app/data/local/EnvironmentType';
import { AuthMode } from 'app/data/local/AuthMode';

export const environment = {
  name: EnvironmentType.DEV,
  production: true,
  mock: false,
  apiUrl: 'https://api.encore-dev.apzumi.dev/api',
  fileUrl: 'https://dev.lab-ui.com/files',
  authMode: AuthMode.COGNITO,
  customOAuth2: {
    customOAuth2Url: 'https://dev.lab-ui.com/oauth',
    customOAuth2ClientId: 'web-app',
    customOAuth2ClientSecret: 'secret'
  },
  stripeConfiguration: {
    pricingTableId: 'prctbl_1P9kx7Cs2D8j3uf7i6pkEViE',
    publishableKey: 'pk_test_51Ovi0JCs2D8j3uf7sGigsblTrRFcIZ1CkSVjdNCvdQBMxCTP0v7V3B1oedPktuCkwbhkYFPnwpJyh9gnTcJxm6vA00ESq8X1FM'
  },
  cognito: {
    cognitoClientId: '6bbljnp0r4c913nrsd8v3plaen',
    cognitoUserPoolId: 'ca-central-1_Ee3v9yOEG'
  },
  defaultLanguage: LanguageVersion.EN,
  maxFileSize: 10485760,   // 10MB
  websiteUrl: 'https://encore-dev.apzumi.dev/',
  ipDataCoKey: null,
  tutorialId: 917045523,
  statisticsUrl: 'https://lookerstudio.google.com/embed/reporting/b7686b7a-b27d-44ef-84be-239d91160372'
};
