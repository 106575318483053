import { FileUploader } from 'ng2-file-upload';
import * as _ from 'lodash';
import { FileUtil } from 'app/util/FileUtil';
import { Subscription } from 'rxjs';

export class UploadFile {

  public file: File | Blob;

  public fileDataUrl: string; // local file urls (i.e. image files created with URL.createObjectURL), to display directly

  public fileUploader: FileUploader;

  public uploadSubscription: Subscription;

  public uploadProgress: number;

  public dropOver: boolean;

  public uploading: boolean;

  public uploaded: boolean;

  public uploadError: boolean;

  public id: number;

  public reset(): void {
    this.file = null;
    this.fileDataUrl = null;
    this.uploadProgress = null;
    this.uploadSubscription = null;
    this.dropOver = false;
    this.uploading = false;
    this.uploaded = false;
    this.uploadError = false;
  }

  public get name(): string {
    if (this.file && (this.file instanceof File)) {
      return this.file.name;
    }
    else {
      return null;
    }
  }

  public get extension(): string {
    if (this.file && (this.file instanceof File)) {
      const lastIndexOfDot: number = this.file.name.lastIndexOf('.');
      if (lastIndexOfDot > -1) {
        const extension: string = this.file.name.substring(lastIndexOfDot + 1, this.file.name.length);
        return extension.toLowerCase();
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  public get isImage(): boolean {
    const extension: string = this.extension;

    if (_.includes(FileUtil.IMAGE_EXTENSIONS, extension)) {
      return true;
    }
    else {
      return false;
    }
  }

  public get isAudio(): boolean {
    const extension: string = this.extension;

    if (_.includes(FileUtil.AUDIO_EXTENSIONS, extension)) {
      return true;
    }
    else {
      return false;
    }
  }

  public get isVideo(): boolean {
    const extension: string = this.extension;

    if (_.includes(FileUtil.VIDEO_EXTENSIONS, extension)) {
      return true;
    }
    else {
      return false;
    }
  }

  public get isDoc(): boolean {
    const extension: string = this.extension;

    if (_.includes(FileUtil.DOC_EXTENSIONS, extension)) {
      return true;
    }
    else {
      return false;
    }
  }

  public get isPDF(): boolean {
    const extension: string = this.extension;

    if (_.includes(FileUtil.PDF_EXTENSIONS, extension)) {
      return true;
    }
    else {
      return false;
    }
  }

  public get isOther(): boolean {
    const extension: string = this.extension;

    if ((!_.includes(FileUtil.IMAGE_EXTENSIONS, extension)) &&
      (!_.includes(FileUtil.AUDIO_EXTENSIONS, extension)) &&
      (!_.includes(FileUtil.VIDEO_EXTENSIONS, extension)) &&
      (!_.includes(FileUtil.DOC_EXTENSIONS, extension)) &&
      (!_.includes(FileUtil.PDF_EXTENSIONS, extension))
    ) {
      return true;
    }
    else {
      return false;
    }
  }

}
