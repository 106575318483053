<div id="files" class="d-flex">
  <form #fileForm="ngForm" novalidate class="flex-grow-1">
    <div>{{ 'COMPONENT.FILE_UPLOAD.UPLOAD_VIDEO' | translate }}</div>
    <div *ngIf="!videoUploadStatus" id="upload-file" class="p-3">
      <input
        type="file"
        #fileModel="ngModel"
        id="fileModel"
        name="fileModel"
        class="custom-file-input"
        [(ngModel)]="uploadFile.file"
        (ngModelChange)="onFileChange($event)"
        [appFileAllowedExtensions]="allowedExtensions"
        [appFileMaxSize]="maxSize"
      />
      <div class="d-flex align-items-center gap-2">
        <div class="file-icon">
          <svg-icon src="asset/image/icon/file-upload 1.svg"></svg-icon>
        </div>
        <div class="d-flex flex-column align-items-center align-items-sm-start">
          <div class="underline">{{ 'COMPONENT.FILE_UPLOAD.CLICK_OR_DROP' | translate }}</div>
          <div class="max-size">{{ 'COMPONENT.FILE_UPLOAD.MAX_SIZE' | translate }} 250 GB</div>
        </div>
      </div>
      <div class="invalid-feedback d-block" *ngIf="fileForm.submitted && fileModel.invalid">
        <div *ngIf="fileModel.errors.fileMaxSize">
          {{
            'VALIDATION.FILE_SIZE_MAX' | translate:{
              maxSizeString: (fileModel.errors.fileMaxSize.requiredSize |
                filesize)
            }
          }}
        </div>
        <div *ngIf="fileModel.errors.fileAllowedExtensions">
          {{
            'VALIDATION.FILE_ALLOWED_EXTENSIONS' | translate:{
              extensions:
              fileModel.errors.fileAllowedExtensions.allowedExtensions
            }
          }}
        </div>
      </div>
    </div>
    <div *ngIf="videoUploadStatus" class="file-card p-3 mt-4" [class.upload-error]="videoUploadStatus.error">
      <div class="d-flex mb-2 align-items-center">
        <svg-icon src="asset/image/icon/file-upload 1.svg"></svg-icon>
        <div class="d-flex flex-column ml-4">
          <div>{{ uploadFile.name }}</div>
          <div>{{ uploadFile.file | fileSize }}</div>
        </div>
      </div>
      <div *ngIf="!videoUploadStatus.error" class="d-flex justify-content-between align-items-center">
        <div class="d-flex w-100 position-relative">
          <div class="file-progress" [style.width]="videoUploadStatus.progress + '%'"></div>
          <div class="file-progress-track"></div>
        </div>

        <div class="ml-1 text-monospace">{{ videoUploadStatus.progress }}%</div>
      </div>
      <div *ngIf="videoUploadStatus.error" class="d-flex justify-content-between align-items-center color-red">
        {{ 'VIEW.MAIN.ON_DEMAND.VIDEO_UPLOAD_ERROR' | translate }}
      </div>

      <button type="button" class="delete-btn">
        <svg-icon src="asset/image/icon/x.svg" role="button" (click)="cancelUpload()"></svg-icon>
      </button>
    </div>
  </form>
</div>
