import { Component, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { RouteTab } from 'app/component/ui/routeTabs/RouteTab';
import { State } from 'app/common/State';
import { PortalUtil } from 'app/util/PortalUtil';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import { TemplatePortal } from '@angular/cdk/portal';
import { FriendInvitationModel } from 'app/model/friend/FriendInvitationModel';
import { FriendsSentInvitationsComponent } from 'app/component/view/main/friends/component/sentInvitations/FriendsSentInvitationsComponent';

@Component({
  selector: 'app-friends-invitations',
  templateUrl: './FriendsInvitationsComponent.html'
})
export class FriendsInvitationsComponent implements OnInit {
  @ViewChild('headingTemplate', { static: true })
  private readonly headingTemplate: TemplateRef<any>;
  @ViewChild(FriendsSentInvitationsComponent)
  private readonly friendsSentInvitationsComponent: FriendsSentInvitationsComponent;

  public readonly tabs: RouteTab[] = [
    {
      name: 'VIEW.MAIN.FRIENDS.TABS.ALL',
      state: State.MAIN.SENIOR.FRIEND.LIST
    },
    {
      name: 'VIEW.MAIN.FRIENDS.TABS.PENDING',
      state: State.MAIN.SENIOR.FRIEND.INVITATIONS
    }
  ];

  constructor(private readonly portalUtil: PortalUtil,
              private readonly friendInvitationModel: FriendInvitationModel,
              private readonly viewContainerRef: ViewContainerRef) { }

  ngOnInit(): void {
    this.portalUtil.attachPortalTo(
      MainLayoutComponent.PORTAL_OUTLET.HEADING,
      new TemplatePortal(this.headingTemplate, this.viewContainerRef)
    );
  }

  public addFriend(): void {
    this.friendInvitationModel.invite().subscribe({
      next: () => {
        this.friendsSentInvitationsComponent.loadData();
      }
    });
  }

}
