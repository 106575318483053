import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import { Directive, OnInit } from '@angular/core';

export const fileRequiredValidator = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors => {
    return (!control.value || (control.value === null)) ? { required: true } : null;
  };
};

// this assumes the use of InputFileValueAccessorDirective, to properly determine whether the control has value and required condition satisfied or not
// (standard Angular 'required' would probably be ok too in this case, but this way, we can retain control, should we intend to modify it in the future)

@Directive({
  selector: '[appFileRequired][formControlName],[appFileRequired][formControl],[appFileRequired][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: FileRequiredValidator,
      multi: true
    }
  ]
})
export class FileRequiredValidator implements Validator, OnInit {

  private validator: ValidatorFn;

  public ngOnInit(): void {
    this.validator = fileRequiredValidator();
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    return this.validator(control);
  }
}
