<ng-template #headingTemplate>
  <h1>{{ 'VIEW.MAIN.ON_DEMAND.ON_DEMAND' | translate }}</h1>

  <div class="d-flex gap-4 flex-wrap">
    <button type="button" class="btn btn-outline-primary w-100 w-sm-auto" (click)="onAddNewClassClick()">
      {{ 'VIEW.MAIN.ON_DEMAND.CREATE_A_NEW_PROGRAM' | translate }}
    </button>

    <button type="button" class="btn btn-primary w-100 w-sm-auto" (click)="newVideo()">
      <svg-icon src="asset/image/icon/plus.svg"></svg-icon>
      <span>{{ 'VIEW.MAIN.ON_DEMAND.ADD_VIDEO' | translate }}</span>
    </button>
  </div>
</ng-template>

<section class="section">
  <app-tabs
    [translationPrefix]="'VIEW.MAIN.ON_DEMAND.TABS.'"
    [tabs]="tabs"
    (tabChanged)="onTabChanged($event)"
  ></app-tabs>
  <ng-container [ngSwitch]="activeTab">
    <ng-container *ngSwitchCase="VideoListTabsAdmin.PROGRAMS">
      <app-program-admin-list></app-program-admin-list>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <app-data-grid
        *ngIf="page"
        [data]="page.content"
        [criteria]="criteria"
        [context]="{ host: this }"
        [showFilters]="true"
        [showPagination]="true"
        [showItemsPerPage]="false"
        [showTotalItems]="false"
        [selectionEnabled]="true"
        [multiSelectionEnabled]="true"
        [useCheckboxSelection]="true"
        [pageTotal]="page.totalPages"
        [itemTotal]="page.totalElements"
        (gridAction)="onGridAction($event)"
        (itemsSelected)="onSelectionChange($event)"
        (criteriaChanged)="onCriteriaChanged($event)"
        [columns]="$any([
          { headerName: ('VIEW.MAIN.ON_DEMAND.NAME' | translate), cellClass: 'text-underline cell-fullwidth', headerClass: 'margin-l-50 pl-0', field: 'title', tooltipField: 'title', minWidth: 250, checkboxSelection: true },
          { headerName: ('VIEW.MAIN.ON_DEMAND.CATEGORY' | translate), field: 'category', valueFormatter: enumCategoryFormatter },
          { headerName: ('VIEW.MAIN.ON_DEMAND.LENGTH' | translate), field: 'duration', cellClass: 'text-right text-lg-left', valueFormatter: durationFormatter },
          { headerName: ('VIEW.MAIN.ON_DEMAND.INTENSITY' | translate), field: 'intensity', valueFormatter: enumIntensityFormatter },
          { headerName: ('VIEW.MAIN.ON_DEMAND.COACH' | translate), field: 'coaches', cellClass: 'text-right text-lg-left', valueFormatter: coachFormatter },
          { headerName: ('VIEW.MAIN.ON_DEMAND.VIEWS' | translate), field: 'viewCount', cellClass: 'd-none d-lg-inline-block' },
          { headerName: ('VIEW.MAIN.ON_DEMAND.RATING' | translate), minWidth: 140, field: 'rating', cellRendererFramework: StarRatingCellRendererComponent },
          { headerName: '', headerClass: 'header-right', minWidth: 130, maxWidth: 130, cellClass: 'cell-fullwidth', suppressKeyboardEvent: ActionCellRendererComponent.suppressKeyboardEvent, cellRendererFramework: ActionCellRendererComponent }
        ])"
      >
        <app-data-grid-filters>
          <div class="form-row space-y-3 space-y-md-0 mb-4 mb-lg-0">
            <div class="col-12 col-md-6 col-lg-auto">
              <ng-select
                id="sort-by"
                name="sort-by"
                [placeholder]="'COMMON.SORT_BY' | translate"
                [searchable]="false"
                [style.--icon]="'url(/asset/image/icon/arrows-sort.svg)'"
                (change)="onSortOptionChange($event)"
                [(ngModel)]="sortValue"
              >
                <ng-option *ngFor="let sortType of sortByOptions" [value]="sortType.value">
                  {{ sortType.label | translate }}
                </ng-option>
              </ng-select>
            </div> <!-- /.col -->

            <div class="col-12 col-md-6 col-lg-auto">
              <button
                id="filter-videos"
                name="filter-videos"
                class="btn btn-select d-flex justify-content-between align-items-center gap-2 w-100 w-lg-auto"
                (click)="openFiltersModal()"
              >
                <svg-icon src="asset/image/icon/filter.svg"></svg-icon>
                <span>{{ 'COMMON.FILTER_BY' | translate }}</span>
                <svg-icon src="asset/image/icon/chevron-down.svg"></svg-icon>
              </button>
            </div> <!-- /.col -->
          </div> <!-- /.form-row -->
        </app-data-grid-filters>
      </app-data-grid>
    </ng-container>
  </ng-container>
</section>

<app-on-demand-videos-bulk-actions
  [selectedVideos]="selectedItems"
  (deleteClicked)="onBulkDeleteClick()"
  (recommendedClick)="onBulkRecommendedClick()"
></app-on-demand-videos-bulk-actions>

