import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActiveCoachDTO } from 'app/data/dto/coach/ActiveCoachDTO';
import { VideoCategory } from 'app/data/enum/video/VideoCategory';
import { OptionItem } from 'app/data/local/generic/OptionItem';
import { ViewUtil } from 'app/util/ViewUtil';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { OnDemandVideoModel } from 'app/model/OnDemandVideoModel';

@Component({
  selector: 'app-program-filter-modal',
  templateUrl: './ProgramsFilterModalComponent.html',
  styleUrls: [ './ProgramsFilterModalComponent.scss' ]
})
export class ProgramsFilterModalComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  public static readonly FILTER_DATA: string = 'FILTER_DATA';
  public static readonly CLEAR_FILTERS: string = 'CLEAR_FILTERS';
  public anyOfCoachIds: number[];
  public anyOfCategories: string[];
  public categories: OptionItem<VideoCategory>[] = this.viewUtil.createEnumSelectOptions(
    VideoCategory,
    'VIDEO_CATEGORY'
  );

  public coaches: ActiveCoachDTO[];
  public filters = {
    categories: {
      selectedCategories: []
    },
    coaches: {
      selectedCoaches: []
    }
  };

  constructor(
    private viewUtil: ViewUtil,
    private bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private onDemandVideoModel: OnDemandVideoModel
  ) {
  }

  ngOnInit(): void {
    this.getCoachesList();

    if (this.anyOfCoachIds) {
      this.anyOfCoachIds.forEach((coachId) => {
        this.filters.coaches.selectedCoaches.push(coachId);
      });
    }
    if (this.anyOfCategories) {
      this.anyOfCategories.forEach((category) => {
        this.filters.categories.selectedCategories.push(category);
      });
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onClearFiltersClick(): void {
    this.filters.categories.selectedCategories = [];
    this.filters.coaches.selectedCoaches = [];
    this.modalService.setDismissReason(ProgramsFilterModalComponent.CLEAR_FILTERS);
    this.bsModalRef.hide();
  }

  public onShowFiltersClick(): void {
    this.modalService.setDismissReason(ProgramsFilterModalComponent.FILTER_DATA);
    this.bsModalRef.hide();
  }

  public onHideFiltersClick(): void {
    this.bsModalRef.hide();
  }

  private getCoachesList(): void {
    this.onDemandVideoModel.getActiveCoachList().subscribe((coaches) => {
      this.coaches = coaches;
    });
  }
}
