<div class="content">
  <div [formGroup]="form" class="row no-gutters">
    <div class="col-12 d-flex justify-content-center mb-6">
      <h1>{{ "VIEW.MAIN.ON_DEMAND.PROGRAM.ADD_TO_PROGRAM" | translate }}</h1>
    </div>
    <div class="col-12 d-flex justify-content-center mb-6">
      <span class="font-size-lg text-center">(2) videos selected</span>
    </div>
    <div class="col-12">
      <ng-container *ngFor="let program of programs">
        <ul class="circle-list">
          <li class="mb-6">{{ program.name }}</li>
        </ul>
      </ng-container>
    </div>
    <div class="col-12">
      <label for="video">{{ "VIEW.MAIN.ON_DEMAND.PROGRAM.ADD_TO_PROGRAM" | translate }}</label>
    </div>
    <div class="col mr-4">
      <div class="form-group mb-4">
        <ng-select
          id="video"
          name="video"
          [placeholder]="'VIEW.MAIN.ON_DEMAND.PROGRAM.CHOOSE_A_PROGRAM' | translate"
          [searchable]="false"
          class="with-placeholder-as-value"
          formControlName="program"
        >
          <ng-option *ngFor="let program of programs" [value]="program.value"
          >{{ program.label | translate }}
          </ng-option
          >
        </ng-select>
      </div>
    </div>
    <div class="col mb-6">
      <div class="form-group mb-4">
        <button class="btn btn-primary w-100">
          <span class="d-flex flex-row justify-content-center align-items-center">
            <span> {{ 'VIEW.MAIN.ON_DEMAND.PROGRAM.CREATE_A_PROGRAM' | translate }} </span>
          </span>
        </button>
      </div>
    </div>
    <div class="spacer mb-6"></div>
    <div class="col-4 mr-4 ml-auto">
      <button id="back-button" type="button" class="btn btn-outline-primary w-100">
        <span class="d-flex flex-row justify-content-center align-items-center">
          <svg-icon class="d-flex" src="asset/image/icon/chevron-left.svg"></svg-icon>
          <span>{{ 'COMMON.BACK' | translate }}</span>
        </span>
      </button>
    </div>
    <div class="col-4">
      <button (click)="onSaveClick()" id="back-button" type="button" class="btn btn-primary w-100">
        <span class="d-flex flex-row justify-content-center align-items-center">
          <span>{{ 'COMMON.SAVE' | translate }}</span>
        </span>
      </button>
    </div>
  </div>
</div>
