<ng-container [formGroup]="form">
  <h3 class="mb-4">{{ 'DOMAIN.WATCH_PARTY.DATA.TYPE.LABEL' | translate }}</h3>
  <div class="row">
    <div class="form-group col-12 col-sm-6">
      <ng-container *ngIf="form.get('type') as type">
        <div class="btn-group btn-tiles" btnRadioGroup formControlName="type"
             [ngClass]="{'is-invalid': submitted && type.invalid}">
          <label class="btn" [btnRadio]="WatchPartyType.PRIVATE">
            <var>{{ WatchPartyType.PRIVATE | prefix: 'ENUM.WATCH_PARTY_TYPE.' | translate }}</var>
          </label>

          <label class="btn" [btnRadio]="WatchPartyType.PUBLIC">
            <var>{{ WatchPartyType.PUBLIC | prefix: 'ENUM.WATCH_PARTY_TYPE.' | translate }}</var>
          </label>
        </div>

        <div class="invalid-feedback" *ngIf="submitted && type.invalid">
          <div *ngIf="type.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
        </div>
      </ng-container>
    </div>
  </div>

  <h3 class="mb-4">{{ 'DOMAIN.WATCH_PARTY.DETAILS' | translate }}</h3>

  <div class="row">
    <ng-container *ngIf="isCategoryFilterActive">
      <!--      Category-->
      <div class="col-12 col-sm-6 col-md-4">
        <div class="form-group" *ngIf="form.get('category') as category">
          <label for="category">{{ 'DOMAIN.WATCH_PARTY.DATA.CATEGORY.LABEL' | translate }}</label>
          <ng-select labelForId="category"
                     placeholder="{{'COMMON.CHOOSE' | translate}}"
                     formControlName="category"
                     [ngClass]="{'is-invalid': submitted && category.invalid, 'is-valid': submitted && category.valid}"
                     [searchable]="false">
            <ng-option *ngFor="let category of categories"
                       [value]="category.value">{{ category.label | translate }}
            </ng-option>
          </ng-select>

          <div class="invalid-feedback" *ngIf="submitted && category.invalid">
            <div *ngIf="category.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
          </div>
        </div>
      </div>

      <!--      Sub-category-->
      <div class="col-12 col-sm-6 col-md-4">
        <div class="form-group" *ngIf="form.get('subcategory') as subcategory">
          <label for="subcategory">{{ 'DOMAIN.WATCH_PARTY.DATA.SUBCATEGORY.LABEL' | translate }}</label>
          <ng-select labelForId="subcategory"
                     placeholder="{{'COMMON.CHOOSE' | translate}}"
                     formControlName="subcategory"
                     [ngClass]="{'is-invalid': submitted && subcategory.invalid, 'is-valid': submitted && subcategory.valid}"
                     [searchable]="false">
            <ng-option *ngFor="let subcategory of subcategories"
                       [value]="subcategory.value">{{ subcategory.label | translate }}
            </ng-option>
          </ng-select>

          <div class="invalid-feedback" *ngIf="submitted && subcategory.invalid">
            <div *ngIf="subcategory.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
          </div>
        </div>
      </div>
    </ng-container>

    <!--      Video-->
    <div class="col-12 col-sm-6 col-md-4">
      <div class="row">
        <div class="col-10 form-group" [class.w-75]="!isCategoryFilterActive" *ngIf="form.get('video') as video">
          <label for="video">{{ 'DOMAIN.WATCH_PARTY.DATA.VIDEO.LABEL' | translate }}</label>
          <ng-select labelForId="video"
                     placeholder="{{'COMMON.CHOOSE' | translate}}"
                     notFoundText="{{'COMMON.NO_DATA' | translate}}"
                     [items]="videos$ | async"
                     [virtualScroll]="true"
                     [loading]="videoLoading"
                     bindLabel="title"
                     formControlName="video"
                     [ngClass]="{'is-invalid': submitted && video.invalid, 'is-valid': submitted && video.valid}"
                     [searchable]="false"
                     (clear)="removeVideo()"
                     (scrollToEnd)="onScrollToEnd()">
          </ng-select>

          <div class="invalid-feedback" *ngIf="submitted && video.invalid">
            <div *ngIf="video.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
          </div>
        </div>
        <div class="col-2 form-group d-flex align-items-end px-0" *ngIf="!isCategoryFilterActive">
          <button type="button"
                  class="btn btn-warning"
                  (click)="showCategories()">{{ 'COMMON.REMOVE' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-sm-6 col-md-4">
      <ng-container *ngIf="form.get('type')?.value === WatchPartyType.PRIVATE">
        <div class="form-group" *ngIf="form.get('participants') as participants">
          <label for="participants">{{ 'DOMAIN.WATCH_PARTY.DATA.PARTICIPANTS.LABEL' | translate }}</label>
          <app-select-friend formControlName="participants"
                             [submitted]="submitted"
                             [ngClass]="{'is-invalid': submitted && participants.invalid, 'is-valid': submitted && participants.valid}"></app-select-friend>
          <div class="invalid-feedback" *ngIf="submitted && participants.invalid">
            <div *ngIf="participants.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
            <div *ngIf="participants.errors['maxlength']">{{ 'VALIDATION.MAXLENGTH_COLLECTION' | translate:{requiredLength: participants.errors.maxlength.requiredLength} }}</div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-lg-5">
      <!--    Date & Time-->
      <div class="form-group" *ngIf="form.get('startDate') as startDate">
        <app-date-time-picker formControlName="startDate"
                              dateLabel="DOMAIN.WATCH_PARTY.DATA.START_DATE.LABEL"
                              [submitted]="submitted"
                              [ngClass]="{'is-invalid': submitted && startDate.invalid, 'is-valid': submitted && startDate.valid}"
                              [minDate]="minDate"></app-date-time-picker>
        <div class="invalid-feedback" *ngIf="submitted && startDate.invalid">
          <div *ngIf="startDate.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
          <div *ngIf="startDate.errors['minDate']">{{ 'VALIDATION.DATETIME_MIN' | translate: {dateTimeString: startDate.errors['minDate'].value.toDateString()} }}</div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
