import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApplicationConfig } from 'app/config/ApplicationConfig';
import { ObjectUtil } from 'app/util/ObjectUtil';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UserRegistrationServiceInterface } from 'app/service/interface/UserRegistrationServiceInterface';
import { SeniorRegistrationDTO } from 'app/data/dto/registration/SeniorRegistrationDTO';
import { SeniorValidationRequestDTO } from 'app/data/dto/registration/SeniorValdiationRequestDTO';
import { SeniorValidationResponseDTO } from 'app/data/dto/registration/SeniorValidationResponseDTO';
import { OrganizationListDTO } from 'app/data/dto/registration/OrganizationListDTO';
import { OrganizationEssentialsDTO } from 'app/data/dto/registration/OrganizationEssentialsDTO';
import { UserConfirmRegistrationDTO } from 'app/data/dto/registration/UserConfirmRegistrationDTO';
import { RegistrationType } from 'app/data/enum/user/RegistrationType';

@Injectable({ providedIn: 'root' })
export class UserRegistrationService implements UserRegistrationServiceInterface {

  constructor(private http: HttpClient) {
  }

  public registerSenior(seniorRegistration: SeniorRegistrationDTO): Observable<unknown> {
    return this.http.post(`${ ApplicationConfig.apiUrl }/seniors/registrations`, ObjectUtil.classToPlain(seniorRegistration));
  }

  public validateSenior(seniorValidation: SeniorValidationRequestDTO): Observable<SeniorValidationResponseDTO> {
    return this.http.post(`${ ApplicationConfig.apiUrl }/seniors/registrations/validations`, ObjectUtil.classToPlain(seniorValidation))
      .pipe(
        map((response: any) => {
          const validationResult: SeniorValidationResponseDTO = ObjectUtil.plainToClass(SeniorValidationResponseDTO, response);
          return validationResult;
        })
      );
  }

  public getOrganizationsList(): Observable<OrganizationListDTO[]> {
    return this.http.get(`${ ApplicationConfig.apiUrl }/organizations/list`)
      .pipe(
        map((response: any) => {
          const organizations: OrganizationListDTO[] = ObjectUtil.plainToClassArray(OrganizationListDTO, response);
          return organizations;
        })
      );
  };

  public getOrganizationsDetails(organizationStub: string): Observable<OrganizationEssentialsDTO> {
    return this.http.get(`${ ApplicationConfig.apiUrl }/organizations/essentials/${ organizationStub }`)
      .pipe(
        map((response: any) => {
          const organizationDetails: OrganizationEssentialsDTO = ObjectUtil.plainToClass(OrganizationEssentialsDTO, response);
          return organizationDetails;
        })
      );
  }

  public confirmRegistration(username: string, registrationType: RegistrationType, confirmationCode: string): Observable<unknown> {
    const request: UserConfirmRegistrationDTO = {
      confirmationCode: confirmationCode,
      registrationType: registrationType
    };

    return this.http.post(`${ ApplicationConfig.apiUrl }/accounts/${ encodeURIComponent(username) }/confirmationCode`, request);
  }

}
