import { AfterViewInit, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SeniorService } from 'app/service/SeniorService';
import { ApplicationConfig } from 'app/config/ApplicationConfig';
import { BaseModal } from 'app/util/extension/BaseModal';

@Component({
  selector: 'app-membership-plans-modal',
  templateUrl: './MembershipPlansModalComponent.html'
})
export class MembershipPlansModalComponent extends BaseModal<void> implements AfterViewInit {
  @ViewChild('stripePlaceholder')
  public stripePlaceholderElement: ElementRef<any>;

  public title: string = 'VIEW.MAIN.ACCOUNT.BILLING_INFO.MODAL.MEMBERSHIP_PLAN.CHOOSE_PLAN';

  constructor(protected readonly bsModalRef: BsModalRef,
              private readonly renderer2: Renderer2,
              private readonly seniorService: SeniorService) {
    super();
  }

  ngAfterViewInit() {
    this.seniorService.getStripeClientSessionSecret()
      .subscribe((sessionSecret: string) => {
        const stripeWidget = this.renderer2.createElement('stripe-pricing-table');

        this.renderer2.setAttribute(stripeWidget, 'pricing-table-id', ApplicationConfig.stripeConfiguration.pricingTableId);
        this.renderer2.setAttribute(stripeWidget, 'publishable-key', ApplicationConfig.stripeConfiguration.publishableKey);
        this.renderer2.setAttribute(stripeWidget, 'customer-session-client-secret', sessionSecret);

        this.renderer2.appendChild(this.stripePlaceholderElement.nativeElement, stripeWidget);
      });
  }
}
