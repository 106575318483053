import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { EventManager } from 'app/util/other/EventManager';
import { Constant } from 'app/common/Constant';
import { ApplicationConfig } from 'app/config/ApplicationConfig';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { Duration } from 'moment';
import { Event } from 'app/common/Event';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {

  private static readonly DEBOUNCE_TIME: Duration = moment.duration(200, 'milliseconds');

  private requestCount: number = 0;
  private isLoading: boolean = false;

  constructor(private eventManager: EventManager) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if ((request.url.indexOf(ApplicationConfig.customOAuth2.customOAuth2Url) === -1) &&
      (request.url.indexOf(ApplicationConfig.apiUrl) === -1) &&
      (request.url.indexOf(ApplicationConfig.fileUrl) === -1)) {
      return next.handle(request);
    }
    else {
      let suppressLoading: boolean = request.context.get(Constant.HTTP_SUPPRESS_LOADING_TOKEN);

      if (suppressLoading) {
        // pass
      }
      else {
        this.requestCount++;
      }

      setTimeout(() => {
        if (this.requestCount > 0) {
          if (!this.isLoading) {
            this.isLoading = true;
            this.eventManager.broadcast(Event.SYSTEM.LOADING, true);
          }
        }
      }, LoadingInterceptor.DEBOUNCE_TIME.as('milliseconds'));

      return next.handle(request).pipe(
        tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if (suppressLoading) {
              // pass
            }
            else {
              this.requestCount--;
            }

            setTimeout(() => {
              if ((this.requestCount === 0) && (this.isLoading)) {
                this.isLoading = false;
                this.eventManager.broadcast(Event.SYSTEM.LOADING, false);
              }
            }, LoadingInterceptor.DEBOUNCE_TIME.as('milliseconds'));
          }
        }, (error: any) => {
          if (error instanceof HttpErrorResponse) {
            if (suppressLoading) {
              // pass
            }
            else {
              this.requestCount--;
            }

            setTimeout(() => {
              if ((this.requestCount === 0) && (this.isLoading)) {
                this.isLoading = false;
                this.eventManager.broadcast(Event.SYSTEM.LOADING, false);
              }
            }, LoadingInterceptor.DEBOUNCE_TIME.as('milliseconds'));
          }
        }));
    }
  }
}
