import { UserGender } from 'app/data/enum/account/UserGender';
import { DateUtil } from 'app/util/DateUtil';
import { Transform, Type } from 'class-transformer';
import { UserAddressDTO } from '../user/UserAddressDTO';
import { UserProfileDTO } from '../user/UserProfileDTO';

export class SeniorProfileDTO {
  @Type(() => UserAddressDTO)
  address: UserAddressDTO;

  @Transform(DateUtil.dateConversion)
  dateOfBirth: string;

  gender: UserGender;

  @Type(() => UserProfileDTO)
  user: UserProfileDTO;
}
