import { Injectable } from '@angular/core';
import { ApplicationConfig } from '../config/ApplicationConfig';
import { LookupResponse } from 'ipdata';
import * as URI from 'urijs';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class IpDataCoService {

  private static readonly IPDATA_CO_URL: string = 'https://api.ipdata.co';

  constructor(private http: HttpClient) {
  }

  public getIpData(): Observable<LookupResponse> {
    const ipDataCoUrl: URI = URI(IpDataCoService.IPDATA_CO_URL);
    ipDataCoUrl.addQuery('api-key', ApplicationConfig.ipDataCoKey);

    return this.http.get<LookupResponse>(ipDataCoUrl.href());
  }

}
