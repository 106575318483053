import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationConfig } from 'app/config/ApplicationConfig';
import { ImageCreateRequestDTO } from 'app/data/dto/images/ImageCreateRequestDTO';
import { ImageResponseDTO } from 'app/data/dto/images/ImageResponseDTO';
import { ObjectUtil } from 'app/util/ObjectUtil';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  constructor(private http: HttpClient) {
  }

  public createImages(imageCreateRequestDTO: ImageCreateRequestDTO): Observable<ImageResponseDTO> {
    return this.http
      .post(`${ ApplicationConfig.apiUrl }/images`, ObjectUtil.classToPlain(imageCreateRequestDTO))
      .pipe(map((response) => ObjectUtil.plainToClass(ImageResponseDTO, response)));
  }
}
