<ng-container *ngIf="currentUser?.isSenior">
  <div *ngIf="seniorClassDetails?.id"
       class="d-flex align-items-center justify-content-end gap-2 w-100 h-100"
       (click)="preventGridSelection($event)">
    <button type="button" class="btn btn-sm btn-light"
            *ngIf="isDetailsButtonAvailable"
            (click)="onDetailsClickUser()">
      <svg-icon src="asset/image/icon/eye.svg"></svg-icon>
      <span>{{ 'COMMON.DETAILS' | translate }}</span>
    </button>

    <div class="d-flex align-items-center gap-2">
      <button
        *ngIf="isJoinButtonAvailable"
        [disabled]="isJoinButtonDisabled"
        (click)="onJoinClick()"
        type="button"
        class="btn btn-sm btn-link">
        <svg-icon src="asset/image/icon/circle-chevron-right.svg"></svg-icon>
        <span>{{ 'COMMON.JOIN' | translate }}</span>
      </button>

      <button *ngIf="isDropButtonAvailable" (click)="onDropClick()" type="button" class="btn btn-sm btn-light">
        <svg-icon src="asset/image/icon/circle-off.svg"></svg-icon>
        <span>{{ 'COMMON.DROP' | translate }}</span>
      </button>

      <button
        *ngIf="isRegisterButtonAvailable"
        [disabled]="isRegisterButtonDisabled"
        (click)="onRegisterClick()"
        type="button"
        class="btn btn-sm btn-link"
      >
        <svg-icon src="asset/image/icon/circle-chevron-right.svg"></svg-icon>
        <span>{{ 'COMMON.REGISTER' | translate }}</span>
      </button>

      <button *ngIf="isWaitingListButtonAvailable"
              type="button"
              class="btn btn-sm btn-link"
              [disabled]="isWaitingListButtonDisabled"
              (click)="onJoinWaitingListClick()">
        <svg-icon src="asset/image/icon/circle-chevron-right.svg"></svg-icon>
        <span>Join waiting list</span>
      </button>

      <ng-container *ngIf="accessControlModel.isFullAccess$ | async">
        <button *ngIf="!seniorClassDetails.isFavorite"
                type="button"
                class="btn btn-sm btn-link"
                (click)="onFavoriteClick()">
          <svg-icon src="asset/image/icon/heart.svg"></svg-icon>
        </button>

        <button *ngIf="seniorClassDetails.isFavorite"
                type="button"
                class="btn btn-sm btn-link"
                (click)="onUnfavoriteClick()">
          <svg-icon src="asset/image/icon/heart-filled.svg"></svg-icon>
        </button>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="currentUser?.isAdmin">
  <div *ngIf="adminClassDetails.id"
       class="d-flex align-items-center justify-content-between w-100 h-100"
       (click)="preventGridSelection($event)">
    <button (click)="onDetailsClickAdmin()" type="button" class="btn btn-sm btn-light">
      <div class="d-flex flex-row align-items-center">
        <svg-icon class="d-flex mr-2" src="asset/image/icon/eye.svg"></svg-icon>

        <span>{{ 'COMMON.VIEW' | translate }}</span>
      </div>
    </button>

    <div class="btn-group d-none d-lg-inline-flex" dropdown container="body" placement="left bottom">
      <button
        id="button-dropdown"
        dropdownToggle
        type="button"
        class="btn btn-sm btn-light dropdown-toggle"
        aria-controls="dropdown-basic"
      >
        <div class="d-flex flex-row align-items-center">
          <svg-icon class="d-flex" src="asset/image/icon/dots-vertical.svg"></svg-icon>
        </div>
      </button>
      <div *dropdownMenu class="dropdown-menu p-3" role="menu" aria-labelledby="button-dropdown">
        <a role="menuitem" class="dropdown-item p-0 m-0 mb-4" (click)="onEditClick()">
          {{ 'COMMON.EDIT' | translate }}
        </a>
        <a role="menuitem" class="dropdown-item p-0 m-0" (click)="onDeleteClick()">
          {{ 'COMMON.DELETE' | translate }}
        </a>
      </div>
    </div> <!-- /.btn-group -->

    <div class="d-flex gap-2 d-lg-none">
      <button type="button" class="btn btn-sm btn-primary" (click)="onEditClick()">
        <span>{{ 'COMMON.EDIT' | translate }}</span>
      </button>

      <button type="button" class="btn btn-sm btn-danger" (click)="onDeleteClick()">
        <span>{{ 'COMMON.DELETE' | translate }}</span>
      </button>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="currentUser?.isCoach">
  <div (click)="preventGridSelection($event)" class="d-flex align-items-center justify-content-end w-100 h-100">
    <button (click)="onDetailsClickCoach()" type="button" class="btn btn-sm btn-light mr-2">
      <div class="d-flex flex-row align-items-center">
        <svg-icon class="d-flex mr-2" src="asset/image/icon/eye.svg"></svg-icon>
        <span>{{ 'COMMON.VIEW' | translate }}</span>
      </div>
    </button>

    <button
      *ngIf="activeTab === liveClassesTabs.MY_CLASSES"
      (click)="onJoinClickForCoach()"
      [disabled]="isJoinButtonDisabled"
      type="button"
      class="btn btn-sm btn-light-liveClass mr-2"
    >
      <div class="d-flex flex-row align-items-center">
        <svg-icon class="d-flex mr-2" src="asset/image/icon/circle-chevron-right.svg"></svg-icon>
        <span>{{ 'COMMON.JOIN' | translate }}</span>
      </div>
    </button>
  </div>
</ng-container>
