<div class="row no-gutters p-4 position-relative">
  <div class="mr-6">
    <img [src]="data.thumbnail.thumbnailAsset.url" alt=""/>
  </div>
  <div>
    <div>{{ data.title }}</div>
    <div>
      {{ data.videoCount }} <span *ngIf="data.videoCount > 1">{{ 'COMMON.VIDEOS' | translate }}</span
    ><span *ngIf="data.videoCount === 1">{{ 'COMMON.VIDEO' | translate }}</span>
    </div>
    <app-rating-stars *ngIf="data.rating >= Constant.STAR_RATING_MIN_VALUE" [value]="data.rating"></app-rating-stars>
  </div>
  <div class="position-absolute" [ngStyle]="{ 'top': 0, 'right': 0 }">
    <svg-icon class="d-flex btn m-0 p-0" (click)="onHideClick()" src="asset/image/icon/x.svg"></svg-icon>
  </div>
</div>
<div class="row no-gutters px-6">
  <div class="col mr-4">
    <div>{{ 'VIEW.MAIN.ON_DEMAND.PROGRAM.CATEGORY' | translate }}</div>
    <div>{{ 'ENUM.VIDEO_CATEGORY.' + data.category | translate }}</div>
  </div>
  <div class="col mr-4">
    <div>{{ 'VIEW.MAIN.ON_DEMAND.PROGRAM.COACH' | translate }}</div>
    <ng-container *ngFor="let coach of data.coaches">
      <div>{{ coach.user | fullName }}</div>
    </ng-container>
  </div>
  <div class="col">
    <div *ngIf="data.intensity !== IntensityLevel.NOT_APPLICABLE">
      {{ 'VIEW.MAIN.ON_DEMAND.PROGRAM.INTENSITY' | translate | uppercase }}
    </div>
    <div>
      <ng-container [ngSwitch]="data.intensity">
        <svg-icon
          class="fill"
          *ngSwitchCase="IntensityLevel.BEGINNER"
          src="asset/image/icon/intensity_1.svg"
        ></svg-icon>
        <svg-icon
          class="fill"
          *ngSwitchCase="IntensityLevel.INTERMEDIATE"
          src="asset/image/icon/intensity_2.svg"
        ></svg-icon>
        <svg-icon
          class="fill"
          *ngSwitchCase="IntensityLevel.ADVANCED"
          src="asset/image/icon/intensity_3.svg"
        ></svg-icon>
      </ng-container>
    </div>
  </div>
</div>

<div class="row no-gutters p-4">
  <div class="col">
    <div>{{ 'VIEW.MAIN.ON_DEMAND.PROGRAM.DESCRIPTION' | translate }}</div>
    {{ data.description }}
  </div>
</div>

<div class="spacer-light-grey"></div>

<div class="d-flex justify-content-end p-4">
  <button class="btn btn-light" (click)="onHideClick()">
    <span class="d-flex flex-row justify-content-center align-items-center">
      <svg-icon class="mr-2" src="asset/image/icon/chevron-left.svg"></svg-icon>
      <span>{{ 'COMMON.BACK' | translate }}</span>
    </span>
  </button>
  <button (click)="onPlayClick()" class="btn btn-primary">
    <span class="d-flex flex-row justify-content-center align-items-center">
      <svg-icon class="d-flex mr-2" src="asset/image/icon/player-play.svg"></svg-icon>
      <span>{{ 'COMMON.PLAY' | translate }}</span>
    </span>
  </button>
</div>
