<ng-template #headingTemplate>
  <h1>{{ 'VIEW.MAIN.LIVE_CLASSES.LIVE_CLASSES' | translate }}</h1>

  <div class="d-flex gap-4 flex-wrap">
    <button type="button"
            class="btn btn-outline-primary w-100 w-sm-auto"
            [uiSref]="State.MAIN.ADMIN.LIVE_CLASSES.SERIES.CREATE">
      <svg-icon src="asset/image/icon/plus.svg"></svg-icon>
      <span>{{ 'VIEW.MAIN.LIVE_CLASSES.SERIES.ADD_NEW' | translate }}</span>
    </button>

    <button class="btn btn-primary w-100 w-sm-auto" [uiSref]="State.MAIN.ADMIN.LIVE_CLASSES.SINGLE.CREATE">
      <svg-icon src="asset/image/icon/plus.svg"></svg-icon>
      <span>{{ 'VIEW.MAIN.LIVE_CLASSES.ADD_NEW_CLASS' | translate }}</span>
    </button>
  </div>
</ng-template>

<section class="section">
  <div class="d-flex">
    <app-route-tabs [routeTabs]="tabs" translationPrefix="ENUM.LIVE_CLASS_TYPE."></app-route-tabs>
  </div>

  <div *ngIf="page$ | async as page">
    <app-data-grid
      [data]="page.content"
      [criteria]="criteria"
      [context]="{ host: this, additionalData: { currentUser } }"
      [showFilters]="true"
      [showPagination]="true"
      [pageTotal]="page.totalPages"
      [itemTotal]="page.totalElements"
      [showItemsPerPage]="true"
      [showTotalItems]="false"
      [selectionEnabled]="true"
      [multiSelectionEnabled]="true"
      [useCheckboxSelection]="true"
      [selectedRows]="selectedItems"
      (gridAction)="onGridAction($event)"
      (itemsSelected)="onSelectionChange($event)"
      (criteriaChanged)="onCriteriaChanged($event)"
      [columns]="$any([
        { headerName: ('VIEW.MAIN.LIVE_CLASSES.CLASS' | translate), headerClass: 'margin-l-50 pl-0', field: 'title', tooltipField: 'title', minWidth: 180, checkboxSelection: checkboxSelectionFunction },
        { headerName: ('VIEW.MAIN.LIVE_CLASSES.SERIES.END_DATE' | translate), field: 'endDate', minWidth: 110, maxWidth: 140, cellClass: 'text-right text-lg-left', cellRendererFramework: DataGridTemplateCellRendererComponent, cellRendererParams: { ngTemplate: endDateTemplate } },
        { headerName: ('VIEW.MAIN.LIVE_CLASSES.SERIES.LEFT_CLASSES_SHORT' | translate), field: 'numberOfClassesLeft', minWidth: 100 },
        { headerName: ('VIEW.MAIN.LIVE_CLASSES.SERIES.DAYS' | translate), field: 'daysOfWeek', minWidth: 100, maxWidth: 140, cellClass: 'text-right text-lg-left', cellRendererFramework: DataGridTemplateCellRendererComponent, cellRendererParams: { ngTemplate: daysTemplate } },
        { headerName: ('VIEW.MAIN.LIVE_CLASSES.CATEGORY' | translate), field: 'category', minWidth: 100, maxWidth: 150, valueFormatter: enumCategoryFormatter },
        { headerName: ('VIEW.MAIN.LIVE_CLASSES.LENGTH' | translate), field: 'duration', minWidth: 70, maxWidth: 80, cellClass: 'text-right text-lg-left' },
        { headerName: ('VIEW.MAIN.LIVE_CLASSES.INTENSITY' | translate), field: 'intensity', minWidth: 85, maxWidth: 100, valueFormatter: enumIntensityFormatter },
        { headerName: ('VIEW.MAIN.LIVE_CLASSES.COACH' | translate), field: 'coaches', minWidth: 120, cellClass: 'text-right text-lg-left', valueFormatter: coachFormatter },
        { headerName: ('VIEW.MAIN.LIVE_CLASSES.RATING' | translate), field: 'rating', minWidth: 140, cellRendererFramework: StarRatingCellRendererComponent },
        { headerName: '', headerClass: 'header-right', minWidth: 150, maxWidth: 150, cellClass: 'cell-fullwidth', suppressKeyboardEvent: LiveClassesListActionCellRendererComponent.suppressKeyboardEvent, cellRendererFramework: LiveClassesListActionCellRendererComponent }
      ])"
    >
      <app-extra-control>
        <ng-select
          id="filter-classes"
          [placeholder]="'VIEW.MAIN.LIVE_CLASSES.FILTER_BY' | translate"
          [searchable]="false"
          [style.--icon]="'url(/asset/image/icon/filter.svg)'"
          (click)="showFilterClick()"
        >
        </ng-select>
      </app-extra-control>

      <app-selected-filters [criteria]="criteria"
                            [friendlyNames]="friendlyNames"
                            [getLabelForKeyValue]="getLabelForKeyValueBind"
                            (criteriaChange)="onCriteriaChanged($event)"></app-selected-filters>
    </app-data-grid>
  </div>
</section>

<app-live-classes-selected-action-bar
  [selectedClasses]="selectedItems"
  [adminMode]="true"
  (deleteClicked)="handleDeleteClick()"
></app-live-classes-selected-action-bar>


<!-- Grid Templates -->
<ng-template #endDateTemplate let-value="value">{{ value | date:'MM/dd/yyyy' | replaceEmpty }}</ng-template>
<ng-template #daysTemplate
             let-value="value">{{ value | days:'ENUM.DAYS_SHORT' | arrayJoin | replaceEmpty }}
</ng-template>