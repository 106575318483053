<ng-template #headingTemplate>
  <h1>{{ 'VIEW.MAIN.ON_DEMAND.PROGRAM.DETAILS' | translate }}</h1>

  <div class="d-flex flex-wrap gap-4">
    <button class="btn btn-outline-primary w-100 w-sm-auto" (click)="stateUtil.goBack()">
      <svg-icon class="d-flex" src="asset/image/icon/chevron-left.svg"></svg-icon>
      {{ 'COMMON.BACK' | translate }}
    </button>

    <ng-container *ngIf="currentUser.isAdmin">
      <button class="btn btn-outline-primary w-100 w-sm-auto" (click)="onDeleteClick()">
        {{ 'COMMON.DELETE' | translate }}
      </button>

      <button class="btn btn-primary w-100 w-sm-auto" (click)="onEditClick()">
        {{ 'COMMON.EDIT' | translate }}
      </button>
    </ng-container>
  </div>
</ng-template>

<section class="section">
  <div *ngIf="program" class="row no-gutters">
    <div class="col-12 mb-4">
      <div class="text-sm-light mb-4">{{ 'VIEW.MAIN.ON_DEMAND.PROGRAM.TITLE' | translate }}</div>
      <div>{{ program.title }}</div>
    </div>
    <div class="col-12 mb-4">
      <div class="text-sm-light mb-4">{{ 'VIEW.MAIN.ON_DEMAND.PROGRAM.RATING' | translate }}</div>
      <app-rating-stars [value]="program.rating"></app-rating-stars>
    </div>
    <div class="row no-gutters w-100">
      <div class="col mr-4 mb-4">
        <div class="text-sm-light mb-4">{{ 'VIEW.MAIN.ON_DEMAND.PROGRAM.VIDEOS' | translate }}</div>
        <ng-container *ngFor="let video of program.videoPrograms">
          <div class="custom-control custom-checkbox">
            <input
              type="checkbox"
              class="custom-control-input"
              [id]="'video' + video.video.id"
              [name]="'video' + video.video.id"
              disabled
              checked
            />
            <label class="custom-control-label" [for]="'video' + video.video.id">{{ video.video.title }}</label>
          </div>
        </ng-container>
      </div>
      <div class="col mb-4">
        <div class="text-sm-light mb-4">
          <div>{{ 'VIEW.MAIN.ON_DEMAND.PROGRAM.CATEGORY' | translate }}</div>
        </div>
        <div>{{ 'ENUM.VIDEO_CATEGORY.' + program.category | translate }}</div>
      </div>
    </div>
    <div class="row no-gutters w-100">
      <div class="col mr-4 mb-4">
        <div class="text-sm-light mb-4">
          <div>{{ 'VIEW.MAIN.ON_DEMAND.PROGRAM.COACH' | translate }}</div>
        </div>
        <ng-container *ngFor="let coach of program.coaches">
          <div>{{ coach.user | fullName }}</div>
        </ng-container>
      </div>
      <div class="col mb-4">
        <div class="text-sm-light mb-4">
          <div>{{ 'VIEW.MAIN.ON_DEMAND.PROGRAM.INTENSITY' | translate }}</div>
        </div>
        <div>{{ 'ENUM.INTENSITY_LEVEL.' + program.intensity | translate }}</div>
      </div>
    </div>
    <div class="col-12 mb-4">
      <div class="text-sm-light mb-4">{{ 'VIEW.MAIN.ON_DEMAND.PROGRAM.DESCRIPTION' | translate }}</div>
      <div>{{ program.description }}</div>
    </div>
    <div class="col-12 col-lg-9 mb-4">
      <div>{{ 'VIEW.MAIN.ON_DEMAND.PROGRAM.THUMBNAIL' | translate }}</div>
      <img [src]="program.thumbnail.defaultAsset.url" alt="thumbnail" class="img-fluid"/>
    </div>
  </div>
</section>
