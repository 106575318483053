import { Component } from '@angular/core';
import { Transition } from '@uirouter/core';
import { SeniorProfileResponseAdminDTO } from 'app/data/dto/senior/SeniorProfileResponseAdminDTO';
import { AdminModel } from 'app/model/AdminModel';
import { ViewUtil } from 'app/util/ViewUtil';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-user-details-profile',
  templateUrl: './UserDetailsProfileComponent.html',
  styleUrls: [ './UserDetailsProfileComponent.scss' ]
})
export class UserDetailsProfileComponent {
  public id: number;
  public user$: Observable<SeniorProfileResponseAdminDTO>;

  constructor(private readonly transition: Transition,
              private readonly adminModel: AdminModel,
              private readonly viewUtil: ViewUtil) {
    this.id = this.transition.params().id;
    this.user$ = this.adminModel.getSeniorDetails(this.id);
  }

  public onResendPasswordClick(): void {
    this.adminModel
      .resetPasswordForSeniorCreatedByAdmin(this.id)
      .subscribe({
        next: () => {
          this.viewUtil.showToastSuccess('VIEW.MAIN.ACCOUNT.UPDATE.SUCCESS');
        },
        error: (err) => {
          this.viewUtil.handleServerError(err);
        }
      });
  }
}
