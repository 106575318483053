<div class="d-flex align-items-center justify-content-between justify-content-lg-end w-100 h-100">
  <button (click)="onDetailsClickAdmin()" type="button" class="btn btn-sm btn-light mr-2">
    <div class="d-flex flex-row align-items-center">
      <svg-icon class="d-flex mr-2" src="asset/image/icon/eye.svg"></svg-icon>
      <span>{{ 'COMMON.VIEW' | translate }}</span>
    </div>
  </button>

  <button (click)="onDeleteClickAdmin()" type="button" class="btn btn-sm btn-light d-none d-lg-inline-block">
    <div class="d-flex flex-row align-items-center">
      <svg-icon class="d-flex stroke" src="asset/image/icon/trash-x.svg"></svg-icon>
    </div>
  </button>

  <button (click)="onDeleteClickAdmin()" type="button" class="btn btn-sm btn-danger d-lg-none">
    {{ 'COMMON.DELETE' | translate }}
  </button>
</div>
