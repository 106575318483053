<form #changePasswordForm="ngForm" class="d-flex flex-column gap-4 mb-6" novalidate>
  <div class="d-flex gap-4 flex-wrap justify-content-end mb-4 w-100">
    <button *ngIf="!editMode" class="btn btn-primary edit-btn" (click)="editMode = true">
      <div class="d-flex align-items-center justify-content-center">
        <svg-icon class="d-flex mr-2" src="asset/image/icon/edit.svg"></svg-icon>
        <span>{{ 'COMMON.EDIT' | translate }}</span>
      </div>
    </button>
    <button *ngIf="editMode" class="btn btn-outline-primary edit-btn" (click)="cancelEdit()">
      <div class="d-flex align-items-center justify-content-center">
        <svg-icon class="d-flex mr-2" src="asset/image/icon/chevron-left.svg"></svg-icon>
        <span>{{ 'COMMON.CANCEL' | translate }}</span>
      </div>
    </button>
    <button *ngIf="editMode" class="btn btn-primary edit-btn" (click)="changePassword()">
      <div class="d-flex align-items-center justify-content-center">
        <svg-icon class="d-flex mr-2" src="asset/image/icon/edit.svg"></svg-icon>
        <span>{{ 'COMMON.SAVE' | translate }}</span>
      </div>
    </button>
  </div>

  <div class="form-group">
    <label for="currentPassword">{{ 'VIEW.MAIN.ACCOUNT.CHANGE_PASSWORD.CURRENT_PASSWORD' | translate }}</label>
    <input
      type="password"
      id="currentPassword"
      name="currentPassword"
      #currentPassword="ngModel"
      class="form-control"
      placeholder="{{ 'VIEW.MAIN.ACCOUNT.CHANGE_PASSWORD.CURRENT_PASSWORD' | translate }}"
      [ngClass]="{'is-invalid': changePasswordForm.submitted && currentPassword.invalid, 'is-valid': changePasswordForm.submitted && currentPassword.valid }"
      [(ngModel)]="formData.currentPassword"
      required
      [disabled]="!editMode"
      appInputPasswordToggle
      autocomplete="current-password"
      appPasswordRequirements
    />
    <div class="invalid-feedback" *ngIf="changePasswordForm.submitted && currentPassword.invalid">
      <div *ngIf="currentPassword.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
      <div *ngIf="currentPassword.errors['invalid']">{{ 'VALIDATION.CURRENT_PASSWORD' | translate }}</div>
      <div *ngIf="currentPassword.errors?.passwordRequirements?.maxLength">
        {{ 'VALIDATION.PASSWORD_REQUIREMENTS.MAX_LENGTH' | translate: { max: Constant.PASSWORD_MAX_LENGTH_256 } }}
      </div>
    </div>
  </div>

  <div class="form-group">
    <label for="newPassword">{{ 'VIEW.MAIN.ACCOUNT.CHANGE_PASSWORD.NEW_PASSWORD' | translate }}</label>
    <input
      type="password"
      id="newPassword"
      name="newPassword"
      #newPassword="ngModel"
      class="form-control"
      placeholder="{{ 'VIEW.MAIN.ACCOUNT.CHANGE_PASSWORD.NEW_PASSWORD' | translate }}"
      [ngClass]="{'is-invalid': changePasswordForm.submitted && newPassword.invalid, 'is-valid': changePasswordForm.submitted && newPassword.valid }"
      [(ngModel)]="formData.newPassword"
      appPasswordRequirements
      required
      [pattern]="Constant.PASSWORD_PATTERN"
      [disabled]="!editMode"
      appInputPasswordToggle
      autocomplete="new-password"
    />
    <div class="invalid-feedback" *ngIf="changePasswordForm.submitted && newPassword.invalid">
      <div *ngIf="newPassword.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
      <div *ngIf="newPassword.errors['pattern']">
        {{ 'VALIDATION.PASSWORD' | translate: { passwordLength: Constant.PASSWORD_MIN_LENGTH } }}
      </div>
      <div *ngIf="newPassword.errors?.passwordRequirements?.maxLength">
        {{ 'VALIDATION.PASSWORD_REQUIREMENTS.MAX_LENGTH' | translate: { max: Constant.PASSWORD_MAX_LENGTH_256 } }}
      </div>
    </div>
  </div>

  <div class="password-requirements">
    <div
      class="password-req-item"
      [ngClass]="{'is-invalid': !newPassword.value || newPassword.errors?.passwordRequirements?.minLength}"
    >
      {{ 'VALIDATION.PASSWORD_REQUIREMENTS.MIN_LENGTH' | translate: { min: Constant.PASSWORD_MIN_LENGTH } }}
    </div>
    <div
      class="password-req-item"
      [ngClass]="{'is-invalid': !newPassword.value || newPassword.errors?.passwordRequirements?.upperCase}"
    >
      {{ 'VALIDATION.PASSWORD_REQUIREMENTS.UPPERCASE' | translate }}
    </div>
    <div
      class="password-req-item"
      [ngClass]="{'is-invalid': !newPassword.value || newPassword.errors?.passwordRequirements?.lowerCase}"
    >
      {{ 'VALIDATION.PASSWORD_REQUIREMENTS.LOWERCASE' | translate }}
    </div>
    <div
      class="password-req-item"
      [ngClass]="{'is-invalid': !newPassword.value || newPassword.errors?.passwordRequirements?.numberChar}"
    >
      {{ 'VALIDATION.PASSWORD_REQUIREMENTS.NUMBER' | translate }}
    </div>
    <div
      class="password-req-item"
      [ngClass]="{'is-invalid': !newPassword.value || newPassword.errors?.passwordRequirements?.specialChar}"
    >
      {{ 'VALIDATION.PASSWORD_REQUIREMENTS.SPECIAL' | translate }}
    </div>
  </div>

  <div class="form-group">
    <label for="newPasswordRepeat">{{ 'VIEW.MAIN.ACCOUNT.CHANGE_PASSWORD.REPEAT_NEW_PASSWORD' | translate }}</label>
    <input
      type="password"
      id="newPasswordRepeat"
      name="newPasswordRepeat"
      #newPasswordRepeat="ngModel"
      class="form-control"
      placeholder="{{ 'VIEW.MAIN.ACCOUNT.CHANGE_PASSWORD.REPEAT_NEW_PASSWORD' | translate }}"
      [ngClass]="{'is-invalid': changePasswordForm.submitted && newPasswordRepeat.invalid, 'is-valid': changePasswordForm.submitted && newPasswordRepeat.valid }"
      [(ngModel)]="formData.newPasswordRepeat"
      required
      [pattern]="Constant.PASSWORD_PATTERN"
      [equalTo]="newPassword.control"
      [disabled]="!editMode"
      appInputPasswordToggle
      autocomplete="new-password"
      appPasswordRequirements
    />
    <div class="invalid-feedback" *ngIf="changePasswordForm.submitted && newPasswordRepeat.invalid">
      <div *ngIf="newPasswordRepeat.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
      <div *ngIf="newPasswordRepeat.errors['pattern']">
        {{ 'VALIDATION.PASSWORD' | translate: { passwordLength: Constant.PASSWORD_MIN_LENGTH } }}
      </div>
      <div *ngIf="newPasswordRepeat.errors['equalTo']">{{ 'VALIDATION.PASSWORD_MATCH' | translate }}</div>
      <div *ngIf="newPasswordRepeat.errors?.passwordRequirements?.maxLength">
        {{ 'VALIDATION.PASSWORD_REQUIREMENTS.MAX_LENGTH' | translate: { max: Constant.PASSWORD_MAX_LENGTH_256 } }}
      </div>
    </div>
  </div>
</form>
