<div *ngIf="progressBarItems">
  <ng-container *ngIf="doubleBar">
    <div class="d-flex flex-sm-row flex-column align-items-center">
      <div class="progress-labels align-xs-start mr-2">
        <span> {{ progressBarItems[0].text }} {{ progressBarItems[0].value }}% </span>
      </div>
      <div class="progress flex-grow-1">
        <div
          *ngFor="let item of progressBarItems; let i = index"
          class="progress-bar"
          [ngClass]="{'bg-success': i > 0,'first-item': i === 0, 'second-item': i === 1}"
          role="progressbar"
          [ngStyle]="{'width': item.value + '%'}"
        ></div>
      </div>
      <div class="progress-labels align-xs-end ml-2">
        <span>{{ progressBarItems[1].value }}% {{ progressBarItems[1].text }} </span>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!doubleBar">
    <div class="progress">
      <div
        class="progress-bar"
        role="progressbar"
        [style.width]="progressBarItems[0]?.value + '%'"
        [attr.aria-valuenow]="progressBarItems[0]?.value"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
    <p class="font-size-sm mb-0">
      {{ progressBarItems[0]?.value }}/{{ progressBarItems[0]?.valueTo }} {{ progressBarItems[0].text }}
    </p>
  </ng-container>
</div>
