import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { StateService, Transition } from '@uirouter/core';
import { Constant } from 'app/common/Constant';
import { State } from 'app/common/State';
import { AdminModel } from 'app/model/AdminModel';
import { StateUtil } from 'app/util/StateUtil';
import { ViewUtil } from 'app/util/ViewUtil';
import { forkJoin, Subject, throwError } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { LiveClassSeriesDetailsResponseDTO } from 'app/data/dto/liveClassSeries/admin/LiveClassSeriesDetailsResponseDTO';
import { IntensityLevel } from 'app/data/enum/IntensityLevel';
import { PortalUtil } from 'app/util/PortalUtil';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import { TemplatePortal } from '@angular/cdk/portal';

@Component({
  selector: 'app-live-class-series-details-admin',
  templateUrl: './LiveClassSeriesDetailsAdminComponent.html',
  styleUrls: [ './LiveClassSeriesDetailsAdminComponent.scss' ]
})
export class LiveClassSeriesDetailsAdminComponent implements OnInit, OnDestroy {
  @ViewChild('headingTemplate', { static: true })
  private readonly headingTemplate: TemplateRef<any>;

  private destroy$: Subject<void> = new Subject<void>();
  public id: number;
  public liveClassSeries: LiveClassSeriesDetailsResponseDTO;

  public Constant: typeof Constant = Constant;
  public IntensityLevel: typeof IntensityLevel = IntensityLevel;

  constructor(private transition: Transition,
              private adminModel: AdminModel,
              private stateService: StateService,
              private viewUtil: ViewUtil,
              public stateUtil: StateUtil,
              private portalUtil: PortalUtil,
              private viewContainerRef: ViewContainerRef) {
    this.id = this.transition.params().id;
  }

  public ngOnInit(): void {
    this.portalUtil.attachPortalTo(
      MainLayoutComponent.PORTAL_OUTLET.HEADING,
      new TemplatePortal(this.headingTemplate, this.viewContainerRef)
    );

    forkJoin({
      liveClassSeries: this.adminModel.getLiveClassSeriesDetails(this.id)
    })
      .pipe(
        takeUntil(this.destroy$),
        tap(({ liveClassSeries }) => {
          this.liveClassSeries = liveClassSeries;
        }),
        catchError((err) => {
          this.viewUtil.handleServerError(err);
          return throwError(() => err);
        })
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.portalUtil.detachPortalFrom(MainLayoutComponent.PORTAL_OUTLET.HEADING);
  }

  public onBackClick(): void {
    history.back();
  }

  public onEditClick(): void {
    this.stateService.go(State.MAIN.ADMIN.LIVE_CLASSES.SERIES.EDIT, { id: this.id });
  }
}
