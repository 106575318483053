import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { ApplicationModel } from 'app/model/ApplicationModel';
import { Observable } from 'rxjs';
import { Constant } from 'app/common/Constant';

@Injectable()
export class SuppressInterceptor implements HttpInterceptor {

  // note: this is less reliable in general, because if it happens that more than one request is called in the time window between
  // applicationModel.suppressLoading/suppressErrors is changed (and the first request hasn't yet come back), then other requests
  // will also follow the suppression - so be aware of that and handle applicationModel.suppressLoading/suppressErrors only when you're sure
  // you're working with only one request during that time window.
  // Otherwise, use the standard route, and insert context tokens into the request directly, in its respective *Service class.

  constructor(public applicationModel: ApplicationModel) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.applicationModel.suppressLoading) {
      request.context.set(Constant.HTTP_SUPPRESS_LOADING_TOKEN, true);
    }
    if (this.applicationModel.suppressErrors) {
      request.context.set(Constant.HTTP_SUPPRESS_ERRORS_TOKEN, true);
    }

    return next.handle(request);
  }
}


