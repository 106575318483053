import { UserActivityFrequency } from 'app/data/enum/account/UserActivityFrequency';
import { UserHealth } from 'app/data/enum/account/UserHealth';
import { UserInterest } from 'app/data/enum/account/UserInterest';
import { UserPreferencesDTO } from './UserPreferencesDTO';
import { Type } from 'class-transformer';

export class SeniorPreferencesDTO {

  public seniorActivityFrequency: UserActivityFrequency;

  public seniorHealth: UserHealth;

  public seniorInterests: UserInterest[];

  @Type(() => UserPreferencesDTO)
  public user: UserPreferencesDTO;
}