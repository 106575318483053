import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { DataGridContext } from 'app/component/ui/dataGrid/DataGridContext';
import { StateUtil } from 'app/util/StateUtil';
import { ProgramCoachDetailsPageDTO } from 'app/data/dto/programs/ProgramCoachDetailsPageDTO';

@Component({
  selector: 'app-program-list-coach-actions',
  templateUrl: './ProgramCoachListActionsComponent.html',
  styleUrls: [ './ProgramCoachListActionsComponent.scss' ]
})
export class ProgramCoachListActionsComponent implements ICellRendererAngularComp {
  public static readonly ACTION = {
    SHOW_DETAILS: 'showDetails'
  } as const;

  public context: DataGridContext;
  public program: ProgramCoachDetailsPageDTO;

  constructor(private stateUtil: StateUtil) {}

  public agInit(params: ICellRendererParams): void {
    this.context = params.context;
    this.program = params.data as ProgramCoachDetailsPageDTO;
  }

  public refresh(params: ICellRendererParams): boolean {
    return false;
  }

  public showDetails(): void {
    this.context.dataGridAction(ProgramCoachListActionsComponent.ACTION.SHOW_DETAILS, [ this.program ]);
  }
}
