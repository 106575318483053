import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { State } from 'app/common/State';
import { PortalUtil } from 'app/util/PortalUtil';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import { TemplatePortal } from '@angular/cdk/portal';
import { Observable } from 'rxjs';
import { WatchPartyDTO } from 'app/data/dto/watchParty/WatchPartyDTO';
import { WatchPartyModel } from 'app/model/WatchPartyModel';
import { Transition } from '@uirouter/angular';
import { IntensityLevel } from 'app/data/enum/IntensityLevel';

@Component({
  selector: 'app-watch-party-details',
  templateUrl: './WatchPartyDetailsComponent.html',
  styleUrls: [ './WatchPartyDetailsComponent.scss' ]
})
export class WatchPartyDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('headingTemplate', { static: true })
  private readonly headingTemplate: TemplateRef<any>;

  public State: typeof State = State;
  public IntensityLevel: typeof IntensityLevel = IntensityLevel;

  public id: number = this.transition.params().id;
  public watchParty$: Observable<WatchPartyDTO> = this.watchPartyModel.getById(this.id);

  constructor(private readonly portalUtil: PortalUtil,
              public readonly watchPartyModel: WatchPartyModel,
              private readonly transition: Transition,
              private readonly viewContainerRef: ViewContainerRef) { }

  public ngOnInit(): void {
    this.portalUtil.attachPortalTo(
      MainLayoutComponent.PORTAL_OUTLET.HEADING,
      new TemplatePortal(this.headingTemplate, this.viewContainerRef)
    );
  }

  public ngOnDestroy(): void {
    this.portalUtil.detachPortalFrom(MainLayoutComponent.PORTAL_OUTLET.HEADING);
  }
}
