<app-content-and-image-column-layout
  *ngIf="userRegistration && organizationEssentials"
  [imageSrc]="'asset/image/register-background.jpg'"
>
  <div id="content" class="p-4 d-flex flex-column">
    <img class="logo" src="asset/image/logo.png" alt="Logo"/>
    <img
      *ngIf="organizationEssentials.logoUrl; else brandName"
      class="brand-logo"
      [src]="organizationEssentials.logoUrl"
    />
    <!-- <ng-template #brandName>
      <h1 class="brand-name">{{organizationEssentials.name}}</h1>
      <img class="img-fluid" src="asset/image/logo/RenewActive.png"/>
    </ng-template> -->
    <ng-template #brandName>
      <div class="d-flex justify-content-center align-items-center">
        <img
          *ngIf="organizationEssentials.name === 'Renew Active'"
          class="img-fluid mb-12"
          [style.max-width.px]="400"
          src="../../../../../../asset/image/logo/renew-active.png"
        />
        <img
          *ngIf="organizationEssentials.name === 'One Pass'"
          class="img-fluid mb-12"
          [style.max-width.px]="400"
          src="../../../../../../asset/image/logo/one-pass.png"
        />
      </div>
      <!-- <h1 *ngIf="organizationEssentials.name === 'One Pass'" class="brand-name">{{organizationEssentials.name}}</h1> -->
    </ng-template>
    <div class="d-flex justify-content-between w-100">
      <h4 class="mr-2">{{ 'VIEW.PRELIMINARY.REGISTRATION.GETTING_STARTED' | translate }}</h4>
      <h4 *ngIf="currentStep && noOfSteps">step {{ currentStep }} of {{ noOfSteps }}</h4>
    </div>
    <h1 class="mb-6">{{ 'VIEW.PRELIMINARY.REGISTRATION.CREATE_ACCOUNT' | translate }}</h1>

    <form
      #registrationForm="ngForm"
      class="d-flex flex-column"
      novalidate
      (keyup.enter)="validateUserAndGoToNextStep()"
    >
      <div class="form-group">
        <label for="email">{{ 'VIEW.PRELIMINARY.REGISTRATION.EMAIL' | translate }}</label>
        <input
          type="email"
          id="email"
          name="email"
          #email="ngModel"
          class="form-control"
          placeholder="{{ 'VIEW.PRELIMINARY.REGISTRATION.EMAIL' | translate }}"
          [ngClass]="{'is-invalid': registrationForm.submitted && email.invalid, 'is-valid': registrationForm.submitted && email.valid }"
          [(ngModel)]="userRegistration.user.email"
          required
          ngvemail
          [appMaxLength]="Constant.TEXT_MAX_LENGTH_150"
        />
        <div class="invalid-feedback" *ngIf="registrationForm.submitted && email.invalid">
          <div *ngIf="email.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
          <div *ngIf="email.errors['email']">{{ 'VALIDATION.EMAIL' | translate }}</div>
          <div *ngIf="email.errors['maxLength']">
            {{ 'VALIDATION.CHAR_MAX' | translate: { max: Constant.TEXT_MAX_LENGTH_150 } }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="memberCode">{{ 'VIEW.PRELIMINARY.REGISTRATION.MEMBER_CODE' | translate }}</label>
        <input
          type="text"
          id="memberCode"
          name="memberCode"
          #memberCode="ngModel"
          class="form-control"
          placeholder="{{ 'VIEW.PRELIMINARY.REGISTRATION.MEMBER_CODE' | translate }}"
          [ngClass]="{'is-invalid': registrationForm.submitted && memberCode.invalid, 'is-valid': registrationForm.submitted && memberCode.valid }"
          [(ngModel)]="userRegistration.memberCode"
          required
          appMemberCodeValidator
        />
        <div class="invalid-feedback" *ngIf="registrationForm.submitted && memberCode.invalid">
          <div *ngIf="memberCode.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
          <div *ngIf="memberCode.errors['memberCode']">{{ 'VALIDATION.MEMBER_CODE' | translate }}</div>
        </div>
      </div>
    </form>
    <div class="d-flex flex-wrap justify-content-between mt-6">
      <div class="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center w-100 w-sm-auto">
        {{ 'VIEW.PRELIMINARY.REGISTRATION.ALREADY_HAVE_ACCOUNT' | translate }}
        <button class="btn btn-light login-btn" (click)="backToLogin()">
          {{ 'VIEW.PRELIMINARY.LOGIN.LOGIN' | translate }}
        </button>
      </div>

      <button id="next-btn" class="btn btn-primary" (click)="validateUserAndGoToNextStep()">
        <div class="d-flex flex-row align-items-center justify-content-center">
          <span>{{ 'COMMON.NEXT' | translate }}</span>
          <svg-icon class="d-flex" src="asset/image/icon/chevron-right.svg"></svg-icon>
        </div>
      </button>
    </div>
  </div>
</app-content-and-image-column-layout>
