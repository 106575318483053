import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isFile'
})
export class IsFilePipe implements PipeTransform {

  transform(value: File | Blob): File | null {
    if (value instanceof File) {
      return value;
    }
    return null;
  }

}
