<div class="form-group">
  <label for="internal">Internal value</label>
  <input type="text" id="internal" name="internal"
         #internal="ngModel" [(ngModel)]="data.internal"
         [ngClass]="{'is-invalid': form.submitted && internal.invalid, 'is-valid': form.submitted && internal.valid }"
         class="form-control" required>
  <div class="invalid-feedback" *ngIf="internal.invalid">
    <div *ngIf="internal.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
  </div>
</div>

