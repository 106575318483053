import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { StateService } from '@uirouter/core';
import { ValueFormatterParams } from 'ag-grid-community';
import { State } from 'app/common/State';
import { DataGridAction } from 'app/component/ui/dataGrid/DataGridAction';
import { DataGridEmailCellRendererComponent } from 'app/component/ui/dataGrid/cellRenderer/DataGridEmailCellRendererComponent';
import { PopupConfirmationComponent } from 'app/component/ui/popup/PopupConfirmationComponent';
import { UserListActionsCellRendererComponent } from 'app/component/view/main/user/components/cellRenderer/UserListActionsCellRendererComponent';
import { BaseCriteriaSortOrder } from 'app/data/dto/BaseCriteriaDTO';
import { PageCriteriaDTO } from 'app/data/dto/PageCriteriaDTO';
import { PageDTO } from 'app/data/dto/PageDTO';
import { SeniorDetailsPageDTO } from 'app/data/dto/senior/SeniorDetailsPageDTO';
import { SeniorPageCriteriaDTO } from 'app/data/dto/senior/SeniorPageCriteriaDTO';
import { UserDTO } from 'app/data/dto/user/UserDTO';
import { SortDirection } from 'app/data/enum/SortDirection';
import { UserListTabs } from 'app/data/enum/userList/UserListTabs';
import { UserSortBy } from 'app/data/enum/userList/UserSortBy';
import { OptionItem } from 'app/data/local/generic/OptionItem';
import { AdminModel } from 'app/model/AdminModel';
import { UserModel } from 'app/model/UserModel';
import { ViewUtil } from 'app/util/ViewUtil';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, filter, switchMap, tap } from 'rxjs/operators';
import { UsersSelectedActionBarComponent } from 'app/component/view/main/user/components/actionBar/UsersSelectedActionBarComponent';
import { SubscriptionStoreComponent } from 'app/component/SubscriptionStoreComponent';
import { PortalUtil } from 'app/util/PortalUtil';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import { TemplatePortal } from '@angular/cdk/portal';
import { DataGridTemplateCellRendererComponent } from 'app/component/ui/dataGrid/cellRenderer/DataGridTemplateCellRendererComponent';

@Component({
  selector: 'app-user-list',
  templateUrl: 'UserListComponent.html',
  styleUrls: [ 'UserListComponent.scss' ]
})
export class UserListComponent extends SubscriptionStoreComponent implements OnInit, OnDestroy {
  @ViewChild('headingTemplate', { static: true })
  private readonly headingTemplate: TemplateRef<any>;

  private _activeTab: string;

  private loadData: Subject<SeniorPageCriteriaDTO> = new Subject<SeniorPageCriteriaDTO>();

  public currentUser: UserDTO;
  public readonly tabs: UserListTabs[] = [ UserListTabs.ACTIVE, UserListTabs.INACTIVE ];

  public selectedUsers: SeniorDetailsPageDTO[] = [];

  public page$: Observable<PageDTO<SeniorDetailsPageDTO>> = this.loadData.asObservable().pipe(
    debounceTime(300),
    switchMap((criteria: SeniorPageCriteriaDTO) => this.adminModel.getSeniorsPage(criteria))
  );

  public page: PageDTO<SeniorDetailsPageDTO>;

  public criteria: SeniorPageCriteriaDTO = new SeniorPageCriteriaDTO();

  public DataGridTemplateCellRendererComponent: typeof DataGridTemplateCellRendererComponent =
    DataGridTemplateCellRendererComponent;

  public DataGridEmailCellRendererComponent: typeof DataGridEmailCellRendererComponent =
    DataGridEmailCellRendererComponent;

  public UserListActionsCellRendererComponent: typeof UserListActionsCellRendererComponent =
    UserListActionsCellRendererComponent;

  public sortValue: string;
  public sortByOptions: OptionItem<string>[] = this.viewUtil.createEnumSelectOptions(UserSortBy, 'USER_SORT_BY');

  public dateValueFormatter: (params: ValueFormatterParams) => any = (params: ValueFormatterParams) => {
    const date: string = this.viewUtil.dataGridPipeValueFormatter(params, DatePipe, 'MM/dd/yyyy');
    return `${ date }`;
  };

  public get activeTab(): string {
    return this._activeTab;
  }

  public set activeTab(value: string) {
    this._activeTab = value;
    this.criteria.userEnabledEq = value === UserListTabs.ACTIVE;
  }

  public get actionBarMode(): keyof typeof UsersSelectedActionBarComponent.MODE {
    if (this.activeTab === UserListTabs.ACTIVE) {
      return UsersSelectedActionBarComponent.MODE.DEACTIVATE;
    }
    else {
      return UsersSelectedActionBarComponent.MODE.ACTIVATE;
    }
  }

  constructor(private stateService: StateService,
              private modalService: BsModalService,
              public viewUtil: ViewUtil,
              private userModel: UserModel,
              private adminModel: AdminModel,
              private portalUtil: PortalUtil,
              private viewContainerRef: ViewContainerRef) {
    super();

    this.currentUser = userModel.currentUser;
  }

  public ngOnInit(): void {
    this.portalUtil.attachPortalTo(
      MainLayoutComponent.PORTAL_OUTLET.HEADING,
      new TemplatePortal(this.headingTemplate, this.viewContainerRef)
    );

    this.activeTab = UserListTabs.ACTIVE;

    this.subscription = this.page$.subscribe((result: PageDTO<SeniorDetailsPageDTO>) => {
      this.page = result;
    });


    this.refreshData();
  }

  public ngOnDestroy(): void {
    this.portalUtil.detachPortalFrom(MainLayoutComponent.PORTAL_OUTLET.HEADING);
  }

  public goToUserDetails(id: number): void {
    this.stateService.go(State.MAIN.ADMIN.USER.DETAILS, { id });
  }

  public activateSeniors(ids: number[]): void {
    this.subscription = this.adminModel.activateSeniors(ids)
      .pipe(
        tap(() => {
          this.viewUtil.showToastSuccess('COMMON.SUCCESS');
          this.refreshData();
        }),
        catchError((err) => {
          this.viewUtil.handleServerError(err);
          return of(null);
        })
      )
      .subscribe();
  }

  public deactivateSeniors(senior: SeniorDetailsPageDTO, ids?: number[], names?: string): void {
    const name: string = senior?.user ? senior.user.fullName : names;
    const id: number[] = senior?.id ? [ senior.id ] : ids;

    const modal: BsModalRef = this.modalService.show(PopupConfirmationComponent, {
      initialState: {
        message: 'VIEW.MAIN.USER.DEACTIVATE_WARNING',
        messageVariables: { user: name },
        okText: 'COMMON.YES',
        cancelText: 'COMMON.NO'
      },
      class: 'modal-dialog-centered'
    });

    this.subscription = modal.onHide
      .pipe(
        filter((reason) => reason === PopupConfirmationComponent.POPUP_RESULT_CONFIRM),
        switchMap(() => this.adminModel.deactivateSeniors(id)),
        tap(() => {
          this.viewUtil.showToastSuccess('COMMON.SUCCESS');
          this.refreshData();
        }),
        catchError((err) => {
          this.viewUtil.handleServerError(err);
          return of(null);
        })
      )
      .subscribe();
  }

  public createUser(): void {
    this.stateService.go(State.MAIN.ADMIN.USER.CREATE);
  }

  public onTabChanged(tabName: string) {
    if (tabName === this.activeTab) {
      return;
    }

    this.activeTab = tabName;
    this.onCriteriaChanged(this.criteria);
  }

  public onCriteriaChanged(criteria: PageCriteriaDTO): void {
    this.criteria = criteria as SeniorPageCriteriaDTO;

    this.refreshData();
  }

  public onSelectionChange(selectedUsers: SeniorDetailsPageDTO[]): void {
    this.selectedUsers = selectedUsers;
  }

  public onSortOptionChange(sortBy: UserSortBy): void {
    if (!sortBy) {
      delete this.criteria.sortOrders;
      this.refreshData();

      return;
    }
    let sortOrders: BaseCriteriaSortOrder;
    let sortField: string;
    let sortDirection: SortDirection = SortDirection.ASCENDING;
    switch (sortBy) {
      case UserSortBy.MOST_RECENT: {
        sortField = 'id';
        sortDirection = SortDirection.DESCENDING;
        break;
      }
      case UserSortBy.ALPHABETIC: {
        sortField = 'user.lastName';
        break;
      }
    }
    sortOrders = `${ sortField } ${ sortDirection }`;
    this.criteria.sortOrders = [ sortOrders ];
    this.refreshData();
  }

  public checkboxSelectionFunction(TData: any, _TValue: any): boolean {
    const { data } = TData;
    return data.id !== null && data.name !== null;
  }

  public onGridAction(action: DataGridAction): void {
    const user: SeniorDetailsPageDTO = action.args[0] as SeniorDetailsPageDTO;
    const { id } = user;
    if (action.actionName === UserListActionsCellRendererComponent.ACTION_DETAILS_USER) {
      this.goToUserDetails(id);
    }
    if (action.actionName === UserListActionsCellRendererComponent.ACTION_DELETE_USER) {
      this.deactivateSeniors(user);
    }
  }

  public toggleUserStatus(mode: keyof typeof UsersSelectedActionBarComponent.MODE): void {
    const ids: number[] = this.selectedUsers.map((user) => user.id);
    const names: string = this.selectedUsers.map((user) => user.user.fullName).join(', ');
    this.selectedUsers = [];

    if (mode === UsersSelectedActionBarComponent.MODE.ACTIVATE) {
      this.activateSeniors(ids);
    }
    else {
      this.deactivateSeniors(null, ids, names);
    }
  }

  private refreshData(): void {
    this.loadData.next(this.criteria);
  }
}
