<div id="layout" [ngClass]="{ 'sidebar-active': sideBarActive }" ngxUiLoaderBlurred (mousedown)="onMouseDown($event)">
  <div id="header">
    <div class="d-flex flex-row align-items-center justify-content-between h-100 w-100 px-4">
      <img id="logo" src="asset/image/logo-white.svg" alt="Logo" (click)="stateUtil.goToDashboard()"/>
      <button
        id="sidebar-toggler"
        class="navbar-toggler mr-2"
        type="button"
        (click)="toggleSideBar()"
        *ngIf="!forcedPasswordChangeMode"
      >
        <span><i class="sr-only">{{ 'VIEW.MAIN.MAIN.OPEN_MENU' | translate }}</i></span>
      </button>
    </div>
  </div>

  <div *ngIf="currentUser" id="control" [tourWizardAnchor]="TourWizardAnchor.CONTROL_MENU" class="btn-group align-items-center ml-auto">
    <div *ngIf="currentUser.userType === userType.SENIOR" dropdown [insideClick]="true" #dropdown="bs-dropdown">
      <button
        id="notification"
        dropdownToggle
        class="btn dropdown-toggle d-flex align-items-center p-0"
        type="button"
        aria-controls="notification-dropdown"
      >
        <ng-container *ngIf="hasUnreadCriticalNotifications$ | async; else notCritical">
          <!-- SVG Icon for critical notifications -->
          <svg-icon class="important-notification" src="asset/image/icon/bell-active.svg"></svg-icon>
        </ng-container>
        <ng-template #notCritical>
          <ng-container *ngIf="hasUnreadNotifications$ | async; else hasntUnreadNotifications">
            <!-- SVG Icon for unread notifications -->
            <svg-icon class="new-notification" src="asset/image/icon/bell-active.svg"></svg-icon>
          </ng-container>
        </ng-template>
        <ng-template #hasntUnreadNotifications>
          <!-- SVG Icon for no unread notifications -->
          <svg-icon src="asset/image/icon/bell.svg"></svg-icon>
        </ng-template>
      </button>
      <div
        id="notification-dropdown"
        *dropdownMenu
        class="dropdown-menu dropdown-menu-right p-0"
        role="menu"
        aria-labelledby="notification"
      >
        <app-notifications (closeClicked)="dropdown.hide()"></app-notifications>
      </div>
      <div *ngIf="dropdown.isOpen" class="notification-overlay"></div>
    </div>

    <div class="d-none d-md-inline" dropdown>
      <button
        id="user-button"
        dropdownToggle
        type="button"
        class="btn dropdown-toggle d-flex align-items-center gap-2"
        aria-controls="user-dropdown"
      >
        <span *ngIf="!forcedPasswordChangeMode">{{ currentUser?.fullName | replaceEmpty }}</span>
        <app-avatar *ngIf="currentUser$ | async as currentUser"
                    class="avatar"
                    [src]="currentUser.avatar?.thumbnailAsset?.url"
                    size="24px"
                    [circle]="true">
        </app-avatar>
        <svg-icon class="dropdown-btn" src="asset/image/icon/chevron-down.svg"></svg-icon>
      </button>
      <ul
        id="user-dropdown"
        *dropdownMenu
        class="dropdown-menu dropdown-menu-right"
        role="menu"
        aria-labelledby="user-button"
      >
        <li role="menuitem" *ngIf="!forcedPasswordChangeMode">
          <a class="dropdown-item" (click)="showAccountDetails()"
          >{{ 'VIEW.MAIN.MAIN.ACCOUNT_DETAILS' | translate }}</a
          >
        </li>
        <li role="menuitem">
          <a class="dropdown-item" (click)="logout()">{{ 'VIEW.MAIN.MAIN.LOGOUT' | translate }}</a>
        </li>
      </ul>
    </div>
  </div>

  <div *ngIf="!forcedPasswordChangeMode" id="sidebar" [tourWizardAnchor]="TourWizardAnchor.SIDEBAR">
    <ul class="nav flex-column">
      <ng-container *ngFor="let sideBarItem of sideBarItems">
        <li class="nav-item" *ngIf="sideBarItem.visible" [ngClass]="sideBarItem.cssClass">
          <button type="button"
                  class="btn nav-link"
                  [ngClass]="{ 'active': sideBarItem.selected }"
                  (click)="goToSideBarItem(sideBarItem)"
          >
            <span class="mr-2 d-flex align-items-center justify-content-center">
              <svg-icon class="d-flex" [src]="sideBarItem.image"></svg-icon>
            </span>
            <span>{{ sideBarItem.label | translate }}</span>
            <span class="ml-2 badge badge-pill badge-primary" *ngIf="sideBarItem.badge > 0">
              {{ sideBarItem.badge }}
            </span>
          </button>
        </li>
      </ng-container>
    </ul>
    <ul *ngIf="sideBarAccountItem && sideBarAccountItem.length > 0" class="nav flex-column mt-0 mb-4">
      <ng-container *ngFor="let item of sideBarAccountItem">
        <li class="nav-item" *ngIf="item.visible" [ngClass]="item.cssClass">
          <a
            class="nav-link d-flex flex-row align-items-center text-sm"
            [ngClass]="{ 'active': item.selected }"
            (click)="goToSideBarItem(item)"
          >
            <div class="icon-container mr-2 d-flex align-items-center justify-content-center">
              <svg-icon class="d-flex" [src]="item.image"></svg-icon>
            </div>
            <span>{{ item.label | translate }}</span>
            <span class="ml-2 badge badge-pill badge-primary" *ngIf="item.badge > 0">{{ item.badge }}</span>
          </a>
        </li>
      </ng-container>
    </ul>
  </div>

  <div id="content" [ngClass]="{ 'full-width': forcedPasswordChangeMode }">
    <main role="main">
      <header class="portal-outlet" #headingPortalOutlet><!-- portal content --></header>
      <app-custom-ui-view></app-custom-ui-view>
    </main>
  </div>
  <div id="footer">
    <span class="copyright">
      {{ 'VIEW.MAIN.MAIN.COPYRIGHT' | translate: { year: (currentDate | date: 'yyyy') } }}
    </span>
    <span class="version">
      {{ applicationVersion }} <i *ngIf="devMode">[{{ environmentName }}]</i>
    </span>
  </div>
</div>
<div class="overlay" (click)="toggleSideBar()"></div>

<ngx-ui-loader></ngx-ui-loader>
