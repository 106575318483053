<app-content-and-image-column-layout [imageSrc]="'asset/image/register-background.jpg'">
  <div class="p-4">
    <div class="d-flex justify-content-center">
      <img class="logo" src="asset/image/logo.png" alt="Logo"/>
    </div>
    <span>{{ 'VIEW.PRELIMINARY.LOGIN.WELCOME' | translate | uppercase }}</span>
    <h1 class="mb-0">{{ 'VIEW.PRELIMINARY.NOT_VERIFIED.MESSAGE' | translate }}</h1>
    <p class="my-6">{{ 'VIEW.PRELIMINARY.NOT_VERIFIED.CHECK_YOUR_EMAIL' | translate }}</p>
    <div class="d-flex flex-column flex-sm-row justify-content-end">
      <button class="btn btn-light mr-sm-4 mr-0" (click)="goBack()">
        <svg-icon class="mr-2" src="asset/image/icon/chevron-left.svg"></svg-icon>
        <span>{{ 'COMMON.BACK' | translate }}</span>
      </button>
      <button class="btn btn-primary" (click)="resendConfirmationLink()">
        {{ 'VIEW.PRELIMINARY.NOT_VERIFIED.RESEND_EMAIL' | translate }}
      </button>
    </div>
  </div>
</app-content-and-image-column-layout>
