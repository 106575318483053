<div>
  <ng-select [placeholder]="'COMMON.FILTER_BY' | translate"
             [searchable]="false"
             [style.--icon]="'url(/asset/image/icon/filter.svg)'"
             (click)="openFilterModal(modalTemplate)">
  </ng-select>
</div>

<ng-template #modalTemplate>
  <form>
    <div class="modal-header border-bottom-0">
      <h4 class="modal-title pull-left">{{ 'COMMON.FILTERS' | translate }}</h4>
      <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
        <svg-icon src="asset/image/icon/x.svg"></svg-icon>
      </button>
    </div>

    <div class="modal-body">
      <div class="form-group">
        <label for="subscriptionType" class="font-weight-bold">{{ 'VIEW.MAIN.USER.FILTER.SUBSCRIPTION_TYPE.LABEL' | translate }}</label>
        <ng-select class="w-100"
                   labelForId="subscriptionType"
                   name="subscriptionType"
                   [placeholder]="'VIEW.MAIN.USER.FILTER.SUBSCRIPTION_TYPE.PLACEHOLDER' | translate"
                   [searchable]="false"
                   [multiple]="false"
                   [(ngModel)]="_criteria.subscriptionType"
                   (change)="onSubscriptionTypeChange()">
          <ng-option *ngFor="let subscriptionType of subscriptionTypeOptions"
                     [value]="subscriptionType">{{ subscriptionType | prefix: 'ENUM.SUBSCRIPTION_TYPE.' | translate }}
          </ng-option>
        </ng-select>
      </div>

      <div class="form-group">
        <label for="provider" class="font-weight-bold">{{ 'VIEW.MAIN.USER.FILTER.PROVIDER.LABEL' | translate }}</label>
        <ng-select class="w-100"
                   id="provider"
                   name="provider"
                   bindLabel="name"
                   [placeholder]="'VIEW.MAIN.USER.FILTER.PROVIDER.PLACEHOLDER' | translate"
                   [searchable]="false"
                   [multiple]="true"
                   [disabled]="_criteria.subscriptionType === SubscriptionType.RETAIL"
                   [items]="organizationOptions$ | async"
                   [(ngModel)]="_criteria.organizations">
        </ng-select>
      </div>
    </div>

    <div class="modal-footer border-top-0">
      <button type="button" class="btn btn-outline-primary"
              (click)="clearFilters()">{{ 'COMMON.CLEAR_FILTERS' | translate }}
      </button>

      <button type="button" class="btn btn-primary"
              (click)="save()">{{ 'COMMON.SHOW' | translate }}
      </button>
    </div>
  </form>
</ng-template>
