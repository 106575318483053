import { Injectable, Injector, OnDestroy, PipeTransform, Type } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ValueFormatterParams } from 'ag-grid-community';
import { IdentifiableObjectDTO } from 'app/data/dto/IdentifiableObjectDTO';
import { ServerErrorDTO } from 'app/data/dto/ServerErrorDTO';
import { LanguageVersion } from 'app/data/local/LanguageVersion';
import { OptionItem } from 'app/data/local/generic/OptionItem';
import { ApplicationModel } from 'app/model/ApplicationModel';
import { ReplaceEmptyPipe } from 'app/util/pipe/ReplaceEmptyPipe';
import * as _ from 'lodash';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';
import { ServerErrorCode } from 'app/data/enum/ServerErrorCode';

@Injectable({ providedIn: 'root' })
export class ViewUtil implements OnDestroy {
  private languageSubscription: Subscription;
  private isLoadingSubscription: Subscription;

  public language: LanguageVersion;
  public isLoading: boolean = false;

  // avoid circular dependency issues (relies on ApplicationRef)
  private get toastrService(): ToastrService {
    return this.injector.get<ToastrService>(ToastrService);
  }

  // avoid circular dependency issues (relies on ApplicationRef)
  private get modalService(): BsModalService {
    return this.injector.get<BsModalService>(BsModalService);
  }

  constructor(
    private translateService: TranslateService,
    private injector: Injector,
    private loader: NgxUiLoaderService,
    private applicationModel: ApplicationModel
  ) {
    this.languageSubscription = this.applicationModel.language$.subscribe(
      (value: LanguageVersion) => (this.language = value)
    );
    this.isLoadingSubscription = this.applicationModel.isLoading$.subscribe((value: boolean) => {
      if (this.isLoading !== value) {
        this.isLoading = value;
        this.toggleLoader(value);
      }
    });
  }

  public ngOnDestroy(): void {
    this.languageSubscription.unsubscribe();
    this.isLoadingSubscription.unsubscribe();
  }

  public showToastSuccess(text: string, title: string = 'COMMON.SUCCESS', forceShow: boolean = true): void {
    this.translate([ text, title ]).then((translations: string) => {
      if (forceShow) {
        this.removeToasts();
      }
      this.toastrService.success(translations[text], translations[title]);
    });
  }

  public showToastInfo(text: string, title: string = 'COMMON.INFO', forceShow: boolean = true): void {
    this.translate([ text, title ]).then((translations: string) => {
      if (forceShow) {
        this.removeToasts();
      }
      this.toastrService.info(translations[text], translations[title]);
    });
  }

  public showToastWarning(text: string, title: string = 'COMMON.WARNING', forceShow: boolean = true): void {
    this.translate([ text, title ]).then((translations: string) => {
      if (forceShow) {
        this.removeToasts();
      }
      this.toastrService.warning(translations[text], translations[title]);
    });
  }

  public showToastError(text: string, title: string = 'COMMON.ERROR', forceShow: boolean = true): void {
    this.translate([ text, title ]).then((translations: string) => {
      if (forceShow) {
        this.removeToasts();
      }
      this.toastrService.error(translations[text], translations[title]);
    });
  }

  public removeToasts(): void {
    _.forEach(this.toastrService.toasts, (toast: ActiveToast<any>) => {
      this.toastrService.remove(toast.toastId);
    });
  }

  public toggleLoader(on: boolean, loaderId: string = 'master'): void {
    if (this.loader.getLoader(loaderId)) {
      if (on) {
        this.loader.startLoader(loaderId);
      }
      else {
        this.loader.stopLoader(loaderId);
      }
    }
    else {
      // in special cases (during init), loader might not be present yet, so we can postpone the call to next tick
      // hoping it will show up by then
      setTimeout(() => {
        this.toggleLoader(on, loaderId);
      });
    }
  }

  public closeAllModals(): void {
    for (const modalLoader of this.modalService['loaders']) {
      this.modalService.hide(modalLoader.instance.id);
    }
  }

  public handleServerError(error: any, failsafeMessage: string = 'ERROR.OPERATION_UNSUCCESSFUL'): void {
    if (error?.error && (error.error instanceof ServerErrorDTO) && error.error?.errorCode) {
      if (ServerErrorCode[error.error.errorCode]) {
        this.translate(`SERVER_ERROR_CODE.${ ServerErrorCode[error.error.errorCode] }`)
          .then((translation: string) => {
            this.showToastError(translation);
          });
      }
      else {
        if (!failsafeMessage) {
          if (error.error.errorDescription) {
            this.showToastError(error.error.errorCode + ' - ' + error.error.errorDescription);
          }
          else {
            this.showToastError(error.error.errorCode);
          }
        }
        else {
          this.translate(failsafeMessage)
            .then((translation: string) => {
              if (error.error.errorDescription) {
                this.showToastError(translation + ' - ' + error.error.errorCode + ' - ' + error.error.errorDescription);
              }
              else {
                this.showToastError(translation + ' - ' + error.error.errorCode);
              }
            });
        }
      }
    }
    else {
      this.translate(failsafeMessage)
        .then((translation: string) => {
          this.showToastError(translation);
        });
    }
  }

  public translate(key: string | Array<string>, params: { [key: string]: any } = null): Promise<string | any> {
    return this.translateService
      .get(key, params)
      .toPromise()
      .then((translation: string | any) => {
        return translation;
      });
  }

  public translateInstant(key: string | Array<string>, params: { [key: string]: any } = null): string {
    return this.translateService.instant(key, params);
  }

  public dataGridPipeValueFormatter<T>(params: ValueFormatterParams, pipeClass: T, ...pipeArgs: any[]): string {
    const pipe: PipeTransform = this.injector.get<PipeTransform>(pipeClass as unknown as Type<PipeTransform>);
    if (pipe) {
      return pipe.transform(params.value, ...pipeArgs);
    }
    else {
      return null;
    }
  }

  public enumValueFormatter<T>(params: ValueFormatterParams, pipeClass: T, prefix: string): string {
    const pipe: PipeTransform = this.injector.get<PipeTransform>(pipeClass as unknown as Type<PipeTransform>);
    if (pipe) {
      return pipe.transform(prefix + params.value);
    }
    else {
      return null;
    }
  }

  public dataGridArrayRawValueFormatter(params: ValueFormatterParams): any {
    return params.data;
  }

  public dataGridReplaceEmptyValueFormatter(params: ValueFormatterParams): any {
    return new ReplaceEmptyPipe().transform(params.value);
  }

  public dataGridPipeValueFormatterWithTranslate<T>(
    params: ValueFormatterParams,
    pipeClass: T,
    ...pipeArgs: any[]
  ): string {
    return this.translateInstant(this.dataGridPipeValueFormatter(params, pipeClass, ...pipeArgs));
  }

  public dataGridPipeValueFormatterWithReplaceEmpty<T>(
    params: ValueFormatterParams,
    pipeClass: T,
    ...pipeArgs: any[]
  ): string {
    return new ReplaceEmptyPipe().transform(this.dataGridPipeValueFormatter(params, pipeClass, ...pipeArgs));
  }

  public dataGridPipeValueFormatterWithTranslateAndReplaceEmpty<T>(
    params: ValueFormatterParams,
    pipeClass: T,
    ...pipeArgs: any[]
  ): string {
    return this.translateInstant(
      new ReplaceEmptyPipe().transform(this.dataGridPipeValueFormatter(params, pipeClass, ...pipeArgs))
    );
  }

  public createEnumSelectOptions<T, V>(enumClass: V, enumLangKey: string): OptionItem<T>[] {
    const options: OptionItem<T>[] = [];
    let enumKeys: string[] = Object.keys(enumClass);
    let valuesAreNumbers: boolean = false;
    const numberValuesCount: number = enumKeys.filter((key) => isNaN(Number(key)) === false).length;

    if (numberValuesCount === (enumKeys.length / 2)) {
      valuesAreNumbers = true;
    }

    if (valuesAreNumbers) {
      enumKeys = enumKeys.filter((key) => isNaN(Number(key)));
    }

    for (const key of enumKeys) {
      const option: OptionItem<T> = new OptionItem<T>();
      option.label = `ENUM.${ enumLangKey }.${ key }`;
      option.value = enumClass[key] as T;
      options.push(option);
    }

    return options;
  }

  public mapToSelectOptions<T extends { id: any; name: string; profilePicture: string }>(items: T[]) {
    return items.map((item) => ({
      label: item.name,
      value: item
    }));
  }

  public identifiableObjectSelectComparator(
    val1: IdentifiableObjectDTO<any>,
    val2: IdentifiableObjectDTO<any>
  ): boolean {
    return val1 && val2 ? val1.id === val2.id : val1 === val2;
  }
}
