<ng-template #headingTemplate>
  <h1>{{ 'VIEW.MAIN.LIVE_CLASSES.LIVE_CLASSES' | translate }}</h1>

  <div class="space-x-4">
    <button class="btn btn-outline-primary w-100 w-sm-auto" [uiSref]="State.MAIN.ADMIN.LIVE_CLASSES.SERIES.CREATE">
      <svg-icon src="asset/image/icon/plus.svg"></svg-icon>
      <span>{{ 'VIEW.MAIN.LIVE_CLASSES.SERIES.ADD_NEW' | translate }}</span>
    </button>

    <button class="btn btn-primary w-100 w-sm-auto" [uiSref]="State.MAIN.ADMIN.LIVE_CLASSES.SINGLE.CREATE">
      <svg-icon class="d-flex" src="asset/image/icon/plus.svg"></svg-icon>
      <span>{{ 'VIEW.MAIN.LIVE_CLASSES.ADD_NEW_CLASS' | translate }}</span>
    </button>
  </div>
</ng-template>

<section class="section">
  <div class="d-flex">
    <app-route-tabs [routeTabs]="tabs" translationPrefix="ENUM.LIVE_CLASS_TYPE."></app-route-tabs>
  </div>

  <div *ngIf="page$ | async as page">
    <app-data-grid
      [data]="page.content"
      [criteria]="criteria"
      [context]="{ host: this, additionalData: { currentUser } }"
      [showFilters]="true"
      [showPagination]="true"
      [pageTotal]="page.totalPages"
      [itemTotal]="page.totalElements"
      [showItemsPerPage]="true"
      [showTotalItems]="false"
      [selectionEnabled]="true"
      [multiSelectionEnabled]="true"
      [useCheckboxSelection]="true"
      [selectedRows]="selectedItems"
      (gridAction)="onGridAction($event)"
      (itemsSelected)="onSelectionChange($event)"
      (criteriaChanged)="onCriteriaChanged($event)"
      [columns]="$any([
        { headerName: ('VIEW.MAIN.LIVE_CLASSES.CLASS' | translate), headerClass: 'margin-l-50 pl-0', field: 'title', tooltipField: 'title', minWidth: 200, maxWidth: 300, cellClass: 'cell-fullwidth', checkboxSelection: checkboxSelectionFunction },
        { headerName: ('VIEW.MAIN.LIVE_CLASSES.TIME' | translate), field: 'startDate', minWidth: 100, maxWidth: 170, valueFormatter: dateTimeValueFormatter },
        { headerName: ('VIEW.MAIN.LIVE_CLASSES.CATEGORY' | translate), field: 'category', minWidth: 100, maxWidth: 150, cellClass: 'text-right text-lg-left', valueFormatter: enumCategoryFormatter },
        { headerName: ('VIEW.MAIN.LIVE_CLASSES.LENGTH' | translate), field: 'duration', minWidth: 80, maxWidth: 100 },
        { headerName: ('VIEW.MAIN.LIVE_CLASSES.INTENSITY' | translate), field: 'intensity', minWidth: 85, maxWidth: 100, cellClass: 'text-right text-lg-left', valueFormatter: enumIntensityFormatter },
        { headerName: ('VIEW.MAIN.LIVE_CLASSES.COACH' | translate), field: 'coaches', minWidth: 120, valueFormatter: coachFormatter },
        { headerName: ('VIEW.MAIN.LIVE_CLASSES.SIGNED_USERS' | translate), field: 'capacity', minWidth: 120, maxWidth: 120, cellClass: 'text-right text-lg-left', valueFormatter: usersCapacityFormatter, cellRendererFramework: UsersCapacityCellRendererComponent },
        { headerName: ('VIEW.MAIN.LIVE_CLASSES.RATING' | translate), field: 'rating', minWidth: 140, cellRendererFramework: StarRatingCellRendererComponent },
        { headerName: '', headerClass: 'header-right', minWidth: 150, maxWidth: 150, cellClass: 'cell-fullwidth', suppressKeyboardEvent: LiveClassesListActionCellRendererComponent.suppressKeyboardEvent, cellRendererFramework: LiveClassesListActionCellRendererComponent }
      ])"
    >
      <app-data-grid-filters>
        <div class="form-row mb-4 mb-lg-0">
          <div class="col-6 col-md-auto">
            <button (click)="onTodayClick()" class="btn btn-outline-primary w-100">
              {{ 'VIEW.MAIN.LIVE_CLASSES.TODAY' | translate }}
            </button>
          </div> <!-- /.col -->

          <div class="col-6 col-md-auto">
            <ng-select
              id="days-ahead"
              name="days-ahead"
              [placeholder]="'VIEW.MAIN.LIVE_CLASSES.NEXT_DAYS' | translate"
              [searchable]="false"
              [style.--icon]="'url(/asset/image/icon/calendar-due.svg)'"
              (ngModelChange)="onDaysAheadChange($event)"
              [(ngModel)]="consecutiveDays"
            >
              <ng-option *ngFor="let daysAhead of daysAheadSelectList" [value]="daysAhead.value"
              >{{ daysAhead.label | translate }}
              </ng-option
              >
            </ng-select>
          </div> <!-- /.col -->

          <div class="col-12 col-md-auto">
            <div id="filter-date" class="mt-4 mt-md-0">
              <svg-icon
                src="asset/image/icon/chevron-left.svg"
                class="mr-auto mr-md-2 btn-link"
                (click)="changeToPreviousDay()"
              ></svg-icon>
              <div class="d-flex flex-column align-items-center">
                <div (click)="dp.toggle()">
                  <span class="btn-link">{{ selectedDate | date: 'EEE, MMM d, yyyy' }}</span>
                </div>
                <input
                  type="text"
                  class="text-like-input"
                  bsDatepicker
                  [bsConfig]="bsConfig"
                  #dp="bsDatepicker"
                  (bsValueChange)="onDateChange($event)"
                  readonly
                />
              </div>
              <svg-icon
                class="ml-auto ml-md-2 btn-link"
                src="asset/image/icon/chevron-right.svg"
                (click)="changeToNextDay()"
              ></svg-icon>
            </div>
          </div> <!-- /.col -->
        </div> <!-- /.form-row -->
      </app-data-grid-filters>

      <app-extra-control>
        <ng-select
          id="filter-classes"
          [placeholder]="'VIEW.MAIN.LIVE_CLASSES.FILTER_BY' | translate"
          [searchable]="false"
          [style.--icon]="'url(/asset/image/icon/filter.svg)'"
          (click)="showFilterClick()"
        >
        </ng-select>
      </app-extra-control>

      <app-selected-filters [criteria]="criteria"
                            [friendlyNames]="friendlyNames"
                            [getLabelForKeyValue]="getLabelForKeyValueBind"
                            (criteriaChange)="onCriteriaChanged($event)"></app-selected-filters>
    </app-data-grid>
  </div>
</section>

<app-live-classes-selected-action-bar
  [selectedClasses]="selectedItems"
  [adminMode]="true"
  (deleteClicked)="handleDeleteClick()"
></app-live-classes-selected-action-bar>
