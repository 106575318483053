<div
  *ngIf="selectedVideos?.length"
  id="bulk-actions"
  class="p-2 d-flex justify-content-between align-items-center mt-4 flex-wrap gap-2"
>
  <span class="ml-4"> {{ selectedVideos.length }} {{ videosText }} {{ "COMMON.SELECTED" | translate | lowercase }} </span>
  <div class="d-flex flex-wrap gap-2 w-100 w-sm-auto">
    <button
      class="btn btn-primary d-flex justify-content-center align-items-center w-100 w-sm-auto"
      (click)="onMarkRecommendedClick()"
    >
      <svg-icon class="d-flex mr-2" src="asset/image/icon/join.svg"></svg-icon>
      <span *ngIf="shouldMark">{{ "VIEW.MAIN.ON_DEMAND.MARK_RECOMMENDED" | translate }}</span>
      <span *ngIf="!shouldMark">{{ "VIEW.MAIN.ON_DEMAND.UNMARK_RECOMMENDED" | translate }}</span>
    </button>
    <button
      class="btn btn-danger d-flex justify-content-center align-items-center w-100 w-sm-auto"
      (click)="onDeleteClick()"
    >
      <span>{{ "COMMON.DELETE" | translate }}</span>
    </button>
  </div>
</div>
