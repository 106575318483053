import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ScheduledLiveClassUnratedResponseDTO } from 'app/data/dto/scheduledLiveClass/ScheduledLiveClassUnratedResponseDTO';
import { VideoUnratedResponseDTO } from 'app/data/dto/onDemandVideo/VideoUnratedResponseDTO';
import { OnDemandVideoModel } from 'app/model/OnDemandVideoModel';
import { VideoRatingRequestDTO } from 'app/data/dto/onDemandVideo/VideoRatingRequestDTO';
import { ViewUtil } from 'app/util/ViewUtil';
import { LiveClassModel } from 'app/model/LiveClassModel';
import { ScheduledLiveClassRatingRequestDTO } from 'app/data/dto/scheduledLiveClass/ScheduledLiveClassRatingRequestDTO';

@Component({
  selector: 'app-recent-activities-to-rate',
  templateUrl: 'RecentActivitiesToRateComponent.html',
  styleUrls: [ 'RecentActivitiesToRateComponent.scss' ]
})
export class RecentActivitiesToRateComponent implements OnInit {
  @Input()
  public unratedLiveClasses: ScheduledLiveClassUnratedResponseDTO[] = [];

  @Input()
  public unratedVideos: VideoUnratedResponseDTO[] = [];

  @Output()
  public rate: EventEmitter<ScheduledLiveClassUnratedResponseDTO> = new EventEmitter();

  public unratedItems: ScheduledLiveClassUnratedResponseDTO[] | VideoUnratedResponseDTO[] = [];

  constructor(private onDemandVideoModel: OnDemandVideoModel,
              private liveClassModel: LiveClassModel,
              private viewUtil: ViewUtil) {
  }

  public ngOnInit(): void {
    this.unratedItems = this.unratedLiveClasses.concat(this.unratedVideos);
  }

  public rateItem(rate: number, item: ScheduledLiveClassUnratedResponseDTO | VideoUnratedResponseDTO): void {
    const rating: VideoRatingRequestDTO | ScheduledLiveClassRatingRequestDTO = {
      feedback: null,
      rating: rate
    };

    if (item instanceof VideoUnratedResponseDTO) {
      this.onDemandVideoModel.rateVideo(item.id, rating as VideoRatingRequestDTO)
        .subscribe(() => {
            this.rate.emit(item);
          },
          (error) => this.viewUtil.handleServerError(error)
        );
    }
    else {
      this.liveClassModel.rateScheduledLiveClass(item.id, rating as ScheduledLiveClassRatingRequestDTO)
        .subscribe(() => {
            this.rate.emit(item);
          },
          (error) => this.viewUtil.handleServerError(error)
        );
    }
  }
}
