import { Component, ViewChild } from '@angular/core';
import { StateService } from '@uirouter/core';
import { Transition } from '@uirouter/angular';
import { NgForm } from '@angular/forms';
import { AuthModel } from 'app/model/AuthModel';
import { ViewUtil } from 'app/util/ViewUtil';
import { State } from 'app/common/State';
import { Constant } from 'app/common/Constant';

@Component({
  selector: 'app-reset-password-complete',
  templateUrl: 'ResetPasswordCompleteComponent.html',
  styleUrls: [ 'ResetPasswordCompleteComponent.scss' ]
})
export class ResetPasswordCompleteComponent {
  @ViewChild('resetPasswordForm')
  private resetPasswordForm: NgForm;

  public usernameParam: string;
  public verificationCodeParam: string;
  public formData: {
    username: string;
    password: string;
    passwordRepeat: string;
    verificationCode: string;
  } = {
    username: null,
    password: null,
    passwordRepeat: null,
    verificationCode: null
  };

  public Constant: typeof Constant = Constant;

  constructor(private stateService: StateService,
              private transition: Transition,
              public viewUtil: ViewUtil,
              private authModel: AuthModel) {
    this.usernameParam = this.transition.params().username ? unescape(transition.params().username) : null;
    this.verificationCodeParam = transition.params().verificationCode;

    if (this.usernameParam && this.verificationCodeParam) {
      this.formData.username = this.usernameParam;
      this.formData.verificationCode = this.verificationCodeParam;
    }
    else {
      this.backToLogin();
    }
  }

  public resetPassword(): void {
    this.resetPasswordForm.onSubmit(null);

    if (this.resetPasswordForm.form.valid) {
      this.authModel
        .completePasswordReset(this.formData.username, this.formData.verificationCode, this.formData.password)
        .then(() => {
          this.viewUtil.showToastSuccess('VIEW.PRELIMINARY.RESET_PASSWORD.RESET_PASSWORD_COMPLETE_SUCCESS');
          this.backToLogin();
        })
        .catch((error) => {
          const errorMessage = error?.message;
          this.viewUtil.handleServerError(error, errorMessage);
        });
    }
  }

  public backToLogin(): void {
    this.stateService.go(State.PRELIMINARY.LOGIN);
  }

  public onBackClick(): void {
    this.stateService.go(State.PRELIMINARY.RESET_PASSWORD_VERIFICATION_CODE, { username: this.usernameParam });
  }
}
