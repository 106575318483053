export enum PermissionName {
  ADMIN_LIVE_CLASSES_READ = 'ADMIN_LIVE_CLASSES_READ',
  ADMIN_VIDEOS_READ = 'ADMIN_VIDEOS_READ',

  ASSETS_DELETE = 'ASSETS_DELETE',

  COACHES_READ = 'COACHES_READ',
  COACHES_UPDATE = 'COACHES_UPDATE',
  COACHES_CREATE = 'COACHES_CREATE',
  COACHES_DELETE = 'COACHES_DELETE',
  COACHES_RATING_UPDATE = 'COACHES_RATING_UPDATE',
  COACHES_RATING_CREATE = 'COACHES_RATING_CREATE',
  COACHES_RATING_READ = 'COACHES_RATING_READ',
  COACHES_RATING_DELETE = 'COACHES_RATING_DELETE',
  COACHES_ESSENTIALS_READ = 'COACHES_ESSENTIALS_READ',
  COACH_VIDEOS_READ = 'COACH_VIDEOS_READ',

  IMAGES_DELETE = 'IMAGES_DELETE',

  LIVE_CLASSES_READ = 'LIVE_CLASSES_READ',
  LIVE_CLASSES_CREATE = 'LIVE_CLASSES_CREATE',
  LIVE_CLASSES_UPDATE = 'LIVE_CLASSES_UPDATE',
  LIVE_CLASSES_DELETE = 'LIVE_CLASSES_DELETE',
  LIVE_CLASSES_FAVORITE = 'LIVE_CLASSES_FAVORITE',
  LIVE_CLASSES_RATING_READ = 'LIVE_CLASSES_RATING_READ',
  LIVE_CLASSES_RATING_CREATE = 'LIVE_CLASSES_RATING_CREATE',
  LIVE_CLASSES_RATING_UPDATE = 'LIVE_CLASSES_RATING_UPDATE',
  LIVE_CLASSES_RATING_DELETE = 'LIVE_CLASSES_RATING_DELETE',
  LIVE_CLASSES_BULK_ENROLL = 'LIVE_CLASSES_BULK_ENROLL',
  LIVE_CLASSES_SELF_ENROLL = 'LIVE_CLASSES_SELF_ENROLL',
  LIVE_CLASSES_COACH = 'LIVE_CLASSES_COACH',

  ORGANIZATIONS_READ = 'ORGANIZATIONS_READ',
  ORGANIZATIONS_CREATE = 'ORGANIZATIONS_CREATE',
  ORGANIZATIONS_UPDATE = 'ORGANIZATIONS_UPDATE',
  ORGANIZATIONS_DELETE = 'ORGANIZATIONS_DELETE',
  ORGANIZATIONS_ESSENTIALS_READ = 'ORGANIZATIONS_ESSENTIALS_READ',

  PROGRAMS_READ = 'PROGRAMS_READ',
  PROGRAMS_UPDATE = 'PROGRAMS_UPDATE',
  PROGRAMS_CREATE = 'PROGRAMS_CREATE',

  SENIORS_UPDATE = 'SENIORS_UPDATE',
  SENIORS_READ = 'SENIORS_READ',
  SENIORS_DELETE = 'SENIORS_DELETE',
  SENIORS_CREATE = 'SENIORS_CREATE',

  STATISTICS_READ = 'STATISTICS_READ',

  SUBADMINS_CREATE = 'SUBADMINS_CREATE',
  SUBADMINS_READ = 'SUBADMINS_READ',
  SUBADMINS_UPDATE = 'SUBADMINS_UPDATE',
  SUBADMINS_DELETE = 'SUBADMINS_DELETE',

  USERS_UPDATE = 'USERS_UPDATE',

  VIDEOS_READ = 'VIDEOS_READ',
  VIDEOS_CREATE = 'VIDEOS_CREATE',
  VIDEOS_UPDATE = 'VIDEOS_UPDATE',
  VIDEOS_DELETE = 'VIDEOS_DELETE',
  VIDEOS_FAVORITE = 'VIDEOS_FAVORITE',
  VIDEOS_RATING_READ = 'VIDEOS_RATING_READ',
  VIDEOS_RATING_CREATE = 'VIDEOS_RATING_CREATE',
  VIDEOS_RATING_UPDATE = 'VIDEOS_RATING_UPDATE',
  VIDEOS_RATING_DELETE = 'VIDEOS_RATING_DELETE'
}
