import { Component, EventEmitter, Input, Output } from '@angular/core';
import { VideoSortBy } from 'app/data/enum/video/VideoSortBy';
import _ from 'lodash';
import { BaseCriteriaSortOrder } from 'app/data/dto/BaseCriteriaDTO';
import { SortDirection } from 'app/data/enum/SortDirection';
import { OptionItem } from 'app/data/local/generic/OptionItem';
import { ViewUtil } from 'app/util/ViewUtil';
import { VideoPageCriteriaDTO } from 'app/data/dto/onDemandVideo/VideoPageCriteriaDTO';
import { ProgramSeniorPageCriteriaDTO } from 'app/data/dto/programs/ProgramSeniorPageCriteriaDTO';
import { PageCriteriaDTO } from 'app/data/dto/PageCriteriaDTO';

@Component({
  selector: 'app-on-demand-filter-list',
  templateUrl: './OnDemandFilterListComponent.html',
  styleUrls: [ './OnDemandFilterListComponent.scss' ]
})
export class OnDemandFilterListComponent {
  @Input() criteria: PageCriteriaDTO;
  @Output() criteriaChange: EventEmitter<PageCriteriaDTO> = new EventEmitter<PageCriteriaDTO>();

  public internalSearchPhrase: string;
  public sortByOptions: OptionItem<string>[] = this.viewUtil.createEnumSelectOptions(VideoSortBy, 'VIDEO_SORT_BY');
  public sortValue: string;

  constructor(private readonly viewUtil: ViewUtil) { }

  public onSortOptionChange(sortBy: VideoSortBy) {
    const criteria = _.cloneDeep(this.criteria);

    if (!sortBy) {
      criteria.sortOrders = undefined;
    }
    else {
      let sortOrders: BaseCriteriaSortOrder;
      let sortField: string;
      let sortDirection: SortDirection = SortDirection.ASCENDING;

      switch (sortBy) {
        case VideoSortBy.MOST_RECENT: {
          sortField = 'createdDate';
          sortDirection = SortDirection.DESCENDING;
          break;
        }
        case VideoSortBy.ALPHABETIC: {
          sortField = 'title';
          break;
        }
      }

      sortOrders = `${ sortField } ${ sortDirection }`;
      criteria.sortOrders = [ sortOrders ];
    }

    this.criteriaChange.emit(criteria);
  }

  public clearSearch(): void {
    this.internalSearchPhrase = undefined;
    this.onSearchCommitted();
  }

  public onSearchCommitted(): void {
    const criteria = _.cloneDeep(this.criteria);
    criteria.searchPhrase = this.internalSearchPhrase;

    this.criteriaChange.next(criteria);
  }
}
