import { Transform, Type } from 'class-transformer';
import { DateUtil } from 'app/util/DateUtil';
import { UserProfileResponseAdminDTO } from '../user/UserProfileResponseAdminDTO';
import { IdentifiableObjectDTO } from '../IdentifiableObjectDTO';
import { UserGender } from 'app/data/enum/account/UserGender';
import { UserAddressDTO } from '../user/UserAddressDTO';

export class SeniorProfileResponseAdminDTO extends IdentifiableObjectDTO<number> {
  @Type(() => UserAddressDTO)
  public address?: UserAddressDTO;

  @Transform(DateUtil.dateConversion)
  public dateOfBirth: Date;

  public gender?: UserGender;

  @Type(() => UserProfileResponseAdminDTO)
  public user: UserProfileResponseAdminDTO;
}
