<ng-template #headingTemplate>
  <h1>{{ 'VIEW.MAIN.COACHES.COACHES_CREATE' | translate }}</h1>

  <div class="space-x-4">
    <button type="button" class="btn btn-outline-primary" (click)="onBackClick()">
      <svg-icon src="asset/image/icon/chevron-left.svg"></svg-icon>
      <span>{{ 'COMMON.BACK' | translate }}</span>
    </button>

    <button type="button" class="btn btn-primary" [disabled]="uploadStarted" (click)="submit()">
      <span *ngIf="uploadStarted" class="loader-white position-absolute"></span>
      <span>{{ 'COMMON.SAVE' | translate }}</span>
    </button>
  </div>
</ng-template>

<section class="section">
  <div class="row p-0 m-0">
    <div class="col-12 col-lg-7 p-0">
      <form #form="ngForm">
        <div class="row m-0 p-0 mb-4">
          <div
            class="col-12 col-lg-6 d-flex flex-column gap-0 d-block d-lg-none mb-4 mb-lg-0 d-flex flex-column align-items-end"
          ></div>
          <div class="form-group col-12 col-lg-6 d-flex flex-column gap-lg-0 mb-4 mb-lg-0">
            <label for="firstName"> {{ "VIEW.MAIN.COACHES.FIRST_NAME" | translate }} </label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              #firstName="ngModel"
              class="form-control"
              [placeholder]="'VIEW.MAIN.COACHES.FIRST_NAME' | translate"
              [(ngModel)]="coach.user.firstName"
              required
              [appMaxLength]="Constant.TEXT_MAX_LENGTH_255"
              [ngClass]="{'is-invalid': form.submitted && firstName.invalid, 'is-valid': form.submitted && firstName.valid }"
            />
            <div class="invalid-feedback" *ngIf="form.submitted && firstName.invalid">
              <div *ngIf="firstName.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
              <div *ngIf="firstName.errors['maxLength']">
                {{ 'VALIDATION.CHAR_MAX' | translate: { max: Constant.TEXT_MAX_LENGTH_255 } }}
              </div>
            </div>
          </div>

          <div class="form-group col-12 col-lg-6 d-flex flex-column gap-lg-0 mb-4 mb-lg-0">
            <label for="lastName"> {{ "VIEW.MAIN.COACHES.LAST_NAME" | translate }} </label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              #lastName="ngModel"
              class="form-control"
              [placeholder]="'VIEW.MAIN.COACHES.FIRST_NAME' | translate"
              [(ngModel)]="coach.user.lastName"
              required
              [appMaxLength]="Constant.TEXT_MAX_LENGTH_255"
              [ngClass]="{'is-invalid': form.submitted && lastName.invalid, 'is-valid': form.submitted && lastName.valid }"
            />
            <div class="invalid-feedback" *ngIf="form.submitted && lastName.invalid">
              <div *ngIf="lastName.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
              <div *ngIf="lastName.errors['maxLength']">
                {{ 'VALIDATION.CHAR_MAX' | translate: { max: Constant.TEXT_MAX_LENGTH_255 } }}
              </div>
            </div>
          </div>
        </div>

        <div class="row m-0 p-0 mb-4">
          <div class="form-group col-12 col-lg-6 d-flex flex-column mb-4 mb-lg-0">
            <label for="email"> {{ "VIEW.MAIN.COACHES.EMAIL" | translate }} </label>
            <input
              type="email"
              id="email"
              name="email"
              #email="ngModel"
              class="form-control"
              [placeholder]="'VIEW.MAIN.COACHES.EMAIL' | translate"
              [(ngModel)]="coach.user.email"
              required
              [appMaxLength]="Constant.TEXT_MAX_LENGTH_255"
              [ngClass]="{'is-invalid': form.submitted && email.invalid, 'is-valid': form.submitted && email.valid }"
            />
            <div class="invalid-feedback" *ngIf="form.submitted && email.invalid">
              <div *ngIf="email.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
              <div *ngIf="email.errors['maxLength']">
                {{ 'VALIDATION.CHAR_MAX' | translate: { max: Constant.TEXT_MAX_LENGTH_255 } }}
              </div>
            </div>
          </div>
          <div class="form-group col-12 col-lg-6 d-flex flex-column mb-4 mb-lg-0">
            <label for="phone"> {{ "VIEW.MAIN.COACHES.PHONE" | translate }} </label>
            <input
              type="text"
              id="phone"
              name="phone"
              #phone="ngModel"
              class="form-control"
              [placeholder]="'VIEW.MAIN.COACHES.PHONE' | translate"
              [(ngModel)]="coach.user.phone"
              required
              [appMaxLength]="Constant.TEXT_MAX_LENGTH_255"
              [ngClass]="{'is-invalid': form.submitted && phone.invalid, 'is-valid': form.submitted && phone.valid }"
            />
            <div class="invalid-feedback" *ngIf="form.submitted && phone.invalid">
              <div *ngIf="phone.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
              <div *ngIf="phone.errors['maxLength']">
                {{ 'VALIDATION.CHAR_MAX' | translate: { max: Constant.TEXT_MAX_LENGTH_255 } }}
              </div>
            </div>
          </div>
        </div>

        <div class="row m-0 p-0 mb-4">
          <div class="form-group col-12 col-lg-6 d-flex flex-column mb-4 mb-lg-0">
            <label for="location"> {{ "VIEW.MAIN.COACHES.LOCATION" | translate }} </label>
            <input
              type="text"
              id="location"
              name="location"
              #location="ngModel"
              class="form-control"
              [placeholder]="'VIEW.MAIN.COACHES.LOCATION' | translate"
              [(ngModel)]="coach.location"
              required
              [appMaxLength]="Constant.TEXT_MAX_LENGTH_255"
              [ngClass]="{'is-invalid': form.submitted && location.invalid, 'is-valid': form.submitted && location.valid }"
            />
            <div class="invalid-feedback" *ngIf="form.submitted && location.invalid">
              <div *ngIf="location.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
              <div *ngIf="location.errors['maxLength']">
                {{ 'VALIDATION.CHAR_MAX' | translate: { max: Constant.TEXT_MAX_LENGTH_255 } }}
              </div>
            </div>
          </div>
          <div class="form-group col-12 col-lg-6 d-flex flex-column mb-4 mb-lg-0">
            <label for="status"> {{ "VIEW.MAIN.COACHES.STATUS" | translate }} </label>
            <ng-select
              id="status"
              name="status"
              #status="ngModel"
              [placeholder]="'VIEW.MAIN.COACHES.STATUS' | translate"
              [searchable]="false"
              [ngClass]="{'is-invalid': form.submitted && status.invalid, 'is-valid': form.submitted && status.valid }"
              [(ngModel)]="coach.user.enabled"
              required
            >
              <ng-option *ngFor="let status of statuses" [value]="status.value"
              >{{ status.label | translate }}
              </ng-option>
            </ng-select>
            <div class="invalid-feedback" *ngIf="form.submitted && status.invalid">
              <div *ngIf="status.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
            </div>
          </div>
        </div>

        <div class="row m-0 p-0 mb-4">
          <div class="form-group col-12 col-lg-6 d-flex flex-column mb-4 mb-lg-0">
            <label for="typeOfCoach"> {{ "VIEW.MAIN.COACHES.TYPE_OF_COACH" | translate }} </label>
            <ng-select
              id="typeOfCoach"
              name="typeOfCoach"
              #typeOfCoach="ngModel"
              [placeholder]="'VIEW.MAIN.COACHES.TYPE_OF_COACH' | translate"
              [searchable]="false"
              [ngClass]="{'is-invalid': form.submitted && typeOfCoach.invalid, 'is-valid': form.submitted && typeOfCoach.valid }"
              [(ngModel)]="coach.type"
              required
            >
              <ng-option *ngFor="let type of types" [value]="type.value">{{ type.label | translate }}</ng-option>
            </ng-select>
            <div class="invalid-feedback" *ngIf="form.submitted && typeOfCoach.invalid">
              <div *ngIf="typeOfCoach.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
            </div>
          </div>
        </div>

        <div class="form-group col d-flex flex-column mb-4">
          <label for="bio">{{ "VIEW.MAIN.COACHES.BIO" | translate }}</label>
          <textarea
            id="bio"
            name="bio"
            #bio="ngModel"
            class="form-control"
            placeholder="Text"
            rows="5"
            maxlength="255"
            [ngClass]="{'is-invalid': form.submitted && bio.invalid, 'is-valid': form.submitted && bio.valid }"
            [(ngModel)]="coach.bio"
          ></textarea>
          <div class="d-flex justify-content-end">{{ bio.value ? bio.value.length : 0 }}/255</div>
        </div>

        <div class="form-group col d-flex flex-column mb-4">
          <app-dynamic-input
            [label]="'VIEW.MAIN.COACHES.HOBBIES_PASSIONS' | translate"
            [items]="coach.coachHobbies"
            keyName="hobby"
            [addButtonText]="'COMMON.ADD' | translate"
            [placeholder]="'VIEW.MAIN.COACHES.HOBBIES_PASSIONS' | translate"
            (updatedItems)="handleUpdatedHobbies($event)"
            [appMaxLength]="Constant.TEXT_MAX_LENGTH_255"
            [formSubmitted]="form.submitted"
            (formStatus)="onHobbiesFormStatusChange($event)"
            [isRequired]="true"
          ></app-dynamic-input>
        </div>

        <div class="form-group col d-flex flex-column mb-4">
          <app-dynamic-input
            [label]="'VIEW.MAIN.COACHES.EDUCATION' | translate"
            [items]="coach.coachEducations"
            keyName="education"
            [addButtonText]="'COMMON.ADD' | translate"
            [placeholder]="'VIEW.MAIN.COACHES.EDUCATION' | translate"
            (updatedItems)="handleUpdatedEducations($event)"
            [appMaxLength]="Constant.TEXT_MAX_LENGTH_255"
            [formSubmitted]="form.submitted"
            (formStatus)="onEducationFormStatusChange($event)"
            [isRequired]="true"
          ></app-dynamic-input>
        </div>

        <div class="form-group col d-flex flex-column mb-4">
          <app-dynamic-input
            [label]="'VIEW.MAIN.COACHES.CERTIFICATIONS' | translate"
            [items]="coach.coachCertifications"
            keyName="certification"
            [addButtonText]="'COMMON.ADD' | translate"
            [placeholder]="'VIEW.MAIN.COACHES.CERTIFICATIONS' | translate"
            (updatedItems)="handleUpdatedCertification($event)"
            [appMaxLength]="Constant.TEXT_MAX_LENGTH_255"
            [formSubmitted]="form.submitted"
            (formStatus)="onCertificationFormStatusChange($event)"
            [isRequired]="true"
          ></app-dynamic-input>
        </div>
        <app-thumbnail-and-files-upload
          [thumbnailPlaceholder]="'VIEW.MAIN.COACHES.PICTURE' | translate"
          [thumbnailMode]="true"
          (startUpload)="handleStartUpload($event)"
          (imageId)="handleImageId($event)"
        ></app-thumbnail-and-files-upload>
      </form>
    </div>
  </div>
</section>
