<div class="d-flex justify-content-center mb-2">
  <p class="font-size-lg mb-0">{{ 'VIEW.MAIN.HELP.WE_WANT_TO_HEAR_FROM_YOU' | translate | uppercase }}</p>
</div>
<div class="row m-0">
  <div class="col-md-12 col-lg-8 col-xl-8">
    <form [formGroup]="form">
      <div class="form-group">
        <label>{{ 'VIEW.MAIN.HELP.CATEGORY' | translate }}</label>
        <ng-select
          id="category"
          name="category"
          [class.is-invalid]="submitted && form.controls['category'].invalid"
          formControlName="category"
          [placeholder]="'VIEW.MAIN.HELP.CATEGORY_PLACEHOLDER' | translate"
          [searchable]="false"
        >
          <ng-option *ngFor="let category of questionCategories" [value]="category.value">
            {{ category.label | translate }}
          </ng-option>
        </ng-select>
        <div class="invalid-feedback" *ngIf="submitted && form.controls['category'].invalid">
          <div *ngIf="form.controls['category'].errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
        </div>
      </div>
      <div class="form-group">
        <label for="title">{{ 'VIEW.MAIN.HELP.TITLE' | translate }}</label>
        <input
          type="text"
          id="title"
          name="title"
          class="form-control"
          [class.is-invalid]="submitted && form.controls['title'].invalid"
          formControlName="title"
          [placeholder]="'VIEW.MAIN.HELP.TITLE_PLACEHOLDER' | translate"
        />
        <div class="invalid-feedback" *ngIf="submitted && form.controls['title'].invalid">
          <div *ngIf="form.controls['title'].errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
        </div>
      </div>
      <div class="form-group">
        <label for="message">{{ 'VIEW.MAIN.HELP.MESSAGE' | translate }}</label>
        <textarea
          rows="5"
          id="message"
          name="content"
          class="form-control"
          [class.is-invalid]="submitted && form.controls['content'].invalid"
          formControlName="content"
          [placeholder]="'VIEW.MAIN.HELP.MESSAGE_PLACEHOLDER' | translate"
        ></textarea>
        <div class="invalid-feedback" *ngIf="submitted && form.controls['content'].invalid">
          <div *ngIf="form.controls['content'].errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
        </div>
      </div>
    </form>
  </div>
  <div
    id="phone-panel"
    class="d-flex flex-column justify-content-center align-items-center col-md-12 col-lg-4 col-xl-4 mb-4"
  >
    <img class="img-fluid logo mb-4" src="asset/image/logo.png" alt="Logo"/>
    <div class="font-weight-bold">{{ 'VIEW.MAIN.HELP.PHONE' | translate }}</div>
    <div class="mb-6">
      <svg-icon src="asset/image/icon/phone.svg"></svg-icon>
      (855) 236-4389
    </div>
    <button type="button" class="btn btn-light" (click)="onFacebookClick()">
      <svg-icon src="asset/image/icon/brand-facebook.svg"></svg-icon>
    </button>
  </div>
</div>
<div class="row m-0">
  <div class="col-md-12 col-lg-8 col-xl-8">
    <div class="row justify-content-center justify-content-lg-end m-0">
      <button type="submit" id="submit" class="btn btn-primary" (click)="submit()">
        {{ 'COMMON.SEND' | translate }}
      </button>
    </div>
  </div>
</div>
