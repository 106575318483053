import { Transform } from 'class-transformer';
import { PageCriteriaDTO } from '../../PageCriteriaDTO';
import { DateUtil } from 'app/util/DateUtil';
import { LiveClassCategory } from 'app/data/enum/liveClass/LiveClassCategory';
import { DayOfWeek } from 'app/data/enum/DayOfWeek';
import { TimeOfDay } from 'app/data/enum/TimeOfDay';
import { IntensityLevel } from 'app/data/enum/IntensityLevel';

export class ScheduledLiveClassCoachPageCriteriaDTO extends PageCriteriaDTO {
  public anyOfCategories?: LiveClassCategory[];

  public anyOfIntensities?: IntensityLevel[];

  public anyOfLanguages?: string[];

  public consecutiveDays?: number;

  public daysOfWeek?: DayOfWeek[];

  public hasNotEnded?: boolean; // "true" for SENIOR, "false" for ADMIN

  public myClasses?: boolean;

  @Transform(DateUtil.dateConversion)
  public selectedDate: Date;

  public timesOfDay: TimeOfDay[];

  // set default values
  constructor() {
    super();

    this.hasNotEnded = true;
  }
}
