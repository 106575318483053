import { Type } from 'class-transformer';
import { IdentifiableObjectDTO } from 'app/data/dto/IdentifiableObjectDTO';
import { AssetPublicDownloadResponseDTO } from 'app/data/dto/images/AssetPublicDownloadResponseDTO';
import { Attachment } from 'app/data/local/attachment/Attachment';

export class LiveClassSeriesAttachmentResponseDTO extends IdentifiableObjectDTO<number> implements Attachment {
  @Type(() => AssetPublicDownloadResponseDTO)
  public asset: AssetPublicDownloadResponseDTO;

  public title: string;
}
