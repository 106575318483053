<ng-template #headingTemplate>
  <h1 class="mb-0">{{ 'VIEW.MAIN.USER.USERS' | translate }}</h1>
  <button class="btn btn-primary w-100 w-sm-auto" (click)="createUser()">
    <svg-icon src="asset/image/icon/plus.svg"></svg-icon>
    <span>{{ "VIEW.MAIN.USER.CREATE_USER" |translate }}</span>
  </button>
</ng-template>

<section class="section">
  <div class="d-flex">
    <app-tabs
      [translationPrefix]="'VIEW.MAIN.USER.'"
      class="py-2"
      [tabs]="tabs"
      (tabChanged)="onTabChanged($event)"
    ></app-tabs>
  </div>

  <app-data-grid *ngIf="page"
                 [data]="page.content"
                 [criteria]="criteria"
                 [context]="{ host: this }"
                 itemLabel="{{ 'VIEW.MAIN.USER.USERS_POSSESSIVE' | translate | lowercase }}"
                 [showItemsPerPage]="false"
                 [showFilters]="true"
                 [pageTotal]="page.totalPages"
                 [itemTotal]="page.totalElements"
                 (criteriaChanged)="onCriteriaChanged($event)"
                 (gridAction)="onGridAction($event)"
                 [multiSelectionEnabled]="true"
                 [selectionEnabled]="true"
                 (itemsSelected)="onSelectionChange($event)"
                 [useCheckboxSelection]="true"
                 [selectedRows]="selectedUsers"
                 [columns]="$any([
      { headerName: ('VIEW.MAIN.USER.FIRST_NAME' | translate), minWidth: 150, field: 'user.firstName', checkboxSelection: checkboxSelectionFunction },
      { headerName: ('VIEW.MAIN.USER.LAST_NAME' | translate), minWidth: 150, field: 'user.lastName', cellClass: 'text-right text-lg-left' },
      { headerName: ('VIEW.MAIN.USER.USER_ID' | translate), field: 'id', minWidth: 50, maxWidth: 100 },
      { headerName: ('VIEW.MAIN.USER.DATE_OF_BIRTH' | translate), field: 'dateOfBirth', minWidth: 150, maxWidth: 150, cellClass: 'text-right text-lg-left', valueFormatter: dateValueFormatter },
      { headerName: ('VIEW.MAIN.USER.PHONE' | translate), field: 'user.phone', minWidth: 150, maxWidth: 150 },
      { headerName: ('VIEW.MAIN.USER.EMAIL' | translate), field: 'user.email', minWidth: 250, maxWidth: 250, cellClass: 'text-right text-lg-left', cellRendererFramework: DataGridEmailCellRendererComponent },
      { headerName: ('DOMAIN.USER.DATA.SUBSCRIPTION.TYPE.LABEL' | translate), field: 'user.subscription.subscriptionType', minWidth: 100, maxWidth: 100, cellRendererFramework: DataGridTemplateCellRendererComponent, cellRendererParams: { ngTemplate: subscriptionTypeTemplate } },
      { headerName: ('DOMAIN.USER.DATA.SUBSCRIPTION.ORGANIZATION.LABEL' | translate), field: 'user.subscription.organization.name', minWidth: 100, maxWidth: 100, valueFormatter: viewUtil.dataGridReplaceEmptyValueFormatter },
      { headerClass: 'header-right', minWidth:100, maxWidth: 150, cellClass: 'cell-fullwidth', suppressKeyboardEvent: UserListActionsCellRendererComponent.suppressKeyboardEvent, cellRendererFramework: UserListActionsCellRendererComponent, cellRendererParams: { context: { activeTab: activeTab } } }
    ])"
  >
    <app-data-grid-filters>
      <div class="form-inline align-items-start">
        <div class="col-12 col-sm col-md-3 p-0">
          <ng-select
            id="sort-by"
            name="sort-by"
            [placeholder]="'COMMON.SORT_BY' | translate"
            [searchable]="false"
            class="mr-sm-4 mr-0 mb-sm-0 mb-4"
            [style.--icon]="'url(/asset/image/icon/arrows-sort.svg)'"
            (change)="onSortOptionChange($event)"
            [(ngModel)]="sortValue"
          >
            <ng-option *ngFor="let sortType of sortByOptions" [value]="sortType.value">
              {{ sortType.label | translate }}
            </ng-option>
          </ng-select>
        </div>

        <div class="col-12 col-sm col-md-4 p-0">
          <div class="w-100">
            <app-user-filter [criteria]="criteria"
                             (criteriaChange)="onCriteriaChanged($event)"></app-user-filter>
          </div>
        </div>
      </div>

      <app-filters-labels>
        <div class="d-flex mt-4">
          <app-user-selected-filter [criteria]="criteria"
                                    (criteriaChange)="onCriteriaChanged($event)"></app-user-selected-filter>
        </div>
      </app-filters-labels>
    </app-data-grid-filters>
  </app-data-grid>
</section>

<app-users-selected-action-bar [mode]="actionBarMode"
                               [selectedUsers]="selectedUsers"
                               (action)="toggleUserStatus($event)">
</app-users-selected-action-bar>


<!--Grid templates-->
<ng-template #subscriptionTypeTemplate
             let-value="value">{{ value | prefix: 'ENUM.SUBSCRIPTION_TYPE.' | translate }}
</ng-template>
