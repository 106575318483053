import { Component } from '@angular/core';
import { questionsList } from 'app/data/local/FAQList';
import { FAQItem } from 'app/data/local/FAQItem';

@Component({
  selector: 'app-help-faq',
  templateUrl: './HelpFAQComponent.html',
  styleUrls: [ './HelpFAQComponent.scss' ]
})
export class HelpFAQComponent {
  public questions: FAQItem[] = questionsList;
}
