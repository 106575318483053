import { VideoStatus } from 'app/data/enum/video/VideoStatus';
import { VideoVisibility } from 'app/data/enum/video/VideoVisibility';
import { Exclude, Type } from 'class-transformer';
import { ActiveCoachDTO } from '../coach/ActiveCoachDTO';
import { VideoAttachmentResponseDTO } from './VideoAttachmentResponseDTO';
import { ImageResponseDTO } from '../images/ImageResponseDTO';
import { IntensityLevel } from 'app/data/enum/IntensityLevel';
import { BaseVideoDTO } from 'app/data/dto/onDemandVideo/BaseVideoDTO';

export class VideoDetailsDTO extends BaseVideoDTO {
  @Type(() => VideoAttachmentResponseDTO)
  public attachments: VideoAttachmentResponseDTO[];

  @Type(() => ImageResponseDTO)
  public thumbnail: ImageResponseDTO;

  @Type(() => ActiveCoachDTO)
  public coaches: ActiveCoachDTO[];

  public description: string;

  public duration: number;

  public featured: boolean;

  public intensity: IntensityLevel;

  public language: string;

  public rating: number;

  public ratingEnabled: boolean;

  public recommended: boolean;

  public status: VideoStatus;

  public vimeoVideo: {
    id: number;
    embedUrl: string;
  };

  public viewCount: number;

  public visibility: VideoVisibility;

  @Exclude()
  public get coachIds(): number[] {
    return this.coaches.map(coach => coach.id);
  }
}