<div class="program-tile">
  <div class="details mb-2">
    <img role="button" class="image" [src]="data.thumbnail.defaultAsset.url" (click)="onDetailsClick()" alt=""/>

    <div class="d-flex justify-content-between icons-top">
      <button type="button" class="btn" (click)="onDetailsClick()">
        <svg-icon src="asset/image/icon/info-circle.svg"></svg-icon>
      </button>
      <button type="button" class="btn" (click)="changeIsFavorite()">
        <svg-icon [src]="'asset/image/icon/heart' + (data.isFavorite ? '-filled' : '') + '.svg'"></svg-icon>
      </button>
    </div>

    <div class="overlay p-3">
      <p class="mb-0">
        {{ data.videoCount }}
        <span *ngIf="data.videoCount > 1">{{ 'COMMON.VIDEOS' | translate }}</span>
        <span *ngIf="data.videoCount === 1">{{ 'COMMON.VIDEO' | translate }}</span>
      </p>
      <button class="btn btn-outline-primary" (click)="onPlayClick()">
        <span>{{ 'VIEW.MAIN.ON_DEMAND.PLAY' | translate }}</span>
        <svg-icon src="asset/image/icon/player-play.svg"></svg-icon>
      </button>
    </div>
  </div>
  <div class="d-flex flex-column justify-content-center align-items-center">
    <div class="text-sm mb-2">{{ data.title }}</div>
    <div *ngIf="data.rating >= Constant.STAR_RATING_MIN_VALUE" class="rating">
      <app-rating-stars [value]="data.rating"></app-rating-stars>
    </div>
  </div>
</div>
