<div *ngIf="scheduledLiveClass" class="row m-0 p-4">
  <div class="col-12 p-0 mb-0 d-flex justify-content-between">
    <p class="mb-0">{{ 'VIEW.MAIN.LIVE_CLASSES.NAME' | translate | uppercase }}:</p>
    <button type="button" class="btn" aria-label="Close" (click)="onCloseClick()">
      <svg-icon src="asset/image/icon/x.svg"></svg-icon>
    </button>
  </div>
  <div class="col-12 p-0 mb-6">
    <h3 class="font-weight-600">{{ scheduledLiveClass.title }}</h3>
  </div>
  <div class="col-12 col-md-6 p-0 mb-6">
    <p class="mb-0">{{ 'VIEW.MAIN.LIVE_CLASSES.TIME' | translate | uppercase }}</p>
    <p>{{ scheduledLiveClass.startDate | date: 'short' }} ({{ scheduledLiveClass.duration }} {{'COMMON.UNIT.MIN' | translate }})</p>
  </div>
  <div class="col-12 col-md-6 p-0 mb-6">
    <p class="mb-0">{{ 'VIEW.MAIN.LIVE_CLASSES.COACH' | translate | uppercase }}</p>
    <p>{{ scheduledLiveClass.coaches[0].user | fullName }}</p>
  </div>
  <div class="col-12 col-md-6 p-0 mb-6">
    <p class="mb-0">{{ 'VIEW.MAIN.LIVE_CLASSES.TYPE' | translate | uppercase }}</p>
    <p>{{ 'ENUM.CATEGORY.' + scheduledLiveClass.category | translate }}</p>
  </div>
  <div class="col-12 col-md-6 p-0 mb-6">
    <p class="mb-0">{{ 'VIEW.MAIN.LIVE_CLASSES.CAPACITY' | translate | uppercase }}</p>
    <p>{{ scheduledLiveClass.capacity | liveClassCapacity | translate }}</p>
  </div>
  <div class="col-12 p-0 mb-6">
    <p class="mb-0">{{ 'VIEW.MAIN.LIVE_CLASSES.DESCRIPTION' | translate | uppercase }}:</p>
    <p>{{ scheduledLiveClass.description }}</p>
  </div>
  <div *ngIf="scheduledLiveClass.scheduledLiveClassNeeds.length > 0" class="col-12 col-md-6 p-0">
    <p class="mb-0">{{ 'VIEW.MAIN.LIVE_CLASSES.RECOMMENDED_EQUIPMENT' | translate | uppercase }}</p>
    <ul class="pl-4 pt-2">
      <li *ngFor="let need of scheduledLiveClass.scheduledLiveClassNeeds">{{ need.need }}</li>
    </ul>
  </div>
  <div class="col-12 col-md-6 p-0">
    <ng-container *ngIf="scheduledLiveClass.attachments.length > 0">
      <p class="mb-0">{{ 'VIEW.MAIN.LIVE_CLASSES.ATTACHMENTS' | translate | uppercase }}</p>

      <div class="pt-2">
        <app-attachments [attachments]="scheduledLiveClass.attachments"></app-attachments>
      </div>
    </ng-container>
  </div>

  <div class="col-12">
    <hr/>
  </div>

  <div class="col-12 mt-4">
    <div class="d-flex justify-content-end">
      <button (click)="bsModalRef.hide()" type="button" class="btn mr-4">
        <div class="d-flex align-items-center gap-2">
          <svg-icon src="asset/image/icon/chevron-left.svg"></svg-icon>
          <span>{{ 'COMMON.BACK' | translate }}</span>
        </div>
      </button>
      <button
        *ngIf="!coachMode"
        [disabled]="!scheduledLiveClass.currentSeniorEnrollment || scheduledLiveClass.currentSeniorEnrollment.dropped"
        (click)="onDropClick()"
        id="back-button"
        type="button"
        class="btn mr-4"
      >
        <span class="d-flex flex-row justify-content-center align-items-center">
          <svg-icon class="d-flex mr-2" src="asset/image/icon/circle-off.svg"></svg-icon>
          <span>{{ 'COMMON.DROP' | translate }}</span>
        </span>
      </button>
      <button
        [disabled]="disableJoinButton()"
        (click)="onJoinClick()"
        id="back-button"
        type="button"
        class="btn btn-primary"
      >
        <span class="d-flex flex-row justify-content-center align-items-center">
          <svg-icon class="d-flex mr-2" src="asset/image/icon/circle-chevron-right.svg"></svg-icon>
          <span>{{ 'COMMON.JOIN' | translate }}</span>
        </span>
      </button>
    </div>
  </div>
</div>
