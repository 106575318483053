import { Component, Input } from '@angular/core';
import { IntensityLevel } from 'app/data/enum/IntensityLevel';

@Component({
  selector: 'app-intensity-level',
  templateUrl: './IntensityLevelComponent.html',
  styleUrls: [ './IntensityLevelComponent.scss' ]
})
export class IntensityLevelComponent {
  @Input() public intensity: IntensityLevel;
  public IntensityLevel: typeof IntensityLevel = IntensityLevel;
}
