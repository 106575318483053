<ng-template #headingTemplate>
  <h1>{{ 'VIEW.MAIN.MY_ACTIVITY.MY_ACTIVITY' | translate }}</h1>
  <p class="font-size-lg mb-0">
    {{ 'VIEW.MAIN.MY_ACTIVITY.TOTAL_ACTIVITY_TIME_THIS_MONTH' | translate }} {{ totalActivityTimeFormatted }}
  </p>
</ng-template>

<section class="section">
  <div class="row no-gutters d-flex justify-content-between">
    <div class="col-12 col-lg-4 no-gutters mb-4">
      <div class="card mr-lg-4">
        <div class="card-body">
          <ng-container *ngIf="seniorActivity">
            <div>
              <p class="font-size-lg">{{ 'VIEW.MAIN.MY_ACTIVITY.SUMMARY' | translate }}</p>
            </div>
            <div class="d-flex justify-content-between">
              <p class="font-weight-600">{{ 'VIEW.MAIN.MY_ACTIVITY.WEEK_STREAK' | translate }}</p>
              <p>{{ seniorActivity.weekStreak }}</p>
            </div>
            <div>
              <p class="font-weight-600">
                <span type="button" (click)="changeTimeFrame('previous')">&lt;</span>
                {{ currentTimeFrame | translate }}
                <span type="button" (click)="changeTimeFrame('next')">&gt;</span>
              </p>
            </div>

            <div>
              <ul>
                <li>
                  <span class="font-weight-light left">{{ 'VIEW.MAIN.MY_ACTIVITY.ACTIVE_DAYS' | translate }}</span>
                  <span class="right">{{ seniorActivity.summary.activeDays }}</span>
                </li>
                <li>
                  <span class="font-weight-light left">{{ 'VIEW.MAIN.MY_ACTIVITY.LIVE_CLASSES_TAKEN' | translate }}</span>
                  <span class="right">{{ seniorActivity.summary.liveClassesTakenCount }}</span>
                </li>
                <li>
                  <span class="font-weight-light left">{{ 'VIEW.MAIN.MY_ACTIVITY.ON_DEMAND_VIDEO_TAKEN' | translate }}</span>
                  <span class="right">{{ seniorActivity.summary.videosWatchedCount }}</span>
                </li>
              </ul>
            </div>

            <div class="mb-6">
              <p class="font-size-lg mb-0">{{ 'VIEW.MAIN.MY_ACTIVITY.RECENT_ACTIVITIES_TO_RATE' | translate }}</p>
            </div>

            <div class="d-flex mb-6">
              <app-recent-activities-to-rate *ngIf="unratedActivityCount; else noUnratedActivity"
                                             [unratedLiveClasses]="seniorActivity.liveClassesToRate"
                                             [unratedVideos]="seniorActivity.videosToRate"
                                             (rate)="getSeniorActivity()">
              </app-recent-activities-to-rate>

              <ng-template #noUnratedActivity>
                <p class="font-weight-light mb-0">
                  {{ 'VIEW.MAIN.MY_ACTIVITY.RECENT_ACTIVITIES_TO_RATE_EMPTY' | translate }}
                </p>
              </ng-template>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="col-12 col-lg-8 no-gutters">
      <div class="card mb-4">
        <div class="card-body">
          <p class="font-size-lg">{{ 'VIEW.MAIN.MY_ACTIVITY.TOTAL_CLASSES_TAKEN' | translate }}</p>

          <app-progression-bar [progressBarItems]="progressBarItems"></app-progression-bar>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <p class="font-size-lg mb-4">{{ 'VIEW.MAIN.MY_ACTIVITY.MOST_RECENT_BADGES' | translate }}</p>

          <div class="container-fluid px-0">
            <div class="row space-y-6 space-y-md-0">
              <div *ngFor="let badge of badges" class="col-12 col-sm-4">
                <app-badge-template [data]="badge" [disabled]="false"></app-badge-template>
              </div>
            </div>
          </div>

          <div class="mt-6 text-center">
            <button type="button" class="btn btn-primary w-100 w-md-auto px-md-8" (click)="onAllBadgesClick()">
              {{ 'VIEW.MAIN.MY_ACTIVITY.ALL_BADGES' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
